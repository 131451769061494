import { useCallback } from 'react';
import { useSelector } from '@lingoda/hooks';
import {
    goTo,
    removeRedirectPathStorage,
    studentHomePath,
    useRedirectPath,
    wrapDisplayName,
} from '@lingoda/router';
import { Loader } from '@lingoda/ui';
import { isAuthStatusUnauthenticatedSelector, isAuthStatusUnknownSelector } from '@lingoda/auth';
import type { ComponentType, FC } from 'react';

const DefaultRedirect: FC = () => {
    const [redirectPath] = useRedirectPath();

    if (redirectPath !== undefined) {
        removeRedirectPathStorage();
        goTo(redirectPath || studentHomePath());
    }

    return <Loader />;
};

export const ensureUnauthenticated = <P extends Record<string, unknown>>(
    Component: ComponentType<P>,
    Redirect: ComponentType = DefaultRedirect,
): ComponentType<P> => {
    const EnsureUnauthenticated: FC<P> = (props) => {
        const isUnauthenticated = useSelector(isAuthStatusUnauthenticatedSelector);
        const isUnknown = useSelector(isAuthStatusUnknownSelector);

        // useCallback is used to cache the value of isUnauthenticated once
        // the isUnknown is true (which means auth finished loading)
        // this component will ensureUnauthenticated only once so not auth components
        // can perform user actions (register/create/login) and not be autoredirected when that happens
        const redirectIfLogged = useCallback(
            (componentProps: P) => {
                if (isUnauthenticated) {
                    return <Component {...componentProps} />;
                }
                if (isUnknown) {
                    return <Loader />;
                }

                return <Redirect />;
            },
            [isUnauthenticated, isUnknown],
        );

        return redirectIfLogged(props);
    };

    if (__DEV__) {
        EnsureUnauthenticated.displayName = wrapDisplayName(Component, 'EnsureUnauthenticated');
    }

    return EnsureUnauthenticated;
};
