/// <reference types="user-agent-data-types" />

import { getTestStableChromiumVersion } from '@lingoda/feature-flags';
import { isDesktopDevice } from '@lingoda/user-agent';

export const getIsDeviceSupportingAllClassroomFeatures = () => {
    return isDesktopDevice;
};

function getChromiumVersion(): number | null {
    if (!navigator.userAgentData) {
        return null;
    }

    const chromiumBrand = navigator.userAgentData.brands.find(
        (brand) => brand.brand === 'Chromium',
    );

    return chromiumBrand ? parseInt(chromiumBrand.version) : null;
}

export const getIsBrowserSupportingAllClassroomFeatures = () => {
    const currentChromiumVersion = getChromiumVersion();

    if (currentChromiumVersion === null) {
        // Not a chromium browser
        return false;
    }

    return currentChromiumVersion >= MIN_SUPPORTED_CHROMIUM_VERSION;
};

// Check at https://chromestatus.com/roadmap
const CURRENT_STABLE_CHROMIUM_VERSION = getTestStableChromiumVersion() || 134;

// Zoom supports the current stable Chromium version and the two previous versions
// https://developers.zoom.us/docs/video-sdk/web/browser-support/
const MIN_SUPPORTED_CHROMIUM_VERSION = CURRENT_STABLE_CHROMIUM_VERSION - 2;

export const NEW_CHROME_LINK = 'https://www.google.com/chrome';
export const NEW_EDGE_LINK = 'https://www.microsoft.com/edge';
