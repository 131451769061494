import type { LessonSchema } from '@lingoda/api';
import { LessonType, StudentStatus } from '@lingoda/api';
import { createRecordFactory } from '@lingoda/utils';

export { StudentStatus, LessonType } from '@lingoda/api';

export type LessonId = number;
export type Lesson = LessonSchema;

export const LessonFactory = createRecordFactory<LessonSchema>({
    id: undefined,
    slug: undefined,
    type: undefined,
    label: undefined,
    title: undefined,
    active: undefined,
    seoMetadata: undefined,
    isOrientation: undefined,
    presentation: undefined,
    description: undefined,
    studentStatus: undefined,
    learningUnitIds: undefined,
    learningOutcome: undefined,
    completedCurrentVersion: undefined,
    availableWithoutPayment: undefined,
});

export const isLessonSkipped = (lesson: Lesson) => lesson.studentStatus === StudentStatus.Skipped;

export const isLessonNotTaken = (lesson: Lesson) => lesson.studentStatus === StudentStatus.NotTaken;

export const isLessonCompleted = (lesson: Lesson) =>
    lesson.studentStatus === StudentStatus.Completed;

export const isLessonPending = (lesson: Lesson) => lesson.studentStatus === StudentStatus.Pending;

export const isLessonBooked = (lesson: Lesson) => lesson.studentStatus === StudentStatus.Booked;

export const isLessonAttended = (lesson: Lesson) =>
    isLessonCompleted(lesson) || isLessonBooked(lesson) || isLessonPending(lesson);

export const isOrientationLesson = (lesson?: Lesson) => lesson?.type === LessonType.ORIENTATION;

export const isLessonProgressed = (lesson: Lesson) =>
    isLessonAttended(lesson) || isLessonSkipped(lesson);
