import { lazy } from 'react';
import {
    type RouteConfigExtended,
    addRouterListener,
    addSectionListener,
    defaultAppPath,
    finalizeDisconnectPath,
    forgotPasswordPath,
    forgotPasswordSuccessfulPath,
    loginPath,
    logoutPath,
    registerPath,
    socialConfirmPath,
    socialLoginConfirmMobilePath,
    socialRegisterConfirmMobilePath,
    socialRegisterPath,
} from '@lingoda/router';
import {
    RouteAccess,
    completeAccountSetPasswordPath,
    completeAccountWelcomePath,
    resetPasswordPath,
    setPasswordForNewAccountPath,
    socialRegisterConfirmPath,
} from '@lingoda/router';
import { registrationRoutes } from './registration';

const ResetPasswordPage = lazy(() => import('../pages/ResetPassword'));
const SetPasswordForNewAccountPage = lazy(() => import('../pages/SetPassword'));
const SocialRegisterConfirmPage = lazy(() => import('../pages/SocialRegisterConfirm'));
const CompleteAccountWelcomePage = lazy(
    () => import('../pages/CompleteAccountSetup/CompleteAccountWelcomePage'),
);
const CompleteAccountSetPasswordPage = lazy(
    () => import('../pages/CompleteAccountSetup/CompleteAccountSetPasswordPage'),
);
const LogoutPage = lazy(() => import('../pages/LogoutPage'));
const Login = lazy(() => import('../pages/Login'));
const ChooseSectionCurriculum = lazy(() => import('../pages/ChooseSectionCurriculum'));
const ForgottenPassword = lazy(() => import('../pages/ForgottenPassword'));
const ForgottenPasswordSuccessful = lazy(() => import('../pages/ForgottenPasswordSuccessful'));
const FinalizeDisconnect = lazy(() => import('../pages/FinalizeDisconnect'));
const SocialRegister = lazy(() => import('../pages/SocialRegister'));
const SocialConfirm = lazy(() => import('../pages/SocialConfirm'));
const SocialMobileConfirm = lazy(() => import('../pages/SocialMobileConfirm'));

const LoginPage = addSectionListener(addRouterListener('loginPath')(Login));
const RouteForgottenPassword = addSectionListener(
    addRouterListener('forgotPasswordPath')(ForgottenPassword),
);
const RouteForgottenPasswordSuccessful = addSectionListener(
    addRouterListener('forgotPasswordSuccessfulPath')(ForgottenPasswordSuccessful),
);
const RouteFinalizeDisconnect = addSectionListener(
    addRouterListener('finalizeDisconnectPath')(FinalizeDisconnect),
);
const RouteSocialRegister = addRouterListener('registerLoginPath')(SocialRegister);
const RouteSocialConfirm = addRouterListener('confirmSocialPath')(SocialConfirm);
const RouteLoginSocialMobileConfirm = addRouterListener('socialLoginConfirmMobilePath')(
    SocialMobileConfirm,
);
const RouteRegisterSocialMobileConfirm = addRouterListener('socialRegisterConfirmMobilePath')(
    SocialMobileConfirm,
);

export const studentAuthRoutes: RouteConfigExtended[] = [
    {
        exact: true,
        label: 'Login',
        path: defaultAppPath.raw,
        component: LoginPage,
        config: {
            access: RouteAccess.Public,
            hideFooter: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: LoginPage,
        path: loginPath.raw,
        label: 'Login',
        config: {
            access: RouteAccess.Public,
            hideFooter: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: ChooseSectionCurriculum,
        path: registerPath.raw,
        label: 'ChooseSectionCurriculum',
        config: {
            access: RouteAccess.Public,
            hideFooter: true,
            theme: 'dark',
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: RouteForgottenPassword,
        path: forgotPasswordPath.raw,
        label: 'ForgottenPassword',
        config: {
            access: RouteAccess.Public,
            hideFooter: true,
            hideNavigation: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: RouteForgottenPasswordSuccessful,
        path: forgotPasswordSuccessfulPath.raw,
        label: 'ForgottenPasswordSuccess',
        config: {
            access: RouteAccess.Public,
            hideFooter: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: LogoutPage,
        label: 'Logout',
        path: logoutPath.raw,
        config: {
            navigationElevation: 1,
            access: RouteAccess.All,
            hideNavigation: true,
            hideFooter: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: ResetPasswordPage,
        path: resetPasswordPath.raw,
        label: 'ResetPassword',
        config: {
            navigationElevation: 1,
            access: RouteAccess.Public,
            hideNavigation: true,
            hideFooter: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: SetPasswordForNewAccountPage,
        path: setPasswordForNewAccountPath.raw,
        label: 'SetPasswordForNewAccount',
        config: {
            navigationElevation: 1,
            access: RouteAccess.Public,
            hideNavigation: true,
            hideFooter: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: RouteSocialRegister,
        path: socialRegisterPath.raw,
        label: 'SocialRegister',
        config: {
            access: RouteAccess.Public,
            hideFooter: true,
            hideNavigation: true,
            tab: undefined,
            hideNotifications: true,
            navigationElevation: 1,
        },
    },
    {
        exact: true,
        component: SocialRegisterConfirmPage,
        path: socialRegisterConfirmPath.raw,
        label: 'SocialRegisterConfirmPage',
        config: {
            navigationElevation: 1,
            access: RouteAccess.Public,
            hideNavigation: true,
            hideFooter: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: RouteSocialConfirm,
        path: socialConfirmPath.raw,
        label: 'ConfirmSocialLogin',
        config: {
            access: RouteAccess.Public,
            hideFooter: true,
            hideNavigation: true,
            theme: 'light',
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: RouteLoginSocialMobileConfirm,
        path: socialLoginConfirmMobilePath.raw,
        label: 'ConfirmLoginMobileSocialLogin',
        config: {
            access: RouteAccess.Public,
            hideFooter: true,
            hideNavigation: true,
            tab: undefined,
            hideNotifications: true,
            disableLogoLink: true,
        },
    },
    {
        exact: true,
        component: RouteRegisterSocialMobileConfirm,
        path: socialRegisterConfirmMobilePath.raw,
        label: 'ConfirmRegisterMobileSocialLogin',
        config: {
            access: RouteAccess.Public,
            hideFooter: true,
            hideNavigation: true,
            tab: undefined,
            hideNotifications: true,
            disableLogoLink: true,
        },
    },
    {
        exact: true,
        component: CompleteAccountWelcomePage,
        path: completeAccountWelcomePath.raw,
        label: 'CompleteAccountWelcomePage',
        config: {
            navigationElevation: 1,
            disableLogoLink: true,
            hideNavigation: true,
            hideFooter: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: CompleteAccountSetPasswordPage,
        path: completeAccountSetPasswordPath.raw,
        label: 'CompleteAccountSetPasswordPage',
        config: {
            navigationElevation: 1,
            disableLogoLink: true,
            hideNavigation: true,
            hideFooter: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: RouteFinalizeDisconnect,
        path: finalizeDisconnectPath.raw,
        label: 'FinalizeDisconnect',
        config: {
            access: RouteAccess.Public,
            hideFooter: true,
            hideNavigation: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    ...registrationRoutes,
];
