import { lazy } from 'react';
import type { RouteConfigExtended } from '@lingoda/router';
import { RouteAccess, addRouterListener, addSectionListener, referralPath } from '@lingoda/router';
import { studentAuthRoutes } from '@lingoda/student-auth';

const Referral = lazy(() => import('../components/pages/Referral'));
const RouteReferral = addSectionListener(addRouterListener('referralPath')(Referral));

const routes: RouteConfigExtended[] = [
    {
        exact: true,
        component: RouteReferral,
        path: referralPath.raw,
        label: 'Referral',
        config: {
            access: RouteAccess.Public,
            hideFooter: true,
            hideNavigation: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    ...studentAuthRoutes,
];

export default routes;
