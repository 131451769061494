import { useEffect } from 'react';
import {
    bookingPreviewPath,
    currentRouteSectionCurriculumSlugSelector,
    resolvePath,
    resolveUrl,
    useHistory,
    useLocation,
    useRedirectPath,
} from '@lingoda/router';
import { buildQueryString, getQueryParam, getQueryParams } from '@lingoda/urls';
import { useSelector, useToggleState } from '@lingoda/hooks';
import { getLang, isFullySupportedLangValid } from '@lingoda/env';
import { isSprintBookingPreviewEnabled } from '@lingoda/feature-flags';

/**
 * This hook is used to change redirect path to booking preview page with old redirect path as query param
 * @returns loading state to prevent redirecting before redirect path changed
 */

export const usePreviewRegistrationPage = () => {
    const history = useHistory();
    const location = useLocation();
    const previewBooking = getQueryParam<string>(PREVIEW_QUERY_PARAM, location);
    const { sectionName } = useSelector(currentRouteSectionCurriculumSlugSelector);
    const [redirectPath, setRedirectPath] = useRedirectPath();
    const [loading, , setLoaded] = useToggleState(true);

    useEffect(() => {
        if (!sectionName) {
            return;
        }

        if (
            previewBooking !== 'true' ||
            !isFullySupportedLangValid(getLang()) ||
            !isSprintBookingPreviewEnabled()
        ) {
            setLoaded();

            return;
        }

        const redirectPathResolved = redirectPath && resolvePath(redirectPath);

        const bookingPreviewFinalPath = bookingPreviewPath({
            redirect: redirectPathResolved,
        });

        const redirectUrlResolved = resolveUrl(redirectPathResolved);

        if (redirectUrlResolved) {
            const redirectUrl = new URL(redirectUrlResolved);
            const bookingPreviewUrl = new URL(
                resolveUrl(resolvePath(bookingPreviewPath({}))) as string,
            );

            if (redirectUrl.pathname !== bookingPreviewUrl.pathname) {
                setRedirectPath(bookingPreviewFinalPath);
                history.replace({
                    ...location,
                    search: buildQueryString({
                        ...getQueryParams(location.search),
                        redirect: resolvePath(bookingPreviewFinalPath),
                    }),
                });
            }
        }

        setLoaded();
    }, [previewBooking, redirectPath, setRedirectPath, history, location, sectionName, setLoaded]);

    return loading;
};

const PREVIEW_QUERY_PARAM = 'previewBooking';
