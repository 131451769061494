import { type Dispatch } from 'redux';
import { actionClick, addEventFromAction } from '@lingoda/analytics';
import type { IncludedResponseAttributes, UserResponse } from '@lingoda/api';
import { createAppointment, createClass, getClassLeadTime } from '@lingoda/api';
import {
    createStudent,
    fetchMe,
    getUserIdFromStorage,
    logout,
    needToShowBookingSurvey,
    setUserIdToStorage,
} from '@lingoda/auth';
import { objectDiff, setInterval } from '@lingoda/utils';
import { type Date, addDuration, createDate, formatDateTime } from '@lingoda/dates';
import { addStudents, setModule, studentIdSelector, updatePreferences } from '@lingoda/students';
import { showPopup } from '@lingoda/popups';
import { resolveAppointmentId } from '@lingoda/classes';
import { ClassType } from '@lingoda/graphql';
import { addCallback, addTrackerCallback, memoiseAction } from '@lingoda/core';
import { DialogComponent, showDialog } from '@lingoda/dialogs';
import { addLessons, lessonByIdSelector } from '@lingoda/lessons';
import { addLearningUnits } from '@lingoda/learning-units';
import { addModules } from '@lingoda/modules';
import { onboardingPath } from '@lingoda/router';
import {
    currentSubscriptionSelector,
    isDateAfterExpiry,
    isDateInsidePausePeriod,
    isPausedSubscription,
    isSubscriptionWillExpireSelector,
} from '@lingoda/subscriptions';
import {
    isAfterBookingFeedbackSurveyEnabled,
    isOnboardingChecklistEnabled,
} from '@lingoda/feature-flags';
import {
    type BookClassActionParams,
    type ExistingClassBookParams,
    type RequestedClassBookParams,
    addLeadTime,
    asyncMinDate,
    bookClass,
    clearFilter,
    fetchErrorLeadTime,
    getLeadTime,
    resetBaseBookingFilter,
    resetCurriculumBookingFilter,
    revalidateBookingFilter,
    setFilter,
    updateMinDates,
} from '../actions';
import {
    bookingFilterSelector,
    defaultBookingFilterSelector,
    sectionLeadTimesSelector,
} from '../selectors';
import {
    clearLastBookingDate,
    clearStoredBookingFilter,
    getBaseFilter,
    getCurriculumFilter,
    getValidBookingFilter,
    updateStoredBookingFilter,
} from '../utils';
import { type MinimalBookingDates } from '../models';
import {
    checkCredits,
    oneClickBookingEnabled,
    showBookClassSuccessNotification,
    showBookingBlockedByPauseDialog,
} from './common';

const isExistingClass = (payload: BookClassActionParams): payload is ExistingClassBookParams => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return !!(payload as any).id;
};

const isRequestedClass = (payload: BookClassActionParams): payload is RequestedClassBookParams => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return !!(payload as any).isRequestedByUser;
};

export default () => {
    addCallback([fetchMe.success, createStudent.success], async (action, store) => {
        const result = action.payload.result as UserResponse;
        // eslint-disable-next-line @typescript-eslint/await-thenable
        const userId = await getUserIdFromStorage();

        if (!userId) {
            setUserIdToStorage(result.data.id);

            return;
        }

        if (userId !== result.data.id) {
            clearStoredBookingFilter();
            store.dispatch(clearFilter());
            setUserIdToStorage(result.data.id);
        }
    });

    addCallback(setModule.success, ({ payload: { payload } }, store) => {
        store.dispatch(setFilter({ modules: [payload.moduleId], lessons: [] }));
    });

    addCallback(setFilter, ({ payload }) => updateStoredBookingFilter(payload));

    addCallback(resetBaseBookingFilter, (_, store) => {
        const state = store.getState();
        const filter = bookingFilterSelector(state);
        const defaultBookingFilter = defaultBookingFilterSelector(state);
        const nextFilter = { ...filter, ...getBaseFilter(defaultBookingFilter) };
        const filterDiff = objectDiff(filter, nextFilter);

        if (filterDiff) {
            store.dispatch(setFilter(filterDiff));
        }
    });

    addCallback(resetCurriculumBookingFilter, (_, store) => {
        const state = store.getState();
        const filter = bookingFilterSelector(state);
        const defaultBookingFilter = defaultBookingFilterSelector(state);
        const nextFilter = { ...filter, ...getCurriculumFilter(defaultBookingFilter) };
        const filterDiff = objectDiff(filter, nextFilter);

        if (filterDiff) {
            store.dispatch(setFilter(filterDiff));
        }
    });

    addCallback(revalidateBookingFilter, (_, store) => {
        const bookingFilter = bookingFilterSelector(store.getState());
        const validatedFilter = getValidBookingFilter(bookingFilter);
        const filterDiff = objectDiff(bookingFilter, validatedFilter);
        if (filterDiff) {
            store.dispatch(setFilter(filterDiff));
        }
    });

    addTrackerCallback(bookClass, async (action, store): Promise<BookClassActionResult> => {
        const bookParams = action.payload;
        const { type: classType, startDate, availableSeats, overrideOneClickBooking } = bookParams;
        const confirmExtraValues =
            classType === ClassType.Group
                ? { value: availableSeats ? String(availableSeats) : undefined }
                : undefined;

        const subscription = currentSubscriptionSelector(store.getState());
        if (
            isPausedSubscription(subscription) &&
            isDateInsidePausePeriod(startDate, subscription)
        ) {
            showBookingBlockedByPauseDialog(store, subscription);
        }

        if (
            isSubscriptionWillExpireSelector(store.getState()) &&
            subscription &&
            isDateAfterExpiry(startDate, subscription)
        ) {
            store.dispatch(
                showDialog(DialogComponent.BookingBlockedBySubsExpiry, { subscription }),
            );

            return Promise.reject();
        }

        checkCredits(store, classType);

        const BookConfirmationCategory = `Book Confirmation ${
            classType === ClassType.Group ? 'Group' : 'Private'
        }`;

        if (overrideOneClickBooking ?? oneClickBookingEnabled(store)) {
            addEventFromAction(
                action,
                actionClick('Confirm'),
                BookConfirmationCategory,
                confirmExtraValues,
            );
        } else {
            await new Promise<void>((resolve, reject) => {
                store.dispatch(
                    showDialog(DialogComponent.ConfirmBooking, {
                        classStartDate: startDate,
                        classType,
                        onBookSuccess: () => {
                            addEventFromAction(
                                action,
                                actionClick('Confirm'),
                                BookConfirmationCategory,
                                confirmExtraValues,
                            );
                            resolve();
                        },
                        onDismiss: () => {
                            addEventFromAction(
                                action,
                                actionClick('Close'),
                                BookConfirmationCategory,
                            );
                            reject();
                        },
                    }),
                );
            });
        }

        const studentId = studentIdSelector(store.getState());
        let appointmentId: string;
        let bookedClassId: string;
        let bookedLessonId: number;

        if (!studentId) {
            throw new Error('StudentId is not found');
        }

        let isFirstAppointment: boolean | undefined;

        if (isExistingClass(bookParams)) {
            const classId = bookParams.id;
            const result = await createAppointment(classId, `${studentId}`, bookParams.stats);
            const appointment = result.data;
            const appointmentClassId = resolveAppointmentId(appointment.id).classId;

            handleClassesResponse(store.dispatch, result.included);

            appointmentId = appointment.id;
            bookedClassId = classId || appointmentClassId;
            bookedLessonId = appointment.class.lessonId;
            isFirstAppointment = appointment.isFirstBooked;
        } else {
            const { lessonId } = bookParams;
            let result;

            if (isRequestedClass(bookParams)) {
                const { topic, isRequestedByUser } = bookParams;
                if (!lessonId && !topic) {
                    throw new Error('Topic or LessonId needs to be provided');
                }
                result = await createClass(studentId, {
                    lessonId,
                    startDate: formatDateTime(startDate),
                    type: classType,
                    isRequestedByUser,
                    topic,
                });
            } else {
                if (!lessonId) {
                    throw new Error('LessonId needs to be provided');
                }
                result = await createClass(studentId, {
                    lessonId,
                    startDate: formatDateTime(startDate),
                    type: classType,
                });
            }

            handleClassesResponse(store.dispatch, result.included);

            if (!result.data.currentStudentAppointment) {
                throw new Error('Student Appointment not found in response');
            }

            appointmentId = result.data.currentStudentAppointment.id;
            isFirstAppointment = result.data.currentStudentAppointment.isFirstBooked;
            bookedClassId = result.data.id;
            bookedLessonId = result.data.lessonId;
        }

        store.dispatch(fetchMe());

        if (!window.location?.pathname?.startsWith(onboardingPath() as string)) {
            if (isOnboardingChecklistEnabled() && isFirstAppointment) {
                // Do nothing
                // Mercure would trigger special first class toast
                // See: workspaces/apps/student_frontend/src/milestones/hooks/useAchievementEvents.tsx
            } else {
                showBookClassSuccessNotification();
            }
        }

        return {
            classId: bookedClassId,
            appointmentId,
            lessonId: bookedLessonId,
        };
    });

    addCallback(bookClass.success, (action, store) => {
        const { lessonId } = action.payload.result as BookClassActionResult;
        const state = store.getState();

        const currentlyBookedLesson = lessonId ? lessonByIdSelector(state, lessonId) : undefined;

        if (currentlyBookedLesson?.isOrientation) {
            store.dispatch(updatePreferences({ showOrientationClassBanner: false }));
        }

        const overrideOneClickBooking = action.payload.payload.overrideOneClickBooking;

        if (
            isAfterBookingFeedbackSurveyEnabled() &&
            !overrideOneClickBooking &&
            needToShowBookingSurvey(state)
        ) {
            showPopup('bookingFeedback');
        }
    });

    addCallback(getLeadTime, (action, store) =>
        memoiseAction(action, () => {
            const promise = getClassLeadTime();

            promise.then(
                (leadTime) => store.dispatch(addLeadTime(leadTime)),
                () => store.dispatch(fetchErrorLeadTime()),
            );

            return promise;
        }),
    );

    const parseDuration = (durationString: string): Date | undefined =>
        durationString ? addDuration(createDate(), durationString) : undefined;

    let interval: number;
    addCallback(asyncMinDate, (_action, store) => {
        if (interval) {
            clearInterval(interval);
        }

        const leadTimes = sectionLeadTimesSelector(store.getState());

        const setMinDates = () => {
            const minDates = Object.entries(leadTimes).reduce(
                (acc, [classType, date]) => ({ ...acc, [classType]: parseDuration(date) }),
                {} as MinimalBookingDates,
            );
            store.dispatch(updateMinDates(minDates));
        };
        setMinDates();
        interval = setInterval(setMinDates, 60000);
    });

    addCallback(logout, () => {
        clearStoredBookingFilter();
        clearLastBookingDate();
    });
};

export interface BookClassActionResult {
    classId: string;
    appointmentId: string;
    lessonId?: number;
}

function handleClassesResponse(dispatch: Dispatch, includedResponse: IncludedResponseAttributes) {
    if (!includedResponse) {
        return;
    }

    const { learningUnits, lessons, modules, students } = includedResponse;
    dispatch(addLearningUnits(learningUnits || []));
    dispatch(addLessons(lessons || []));
    dispatch(addModules(modules || []));
    dispatch(addStudents(students || []));
}
