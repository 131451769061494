import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from './hooks';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
    [_ in K]?: never;
};
export type Incremental<T> =
    | T
    | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = { context: { clientName: 'monolith' } } as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /** The `DateTime` scalar type represents time data, represented as an ISO-8601 encoded UTC date string. */
    DateTime: { input: string; output: string };
    /** Represents a hash map */
    Map: { input: Record<string, unknown>; output: Record<string, unknown> };
    /** Special treatment for section.name for BC types on Frontend */
    SectionName: { input: SectionName; output: SectionName };
    /** Represents a hash map with string value */
    StringMap: { input: Record<string, string>; output: Record<string, string> };
    StringMatrix: { input: string[][]; output: string[][] };
    Ulid: { input: string; output: string };
    /**
     * The `Upload` special type represents a file to be uploaded in the same HTTP request as specified by
     *  [graphql-multipart-request-spec](https://github.com/jaydenseric/graphql-multipart-request-spec).
     */
    Upload: { input: File; output: File };
    Url: { input: string; output: string };
};

export type Achievement = {
    readonly __typename?: 'Achievement';
    readonly achieved: Scalars['Boolean']['output'];
    readonly achievedAt: Maybe<Scalars['DateTime']['output']>;
    readonly achievedLabel: Scalars['String']['output'];
    readonly claimId: Maybe<Scalars['Ulid']['output']>;
    readonly claimed: Scalars['Boolean']['output'];
    readonly claimedAt: Maybe<Scalars['DateTime']['output']>;
    readonly congratulationsText: Scalars['String']['output'];
    readonly currentProgress: Scalars['Int']['output'];
    readonly id: Scalars['Ulid']['output'];
    readonly name: Scalars['String']['output'];
    readonly progressLabel: Scalars['String']['output'];
    readonly rewardText: Scalars['String']['output'];
    readonly slug: Scalars['String']['output'];
    readonly value: Scalars['Int']['output'];
};

export type Activity = {
    readonly __typename?: 'Activity';
    readonly completed: Scalars['Boolean']['output'];
    readonly lastAttendedClass: Scalars['Boolean']['output'];
    readonly lesson: Maybe<Lesson>;
    readonly quizProgress: Maybe<QuizProgress>;
    readonly type: ActivityTypeEnum;
    readonly upcomingClass: Scalars['Boolean']['output'];
};

export type ActivityChallenge = {
    readonly __typename?: 'ActivityChallenge';
    readonly challengeIcon: Maybe<Media>;
    readonly currentParticipant: Maybe<ActivityChallengeParticipant>;
    readonly description: Scalars['String']['output'];
    readonly desktopBackgroundImage: Maybe<Media>;
    readonly desktopBackgroundTheme: BackgroundThemeEnum;
    readonly endDate: Scalars['DateTime']['output'];
    readonly mobileBackgroundImage: Maybe<Media>;
    readonly mobileBackgroundTheme: BackgroundThemeEnum;
    readonly name: Scalars['String']['output'];
    readonly overviewFirstParagraph: Scalars['String']['output'];
    readonly overviewSecondParagraph: Scalars['String']['output'];
    readonly participants: Scalars['Int']['output'];
    readonly prizes: ReadonlyArray<ActivityChallengePrize>;
    readonly publicName: Scalars['String']['output'];
    readonly startDate: Scalars['DateTime']['output'];
};

export type ActivityChallengeParticipant = {
    readonly __typename?: 'ActivityChallengeParticipant';
    readonly classesAttendedCount: Scalars['Int']['output'];
    readonly completedActivities: Scalars['Int']['output'];
    readonly id: Scalars['Ulid']['output'];
    readonly learnedWordsCount: Scalars['Int']['output'];
    readonly rank: Maybe<Scalars['Int']['output']>;
    readonly status: ActivityChallengeParticipantStatusEnum;
    readonly student: Student;
    readonly trainingSessionsCount: Scalars['Int']['output'];
};

export type ActivityChallengeParticipantRankArgs = {
    challengeName: Scalars['String']['input'];
};

export enum ActivityChallengeParticipantStatusEnum {
    Created = 'created',
    Joined = 'joined',
}

export type ActivityChallengePrize = {
    readonly __typename?: 'ActivityChallengePrize';
    readonly audioUrl: Maybe<Scalars['String']['output']>;
    readonly explanation: Scalars['String']['output'];
    /**
     * Until we fully automate the challenge creation process in the admin,
     * we need a way to map prize icons to prizes on FE side.
     * So far, the easiest solution is to create index-based logic and map the icons to the indexes.
     * This logic will be removed after we fully automate the challenge creation process
     */
    readonly index: Scalars['Int']['output'];
    readonly name: Scalars['String']['output'];
    readonly noun: Scalars['String']['output'];
    readonly nounTranslation: Scalars['String']['output'];
    readonly prizeIcon: Maybe<Media>;
    readonly value: Scalars['Int']['output'];
};

export enum ActivityTypeEnum {
    PracticeSequence = 'PRACTICE_SEQUENCE',
    PreClass = 'PRE_CLASS',
    Quiz = 'QUIZ',
}

export type Agreement = {
    readonly __typename?: 'Agreement';
    readonly acceptanceText: Scalars['String']['output'];
    readonly acceptedAt: Maybe<Scalars['DateTime']['output']>;
    readonly body: Scalars['String']['output'];
    readonly id: Scalars['String']['output'];
    readonly isAccepted: Scalars['Boolean']['output'];
    readonly isRequired: Scalars['Boolean']['output'];
    readonly pdfLink: Maybe<Scalars['String']['output']>;
    readonly type: Maybe<AgreementType>;
};

export enum AgreementType {
    MarketingCommunication = 'marketing_communication',
    PrivacyPolicy = 'privacy_policy',
    ProductPolicy = 'product_policy',
    RemunerationStructure = 'remuneration_structure',
    TeacherTerms = 'teacher_terms',
}

export type Appointment = {
    readonly __typename?: 'Appointment';
    readonly appointmentLog: Maybe<AppointmentLog>;
    readonly attendanceStatus: Maybe<AppointmentStatusBySystem>;
    readonly class: Klass;
    readonly createdAt: Scalars['DateTime']['output'];
    readonly feedback: Maybe<ClassFeedback>;
    readonly hasAttemptToJoin: Scalars['Boolean']['output'];
    readonly id: Scalars['Ulid']['output'];
    readonly isNewStudent: Scalars['Boolean']['output'];
    readonly isRatedByStudent: Scalars['Boolean']['output'];
    readonly label: Maybe<Scalars['String']['output']>;
    readonly module: Module;
    readonly status: AppointmentStatus;
    /** Get statusByStudent */
    readonly statusByStudent: Maybe<AppointmentStatusByStudentType>;
    readonly statusBySystem: Maybe<AppointmentStatusBySystem>;
    readonly student: Student;
};

export type AppointmentAttendance = {
    readonly __typename?: 'AppointmentAttendance';
    readonly appointmentId: Scalars['Ulid']['output'];
    readonly attended: Scalars['Boolean']['output'];
    readonly classId: Scalars['Int']['output'];
    readonly endDate: Scalars['DateTime']['output'];
    readonly lessonTitle: Scalars['String']['output'];
    readonly startDate: Scalars['DateTime']['output'];
};

export type AppointmentAttendanceInput = {
    readonly endDate?: InputMaybe<Scalars['DateTime']['input']>;
    readonly startDate?: InputMaybe<Scalars['DateTime']['input']>;
    readonly userIds?: InputMaybe<ReadonlyArray<Scalars['Int']['input']>>;
};

export type AppointmentLog = {
    readonly __typename?: 'AppointmentLog';
    readonly answers: ReadonlyArray<AppointmentLogAnswer>;
    readonly createdAt: Scalars['DateTime']['output'];
    readonly feedback: Maybe<Scalars['String']['output']>;
    readonly isAttended: Scalars['Boolean']['output'];
    readonly questions: ReadonlyArray<AppointmentLogQuestion>;
    readonly recommendedLevel: Maybe<Module>;
    readonly updatedAt: Scalars['DateTime']['output'];
};

export type AppointmentLogAnswer = {
    readonly __typename?: 'AppointmentLogAnswer';
    readonly answer: Scalars['String']['output'];
    readonly appointmentLogQuestion: AppointmentLogQuestion;
    readonly id: Scalars['Ulid']['output'];
};

export type AppointmentLogQuestion = {
    readonly __typename?: 'AppointmentLogQuestion';
    readonly answers: ReadonlyArray<AppointmentLogAnswer>;
    readonly id: Scalars['Ulid']['output'];
    readonly isRequired: Scalars['Boolean']['output'];
    readonly label: Scalars['String']['output'];
    readonly max: Scalars['Int']['output'];
    readonly min: Scalars['Int']['output'];
    readonly question: Scalars['String']['output'];
    readonly sentiment: Maybe<AppointmentLogQuestionSentiment>;
    readonly studentAnswers: ReadonlyArray<AppointmentLogAnswer>;
};

export enum AppointmentLogQuestionSentiment {
    Negative = 'negative',
    Positive = 'positive',
}

export enum AppointmentStatus {
    Active = 'active',
    Attended = 'attended',
    Cancelled = 'cancelled',
    Deleted = 'deleted',
    Missed = 'missed',
}

export enum AppointmentStatusByStudentType {
    Cancelled = 'cancelled',
    Completed = 'completed',
    NoTeacher = 'no_teacher',
    NotAttended = 'not_attended',
}

export enum AppointmentStatusBySystem {
    Attended = 'attended',
    Incomplete = 'incomplete',
    Missed = 'missed',
}

export type AuthData = {
    readonly __typename?: 'AuthData';
    readonly classUid: Scalars['String']['output'];
    readonly jwtToken: Scalars['String']['output'];
    readonly pdfUrl: Scalars['String']['output'];
    readonly userId: Scalars['Int']['output'];
    readonly userRole: Scalars['String']['output'];
};

export enum AuthTokenStatus {
    Active = 'active',
    Expired = 'expired',
}

export type B2Attendance = {
    readonly __typename?: 'B2Attendance';
    readonly attended: Scalars['Int']['output'];
    readonly endDate: Scalars['DateTime']['output'];
    readonly startDate: Scalars['DateTime']['output'];
    readonly total: Scalars['Int']['output'];
    readonly weekNumber: Scalars['Int']['output'];
};

export type B2AttendanceInput = {
    readonly curriculumCode?: InputMaybe<Scalars['String']['input']>;
    readonly endDate: Scalars['DateTime']['input'];
    readonly startDate: Scalars['DateTime']['input'];
    readonly userIds?: InputMaybe<ReadonlyArray<Scalars['Int']['input']>>;
};

export type B2GCourseInput = {
    readonly classes?: InputMaybe<ReadonlyArray<ClassInput>>;
    readonly course: CourseInput;
    readonly curriculumName: Scalars['String']['input'];
    readonly curriculumPoolId: Scalars['Int']['input'];
    readonly emails?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
    readonly moduleIds?: InputMaybe<ReadonlyArray<Scalars['Int']['input']>>;
    readonly teacherUserId: Scalars['Int']['input'];
};

export enum BackgroundAssetId {
    Bkg1 = 'bkg1',
    Bkg2 = 'bkg2',
    Bkg3 = 'bkg3',
    Bkg4 = 'bkg4',
    Bkg5 = 'bkg5',
    Bkg6 = 'bkg6',
    Bkg7 = 'bkg7',
    Bkg8 = 'bkg8',
    Bkg9 = 'bkg9',
    Bkg10 = 'bkg10',
    Bkg11 = 'bkg11',
    Bkg12 = 'bkg12',
    Bkg13 = 'bkg13',
    Bkg14 = 'bkg14',
    Bkg15 = 'bkg15',
    Bkg16 = 'bkg16',
    Bkg17 = 'bkg17',
    Bkg18 = 'bkg18',
    Blur = 'blur',
}

export type BackgroundImage = {
    readonly __typename?: 'BackgroundImage';
    readonly assetId: Maybe<BackgroundAssetId>;
    readonly imageHd: Maybe<Scalars['String']['output']>;
    readonly imageThumbnail: Maybe<Scalars['String']['output']>;
};

export enum BackgroundThemeEnum {
    Dark = 'dark',
    Light = 'light',
}

export enum BookingExperienceAnswerType {
    Dismissed = 'dismissed',
    No = 'no',
    Yes = 'yes',
}

export enum BookingExperienceExtraFeedback {
    AClassToTakeAsSoonAsPossible = 'a_class_to_take_as_soon_as_possible',
    AParticularTopicIAmInterestedIn = 'a_particular_topic_i_am_interested_in',
    ASpecificTimeSlot = 'a_specific_time_slot',
    ASpecificTypeOfClass = 'a_specific_type_of_class',
    ATeacherThatILike = 'a_teacher_that_i_like',
    IAmNotSure = 'i_am_not_sure',
    TheNextClassInTheOrderOfMyCourse = 'the_next_class_in_the_order_of_my_course',
    ToAvoidTeachersThatIDontLike = 'to_avoid_teachers_that_i_dont_like',
}

export type BookingExperienceSurveyAnswer = {
    readonly __typename?: 'BookingExperienceSurveyAnswer';
    readonly childQuestion: Maybe<BookingExperienceSurveyQuestion>;
    readonly translatedAnswer: Scalars['String']['output'];
    readonly value: Scalars['String']['output'];
};

export type BookingExperienceSurveyQuestion = {
    readonly __typename?: 'BookingExperienceSurveyQuestion';
    readonly answers: ReadonlyArray<BookingExperienceSurveyAnswer>;
    readonly fieldType: Scalars['String']['output'];
    readonly key: Scalars['String']['output'];
    readonly screenPosition: Scalars['Int']['output'];
    readonly translatedQuestion: Scalars['String']['output'];
};

export enum BusinessModelType {
    B2B = 'B2B',
    B2C = 'B2C',
    B2G = 'B2G',
    LearningTeams = 'LearningTeams',
}

export type CalendarConnectionExpiredErrorDto = {
    readonly __typename?: 'CalendarConnectionExpiredErrorDTO';
    readonly calendarType: CalendarType;
};

export enum CalendarType {
    Google = 'google',
    Microsoft = 'microsoft',
}

export type CanChangeLanguage = {
    readonly __typename?: 'CanChangeLanguage';
    readonly isEnabled: Scalars['Boolean']['output'];
    readonly isShown: Scalars['Boolean']['output'];
};

export type CefrLevel = {
    readonly __typename?: 'CefrLevel';
    readonly id: Scalars['String']['output'];
    readonly name: Scalars['String']['output'];
};

export type CertificateEarnedType = {
    readonly __typename?: 'CertificateEarnedType';
    readonly name: Scalars['String']['output'];
};

export enum CertificateType {
    AndererHochschulabschluss = 'anderer_hochschulabschluss',
    AnderesDafZertifikat = 'anderes_daf_zertifikat',
    AusbildungOperationstechnischeAssistentin = 'ausbildung_operationstechnische_assistentin',
    AusbildungZumZurFremdsprachenkorrespondentin = 'ausbildung_zum_zur_fremdsprachenkorrespondentin',
    BaMaAusbildungHebammenwesen = 'ba_ma_ausbildung_hebammenwesen',
    BaMaAusbildungPflegeUndGesundheitswesen = 'ba_ma_ausbildung_pflege_und_gesundheitswesen',
    BaMaInDaf = 'ba_ma_in_daf',
    BaMaInFle = 'ba_ma_in_fle',
    BaMaInGermanistik = 'ba_ma_in_germanistik',
    BaMaInItalian = 'ba_ma_in_italian',
    BaMaInPhilologie = 'ba_ma_in_philologie',
    BaMaInternationaleKommunikation = 'ba_ma_internationale_kommunikation',
    BaMaLehramtDeutsch = 'ba_ma_lehramt_deutsch',
    BaMaLehramtGeisteswissenschaften = 'ba_ma_lehramt_geisteswissenschaften',
    BaMaLehramtSprachen = 'ba_ma_lehramt_sprachen',
    BaMaLinguistik = 'ba_ma_linguistik',
    BaMaPhilologieAndereSprachen = 'ba_ma_philologie_andere_sprachen',
    BaMaPsychologie = 'ba_ma_psychologie',
    BaMaSonderpadagogik = 'ba_ma_sonderpadagogik',
    BaMaSozialeArbeit = 'ba_ma_soziale_arbeit',
    BaMaTranskulturelleKommunikation = 'ba_ma_transkulturelle_kommunikation',
    BaMaUberzetzung = 'ba_ma_uberzetzung',
    BamfAlphabetisierungskurs = 'bamf_alphabetisierungskurs',
    BamfBerufssprachkurs = 'bamf_berufssprachkurs',
    BamfBerufssprachkursLernschwierigkeiten = 'bamf_berufssprachkurs_lernschwierigkeiten',
    BamfBerufssprachkursOrientierungskursen = 'bamf_berufssprachkurs_orientierungskursen',
    BamfIntegrationskurse = 'bamf_integrationskurse',
    BamfZusatzqualifizierungDaf = 'bamf_zusatzqualifizierung_daf',
    BamfZusatzqualifizierungDaz = 'bamf_zusatzqualifizierung_daz',
    Cedils = 'cedils',
    Celta = 'celta',
    CeltaTeflEleFleDeutsch = 'celta_tefl_ele_fle_deutsch',
    DaefleAllianceFrancaise = 'daefle_alliance_francaise',
    Ditals = 'ditals',
    DllPremiumGoethe = 'dll_premium_goethe',
    DllStandardGoethe = 'dll_standard_goethe',
    GoethePruferlizenzA = 'goethe_pruferlizenz_a',
    GoethePruferlizenzB = 'goethe_pruferlizenz_b',
    GoethePruferlizenzC = 'goethe_pruferlizenz_c',
    HochschulabschlussMedizin = 'hochschulabschluss_medizin',
    HochschulzertifikatDafDaz = 'hochschulzertifikat_daf_daz',
    InstitutoCervantesCertificate = 'instituto_cervantes_certificate',
    MasterInEle = 'master_in_ele',
    MastersInTefl = 'masters_in_tefl',
    OsdPruferlizenzA = 'osd_pruferlizenz_a',
    OsdPruferlizenzB = 'osd_pruferlizenz_b',
    OsdPruferlizenzC = 'osd_pruferlizenz_c',
    OtherEnglish = 'other_english',
    OtherFrench = 'other_french',
    OtherItalian = 'other_italian',
    OtherSpanish = 'other_spanish',
    Pgce = 'pgce',
    ReceivedDoctorsEBook = 'received_doctors_e_book',
    ReceivedTelcEBook = 'received_telc_e_book',
    SprachzertifikatC1Deutsch = 'sprachzertifikat_c1_deutsch',
    SprachzertifikatC2Deutsch = 'sprachzertifikat_c2_deutsch',
    Tefl = 'tefl',
    TelcPruferlizenzA = 'telc_pruferlizenz_a',
    TelcPruferlizenzB = 'telc_pruferlizenz_b',
    TelcPruferlizenzC = 'telc_pruferlizenz_c',
    Tesol = 'tesol',
    UniversitatsabschlussDeutschDafAlsZweitfach = 'universitatsabschluss_deutsch_daf_als_zweitfach',
}

export type ChallengeStartedDto = {
    readonly __typename?: 'ChallengeStartedDTO';
    readonly subtitle: Scalars['String']['output'];
    readonly title: Scalars['String']['output'];
};

export type ChecklistItem = {
    readonly __typename?: 'ChecklistItem';
    readonly description: Scalars['String']['output'];
    readonly id: Scalars['Ulid']['output'];
    readonly isCompleted: Scalars['Boolean']['output'];
    readonly slug: ChecklistItemSlug;
    readonly title: Scalars['String']['output'];
};

export enum ChecklistItemSlug {
    AttendClass = 'attend_class',
    BookFirstClass = 'book_first_class',
    CheckOutYourCourse = 'check_out_your_course',
    Practice = 'practice',
    ReviewAfterClass = 'review_after_class',
    ReviewYourSchedule = 'review_your_schedule',
    StartPracticing = 'start_practicing',
    WarmupBeforeClass = 'warmup_before_class',
    WatchLingodaTeamsVideo = 'watch_lingoda_teams_video',
}

export type Choice = {
    readonly __typename?: 'Choice';
    readonly audioUrl: Maybe<Scalars['String']['output']>;
    readonly id: Scalars['String']['output'];
    readonly isCorrect: Scalars['Boolean']['output'];
    readonly text: Scalars['String']['output'];
    readonly translation: Maybe<QuestionTranslations>;
    readonly translations: ReadonlyArray<QuestionTranslations>;
};

export type ClassBookingErrorDto = {
    readonly __typename?: 'ClassBookingErrorDTO';
    readonly classType: Scalars['String']['output'];
    readonly date: Scalars['DateTime']['output'];
    readonly module: Scalars['String']['output'];
    readonly section: Scalars['String']['output'];
};

export type ClassBookingExperienceSurvey = {
    readonly __typename?: 'ClassBookingExperienceSurvey';
    readonly answer: Maybe<BookingExperienceAnswerType>;
    readonly comment: Maybe<Scalars['String']['output']>;
    readonly extraFeedback: ReadonlyArray<Scalars['String']['output']>;
    readonly id: Scalars['Ulid']['output'];
    readonly score: Maybe<Scalars['Int']['output']>;
};

export type ClassChatHistory = {
    readonly __typename?: 'ClassChatHistory';
    readonly content: ReadonlyArray<Scalars['StringMap']['output']>;
    readonly id: Scalars['Ulid']['output'];
    readonly zoomSession: ZoomSession;
};

export type ClassChatHistoryInput = {
    readonly chatHistory: Scalars['String']['input'];
    readonly zoomSessionId: Scalars['Ulid']['input'];
};

export type ClassConfig = {
    readonly __typename?: 'ClassConfig';
    readonly classDefaultDurationMinutes: Scalars['Int']['output'];
    readonly classMaxDurationMinutes: Scalars['Int']['output'];
    readonly classStartTimePoint: Scalars['Int']['output'];
    readonly enableGoToClassBeforeStartMinutes: Scalars['Int']['output'];
    readonly enableStudentNoShowAfterStartMinutes: Scalars['Int']['output'];
    readonly refundableAfterBookingForMinutes: Scalars['Int']['output'];
    readonly refundableDaysBeforeStart: Scalars['Int']['output'];
    readonly studentCancelLateHours: Scalars['Int']['output'];
    readonly teacherCancelLateHours: Scalars['Int']['output'];
    readonly teacherCancelLateHoursFake: Scalars['Int']['output'];
    readonly teacherCancelLatePenaltyDays: Scalars['Int']['output'];
    readonly teacherClassLogVisibleAfterStartMinutes: Scalars['Int']['output'];
    readonly teacherMissedClassPenaltyDays: Scalars['Int']['output'];
    readonly teacherResetBeforeStartHours: Scalars['Int']['output'];
    readonly teacherShortNoticeCancelAfterCommitMinutes: Scalars['Int']['output'];
    readonly teacherShortNoticeCancelBeforeStartHours: Scalars['Int']['output'];
    readonly technicalIssueAfterStartMinutes: Scalars['Int']['output'];
};

export type ClassFeedback = {
    readonly __typename?: 'ClassFeedback';
    readonly id: Scalars['Int']['output'];
};

export type ClassFeedbackAnswer = {
    readonly __typename?: 'ClassFeedbackAnswer';
    readonly active: Scalars['Boolean']['output'];
    readonly answer: Scalars['String']['output'];
    readonly answerType: ClassFeedbackAnswerType;
    readonly id: Scalars['Ulid']['output'];
    readonly sentiment: ClassFeedbackSentimentType;
};

export enum ClassFeedbackAnswerType {
    LearningMaterialFeedback = 'learning_material_feedback',
    TeacherFeedback = 'teacher_feedback',
    TechnicalQualityFeedbackStudent = 'technical_quality_feedback_student',
    TechnicalQualityFeedbackTeacher = 'technical_quality_feedback_teacher',
}

export type ClassFeedbackInput = {
    readonly learningMaterialFeedbackInput?: InputMaybe<LearningMaterialFeedbackInput>;
    readonly teacherFeedbackInput: TeacherFeedbackInput;
    readonly technicalQualityInput?: InputMaybe<TechnicalQualityFeedbackInput>;
};

export enum ClassFeedbackSentimentType {
    Negative = 'negative',
    Positive = 'positive',
}

export type ClassInput = {
    readonly datetime: Scalars['String']['input'];
    readonly lessonId: Scalars['Int']['input'];
};

export type ClassLeadTime = {
    readonly __typename?: 'ClassLeadTime';
    readonly group: ClassLeadTimeItem;
    readonly individual: ClassLeadTimeItem;
    readonly ocGroup: ClassLeadTimeItem;
    readonly ocIndividual: ClassLeadTimeItem;
};

export type ClassLeadTimeItem = {
    readonly __typename?: 'ClassLeadTimeItem';
    readonly english: Maybe<Scalars['String']['output']>;
    readonly french: Maybe<Scalars['String']['output']>;
    readonly german: Maybe<Scalars['String']['output']>;
    readonly spanish: Maybe<Scalars['String']['output']>;
};

export type ClassLog = {
    readonly __typename?: 'ClassLog';
    readonly class: Klass;
    readonly classroomChatLogUrl: Maybe<Scalars['String']['output']>;
    readonly createdAt: Maybe<Scalars['DateTime']['output']>;
    readonly hasClassroomChatLog: Scalars['Boolean']['output'];
    readonly id: Scalars['Int']['output'];
    readonly logMessage: Maybe<Scalars['String']['output']>;
    readonly updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type ClassLogGroupDto = {
    readonly __typename?: 'ClassLogGroupDTO';
    readonly classLogs: ReadonlyArray<ClassLog>;
    readonly date: Scalars['DateTime']['output'];
};

export type ClassMaterialSnapshot = {
    readonly __typename?: 'ClassMaterialSnapshot';
    readonly snapshot: Scalars['String']['output'];
};

export type ClassObservation = {
    readonly __typename?: 'ClassObservation';
    readonly id: Scalars['Ulid']['output'];
    readonly observerFullName: Scalars['String']['output'];
    readonly status: ClassObservationStatusType;
};

export enum ClassObservationStatusType {
    Booked = 'booked',
    CancelledByObserver = 'cancelled_by_observer',
    CancelledByTeacher = 'cancelled_by_teacher',
    ClassCancelled = 'class_cancelled',
    NotBooked = 'not_booked',
}

export enum ClassStatus {
    Cancelled = 'cancelled',
    Completed = 'completed',
    Requested = 'requested',
    Scheduled = 'scheduled',
    Unsuccessful = 'unsuccessful',
}

export enum ClassStatusByTeacherType {
    Active = 'active',
    Cancelled = 'cancelled',
    Completed = 'completed',
    Missed = 'missed',
    NoStudents = 'no_students',
    TechnicalIssue = 'technical_issue',
}

export type ClassTeacherFeedback = {
    readonly __typename?: 'ClassTeacherFeedback';
    readonly id: Scalars['Int']['output'];
    readonly technicalQualityFeedback: Maybe<TechnicalQualityFeedback>;
};

export enum ClassType {
    Group = 'group',
    Individual = 'individual',
    Trial = 'trial',
}

export enum ClassroomProvider {
    LingodaClassroom = 'lingoda_classroom',
    Zoom = 'zoom',
}

export type Company = {
    readonly __typename?: 'Company';
    readonly id: Scalars['String']['output'];
    readonly name: Scalars['String']['output'];
};

export type ContentBlock = {
    readonly __typename?: 'ContentBlock';
    readonly icon: Maybe<Icon>;
    readonly isStarred: Scalars['Boolean']['output'];
    readonly label: Maybe<Scalars['String']['output']>;
    readonly location: Location;
    readonly table: Maybe<Scalars['StringMatrix']['output']>;
    readonly text: Scalars['String']['output'];
};

export type Country = {
    readonly __typename?: 'Country';
    readonly code: Scalars['String']['output'];
    readonly commonName: Scalars['String']['output'];
};

export enum CountryCode {
    Ad = 'AD',
    Ae = 'AE',
    Af = 'AF',
    Ag = 'AG',
    Ai = 'AI',
    Al = 'AL',
    Am = 'AM',
    Ao = 'AO',
    Aq = 'AQ',
    Ar = 'AR',
    As = 'AS',
    At = 'AT',
    Au = 'AU',
    Aw = 'AW',
    Ax = 'AX',
    Az = 'AZ',
    Ba = 'BA',
    Bb = 'BB',
    Bd = 'BD',
    Be = 'BE',
    Bf = 'BF',
    Bg = 'BG',
    Bh = 'BH',
    Bi = 'BI',
    Bj = 'BJ',
    Bl = 'BL',
    Bm = 'BM',
    Bn = 'BN',
    Bo = 'BO',
    Bq = 'BQ',
    Br = 'BR',
    Bs = 'BS',
    Bt = 'BT',
    Bv = 'BV',
    Bw = 'BW',
    By = 'BY',
    Bz = 'BZ',
    Ca = 'CA',
    Cc = 'CC',
    Cd = 'CD',
    Cf = 'CF',
    Cg = 'CG',
    Ch = 'CH',
    Ci = 'CI',
    Ck = 'CK',
    Cl = 'CL',
    Cm = 'CM',
    Cn = 'CN',
    Co = 'CO',
    Cr = 'CR',
    Cu = 'CU',
    Cv = 'CV',
    Cw = 'CW',
    Cx = 'CX',
    Cy = 'CY',
    Cz = 'CZ',
    De = 'DE',
    Dj = 'DJ',
    Dk = 'DK',
    Dm = 'DM',
    Do = 'DO',
    Dz = 'DZ',
    Ec = 'EC',
    Ee = 'EE',
    Eg = 'EG',
    Eh = 'EH',
    Er = 'ER',
    Es = 'ES',
    Et = 'ET',
    Fi = 'FI',
    Fj = 'FJ',
    Fk = 'FK',
    Fm = 'FM',
    Fo = 'FO',
    Fr = 'FR',
    Ga = 'GA',
    Gb = 'GB',
    Gd = 'GD',
    Ge = 'GE',
    Gf = 'GF',
    Gg = 'GG',
    Gh = 'GH',
    Gi = 'GI',
    Gl = 'GL',
    Gm = 'GM',
    Gn = 'GN',
    Gp = 'GP',
    Gq = 'GQ',
    Gr = 'GR',
    Gs = 'GS',
    Gt = 'GT',
    Gu = 'GU',
    Gw = 'GW',
    Gy = 'GY',
    Hk = 'HK',
    Hm = 'HM',
    Hn = 'HN',
    Hr = 'HR',
    Ht = 'HT',
    Hu = 'HU',
    Id = 'ID',
    Ie = 'IE',
    Il = 'IL',
    Im = 'IM',
    In = 'IN',
    Io = 'IO',
    Iq = 'IQ',
    Ir = 'IR',
    Is = 'IS',
    It = 'IT',
    Je = 'JE',
    Jm = 'JM',
    Jo = 'JO',
    Jp = 'JP',
    Ke = 'KE',
    Kg = 'KG',
    Kh = 'KH',
    Ki = 'KI',
    Km = 'KM',
    Kn = 'KN',
    Kp = 'KP',
    Kr = 'KR',
    Kw = 'KW',
    Ky = 'KY',
    Kz = 'KZ',
    La = 'LA',
    Lb = 'LB',
    Lc = 'LC',
    Li = 'LI',
    Lk = 'LK',
    Lr = 'LR',
    Ls = 'LS',
    Lt = 'LT',
    Lu = 'LU',
    Lv = 'LV',
    Ly = 'LY',
    Ma = 'MA',
    Mc = 'MC',
    Md = 'MD',
    Me = 'ME',
    Mf = 'MF',
    Mg = 'MG',
    Mh = 'MH',
    Mk = 'MK',
    Ml = 'ML',
    Mm = 'MM',
    Mn = 'MN',
    Mo = 'MO',
    Mp = 'MP',
    Mq = 'MQ',
    Mr = 'MR',
    Ms = 'MS',
    Mt = 'MT',
    Mu = 'MU',
    Mv = 'MV',
    Mw = 'MW',
    Mx = 'MX',
    My = 'MY',
    Mz = 'MZ',
    Na = 'NA',
    Nc = 'NC',
    Ne = 'NE',
    Nf = 'NF',
    Ng = 'NG',
    Ni = 'NI',
    Nl = 'NL',
    No = 'NO',
    Np = 'NP',
    Nr = 'NR',
    Nu = 'NU',
    Nz = 'NZ',
    Om = 'OM',
    Pa = 'PA',
    Pe = 'PE',
    Pf = 'PF',
    Pg = 'PG',
    Ph = 'PH',
    Pk = 'PK',
    Pl = 'PL',
    Pm = 'PM',
    Pn = 'PN',
    Pr = 'PR',
    Ps = 'PS',
    Pt = 'PT',
    Pw = 'PW',
    Py = 'PY',
    Qa = 'QA',
    Re = 'RE',
    Ro = 'RO',
    Rs = 'RS',
    Ru = 'RU',
    Rw = 'RW',
    Sa = 'SA',
    Sb = 'SB',
    Sc = 'SC',
    Sd = 'SD',
    Se = 'SE',
    Sg = 'SG',
    Sh = 'SH',
    Si = 'SI',
    Sj = 'SJ',
    Sk = 'SK',
    Sl = 'SL',
    Sm = 'SM',
    Sn = 'SN',
    So = 'SO',
    Sr = 'SR',
    Ss = 'SS',
    St = 'ST',
    Sv = 'SV',
    Sx = 'SX',
    Sy = 'SY',
    Sz = 'SZ',
    Tc = 'TC',
    Td = 'TD',
    Tf = 'TF',
    Tg = 'TG',
    Th = 'TH',
    Tj = 'TJ',
    Tk = 'TK',
    Tl = 'TL',
    Tm = 'TM',
    Tn = 'TN',
    To = 'TO',
    Tr = 'TR',
    Tt = 'TT',
    Tv = 'TV',
    Tw = 'TW',
    Tz = 'TZ',
    Ua = 'UA',
    Ug = 'UG',
    Um = 'UM',
    Us = 'US',
    Uy = 'UY',
    Uz = 'UZ',
    Va = 'VA',
    Vc = 'VC',
    Ve = 'VE',
    Vg = 'VG',
    Vi = 'VI',
    Vn = 'VN',
    Vu = 'VU',
    Wf = 'WF',
    Ws = 'WS',
    Ye = 'YE',
    Yt = 'YT',
    Za = 'ZA',
    Zm = 'ZM',
    Zw = 'ZW',
}

export type Coupon = {
    readonly __typename?: 'Coupon';
    readonly code: Scalars['String']['output'];
    readonly id: Scalars['Int']['output'];
};

export type CouponEvaluationStatusResponse = {
    readonly __typename?: 'CouponEvaluationStatusResponse';
    readonly status: Maybe<Scalars['String']['output']>;
};

export type Course = {
    readonly __typename?: 'Course';
    readonly externalId: Scalars['String']['output'];
    readonly id: Scalars['Ulid']['output'];
    readonly type: CourseType;
};

export type CourseAssignError = {
    readonly __typename?: 'CourseAssignError';
    readonly class: Klass;
    readonly reason: CourseAssignErrorReason;
};

export enum CourseAssignErrorReason {
    CanNotTeachClass = 'can_not_teach_class',
    Unknown = 'unknown',
}

export type CourseAssignOverlap = {
    readonly __typename?: 'CourseAssignOverlap';
    readonly overlappedScheduledClass: Klass;
    readonly overlappingCourseClass: Klass;
};

export type CourseAssignResult = {
    readonly __typename?: 'CourseAssignResult';
    readonly bookedClasses: ReadonlyArray<Klass>;
    readonly errors: ReadonlyArray<CourseAssignError>;
    readonly overriddenClasses: ReadonlyArray<CourseAssignOverlap>;
};

export type CourseDayAndHour = {
    readonly __typename?: 'CourseDayAndHour';
    readonly dayOfWeek: DayOfWeek;
    readonly hour: Scalars['String']['output'];
};

export type CourseHoliday = {
    readonly __typename?: 'CourseHoliday';
    readonly endDate: Scalars['DateTime']['output'];
    readonly startDate: Scalars['DateTime']['output'];
};

export type CourseInput = {
    readonly externalId: Scalars['String']['input'];
    readonly type: Scalars['String']['input'];
};

export enum CourseType {
    Corporate = 'corporate',
    Doctors = 'doctors',
    LearningTeams = 'learning_teams',
    Match = 'match',
    Midwifery = 'midwifery',
    Nursing = 'nursing',
    OtaAta = 'ota_ata',
}

export type CreateAdminResponse = {
    readonly __typename?: 'CreateAdminResponse';
    readonly admin: TestAdmin;
};

export type CreateTeacherResponse = {
    readonly __typename?: 'CreateTeacherResponse';
    readonly classes: ReadonlyArray<Klass>;
    readonly invoices: ReadonlyArray<TeacherInvoice>;
    readonly teacher: TestTeacher;
};

export type CreateTestStudentResponse = {
    readonly __typename?: 'CreateTestStudentResponse';
    readonly appointments: Maybe<ReadonlyArray<Maybe<TestAppointment>>>;
    readonly credits: Maybe<ReadonlyArray<Maybe<TestCredit>>>;
    readonly student: TestStudent;
    readonly subscriptions: Maybe<ReadonlyArray<Maybe<TestSubscription>>>;
};

export type CumulativeVocabWord = {
    readonly __typename?: 'CumulativeVocabWord';
    readonly audioUrls: Maybe<VocabularyItemAudio>;
    readonly id: Scalars['String']['output'];
    readonly stage: Maybe<Scalars['Int']['output']>;
    readonly status: WordStatus;
    readonly target: Scalars['String']['output'];
    readonly translation: Scalars['String']['output'];
};

export enum Currency {
    Brl = 'BRL',
    Chf = 'CHF',
    Eur = 'EUR',
    Gbp = 'GBP',
    Pln = 'PLN',
    Try = 'TRY',
    Usd = 'USD',
}

export type Curriculum = {
    readonly __typename?: 'Curriculum';
    readonly default: Scalars['Boolean']['output'];
    readonly id: Scalars['Int']['output'];
    readonly name: Scalars['String']['output'];
};

export type DateInterval = {
    readonly __typename?: 'DateInterval';
    readonly days: Scalars['Int']['output'];
    readonly forHumans: Scalars['String']['output'];
    readonly hours: Scalars['Int']['output'];
    readonly microseconds: Scalars['Int']['output'];
    readonly minutes: Scalars['Int']['output'];
    readonly months: Scalars['Int']['output'];
    readonly seconds: Scalars['Int']['output'];
    readonly weeks: Scalars['Int']['output'];
    readonly years: Scalars['Int']['output'];
};

export enum DayOfWeek {
    Friday = 'friday',
    Monday = 'monday',
    Saturday = 'saturday',
    Sunday = 'sunday',
    Thursday = 'thursday',
    Tuesday = 'tuesday',
    Wednesday = 'wednesday',
}

export enum Deck {
    Completed = 'completed',
    Upcoming = 'upcoming',
}

export type EmailNotifications = {
    readonly __typename?: 'EmailNotifications';
    readonly emailBookedClassConfirmation: Scalars['Boolean']['output'];
    readonly emailCancelledClassConfirmation: Scalars['Boolean']['output'];
    readonly emailClassReminder: Scalars['Boolean']['output'];
    readonly emailWeeklyReport: Scalars['Boolean']['output'];
};

export type EmailNotificationsInput = {
    readonly emailBookedClassConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
    readonly emailCancelledClassConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
    readonly emailClassReminder?: InputMaybe<Scalars['Boolean']['input']>;
    readonly emailWeeklyReport?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum EmojiType {
    Awesome = 'awesome',
    Bad = 'bad',
    Good = 'good',
    Horrible = 'horrible',
    Ok = 'ok',
}

export type Exercise = {
    readonly __typename?: 'Exercise';
    readonly id: Scalars['Ulid']['output'];
    readonly link: Maybe<Scalars['Url']['output']>;
    readonly score: Maybe<Scalars['Float']['output']>;
    readonly title: Scalars['String']['output'];
};

export type ExerciseScore = {
    readonly __typename?: 'ExerciseScore';
    readonly avgPercentage: Scalars['Float']['output'];
    readonly maxScore: Scalars['Int']['output'];
    readonly percentage: Scalars['Float']['output'];
    readonly score: Scalars['Float']['output'];
    readonly type: SessionExerciseType;
};

export enum ExerciseStatus {
    Completed = 'completed',
    Pending = 'pending',
}

export enum ExerciseType {
    Homework = 'homework',
    Quiz = 'quiz',
}

export type ExtraCreditPurchase = {
    readonly __typename?: 'ExtraCreditPurchase';
    readonly agreements: ReadonlyArray<Agreement>;
    readonly canUseCoupon: Scalars['Boolean']['output'];
    readonly chargeTotal: Price;
    readonly contentBlocks: ReadonlyArray<ContentBlock>;
    readonly coupons: ReadonlyArray<Coupon>;
    readonly discount: Price;
    readonly displayName: Scalars['String']['output'];
    readonly groupCredits: Scalars['Int']['output'];
    readonly id: Scalars['String']['output'];
    readonly isSubscription: Scalars['Boolean']['output'];
    readonly lastTransaction: Maybe<Transaction>;
    readonly privateCredits: Scalars['Int']['output'];
    /** TODO: This is here for BC, drop this */
    readonly productDescription: Maybe<Scalars['String']['output']>;
    readonly subtotal: Price;
    readonly total: Price;
    readonly type: PurchaseType;
    readonly validityInterval: DateInterval;
};

export enum Feature {
    HasAbilityToHaveOpenDiscussionClass = 'hasAbilityToHaveOpenDiscussionClass',
    HasAvailableToBookClasses = 'hasAvailableToBookClasses',
    HasBeenOnboarded = 'hasBeenOnboarded',
    HasBookedCustomClass = 'hasBookedCustomClass',
    HasBookedOrientationClass = 'hasBookedOrientationClass',
    HasCalendarConnected = 'hasCalendarConnected',
    HasCalendarConnectionExpired = 'hasCalendarConnectionExpired',
    HasCancelledClass = 'hasCancelledClass',
    HasCancelledClassByTeacher = 'hasCancelledClassByTeacher',
    HasCancelledClassOnShortNotice = 'hasCancelledClassOnShortNotice',
    HasClass3DaysLater = 'hasClass3DaysLater',
    HasClassMoreThanWeekLater = 'hasClassMoreThanWeekLater',
    HasClassStartingIn3Minutes = 'hasClassStartingIn3Minutes',
    HasClassStartingIn10Minutes = 'hasClassStartingIn10Minutes',
    HasClassStartingInTenMinutes = 'hasClassStartingInTenMinutes',
    HasClassStartingInThreeMinutes = 'hasClassStartingInThreeMinutes',
    HasClassStartingNow = 'hasClassStartingNow',
    HasClassesWithAndWithoutTeacher = 'hasClassesWithAndWithoutTeacher',
    HasComingClassIn2Hours = 'hasComingClassIn2Hours',
    HasComingClassIn5Hours = 'hasComingClassIn5Hours',
    HasComingClassInMoreThan24Hours = 'hasComingClassInMoreThan24Hours',
    HasCommittableClasses = 'hasCommittableClasses',
    HasCompletedClass = 'hasCompletedClass',
    HasCompletedClassWithFeedback = 'hasCompletedClassWithFeedback',
    HasCompletedModule = 'hasCompletedModule',
    HasConsentedToMarketingCommunication = 'hasConsentedToMarketingCommunication',
    HasDisabledOrientationBanner = 'hasDisabledOrientationBanner',
    HasExpiredSubscription = 'hasExpiredSubscription',
    HasFourAttendancesPerWeekGoal = 'hasFourAttendancesPerWeekGoal',
    HasFullyCompletedNextLevel = 'hasFullyCompletedNextLevel',
    HasFutureClass = 'hasFutureClass',
    HasFuturePrivateClass = 'hasFuturePrivateClass',
    HasGeneratedInvoice = 'hasGeneratedInvoice',
    HasGroupCredits = 'hasGroupCredits',
    HasGroupSubscriptionWithTrial = 'hasGroupSubscriptionWithTrial',
    HasGroupSubscriptionWithTrialNew = 'hasGroupSubscriptionWithTrialNew',
    HasMissedClass = 'hasMissedClass',
    HasMultipleCommittableClassesAtSameTime = 'hasMultipleCommittableClassesAtSameTime',
    HasNextSubscription = 'hasNextSubscription',
    HasPartiallyCompletedCurrentLevel = 'hasPartiallyCompletedCurrentLevel',
    HasPartiallyCompletedEachChapter = 'hasPartiallyCompletedEachChapter',
    HasParticipationInOngoingSprint = 'hasParticipationInOngoingSprint',
    HasParticipationInUpcomingSprint = 'hasParticipationInUpcomingSprint',
    HasPastClass = 'hasPastClass',
    HasPastClasses = 'hasPastClasses',
    HasPastPrivateClasses = 'hasPastPrivateClasses',
    HasPauseScheduledSubscription = 'hasPauseScheduledSubscription',
    HasPausedSubscription = 'hasPausedSubscription',
    HasPaymentMethods = 'hasPaymentMethods',
    HasPendingAgreement = 'hasPendingAgreement',
    HasPendingClassReview = 'hasPendingClassReview',
    HasPendingClassReviewRightNow = 'hasPendingClassReviewRightNow',
    HasPendingInvoice = 'hasPendingInvoice',
    HasPrivateClassStartingNow = 'hasPrivateClassStartingNow',
    HasPrivateCredits = 'hasPrivateCredits',
    HasPrivateSubscriptionWithTrial = 'hasPrivateSubscriptionWithTrial',
    HasPrivateSubscriptionWithTrialNew = 'hasPrivateSubscriptionWithTrialNew',
    HasRecurringPrivateSubscription = 'hasRecurringPrivateSubscription',
    HasRecurringSubscription = 'hasRecurringSubscription',
    HasSocialLogin = 'hasSocialLogin',
    HasStartedClassLessThanFifteenMinutes = 'hasStartedClassLessThanFifteenMinutes',
    HasStartedClassMoreThanFifteenMinutes = 'hasStartedClassMoreThanFifteenMinutes',
    HasStudentMissedClass = 'hasStudentMissedClass',
    HasStudentPassedCustomClass = 'hasStudentPassedCustomClass',
    HasSubscribeLingodaBanner = 'hasSubscribeLingodaBanner',
    HasSubscribeLingodaPopup = 'hasSubscribeLingodaPopup',
    HasSuperAdmin = 'hasSuperAdmin',
    HasUserCreatedEightMonthsAgo = 'hasUserCreatedEightMonthsAgo',
    HasUserCreatedFourDaysAgo = 'hasUserCreatedFourDaysAgo',
    HasUserCreatedTweentyFiveDaysAgo = 'hasUserCreatedTweentyFiveDaysAgo',
    HasZeroCredits = 'hasZeroCredits',
    InTheFirstMonthOfTwoMonthsSprint = 'inTheFirstMonthOfTwoMonthsSprint',
    InTheMiddleOfOneMonthSprint = 'inTheMiddleOfOneMonthSprint',
    InTheSecondMonthOfTwoMonthsSprint = 'inTheSecondMonthOfTwoMonthsSprint',
    InTheSecondMonthOfTwoMonthsSprintExtended = 'inTheSecondMonthOfTwoMonthsSprintExtended',
}

export enum FeatureFlag {
    AlwaysOnDiscount = 'always_on_discount',
    BookClassBeforeTrialStart = 'book_class_before_trial_start',
    BookClassesWithCurriculum = 'book_classes_with_curriculum',
    ClassLogEmailBraze = 'class_log_email_braze',
    DeprecatedPaypalHpp = 'deprecated_paypal_hpp',
    EasyGenerator = 'easy_generator',
    EmailVerification = 'email_verification',
    EnablePaypal = 'enable_paypal',
    EnablePaypalEcs = 'enable_paypal_ecs',
    FrictionlessFunnel = 'frictionless_funnel',
    GuestCheckout = 'guest_checkout',
    HideFlashNotifications = 'hide_flash_notifications',
    LeadConversionFlow = 'lead_conversion_flow',
    LivePractice = 'live_practice',
    MixedCredits = 'mixed_credits',
    MyCourseUpgrade = 'my_course_upgrade',
    NewClassroom = 'new_classroom',
    NewLearnerGuidance = 'new_learner_guidance',
    NewLimitOfClasses = 'new_limit_of_classes',
    NewProductsAaTest = 'new_products_aa_test',
    NewRegistrationPage = 'new_registration_page',
    NudgeForPlanning = 'nudge_for_planning',
    OldPracticeDisabled = 'old_practice_disabled',
    OnboardingChecklist = 'onboarding_checklist',
    OnboardingChecklistRevamp = 'onboarding_checklist_revamp',
    PaypalTest = 'paypal_test',
    PersonalizedFeedbackSurvey = 'personalized_feedback_survey',
    PracticeSequence = 'practice_sequence',
    PracticeSet = 'practice_set',
    PresentationMode = 'presentation_mode',
    QuizEngine = 'quiz_engine',
    QuizNewFinishScreens = 'quiz_new_finish_screens',
    SelfLearningLimitAccess = 'self_learning_limit_access',
    ShareCertificate = 'share_certificate',
    ShorterClassLeadTimeForFrench = 'shorter_class_lead_time_for_french',
    SkipCreditCardForTrialDirectPurchase = 'skip_credit_card_for_trial_direct_purchase',
    SpacedRepetition = 'spaced_repetition',
    SprintPrePayment = 'sprint_pre_payment',
    StudentProfile = 'student_profile',
    TempDebuggingHelperExperiment = 'temp_debugging_helper_experiment',
    UnlockedLingobites = 'unlocked_lingobites',
    VocabTrainerContentErrors = 'vocab_trainer_content_errors',
    VocabularyGoalSetting = 'vocabulary_goal_setting',
    WordByWordTranslations = 'word_by_word_translations',
    WritingPractice = 'writing_practice',
}

export type FeedbackCountType = {
    readonly __typename?: 'FeedbackCountType';
    readonly name: Scalars['String']['output'];
    readonly value: Scalars['String']['output'];
};

export type Flashcard = {
    readonly __typename?: 'Flashcard';
    readonly item: VocabularyItem;
    readonly knownStatus: KnownStatus;
};

export type FlashcardFeedbackInput = {
    readonly answersIds: ReadonlyArray<Scalars['String']['input']>;
    readonly feedback?: InputMaybe<Scalars['String']['input']>;
    readonly vocabularyItemId: Scalars['String']['input'];
};

export type FlashcardSpacedRepetition = {
    readonly __typename?: 'FlashcardSpacedRepetition';
    readonly id: Scalars['Ulid']['output'];
    readonly remembered: Scalars['Boolean']['output'];
    readonly showAfter: Scalars['DateTime']['output'];
    readonly step: Scalars['Int']['output'];
};

export type FlexGeoPricedVariantCreditsResponseEntry = {
    readonly __typename?: 'FlexGeoPricedVariantCreditsResponseEntry';
    readonly group: Scalars['Int']['output'];
    readonly private: Scalars['Int']['output'];
};

export type FlexGeoPricedVariantResponseEntry = {
    readonly __typename?: 'FlexGeoPricedVariantResponseEntry';
    readonly class_type: Maybe<Scalars['String']['output']>;
    readonly coupon_code: Maybe<Scalars['String']['output']>;
    readonly coupon_discount_percentage: Maybe<Scalars['String']['output']>;
    readonly credits: FlexGeoPricedVariantCreditsResponseEntry;
    readonly currency: Maybe<Scalars['String']['output']>;
    readonly has_promotion: Maybe<Scalars['Boolean']['output']>;
    readonly language: Maybe<Scalars['String']['output']>;
    readonly package_id: Maybe<Scalars['String']['output']>;
    readonly package_name: Maybe<Scalars['String']['output']>;
    readonly price_per_class: Maybe<Scalars['Float']['output']>;
    readonly price_per_class_with_promotion: Maybe<Scalars['Float']['output']>;
    readonly promotion_credits: PromotionCreditAward;
    readonly save_percentage: Maybe<Scalars['Float']['output']>;
    readonly total_price: Maybe<Scalars['Float']['output']>;
    readonly total_price_with_promotion: Maybe<Scalars['Float']['output']>;
};

export enum GenderType {
    Feminine = 'feminine',
    Masculine = 'masculine',
    MasculineFeminine = 'masculine_feminine',
    Neutral = 'neutral',
}

export type GrammarItem = {
    readonly __typename?: 'GrammarItem';
    readonly content: Maybe<Scalars['String']['output']>;
    readonly id: Scalars['String']['output'];
    readonly isCompleted: Scalars['Boolean']['output'];
    readonly lesson: Maybe<Lesson>;
    readonly subtitle: Maybe<Scalars['String']['output']>;
    readonly title: Scalars['String']['output'];
    readonly translations: ReadonlyArray<Maybe<GrammarItemTrans>>;
};

export type GrammarItemTrans = {
    readonly __typename?: 'GrammarItemTrans';
    readonly content: Maybe<Scalars['String']['output']>;
    readonly locale: Scalars['String']['output'];
    readonly subtitle: Maybe<Scalars['String']['output']>;
    readonly title: Scalars['String']['output'];
};

export enum GrammarStatus {
    Completed = 'Completed',
    Uncompleted = 'Uncompleted',
}

export type GuestUserInput = {
    readonly desiredProduct?: InputMaybe<Scalars['String']['input']>;
    readonly email: Scalars['String']['input'];
    readonly googleAnalyticsId?: InputMaybe<Scalars['String']['input']>;
    readonly marketingCommunicationAccepted: Scalars['Boolean']['input'];
    readonly name: Scalars['String']['input'];
    readonly privacyPolicyAccepted: Scalars['Boolean']['input'];
    readonly reCaptcha: Scalars['String']['input'];
    readonly section: Scalars['String']['input'];
    readonly timeFormat?: InputMaybe<Scalars['String']['input']>;
};

export type H5PExercise = {
    readonly __typename?: 'H5PExercise';
    readonly id: Scalars['String']['output'];
    readonly score: Maybe<Scalars['Float']['output']>;
    readonly title: Scalars['String']['output'];
    readonly type: Maybe<ExerciseType>;
    readonly url: Scalars['String']['output'];
};

export enum Icon {
    Checkmark = 'CHECKMARK',
    Heart = 'HEART',
    PayInRates = 'PAY_IN_RATES',
    Pricetag = 'PRICETAG',
    SectionDe = 'SECTION_DE',
    SectionEn = 'SECTION_EN',
    SectionEs = 'SECTION_ES',
    SectionFr = 'SECTION_FR',
    SectionIt = 'SECTION_IT',
    StudyCalendar = 'STUDY_CALENDAR',
    Trial = 'TRIAL',
}

export type Image = {
    readonly __typename?: 'Image';
    readonly contentType: Scalars['String']['output'];
    readonly height: Maybe<Scalars['Int']['output']>;
    readonly id: Scalars['Int']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly urls: ImageUrl;
    readonly width: Maybe<Scalars['Int']['output']>;
};

export type ImageUrl = {
    readonly __typename?: 'ImageUrl';
    readonly medium: Scalars['String']['output'];
    readonly medium2x: Scalars['String']['output'];
    readonly original: Scalars['String']['output'];
    readonly thumbnail: Scalars['String']['output'];
    readonly thumbnail2x: Scalars['String']['output'];
    readonly thumbnail_large: Scalars['String']['output'];
    readonly thumbnail_large2x: Scalars['String']['output'];
};

export type ImportedStudentInput = {
    readonly amAppId?: InputMaybe<Scalars['String']['input']>;
    readonly availableAt?: InputMaybe<Scalars['String']['input']>;
    readonly company?: InputMaybe<Scalars['String']['input']>;
    readonly companyId?: InputMaybe<Scalars['String']['input']>;
    readonly contractStatus?: InputMaybe<Scalars['String']['input']>;
    readonly credits?: InputMaybe<Scalars['Int']['input']>;
    readonly curriculum?: InputMaybe<Scalars['String']['input']>;
    readonly email: Scalars['String']['input'];
    readonly expireAt?: InputMaybe<Scalars['String']['input']>;
    readonly firstName: Scalars['String']['input'];
    readonly lastName: Scalars['String']['input'];
    readonly locale?: InputMaybe<Scalars['String']['input']>;
    readonly module?: InputMaybe<Scalars['String']['input']>;
    readonly notifications?: InputMaybe<Scalars['String']['input']>;
    readonly section: Scalars['String']['input'];
    readonly studentUserId?: InputMaybe<Scalars['Int']['input']>;
    readonly type?: InputMaybe<Scalars['String']['input']>;
};

export type Klass = {
    readonly __typename?: 'Klass';
    readonly activeAppointments: ReadonlyArray<Appointment>;
    readonly annotatedPresentation: Maybe<Scalars['String']['output']>;
    readonly appointment: Maybe<Appointment>;
    readonly attendedPrice: Price;
    readonly availableSeats: Scalars['Int']['output'];
    readonly availableWithoutPayment: Scalars['Boolean']['output'];
    readonly businessModelType: BusinessModelType;
    readonly classLog: Maybe<ClassLog>;
    readonly classLogEnabled: Scalars['Boolean']['output'];
    readonly classNote: Maybe<Scalars['String']['output']>;
    readonly confirmedAt: Maybe<Scalars['DateTime']['output']>;
    readonly courseId: Maybe<Scalars['String']['output']>;
    readonly duration: Scalars['Int']['output'];
    readonly endDate: Scalars['DateTime']['output'];
    readonly isExisting: Scalars['Boolean']['output'];
    readonly isForNewClassroom: Scalars['Boolean']['output'];
    readonly isLanguageClass: Scalars['Boolean']['output'];
    readonly isPresentationMode: Scalars['Boolean']['output'];
    readonly isPriceVisible: Scalars['Boolean']['output'];
    readonly labels: ReadonlyArray<KlassLabel>;
    readonly learningUnit: Maybe<LearningUnit>;
    readonly lesson: Maybe<Lesson>;
    readonly maxSeats: Scalars['Int']['output'];
    readonly missedPrice: Price;
    readonly module: Module;
    readonly prepareClassInfo: PrepareClassInfo;
    readonly previousClassLogsUrl: Maybe<Scalars['String']['output']>;
    readonly remuneration: Maybe<Remuneration>;
    readonly sourcePresentation: Maybe<Scalars['String']['output']>;
    readonly startDate: Scalars['DateTime']['output'];
    readonly status: ClassStatus;
    readonly statusByTeacher: Maybe<ClassStatusByTeacherType>;
    readonly students: ReadonlyArray<Student>;
    /** Get teacher */
    readonly teacher: Maybe<Teacher>;
    readonly teacherFeedback: Maybe<ClassTeacherFeedback>;
    readonly total: Maybe<Price>;
    readonly type: ClassType;
    readonly uid: Scalars['String']['output'];
};

export enum KlassLabel {
    Adg = 'Adg',
    Bonus = 'Bonus',
    Group = 'Group',
    MissedClass = 'MissedClass',
    NoShow = 'NoShow',
    Private = 'Private',
    TechIssue = 'TechIssue',
}

export enum KnownStatus {
    Known = 'KNOWN',
    NoAnswer = 'NO_ANSWER',
    Unknown = 'UNKNOWN',
}

export type KysFeedback = {
    readonly __typename?: 'KysFeedback';
    readonly id: Scalars['Ulid']['output'];
    readonly isUseful: Scalars['Boolean']['output'];
};

export type Language = {
    readonly __typename?: 'Language';
    readonly fluency: LanguageFluency;
    readonly language: SpokenLanguage;
};

export type LanguageAttendance = {
    readonly __typename?: 'LanguageAttendance';
    readonly attended: Scalars['Int']['output'];
    readonly learningUnitName: Scalars['String']['output'];
    readonly total: Scalars['Int']['output'];
};

export type LanguageAttendanceInput = {
    readonly endDate: Scalars['DateTime']['input'];
    readonly moduleCode: Scalars['String']['input'];
    readonly startDate: Scalars['DateTime']['input'];
    readonly userIds?: InputMaybe<ReadonlyArray<Scalars['Int']['input']>>;
};

export enum LanguageFluency {
    A1 = 'A1',
    A2 = 'A2',
    B1 = 'B1',
    B2 = 'B2',
    C1 = 'C1',
    C2 = 'C2',
    Native = 'Native',
}

export type LanguageInput = {
    readonly fluency: LanguageFluency;
    readonly language: SpokenLanguage;
};

export type LearningMaterialFeedbackInput = {
    readonly learningMaterialCustomFeedback?: InputMaybe<Scalars['String']['input']>;
    readonly learningMaterialFeedbackAnswers?: ReadonlyArray<Scalars['String']['input']>;
    readonly learningMaterialRating?: InputMaybe<Scalars['Float']['input']>;
};

export type LearningPlanInput = {
    readonly moduleCode: Scalars['String']['input'];
    readonly userIds?: InputMaybe<ReadonlyArray<Scalars['Int']['input']>>;
};

export type LearningPlanLearningUnit = {
    readonly __typename?: 'LearningPlanLearningUnit';
    readonly dueDate: Scalars['DateTime']['output'];
    readonly name: Scalars['String']['output'];
};

export type LearningProgress = {
    readonly __typename?: 'LearningProgress';
    readonly known: Scalars['Int']['output'];
    readonly reviewed: Scalars['Int']['output'];
    readonly reviewing: Scalars['Int']['output'];
};

export type LearningTeamCourse = {
    readonly __typename?: 'LearningTeamCourse';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly endDate: Maybe<Scalars['DateTime']['output']>;
    readonly finalCutOffDate: Scalars['DateTime']['output'];
    readonly id: Scalars['Ulid']['output'];
    readonly modules: ReadonlyArray<Module>;
    readonly name: Scalars['String']['output'];
    readonly package: LearningTeamPackage;
    readonly publicationDate: Maybe<Scalars['DateTime']['output']>;
    readonly section: Section;
    readonly startDate: Scalars['DateTime']['output'];
    readonly timeSlots: ReadonlyArray<LearningTeamTimeSlot>;
    readonly timezone: Timezone;
    readonly type: Scalars['String']['output'];
    readonly withExam: Scalars['Boolean']['output'];
};

export type LearningTeamCourseGeoPricedVariantResponseEntry = {
    readonly __typename?: 'LearningTeamCourseGeoPricedVariantResponseEntry';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly curriculum: Curriculum;
    readonly endDate: Maybe<Scalars['DateTime']['output']>;
    readonly finalCutOffDate: Scalars['DateTime']['output'];
    readonly id: Scalars['Ulid']['output'];
    readonly modules: ReadonlyArray<LearningTeamPackageGeoPricedVariantModule>;
    readonly name: Scalars['String']['output'];
    readonly package: LearningTeamPackageGeoPricedVariantResponseEntry;
    readonly publicationDate: Maybe<Scalars['DateTime']['output']>;
    readonly section: Section;
    readonly startDate: Scalars['DateTime']['output'];
    readonly timeSlots: ReadonlyArray<LearningTeamTimeSlot>;
    readonly timezone: Timezone;
    readonly type: Scalars['String']['output'];
    readonly withExam: Scalars['Boolean']['output'];
};

export type LearningTeamCourseInput = {
    readonly courseType?: InputMaybe<Scalars['String']['input']>;
};

export type LearningTeamPackage = {
    readonly __typename?: 'LearningTeamPackage';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly curriculum: Curriculum;
    readonly duration: Scalars['Int']['output'];
    readonly id: Scalars['Ulid']['output'];
    readonly packagePrices: ReadonlyArray<LearningTeamPackagePrice>;
    readonly type: Scalars['String']['output'];
};

export type LearningTeamPackageGeoPricedVariantModule = {
    readonly __typename?: 'LearningTeamPackageGeoPricedVariantModule';
    readonly cefrLevelName: Maybe<Scalars['String']['output']>;
    readonly id: Scalars['Int']['output'];
    readonly internalName: Scalars['String']['output'];
};

export type LearningTeamPackageGeoPricedVariantResponseEntry = {
    readonly __typename?: 'LearningTeamPackageGeoPricedVariantResponseEntry';
    readonly createdAt: Scalars['DateTime']['output'];
    readonly curriculum: Curriculum;
    readonly duration: Scalars['Int']['output'];
    readonly id: Scalars['Ulid']['output'];
    readonly packagePrices: LearningTeamPackagePriceGeoPricedVariantResponseEntry;
    readonly type: Scalars['String']['output'];
};

export type LearningTeamPackagePrice = {
    readonly __typename?: 'LearningTeamPackagePrice';
    readonly price: Price;
    readonly pricePerClass: Price;
    readonly priceWithoutDiscount: Price;
    readonly section: Section;
};

export type LearningTeamPackagePriceGeoPricedVariantResponseEntry = {
    readonly __typename?: 'LearningTeamPackagePriceGeoPricedVariantResponseEntry';
    readonly discountedPricePerMonth: Scalars['Int']['output'];
    readonly pricePerClass: Scalars['Int']['output'];
    readonly regularPricePerMonth: Scalars['Int']['output'];
    readonly totalDiscountedPrice: Scalars['Int']['output'];
};

export enum LearningTeamStatus {
    Preparing = 'preparing',
    Ready = 'ready',
}

export type LearningTeamTimeSlot = {
    readonly __typename?: 'LearningTeamTimeSlot';
    readonly dayOfWeek: DayOfWeek;
    readonly formattedStartHour: Scalars['String']['output'];
    readonly startHour: Scalars['Int']['output'];
};

export type LearningUnit = {
    readonly __typename?: 'LearningUnit';
    readonly chapter: Scalars['Int']['output'];
    readonly color: Scalars['String']['output'];
    readonly exercise: Maybe<Exercise>;
    readonly icon: Maybe<Scalars['String']['output']>;
    readonly id: Scalars['Int']['output'];
    readonly lastBookedDate: Maybe<Scalars['DateTime']['output']>;
    readonly lessons: ReadonlyArray<Lesson>;
    readonly module: Maybe<Module>;
    readonly name: Scalars['String']['output'];
    readonly vocabularyItems: ReadonlyArray<VocabularyItem>;
};

export type LegacyPurchase = {
    readonly __typename?: 'LegacyPurchase';
    readonly agreements: ReadonlyArray<Agreement>;
    readonly canUseCoupon: Scalars['Boolean']['output'];
    readonly chargeTotal: Price;
    readonly contentBlocks: ReadonlyArray<ContentBlock>;
    readonly coupons: ReadonlyArray<Coupon>;
    readonly curriculum: Maybe<Curriculum>;
    readonly discount: Price;
    readonly displayName: Scalars['String']['output'];
    readonly groupCredits: Scalars['Int']['output'];
    readonly id: Scalars['String']['output'];
    readonly isSubscription: Scalars['Boolean']['output'];
    readonly lastTransaction: Maybe<Transaction>;
    readonly newSection: Maybe<Section>;
    readonly privateCredits: Scalars['Int']['output'];
    /** @deprecated Will be replaced with @see \App\Store\Content */
    readonly productDescription: Maybe<Scalars['String']['output']>;
    readonly recurrenceInterval: Maybe<DateInterval>;
    readonly rollover: Maybe<LegacyRolloverData>;
    readonly subtotal: Price;
    readonly total: Price;
    readonly type: PurchaseType;
    readonly validityInterval: DateInterval;
    readonly withTrial: Scalars['Boolean']['output'];
};

export type LegacyRolloverData = {
    readonly __typename?: 'LegacyRolloverData';
    readonly productId: Scalars['Int']['output'];
    readonly recurrenceInterval: Maybe<DateInterval>;
    readonly subTotal: Price;
    readonly total: Price;
    readonly trialInterval: DateInterval;
};

export type Lesson = {
    readonly __typename?: 'Lesson';
    readonly availableWithoutPayment: Scalars['Boolean']['output'];
    readonly completedCurrentVersion: Scalars['Boolean']['output'];
    readonly description: Maybe<Scalars['String']['output']>;
    readonly grammarItems: ReadonlyArray<GrammarItem>;
    readonly id: Scalars['Int']['output'];
    readonly label: Scalars['String']['output'];
    readonly learningOutcome: Maybe<Scalars['String']['output']>;
    readonly learningOutcomes: Maybe<ReadonlyArray<Scalars['String']['output']>>;
    readonly lingobitesAvailable: Scalars['Boolean']['output'];
    readonly module: Module;
    readonly practiceSequenceScore: Maybe<Scalars['Float']['output']>;
    readonly presentation: Maybe<Media>;
    readonly presentationNumber: Maybe<Scalars['String']['output']>;
    readonly presentationUrl: Maybe<Scalars['Url']['output']>;
    readonly section: Section;
    readonly soonestBookedDate: Maybe<Scalars['DateTime']['output']>;
    readonly studentLearningUnit: Maybe<LearningUnit>;
    readonly studentStatus: StudentStatus;
    readonly title: Maybe<Scalars['String']['output']>;
    readonly topics: Maybe<Scalars['String']['output']>;
    readonly translations: ReadonlyArray<Maybe<LessonTrans>>;
    readonly type: Maybe<LessonType>;
    readonly units: ReadonlyArray<Maybe<LearningUnit>>;
    readonly vocabularyItems: ReadonlyArray<VocabularyItem>;
};

export type LessonBasedExerciseFeedbackInput = {
    readonly answersIds: ReadonlyArray<Scalars['String']['input']>;
    readonly exerciseId: Scalars['String']['input'];
    readonly feedback?: InputMaybe<Scalars['String']['input']>;
    readonly lessonId: Scalars['Int']['input'];
};

export type LessonStatusInput = {
    readonly lessonId: Scalars['Int']['input'];
    readonly status: StudentLessonStatus;
};

export type LessonTrans = {
    readonly __typename?: 'LessonTrans';
    readonly description: Maybe<Scalars['String']['output']>;
    readonly learningOutcome: Maybe<Scalars['String']['output']>;
    readonly learningOutcomes: Maybe<ReadonlyArray<Scalars['String']['output']>>;
    readonly locale: Scalars['String']['output'];
    readonly title: Maybe<Scalars['String']['output']>;
};

export enum LessonType {
    ChapterCheck = 'chapter_check',
    Communication = 'communication',
    Conversation = 'conversation',
    Custom = 'custom',
    Grammar = 'grammar',
    LevelCheck = 'level_check',
    Orientation = 'orientation',
    Reading = 'reading',
    Vocabulary = 'vocabulary',
    Writing = 'writing',
}

export type LiveClassAttendance = {
    readonly __typename?: 'LiveClassAttendance';
    readonly attended: Scalars['Boolean']['output'];
    readonly classEndDate: Scalars['DateTime']['output'];
    readonly classId: Scalars['Int']['output'];
    readonly classStartDate: Scalars['DateTime']['output'];
    readonly lessonId: Scalars['Int']['output'];
};

export type LiveClassAttendanceInput = {
    readonly endDate: Scalars['DateTime']['input'];
    readonly lessonIds?: InputMaybe<ReadonlyArray<Scalars['Int']['input']>>;
    readonly startDate: Scalars['DateTime']['input'];
    readonly userIds?: InputMaybe<ReadonlyArray<Scalars['Int']['input']>>;
};

export enum Location {
    BillingAndCancellation = 'BILLING_AND_CANCELLATION',
    CheckoutButton = 'CHECKOUT_BUTTON',
    CheckoutTermsInformation = 'CHECKOUT_TERMS_INFORMATION',
    CheckoutTestCharge = 'CHECKOUT_TEST_CHARGE',
    CheckoutWarnings = 'CHECKOUT_WARNINGS',
    ClassAMonth = 'CLASS_A_MONTH',
    CreditsExpire = 'CREDITS_EXPIRE',
    CreditsRenew = 'CREDITS_RENEW',
    Main = 'MAIN',
    PackageDetail = 'PACKAGE_DETAIL',
    PackageDetailOld = 'PACKAGE_DETAIL_OLD',
    PackagePaymentDetails = 'PACKAGE_PAYMENT_DETAILS',
    PackageTitle = 'PACKAGE_TITLE',
    PricePerClassShort = 'PRICE_PER_CLASS_SHORT',
    SavePercentage = 'SAVE_PERCENTAGE',
    SectionTitle = 'SECTION_TITLE',
    SubscriptionDuration = 'SUBSCRIPTION_DURATION',
    SubscriptionMainTitle = 'SUBSCRIPTION_MAIN_TITLE',
    SubscriptionName = 'SUBSCRIPTION_NAME',
    SubscriptionNextPaymentDate = 'SUBSCRIPTION_NEXT_PAYMENT_DATE',
    SubscriptionPausePeriod = 'SUBSCRIPTION_PAUSE_PERIOD',
    SubscriptionPrice = 'SUBSCRIPTION_PRICE',
    SubscriptionStatus = 'SUBSCRIPTION_STATUS',
    SubscriptionTransactionsTotal = 'SUBSCRIPTION_TRANSACTIONS_TOTAL',
    SubscriptionWillActivateAt = 'SUBSCRIPTION_WILL_ACTIVATE_AT',
    SummaryContent = 'SUMMARY_CONTENT',
    SummaryDiscount = 'SUMMARY_DISCOUNT',
    SummaryDiscountNote = 'SUMMARY_DISCOUNT_NOTE',
    SummaryPrice = 'SUMMARY_PRICE',
    SummaryTitle = 'SUMMARY_TITLE',
    SummaryTrialDetails = 'SUMMARY_TRIAL_DETAILS',
    TopBadge = 'TOP_BADGE',
    TotalPriceShort = 'TOTAL_PRICE_SHORT',
}

export type MarkWidgetAsDisplayedResultType = {
    readonly __typename?: 'MarkWidgetAsDisplayedResultType';
    readonly status: Scalars['Boolean']['output'];
};

export type Media = {
    readonly __typename?: 'Media';
    readonly contentType: Scalars['String']['output'];
    readonly id: Scalars['Int']['output'];
    readonly updatedAt: Scalars['DateTime']['output'];
    readonly urls: MediaUrl;
};

export type MediaUrl = {
    readonly __typename?: 'MediaUrl';
    readonly original: Scalars['String']['output'];
};

export enum MissedStatusReason {
    Emergency = 'emergency',
    Forgot = 'forgot',
    LateToClass = 'late_to_class',
    Other = 'other',
    TechnicalIssues = 'technical_issues',
}

export type Module = {
    readonly __typename?: 'Module';
    readonly cefrLevelName: Maybe<Scalars['String']['output']>;
    readonly code: Scalars['String']['output'];
    readonly completedClasses: Scalars['Int']['output'];
    readonly description: Scalars['String']['output'];
    readonly id: Scalars['Int']['output'];
    /** Return only learning units with active lesson(s) for current user section */
    readonly learningUnits: ReadonlyArray<LearningUnit>;
    readonly longDescription: Scalars['String']['output'];
    readonly longName: Scalars['String']['output'];
    readonly name: Scalars['String']['output'];
    readonly title: Scalars['String']['output'];
    readonly totalClasses: Scalars['Int']['output'];
};

export type ModuleAttendedType = {
    readonly __typename?: 'ModuleAttendedType';
    readonly name: Scalars['String']['output'];
};

export type Mutation = {
    readonly __typename?: 'Mutation';
    readonly answerBookingExperienceSurvey: ClassBookingExperienceSurvey;
    readonly answerBookingExperienceSurveyDetails: ClassBookingExperienceSurvey;
    readonly answerChallenge: Maybe<Scalars['Boolean']['output']>;
    readonly appealPenalty: Scalars['Boolean']['output'];
    readonly bookClass: Appointment;
    readonly calendarDisconnect: Scalars['Boolean']['output'];
    readonly cancelClass: Klass;
    readonly cancelCurrentSubscriptionDelay: Scalars['Boolean']['output'];
    readonly cancelNextSubscriptionDelayedStart: Scalars['Boolean']['output'];
    readonly cancelScheduledPause: Subscription;
    readonly cancelSprintRolloverWithQuestionnaire: Scalars['Boolean']['output'];
    readonly cancelSubscriptionWithFeedback: SubscriptionFeedback;
    readonly cancelTrial: Subscription;
    readonly changeAppointmentStatusByStudent: Appointment;
    readonly claimAttendanceAchievement: Achievement;
    readonly completeChecklistItems: ReadonlyArray<ChecklistItem>;
    readonly convertGroupCreditsIntoPrivate: Scalars['Boolean']['output'];
    readonly createAppleCreditPurchase: ExtraCreditPurchase;
    readonly createB2GCourse: Course;
    readonly createClassChatHistory: Scalars['Boolean']['output'];
    readonly createCompany: Company;
    readonly createDemoZoomSession: ZoomSession;
    readonly createExtraCreditPurchase: ExtraCreditPurchase;
    readonly createFreeTrialPurchase: Purchase;
    readonly createGuestUser: User;
    readonly createLegacyPurchase: LegacyPurchase;
    readonly createMissedClassFeedback: ClassFeedback;
    /** Will eventually replace product-based endpoint, for now just wrapping it */
    readonly createPurchase: Purchase;
    readonly createSpokenLanguage: Scalars['Boolean']['output'];
    readonly createTestAdmin: CreateAdminResponse;
    readonly createTestMercureMessage: Scalars['Boolean']['output'];
    readonly createTestStudent: CreateTestStudentResponse;
    readonly createTestStudentNotification: Scalars['Boolean']['output'];
    readonly createTestTeacher: CreateTeacherResponse;
    readonly createTestUsers: Scalars['Boolean']['output'];
    readonly createZoomSession: ZoomSession;
    readonly delayNextSubscriptionStartDate: Scalars['Boolean']['output'];
    readonly deleteAccount: Scalars['Boolean']['output'];
    readonly deleteLearningNeeds: Scalars['Boolean']['output'];
    readonly deleteNextSubscription: Scalars['Boolean']['output'];
    readonly deleteSpokenLanguage: Scalars['Boolean']['output'];
    readonly deleteWeeklyGoal: StudentGoal;
    readonly deleteWhyAreYouLearning: Scalars['Boolean']['output'];
    readonly dismissBookingExperienceSurvey: ClassBookingExperienceSurvey;
    readonly dismissStudentNotification: Scalars['Boolean']['output'];
    readonly flashcardSpacedRepetition: Maybe<FlashcardSpacedRepetition>;
    readonly iMissedClass: Scalars['Boolean']['output'];
    readonly importStudents: ReadonlyArray<User>;
    readonly joinChallenge: ActivityChallengeParticipant;
    readonly learningNeeds: Scalars['Boolean']['output'];
    readonly loadSchedulerData: Scalars['Boolean']['output'];
    readonly markWidgetAsDisplayed: MarkWidgetAsDisplayedResultType;
    readonly pauseTeaching: Teacher;
    readonly promoteGuestUser: User;
    readonly quizEngineReportResult: ReportResultPayload;
    readonly quizEngineReportResults: ReadonlyArray<ReportResultPayload>;
    readonly quizEngineResetResult: Scalars['Boolean']['output'];
    readonly quizReportVocabResult: Scalars['Boolean']['output'];
    readonly refreshRecommendedActivities: ReadonlyArray<Activity>;
    readonly removeAvatar: Scalars['Boolean']['output'];
    readonly removePauseTeaching: Teacher;
    readonly reportPracticeSequenceSession: Scalars['Boolean']['output'];
    readonly reportSession: Scalars['Boolean']['output'];
    readonly resetCumulativeFlashcardDeck: ReadonlyArray<VocabularyItem>;
    readonly resetFlashcardsStatus: Scalars['Boolean']['output'];
    readonly resetVocabularyItemsStatus: Scalars['Boolean']['output'];
    readonly restartExercises: Maybe<PracticeItem>;
    readonly restartPracticeSequenceQuiz: Session;
    readonly reviewMachineNext: Maybe<ReviewMachineProvider>;
    readonly reviewMachineReviewed: Scalars['Boolean']['output'];
    readonly rolloverSubscription: Scalars['Boolean']['output'];
    readonly saveClassroomEvent: Scalars['Boolean']['output'];
    readonly schedulePause: Scalars['Boolean']['output'];
    readonly sendChallengesFeedback: Maybe<Scalars['String']['output']>;
    readonly setCurrentClassroomUser: ZoomSessionParticipant;
    readonly setDailyGoal: StudentGoal;
    readonly setFlashcardStatus: VocabularyItem;
    readonly setFlashcardsStatus: ReadonlyArray<VocabularyItem>;
    readonly setFlashcardsStatusByLesson: ReadonlyArray<VocabularyItem>;
    readonly setGrammarStatus: GrammarItem;
    readonly setLessonsStatus: Scalars['Boolean']['output'];
    readonly setProfileFeedback: Scalars['Boolean']['output'];
    readonly setVocabularyItemStatus: VocabularyItem;
    readonly setVocabularyItemsStatus: ReadonlyArray<VocabularyItem>;
    readonly setVocabularyItemsStatusByLesson: ReadonlyArray<VocabularyItem>;
    readonly setWeeklyGoal: StudentGoal;
    readonly skipTrial: Subscription;
    readonly submitBinaryDialogFeedback: Scalars['Boolean']['output'];
    readonly submitChapterFlashcardsFeedback: Scalars['Boolean']['output'];
    readonly submitClassroomClassReview: UnionClassFeedbackClassTeacherFeedback;
    readonly submitExerciseScore: PracticeItem;
    readonly submitKysFeedback: ClassTeacherFeedback;
    readonly submitMatchingExerciseFeedback: Scalars['Boolean']['output'];
    readonly submitOnboardingSurvey: Scalars['Boolean']['output'];
    readonly submitPersonalizedFeedback: Scalars['Boolean']['output'];
    readonly submitPracticeSatisfactionFeedback: Scalars['Boolean']['output'];
    readonly submitPracticeSatisfactionScore: Scalars['Boolean']['output'];
    readonly submitPracticeSequenceFlashcardFeedback: Scalars['Boolean']['output'];
    readonly submitPracticeSequenceSingleChoiceFeedback: Scalars['Boolean']['output'];
    readonly submitPresentationFeedback: Scalars['Boolean']['output'];
    readonly submitStudentClassReview: ClassFeedback;
    readonly submitVocabTrainerFeedback: Scalars['Boolean']['output'];
    readonly subscriptionCancellationSurvey: ReadonlyArray<SubscriptionFeedback>;
    readonly teacherAcceptAgreement: Agreement;
    readonly teacherBookCourse: CourseAssignResult;
    readonly teacherCancelClass: Scalars['Boolean']['output'];
    readonly teacherCancelClassObservation: Maybe<ClassObservation>;
    readonly teacherCommitToClass: TeacherCalendarTimeSlot;
    readonly teacherLogAppointment: Klass;
    readonly teacherLogNoShow: Klass;
    readonly teacherLogTechnicalProblem: Klass;
    readonly teacherRegistrationInput: User;
    readonly teacherRemoveCertificate: Scalars['Boolean']['output'];
    readonly teacherRequestEmailConfirmation: Scalars['Boolean']['output'];
    readonly teacherSetIsOnboarded: Scalars['Boolean']['output'];
    readonly teacherSubmitClassLog: Klass;
    readonly teacherUpdateAccountSettings: Teacher;
    readonly teacherUpdateLanguages: Teacher;
    readonly teacherUpdateNotificationSettings: Teacher;
    readonly teacherUpdatePaymentInfo: Teacher;
    readonly teacherUpdatePreferences: Teacher;
    readonly teacherUpdateProfile: Teacher;
    readonly teacherUploadCertificate: ReadonlyArray<TeacherCertificate>;
    readonly updateAvatar: User;
    readonly updateClassroomUser: Scalars['Boolean']['output'];
    readonly updateCompanyName: Company;
    readonly updateExtraCreditPurchase: ExtraCreditPurchase;
    readonly updateLearningLanguageForNotSubscriber: Scalars['Boolean']['output'];
    readonly updateLegacyPurchase: LegacyPurchase;
    /** Will eventually replace product-based endpoint */
    readonly updatePurchase: Purchase;
    readonly updateSpokenLanguage: Scalars['Boolean']['output'];
    readonly updateStudentMarketingPreferences: Scalars['Boolean']['output'];
    readonly updateStudentNotificationPreferences: Scalars['Boolean']['output'];
    readonly updateStudentVirtualBackground: Scalars['Boolean']['output'];
    readonly updateUserLanguageAndSubscription: Subscription;
    readonly updateUserPhone: User;
    readonly whyAreYouLearning: Scalars['Boolean']['output'];
};

export type MutationAnswerBookingExperienceSurveyArgs = {
    score: Scalars['Int']['input'];
};

export type MutationAnswerBookingExperienceSurveyDetailsArgs = {
    answer?: InputMaybe<BookingExperienceAnswerType>;
    comment?: InputMaybe<Scalars['String']['input']>;
    extraFeedback?: InputMaybe<ReadonlyArray<BookingExperienceExtraFeedback>>;
};

export type MutationAnswerChallengeArgs = {
    answer?: InputMaybe<Scalars['Boolean']['input']>;
    challengeName: Scalars['String']['input'];
};

export type MutationAppealPenaltyArgs = {
    explanationNote: Scalars['String']['input'];
};

export type MutationBookClassArgs = {
    classId: Scalars['String']['input'];
    stats?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCalendarDisconnectArgs = {
    calendarType: CalendarType;
};

export type MutationCancelClassArgs = {
    classId: Scalars['String']['input'];
    isImmediate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCancelSprintRolloverWithQuestionnaireArgs = {
    sprintCancelRolloverQuestionnaire: SprintCancelRolloverQuestionnaireInput;
};

export type MutationCancelSubscriptionWithFeedbackArgs = {
    mood?: InputMaybe<EmojiType>;
    reason: Scalars['String']['input'];
    reasonComment?: InputMaybe<Scalars['String']['input']>;
};

export type MutationChangeAppointmentStatusByStudentArgs = {
    appointmentId: Scalars['Ulid']['input'];
    statusByStudent?: InputMaybe<Scalars['String']['input']>;
};

export type MutationClaimAttendanceAchievementArgs = {
    claimId: Scalars['Ulid']['input'];
};

export type MutationCompleteChecklistItemsArgs = {
    slugs: ReadonlyArray<ChecklistItemSlug>;
};

export type MutationConvertGroupCreditsIntoPrivateArgs = {
    numberOfCredits: Scalars['Int']['input'];
};

export type MutationCreateAppleCreditPurchaseArgs = {
    price: PriceInput;
    transactionReceipt: Scalars['String']['input'];
};

export type MutationCreateB2GCourseArgs = {
    b2GCourse: B2GCourseInput;
};

export type MutationCreateClassChatHistoryArgs = {
    input: ClassChatHistoryInput;
};

export type MutationCreateCompanyArgs = {
    name: Scalars['String']['input'];
};

export type MutationCreateDemoZoomSessionArgs = {
    demoClassUid: Scalars['String']['input'];
};

export type MutationCreateExtraCreditPurchaseArgs = {
    currency: Currency;
    groupCredits: Scalars['Int']['input'];
    privateCredits: Scalars['Int']['input'];
};

export type MutationCreateFreeTrialPurchaseArgs = {
    packageId: Scalars['Ulid']['input'];
};

export type MutationCreateGuestUserArgs = {
    input: GuestUserInput;
};

export type MutationCreateLegacyPurchaseArgs = {
    couponCode?: InputMaybe<Scalars['String']['input']>;
    currency: Currency;
    productId: Scalars['Int']['input'];
};

export type MutationCreateMissedClassFeedbackArgs = {
    classId: Scalars['String']['input'];
    missedClassFeedbackAnswers?: ReadonlyArray<Scalars['String']['input']>;
};

export type MutationCreatePurchaseArgs = {
    couponCode?: InputMaybe<Scalars['String']['input']>;
    currency: Currency;
    options?: InputMaybe<PurchaseOptionsInput>;
    packageId: Scalars['Ulid']['input'];
    withTrial: Scalars['Boolean']['input'];
};

export type MutationCreateSpokenLanguageArgs = {
    language: UserLanguage;
    languageFluency: LanguageFluency;
};

export type MutationCreateTestAdminArgs = {
    features?: InputMaybe<ReadonlyArray<InputMaybe<Feature>>>;
};

export type MutationCreateTestMercureMessageArgs = {
    data: Scalars['String']['input'];
    topic: Scalars['String']['input'];
    type: Scalars['String']['input'];
};

export type MutationCreateTestStudentArgs = {
    featureFlags?: InputMaybe<ReadonlyArray<InputMaybe<FeatureFlag>>>;
    features?: InputMaybe<ReadonlyArray<InputMaybe<Feature>>>;
    sectionName: Scalars['SectionName']['input'];
};

export type MutationCreateTestStudentNotificationArgs = {
    data: Scalars['String']['input'];
    type: Scalars['String']['input'];
};

export type MutationCreateTestTeacherArgs = {
    featureFlags?: InputMaybe<ReadonlyArray<InputMaybe<FeatureFlag>>>;
    features?: InputMaybe<ReadonlyArray<InputMaybe<Feature>>>;
    maxGroupAndIndividualClasses?: InputMaybe<Scalars['Int']['input']>;
    onboarded?: InputMaybe<Scalars['Boolean']['input']>;
    sectionName: Scalars['SectionName']['input'];
};

export type MutationCreateZoomSessionArgs = {
    classUid: Scalars['String']['input'];
    isDemoClass?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationDelayNextSubscriptionStartDateArgs = {
    delayUntil: Scalars['DateTime']['input'];
};

export type MutationDeleteSpokenLanguageArgs = {
    language: UserLanguage;
};

export type MutationDismissStudentNotificationArgs = {
    studentNotificationId: Scalars['Ulid']['input'];
};

export type MutationFlashcardSpacedRepetitionArgs = {
    remembered: Scalars['Boolean']['input'];
    vocabularyItemId: Scalars['String']['input'];
};

export type MutationIMissedClassArgs = {
    classId: Scalars['String']['input'];
    details?: InputMaybe<Scalars['String']['input']>;
    reason: MissedStatusReason;
};

export type MutationImportStudentsArgs = {
    students: ReadonlyArray<ImportedStudentInput>;
};

export type MutationJoinChallengeArgs = {
    challengeName: Scalars['String']['input'];
};

export type MutationLearningNeedsArgs = {
    learningNeeds: Scalars['String']['input'];
};

export type MutationMarkWidgetAsDisplayedArgs = {
    widgetClass: UserDisplayedWidgetClassEnum;
};

export type MutationPauseTeachingArgs = {
    pausedFrom: Scalars['DateTime']['input'];
    pausedUntil: Scalars['DateTime']['input'];
};

export type MutationPromoteGuestUserArgs = {
    name?: InputMaybe<Scalars['String']['input']>;
    password: Scalars['String']['input'];
};

export type MutationQuizEngineReportResultArgs = {
    input: ReportResultInput;
};

export type MutationQuizEngineReportResultsArgs = {
    input: ReportMultipleResultInput;
};

export type MutationQuizReportVocabResultArgs = {
    input: ReportVocabResultInput;
};

export type MutationRefreshRecommendedActivitiesArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationReportPracticeSequenceSessionArgs = {
    sessionInput: PracticeSequenceSessionCompletedInput;
    userId: Scalars['Int']['input'];
};

export type MutationReportSessionArgs = {
    sessionInput: SessionInput;
    userId: Scalars['Int']['input'];
};

export type MutationResetCumulativeFlashcardDeckArgs = {
    deck: Deck;
};

export type MutationResetFlashcardsStatusArgs = {
    learningUnitId: Scalars['Int']['input'];
};

export type MutationResetVocabularyItemsStatusArgs = {
    learningUnitId: Scalars['Int']['input'];
};

export type MutationRestartExercisesArgs = {
    lessonId: Scalars['Int']['input'];
    type: ExerciseType;
};

export type MutationRestartPracticeSequenceQuizArgs = {
    lessonId: Scalars['Int']['input'];
};

export type MutationReviewMachineReviewedArgs = {
    provider: ReviewMachineProvider;
};

export type MutationSaveClassroomEventArgs = {
    classId: Scalars['String']['input'];
    eventName: VirtualClassroomEvent;
    studentIds?: InputMaybe<ReadonlyArray<Scalars['Int']['input']>>;
};

export type MutationSchedulePauseArgs = {
    pauseEndTime: Scalars['DateTime']['input'];
    pauseStartTime: Scalars['DateTime']['input'];
};

export type MutationSendChallengesFeedbackArgs = {
    feedback?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSetCurrentClassroomUserArgs = {
    input: ZoomSessionParticipantInput;
};

export type MutationSetDailyGoalArgs = {
    value: Scalars['Int']['input'];
};

export type MutationSetFlashcardStatusArgs = {
    status: VocabularyStatus;
    vocabularyItemId: Scalars['String']['input'];
};

export type MutationSetFlashcardsStatusArgs = {
    status: VocabularyStatus;
    vocabularyItemIds: ReadonlyArray<Scalars['String']['input']>;
};

export type MutationSetFlashcardsStatusByLessonArgs = {
    lessonId: Scalars['Int']['input'];
    status: VocabularyStatus;
};

export type MutationSetGrammarStatusArgs = {
    id: Scalars['String']['input'];
    status: GrammarStatus;
};

export type MutationSetLessonsStatusArgs = {
    lessonsStatus: ReadonlyArray<LessonStatusInput>;
};

export type MutationSetProfileFeedbackArgs = {
    feedback: Scalars['Boolean']['input'];
};

export type MutationSetVocabularyItemStatusArgs = {
    status: VocabularyStatus;
    vocabularyItemId: Scalars['String']['input'];
};

export type MutationSetVocabularyItemsStatusArgs = {
    status: VocabularyStatus;
    vocabularyItemIds: ReadonlyArray<Scalars['String']['input']>;
};

export type MutationSetVocabularyItemsStatusByLessonArgs = {
    lessonId: Scalars['Int']['input'];
    status: VocabularyStatus;
};

export type MutationSetWeeklyGoalArgs = {
    value: Scalars['Int']['input'];
};

export type MutationSubmitBinaryDialogFeedbackArgs = {
    feedbackInput: LessonBasedExerciseFeedbackInput;
};

export type MutationSubmitChapterFlashcardsFeedbackArgs = {
    feedbackInput: FlashcardFeedbackInput;
};

export type MutationSubmitClassroomClassReviewArgs = {
    classId: Scalars['String']['input'];
    technicalQualityFeedbackInput: TechnicalQualityFeedbackInput;
};

export type MutationSubmitExerciseScoreArgs = {
    exerciseId: Scalars['Int']['input'];
    lessonId: Scalars['Int']['input'];
    score: Scalars['Float']['input'];
    type: ExerciseType;
};

export type MutationSubmitKysFeedbackArgs = {
    classId: Scalars['String']['input'];
    isKysUseful: Scalars['Boolean']['input'];
};

export type MutationSubmitMatchingExerciseFeedbackArgs = {
    feedbackInput: LessonBasedExerciseFeedbackInput;
};

export type MutationSubmitOnboardingSurveyArgs = {
    isCompletedOnboardingSurvey: Scalars['Boolean']['input'];
};

export type MutationSubmitPersonalizedFeedbackArgs = {
    firstAnswer: Scalars['String']['input'];
    secondAnswer: Scalars['String']['input'];
};

export type MutationSubmitPracticeSatisfactionFeedbackArgs = {
    feedback: ReadonlyArray<Scalars['String']['input']>;
};

export type MutationSubmitPracticeSatisfactionScoreArgs = {
    score: Scalars['Int']['input'];
};

export type MutationSubmitPracticeSequenceFlashcardFeedbackArgs = {
    feedbackInput: FlashcardFeedbackInput;
};

export type MutationSubmitPracticeSequenceSingleChoiceFeedbackArgs = {
    feedbackInput: SingleChoiceFeedbackInput;
};

export type MutationSubmitPresentationFeedbackArgs = {
    classId: Scalars['String']['input'];
    feedback: Scalars['String']['input'];
};

export type MutationSubmitStudentClassReviewArgs = {
    classId: Scalars['String']['input'];
    input: ClassFeedbackInput;
};

export type MutationSubmitVocabTrainerFeedbackArgs = {
    feedbackInput: SingleChoiceFeedbackInput;
};

export type MutationSubscriptionCancellationSurveyArgs = {
    feedbacks: ReadonlyArray<SubscriptionCancellationFeedbackInput>;
    subscriptionId: Scalars['Int']['input'];
};

export type MutationTeacherAcceptAgreementArgs = {
    agreementId: Scalars['String']['input'];
};

export type MutationTeacherBookCourseArgs = {
    courseId: Scalars['Ulid']['input'];
};

export type MutationTeacherCancelClassArgs = {
    classId: Scalars['String']['input'];
};

export type MutationTeacherCancelClassObservationArgs = {
    classId: Scalars['String']['input'];
};

export type MutationTeacherCommitToClassArgs = {
    classId: Scalars['String']['input'];
};

export type MutationTeacherLogAppointmentArgs = {
    answerIds: ReadonlyArray<Scalars['Ulid']['input']>;
    classId: Scalars['String']['input'];
    feedback?: InputMaybe<Scalars['String']['input']>;
    isAttended: Scalars['Boolean']['input'];
    recommendedLevel?: InputMaybe<Scalars['Int']['input']>;
    studentId: Scalars['Int']['input'];
};

export type MutationTeacherLogNoShowArgs = {
    classId: Scalars['String']['input'];
};

export type MutationTeacherLogTechnicalProblemArgs = {
    classId: Scalars['String']['input'];
    description: Scalars['String']['input'];
    screenshots: ReadonlyArray<Scalars['Upload']['input']>;
};

export type MutationTeacherRegistrationInputArgs = {
    input: TeacherRegistrationInput;
};

export type MutationTeacherRemoveCertificateArgs = {
    id: Scalars['String']['input'];
};

export type MutationTeacherRequestEmailConfirmationArgs = {
    input: TeacherEmailConfirmationInput;
};

export type MutationTeacherSubmitClassLogArgs = {
    classId: Scalars['String']['input'];
    logMessage: Scalars['String']['input'];
};

export type MutationTeacherUpdateAccountSettingsArgs = {
    accountSettingsInput: TeacherAccountSettingsInput;
};

export type MutationTeacherUpdateLanguagesArgs = {
    teacherLanguagesInput: TeacherLanguagesInput;
};

export type MutationTeacherUpdateNotificationSettingsArgs = {
    notificationSettingsInput: TeacherNotificationSettingsInput;
};

export type MutationTeacherUpdatePaymentInfoArgs = {
    paymentInfoInput: UpdatePaymentInfoInput;
};

export type MutationTeacherUpdatePreferencesArgs = {
    teacherPreferencesInput: UpdateTeacherPreferencesInput;
};

export type MutationTeacherUpdateProfileArgs = {
    input: ProfileInput;
};

export type MutationTeacherUploadCertificateArgs = {
    certificates: ReadonlyArray<Scalars['Upload']['input']>;
    type: CertificateType;
};

export type MutationUpdateAvatarArgs = {
    avatar: Scalars['String']['input'];
};

export type MutationUpdateClassroomUserArgs = {
    input: ZoomSessionParticipantInput;
};

export type MutationUpdateCompanyNameArgs = {
    companyId: Scalars['String']['input'];
    name: Scalars['String']['input'];
};

export type MutationUpdateExtraCreditPurchaseArgs = {
    currency?: InputMaybe<Currency>;
    groupCredits?: InputMaybe<Scalars['Int']['input']>;
    id: Scalars['String']['input'];
    privateCredits?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateLearningLanguageForNotSubscriberArgs = {
    newCurriculumId: Scalars['Int']['input'];
    newSectionName: Scalars['SectionName']['input'];
};

export type MutationUpdateLegacyPurchaseArgs = {
    couponCode?: InputMaybe<Scalars['String']['input']>;
    currency?: InputMaybe<Currency>;
    id: Scalars['String']['input'];
    productId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdatePurchaseArgs = {
    couponCode?: InputMaybe<Scalars['String']['input']>;
    currency?: InputMaybe<Currency>;
    id: Scalars['String']['input'];
};

export type MutationUpdateSpokenLanguageArgs = {
    currentLanguage: UserLanguage;
    languageFluency: LanguageFluency;
    newLanguage: UserLanguage;
};

export type MutationUpdateStudentMarketingPreferencesArgs = {
    preferences: StudentMarketingPreferencesInput;
};

export type MutationUpdateStudentNotificationPreferencesArgs = {
    preferences: StudentNotificationPreferencesInput;
};

export type MutationUpdateStudentVirtualBackgroundArgs = {
    backgroundAssetId?: InputMaybe<BackgroundAssetId>;
};

export type MutationUpdateUserLanguageAndSubscriptionArgs = {
    agreements: ReadonlyArray<Scalars['String']['input']>;
    couponCode?: InputMaybe<Scalars['String']['input']>;
    newCurriculumId: Scalars['Int']['input'];
    newSectionName: Scalars['SectionName']['input'];
    purchaseId: Scalars['String']['input'];
};

export type MutationUpdateUserPhoneArgs = {
    phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type MutationWhyAreYouLearningArgs = {
    answers?: InputMaybe<ReadonlyArray<InputMaybe<WhyAreYouLearningAnswerEnum>>>;
    otherOption?: InputMaybe<Scalars['String']['input']>;
};

export type OthersReferral = {
    readonly __typename?: 'OthersReferral';
    readonly ownerFirstName: Scalars['String']['output'];
    readonly ownerPhoto: Maybe<Image>;
    readonly userDiscount: ReadonlyArray<Price>;
};

export type OwnReferral = {
    readonly __typename?: 'OwnReferral';
    readonly code: Scalars['String']['output'];
    readonly creditsFromReferred: Scalars['Int']['output'];
    readonly ownerGroupCredits: Scalars['Int']['output'];
    readonly stayedReferred: Scalars['Int']['output'];
    readonly totalReferred: Scalars['Int']['output'];
    readonly userDiscount: ReadonlyArray<Price>;
};

export type Pagination_Appointment = {
    readonly __typename?: 'Pagination_Appointment';
    /** The total count of items. */
    readonly count: Scalars['Int']['output'];
    /** Current page number */
    readonly currentPage: Scalars['Int']['output'];
    /** Has more items */
    readonly hasMore: Scalars['Boolean']['output'];
    readonly items: ReadonlyArray<Appointment>;
};

export type Pagination_CumulativeVocabWord = {
    readonly __typename?: 'Pagination_CumulativeVocabWord';
    /** The total count of items. */
    readonly count: Scalars['Int']['output'];
    /** Current page number */
    readonly currentPage: Scalars['Int']['output'];
    /** Has more items */
    readonly hasMore: Scalars['Boolean']['output'];
    readonly items: ReadonlyArray<CumulativeVocabWord>;
};

export type Pagination_Module = {
    readonly __typename?: 'Pagination_Module';
    /** The total count of items. */
    readonly count: Scalars['Int']['output'];
    /** Current page number */
    readonly currentPage: Scalars['Int']['output'];
    /** Has more items */
    readonly hasMore: Scalars['Boolean']['output'];
    readonly items: ReadonlyArray<Module>;
};

export enum PaymentMethod {
    BankTransfer = 'BankTransfer',
    PayPal = 'PayPal',
}

export enum PaymentStatus {
    Failed = 'failed',
    Pending = 'pending',
    Successful = 'successful',
}

export type PracticeItem = {
    readonly __typename?: 'PracticeItem';
    readonly class: Maybe<Klass>;
    readonly classLog: Maybe<ClassLog>;
    readonly exercises: ReadonlyArray<H5PExercise>;
    readonly grammarItems: ReadonlyArray<GrammarItem>;
    readonly homeworks: ReadonlyArray<H5PExercise>;
    readonly homeworksScore: Maybe<Scalars['Float']['output']>;
    readonly isCompleted: Scalars['Boolean']['output'];
    readonly learningUnit: PracticeItemLearningUnit;
    readonly lesson: PracticeItemLesson;
    readonly module: Module;
    readonly pendingHomeworks: ReadonlyArray<H5PExercise>;
    readonly quiz: H5PExercise;
    readonly vocabularyItems: ReadonlyArray<VocabularyItem>;
};

export type PracticeItemLearningUnit = {
    readonly __typename?: 'PracticeItemLearningUnit';
    readonly chapter: Scalars['Int']['output'];
    readonly name: Scalars['String']['output'];
};

export type PracticeItemLesson = {
    readonly __typename?: 'PracticeItemLesson';
    readonly id: Scalars['Int']['output'];
    readonly number: Scalars['Int']['output'];
    readonly presentationUrl: Maybe<Scalars['Url']['output']>;
    readonly title: Maybe<Scalars['String']['output']>;
};

export enum PracticeItemStatus {
    Completed = 'completed',
    Pending = 'pending',
}

export type PracticeSequenceSessionCompletedInput = {
    readonly completionValue: Scalars['Float']['input'];
    readonly lessonId: Scalars['Int']['input'];
    readonly score: Scalars['Float']['input'];
};

export type PrepareClassInfo = {
    readonly __typename?: 'PrepareClassInfo';
    readonly isAllowedToLeaveFeedback: Scalars['Boolean']['output'];
    readonly kysFeedback: Maybe<KysFeedback>;
    readonly prepareClassStudents: ReadonlyArray<PrepareClassStudent>;
};

export type PrepareClassStudent = {
    readonly __typename?: 'PrepareClassStudent';
    readonly lastInteraction: Maybe<Scalars['DateTime']['output']>;
    readonly student: Student;
    readonly studentAppointmentLabel: StudentAppointmentLabel;
    readonly studentModules: ReadonlyArray<StudentModule>;
    readonly studentTeacherInteractions: Scalars['Int']['output'];
};

export type Price = {
    readonly __typename?: 'Price';
    readonly amount: Scalars['Int']['output'];
    readonly amountInMajor: Scalars['Float']['output'];
    readonly currency: Currency;
    readonly forHumans: Scalars['String']['output'];
};

export type PriceInput = {
    readonly amount: Scalars['Int']['input'];
    readonly currency: Currency;
};

export type PrivacyPolicy = {
    readonly __typename?: 'PrivacyPolicy';
    readonly body: Scalars['String']['output'];
    readonly id: Scalars['String']['output'];
    readonly locale: Scalars['String']['output'];
};

export type ProfileInput = {
    readonly aboutMe?: InputMaybe<Scalars['String']['input']>;
    readonly firstName?: InputMaybe<Scalars['String']['input']>;
    readonly lastName?: InputMaybe<Scalars['String']['input']>;
    readonly photo?: InputMaybe<Scalars['String']['input']>;
    readonly removePhoto?: Scalars['Boolean']['input'];
};

export type PromoCampaign = {
    readonly __typename?: 'PromoCampaign';
    readonly bannerText: Scalars['String']['output'];
    readonly campaignEndDate: Scalars['DateTime']['output'];
    readonly campaignStartDate: Scalars['DateTime']['output'];
    readonly type: PromoCampaignType;
};

export enum PromoCampaignType {
    FftPromo = 'fft_promo',
}

export type PromotionCreditAward = {
    readonly __typename?: 'PromotionCreditAward';
    readonly group: Scalars['Int']['output'];
    readonly private: Scalars['Int']['output'];
};

export type ProvideBookableClassSlotsInput = {
    readonly classTypes: ReadonlyArray<Scalars['String']['input']>;
    readonly dateFrom?: InputMaybe<Scalars['String']['input']>;
    readonly dateTo?: InputMaybe<Scalars['String']['input']>;
    readonly dates?: ReadonlyArray<Scalars['String']['input']>;
    readonly exactTimeSlots?: ReadonlyArray<Scalars['String']['input']>;
    readonly filterBookingClosedCourses?: InputMaybe<Scalars['Boolean']['input']>;
    readonly includeCurrent?: InputMaybe<Scalars['Boolean']['input']>;
    readonly lessonIds?: ReadonlyArray<Scalars['Int']['input']>;
    readonly moduleIds?: ReadonlyArray<Scalars['Int']['input']>;
    readonly skipFilteringOutGeneratedClasses?: InputMaybe<Scalars['Boolean']['input']>;
    readonly slotsType: Scalars['String']['input'];
    readonly weekdays?: ReadonlyArray<Scalars['Int']['input']>;
};

export type Purchase = ExtraCreditPurchase | LegacyPurchase | SubscriptionPurchase;

export type PurchaseOptionsInput = {
    readonly learningTeamCertificates?: InputMaybe<
        ReadonlyArray<InputMaybe<Scalars['String']['input']>>
    >;
    readonly learningTeamCourseId?: InputMaybe<Scalars['Ulid']['input']>;
    readonly newSectionName?: InputMaybe<Scalars['SectionName']['input']>;
};

export enum PurchaseType {
    ExtraCredits = 'extra_credits',
    FreeToRolling = 'free_to_rolling',
    FreeTrial = 'free_trial',
    LearningTeam = 'learning_team',
    Legacy = 'legacy',
    Marathon = 'marathon',
    Monthly = 'monthly',
    NonRecurring = 'non_recurring',
    Recurring = 'recurring',
    Sprint = 'sprint',
}

export type Query = {
    readonly __typename?: 'Query';
    readonly activeFeatures: ReadonlyArray<Scalars['String']['output']>;
    readonly activeGoal: Maybe<StudentGoal>;
    readonly activityChallenge: ActivityChallenge;
    readonly activityChallenges: ReadonlyArray<ActivityChallenge>;
    readonly anonymousOrientationGroupClasses: ReadonlyArray<Klass>;
    readonly anonymousOrientationIndividualClasses: ReadonlyArray<Klass>;
    readonly appointmentAttendance: ReadonlyArray<UserAttendances>;
    readonly appointmentDates: ReadonlyArray<Scalars['DateTime']['output']>;
    readonly appointmentLog: Maybe<AppointmentLog>;
    readonly appointmentsList: ReadonlyArray<Appointment>;
    readonly appropriatePromoCampaign: Maybe<PromoCampaign>;
    readonly b2Attendance: ReadonlyArray<UserAttendance>;
    readonly backgroundOptions: ReadonlyArray<BackgroundImage>;
    readonly bookingExperienceQuestions: ReadonlyArray<BookingExperienceSurveyQuestion>;
    readonly calendarAuthRedirectUrl: Scalars['String']['output'];
    readonly calendarList: ReadonlyArray<UserExternalCalendarDto>;
    readonly canChangeLearningLanguage: CanChangeLanguage;
    readonly cefrLevels: ReadonlyArray<CefrLevel>;
    readonly certificateTypes: ReadonlyArray<TeacherAvailableCertificateType>;
    readonly challengesFeedback: Maybe<Scalars['String']['output']>;
    readonly chatHistory: Maybe<ClassChatHistory>;
    readonly chatHistoryByZoomSession: Maybe<ClassChatHistory>;
    readonly checklist: ReadonlyArray<ChecklistItem>;
    readonly class: Klass;
    readonly classFeedbackAnswers: ReadonlyArray<ClassFeedbackAnswer>;
    readonly classLeadTime: ClassLeadTime;
    readonly classLessonMaterialSnapshot: ClassMaterialSnapshot;
    readonly classes: ReadonlyArray<Klass>;
    readonly classroomUrl: Scalars['String']['output'];
    readonly company: Company;
    readonly config: SystemConfig;
    readonly countries: ReadonlyArray<Country>;
    readonly country: Maybe<Country>;
    readonly courseHolidays: ReadonlyArray<CourseHoliday>;
    readonly cumulativeFlashcardDeck: ReadonlyArray<VocabularyItem>;
    readonly cumulativeVocabList: Pagination_CumulativeVocabWord;
    readonly currentStudentSprint: Maybe<Sprint>;
    readonly currentUser: User;
    readonly evaluateCouponStatus: CouponEvaluationStatusResponse;
    readonly exercise: Maybe<Exercise>;
    readonly exercises: ReadonlyArray<H5PExercise>;
    readonly featureActive: Scalars['Boolean']['output'];
    readonly flexPackages: ReadonlyArray<FlexGeoPricedVariantResponseEntry>;
    readonly futureAppointments: Pagination_Appointment;
    readonly grammarItem: GrammarItem;
    readonly grammarItems: ReadonlyArray<GrammarItem>;
    readonly isWidgetEligibleForDisplay: Scalars['Boolean']['output'];
    readonly languageAttendance: ReadonlyArray<UserLanguageAttendance>;
    readonly lastLessonAppointment: Maybe<Appointment>;
    readonly lastPublishedLearningTeamCourse: Maybe<LearningTeamCourse>;
    readonly lastTransaction: Maybe<Transaction>;
    readonly learningTeamCourses: ReadonlyArray<LearningTeamCourse>;
    readonly learningUnit: LearningUnit;
    readonly learningUnits: ReadonlyArray<LearningUnit>;
    readonly lesson: Lesson;
    readonly lessons: ReadonlyArray<Lesson>;
    readonly liveClassAttendance: ReadonlyArray<UserLiveClassAttendance>;
    readonly modules: ReadonlyArray<Module>;
    readonly newUserOrientationClassLeadTime: Scalars['String']['output'];
    readonly nextAvailableClassDate: Scalars['DateTime']['output'];
    readonly nextLessonsForPracticeSequenceQuiz: ReadonlyArray<Lesson>;
    readonly onboardingClass: Maybe<Klass>;
    readonly orientationLesson: Maybe<Lesson>;
    readonly orientationModules: ReadonlyArray<Scalars['String']['output']>;
    /** Fetch user's own referral coupon */
    readonly ownReferral: Maybe<OwnReferral>;
    readonly package: Maybe<Variant>;
    readonly packages: ReadonlyArray<Variant>;
    readonly pastAppointments: Pagination_Appointment;
    readonly pastAppointmentsCount: Scalars['Int']['output'];
    readonly personalizedFeedbackQuestions: ReadonlyArray<QuestionnaireQuestion>;
    readonly practiceItem: Maybe<PracticeItem>;
    readonly practiceItems: ReadonlyArray<PracticeItem>;
    readonly practiceSequenceQuiz: Session;
    readonly presentationModeAuthData: AuthData;
    readonly privacyPolicy: PrivacyPolicy;
    readonly purchase: Maybe<Purchase>;
    readonly quizEngineContentGenerationModules: ReadonlyArray<QuizEngineModule>;
    readonly quizEngineProgress: QuizProgress;
    readonly quizEngineQuiz: Quiz;
    readonly quizFeedbackQuestions: ReadonlyArray<QuestionnaireQuestion>;
    readonly quizSessionScore: SessionScore;
    readonly recommendedActivities: ReadonlyArray<Activity>;
    readonly recommendedExercises: ReadonlyArray<Exercise>;
    readonly recommendedFlashcardDecks: ReadonlyArray<LearningUnit>;
    readonly recommendedLessonsForPracticeSequenceQuiz: ReadonlyArray<Lesson>;
    readonly recommendedPracticeItems: ReadonlyArray<PracticeItem>;
    /** Fetch referral by code - Intentionally public */
    readonly referralByCode: Maybe<OthersReferral>;
    readonly section: Maybe<Section>;
    readonly sectionCurricula: ReadonlyArray<SectionCurriculum>;
    readonly sections: ReadonlyArray<Section>;
    readonly sprintCancelRolloverQuestionnaire: SprintCancelRolloverQuestionnaire;
    readonly sprintPackages: ReadonlyArray<SprintGeoPricedVariantResponseEntry>;
    readonly startingModules: ReadonlyArray<StartingModule>;
    readonly student: Student;
    readonly studentAchievement: Maybe<Achievement>;
    readonly studentAchievements: ReadonlyArray<Achievement>;
    readonly studentActivity: StudentActivity;
    readonly studentAppointmentDates: ReadonlyArray<Scalars['DateTime']['output']>;
    readonly studentCreditsInfo: StudentCreditsInfo;
    readonly studentGoalWidgetData: WidgetData;
    readonly studentGoalsHistory: ReadonlyArray<StudentGoal>;
    readonly studentLastTwoWeeksClassesPendingReview: ReadonlyArray<Appointment>;
    readonly studentLearningTeamInfo: StudentLearningTeam;
    readonly studentLessons: ReadonlyArray<Lesson>;
    readonly studentModule: Maybe<StudentModule>;
    readonly studentModules: ReadonlyArray<StudentModule>;
    readonly studentNotification: ReadonlyArray<StudentNotification>;
    readonly studentSubscriptionInfo: StudentSubscriptionInfo;
    readonly studentYearlySummary: Maybe<StudentYearlyActivitySummary>;
    readonly studentsLearningPlanForModule: ReadonlyArray<UserLearningPlan>;
    readonly subscriptionCancellationSurvey: QuestionnaireQuestion;
    readonly suggestOpenDiscussion: Scalars['Boolean']['output'];
    readonly teacher: Maybe<Teacher>;
    readonly teacherAppointmentLogQuestions: ReadonlyArray<AppointmentLogQuestion>;
    readonly teacherAvailableCourses: TeacherAvailableCoursesListDto;
    readonly teacherCalendar: TeacherCalendar;
    readonly teacherCalendarEventDetails: TeacherCalendarEventDetailsList;
    readonly teacherCertificates: ReadonlyArray<TeacherCertificate>;
    readonly teacherClass: Klass;
    readonly teacherClassAppointmentLogQuestions: ReadonlyArray<AppointmentLogQuestion>;
    readonly teacherClassObservation: Maybe<ClassObservation>;
    readonly teacherInvoices: ReadonlyArray<TeacherInvoice>;
    readonly teacherLearningMaterials: Pagination_Module;
    readonly teacherNextClass: Maybe<Klass>;
    readonly teacherOpenLogs: ReadonlyArray<Klass>;
    readonly teacherOpenLogsCount: Scalars['Int']['output'];
    readonly teacherProfile: Teacher;
    readonly teacherSubmittedLogs: ReadonlyArray<ClassLogGroupDto>;
    readonly teacherUpcomingClasses: ReadonlyArray<Klass>;
    readonly teacherValidateConfirmationToken: Scalars['Boolean']['output'];
    readonly teamsCourses: ReadonlyArray<LearningTeamCourseGeoPricedVariantResponseEntry>;
    readonly upcomingAppointmentDates: ReadonlyArray<Scalars['DateTime']['output']>;
    readonly upcomingAppointmentsCount: Scalars['Int']['output'];
    readonly upcomingScheduledGroupClassesWithAvailableSeatsAndTeacher: ReadonlyArray<Klass>;
    readonly userByEmail: Maybe<User>;
    readonly userChallenges: ReadonlyArray<UserChallenge>;
    readonly vocabularyItems: ReadonlyArray<VocabularyItem>;
    readonly zoomSessionParticipants: ReadonlyArray<ZoomSessionParticipant>;
};

export type QueryActivityChallengeArgs = {
    challengeName: Scalars['String']['input'];
};

export type QueryActivityChallengesArgs = {
    canJoinOnly: Scalars['Boolean']['input'];
};

export type QueryAnonymousOrientationGroupClassesArgs = {
    curriculumId: Scalars['Int']['input'];
    moduleId: Scalars['Int']['input'];
    sectionName: Scalars['SectionName']['input'];
    timezone: Scalars['String']['input'];
};

export type QueryAnonymousOrientationIndividualClassesArgs = {
    curriculumId: Scalars['Int']['input'];
    moduleId: Scalars['Int']['input'];
    sectionName: Scalars['SectionName']['input'];
    timezone: Scalars['String']['input'];
};

export type QueryAppointmentAttendanceArgs = {
    input: AppointmentAttendanceInput;
};

export type QueryAppointmentDatesArgs = {
    appointmentStatus?: InputMaybe<Scalars['String']['input']>;
    appointmentStatuses?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
    fromDate?: InputMaybe<Scalars['DateTime']['input']>;
    sort?: InputMaybe<Scalars['String']['input']>;
    toDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryAppointmentLogArgs = {
    classId: Scalars['String']['input'];
};

export type QueryAppointmentsListArgs = {
    appointmentStatus?: InputMaybe<Scalars['String']['input']>;
    fromDate?: InputMaybe<Scalars['DateTime']['input']>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Scalars['String']['input']>;
    toDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryB2AttendanceArgs = {
    input: B2AttendanceInput;
};

export type QueryCalendarAuthRedirectUrlArgs = {
    calendarType: CalendarType;
};

export type QueryChatHistoryArgs = {
    classId: Scalars['String']['input'];
};

export type QueryChatHistoryByZoomSessionArgs = {
    zoomSessionId: Scalars['Ulid']['input'];
};

export type QueryChecklistArgs = {
    slug: Scalars['String']['input'];
};

export type QueryClassArgs = {
    id: Scalars['String']['input'];
    isDemoClass?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryClassFeedbackAnswersArgs = {
    sentiment: Scalars['String']['input'];
    type: Scalars['String']['input'];
};

export type QueryClassLessonMaterialSnapshotArgs = {
    classId: Scalars['String']['input'];
};

export type QueryClassesArgs = {
    bookingFilter: ProvideBookableClassSlotsInput;
};

export type QueryClassroomUrlArgs = {
    uniqueId: Scalars['String']['input'];
};

export type QueryCompanyArgs = {
    name: Scalars['String']['input'];
};

export type QueryCountryArgs = {
    code: Scalars['String']['input'];
};

export type QueryCumulativeFlashcardDeckArgs = {
    deck: Deck;
};

export type QueryCumulativeVocabListArgs = {
    limit: Scalars['Int']['input'];
    moduleIds?: InputMaybe<ReadonlyArray<Scalars['Int']['input']>>;
    page: Scalars['Int']['input'];
    wordStatuses?: InputMaybe<ReadonlyArray<WordStatus>>;
};

export type QueryEvaluateCouponStatusArgs = {
    code: Scalars['String']['input'];
};

export type QueryExerciseArgs = {
    learningUnitId: Scalars['Int']['input'];
};

export type QueryExercisesArgs = {
    lessonId: Scalars['Int']['input'];
    status?: InputMaybe<ExerciseStatus>;
    type?: InputMaybe<ExerciseType>;
};

export type QueryFeatureActiveArgs = {
    featureKey: Scalars['String']['input'];
};

export type QueryFlexPackagesArgs = {
    countryIsoCode?: InputMaybe<Scalars['String']['input']>;
    couponCode?: InputMaybe<Scalars['String']['input']>;
    currency?: InputMaybe<Currency>;
    language: Scalars['String']['input'];
    testPackages?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFutureAppointmentsArgs = {
    lessonId?: InputMaybe<Scalars['Int']['input']>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    page?: InputMaybe<Scalars['Int']['input']>;
    startsAfter?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryGrammarItemArgs = {
    id: Scalars['String']['input'];
};

export type QueryGrammarItemsArgs = {
    lessonIds: ReadonlyArray<Scalars['Int']['input']>;
};

export type QueryIsWidgetEligibleForDisplayArgs = {
    widgetClass: UserDisplayedWidgetNewClassroomBannerEnum;
};

export type QueryLanguageAttendanceArgs = {
    input: LanguageAttendanceInput;
};

export type QueryLastLessonAppointmentArgs = {
    lessonId: Scalars['Int']['input'];
};

export type QueryLearningTeamCoursesArgs = {
    input?: InputMaybe<LearningTeamCourseInput>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order?: InputMaybe<Scalars['String']['input']>;
    orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryLearningUnitArgs = {
    learningUnitId: Scalars['Int']['input'];
};

export type QueryLearningUnitsArgs = {
    moduleId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryLessonArgs = {
    id: Scalars['Int']['input'];
};

export type QueryLessonsArgs = {
    lessons: ReadonlyArray<Scalars['Int']['input']>;
};

export type QueryLiveClassAttendanceArgs = {
    input: LiveClassAttendanceInput;
};

export type QueryNewUserOrientationClassLeadTimeArgs = {
    classType: Scalars['String']['input'];
    sectionName: Scalars['SectionName']['input'];
};

export type QueryNextAvailableClassDateArgs = {
    bookingFilter: ProvideBookableClassSlotsInput;
};

export type QueryNextLessonsForPracticeSequenceQuizArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryOnboardingClassArgs = {
    classId: Scalars['String']['input'];
};

export type QueryOrientationLessonArgs = {
    id?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryOrientationModulesArgs = {
    sectionName: Scalars['SectionName']['input'];
};

export type QueryPackageArgs = {
    currency: Currency;
    packageId: Scalars['Ulid']['input'];
    sectionName: Scalars['SectionName']['input'];
};

export type QueryPackagesArgs = {
    currency: Currency;
    ignoreSectionValidation?: InputMaybe<Scalars['Boolean']['input']>;
    sectionName: Scalars['SectionName']['input'];
    store: Store;
};

export type QueryPastAppointmentsArgs = {
    lessonId?: InputMaybe<Scalars['Int']['input']>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    page?: InputMaybe<Scalars['Int']['input']>;
    startsBefore?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryPastAppointmentsCountArgs = {
    toDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryPracticeItemArgs = {
    lessonId: Scalars['Int']['input'];
    status?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPracticeItemsArgs = {
    lessonId?: InputMaybe<Scalars['Int']['input']>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    moduleId?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    status?: InputMaybe<PracticeItemStatus>;
    type?: InputMaybe<ExerciseType>;
};

export type QueryPracticeSequenceQuizArgs = {
    lessonId: Scalars['Int']['input'];
};

export type QueryPresentationModeAuthDataArgs = {
    classId: Scalars['String']['input'];
};

export type QueryPurchaseArgs = {
    id: Scalars['String']['input'];
};

export type QueryQuizEngineContentGenerationModulesArgs = {
    includeAllVocabItems?: InputMaybe<Scalars['Boolean']['input']>;
    lessonId?: InputMaybe<Scalars['Int']['input']>;
    lessonPresentationNumber?: InputMaybe<Scalars['String']['input']>;
    moduleName?: InputMaybe<Scalars['String']['input']>;
    sectionName: Scalars['SectionName']['input'];
    vocabularyItemId?: InputMaybe<Scalars['String']['input']>;
    vocabularyItemTitle?: InputMaybe<Scalars['String']['input']>;
};

export type QueryQuizEngineProgressArgs = {
    from?: InputMaybe<Scalars['String']['input']>;
    to?: InputMaybe<Scalars['String']['input']>;
};

export type QueryQuizEngineQuizArgs = {
    filter: QuizFilterInput;
};

export type QueryQuizFeedbackQuestionsArgs = {
    exerciseType?: InputMaybe<SessionExerciseType>;
};

export type QueryQuizSessionScoreArgs = {
    sessionId: Scalars['String']['input'];
};

export type QueryRecommendedActivitiesArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryRecommendedExercisesArgs = {
    count: Scalars['Int']['input'];
};

export type QueryRecommendedFlashcardDecksArgs = {
    decksCount: Scalars['Int']['input'];
};

export type QueryRecommendedLessonsForPracticeSequenceQuizArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryRecommendedPracticeItemsArgs = {
    count: Scalars['Int']['input'];
};

export type QueryReferralByCodeArgs = {
    code: Scalars['String']['input'];
};

export type QuerySectionArgs = {
    id: Scalars['Int']['input'];
};

export type QuerySprintPackagesArgs = {
    countryIsoCode?: InputMaybe<Scalars['String']['input']>;
    currency?: InputMaybe<Currency>;
    filter?: InputMaybe<SprintPackagesFilterInput>;
    language: Scalars['String']['input'];
    testPackages?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryStartingModulesArgs = {
    curriculumId: Scalars['Int']['input'];
    sectionName: Scalars['SectionName']['input'];
};

export type QueryStudentAchievementArgs = {
    slug: Scalars['String']['input'];
};

export type QueryStudentAppointmentDatesArgs = {
    appointmentStatus?: InputMaybe<Scalars['String']['input']>;
    appointmentStatuses?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
    fromDate?: InputMaybe<Scalars['DateTime']['input']>;
    sort?: InputMaybe<Scalars['String']['input']>;
    studentId: Scalars['Int']['input'];
    toDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryStudentGoalWidgetDataArgs = {
    page: Scalars['Int']['input'];
};

export type QueryStudentModuleArgs = {
    id: Scalars['Int']['input'];
};

export type QueryStudentModulesArgs = {
    curriculumId?: InputMaybe<Scalars['Int']['input']>;
    sectionName?: InputMaybe<Scalars['SectionName']['input']>;
    studentId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryStudentYearlySummaryArgs = {
    year: Scalars['Int']['input'];
};

export type QueryStudentsLearningPlanForModuleArgs = {
    input: LearningPlanInput;
};

export type QueryTeacherCalendarArgs = {
    weekStartDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryTeacherCalendarEventDetailsArgs = {
    eventStartDate: Scalars['DateTime']['input'];
};

export type QueryTeacherClassArgs = {
    classId: Scalars['String']['input'];
};

export type QueryTeacherClassAppointmentLogQuestionsArgs = {
    classId: Scalars['String']['input'];
};

export type QueryTeacherClassObservationArgs = {
    classId: Scalars['String']['input'];
};

export type QueryTeacherInvoicesArgs = {
    limit: Scalars['Int']['input'];
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryTeacherLearningMaterialsArgs = {
    limit: Scalars['Int']['input'];
    page: Scalars['Int']['input'];
};

export type QueryTeacherProfileArgs = {
    teacherUserUid: Scalars['String']['input'];
};

export type QueryTeacherSubmittedLogsArgs = {
    limit: Scalars['Int']['input'];
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryTeacherUpcomingClassesArgs = {
    teacherUserUid: Scalars['String']['input'];
};

export type QueryTeacherValidateConfirmationTokenArgs = {
    token: Scalars['String']['input'];
};

export type QueryTeamsCoursesArgs = {
    countryIsoCode?: InputMaybe<Scalars['String']['input']>;
    currency?: InputMaybe<Currency>;
    language: Scalars['String']['input'];
    testPackages?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryUpcomingAppointmentsCountArgs = {
    appointmentStatus?: InputMaybe<Scalars['String']['input']>;
    classProvider?: InputMaybe<ClassroomProvider>;
    fromDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryUpcomingScheduledGroupClassesWithAvailableSeatsAndTeacherArgs = {
    teacherUserUid: Scalars['Ulid']['input'];
};

export type QueryUserByEmailArgs = {
    email: Scalars['String']['input'];
};

export type QueryVocabularyItemsArgs = {
    learningUnitId?: InputMaybe<Scalars['Int']['input']>;
    lessonIds?: ReadonlyArray<Scalars['Int']['input']>;
};

export type QueryZoomSessionParticipantsArgs = {
    zoomSessionId: Scalars['Ulid']['input'];
};

export type Question = {
    readonly __typename?: 'Question';
    readonly audioUrl: Maybe<Scalars['String']['output']>;
    readonly binaryDialogMeta: Maybe<QuestionBinaryDialogMeta>;
    readonly choices: ReadonlyArray<Choice>;
    readonly id: Scalars['String']['output'];
    readonly meta: Maybe<QuestionMeta>;
    readonly records: ReadonlyArray<QuestionRecord>;
    readonly title: Scalars['String']['output'];
    readonly titleTranslation: Maybe<QuestionTranslations>;
    readonly titleTranslations: ReadonlyArray<QuestionTranslations>;
    readonly translation: Maybe<QuestionTranslations>;
    readonly translations: ReadonlyArray<QuestionTranslations>;
};

export type QuestionBinaryDialogMeta = {
    readonly __typename?: 'QuestionBinaryDialogMeta';
    readonly gender: Scalars['String']['output'];
    readonly speaker: Scalars['String']['output'];
};

export type QuestionByWordTranslations = {
    readonly __typename?: 'QuestionByWordTranslations';
    readonly dictionaryForm: Maybe<Scalars['String']['output']>;
    readonly groupId: Maybe<Scalars['String']['output']>;
    readonly id: Scalars['String']['output'];
    readonly source: Scalars['String']['output'];
    readonly translation: Maybe<Scalars['String']['output']>;
};

export type QuestionMeta = {
    readonly __typename?: 'QuestionMeta';
    readonly gender: Maybe<GenderType>;
    readonly plural: Maybe<Scalars['String']['output']>;
    readonly sampleSentence: Maybe<Scalars['String']['output']>;
    readonly translation: Maybe<Scalars['String']['output']>;
    readonly translations: Maybe<QuizMetaTranslations>;
    readonly vocabularyItemId: Maybe<Scalars['String']['output']>;
    readonly wordClass: Maybe<Scalars['String']['output']>;
};

export type QuestionRecord = {
    readonly __typename?: 'QuestionRecord';
    readonly isReset: Scalars['Boolean']['output'];
    readonly isSkipped: Scalars['Boolean']['output'];
    readonly score: Scalars['Float']['output'];
};

export type QuestionResultInput = {
    readonly answers?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
    readonly isSkipped?: InputMaybe<Scalars['Boolean']['input']>;
    readonly questionId: Scalars['String']['input'];
};

export type QuestionTranslations = {
    readonly __typename?: 'QuestionTranslations';
    readonly byWord: ReadonlyArray<QuestionByWordTranslations>;
    readonly isIdiomaticExpression: Scalars['Boolean']['output'];
    readonly locale: Scalars['String']['output'];
    readonly wholeSentenceToTarget: Scalars['String']['output'];
};

export type QuestionnaireQuestion = {
    readonly __typename?: 'QuestionnaireQuestion';
    readonly answers: Maybe<ReadonlyArray<Maybe<QuestionnaireQuestionAnswer>>>;
    readonly description: Scalars['String']['output'];
    readonly id: Scalars['String']['output'];
    readonly question: Scalars['String']['output'];
    readonly questionSet: Maybe<QuestionnaireQuestionSet>;
};

export type QuestionnaireQuestionAnswer = {
    readonly __typename?: 'QuestionnaireQuestionAnswer';
    readonly answer: Scalars['String']['output'];
    readonly id: Scalars['String']['output'];
    readonly slug: Scalars['String']['output'];
};

export type QuestionnaireQuestionSet = {
    readonly __typename?: 'QuestionnaireQuestionSet';
    readonly id: Scalars['String']['output'];
    readonly name: Scalars['String']['output'];
};

export type Quiz = {
    readonly __typename?: 'Quiz';
    readonly completed: Scalars['Int']['output'];
    readonly hasMore: Scalars['Boolean']['output'];
    readonly session: Maybe<Session>;
    readonly total: Scalars['Int']['output'];
    readonly uncompleted: Scalars['Int']['output'];
};

export type QuizEngineModule = {
    readonly __typename?: 'QuizEngineModule';
    readonly learningUnits: ReadonlyArray<LearningUnit>;
    readonly name: Scalars['String']['output'];
};

export type QuizExercise = {
    readonly __typename?: 'QuizExercise';
    readonly id: Scalars['String']['output'];
    readonly questions: ReadonlyArray<Question>;
    readonly type: SessionExerciseType;
    readonly vocabularyId: Maybe<Scalars['ID']['output']>;
};

export type QuizFilterInput = {
    readonly first?: InputMaybe<Scalars['Int']['input']>;
};

export type QuizMetaTranslations = {
    readonly __typename?: 'QuizMetaTranslations';
    readonly de: Maybe<Scalars['String']['output']>;
    readonly en: Maybe<Scalars['String']['output']>;
    readonly es: Maybe<Scalars['String']['output']>;
    readonly fr: Maybe<Scalars['String']['output']>;
    readonly ru: Maybe<Scalars['String']['output']>;
};

export type QuizProgress = {
    readonly __typename?: 'QuizProgress';
    readonly completed: Scalars['Int']['output'];
    readonly known: Scalars['Int']['output'];
    readonly lastReviewed: Maybe<Scalars['DateTime']['output']>;
    readonly periodProgress: Maybe<LearningProgress>;
    readonly reviewed: Scalars['Int']['output'];
    readonly reviewing: Scalars['Int']['output'];
    readonly total: Scalars['Int']['output'];
    readonly uncompleted: Scalars['Int']['output'];
};

export enum RecurringSpecialType {
    LingodaLite = 'lingoda_lite',
}

export type Remuneration = {
    readonly __typename?: 'Remuneration';
    readonly groupMissed: Price;
    readonly individualMissed: Price;
};

export type ReportMultipleResultInput = {
    readonly lessonId?: InputMaybe<Scalars['Int']['input']>;
    readonly questionResults: ReadonlyArray<QuestionResultInput>;
    readonly sessionId: Scalars['String']['input'];
};

export type ReportResultInput = {
    readonly answers?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
    readonly isSkipped?: InputMaybe<Scalars['Boolean']['input']>;
    readonly lessonId?: InputMaybe<Scalars['Int']['input']>;
    readonly questionId: Scalars['String']['input'];
    readonly sessionId: Scalars['String']['input'];
};

export type ReportResultPayload = {
    readonly __typename?: 'ReportResultPayload';
    readonly answers: ReadonlyArray<Scalars['String']['output']>;
    readonly score: Scalars['Float']['output'];
    readonly sessionCompleted: Scalars['Boolean']['output'];
};

export type ReportVocabResultInput = {
    readonly known: Scalars['Boolean']['input'];
    readonly sessionId: Scalars['String']['input'];
    readonly vocabularyItemId: Scalars['String']['input'];
};

export enum ReviewMachineProvider {
    Google = 'google',
    Trustpilot = 'trustpilot',
}

export type Section = {
    readonly __typename?: 'Section';
    readonly id: Scalars['Int']['output'];
    readonly name: Scalars['SectionName']['output'];
};

export type SectionCurriculum = {
    readonly __typename?: 'SectionCurriculum';
    readonly curriculum: Curriculum;
    readonly section: Section;
    readonly slug: Scalars['String']['output'];
};

export type Session = {
    readonly __typename?: 'Session';
    readonly completedAt: Maybe<Scalars['DateTime']['output']>;
    readonly createdAt: Scalars['DateTime']['output'];
    readonly exercises: ReadonlyArray<QuizExercise>;
    readonly flashcards: ReadonlyArray<Flashcard>;
    readonly id: Scalars['String']['output'];
};

export enum SessionExerciseType {
    BinaryDialog = 'BINARY_DIALOG',
    Flashcard = 'FLASHCARD',
    MatchingExercise = 'MATCHING_EXERCISE',
    SingleChoice = 'SINGLE_CHOICE',
}

export type SessionInput = {
    readonly sessionCompleted: Scalars['Boolean']['input'];
    readonly sessionId: Scalars['String']['input'];
    readonly totalCorrect: Scalars['Int']['input'];
    readonly totalIncorrect: Scalars['Int']['input'];
    readonly totalSkipped: Scalars['Int']['input'];
};

export type SessionScore = {
    readonly __typename?: 'SessionScore';
    readonly avgPercentage: Scalars['Float']['output'];
    readonly exercises: ReadonlyArray<ExerciseScore>;
    readonly maxScore: Scalars['Int']['output'];
    readonly total: Scalars['Float']['output'];
    readonly totalPercentage: Scalars['Float']['output'];
};

export type SingleChoiceFeedbackInput = {
    readonly answersIds: ReadonlyArray<Scalars['String']['input']>;
    readonly exerciseId?: InputMaybe<Scalars['String']['input']>;
    readonly feedback?: InputMaybe<Scalars['String']['input']>;
    readonly questionId: Scalars['String']['input'];
    readonly vocabularyItemId: Scalars['String']['input'];
};

export enum SpokenLanguage {
    Arabic = 'Arabic',
    Chinese = 'Chinese',
    Czech = 'Czech',
    Dutch = 'Dutch',
    English = 'English',
    French = 'French',
    German = 'German',
    Greek = 'Greek',
    Hungarian = 'Hungarian',
    Italian = 'Italian',
    Japanese = 'Japanese',
    Korean = 'Korean',
    Polish = 'Polish',
    Portuguese = 'Portuguese',
    Russian = 'Russian',
    Spanish = 'Spanish',
    Swedish = 'Swedish',
    Thai = 'Thai',
    Turkish = 'Turkish',
    Ukrainian = 'Ukrainian',
    Vietnamese = 'Vietnamese',
}

export type Sprint = {
    readonly __typename?: 'Sprint';
    readonly durationMonths: Scalars['Int']['output'];
    readonly id: Scalars['String']['output'];
    readonly name: Scalars['String']['output'];
    readonly startDate: Scalars['DateTime']['output'];
    readonly startDateInStudentTimezone: Scalars['DateTime']['output'];
};

export type SprintCancelRolloverQuestionnaire = {
    readonly __typename?: 'SprintCancelRolloverQuestionnaire';
    readonly currentSprintQuestionnaireFinished: Scalars['Boolean']['output'];
    readonly questions: ReadonlyArray<SprintCancelRolloverQuestionnaireQuestion>;
};

export type SprintCancelRolloverQuestionnaireInput = {
    readonly answerId: Scalars['String']['input'];
    readonly feedback?: InputMaybe<Scalars['String']['input']>;
    readonly mood: EmojiType;
};

export type SprintCancelRolloverQuestionnaireQuestion = {
    readonly __typename?: 'SprintCancelRolloverQuestionnaireQuestion';
    readonly mood: EmojiType;
    readonly question: QuestionnaireQuestion;
};

export type SprintGeoPricedVariantResponseEntry = {
    readonly __typename?: 'SprintGeoPricedVariantResponseEntry';
    readonly currency: Maybe<Scalars['String']['output']>;
    readonly discounted_monthly_price_from_second_month: Scalars['Int']['output'];
    readonly discounted_percentage: Scalars['Float']['output'];
    readonly discounted_rest_of_first_month_price: Scalars['Int']['output'];
    readonly discounted_total_price: Scalars['Int']['output'];
    readonly entry_price: Scalars['Int']['output'];
    readonly language: Maybe<Scalars['String']['output']>;
    readonly monthly_price_from_second_month: Maybe<Scalars['Int']['output']>;
    readonly package_id: Maybe<Scalars['String']['output']>;
    readonly rest_of_first_month_price: Maybe<Scalars['Int']['output']>;
    readonly sprint: Maybe<Scalars['String']['output']>;
    readonly sprint_type: Maybe<Scalars['String']['output']>;
    readonly total_price: Scalars['Int']['output'];
};

export type SprintPackagesFilterInput = {
    readonly category?: InputMaybe<Scalars['String']['input']>;
};

export type StartingModule = {
    readonly __typename?: 'StartingModule';
    readonly code: Scalars['String']['output'];
    readonly curriculum: Curriculum;
    readonly description: Scalars['String']['output'];
    readonly id: Scalars['String']['output'];
    readonly modules: Maybe<ReadonlyArray<Module>>;
    readonly title: Scalars['String']['output'];
};

export enum Store {
    Internal = 'internal',
    Public = 'public',
    TestInternal = 'test_internal',
    TestPublic = 'test_public',
}

export type Student = {
    readonly __typename?: 'Student';
    readonly businessModelType: BusinessModelType;
    readonly completedClasses: Scalars['Int']['output'];
    readonly currentStudentModule: StudentModule;
    readonly curriculum: Curriculum;
    readonly firstLetterOfFirstName: Scalars['String']['output'];
    readonly firstName: Scalars['String']['output'];
    readonly hasBookedClass: Scalars['Boolean']['output'];
    readonly id: Scalars['Int']['output'];
    readonly lastCertificate: Maybe<StudentLanguageLevel>;
    readonly module: Maybe<Module>;
    readonly onboarded: Scalars['Boolean']['output'];
    readonly photo: Maybe<Image>;
    readonly preferences: StudentPreferences;
    readonly receivedCertificates: Scalars['Int']['output'];
    readonly registrationDate: Maybe<Scalars['DateTime']['output']>;
    readonly section: Section;
    /** EX: John D. */
    readonly shortName: Scalars['String']['output'];
    readonly showPracticeSatisfactionSurvey: Scalars['Boolean']['output'];
    readonly studentProfile: StudentProfile;
    readonly timeFormat: TimeFormat;
    readonly timezone: Scalars['String']['output'];
    readonly uid: Scalars['String']['output'];
    readonly weekStart: Maybe<Scalars['Int']['output']>;
};

export type StudentActivity = {
    readonly __typename?: 'StudentActivity';
    readonly activitiesCompleted: Scalars['Int']['output'];
    readonly activityGoal: Scalars['Int']['output'];
};

export enum StudentAppointmentLabel {
    FirstClass = 'FIRST_CLASS',
    NewToLingoda = 'NEW_TO_LINGODA',
    NoLabel = 'NO_LABEL',
}

export type StudentCreditsInfo = {
    readonly __typename?: 'StudentCreditsInfo';
    readonly canBuyExtraCredits: Scalars['Boolean']['output'];
    readonly contentBlocks: ReadonlyArray<ContentBlock>;
    readonly groupCredits: Scalars['Int']['output'];
    readonly privateCredits: Scalars['Int']['output'];
};

export type StudentGoal = {
    readonly __typename?: 'StudentGoal';
    readonly active: Scalars['Boolean']['output'];
    readonly createdAt: Scalars['DateTime']['output'];
    readonly dailyGoal: Scalars['Int']['output'];
    readonly id: Scalars['Ulid']['output'];
    readonly value: Scalars['Int']['output'];
};

export type StudentLanguageLevel = {
    readonly __typename?: 'StudentLanguageLevel';
    readonly cefrCertificatePdf: Scalars['String']['output'];
    readonly module: Module;
};

export type StudentLearningTeam = {
    readonly __typename?: 'StudentLearningTeam';
    readonly courseEndDate: Scalars['DateTime']['output'];
    readonly courseId: Scalars['Ulid']['output'];
    readonly courseName: Scalars['String']['output'];
    readonly courseStartDate: Scalars['DateTime']['output'];
    readonly modules: ReadonlyArray<StudentModule>;
    readonly participants: ReadonlyArray<Student>;
    readonly status: LearningTeamStatus;
    readonly teachers: ReadonlyArray<Teacher>;
    readonly timeSlots: ReadonlyArray<LearningTeamTimeSlot>;
    readonly timezone: Timezone;
};

export enum StudentLessonStatus {
    Retake = 'retake',
    Skip = 'skip',
}

export type StudentMarketingPreferences = {
    readonly __typename?: 'StudentMarketingPreferences';
    readonly marketingCommunicationEmail: Scalars['Boolean']['output'];
    readonly marketingCommunicationPhone: Scalars['Boolean']['output'];
    readonly marketingCommunicationWhatsApp: Scalars['Boolean']['output'];
};

export type StudentMarketingPreferencesInput = {
    readonly marketingCommunicationEmail: Scalars['Boolean']['input'];
    readonly marketingCommunicationPhone: Scalars['Boolean']['input'];
    readonly marketingCommunicationWhatsApp: Scalars['Boolean']['input'];
};

export type StudentModule = {
    readonly __typename?: 'StudentModule';
    readonly cefrLevelName: Maybe<Scalars['String']['output']>;
    readonly chaptersCount: Scalars['Int']['output'];
    readonly code: Scalars['String']['output'];
    readonly completedChaptersCount: Scalars['Int']['output'];
    readonly completedClasses: Scalars['Int']['output'];
    readonly completedLearningUnitsCount: Scalars['Int']['output'];
    readonly completedLingobites: Scalars['Int']['output'];
    readonly description: Scalars['String']['output'];
    readonly id: Scalars['Int']['output'];
    readonly learningUnits: ReadonlyArray<LearningUnit>;
    readonly learningUnitsCount: Scalars['Int']['output'];
    readonly longDescription: Scalars['String']['output'];
    readonly longName: Scalars['String']['output'];
    readonly name: Scalars['String']['output'];
    readonly position: Scalars['Int']['output'];
    readonly title: Maybe<Scalars['String']['output']>;
    readonly totalClasses: Scalars['Int']['output'];
    readonly totalLingobites: Scalars['Int']['output'];
};

export type StudentModulesData = {
    readonly __typename?: 'StudentModulesData';
    readonly studentId: Scalars['Int']['output'];
    readonly studentModules: ReadonlyArray<StudentModule>;
};

export type StudentNotification = {
    readonly __typename?: 'StudentNotification';
    readonly contentKeys: ReadonlyArray<Scalars['String']['output']>;
    readonly contentValues: ReadonlyArray<Scalars['String']['output']>;
    readonly createdAt: Scalars['DateTime']['output'];
    readonly id: Scalars['Ulid']['output'];
    readonly student: Student;
    readonly type: Scalars['String']['output'];
};

export type StudentNotificationPreferencesInput = {
    readonly emailNotifications?: InputMaybe<EmailNotificationsInput>;
    readonly systemNotifications?: InputMaybe<SystemNotificationsInput>;
};

export type StudentPreferences = {
    readonly __typename?: 'StudentPreferences';
    readonly emailNotifications: EmailNotifications;
    readonly marketingPreferences: StudentMarketingPreferences;
    readonly systemNotifications: SystemNotifications;
    readonly virtualBackground: Maybe<BackgroundImage>;
};

export type StudentProfile = {
    readonly __typename?: 'StudentProfile';
    readonly learningNeeds: Maybe<Scalars['String']['output']>;
    readonly profileFeedback: Maybe<Scalars['Boolean']['output']>;
    readonly spokenLanguages: ReadonlyArray<UserSpokenLanguage>;
    readonly studentSkills: ReadonlyArray<StudentSkill>;
    /** important: avoid calling in request for multiple students, because prefetch is not implemented */
    readonly whyAreYouLearning: WhyAreYouLearningSection;
};

export type StudentSkill = {
    readonly __typename?: 'StudentSkill';
    readonly answer: Scalars['String']['output'];
    readonly count: Scalars['Int']['output'];
    readonly sentiment: Maybe<AppointmentLogQuestionSentiment>;
};

export enum StudentStatus {
    Booked = 'BOOKED',
    Completed = 'COMPLETED',
    NotTaken = 'NOT_TAKEN',
    Pending = 'PENDING',
    Retake = 'RETAKE',
    Skip = 'SKIP',
}

export type StudentSubscriptionInfo = {
    readonly __typename?: 'StudentSubscriptionInfo';
    readonly currentSubscription: Maybe<Subscription>;
    readonly latestSubscription: Maybe<Subscription>;
    readonly nextSubscription: Maybe<Subscription>;
};

export type StudentYearlyActivitySummary = {
    readonly __typename?: 'StudentYearlyActivitySummary';
    readonly attendedMinutes: Maybe<Scalars['Int']['output']>;
    readonly certificatesEarned: ReadonlyArray<CertificateEarnedType>;
    readonly classesAttendedCount: Maybe<Scalars['Int']['output']>;
    readonly exercisesCompletedCount: Maybe<Scalars['Int']['output']>;
    readonly modulesAttended: ReadonlyArray<ModuleAttendedType>;
    readonly rank: Maybe<Scalars['String']['output']>;
    readonly studentsCount: Maybe<Scalars['Int']['output']>;
    readonly teachersCount: Maybe<Scalars['Int']['output']>;
    readonly topFeedbacksCount: ReadonlyArray<FeedbackCountType>;
    readonly weeksStraightCount: Maybe<Scalars['Int']['output']>;
    readonly year: Scalars['Int']['output'];
};

export type Subscription = {
    readonly __typename?: 'Subscription';
    readonly activatedAt: Maybe<Scalars['DateTime']['output']>;
    readonly canCancel: Scalars['Boolean']['output'];
    readonly canCancelTrial: Scalars['Boolean']['output'];
    readonly canChange: Scalars['Boolean']['output'];
    readonly canPause: Scalars['Boolean']['output'];
    readonly canReactivate: Scalars['Boolean']['output'];
    readonly canSkipTrial: Scalars['Boolean']['output'];
    readonly canStartNew: Scalars['Boolean']['output'];
    readonly canStartNextNow: Scalars['Boolean']['output'];
    readonly canUncancel: Scalars['Boolean']['output'];
    readonly canUnpause: Scalars['Boolean']['output'];
    readonly contentBlocks: ReadonlyArray<ContentBlock>;
    readonly createdAt: Maybe<Scalars['DateTime']['output']>;
    readonly expiresAt: Maybe<Scalars['DateTime']['output']>;
    readonly id: Scalars['Int']['output'];
    /** Tells whether a subscription is still within the trial period or was cancelled before the trial period ended */
    readonly isInTrial: Scalars['Boolean']['output'];
    readonly isRecurring: Scalars['Boolean']['output'];
    readonly origin: SubscriptionOrigin;
    readonly pauseScheduled: Scalars['Boolean']['output'];
    readonly paymentMethodAdded: Maybe<Scalars['Boolean']['output']>;
    readonly price: Price;
    readonly startDelayedUntil: Maybe<Scalars['DateTime']['output']>;
    readonly status: SubscriptionStatus;
    readonly subscribeNow: Scalars['Boolean']['output'];
    readonly type: SubscriptionType;
};

export type SubscriptionCancellationFeedbackInput = {
    readonly reason: Scalars['String']['input'];
    readonly slug: Scalars['String']['input'];
};

export type SubscriptionFeedback = {
    readonly __typename?: 'SubscriptionFeedback';
    readonly mood: Scalars['String']['output'];
    readonly periodType: Scalars['String']['output'];
    readonly reason: Scalars['String']['output'];
    readonly studentCancelReason: Scalars['String']['output'];
};

export enum SubscriptionOrigin {
    AppleAppStore = 'apple_app_store',
    GooglePlayMarket = 'google_play_market',
    Web = 'web',
}

export type SubscriptionPurchase = {
    readonly __typename?: 'SubscriptionPurchase';
    readonly agreements: ReadonlyArray<Agreement>;
    readonly canUseCoupon: Scalars['Boolean']['output'];
    readonly chargeTotal: Price;
    readonly contentBlocks: ReadonlyArray<ContentBlock>;
    readonly coupons: ReadonlyArray<Coupon>;
    readonly curriculum: Maybe<Curriculum>;
    readonly discount: Price;
    readonly displayName: Scalars['String']['output'];
    readonly groupCredits: Scalars['Int']['output'];
    readonly id: Scalars['String']['output'];
    readonly isSubscription: Scalars['Boolean']['output'];
    readonly lastTransaction: Maybe<Transaction>;
    readonly newSection: Maybe<Section>;
    readonly privateCredits: Scalars['Int']['output'];
    /** TODO: This is here for BC, drop this */
    readonly productDescription: Maybe<Scalars['String']['output']>;
    readonly recurrenceInterval: Maybe<DateInterval>;
    readonly subtotal: Price;
    readonly total: Price;
    readonly type: PurchaseType;
    /**
     * For recurring, this is the same as the recurrenceInterval.
     * For non-recurring in general, it is the validity
     * For non-recurring LegacyPurchase specifically, it is coming from product.creditsValidityInterval
     */
    readonly validityInterval: DateInterval;
    readonly withTrial: Scalars['Boolean']['output'];
};

export enum SubscriptionStatus {
    Active = 'active',
    Cancelled = 'cancelled',
    Inactive = 'inactive',
    Next = 'next',
    Paused = 'paused',
    Suspended = 'suspended',
}

export enum SubscriptionType {
    FreeTrial = 'free_trial',
    LearningTeam = 'learning_team',
    Legacy = 'legacy',
    NonRecurring = 'non_recurring',
    Recurring = 'recurring',
    Sprint = 'sprint',
}

export type SymfonyUserImpl = SymfonyUserInterface & {
    readonly __typename?: 'SymfonyUserImpl';
    readonly roles: ReadonlyArray<Scalars['String']['output']>;
    readonly userName: Scalars['String']['output'];
};

export type SymfonyUserInterface = {
    readonly roles: ReadonlyArray<Scalars['String']['output']>;
    readonly userName: Scalars['String']['output'];
};

export type SystemConfig = {
    readonly __typename?: 'SystemConfig';
    readonly classConfig: ClassConfig;
    readonly currencies: ReadonlyArray<Currency>;
    readonly deployEnvironment: Scalars['String']['output'];
    readonly domains: Scalars['Map']['output'];
    readonly kameleoonSiteCode: Scalars['String']['output'];
    readonly languages: Scalars['StringMap']['output'];
    readonly minimumCharges: Scalars['Map']['output'];
    readonly presentationModeEndpoint: Scalars['String']['output'];
    readonly releaseId: Scalars['String']['output'];
    /** @deprecated TODO: Remove in another deployment */
    readonly sentryFrontendDsn: Scalars['String']['output'];
    readonly stripePublishable: Scalars['String']['output'];
    readonly timezones: ReadonlyArray<Timezone>;
};

export type SystemNotifications = {
    readonly __typename?: 'SystemNotifications';
    readonly nextClass: Scalars['Boolean']['output'];
    readonly oneClickBooking: Scalars['Boolean']['output'];
};

export type SystemNotificationsInput = {
    readonly newClass?: InputMaybe<Scalars['Boolean']['input']>;
    readonly oneClickBooking?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Teacher = {
    readonly __typename?: 'Teacher';
    readonly agreements: ReadonlyArray<Agreement>;
    readonly canAppealPenalty: Scalars['Boolean']['output'];
    readonly canTeachGroupClass: Scalars['Boolean']['output'];
    readonly canTeachPrivateClass: Scalars['Boolean']['output'];
    readonly email: Scalars['String']['output'];
    readonly feedback: ReadonlyArray<TeacherFeedback>;
    readonly firstName: Scalars['String']['output'];
    readonly id: Scalars['Int']['output'];
    readonly lastName: Maybe<Scalars['String']['output']>;
    readonly notificationSettings: Maybe<TeacherNotificationSettings>;
    readonly pausedFrom: Maybe<Scalars['DateTime']['output']>;
    readonly pausedUntil: Maybe<Scalars['DateTime']['output']>;
    readonly penaltyUntil: Maybe<Scalars['DateTime']['output']>;
    readonly photo: Maybe<Image>;
    readonly rating: TeacherRating;
    readonly section: Maybe<Section>;
    readonly teacherInfo: Maybe<TeacherInfo>;
    readonly teacherPreferences: TeacherPreference;
    readonly uid: Scalars['String']['output'];
};

export type TeacherAccountSettingsInput = {
    readonly email: Scalars['String']['input'];
};

export type TeacherAvailableCertificateType = {
    readonly __typename?: 'TeacherAvailableCertificateType';
    readonly label: Scalars['String']['output'];
    readonly type: Scalars['String']['output'];
};

export type TeacherAvailableCourse = {
    readonly __typename?: 'TeacherAvailableCourse';
    readonly classesCount: Scalars['Int']['output'];
    readonly courseModules: ReadonlyArray<Module>;
    readonly courseTimes: ReadonlyArray<CourseDayAndHour>;
    readonly courseType: Scalars['String']['output'];
    readonly endDate: Scalars['DateTime']['output'];
    readonly id: Scalars['Ulid']['output'];
    readonly material: Scalars['String']['output'];
    readonly name: Scalars['String']['output'];
    readonly price: Price;
    readonly startDate: Scalars['DateTime']['output'];
    readonly studentsCount: Scalars['Int']['output'];
};

export type TeacherAvailableCoursesListDto = {
    readonly __typename?: 'TeacherAvailableCoursesListDTO';
    readonly courses: ReadonlyArray<TeacherAvailableCourse>;
    readonly isPriceVisible: Scalars['Boolean']['output'];
};

export type TeacherCalendar = {
    readonly __typename?: 'TeacherCalendar';
    readonly startOfWeekDate: Scalars['DateTime']['output'];
    readonly timeSlots: ReadonlyArray<TeacherCalendarTimeSlot>;
    readonly totalAvailableClassesPerWeek: Maybe<Scalars['Int']['output']>;
    readonly totalBookedClassesThisWeek: Scalars['Int']['output'];
};

export type TeacherCalendarEvent = {
    readonly __typename?: 'TeacherCalendarEvent';
    readonly classDuration: Scalars['Int']['output'];
    readonly classType: ClassType;
    readonly classUniqId: Scalars['String']['output'];
    readonly isB2G: Scalars['Boolean']['output'];
    readonly isBonus: Scalars['Boolean']['output'];
    readonly isCommittedByTeacher: Scalars['Boolean']['output'];
    readonly price: Maybe<Price>;
};

export type TeacherCalendarEventDetails = {
    readonly __typename?: 'TeacherCalendarEventDetails';
    readonly classDuration: Scalars['Int']['output'];
    readonly classType: ClassType;
    readonly classUniqId: Scalars['String']['output'];
    readonly courseId: Maybe<Scalars['String']['output']>;
    readonly isB2G: Scalars['Boolean']['output'];
    readonly isBonus: Scalars['Boolean']['output'];
    readonly isCommittedByTeacher: Scalars['Boolean']['output'];
    readonly isLanguageClass: Scalars['Boolean']['output'];
    readonly lesson: Maybe<Lesson>;
    readonly module: Maybe<Scalars['String']['output']>;
    readonly previousClassLogsUrl: Maybe<Scalars['String']['output']>;
    readonly price: Maybe<Price>;
    readonly students: ReadonlyArray<Student>;
};

export type TeacherCalendarEventDetailsList = {
    readonly __typename?: 'TeacherCalendarEventDetailsList';
    readonly eventStartDate: Scalars['DateTime']['output'];
    readonly events: ReadonlyArray<TeacherCalendarEventDetails>;
    readonly isPriceVisible: Scalars['Boolean']['output'];
};

export type TeacherCalendarTimeSlot = {
    readonly __typename?: 'TeacherCalendarTimeSlot';
    readonly events: ReadonlyArray<TeacherCalendarEvent>;
    readonly startDate: Scalars['DateTime']['output'];
};

export type TeacherCertificate = {
    readonly __typename?: 'TeacherCertificate';
    readonly id: Scalars['String']['output'];
    readonly label: Maybe<Scalars['String']['output']>;
    readonly type: Maybe<CertificateType>;
    readonly url: Scalars['String']['output'];
};

export type TeacherEmailConfirmationInput = {
    readonly email: Scalars['String']['input'];
    readonly reCaptcha: Scalars['String']['input'];
};

export type TeacherFeedback = {
    readonly __typename?: 'TeacherFeedback';
    readonly classStartDate: Scalars['DateTime']['output'];
    readonly feedback: Scalars['String']['output'];
    readonly lessonTitle: Scalars['String']['output'];
};

export type TeacherFeedbackInput = {
    readonly teacherCustomFeedback?: InputMaybe<Scalars['String']['input']>;
    readonly teacherFeedbackAnswers?: ReadonlyArray<Scalars['String']['input']>;
    readonly teacherRating: Scalars['Float']['input'];
};

export type TeacherInfo = {
    readonly __typename?: 'TeacherInfo';
    readonly aboutMe: Scalars['String']['output'];
    readonly address: Maybe<Scalars['String']['output']>;
    readonly address2: Maybe<Scalars['String']['output']>;
    readonly bic: Scalars['String']['output'];
    readonly city: Maybe<Scalars['String']['output']>;
    readonly country: Maybe<Country>;
    readonly defaultPaymentMethod: Maybe<PaymentMethod>;
    readonly iban: Scalars['String']['output'];
    readonly inCommunity: Scalars['Boolean']['output'];
    readonly incomeTaxNumber: Scalars['String']['output'];
    readonly isB2B: Scalars['Boolean']['output'];
    readonly isB2G: Scalars['Boolean']['output'];
    readonly isExternal: Scalars['Boolean']['output'];
    readonly isOnboarded: Scalars['Boolean']['output'];
    readonly languages: ReadonlyArray<Language>;
    readonly paypal: Scalars['String']['output'];
    readonly postalCode: Maybe<Scalars['String']['output']>;
    readonly state: Maybe<Scalars['String']['output']>;
    readonly vatTaxNumber: Maybe<Scalars['String']['output']>;
};

export type TeacherInvoice = {
    readonly __typename?: 'TeacherInvoice';
    readonly currency: Currency;
    readonly date: Maybe<Scalars['DateTime']['output']>;
    readonly id: Scalars['Int']['output'];
    readonly items: ReadonlyArray<TeacherInvoiceItem>;
    readonly pdfUrl: Maybe<Scalars['String']['output']>;
    readonly pending: Scalars['Boolean']['output'];
    readonly total: Scalars['Float']['output'];
};

export type TeacherInvoiceItem = {
    readonly __typename?: 'TeacherInvoiceItem';
    readonly amount: Price;
    readonly bonusType: Scalars['Boolean']['output'];
    readonly class: Maybe<Klass>;
    readonly date: Scalars['DateTime']['output'];
    readonly description: Scalars['String']['output'];
    readonly id: Scalars['Int']['output'];
};

export type TeacherLanguagesInput = {
    readonly languages: ReadonlyArray<LanguageInput>;
};

export type TeacherNotificationSettings = {
    readonly __typename?: 'TeacherNotificationSettings';
    readonly feedback: Scalars['Boolean']['output'];
    readonly otherClassCancellation: Scalars['Boolean']['output'];
    readonly shortNoticeCancellation: Scalars['Boolean']['output'];
};

export type TeacherNotificationSettingsInput = {
    readonly feedback: Scalars['Boolean']['input'];
    readonly otherClassCancellation: Scalars['Boolean']['input'];
    readonly shortNoticeCancellation: Scalars['Boolean']['input'];
};

export type TeacherPreference = {
    readonly __typename?: 'TeacherPreference';
    readonly groupClasses: Scalars['Boolean']['output'];
    readonly privateClasses: Scalars['Boolean']['output'];
    readonly specializedClasses: Scalars['Boolean']['output'];
    readonly timeFormat: Maybe<TimeFormat>;
    readonly timezone: Scalars['String']['output'];
    readonly weekStart: Scalars['Int']['output'];
};

export type TeacherRating = {
    readonly __typename?: 'TeacherRating';
    readonly classesTaught: Scalars['Int']['output'];
    readonly date: Scalars['DateTime']['output'];
    readonly personalAverage: Scalars['Float']['output'];
    readonly sectionAverage: Scalars['Float']['output'];
};

export type TeacherRegistrationInput = {
    readonly firstName: Scalars['String']['input'];
    readonly lastName: Scalars['String']['input'];
    readonly password: Scalars['String']['input'];
    readonly reCaptcha: Scalars['String']['input'];
    readonly token: Scalars['String']['input'];
};

export type TechnicalQualityFeedback = {
    readonly __typename?: 'TechnicalQualityFeedback';
    readonly feedback: Maybe<Scalars['String']['output']>;
    readonly feedbackAnswers: ReadonlyArray<ClassFeedbackAnswer>;
    readonly id: Scalars['Ulid']['output'];
    readonly metadata: Maybe<ReadonlyArray<Scalars['String']['output']>>;
    readonly origin: TechnicalQualityFeedbackOrigin;
    readonly rate: Maybe<Scalars['String']['output']>;
    readonly userType: UserType;
};

export type TechnicalQualityFeedbackInput = {
    readonly browserName?: InputMaybe<Scalars['String']['input']>;
    readonly browserVersion?: InputMaybe<Scalars['String']['input']>;
    readonly isMobile?: InputMaybe<Scalars['Boolean']['input']>;
    readonly techQualityCustomFeedback?: InputMaybe<Scalars['String']['input']>;
    readonly techQualityFeedbackAnswers?: ReadonlyArray<Scalars['String']['input']>;
    readonly techQualityRating?: InputMaybe<Scalars['Float']['input']>;
};

export enum TechnicalQualityFeedbackOrigin {
    Lingoda = 'lingoda',
    Zoom = 'zoom',
}

export type TestAdmin = {
    readonly __typename?: 'TestAdmin';
    readonly email: Scalars['String']['output'];
    readonly id: Scalars['Int']['output'];
    readonly roles: ReadonlyArray<Scalars['String']['output']>;
    readonly userName: Scalars['String']['output'];
};

export type TestAppointment = {
    readonly __typename?: 'TestAppointment';
    readonly class: TestClass;
    readonly status: AppointmentStatus;
};

export type TestClass = {
    readonly __typename?: 'TestClass';
    readonly id: Scalars['Int']['output'];
    readonly lesson: Maybe<Lesson>;
    readonly startDate: Scalars['DateTime']['output'];
    readonly status: ClassStatus;
    readonly type: Scalars['String']['output'];
    readonly uid: Scalars['String']['output'];
    readonly uniqueId: Maybe<Scalars['String']['output']>;
};

export type TestCredit = {
    readonly __typename?: 'TestCredit';
    readonly amount: Scalars['Int']['output'];
    readonly availabilityDate: Scalars['DateTime']['output'];
    readonly expirationDate: Scalars['DateTime']['output'];
    readonly type: Scalars['String']['output'];
};

export type TestStudent = {
    readonly __typename?: 'TestStudent';
    readonly email: Scalars['String']['output'];
    readonly id: Scalars['Int']['output'];
    readonly userId: Scalars['Int']['output'];
};

export type TestSubscription = {
    readonly __typename?: 'TestSubscription';
    readonly allowsCancellation: Scalars['Boolean']['output'];
    readonly allowsPause: Scalars['Boolean']['output'];
    readonly expirationDate: Maybe<Scalars['DateTime']['output']>;
    readonly expired: Scalars['Boolean']['output'];
    readonly id: Scalars['Int']['output'];
    readonly lastChargeDate: Maybe<Scalars['DateTime']['output']>;
    readonly months: Scalars['Int']['output'];
    readonly nextChargeDate: Maybe<Scalars['DateTime']['output']>;
    readonly price: Price;
    readonly resumingDate: Maybe<Scalars['DateTime']['output']>;
    readonly status: SubscriptionStatus;
};

export type TestTeacher = {
    readonly __typename?: 'TestTeacher';
    readonly email: Scalars['String']['output'];
    readonly id: Scalars['Int']['output'];
    readonly roles: ReadonlyArray<Scalars['String']['output']>;
    readonly userName: Scalars['String']['output'];
};

export enum TimeFormat {
    Ampm = 'AMPM',
    Iso8601 = 'ISO8601',
}

export type Timezone = {
    readonly __typename?: 'Timezone';
    readonly label: Scalars['String']['output'];
    readonly tz: Scalars['String']['output'];
};

export type Transaction = {
    readonly __typename?: 'Transaction';
    readonly amount: Price;
    readonly coupons: ReadonlyArray<Coupon>;
    readonly currency: Scalars['String']['output'];
    readonly discount: Price;
    readonly errorCode: Maybe<Scalars['String']['output']>;
    readonly errorMessage: Maybe<Scalars['String']['output']>;
    readonly id: Scalars['Int']['output'];
    readonly isInitialPurchase: Scalars['Boolean']['output'];
    readonly isPaymentMethodUpdate: Scalars['Boolean']['output'];
    readonly paymentStatus: PaymentStatus;
};

export type TwoWeeksLeftUntilChallengeEndDto = {
    readonly __typename?: 'TwoWeeksLeftUntilChallengeEndDTO';
    readonly subtitle: Scalars['String']['output'];
    readonly title: Scalars['String']['output'];
};

export type TwoWeeksLeftUntilChallengeEndForUnjoinedDto = {
    readonly __typename?: 'TwoWeeksLeftUntilChallengeEndForUnjoinedDTO';
    readonly subtitle: Scalars['String']['output'];
    readonly title: Scalars['String']['output'];
};

export type UnionClassFeedbackClassTeacherFeedback = ClassFeedback | ClassTeacherFeedback;

export type UpdatePaymentInfoInput = {
    readonly address?: InputMaybe<Scalars['String']['input']>;
    readonly address2?: InputMaybe<Scalars['String']['input']>;
    readonly bic?: InputMaybe<Scalars['String']['input']>;
    readonly city?: InputMaybe<Scalars['String']['input']>;
    readonly countryCode?: InputMaybe<CountryCode>;
    readonly defaultPaymentMethod: PaymentMethod;
    readonly iban?: InputMaybe<Scalars['String']['input']>;
    readonly incomeTaxNumber?: InputMaybe<Scalars['String']['input']>;
    readonly paypal?: InputMaybe<Scalars['String']['input']>;
    readonly postalCode?: InputMaybe<Scalars['String']['input']>;
    readonly state?: InputMaybe<Scalars['String']['input']>;
    readonly vatTaxNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTeacherPreferencesInput = {
    readonly groupClasses: Scalars['Boolean']['input'];
    readonly privateClasses: Scalars['Boolean']['input'];
    readonly specializedClasses: Scalars['Boolean']['input'];
    readonly timeFormat?: InputMaybe<TimeFormat>;
    readonly timezone?: InputMaybe<Scalars['String']['input']>;
    readonly weekStart?: InputMaybe<Scalars['Int']['input']>;
};

export type User = {
    readonly __typename?: 'User';
    readonly email: Maybe<Scalars['String']['output']>;
    readonly firstname: Scalars['String']['output'];
    readonly fullName: Scalars['String']['output'];
    readonly id: Scalars['Int']['output'];
    readonly isEmailVerified: Scalars['Boolean']['output'];
    readonly lastname: Maybe<Scalars['String']['output']>;
    readonly locale: Scalars['String']['output'];
    readonly phoneNumber: Maybe<Scalars['String']['output']>;
    readonly photo: Maybe<Image>;
    readonly uid: Scalars['String']['output'];
};

export type UserAttendance = {
    readonly __typename?: 'UserAttendance';
    readonly attendances: ReadonlyArray<B2Attendance>;
    readonly userId: Scalars['Int']['output'];
};

export type UserAttendances = {
    readonly __typename?: 'UserAttendances';
    readonly attendances: ReadonlyArray<AppointmentAttendance>;
    readonly userId: Scalars['Int']['output'];
};

export type UserChallenge = {
    readonly __typename?: 'UserChallenge';
    readonly id: Scalars['Int']['output'];
    readonly isInterested: Maybe<Scalars['Boolean']['output']>;
    readonly name: Scalars['String']['output'];
};

export enum UserDisplayedWidgetClassEnum {
    CalendarReminderWidget = 'CalendarReminderWidget',
    CancellationPolicyUpdateBanner = 'CancellationPolicyUpdateBanner',
    CreditsConversionBanner = 'CreditsConversionBanner',
    EntryPointReleaseMyClassesBanner = 'EntryPointReleaseMyClassesBanner',
    EntryPointReleaseQuizBanner = 'EntryPointReleaseQuizBanner',
    EntryPointReleaseTodayPageBanner = 'EntryPointReleaseTodayPageBanner',
    GoalSettingV2Banner = 'GoalSettingV2Banner',
    H5pAnnouncementBanner = 'H5pAnnouncementBanner',
    LingobitesBanner = 'LingobitesBanner',
    MultiDateAnnouncement = 'MultiDateAnnouncement',
    MyCourseUpgradeAnnouncement = 'MyCourseUpgradeAnnouncement',
    PersonalizedFeedbackDismissedAt = 'PersonalizedFeedbackDismissedAt',
    StudentFillProfileReminderClassDetailsPageBanner = 'StudentFillProfileReminderClassDetailsPageBanner',
    StudentFillProfileReminderProfilePageBanner = 'StudentFillProfileReminderProfilePageBanner',
    StudentFillProfileReminderProfileSettingsPageBanner = 'StudentFillProfileReminderProfileSettingsPageBanner',
    StudentFillProfileReminderTodayPageBanner = 'StudentFillProfileReminderTodayPageBanner',
    StudentFutureClassInClassroomBanner = 'StudentFutureClassInClassroomBanner',
}

export enum UserDisplayedWidgetNewClassroomBannerEnum {
    InformParticipantsAboutFlexibility = 'InformParticipantsAboutFlexibility',
    StudentFillProfileReminderClassDetailsPageBanner = 'StudentFillProfileReminderClassDetailsPageBanner',
    StudentFillProfileReminderProfilePageBanner = 'StudentFillProfileReminderProfilePageBanner',
    StudentFillProfileReminderProfileSettingsPageBanner = 'StudentFillProfileReminderProfileSettingsPageBanner',
    StudentFillProfileReminderTodayPageBanner = 'StudentFillProfileReminderTodayPageBanner',
    StudentFutureClassInClassroomBanner = 'StudentFutureClassInClassroomBanner',
    TeacherFutureClassInClassroomBanner = 'TeacherFutureClassInClassroomBanner',
    TeacherInformTeacherAboutNewMuteFlow = 'TeacherInformTeacherAboutNewMuteFlow',
}

export type UserExternalCalendarDto = {
    readonly __typename?: 'UserExternalCalendarDTO';
    readonly email: Maybe<Scalars['String']['output']>;
    readonly id: Maybe<Scalars['Ulid']['output']>;
    readonly status: Maybe<AuthTokenStatus>;
    readonly type: CalendarType;
};

export enum UserLanguage {
    Abk = 'abk',
    Ace = 'ace',
    Ach = 'ach',
    Ada = 'ada',
    Afr = 'afr',
    Akn = 'akn',
    Amh = 'amh',
    Ara = 'ara',
    Arg = 'arg',
    Arm = 'arm',
    Asm = 'asm',
    Ast = 'ast',
    Azj = 'azj',
    Bam = 'bam',
    Bel = 'bel',
    Ben = 'ben',
    Bih = 'bih',
    Bis = 'bis',
    Bos = 'bos',
    Bul = 'bul',
    Cat = 'cat',
    Ceb = 'ceb',
    Ces = 'ces',
    Che = 'che',
    Cmn = 'cmn',
    Dan = 'dan',
    Deu = 'deu',
    Ell = 'ell',
    Eng = 'eng',
    Est = 'est',
    Eus = 'eus',
    Fas = 'fas',
    Fij = 'fij',
    Fil = 'fil',
    Fin = 'fin',
    Fra = 'fra',
    Gld = 'gld',
    Gle = 'gle',
    Glg = 'glg',
    Grn = 'grn',
    Guj = 'guj',
    Hat = 'hat',
    Hau = 'hau',
    Heb = 'heb',
    Hin = 'hin',
    Hrv = 'hrv',
    Hun = 'hun',
    Ibo = 'ibo',
    Ind = 'ind',
    Isl = 'isl',
    Ita = 'ita',
    Jav = 'jav',
    Jpn = 'jpn',
    Kan = 'kan',
    Kat = 'kat',
    Kaz = 'kaz',
    Khm = 'khm',
    Kin = 'kin',
    Kir = 'kir',
    Kor = 'kor',
    Kur = 'kur',
    Lao = 'lao',
    Lav = 'lav',
    Lit = 'lit',
    Ltz = 'ltz',
    Mao = 'mao',
    Mar = 'mar',
    Mkd = 'mkd',
    Mlg = 'mlg',
    Mlt = 'mlt',
    Mon = 'mon',
    Msa = 'msa',
    Nepal = 'nepal',
    Nld = 'nld',
    Nor = 'nor',
    Pan = 'pan',
    Pol = 'pol',
    Por = 'por',
    Pus = 'pus',
    Ron = 'ron',
    Rus = 'rus',
    Sin = 'sin',
    Slk = 'slk',
    Slv = 'slv',
    Smo = 'smo',
    Som = 'som',
    Spa = 'spa',
    Sqi = 'sqi',
    Srp = 'srp',
    Sun = 'sun',
    Swa = 'swa',
    Swe = 'swe',
    Tam = 'tam',
    Tel = 'tel',
    Tgk = 'tgk',
    Tha = 'tha',
    Tig = 'tig',
    Tur = 'tur',
    Ukr = 'ukr',
    Urd = 'urd',
    Uzb = 'uzb',
    Vie = 'vie',
    Wel = 'wel',
    Wuu = 'wuu',
    Xho = 'xho',
    Yid = 'yid',
    Yor = 'yor',
    Yue = 'yue',
    Zho = 'zho',
    Zul = 'zul',
}

export type UserLanguageAttendance = {
    readonly __typename?: 'UserLanguageAttendance';
    readonly attendances: ReadonlyArray<LanguageAttendance>;
    readonly userId: Scalars['Int']['output'];
};

export type UserLearningPlan = {
    readonly __typename?: 'UserLearningPlan';
    readonly learningUnits: ReadonlyArray<LearningPlanLearningUnit>;
    readonly userId: Scalars['Int']['output'];
};

export type UserLiveClassAttendance = {
    readonly __typename?: 'UserLiveClassAttendance';
    readonly attendances: ReadonlyArray<LiveClassAttendance>;
    readonly userId: Scalars['Int']['output'];
};

export type UserSpokenLanguage = {
    readonly __typename?: 'UserSpokenLanguage';
    readonly language: UserLanguage;
    readonly languageFluency: LanguageFluency;
};

export enum UserType {
    Student = 'student',
    Teacher = 'teacher',
}

export type Variant = {
    readonly __typename?: 'Variant';
    readonly classType: ClassType;
    readonly contentBlocks: ReadonlyArray<ContentBlock>;
    readonly curriculum: Maybe<Curriculum>;
    readonly groupCredits: Scalars['Int']['output'];
    readonly id: Maybe<Scalars['Ulid']['output']>;
    readonly isCurrent: Scalars['Boolean']['output'];
    readonly percentageOfSavings: Scalars['Int']['output'];
    readonly pricePerClass: Maybe<Price>;
    readonly privateCredits: Scalars['Int']['output'];
    readonly recurringSpecialType: Maybe<RecurringSpecialType>;
    readonly sectionCurriculum: Maybe<SectionCurriculum>;
    readonly subTotal: Price;
    readonly type: PurchaseType;
    readonly validityIntervalInMonths: Scalars['Int']['output'];
};

export enum VirtualClassroomEvent {
    AdmitsStudents = 'admits_students',
    AskToJoinAvailable = 'ask_to_join_available',
    AskedToJoin = 'asked_to_join',
    EndsClassForAll = 'ends_class_for_all',
    JoinedClass = 'joined_class',
    JoinedWaitingRoom = 'joined_waiting_room',
    LeavesClass = 'leaves_class',
    LeavesWaitingRoom = 'leaves_waiting_room',
    ReceivedAdmitRequest = 'received_admit_request',
}

export type VocabularyItem = {
    readonly __typename?: 'VocabularyItem';
    readonly answerToTestQuestion: Maybe<Scalars['String']['output']>;
    readonly audio: Maybe<Media>;
    readonly audioUrl: Maybe<Scalars['Url']['output']>;
    readonly audioUrls: Maybe<VocabularyItemAudio>;
    readonly cefrLevel: CefrLevel;
    readonly flashcardIsCompleted: Scalars['Boolean']['output'];
    readonly gender: Maybe<GenderType>;
    readonly id: Scalars['String']['output'];
    readonly image: Maybe<Image>;
    readonly isCompleted: Scalars['Boolean']['output'];
    readonly isOftenSingular: Scalars['Boolean']['output'];
    readonly isRealLifeLanguage: Scalars['Boolean']['output'];
    readonly item: Maybe<Scalars['String']['output']>;
    readonly lessons: ReadonlyArray<Lesson>;
    readonly plural: Maybe<Scalars['String']['output']>;
    readonly relatedItems: ReadonlyArray<VocabularyItem>;
    readonly sampleSentenceOne: Scalars['String']['output'];
    readonly section: Section;
    readonly testQuestion: Scalars['String']['output'];
    readonly title: Scalars['String']['output'];
    readonly translations: ReadonlyArray<Maybe<VocabularyItemTrans>>;
    readonly wordClass: VocabularyItemWordClass;
};

export type VocabularyItemAudio = {
    readonly __typename?: 'VocabularyItemAudio';
    readonly target: Scalars['String']['output'];
    readonly translation: Scalars['String']['output'];
};

export type VocabularyItemTrans = {
    readonly __typename?: 'VocabularyItemTrans';
    readonly item: Maybe<Scalars['String']['output']>;
    readonly locale: Scalars['String']['output'];
};

export enum VocabularyItemWordClass {
    Adjective = 'adjective',
    Adverb = 'adverb',
    Noun = 'noun',
    Other = 'other',
    Phrase = 'phrase',
    Verb = 'verb',
}

export enum VocabularyStatus {
    Completed = 'Completed',
    Uncompleted = 'Uncompleted',
}

export type WeeklyProgress = {
    readonly __typename?: 'WeeklyProgress';
    readonly attendance: Scalars['Int']['output'];
    readonly goal: Maybe<Scalars['Int']['output']>;
    readonly longLabel: Scalars['String']['output'];
    readonly monthName: Scalars['String']['output'];
    readonly shortLabel: Scalars['String']['output'];
};

export enum WhyAreYouLearningAnswerEnum {
    ForFamilyAndOrFriends = 'for_family_and_or_friends',
    ForIntegration = 'for_integration',
    ForPleasure = 'for_pleasure',
    ForSelfDevelopment = 'for_self_development',
    ForTravel = 'for_travel',
    ForWork = 'for_work',
    ILiveInACountryWhereTheLanguageIsSpoken = 'i_live_in_a_country_where_the_language_is_spoken',
    IWillMoveToACountryWhereTheLanguageIsSpoken = 'i_will_move_to_a_country_where_the_language_is_spoken',
    ToConnectWithMyHeritage = 'to_connect_with_my_heritage',
    ToGetACertificate = 'to_get_a_certificate',
    ToGetAVisa = 'to_get_a_visa',
    ToLearnAboutOtherCultures = 'to_learn_about_other_cultures',
}

export type WhyAreYouLearningSection = {
    readonly __typename?: 'WhyAreYouLearningSection';
    readonly answers: ReadonlyArray<Scalars['String']['output']>;
    readonly otherOption: Maybe<Scalars['String']['output']>;
    readonly questions: ReadonlyArray<Scalars['String']['output']>;
};

export type WidgetData = {
    readonly __typename?: 'WidgetData';
    readonly firstAppointmentDate: Maybe<Scalars['DateTime']['output']>;
    readonly hasNext: Scalars['Boolean']['output'];
    readonly hasPrevious: Scalars['Boolean']['output'];
    readonly page: Scalars['Int']['output'];
    readonly range: Scalars['String']['output'];
    readonly weeklyGoal: Maybe<Scalars['Int']['output']>;
    readonly weeklyProgress: ReadonlyArray<WeeklyProgress>;
};

export enum WordStatus {
    Known = 'KNOWN',
    New = 'NEW',
    Reviewing = 'REVIEWING',
}

export type ZoomSession = {
    readonly __typename?: 'ZoomSession';
    readonly id: Scalars['Ulid']['output'];
    readonly token: Scalars['String']['output'];
    readonly zoomSessionName: Scalars['String']['output'];
    readonly zoomSessionPassword: Scalars['String']['output'];
};

export type ZoomSessionTokenArgs = {
    audioCompatibilityMode: Scalars['Boolean']['input'];
};

export type ZoomSessionParticipant = {
    readonly __typename?: 'ZoomSessionParticipant';
    readonly id: Scalars['Ulid']['output'];
    readonly sdkUserId: Scalars['Int']['output'];
    readonly status: Maybe<ZoomSessionParticipantStatus>;
    readonly user: User;
    readonly zoomSession: ZoomSession;
};

export type ZoomSessionParticipantInput = {
    readonly sdkUserId: Scalars['Int']['input'];
    readonly status: Scalars['String']['input'];
    readonly userUid?: InputMaybe<Scalars['String']['input']>;
    readonly zoomSessionId: Scalars['Ulid']['input'];
};

export enum ZoomSessionParticipantStatus {
    Active = 'active',
    Blocked = 'blocked',
    Connected = 'connected',
    Denied = 'denied',
    Pending = 'pending',
}

export type CourseOverviewLessonFragment = {
    readonly __typename?: 'Lesson';
    readonly id: number;
    readonly availableWithoutPayment: boolean;
    readonly completedCurrentVersion: boolean;
    readonly label: string;
    readonly studentStatus: StudentStatus;
    readonly title: string | null;
    readonly type: LessonType | null;
    readonly lingobitesAvailable: boolean;
    readonly soonestBookedDate: string | null;
    readonly practiceSequenceScore: number | null;
    readonly vocabularyItems: ReadonlyArray<{
        readonly __typename?: 'VocabularyItem';
        readonly id: string;
    }>;
};

export type CourseOverviewLearningUnitFragment = {
    readonly __typename?: 'LearningUnit';
    readonly id: number;
    readonly chapter: number;
    readonly color: string;
    readonly icon: string | null;
    readonly name: string;
    readonly lastBookedDate: string | null;
    readonly lessons: ReadonlyArray<{
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly type: LessonType | null;
        readonly availableWithoutPayment: boolean;
        readonly completedCurrentVersion: boolean;
        readonly label: string;
        readonly studentStatus: StudentStatus;
        readonly title: string | null;
        readonly lingobitesAvailable: boolean;
        readonly soonestBookedDate: string | null;
        readonly practiceSequenceScore: number | null;
        readonly vocabularyItems: ReadonlyArray<{
            readonly __typename?: 'VocabularyItem';
            readonly id: string;
        }>;
    }>;
    readonly module: {
        readonly __typename?: 'Module';
        readonly name: string;
        readonly id: number;
    } | null;
    readonly vocabularyItems: ReadonlyArray<{
        readonly __typename?: 'VocabularyItem';
        readonly id: string;
        readonly gender: GenderType | null;
        readonly item: string | null;
        readonly sampleSentenceOne: string;
        readonly title: string;
        readonly wordClass: VocabularyItemWordClass;
        readonly plural: string | null;
        readonly flashcardIsCompleted: boolean;
        readonly section: {
            readonly __typename?: 'Section';
            readonly id: number;
            readonly name: SectionName;
        };
    }>;
    readonly exercise: {
        readonly __typename?: 'Exercise';
        readonly id: string;
        readonly link: string | null;
        readonly score: number | null;
        readonly title: string;
    } | null;
};

export type LearningUnitsQueryVariables = Exact<{
    moduleId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type LearningUnitsQuery = {
    readonly __typename?: 'Query';
    readonly learningUnits: ReadonlyArray<{
        readonly __typename?: 'LearningUnit';
        readonly id: number;
        readonly chapter: number;
        readonly color: string;
        readonly icon: string | null;
        readonly name: string;
        readonly lastBookedDate: string | null;
        readonly lessons: ReadonlyArray<{
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly type: LessonType | null;
            readonly availableWithoutPayment: boolean;
            readonly completedCurrentVersion: boolean;
            readonly label: string;
            readonly studentStatus: StudentStatus;
            readonly title: string | null;
            readonly lingobitesAvailable: boolean;
            readonly soonestBookedDate: string | null;
            readonly practiceSequenceScore: number | null;
            readonly vocabularyItems: ReadonlyArray<{
                readonly __typename?: 'VocabularyItem';
                readonly id: string;
            }>;
        }>;
        readonly module: {
            readonly __typename?: 'Module';
            readonly name: string;
            readonly id: number;
        } | null;
        readonly vocabularyItems: ReadonlyArray<{
            readonly __typename?: 'VocabularyItem';
            readonly id: string;
            readonly gender: GenderType | null;
            readonly item: string | null;
            readonly sampleSentenceOne: string;
            readonly title: string;
            readonly wordClass: VocabularyItemWordClass;
            readonly plural: string | null;
            readonly flashcardIsCompleted: boolean;
            readonly section: {
                readonly __typename?: 'Section';
                readonly id: number;
                readonly name: SectionName;
            };
        }>;
        readonly exercise: {
            readonly __typename?: 'Exercise';
            readonly id: string;
            readonly link: string | null;
            readonly score: number | null;
            readonly title: string;
        } | null;
    }>;
};

export type PracticeItemHeaderFragment = {
    readonly __typename?: 'PracticeItem';
    readonly exercises: ReadonlyArray<{
        readonly __typename?: 'H5PExercise';
        readonly id: string;
        readonly title: string;
        readonly type: ExerciseType | null;
        readonly url: string;
        readonly score: number | null;
    }>;
    readonly lesson: {
        readonly __typename?: 'PracticeItemLesson';
        readonly id: number;
        readonly title: string | null;
        readonly number: number;
    };
    readonly learningUnit: {
        readonly __typename?: 'PracticeItemLearningUnit';
        readonly chapter: number;
    };
    readonly module: { readonly __typename?: 'Module'; readonly id: number; readonly name: string };
};

export type ExerciseDescriptionFragment = {
    readonly __typename?: 'H5PExercise';
    readonly id: string;
    readonly title: string;
    readonly type: ExerciseType | null;
    readonly url: string;
    readonly score: number | null;
};

export type RestartExercisesMutationVariables = Exact<{
    lessonId: Scalars['Int']['input'];
    type: ExerciseType;
}>;

export type RestartExercisesMutation = {
    readonly __typename?: 'Mutation';
    readonly restartExercises: {
        readonly __typename?: 'PracticeItem';
        readonly exercises: ReadonlyArray<{
            readonly __typename?: 'H5PExercise';
            readonly id: string;
            readonly type: ExerciseType | null;
            readonly url: string;
            readonly score: number | null;
        }>;
        readonly lesson: { readonly __typename?: 'PracticeItemLesson'; readonly id: number };
    } | null;
};

export type ExercisesQueryVariables = Exact<{
    lessonId: Scalars['Int']['input'];
    type?: InputMaybe<ExerciseType>;
    status?: InputMaybe<ExerciseStatus>;
}>;

export type ExercisesQuery = {
    readonly __typename?: 'Query';
    readonly practiceItem: {
        readonly __typename?: 'PracticeItem';
        readonly exercises: ReadonlyArray<{
            readonly __typename?: 'H5PExercise';
            readonly id: string;
            readonly title: string;
            readonly type: ExerciseType | null;
            readonly url: string;
            readonly score: number | null;
        }>;
        readonly lesson: {
            readonly __typename?: 'PracticeItemLesson';
            readonly id: number;
            readonly title: string | null;
            readonly number: number;
        };
        readonly learningUnit: {
            readonly __typename?: 'PracticeItemLearningUnit';
            readonly chapter: number;
        };
        readonly module: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
        };
    } | null;
    readonly exercises: ReadonlyArray<{
        readonly __typename?: 'H5PExercise';
        readonly id: string;
        readonly title: string;
        readonly type: ExerciseType | null;
        readonly url: string;
        readonly score: number | null;
    }>;
};

export type LearningUnitExercisesQueryVariables = Exact<{
    learningUnitId: Scalars['Int']['input'];
}>;

export type LearningUnitExercisesQuery = {
    readonly __typename?: 'Query';
    readonly learningUnit: {
        readonly __typename?: 'LearningUnit';
        readonly id: number;
        readonly name: string;
        readonly chapter: number;
        readonly module: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
        } | null;
    };
    readonly exercise: {
        readonly __typename?: 'Exercise';
        readonly id: string;
        readonly score: number | null;
        readonly link: string | null;
        readonly title: string;
    } | null;
};

export type GrammarItemQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type GrammarItemQuery = {
    readonly __typename?: 'Query';
    readonly grammarItem: {
        readonly __typename?: 'GrammarItem';
        readonly id: string;
        readonly title: string;
        readonly content: string | null;
        readonly isCompleted: boolean;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly title: string | null;
        } | null;
    };
};

export type GrammarItemsQueryVariables = Exact<{
    lessonId: Scalars['Int']['input'];
}>;

export type GrammarItemsQuery = {
    readonly __typename?: 'Query';
    readonly grammarItems: ReadonlyArray<{
        readonly __typename?: 'GrammarItem';
        readonly id: string;
        readonly title: string;
        readonly content: string | null;
        readonly isCompleted: boolean;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly title: string | null;
        } | null;
    }>;
};

export type GrammarItemFragment = {
    readonly __typename?: 'GrammarItem';
    readonly id: string;
    readonly title: string;
    readonly content: string | null;
    readonly isCompleted: boolean;
    readonly lesson: {
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly title: string | null;
    } | null;
};

export type SetGrammarItemStatusMutationVariables = Exact<{
    id: Scalars['String']['input'];
    status: GrammarStatus;
}>;

export type SetGrammarItemStatusMutation = {
    readonly __typename?: 'Mutation';
    readonly setGrammarStatus: {
        readonly __typename?: 'GrammarItem';
        readonly id: string;
        readonly title: string;
        readonly content: string | null;
        readonly isCompleted: boolean;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly title: string | null;
        } | null;
    };
};

export type SetVocabularyItemsStatusByLessonMutationVariables = Exact<{
    lessonId: Scalars['Int']['input'];
    status: VocabularyStatus;
}>;

export type SetVocabularyItemsStatusByLessonMutation = {
    readonly __typename?: 'Mutation';
    readonly setVocabularyItemsStatusByLesson: ReadonlyArray<{
        readonly __typename?: 'VocabularyItem';
        readonly id: string;
        readonly isCompleted: boolean;
    }>;
};

export type PracticeItemsQueryVariables = Exact<{
    lessonId?: InputMaybe<Scalars['Int']['input']>;
    moduleId?: InputMaybe<Scalars['Int']['input']>;
    type?: InputMaybe<ExerciseType>;
    status?: InputMaybe<PracticeItemStatus>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    includeH5P: Scalars['Boolean']['input'];
}>;

export type PracticeItemsQuery = {
    readonly __typename?: 'Query';
    readonly practiceItems: ReadonlyArray<{
        readonly __typename?: 'PracticeItem';
        readonly isCompleted: boolean;
        readonly exercises?: ReadonlyArray<{
            readonly __typename?: 'H5PExercise';
            readonly id: string;
            readonly title: string;
            readonly type: ExerciseType | null;
            readonly url: string;
            readonly score: number | null;
        }>;
        readonly quiz: {
            readonly __typename?: 'H5PExercise';
            readonly id: string;
            readonly title: string;
            readonly type: ExerciseType | null;
            readonly url: string;
            readonly score: number | null;
        };
        readonly homeworks: ReadonlyArray<{
            readonly __typename?: 'H5PExercise';
            readonly id: string;
            readonly title: string;
            readonly type: ExerciseType | null;
            readonly url: string;
            readonly score: number | null;
        }>;
        readonly pendingHomeworks: ReadonlyArray<{
            readonly __typename?: 'H5PExercise';
            readonly id: string;
            readonly title: string;
            readonly type: ExerciseType | null;
            readonly url: string;
            readonly score: number | null;
        }>;
        readonly vocabularyItems: ReadonlyArray<{
            readonly __typename?: 'VocabularyItem';
            readonly id: string;
            readonly title: string;
            readonly isCompleted: boolean;
        }>;
        readonly grammarItems: ReadonlyArray<{
            readonly __typename?: 'GrammarItem';
            readonly id: string;
            readonly title: string;
            readonly isCompleted: boolean;
        }>;
        readonly lesson: {
            readonly __typename?: 'PracticeItemLesson';
            readonly id: number;
            readonly title: string | null;
            readonly number: number;
            readonly presentationUrl: string | null;
        };
        readonly learningUnit: {
            readonly __typename?: 'PracticeItemLearningUnit';
            readonly chapter: number;
        };
        readonly module: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
        };
        readonly class: {
            readonly __typename?: 'Klass';
            readonly uid: string;
            readonly annotatedPresentation: string | null;
            readonly sourcePresentation: string | null;
            readonly isForNewClassroom: boolean;
            readonly isPresentationMode: boolean;
        } | null;
        readonly classLog: {
            readonly __typename?: 'ClassLog';
            readonly id: number;
            readonly logMessage: string | null;
            readonly classroomChatLogUrl: string | null;
            readonly hasClassroomChatLog: boolean;
        } | null;
    }>;
};

export type PracticePageLingobitesQueryVariables = Exact<{
    limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type PracticePageLingobitesQuery = {
    readonly __typename?: 'Query';
    readonly nextLessonsForPracticeSequenceQuiz: ReadonlyArray<{
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly title: string | null;
        readonly studentStatus: StudentStatus;
        readonly practiceSequenceScore: number | null;
        readonly studentLearningUnit: {
            readonly __typename?: 'LearningUnit';
            readonly name: string;
        } | null;
    }>;
};

export type RemoveAvatarMutationVariables = Exact<{ [key: string]: never }>;

export type RemoveAvatarMutation = {
    readonly __typename?: 'Mutation';
    readonly removeAvatar: boolean;
};

export type UpdateAvatarMutationVariables = Exact<{
    avatar: Scalars['String']['input'];
}>;

export type UpdateAvatarMutation = {
    readonly __typename?: 'Mutation';
    readonly updateAvatar: {
        readonly __typename?: 'User';
        readonly id: number;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly id: number;
            readonly urls: {
                readonly __typename?: 'ImageUrl';
                readonly thumbnail_large: string;
                readonly thumbnail_large2x: string;
            };
        } | null;
    };
};

export type ProfileImageQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileImageQuery = {
    readonly __typename?: 'Query';
    readonly currentUser: {
        readonly __typename?: 'User';
        readonly id: number;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly id: number;
            readonly urls: {
                readonly __typename?: 'ImageUrl';
                readonly thumbnail_large: string;
                readonly thumbnail_large2x: string;
            };
        } | null;
    };
};

export type UpdateLearningLanguageForNotSubscriberMutationVariables = Exact<{
    newSectionName: Scalars['SectionName']['input'];
    newCurriculumId: Scalars['Int']['input'];
}>;

export type UpdateLearningLanguageForNotSubscriberMutation = {
    readonly __typename?: 'Mutation';
    readonly updateLearningLanguageForNotSubscriber: boolean;
};

export type LearningLanguageQueryVariables = Exact<{ [key: string]: never }>;

export type LearningLanguageQuery = {
    readonly __typename?: 'Query';
    readonly sectionCurricula: ReadonlyArray<{
        readonly __typename?: 'SectionCurriculum';
        readonly slug: string;
        readonly section: {
            readonly __typename?: 'Section';
            readonly id: number;
            readonly name: SectionName;
        };
        readonly curriculum: {
            readonly __typename?: 'Curriculum';
            readonly id: number;
            readonly name: string;
            readonly default: boolean;
        };
    }>;
    readonly canChangeLearningLanguage: {
        readonly __typename?: 'CanChangeLanguage';
        readonly isShown: boolean;
        readonly isEnabled: boolean;
    };
};

export type PrivateFramePreloadQueryVariables = Exact<{ [key: string]: never }>;

export type PrivateFramePreloadQuery = {
    readonly __typename?: 'Query';
    readonly subscriptions: {
        readonly __typename?: 'StudentSubscriptionInfo';
        readonly currentSubscription: {
            readonly __typename?: 'Subscription';
            readonly id: number;
            readonly type: SubscriptionType;
            readonly paymentMethodAdded: boolean | null;
        } | null;
    };
    readonly config: {
        readonly __typename?: 'SystemConfig';
        readonly languages: Record<string, string>;
        readonly currencies: ReadonlyArray<Currency>;
        readonly minimumCharges: Record<string, unknown>;
        readonly classConfig: {
            readonly __typename?: 'ClassConfig';
            readonly refundableAfterBookingForMinutes: number;
            readonly refundableDaysBeforeStart: number;
            readonly studentCancelLateHours: number;
            readonly classStartTimePoint: number;
            readonly classDefaultDurationMinutes: number;
        };
        readonly timezones: ReadonlyArray<{
            readonly __typename?: 'Timezone';
            readonly label: string;
            readonly tz: string;
        }>;
    };
};

export type AnonymousOrientationIndividualClassesQueryVariables = Exact<{
    sectionName: Scalars['SectionName']['input'];
    moduleId: Scalars['Int']['input'];
    curriculumId: Scalars['Int']['input'];
    timezone: Scalars['String']['input'];
}>;

export type AnonymousOrientationIndividualClassesQuery = {
    readonly __typename?: 'Query';
    readonly anonymousOrientationIndividualClasses: ReadonlyArray<{
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly status: ClassStatus;
        readonly type: ClassType;
        readonly isExisting: boolean;
        readonly startDate: string;
        readonly endDate: string;
        readonly isForNewClassroom: boolean;
        readonly duration: number;
        readonly module: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
        };
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly title: string | null;
            readonly label: string;
            readonly learningOutcome: string | null;
        } | null;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        } | null;
    }>;
};

export type OnboardingSearchResultsQueryVariables = Exact<{
    bookingFilter: ProvideBookableClassSlotsInput;
}>;

export type OnboardingSearchResultsQuery = {
    readonly __typename?: 'Query';
    readonly classes: ReadonlyArray<{
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly status: ClassStatus;
        readonly type: ClassType;
        readonly isExisting: boolean;
        readonly startDate: string;
        readonly endDate: string;
        readonly isForNewClassroom: boolean;
        readonly duration: number;
        readonly module: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
        };
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly title: string | null;
            readonly label: string;
            readonly learningOutcome: string | null;
        } | null;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        } | null;
    }>;
};

export type OnboardingClassQueryVariables = Exact<{
    classId: Scalars['String']['input'];
}>;

export type OnboardingClassQuery = {
    readonly __typename?: 'Query';
    readonly onboardingClass: {
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly status: ClassStatus;
        readonly type: ClassType;
        readonly isExisting: boolean;
        readonly startDate: string;
        readonly endDate: string;
        readonly isForNewClassroom: boolean;
        readonly duration: number;
        readonly module: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
        };
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly title: string | null;
            readonly label: string;
            readonly learningOutcome: string | null;
        } | null;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        } | null;
    } | null;
};

export type ClassroomClassDetailsFragment = {
    readonly __typename?: 'Klass';
    readonly isPresentationMode: boolean;
    readonly type: ClassType;
    readonly uid: string;
    readonly endDate: string;
    readonly startDate: string;
    readonly lesson: {
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly title: string | null;
        readonly description: string | null;
        readonly learningOutcomes: ReadonlyArray<string> | null;
    } | null;
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly uid: string;
        readonly firstName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: {
                readonly __typename?: 'ImageUrl';
                readonly thumbnail: string;
                readonly medium: string;
            };
        } | null;
    } | null;
    readonly students: ReadonlyArray<{
        readonly __typename?: 'Student';
        readonly completedClasses: number;
        readonly id: number;
        readonly uid: string;
        readonly firstName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: {
                readonly __typename?: 'ImageUrl';
                readonly thumbnail: string;
                readonly medium: string;
            };
        } | null;
    }>;
};

export type BackgroundOptionsFragment = {
    readonly __typename?: 'Query';
    readonly backgroundOptions: ReadonlyArray<{
        readonly __typename?: 'BackgroundImage';
        readonly assetId: BackgroundAssetId | null;
        readonly imageHd: string | null;
        readonly imageThumbnail: string | null;
    }>;
};

export type ClassroomClassDetailsQueryVariables = Exact<{
    classUid: Scalars['String']['input'];
}>;

export type ClassroomClassDetailsQuery = {
    readonly __typename?: 'Query';
    readonly class: {
        readonly __typename?: 'Klass';
        readonly isPresentationMode: boolean;
        readonly type: ClassType;
        readonly uid: string;
        readonly endDate: string;
        readonly startDate: string;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly title: string | null;
            readonly description: string | null;
            readonly learningOutcomes: ReadonlyArray<string> | null;
        } | null;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly uid: string;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: {
                    readonly __typename?: 'ImageUrl';
                    readonly thumbnail: string;
                    readonly medium: string;
                };
            } | null;
        } | null;
        readonly students: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly completedClasses: number;
            readonly id: number;
            readonly uid: string;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: {
                    readonly __typename?: 'ImageUrl';
                    readonly thumbnail: string;
                    readonly medium: string;
                };
            } | null;
        }>;
    };
};

export type ClassroomSessionParticipantsQueryVariables = Exact<{
    apiSessionId: Scalars['Ulid']['input'];
}>;

export type ClassroomSessionParticipantsQuery = {
    readonly __typename?: 'Query';
    readonly zoomSessionParticipants: ReadonlyArray<{
        readonly __typename?: 'ZoomSessionParticipant';
        readonly id: string;
        readonly sdkUserId: number;
        readonly status: ZoomSessionParticipantStatus | null;
    }>;
};

export type BackgroundOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type BackgroundOptionsQuery = {
    readonly __typename?: 'Query';
    readonly backgroundOptions: ReadonlyArray<{
        readonly __typename?: 'BackgroundImage';
        readonly assetId: BackgroundAssetId | null;
        readonly imageHd: string | null;
        readonly imageThumbnail: string | null;
    }>;
};

export type UpdateClassroomParticipantMutationVariables = Exact<{
    participant: ZoomSessionParticipantInput;
}>;

export type UpdateClassroomParticipantMutation = {
    readonly __typename?: 'Mutation';
    readonly updateClassroomUser: boolean;
};

export type CreateChatHistoryMutationVariables = Exact<{
    chatHistory: ClassChatHistoryInput;
}>;

export type CreateChatHistoryMutation = {
    readonly __typename?: 'Mutation';
    readonly createClassChatHistory: boolean;
};

export type CreateZoomSessionMutationVariables = Exact<{
    classUid: Scalars['String']['input'];
    audioCompatibilityMode?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CreateZoomSessionMutation = {
    readonly __typename?: 'Mutation';
    readonly createZoomSession: {
        readonly __typename?: 'ZoomSession';
        readonly id: string;
        readonly zoomSessionName: string;
        readonly token: string;
    };
};

export type CreateDemoZoomSessionMutationVariables = Exact<{
    classUid: Scalars['String']['input'];
    audioCompatibilityMode?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CreateDemoZoomSessionMutation = {
    readonly __typename?: 'Mutation';
    readonly createZoomSession: {
        readonly __typename?: 'ZoomSession';
        readonly id: string;
        readonly zoomSessionName: string;
        readonly token: string;
    };
};

export type LogClassroomParticipantMutationVariables = Exact<{
    participant: ZoomSessionParticipantInput;
}>;

export type LogClassroomParticipantMutation = {
    readonly __typename?: 'Mutation';
    readonly setCurrentClassroomUser: {
        readonly __typename?: 'ZoomSessionParticipant';
        readonly id: string;
        readonly status: ZoomSessionParticipantStatus | null;
        readonly sdkUserId: number;
        readonly user: { readonly __typename?: 'User'; readonly id: number };
    };
};

export type PrivatePageDataQueryVariables = Exact<{
    classUid: Scalars['String']['input'];
}>;

export type PrivatePageDataQuery = {
    readonly __typename?: 'Query';
    readonly class: {
        readonly __typename?: 'Klass';
        readonly isPresentationMode: boolean;
        readonly type: ClassType;
        readonly uid: string;
        readonly endDate: string;
        readonly startDate: string;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly title: string | null;
            readonly description: string | null;
            readonly learningOutcomes: ReadonlyArray<string> | null;
        } | null;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly uid: string;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: {
                    readonly __typename?: 'ImageUrl';
                    readonly thumbnail: string;
                    readonly medium: string;
                };
            } | null;
        } | null;
        readonly students: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly completedClasses: number;
            readonly id: number;
            readonly uid: string;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: {
                    readonly __typename?: 'ImageUrl';
                    readonly thumbnail: string;
                    readonly medium: string;
                };
            } | null;
        }>;
    };
    readonly currentUser: {
        readonly __typename?: 'User';
        readonly id: number;
        readonly uid: string;
        readonly firstname: string;
    };
    readonly backgroundOptions: ReadonlyArray<{
        readonly __typename?: 'BackgroundImage';
        readonly assetId: BackgroundAssetId | null;
        readonly imageHd: string | null;
        readonly imageThumbnail: string | null;
    }>;
};

export type PrivatePageDemoDataQueryVariables = Exact<{ [key: string]: never }>;

export type PrivatePageDemoDataQuery = {
    readonly __typename?: 'Query';
    readonly currentUser: {
        readonly __typename?: 'User';
        readonly id: number;
        readonly uid: string;
        readonly firstname: string;
    };
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly uid: string;
        readonly firstName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: {
                readonly __typename?: 'ImageUrl';
                readonly thumbnail: string;
                readonly medium: string;
            };
        } | null;
    } | null;
    readonly backgroundOptions: ReadonlyArray<{
        readonly __typename?: 'BackgroundImage';
        readonly assetId: BackgroundAssetId | null;
        readonly imageHd: string | null;
        readonly imageThumbnail: string | null;
    }>;
};

export type VideoClientProviderDataQueryVariables = Exact<{ [key: string]: never }>;

export type VideoClientProviderDataQuery = {
    readonly __typename?: 'Query';
    readonly currentUser: {
        readonly __typename?: 'User';
        readonly id: number;
        readonly firstname: string;
    };
};

export type PageMetaDataQueryVariables = Exact<{
    classUid: Scalars['String']['input'];
}>;

export type PageMetaDataQuery = {
    readonly __typename?: 'Query';
    readonly class: {
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly lesson: { readonly __typename?: 'Lesson'; readonly title: string | null } | null;
    };
};

export type DemoEntryQueryVariables = Exact<{ [key: string]: never }>;

export type DemoEntryQuery = {
    readonly __typename?: 'Query';
    readonly currentUser: {
        readonly __typename?: 'User';
        readonly id: number;
        readonly firstname: string;
    };
};

export type PracticeSectionKlassFragment = {
    readonly __typename?: 'Klass';
    readonly learningUnit: { readonly __typename?: 'LearningUnit'; readonly id: number } | null;
};

export type ClassFeedbackFragment = {
    readonly __typename?: 'Klass';
    readonly uid: string;
    readonly classLog: {
        readonly __typename?: 'ClassLog';
        readonly logMessage: string | null;
        readonly classroomChatLogUrl: string | null;
        readonly hasClassroomChatLog: boolean;
    } | null;
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly firstName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
        } | null;
    } | null;
    readonly lesson: {
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly title: string | null;
        readonly description: string | null;
    } | null;
};

export type StudentClassFeedbackFragment = {
    readonly __typename?: 'Klass';
    readonly uid: string;
    readonly appointment: {
        readonly __typename?: 'Appointment';
        readonly status: AppointmentStatus;
        readonly isRatedByStudent: boolean;
    } | null;
};

export type AppointmentLogFragment = {
    readonly __typename?: 'AppointmentLog';
    readonly feedback: string | null;
    readonly questions: ReadonlyArray<{
        readonly __typename?: 'AppointmentLogQuestion';
        readonly id: string;
        readonly label: string;
        readonly question: string;
        readonly sentiment: AppointmentLogQuestionSentiment | null;
        readonly studentAnswers: ReadonlyArray<{
            readonly __typename?: 'AppointmentLogAnswer';
            readonly id: string;
            readonly answer: string;
        }>;
    }>;
    readonly recommendedLevel: {
        readonly __typename?: 'Module';
        readonly id: number;
        readonly name: string;
    } | null;
};

export type ClassFeedbackQueryVariables = Exact<{
    classUid: Scalars['String']['input'];
}>;

export type ClassFeedbackQuery = {
    readonly __typename?: 'Query';
    readonly shouldShowClassDetailsPageKysBanner: boolean;
    readonly class: {
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly classLog: {
            readonly __typename?: 'ClassLog';
            readonly logMessage: string | null;
            readonly classroomChatLogUrl: string | null;
            readonly hasClassroomChatLog: boolean;
        } | null;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        } | null;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly title: string | null;
            readonly description: string | null;
        } | null;
    };
    readonly appointmentLog: {
        readonly __typename?: 'AppointmentLog';
        readonly feedback: string | null;
        readonly questions: ReadonlyArray<{
            readonly __typename?: 'AppointmentLogQuestion';
            readonly id: string;
            readonly label: string;
            readonly question: string;
            readonly sentiment: AppointmentLogQuestionSentiment | null;
            readonly studentAnswers: ReadonlyArray<{
                readonly __typename?: 'AppointmentLogAnswer';
                readonly id: string;
                readonly answer: string;
            }>;
        }>;
        readonly recommendedLevel: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
        } | null;
    } | null;
};

export type StudentClassResourcesFragment = {
    readonly __typename?: 'Klass';
    readonly annotatedPresentation: string | null;
};

export type LearningUnitPracticeItemFragment = {
    readonly __typename?: 'PracticeItem';
    readonly exercises?: ReadonlyArray<{
        readonly __typename?: 'H5PExercise';
        readonly id: string;
        readonly title: string;
        readonly type: ExerciseType | null;
        readonly url: string;
        readonly score: number | null;
    }>;
    readonly quiz: {
        readonly __typename?: 'H5PExercise';
        readonly id: string;
        readonly title: string;
        readonly type: ExerciseType | null;
        readonly url: string;
        readonly score: number | null;
    };
    readonly homeworks: ReadonlyArray<{
        readonly __typename?: 'H5PExercise';
        readonly id: string;
        readonly title: string;
        readonly type: ExerciseType | null;
        readonly url: string;
        readonly score: number | null;
    }>;
    readonly pendingHomeworks: ReadonlyArray<{
        readonly __typename?: 'H5PExercise';
        readonly id: string;
        readonly title: string;
        readonly type: ExerciseType | null;
        readonly url: string;
        readonly score: number | null;
    }>;
    readonly lesson: { readonly __typename?: 'PracticeItemLesson'; readonly id: number };
    readonly grammarItems: ReadonlyArray<{
        readonly __typename?: 'GrammarItem';
        readonly id: string;
        readonly title: string;
        readonly isCompleted: boolean;
    }>;
    readonly vocabularyItems: ReadonlyArray<{
        readonly __typename?: 'VocabularyItem';
        readonly id: string;
        readonly isCompleted: boolean;
    }>;
};

export type LearningUnitPracticeResourcesQueryVariables = Exact<{
    learningUnitId: Scalars['Int']['input'];
    lessonId: Scalars['Int']['input'];
    includeH5PExercises?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type LearningUnitPracticeResourcesQuery = {
    readonly __typename?: 'Query';
    readonly learningUnit: {
        readonly __typename?: 'LearningUnit';
        readonly id: number;
        readonly name: string;
        readonly chapter: number;
        readonly module: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
        } | null;
        readonly vocabularyItems: ReadonlyArray<{
            readonly __typename?: 'VocabularyItem';
            readonly id: string;
            readonly gender: GenderType | null;
            readonly item: string | null;
            readonly sampleSentenceOne: string;
            readonly title: string;
            readonly wordClass: VocabularyItemWordClass;
            readonly plural: string | null;
            readonly flashcardIsCompleted: boolean;
            readonly section: {
                readonly __typename?: 'Section';
                readonly id: number;
                readonly name: SectionName;
            };
        }>;
        readonly lessons: ReadonlyArray<{
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly type: LessonType | null;
        }>;
        readonly exercise: {
            readonly __typename?: 'Exercise';
            readonly id: string;
            readonly link: string | null;
            readonly score: number | null;
            readonly title: string;
        } | null;
    };
    readonly practiceItem: {
        readonly __typename?: 'PracticeItem';
        readonly exercises?: ReadonlyArray<{
            readonly __typename?: 'H5PExercise';
            readonly id: string;
            readonly title: string;
            readonly type: ExerciseType | null;
            readonly url: string;
            readonly score: number | null;
        }>;
        readonly quiz: {
            readonly __typename?: 'H5PExercise';
            readonly id: string;
            readonly title: string;
            readonly type: ExerciseType | null;
            readonly url: string;
            readonly score: number | null;
        };
        readonly homeworks: ReadonlyArray<{
            readonly __typename?: 'H5PExercise';
            readonly id: string;
            readonly title: string;
            readonly type: ExerciseType | null;
            readonly url: string;
            readonly score: number | null;
        }>;
        readonly pendingHomeworks: ReadonlyArray<{
            readonly __typename?: 'H5PExercise';
            readonly id: string;
            readonly title: string;
            readonly type: ExerciseType | null;
            readonly url: string;
            readonly score: number | null;
        }>;
        readonly lesson: { readonly __typename?: 'PracticeItemLesson'; readonly id: number };
        readonly grammarItems: ReadonlyArray<{
            readonly __typename?: 'GrammarItem';
            readonly id: string;
            readonly title: string;
            readonly isCompleted: boolean;
        }>;
        readonly vocabularyItems: ReadonlyArray<{
            readonly __typename?: 'VocabularyItem';
            readonly id: string;
            readonly isCompleted: boolean;
        }>;
    } | null;
};

export type StudentClassAppointmentFragment = {
    readonly __typename?: 'Appointment';
    readonly id: string;
    readonly status: AppointmentStatus;
};

export type StudentClassLessonFragment = {
    readonly __typename?: 'Lesson';
    readonly id: number;
    readonly type: LessonType | null;
    readonly title: string | null;
    readonly description: string | null;
    readonly learningOutcomes: ReadonlyArray<string> | null;
    readonly label: string;
    readonly presentationUrl: string | null;
    readonly lingobitesAvailable: boolean;
    readonly practiceSequenceScore: number | null;
    readonly module: { readonly __typename?: 'Module'; readonly id: number };
    readonly studentLearningUnit: {
        readonly __typename?: 'LearningUnit';
        readonly id: number;
    } | null;
};

export type StudentClassModuleFragment = {
    readonly __typename?: 'Module';
    readonly id: number;
    readonly name: string;
};

export type StudentClassTeacherFragment = {
    readonly __typename?: 'Teacher';
    readonly id: number;
    readonly uid: string;
    readonly firstName: string;
    readonly photo: {
        readonly __typename?: 'Image';
        readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
    } | null;
    readonly teacherInfo: {
        readonly __typename?: 'TeacherInfo';
        readonly aboutMe: string;
        readonly languages: ReadonlyArray<{
            readonly __typename?: 'Language';
            readonly language: SpokenLanguage;
            readonly fluency: LanguageFluency;
        }>;
        readonly country: {
            readonly __typename?: 'Country';
            readonly code: string;
            readonly commonName: string;
        } | null;
    } | null;
    readonly section: { readonly __typename?: 'Section'; readonly name: SectionName } | null;
};

export type StudentClassKlassFragment = {
    readonly __typename?: 'Klass';
    readonly uid: string;
    readonly sourcePresentation: string | null;
    readonly availableSeats: number;
    readonly isForNewClassroom: boolean;
    readonly isPresentationMode: boolean;
    readonly annotatedPresentation: string | null;
    readonly startDate: string;
    readonly endDate: string;
    readonly status: ClassStatus;
    readonly type: ClassType;
    readonly isExisting: boolean;
    readonly appointment: {
        readonly __typename?: 'Appointment';
        readonly status: AppointmentStatus;
        readonly isRatedByStudent: boolean;
        readonly id: string;
        readonly createdAt: string;
        readonly label: string | null;
        readonly hasAttemptToJoin: boolean;
    } | null;
    readonly module: { readonly __typename?: 'Module'; readonly id: number; readonly name: string };
    readonly lesson: {
        readonly __typename?: 'Lesson';
        readonly studentStatus: StudentStatus;
        readonly id: number;
        readonly type: LessonType | null;
        readonly title: string | null;
        readonly description: string | null;
        readonly learningOutcomes: ReadonlyArray<string> | null;
        readonly label: string;
        readonly presentationUrl: string | null;
        readonly lingobitesAvailable: boolean;
        readonly practiceSequenceScore: number | null;
        readonly module: { readonly __typename?: 'Module'; readonly id: number };
        readonly studentLearningUnit: {
            readonly __typename?: 'LearningUnit';
            readonly id: number;
        } | null;
    } | null;
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly uid: string;
        readonly firstName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly id: number;
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
        } | null;
        readonly teacherInfo: {
            readonly __typename?: 'TeacherInfo';
            readonly aboutMe: string;
            readonly languages: ReadonlyArray<{
                readonly __typename?: 'Language';
                readonly language: SpokenLanguage;
                readonly fluency: LanguageFluency;
            }>;
            readonly country: {
                readonly __typename?: 'Country';
                readonly code: string;
                readonly commonName: string;
            } | null;
        } | null;
        readonly section: { readonly __typename?: 'Section'; readonly name: SectionName } | null;
    } | null;
    readonly learningUnit: { readonly __typename?: 'LearningUnit'; readonly id: number } | null;
    readonly students: ReadonlyArray<{
        readonly __typename?: 'Student';
        readonly id: number;
        readonly shortName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly id: number;
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
        } | null;
    }>;
};

export type StudentClassQueryVariables = Exact<{
    classId: Scalars['String']['input'];
}>;

export type StudentClassQuery = {
    readonly __typename?: 'Query';
    readonly class: {
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly sourcePresentation: string | null;
        readonly availableSeats: number;
        readonly isForNewClassroom: boolean;
        readonly isPresentationMode: boolean;
        readonly annotatedPresentation: string | null;
        readonly startDate: string;
        readonly endDate: string;
        readonly status: ClassStatus;
        readonly type: ClassType;
        readonly isExisting: boolean;
        readonly appointment: {
            readonly __typename?: 'Appointment';
            readonly status: AppointmentStatus;
            readonly isRatedByStudent: boolean;
            readonly id: string;
            readonly createdAt: string;
            readonly label: string | null;
            readonly hasAttemptToJoin: boolean;
        } | null;
        readonly module: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
        };
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly studentStatus: StudentStatus;
            readonly id: number;
            readonly type: LessonType | null;
            readonly title: string | null;
            readonly description: string | null;
            readonly learningOutcomes: ReadonlyArray<string> | null;
            readonly label: string;
            readonly presentationUrl: string | null;
            readonly lingobitesAvailable: boolean;
            readonly practiceSequenceScore: number | null;
            readonly module: { readonly __typename?: 'Module'; readonly id: number };
            readonly studentLearningUnit: {
                readonly __typename?: 'LearningUnit';
                readonly id: number;
            } | null;
        } | null;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly uid: string;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly id: number;
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
            readonly teacherInfo: {
                readonly __typename?: 'TeacherInfo';
                readonly aboutMe: string;
                readonly languages: ReadonlyArray<{
                    readonly __typename?: 'Language';
                    readonly language: SpokenLanguage;
                    readonly fluency: LanguageFluency;
                }>;
                readonly country: {
                    readonly __typename?: 'Country';
                    readonly code: string;
                    readonly commonName: string;
                } | null;
            } | null;
            readonly section: {
                readonly __typename?: 'Section';
                readonly name: SectionName;
            } | null;
        } | null;
        readonly learningUnit: { readonly __typename?: 'LearningUnit'; readonly id: number } | null;
        readonly students: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly id: number;
            readonly shortName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly id: number;
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
            } | null;
        }>;
    };
};

export type StudentLessonLessonFragment = {
    readonly __typename?: 'Lesson';
    readonly id: number;
    readonly type: LessonType | null;
    readonly description: string | null;
    readonly learningOutcomes: ReadonlyArray<string> | null;
    readonly lingobitesAvailable: boolean;
    readonly practiceSequenceScore: number | null;
    readonly presentationUrl: string | null;
    readonly title: string | null;
    readonly label: string;
    readonly studentStatus: StudentStatus;
    readonly module: { readonly __typename?: 'Module'; readonly id: number; readonly name: string };
    readonly studentLearningUnit: {
        readonly __typename?: 'LearningUnit';
        readonly id: number;
    } | null;
};

export type LessonDetailsTileAppointmentFragment = {
    readonly __typename?: 'Appointment';
    readonly id: string;
    readonly createdAt: string;
    readonly label: string | null;
    readonly status: AppointmentStatus;
    readonly isRatedByStudent: boolean;
    readonly hasAttemptToJoin: boolean;
    readonly class: {
        readonly __typename?: 'Klass';
        readonly annotatedPresentation: string | null;
        readonly sourcePresentation: string | null;
        readonly isPresentationMode: boolean;
        readonly uid: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly status: ClassStatus;
        readonly type: ClassType;
        readonly isExisting: boolean;
        readonly availableSeats: number;
        readonly isForNewClassroom: boolean;
        readonly lesson: { readonly __typename?: 'Lesson'; readonly id: number } | null;
        readonly module: { readonly __typename?: 'Module'; readonly id: number };
        readonly students: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly id: number;
            readonly shortName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly id: number;
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
            } | null;
        }>;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly uid: string;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly id: number;
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        } | null;
        readonly appointment: { readonly __typename?: 'Appointment'; readonly id: string } | null;
    };
};

export type StudentLessonQueryVariables = Exact<{
    lessonId: Scalars['Int']['input'];
}>;

export type StudentLessonQuery = {
    readonly __typename?: 'Query';
    readonly lesson: {
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly type: LessonType | null;
        readonly description: string | null;
        readonly learningOutcomes: ReadonlyArray<string> | null;
        readonly lingobitesAvailable: boolean;
        readonly practiceSequenceScore: number | null;
        readonly presentationUrl: string | null;
        readonly title: string | null;
        readonly label: string;
        readonly studentStatus: StudentStatus;
        readonly module: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
        };
        readonly studentLearningUnit: {
            readonly __typename?: 'LearningUnit';
            readonly id: number;
        } | null;
    };
    readonly lastLessonAppointment: {
        readonly __typename?: 'Appointment';
        readonly id: string;
        readonly createdAt: string;
        readonly label: string | null;
        readonly status: AppointmentStatus;
        readonly isRatedByStudent: boolean;
        readonly hasAttemptToJoin: boolean;
        readonly class: {
            readonly __typename?: 'Klass';
            readonly annotatedPresentation: string | null;
            readonly sourcePresentation: string | null;
            readonly isPresentationMode: boolean;
            readonly uid: string;
            readonly startDate: string;
            readonly endDate: string;
            readonly status: ClassStatus;
            readonly type: ClassType;
            readonly isExisting: boolean;
            readonly availableSeats: number;
            readonly isForNewClassroom: boolean;
            readonly lesson: { readonly __typename?: 'Lesson'; readonly id: number } | null;
            readonly module: { readonly __typename?: 'Module'; readonly id: number };
            readonly students: ReadonlyArray<{
                readonly __typename?: 'Student';
                readonly id: number;
                readonly shortName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly id: number;
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
                } | null;
            }>;
            readonly teacher: {
                readonly __typename?: 'Teacher';
                readonly id: number;
                readonly uid: string;
                readonly firstName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly id: number;
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
                } | null;
            } | null;
            readonly appointment: {
                readonly __typename?: 'Appointment';
                readonly id: string;
            } | null;
        };
    } | null;
};

export type StudentCoursePageQueryVariables = Exact<{
    sectionId: Scalars['Int']['input'];
    moduleId: Scalars['Int']['input'];
    curriculumId: Scalars['Int']['input'];
}>;

export type StudentCoursePageQuery = {
    readonly __typename?: 'Query';
    readonly suggestOpenDiscussion: boolean;
    readonly sections: ReadonlyArray<{
        readonly __typename?: 'Section';
        readonly id: number;
        readonly name: SectionName;
    }>;
    readonly activeSection: {
        readonly __typename?: 'Section';
        readonly id: number;
        readonly name: SectionName;
    } | null;
    readonly sectionCurricula: ReadonlyArray<{
        readonly __typename?: 'SectionCurriculum';
        readonly slug: string;
        readonly section: {
            readonly __typename?: 'Section';
            readonly id: number;
            readonly name: SectionName;
        };
        readonly curriculum: {
            readonly __typename?: 'Curriculum';
            readonly id: number;
            readonly name: string;
            readonly default: boolean;
        };
    }>;
    readonly studentModules: ReadonlyArray<{
        readonly __typename?: 'StudentModule';
        readonly id: number;
        readonly code: string;
        readonly cefrLevelName: string | null;
        readonly name: string;
        readonly longName: string;
        readonly title: string | null;
        readonly longDescription: string;
        readonly chaptersCount: number;
        readonly completedChaptersCount: number;
        readonly totalClasses: number;
        readonly completedClasses: number;
        readonly totalLingobites: number;
        readonly completedLingobites: number;
    }>;
    readonly activeModule: {
        readonly __typename?: 'StudentModule';
        readonly id: number;
        readonly code: string;
        readonly cefrLevelName: string | null;
        readonly name: string;
        readonly longName: string;
        readonly title: string | null;
        readonly longDescription: string;
        readonly chaptersCount: number;
        readonly completedChaptersCount: number;
        readonly totalClasses: number;
        readonly completedClasses: number;
        readonly totalLingobites: number;
        readonly completedLingobites: number;
    } | null;
    readonly learningUnits: ReadonlyArray<{
        readonly __typename?: 'LearningUnit';
        readonly id: number;
        readonly chapter: number;
        readonly color: string;
        readonly icon: string | null;
        readonly name: string;
        readonly lastBookedDate: string | null;
        readonly lessons: ReadonlyArray<{
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly type: LessonType | null;
            readonly availableWithoutPayment: boolean;
            readonly completedCurrentVersion: boolean;
            readonly label: string;
            readonly studentStatus: StudentStatus;
            readonly title: string | null;
            readonly lingobitesAvailable: boolean;
            readonly soonestBookedDate: string | null;
            readonly practiceSequenceScore: number | null;
            readonly vocabularyItems: ReadonlyArray<{
                readonly __typename?: 'VocabularyItem';
                readonly id: string;
            }>;
        }>;
        readonly module: {
            readonly __typename?: 'Module';
            readonly name: string;
            readonly id: number;
        } | null;
        readonly vocabularyItems: ReadonlyArray<{
            readonly __typename?: 'VocabularyItem';
            readonly id: string;
            readonly gender: GenderType | null;
            readonly item: string | null;
            readonly sampleSentenceOne: string;
            readonly title: string;
            readonly wordClass: VocabularyItemWordClass;
            readonly plural: string | null;
            readonly flashcardIsCompleted: boolean;
            readonly section: {
                readonly __typename?: 'Section';
                readonly id: number;
                readonly name: SectionName;
            };
        }>;
        readonly exercise: {
            readonly __typename?: 'Exercise';
            readonly id: string;
            readonly link: string | null;
            readonly score: number | null;
            readonly title: string;
        } | null;
    }>;
};

export type TodayPageQueryVariables = Exact<{
    appointmentsLimit: Scalars['Int']['input'];
    appointmentsOffset?: InputMaybe<Scalars['Int']['input']>;
    appointmentsFromDate: Scalars['DateTime']['input'];
    appointmentsToDate: Scalars['DateTime']['input'];
    appointmentsCountFromDate: Scalars['DateTime']['input'];
    appointmentsSort?: InputMaybe<Scalars['String']['input']>;
    appointmentStatus: Scalars['String']['input'];
    goalWidgetPage: Scalars['Int']['input'];
    recapYear: Scalars['Int']['input'];
}>;

export type TodayPageQuery = {
    readonly __typename?: 'Query';
    readonly upcomingAppointmentsCount: number;
    readonly shouldShowTodayPageKysBanner: boolean;
    readonly studentLastTwoWeeksClassesPendingReview: ReadonlyArray<{
        readonly __typename?: 'Appointment';
        readonly id: string;
        readonly label: string | null;
        readonly klass: {
            readonly __typename?: 'Klass';
            readonly uid: string;
            readonly startDate: string;
            readonly endDate: string;
            readonly type: ClassType;
            readonly students: ReadonlyArray<{
                readonly __typename?: 'Student';
                readonly id: number;
                readonly firstLetterOfFirstName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
                } | null;
            }>;
            readonly lesson: {
                readonly __typename?: 'Lesson';
                readonly title: string | null;
                readonly label: string;
            } | null;
            readonly teacher: {
                readonly __typename?: 'Teacher';
                readonly id: number;
                readonly firstName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
                } | null;
            } | null;
        };
    }>;
    readonly studentNotification: ReadonlyArray<{
        readonly __typename?: 'StudentNotification';
        readonly id: string;
        readonly createdAt: string;
        readonly contentKeys: ReadonlyArray<string>;
        readonly contentValues: ReadonlyArray<string>;
        readonly type: string;
    }>;
    readonly currentStudentSprint: {
        readonly __typename?: 'Sprint';
        readonly id: string;
        readonly name: string;
        readonly startDateInStudentTimezone: string;
        readonly durationMonths: number;
        readonly startDate: string;
    } | null;
    readonly sectionCurricula: ReadonlyArray<{
        readonly __typename?: 'SectionCurriculum';
        readonly slug: string;
        readonly section: {
            readonly __typename?: 'Section';
            readonly id: number;
            readonly name: SectionName;
        };
        readonly curriculum: {
            readonly __typename?: 'Curriculum';
            readonly id: number;
            readonly name: string;
            readonly default: boolean;
        };
    }>;
    readonly appointmentsList: ReadonlyArray<{
        readonly __typename?: 'Appointment';
        readonly id: string;
        readonly status: AppointmentStatus;
        readonly label: string | null;
        readonly klass: {
            readonly __typename?: 'Klass';
            readonly uid: string;
            readonly startDate: string;
            readonly endDate: string;
            readonly status: ClassStatus;
            readonly type: ClassType;
            readonly isForNewClassroom: boolean;
            readonly students: ReadonlyArray<{
                readonly __typename?: 'Student';
                readonly id: number;
                readonly firstLetterOfFirstName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
                } | null;
            }>;
            readonly lesson: {
                readonly __typename?: 'Lesson';
                readonly title: string | null;
                readonly description: string | null;
                readonly label: string;
            } | null;
            readonly teacher: {
                readonly __typename?: 'Teacher';
                readonly id: number;
                readonly firstName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
                } | null;
            } | null;
        };
    }>;
    readonly student: {
        readonly __typename?: 'Student';
        readonly id: number;
        readonly hasBookedClass: boolean;
    };
    readonly studentAchievements: ReadonlyArray<{
        readonly __typename?: 'Achievement';
        readonly claimId: string | null;
        readonly name: string;
        readonly value: number;
        readonly currentProgress: number;
        readonly progressLabel: string;
        readonly achievedLabel: string;
        readonly rewardText: string;
        readonly congratulationsText: string;
        readonly achievedAt: string | null;
        readonly claimedAt: string | null;
        readonly claimed: boolean;
        readonly achieved: boolean;
        readonly id: string;
        readonly slug: string;
    }>;
    readonly ownReferral: {
        readonly __typename?: 'OwnReferral';
        readonly code: string;
        readonly ownerGroupCredits: number;
        readonly userDiscount: ReadonlyArray<{
            readonly __typename?: 'Price';
            readonly amountInMajor: number;
            readonly currency: Currency;
            readonly amount: number;
        }>;
    } | null;
    readonly activeGoal: {
        readonly __typename?: 'StudentGoal';
        readonly id: string;
        readonly active: boolean;
        readonly value: number;
        readonly createdAt: string;
    } | null;
    readonly studentGoalWidgetData: {
        readonly __typename?: 'WidgetData';
        readonly firstAppointmentDate: string | null;
        readonly hasNext: boolean;
        readonly hasPrevious: boolean;
        readonly range: string;
        readonly weeklyProgress: ReadonlyArray<{
            readonly __typename?: 'WeeklyProgress';
            readonly shortLabel: string;
            readonly longLabel: string;
            readonly attendance: number;
            readonly goal: number | null;
        }>;
    };
    readonly activityChallenges: ReadonlyArray<{
        readonly __typename?: 'ActivityChallenge';
        readonly name: string;
        readonly publicName: string;
        readonly description: string;
        readonly overviewFirstParagraph: string;
        readonly overviewSecondParagraph: string;
        readonly desktopBackgroundTheme: BackgroundThemeEnum;
        readonly mobileBackgroundTheme: BackgroundThemeEnum;
        readonly startDate: string;
        readonly endDate: string;
        readonly participants: number;
        readonly desktopBackgroundImage: {
            readonly __typename?: 'Media';
            readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
        } | null;
        readonly mobileBackgroundImage: {
            readonly __typename?: 'Media';
            readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
        } | null;
        readonly challengeIcon: {
            readonly __typename?: 'Media';
            readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
        } | null;
        readonly prizes: ReadonlyArray<{
            readonly __typename?: 'ActivityChallengePrize';
            readonly value: number;
            readonly noun: string;
            readonly nounTranslation: string;
            readonly explanation: string;
            readonly audioUrl: string | null;
            readonly prizeIcon: {
                readonly __typename?: 'Media';
                readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
            } | null;
        }>;
        readonly currentParticipant: {
            readonly __typename?: 'ActivityChallengeParticipant';
            readonly id: string;
            readonly classesAttendedCount: number;
            readonly trainingSessionsCount: number;
            readonly learnedWordsCount: number;
            readonly completedActivities: number;
            readonly status: ActivityChallengeParticipantStatusEnum;
        } | null;
    }>;
    readonly studentYearlySummary: {
        readonly __typename?: 'StudentYearlyActivitySummary';
        readonly year: number;
        readonly classesAttendedCount: number | null;
        readonly attendedMinutes: number | null;
        readonly weeksStraightCount: number | null;
        readonly exercisesCompletedCount: number | null;
        readonly rank: string | null;
        readonly teachersCount: number | null;
        readonly studentsCount: number | null;
        readonly certificatesEarned: ReadonlyArray<{
            readonly __typename?: 'CertificateEarnedType';
            readonly name: string;
        }>;
        readonly modulesAttended: ReadonlyArray<{
            readonly __typename?: 'ModuleAttendedType';
            readonly name: string;
        }>;
        readonly topFeedbacksCount: ReadonlyArray<{
            readonly __typename?: 'FeedbackCountType';
            readonly name: string;
            readonly value: string;
        }>;
    } | null;
};

export type FlashcardReportDialogSubmitMutationVariables = Exact<{
    answersIds: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input'];
    feedback?: InputMaybe<Scalars['String']['input']>;
    vocabularyItemId: Scalars['String']['input'];
}>;

export type FlashcardReportDialogSubmitMutation = {
    readonly __typename?: 'Mutation';
    readonly submitChapterFlashcardsFeedback: boolean;
};

export type FlashCardsVocabularyItemFragment = {
    readonly __typename?: 'VocabularyItem';
    readonly id: string;
    readonly gender: GenderType | null;
    readonly item: string | null;
    readonly sampleSentenceOne: string;
    readonly title: string;
    readonly wordClass: VocabularyItemWordClass;
    readonly plural: string | null;
    readonly flashcardIsCompleted: boolean;
    readonly section: {
        readonly __typename?: 'Section';
        readonly id: number;
        readonly name: SectionName;
    };
};

export type FlashCardsLearningUnitFragment = {
    readonly __typename?: 'LearningUnit';
    readonly id: number;
    readonly name: string;
    readonly chapter: number;
    readonly module: { readonly __typename?: 'Module'; readonly name: string } | null;
    readonly vocabularyItems: ReadonlyArray<{
        readonly __typename?: 'VocabularyItem';
        readonly id: string;
        readonly gender: GenderType | null;
        readonly item: string | null;
        readonly sampleSentenceOne: string;
        readonly title: string;
        readonly wordClass: VocabularyItemWordClass;
        readonly plural: string | null;
        readonly flashcardIsCompleted: boolean;
        readonly section: {
            readonly __typename?: 'Section';
            readonly id: number;
            readonly name: SectionName;
        };
    }>;
};

export type SetFlashcardStatusMutationVariables = Exact<{
    itemId: Scalars['String']['input'];
    status: VocabularyStatus;
}>;

export type SetFlashcardStatusMutation = {
    readonly __typename?: 'Mutation';
    readonly setFlashcardStatus: {
        readonly __typename?: 'VocabularyItem';
        readonly id: string;
        readonly flashcardIsCompleted: boolean;
    };
};

export type ResetFlashcardStatusMutationVariables = Exact<{
    learningUnitId: Scalars['Int']['input'];
}>;

export type ResetFlashcardStatusMutation = {
    readonly __typename?: 'Mutation';
    readonly resetFlashcardsStatus: boolean;
};

export type LearningUnitFlashcardsQueryVariables = Exact<{
    learningUnitId: Scalars['Int']['input'];
}>;

export type LearningUnitFlashcardsQuery = {
    readonly __typename?: 'Query';
    readonly learningUnit: {
        readonly __typename?: 'LearningUnit';
        readonly id: number;
        readonly name: string;
        readonly chapter: number;
        readonly vocabularyItems: ReadonlyArray<{
            readonly __typename?: 'VocabularyItem';
            readonly id: string;
            readonly gender: GenderType | null;
            readonly item: string | null;
            readonly sampleSentenceOne: string;
            readonly title: string;
            readonly wordClass: VocabularyItemWordClass;
            readonly plural: string | null;
            readonly flashcardIsCompleted: boolean;
            readonly audioUrls: {
                readonly __typename?: 'VocabularyItemAudio';
                readonly target: string;
                readonly translation: string;
            } | null;
            readonly section: {
                readonly __typename?: 'Section';
                readonly id: number;
                readonly name: SectionName;
            };
        }>;
        readonly module: { readonly __typename?: 'Module'; readonly name: string } | null;
    };
};

export type ActivitiesProfilePageQueryVariables = Exact<{
    vocabTrainerEnabled: Scalars['Boolean']['input'];
}>;

export type ActivitiesProfilePageQuery = {
    readonly __typename?: 'Query';
    readonly student: {
        readonly __typename?: 'Student';
        readonly id: number;
        readonly completedClasses: number;
        readonly module: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
            readonly totalClasses: number;
            readonly completedClasses: number;
        } | null;
        readonly section: {
            readonly __typename?: 'Section';
            readonly id: number;
            readonly name: SectionName;
        };
        readonly lastCertificate: {
            readonly __typename?: 'StudentLanguageLevel';
            readonly module: {
                readonly __typename?: 'Module';
                readonly id: number;
                readonly name: string;
            };
        } | null;
    };
    readonly vocabTrainer: { readonly __typename?: 'QuizProgress'; readonly known: number };
};

export type SetProfileFeedbackMutationVariables = Exact<{
    feedback: Scalars['Boolean']['input'];
}>;

export type SetProfileFeedbackMutation = {
    readonly __typename?: 'Mutation';
    readonly setProfileFeedback: boolean;
};

export type StudentProfileFeedbackQueryVariables = Exact<{ [key: string]: never }>;

export type StudentProfileFeedbackQuery = {
    readonly __typename?: 'Query';
    readonly student: {
        readonly __typename?: 'Student';
        readonly studentProfile: {
            readonly __typename?: 'StudentProfile';
            readonly profileFeedback: boolean | null;
        };
    };
};

export type StudentProfilePageQueryVariables = Exact<{
    recapEnabled: Scalars['Boolean']['input'];
    recapYear: Scalars['Int']['input'];
}>;

export type StudentProfilePageQuery = {
    readonly __typename?: 'Query';
    readonly shouldShowProfilePageKysBanner: boolean;
    readonly student: {
        readonly __typename?: 'Student';
        readonly id: number;
        readonly shortName: string;
        readonly firstName: string;
        readonly registrationDate: string | null;
        readonly completedClasses: number;
        readonly businessModelType: BusinessModelType;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly id: number;
            readonly urls: {
                readonly __typename?: 'ImageUrl';
                readonly default: string;
                readonly retina: string;
            };
        } | null;
        readonly currentStudentModule: {
            readonly __typename?: 'StudentModule';
            readonly name: string;
            readonly completedClasses: number;
            readonly totalClasses: number;
        };
        readonly studentProfile: {
            readonly __typename?: 'StudentProfile';
            readonly studentSkills: ReadonlyArray<{
                readonly __typename?: 'StudentSkill';
                readonly sentiment: AppointmentLogQuestionSentiment | null;
                readonly answer: string;
                readonly count: number;
            }>;
            readonly whyAreYouLearning: {
                readonly __typename?: 'WhyAreYouLearningSection';
                readonly otherOption: string | null;
                readonly answers: ReadonlyArray<string>;
            };
            readonly spokenLanguages: ReadonlyArray<{
                readonly __typename?: 'UserSpokenLanguage';
                readonly language: UserLanguage;
                readonly languageFluency: LanguageFluency;
            }>;
        };
        readonly section: { readonly __typename?: 'Section'; readonly name: SectionName };
    };
    readonly studentYearlySummary?: {
        readonly __typename?: 'StudentYearlyActivitySummary';
        readonly year: number;
    } | null;
    readonly studentAchievements: ReadonlyArray<{
        readonly __typename?: 'Achievement';
        readonly id: string;
        readonly slug: string;
        readonly claimedAt: string | null;
    }>;
    readonly studentModules: ReadonlyArray<{
        readonly __typename?: 'StudentModule';
        readonly name: string;
        readonly completedClasses: number;
        readonly totalClasses: number;
    }>;
};

export type TeacherPageProfileFragment = {
    readonly __typename?: 'Teacher';
    readonly id: number;
    readonly firstName: string;
    readonly canTeachPrivateClass: boolean;
    readonly canTeachGroupClass: boolean;
    readonly photo: {
        readonly __typename?: 'Image';
        readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
    } | null;
    readonly teacherInfo: {
        readonly __typename?: 'TeacherInfo';
        readonly aboutMe: string;
        readonly country: {
            readonly __typename?: 'Country';
            readonly code: string;
            readonly commonName: string;
        } | null;
        readonly languages: ReadonlyArray<{
            readonly __typename?: 'Language';
            readonly language: SpokenLanguage;
            readonly fluency: LanguageFluency;
        }>;
    } | null;
    readonly section: { readonly __typename?: 'Section'; readonly name: SectionName } | null;
};

export type TeacherDetailsPageQueryVariables = Exact<{
    teacherUid: Scalars['String']['input'];
}>;

export type TeacherDetailsPageQuery = {
    readonly __typename?: 'Query';
    readonly teacherProfile: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly firstName: string;
        readonly canTeachPrivateClass: boolean;
        readonly canTeachGroupClass: boolean;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
        } | null;
        readonly teacherInfo: {
            readonly __typename?: 'TeacherInfo';
            readonly aboutMe: string;
            readonly country: {
                readonly __typename?: 'Country';
                readonly code: string;
                readonly commonName: string;
            } | null;
            readonly languages: ReadonlyArray<{
                readonly __typename?: 'Language';
                readonly language: SpokenLanguage;
                readonly fluency: LanguageFluency;
            }>;
        } | null;
        readonly section: { readonly __typename?: 'Section'; readonly name: SectionName } | null;
    };
    readonly teacherUpcomingClasses: ReadonlyArray<{
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly type: ClassType;
        readonly isExisting: boolean;
        readonly isForNewClassroom: boolean;
        readonly module: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
        };
        readonly learningUnit: {
            readonly __typename?: 'LearningUnit';
            readonly id: number;
            readonly chapter: number;
        } | null;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        } | null;
        readonly students: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly shortName: string;
            readonly id: number;
            readonly firstLetterOfFirstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        }>;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly label: string;
            readonly title: string | null;
            readonly type: LessonType | null;
            readonly studentStatus: StudentStatus;
            readonly availableWithoutPayment: boolean;
        } | null;
        readonly appointment: {
            readonly __typename?: 'Appointment';
            readonly id: string;
            readonly status: AppointmentStatus;
        } | null;
    }>;
};

export type HeaderFragment = {
    readonly __typename?: 'Teacher';
    readonly id: number;
    readonly firstName: string;
    readonly photo: {
        readonly __typename?: 'Image';
        readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
    } | null;
};

export type TeacherOpenLogsCountQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherOpenLogsCountQuery = {
    readonly __typename?: 'Query';
    readonly teacherOpenLogsCount: number;
};

export type TeacherQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherQuery = {
    readonly __typename?: 'Query';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly firstName: string;
        readonly teacherPreferences: {
            readonly __typename?: 'TeacherPreference';
            readonly timezone: string;
            readonly timeFormat: TimeFormat | null;
            readonly weekStart: number;
        };
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
        } | null;
    } | null;
};

export type TeacherUidQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherUidQuery = {
    readonly __typename?: 'Query';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly uid: string;
    } | null;
};

export type CreateTestAdminMutationVariables = Exact<{
    features?: InputMaybe<ReadonlyArray<InputMaybe<Feature>> | InputMaybe<Feature>>;
}>;

export type CreateTestAdminMutation = {
    readonly __typename?: 'Mutation';
    readonly createTestAdmin: {
        readonly __typename?: 'CreateAdminResponse';
        readonly admin: { readonly __typename?: 'TestAdmin'; readonly email: string };
    };
};

export type CreatedAdminFragment = {
    readonly __typename?: 'CreateAdminResponse';
    readonly admin: { readonly __typename?: 'TestAdmin'; readonly email: string };
};

export type CreateTestStudentMutationVariables = Exact<{
    sectionName: Scalars['SectionName']['input'];
    features?: InputMaybe<ReadonlyArray<InputMaybe<Feature>> | InputMaybe<Feature>>;
    featureFlags?: InputMaybe<ReadonlyArray<InputMaybe<FeatureFlag>> | InputMaybe<FeatureFlag>>;
}>;

export type CreateTestStudentMutation = {
    readonly __typename?: 'Mutation';
    readonly createTestStudent: {
        readonly __typename?: 'CreateTestStudentResponse';
        readonly student: { readonly __typename?: 'TestStudent'; readonly email: string };
        readonly appointments: ReadonlyArray<{
            readonly __typename?: 'TestAppointment';
            readonly class: {
                readonly __typename?: 'TestClass';
                readonly uid: string;
                readonly startDate: string;
                readonly status: ClassStatus;
                readonly type: string;
                readonly lesson: { readonly __typename?: 'Lesson'; readonly id: number } | null;
            };
        } | null> | null;
        readonly subscriptions: ReadonlyArray<{
            readonly __typename?: 'TestSubscription';
            readonly allowsPause: boolean;
            readonly allowsCancellation: boolean;
            readonly expirationDate: string | null;
            readonly lastChargeDate: string | null;
            readonly nextChargeDate: string | null;
            readonly resumingDate: string | null;
            readonly months: number;
            readonly id: number;
            readonly status: SubscriptionStatus;
            readonly expired: boolean;
            readonly price: {
                readonly __typename?: 'Price';
                readonly forHumans: string;
                readonly amount: number;
                readonly amountInMajor: number;
            };
        } | null> | null;
        readonly credits: ReadonlyArray<{
            readonly __typename?: 'TestCredit';
            readonly type: string;
            readonly amount: number;
            readonly expirationDate: string;
            readonly availabilityDate: string;
        } | null> | null;
    };
};

export type CreatedStudentFragment = {
    readonly __typename?: 'CreateTestStudentResponse';
    readonly student: { readonly __typename?: 'TestStudent'; readonly email: string };
    readonly appointments: ReadonlyArray<{
        readonly __typename?: 'TestAppointment';
        readonly class: {
            readonly __typename?: 'TestClass';
            readonly uid: string;
            readonly startDate: string;
            readonly status: ClassStatus;
            readonly type: string;
            readonly lesson: { readonly __typename?: 'Lesson'; readonly id: number } | null;
        };
    } | null> | null;
    readonly subscriptions: ReadonlyArray<{
        readonly __typename?: 'TestSubscription';
        readonly allowsPause: boolean;
        readonly allowsCancellation: boolean;
        readonly expirationDate: string | null;
        readonly lastChargeDate: string | null;
        readonly nextChargeDate: string | null;
        readonly resumingDate: string | null;
        readonly months: number;
        readonly id: number;
        readonly status: SubscriptionStatus;
        readonly expired: boolean;
        readonly price: {
            readonly __typename?: 'Price';
            readonly forHumans: string;
            readonly amount: number;
            readonly amountInMajor: number;
        };
    } | null> | null;
    readonly credits: ReadonlyArray<{
        readonly __typename?: 'TestCredit';
        readonly type: string;
        readonly amount: number;
        readonly expirationDate: string;
        readonly availabilityDate: string;
    } | null> | null;
};

export type CreateTestStudentNotificationMutationVariables = Exact<{
    type: Scalars['String']['input'];
    data: Scalars['String']['input'];
}>;

export type CreateTestStudentNotificationMutation = {
    readonly __typename?: 'Mutation';
    readonly createTestStudentNotification: boolean;
};

export type CreateTestTeacherMutationVariables = Exact<{
    sectionName: Scalars['SectionName']['input'];
    features?: InputMaybe<ReadonlyArray<InputMaybe<Feature>> | InputMaybe<Feature>>;
    featureFlags?: InputMaybe<ReadonlyArray<InputMaybe<FeatureFlag>> | InputMaybe<FeatureFlag>>;
}>;

export type CreateTestTeacherMutation = {
    readonly __typename?: 'Mutation';
    readonly createTestTeacher: {
        readonly __typename?: 'CreateTeacherResponse';
        readonly teacher: {
            readonly __typename?: 'TestTeacher';
            readonly email: string;
            readonly id: number;
        };
        readonly classes: ReadonlyArray<{ readonly __typename?: 'Klass'; readonly uid: string }>;
        readonly invoices: ReadonlyArray<{
            readonly __typename?: 'TeacherInvoice';
            readonly id: number;
        }>;
    };
};

export type CreatedTeacherFragment = {
    readonly __typename?: 'CreateTeacherResponse';
    readonly teacher: {
        readonly __typename?: 'TestTeacher';
        readonly email: string;
        readonly id: number;
    };
    readonly classes: ReadonlyArray<{ readonly __typename?: 'Klass'; readonly uid: string }>;
    readonly invoices: ReadonlyArray<{
        readonly __typename?: 'TeacherInvoice';
        readonly id: number;
    }>;
};

export type SubmitClassroomClassReviewMutationVariables = Exact<{
    classId: Scalars['String']['input'];
    techQualityRating: Scalars['Float']['input'];
    techQualityFeedbackAnswers?: InputMaybe<
        ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']
    >;
    techQualityCustomFeedback?: InputMaybe<Scalars['String']['input']>;
    browserName?: InputMaybe<Scalars['String']['input']>;
    browserVersion?: InputMaybe<Scalars['String']['input']>;
    isMobile?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SubmitClassroomClassReviewMutation = {
    readonly __typename?: 'Mutation';
    readonly submitClassroomClassReview:
        | { readonly __typename?: 'ClassFeedback'; readonly id: number }
        | { readonly __typename?: 'ClassTeacherFeedback'; readonly id: number };
};

export type IsEmailVerifiedQueryVariables = Exact<{ [key: string]: never }>;

export type IsEmailVerifiedQuery = {
    readonly __typename?: 'Query';
    readonly currentUser: {
        readonly __typename?: 'User';
        readonly id: number;
        readonly isEmailVerified: boolean;
    };
};

export type CreateFreeTrialPurchaseMutationVariables = Exact<{
    packageId: Scalars['Ulid']['input'];
}>;

export type CreateFreeTrialPurchaseMutation = {
    readonly __typename?: 'Mutation';
    readonly purchase:
        | {
              readonly __typename?: 'ExtraCreditPurchase';
              readonly id: string;
              readonly type: PurchaseType;
              readonly displayName: string;
              readonly privateCredits: number;
              readonly groupCredits: number;
              readonly isSubscription: boolean;
              readonly canUseCoupon: boolean;
              readonly contentBlocks: ReadonlyArray<{
                  readonly __typename?: 'ContentBlock';
                  readonly location: Location;
                  readonly label: string | null;
                  readonly text: string;
                  readonly icon: Icon | null;
                  readonly isStarred: boolean;
                  readonly table: string[][] | null;
              }>;
              readonly coupons: ReadonlyArray<{
                  readonly __typename?: 'Coupon';
                  readonly id: number;
                  readonly code: string;
              }>;
              readonly total: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly subtotal: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly chargeTotal: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly discount: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly agreements: ReadonlyArray<{
                  readonly __typename?: 'Agreement';
                  readonly id: string;
                  readonly acceptanceText: string;
                  readonly pdfLink: string | null;
              }>;
              readonly validityInterval: {
                  readonly __typename?: 'DateInterval';
                  readonly years: number;
                  readonly months: number;
                  readonly weeks: number;
                  readonly days: number;
                  readonly hours: number;
                  readonly minutes: number;
                  readonly seconds: number;
                  readonly microseconds: number;
                  readonly forHumans: string;
              };
              readonly lastTransaction: {
                  readonly __typename?: 'Transaction';
                  readonly id: number;
                  readonly errorCode: string | null;
                  readonly errorMessage: string | null;
                  readonly paymentStatus: PaymentStatus;
                  readonly isInitialPurchase: boolean;
                  readonly isPaymentMethodUpdate: boolean;
                  readonly currency: string;
                  readonly discount: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
                  readonly amount: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
              } | null;
          }
        | {
              readonly __typename?: 'LegacyPurchase';
              readonly id: string;
              readonly type: PurchaseType;
              readonly displayName: string;
              readonly privateCredits: number;
              readonly groupCredits: number;
              readonly isSubscription: boolean;
              readonly canUseCoupon: boolean;
              readonly productDescription: string | null;
              readonly withTrial: boolean;
              readonly contentBlocks: ReadonlyArray<{
                  readonly __typename?: 'ContentBlock';
                  readonly location: Location;
                  readonly label: string | null;
                  readonly text: string;
                  readonly icon: Icon | null;
                  readonly isStarred: boolean;
                  readonly table: string[][] | null;
              }>;
              readonly coupons: ReadonlyArray<{
                  readonly __typename?: 'Coupon';
                  readonly id: number;
                  readonly code: string;
              }>;
              readonly total: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly subtotal: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly chargeTotal: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly discount: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly agreements: ReadonlyArray<{
                  readonly __typename?: 'Agreement';
                  readonly id: string;
                  readonly acceptanceText: string;
                  readonly pdfLink: string | null;
              }>;
              readonly validityInterval: {
                  readonly __typename?: 'DateInterval';
                  readonly years: number;
                  readonly months: number;
                  readonly weeks: number;
                  readonly days: number;
                  readonly hours: number;
                  readonly minutes: number;
                  readonly seconds: number;
                  readonly microseconds: number;
                  readonly forHumans: string;
              };
              readonly lastTransaction: {
                  readonly __typename?: 'Transaction';
                  readonly id: number;
                  readonly errorCode: string | null;
                  readonly errorMessage: string | null;
                  readonly paymentStatus: PaymentStatus;
                  readonly isInitialPurchase: boolean;
                  readonly isPaymentMethodUpdate: boolean;
                  readonly currency: string;
                  readonly discount: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
                  readonly amount: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
              } | null;
              readonly rollover: {
                  readonly __typename?: 'LegacyRolloverData';
                  readonly productId: number;
                  readonly trialInterval: {
                      readonly __typename?: 'DateInterval';
                      readonly years: number;
                      readonly months: number;
                      readonly weeks: number;
                      readonly days: number;
                      readonly hours: number;
                      readonly minutes: number;
                      readonly seconds: number;
                      readonly microseconds: number;
                      readonly forHumans: string;
                  };
                  readonly recurrenceInterval: {
                      readonly __typename?: 'DateInterval';
                      readonly years: number;
                      readonly months: number;
                      readonly weeks: number;
                      readonly days: number;
                      readonly hours: number;
                      readonly minutes: number;
                      readonly seconds: number;
                      readonly microseconds: number;
                      readonly forHumans: string;
                  } | null;
                  readonly subTotal: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
                  readonly total: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
              } | null;
              readonly recurrenceInterval: {
                  readonly __typename?: 'DateInterval';
                  readonly years: number;
                  readonly months: number;
                  readonly weeks: number;
                  readonly days: number;
                  readonly hours: number;
                  readonly minutes: number;
                  readonly seconds: number;
                  readonly microseconds: number;
                  readonly forHumans: string;
              } | null;
          }
        | {
              readonly __typename?: 'SubscriptionPurchase';
              readonly id: string;
              readonly type: PurchaseType;
              readonly displayName: string;
              readonly privateCredits: number;
              readonly groupCredits: number;
              readonly isSubscription: boolean;
              readonly canUseCoupon: boolean;
              readonly withTrial: boolean;
              readonly contentBlocks: ReadonlyArray<{
                  readonly __typename?: 'ContentBlock';
                  readonly location: Location;
                  readonly label: string | null;
                  readonly text: string;
                  readonly icon: Icon | null;
                  readonly isStarred: boolean;
                  readonly table: string[][] | null;
              }>;
              readonly coupons: ReadonlyArray<{
                  readonly __typename?: 'Coupon';
                  readonly id: number;
                  readonly code: string;
              }>;
              readonly total: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly subtotal: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly chargeTotal: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly discount: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly agreements: ReadonlyArray<{
                  readonly __typename?: 'Agreement';
                  readonly id: string;
                  readonly acceptanceText: string;
                  readonly pdfLink: string | null;
              }>;
              readonly validityInterval: {
                  readonly __typename?: 'DateInterval';
                  readonly years: number;
                  readonly months: number;
                  readonly weeks: number;
                  readonly days: number;
                  readonly hours: number;
                  readonly minutes: number;
                  readonly seconds: number;
                  readonly microseconds: number;
                  readonly forHumans: string;
              };
              readonly lastTransaction: {
                  readonly __typename?: 'Transaction';
                  readonly id: number;
                  readonly errorCode: string | null;
                  readonly errorMessage: string | null;
                  readonly paymentStatus: PaymentStatus;
                  readonly isInitialPurchase: boolean;
                  readonly isPaymentMethodUpdate: boolean;
                  readonly currency: string;
                  readonly discount: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
                  readonly amount: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
              } | null;
              readonly recurrenceInterval: {
                  readonly __typename?: 'DateInterval';
                  readonly years: number;
                  readonly months: number;
                  readonly weeks: number;
                  readonly days: number;
                  readonly hours: number;
                  readonly minutes: number;
                  readonly seconds: number;
                  readonly microseconds: number;
                  readonly forHumans: string;
              } | null;
              readonly newSection: {
                  readonly __typename?: 'Section';
                  readonly name: SectionName;
              } | null;
              readonly curriculum: {
                  readonly __typename?: 'Curriculum';
                  readonly id: number;
              } | null;
          };
};

export type LearningUnitExerciseFragment = {
    readonly __typename?: 'LearningUnit';
    readonly id: number;
    readonly module: {
        readonly __typename?: 'Module';
        readonly id: number;
        readonly name: string;
    } | null;
    readonly lessons: ReadonlyArray<{
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly type: LessonType | null;
    }>;
    readonly exercise: {
        readonly __typename?: 'Exercise';
        readonly id: string;
        readonly link: string | null;
        readonly score: number | null;
        readonly title: string;
    } | null;
};

export type SetLessonsStatusMutationVariables = Exact<{
    lessonsStatus: ReadonlyArray<LessonStatusInput> | LessonStatusInput;
}>;

export type SetLessonsStatusMutation = {
    readonly __typename?: 'Mutation';
    readonly setLessonsStatus: boolean;
};

export type ExerciseQueryVariables = Exact<{
    learningUnitId: Scalars['Int']['input'];
}>;

export type ExerciseQuery = {
    readonly __typename?: 'Query';
    readonly exercise: {
        readonly __typename?: 'Exercise';
        readonly id: string;
        readonly score: number | null;
        readonly link: string | null;
        readonly title: string;
    } | null;
};

export type PersonalizedFeedbackSubmitMutationVariables = Exact<{
    firstAnswer: Scalars['String']['input'];
    secondAnswer: Scalars['String']['input'];
}>;

export type PersonalizedFeedbackSubmitMutation = {
    readonly __typename?: 'Mutation';
    readonly submitPersonalizedFeedback: boolean;
};

export type PersonalizedFeedbackQuestionsQueryVariables = Exact<{ [key: string]: never }>;

export type PersonalizedFeedbackQuestionsQuery = {
    readonly __typename?: 'Query';
    readonly personalizedFeedbackQuestions: ReadonlyArray<{
        readonly __typename?: 'QuestionnaireQuestion';
        readonly question: string;
        readonly answers: ReadonlyArray<{
            readonly __typename?: 'QuestionnaireQuestionAnswer';
            readonly answer: string;
        } | null> | null;
    }>;
};

export type SubmitPracticeSatisfactionScoreMutationVariables = Exact<{
    score: Scalars['Int']['input'];
}>;

export type SubmitPracticeSatisfactionScoreMutation = {
    readonly __typename?: 'Mutation';
    readonly submitPracticeSatisfactionScore: boolean;
};

export type SubmitPracticeSatisfactionFeedbackMutationVariables = Exact<{
    feedback: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type SubmitPracticeSatisfactionFeedbackMutation = {
    readonly __typename?: 'Mutation';
    readonly submitPracticeSatisfactionFeedback: boolean;
};

export type ShowPracticeSatisfactionSurveyQueryVariables = Exact<{ [key: string]: never }>;

export type ShowPracticeSatisfactionSurveyQuery = {
    readonly __typename?: 'Query';
    readonly student: {
        readonly __typename?: 'Student';
        readonly id: number;
        readonly showPracticeSatisfactionSurvey: boolean;
    };
};

export type CancelSubscriptionDataFragment = {
    readonly __typename?: 'SprintCancelRolloverQuestionnaire';
    readonly questions: ReadonlyArray<{
        readonly __typename?: 'SprintCancelRolloverQuestionnaireQuestion';
        readonly mood: EmojiType;
        readonly question: {
            readonly __typename?: 'QuestionnaireQuestion';
            readonly id: string;
            readonly question: string;
            readonly description: string;
            readonly answers: ReadonlyArray<{
                readonly __typename?: 'QuestionnaireQuestionAnswer';
                readonly id: string;
                readonly answer: string;
            } | null> | null;
        };
    }>;
};

export type CancelQuestionnaireQuestionsFragment = {
    readonly __typename?: 'SprintCancelRolloverQuestionnaireQuestion';
    readonly mood: EmojiType;
    readonly question: {
        readonly __typename?: 'QuestionnaireQuestion';
        readonly id: string;
        readonly question: string;
        readonly description: string;
        readonly answers: ReadonlyArray<{
            readonly __typename?: 'QuestionnaireQuestionAnswer';
            readonly id: string;
            readonly answer: string;
        } | null> | null;
    };
};

export type CancelQuestionnaireQuestionFragment = {
    readonly __typename?: 'QuestionnaireQuestion';
    readonly id: string;
    readonly question: string;
    readonly description: string;
    readonly answers: ReadonlyArray<{
        readonly __typename?: 'QuestionnaireQuestionAnswer';
        readonly id: string;
        readonly answer: string;
    } | null> | null;
};

export type UserSubscriptionFragment = {
    readonly __typename?: 'Subscription';
    readonly id: number;
    readonly isInTrial: boolean;
    readonly activatedAt: string | null;
    readonly createdAt: string | null;
    readonly expiresAt: string | null;
    readonly status: SubscriptionStatus;
    readonly canChange: boolean;
    readonly canPause: boolean;
    readonly canUnpause: boolean;
    readonly pauseScheduled: boolean;
    readonly startDelayedUntil: string | null;
    readonly canCancel: boolean;
    readonly canUncancel: boolean;
    readonly canStartNextNow: boolean;
    readonly canReactivate: boolean;
    readonly canStartNew: boolean;
    readonly canCancelTrial: boolean;
    readonly isRecurring: boolean;
    readonly origin: SubscriptionOrigin;
    readonly subscribeNow: boolean;
    readonly canSkipTrial: boolean;
    readonly type: SubscriptionType;
    readonly paymentMethodAdded: boolean | null;
    readonly price: {
        readonly __typename?: 'Price';
        readonly currency: Currency;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly forHumans: string;
    };
    readonly contentBlocks: ReadonlyArray<{
        readonly __typename?: 'ContentBlock';
        readonly text: string;
        readonly location: Location;
        readonly label: string | null;
        readonly icon: Icon | null;
        readonly table: string[][] | null;
        readonly isStarred: boolean;
    }>;
};

export type SkipTrialSubscriptionMutationVariables = Exact<{ [key: string]: never }>;

export type SkipTrialSubscriptionMutation = {
    readonly __typename?: 'Mutation';
    readonly subscription: {
        readonly __typename?: 'Subscription';
        readonly id: number;
        readonly isInTrial: boolean;
        readonly activatedAt: string | null;
        readonly createdAt: string | null;
        readonly expiresAt: string | null;
        readonly status: SubscriptionStatus;
        readonly canChange: boolean;
        readonly canPause: boolean;
        readonly canUnpause: boolean;
        readonly pauseScheduled: boolean;
        readonly startDelayedUntil: string | null;
        readonly canCancel: boolean;
        readonly canUncancel: boolean;
        readonly canStartNextNow: boolean;
        readonly canReactivate: boolean;
        readonly canStartNew: boolean;
        readonly canCancelTrial: boolean;
        readonly isRecurring: boolean;
        readonly origin: SubscriptionOrigin;
        readonly subscribeNow: boolean;
        readonly canSkipTrial: boolean;
        readonly type: SubscriptionType;
        readonly paymentMethodAdded: boolean | null;
        readonly price: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly contentBlocks: ReadonlyArray<{
            readonly __typename?: 'ContentBlock';
            readonly text: string;
            readonly location: Location;
            readonly label: string | null;
            readonly icon: Icon | null;
            readonly table: string[][] | null;
            readonly isStarred: boolean;
        }>;
    };
};

export type CancelSubscriptionWithFeedbackMutationVariables = Exact<{
    reason: Scalars['String']['input'];
    mood?: InputMaybe<EmojiType>;
    reasonComment: Scalars['String']['input'];
}>;

export type CancelSubscriptionWithFeedbackMutation = {
    readonly __typename?: 'Mutation';
    readonly cancelSubscriptionWithFeedback: {
        readonly __typename?: 'SubscriptionFeedback';
        readonly mood: string;
    };
};

export type DelayNextSubscriptionStartDateMutationVariables = Exact<{
    delayUntil: Scalars['DateTime']['input'];
}>;

export type DelayNextSubscriptionStartDateMutation = {
    readonly __typename?: 'Mutation';
    readonly delayNextSubscriptionStartDate: boolean;
};

export type CancelSprintRolloverWithQuestionnaireMutationVariables = Exact<{
    sprintCancelRolloverQuestionnaire: SprintCancelRolloverQuestionnaireInput;
}>;

export type CancelSprintRolloverWithQuestionnaireMutation = {
    readonly __typename?: 'Mutation';
    readonly cancelSprintRolloverWithQuestionnaire: boolean;
};

export type CancelTrialSubscriptionMutationVariables = Exact<{ [key: string]: never }>;

export type CancelTrialSubscriptionMutation = {
    readonly __typename?: 'Mutation';
    readonly subscription: {
        readonly __typename?: 'Subscription';
        readonly id: number;
        readonly status: SubscriptionStatus;
    };
};

export type CancelScheduledPauseMutationVariables = Exact<{ [key: string]: never }>;

export type CancelScheduledPauseMutation = {
    readonly __typename?: 'Mutation';
    readonly cancelScheduledPause: {
        readonly __typename?: 'Subscription';
        readonly id: number;
        readonly isInTrial: boolean;
        readonly activatedAt: string | null;
        readonly createdAt: string | null;
        readonly expiresAt: string | null;
        readonly status: SubscriptionStatus;
        readonly canChange: boolean;
        readonly canPause: boolean;
        readonly canUnpause: boolean;
        readonly pauseScheduled: boolean;
        readonly startDelayedUntil: string | null;
        readonly canCancel: boolean;
        readonly canUncancel: boolean;
        readonly canStartNextNow: boolean;
        readonly canReactivate: boolean;
        readonly canStartNew: boolean;
        readonly canCancelTrial: boolean;
        readonly isRecurring: boolean;
        readonly origin: SubscriptionOrigin;
        readonly subscribeNow: boolean;
        readonly canSkipTrial: boolean;
        readonly type: SubscriptionType;
        readonly paymentMethodAdded: boolean | null;
        readonly price: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly contentBlocks: ReadonlyArray<{
            readonly __typename?: 'ContentBlock';
            readonly text: string;
            readonly location: Location;
            readonly label: string | null;
            readonly icon: Icon | null;
            readonly table: string[][] | null;
            readonly isStarred: boolean;
        }>;
    };
};

export type CancelNextSubscriptionDelayedStartMutationVariables = Exact<{ [key: string]: never }>;

export type CancelNextSubscriptionDelayedStartMutation = {
    readonly __typename?: 'Mutation';
    readonly cancelNextSubscriptionDelayedStart: boolean;
};

export type DeleteNextSubscriptionMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteNextSubscriptionMutation = {
    readonly __typename?: 'Mutation';
    readonly deleteNextSubscription: boolean;
};

export type RolloverSubscriptionMutationVariables = Exact<{ [key: string]: never }>;

export type RolloverSubscriptionMutation = {
    readonly __typename?: 'Mutation';
    readonly rolloverSubscription: boolean;
};

export type UserSubscriptionsQueryVariables = Exact<{ [key: string]: never }>;

export type UserSubscriptionsQuery = {
    readonly __typename?: 'Query';
    readonly subscriptions: {
        readonly __typename?: 'StudentSubscriptionInfo';
        readonly currentSubscription: {
            readonly __typename?: 'Subscription';
            readonly id: number;
            readonly isInTrial: boolean;
            readonly activatedAt: string | null;
            readonly createdAt: string | null;
            readonly expiresAt: string | null;
            readonly status: SubscriptionStatus;
            readonly canChange: boolean;
            readonly canPause: boolean;
            readonly canUnpause: boolean;
            readonly pauseScheduled: boolean;
            readonly startDelayedUntil: string | null;
            readonly canCancel: boolean;
            readonly canUncancel: boolean;
            readonly canStartNextNow: boolean;
            readonly canReactivate: boolean;
            readonly canStartNew: boolean;
            readonly canCancelTrial: boolean;
            readonly isRecurring: boolean;
            readonly origin: SubscriptionOrigin;
            readonly subscribeNow: boolean;
            readonly canSkipTrial: boolean;
            readonly type: SubscriptionType;
            readonly paymentMethodAdded: boolean | null;
            readonly price: {
                readonly __typename?: 'Price';
                readonly currency: Currency;
                readonly amount: number;
                readonly amountInMajor: number;
                readonly forHumans: string;
            };
            readonly contentBlocks: ReadonlyArray<{
                readonly __typename?: 'ContentBlock';
                readonly text: string;
                readonly location: Location;
                readonly label: string | null;
                readonly icon: Icon | null;
                readonly table: string[][] | null;
                readonly isStarred: boolean;
            }>;
        } | null;
        readonly latestSubscription: {
            readonly __typename?: 'Subscription';
            readonly id: number;
            readonly isInTrial: boolean;
            readonly activatedAt: string | null;
            readonly createdAt: string | null;
            readonly expiresAt: string | null;
            readonly status: SubscriptionStatus;
            readonly canChange: boolean;
            readonly canPause: boolean;
            readonly canUnpause: boolean;
            readonly pauseScheduled: boolean;
            readonly startDelayedUntil: string | null;
            readonly canCancel: boolean;
            readonly canUncancel: boolean;
            readonly canStartNextNow: boolean;
            readonly canReactivate: boolean;
            readonly canStartNew: boolean;
            readonly canCancelTrial: boolean;
            readonly isRecurring: boolean;
            readonly origin: SubscriptionOrigin;
            readonly subscribeNow: boolean;
            readonly canSkipTrial: boolean;
            readonly type: SubscriptionType;
            readonly paymentMethodAdded: boolean | null;
            readonly price: {
                readonly __typename?: 'Price';
                readonly currency: Currency;
                readonly amount: number;
                readonly amountInMajor: number;
                readonly forHumans: string;
            };
            readonly contentBlocks: ReadonlyArray<{
                readonly __typename?: 'ContentBlock';
                readonly text: string;
                readonly location: Location;
                readonly label: string | null;
                readonly icon: Icon | null;
                readonly table: string[][] | null;
                readonly isStarred: boolean;
            }>;
        } | null;
        readonly nextSubscription: {
            readonly __typename?: 'Subscription';
            readonly id: number;
            readonly isInTrial: boolean;
            readonly activatedAt: string | null;
            readonly createdAt: string | null;
            readonly expiresAt: string | null;
            readonly status: SubscriptionStatus;
            readonly canChange: boolean;
            readonly canPause: boolean;
            readonly canUnpause: boolean;
            readonly pauseScheduled: boolean;
            readonly startDelayedUntil: string | null;
            readonly canCancel: boolean;
            readonly canUncancel: boolean;
            readonly canStartNextNow: boolean;
            readonly canReactivate: boolean;
            readonly canStartNew: boolean;
            readonly canCancelTrial: boolean;
            readonly isRecurring: boolean;
            readonly origin: SubscriptionOrigin;
            readonly subscribeNow: boolean;
            readonly canSkipTrial: boolean;
            readonly type: SubscriptionType;
            readonly paymentMethodAdded: boolean | null;
            readonly price: {
                readonly __typename?: 'Price';
                readonly currency: Currency;
                readonly amount: number;
                readonly amountInMajor: number;
                readonly forHumans: string;
            };
            readonly contentBlocks: ReadonlyArray<{
                readonly __typename?: 'ContentBlock';
                readonly text: string;
                readonly location: Location;
                readonly label: string | null;
                readonly icon: Icon | null;
                readonly table: string[][] | null;
                readonly isStarred: boolean;
            }>;
        } | null;
    };
};

export type SprintCancelRolloverQuestionnaireQueryVariables = Exact<{ [key: string]: never }>;

export type SprintCancelRolloverQuestionnaireQuery = {
    readonly __typename?: 'Query';
    readonly sprintCancelRolloverQuestionnaire: {
        readonly __typename?: 'SprintCancelRolloverQuestionnaire';
        readonly currentSprintQuestionnaireFinished: boolean;
        readonly questions: ReadonlyArray<{
            readonly __typename?: 'SprintCancelRolloverQuestionnaireQuestion';
            readonly mood: EmojiType;
            readonly question: {
                readonly __typename?: 'QuestionnaireQuestion';
                readonly id: string;
                readonly question: string;
                readonly description: string;
                readonly answers: ReadonlyArray<{
                    readonly __typename?: 'QuestionnaireQuestionAnswer';
                    readonly id: string;
                    readonly answer: string;
                } | null> | null;
            };
        }>;
    };
};

export type StudentCompletedClassesQueryVariables = Exact<{ [key: string]: never }>;

export type StudentCompletedClassesQuery = {
    readonly __typename?: 'Query';
    readonly student: { readonly __typename?: 'Student'; readonly completedClasses: number };
};

export type PracticeQuizDashboardQueryVariables = Exact<{
    to: Scalars['String']['input'];
}>;

export type PracticeQuizDashboardQuery = {
    readonly __typename?: 'Query';
    readonly quizEngineProgress: {
        readonly __typename?: 'QuizProgress';
        readonly total: number;
        readonly known: number;
        readonly reviewing: number;
        readonly uncompleted: number;
        readonly lastReviewed: string | null;
        readonly periodProgress: {
            readonly __typename?: 'LearningProgress';
            readonly known: number;
        } | null;
    };
};

export type FinishQuizStatsQueryVariables = Exact<{ [key: string]: never }>;

export type FinishQuizStatsQuery = {
    readonly __typename?: 'Query';
    readonly quizEngineProgress: {
        readonly __typename?: 'QuizProgress';
        readonly total: number;
        readonly known: number;
        readonly reviewing: number;
        readonly reviewed: number;
    };
};

export type ResetResultsMutationVariables = Exact<{ [key: string]: never }>;

export type ResetResultsMutation = {
    readonly __typename?: 'Mutation';
    readonly quizEngineResetResult: boolean;
};

export type ReportItemDialogSubmitMutationVariables = Exact<{
    answersIds: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input'];
    questionId: Scalars['String']['input'];
    exerciseId: Scalars['String']['input'];
    feedback?: InputMaybe<Scalars['String']['input']>;
    vocabularyItemId: Scalars['String']['input'];
}>;

export type ReportItemDialogSubmitMutation = {
    readonly __typename?: 'Mutation';
    readonly submitVocabTrainerFeedback: boolean;
};

export type ReportItemDialogOptionsQueryVariables = Exact<{
    exerciseType?: InputMaybe<SessionExerciseType>;
}>;

export type ReportItemDialogOptionsQuery = {
    readonly __typename?: 'Query';
    readonly quizFeedbackQuestions: ReadonlyArray<{
        readonly __typename?: 'QuestionnaireQuestion';
        readonly id: string;
        readonly answers: ReadonlyArray<{
            readonly __typename?: 'QuestionnaireQuestionAnswer';
            readonly id: string;
            readonly answer: string;
            readonly slug: string;
        } | null> | null;
    }>;
};

export type ReportQuizResultMutationVariables = Exact<{
    sessionId: Scalars['String']['input'];
    questionId: Scalars['String']['input'];
    answers: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input'];
    isSkipped?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ReportQuizResultMutation = {
    readonly __typename?: 'Mutation';
    readonly quizEngineReportResult: {
        readonly __typename?: 'ReportResultPayload';
        readonly score: number;
    };
};

export type QuizStartPageProgressQueryVariables = Exact<{ [key: string]: never }>;

export type QuizStartPageProgressQuery = {
    readonly __typename?: 'Query';
    readonly quizEngineProgress: {
        readonly __typename?: 'QuizProgress';
        readonly total: number;
        readonly completed: number;
        readonly uncompleted: number;
        readonly known: number;
        readonly reviewing: number;
    };
};

export type QuizStartPageSessionQueryVariables = Exact<{ [key: string]: never }>;

export type QuizStartPageSessionQuery = {
    readonly __typename?: 'Query';
    readonly quizEngineProgress: {
        readonly __typename?: 'QuizProgress';
        readonly total: number;
        readonly completed: number;
        readonly uncompleted: number;
        readonly known: number;
        readonly reviewing: number;
    };
    readonly quizEngineQuiz: {
        readonly __typename?: 'Quiz';
        readonly hasMore: boolean;
        readonly session: {
            readonly __typename?: 'Session';
            readonly id: string;
            readonly exercises: ReadonlyArray<{
                readonly __typename?: 'QuizExercise';
                readonly id: string;
                readonly vocabularyId: string | null;
                readonly questions: ReadonlyArray<{
                    readonly __typename?: 'Question';
                    readonly id: string;
                    readonly title: string;
                    readonly audioUrl: string | null;
                    readonly meta: {
                        readonly __typename?: 'QuestionMeta';
                        readonly gender: GenderType | null;
                        readonly plural: string | null;
                        readonly wordClass: string | null;
                        readonly sampleSentence: string | null;
                        readonly translations: {
                            readonly __typename?: 'QuizMetaTranslations';
                            readonly de: string | null;
                            readonly en: string | null;
                            readonly es: string | null;
                            readonly fr: string | null;
                            readonly ru: string | null;
                        } | null;
                    } | null;
                    readonly translation: {
                        readonly __typename?: 'QuestionTranslations';
                        readonly wholeSentenceToTarget: string;
                    } | null;
                    readonly titleTranslation: {
                        readonly __typename?: 'QuestionTranslations';
                        readonly byWord: ReadonlyArray<{
                            readonly __typename?: 'QuestionByWordTranslations';
                            readonly id: string;
                            readonly source: string;
                            readonly translation: string | null;
                        }>;
                    } | null;
                    readonly choices: ReadonlyArray<{
                        readonly __typename?: 'Choice';
                        readonly id: string;
                        readonly isCorrect: boolean;
                        readonly text: string;
                        readonly audioUrl: string | null;
                        readonly translation: {
                            readonly __typename?: 'QuestionTranslations';
                            readonly byWord: ReadonlyArray<{
                                readonly __typename?: 'QuestionByWordTranslations';
                                readonly id: string;
                                readonly source: string;
                                readonly translation: string | null;
                            }>;
                        } | null;
                    }>;
                }>;
            }>;
        } | null;
    };
};

export type ConvertGroupCreditsIntoPrivateMutationVariables = Exact<{
    numberOfCredits: Scalars['Int']['input'];
}>;

export type ConvertGroupCreditsIntoPrivateMutation = {
    readonly __typename?: 'Mutation';
    readonly convertGroupCreditsIntoPrivate: boolean;
};

export type LastTransactionQueryVariables = Exact<{ [key: string]: never }>;

export type LastTransactionQuery = {
    readonly __typename?: 'Query';
    readonly lastTransaction: {
        readonly __typename?: 'Transaction';
        readonly id: number;
        readonly errorCode: string | null;
        readonly errorMessage: string | null;
        readonly paymentStatus: PaymentStatus;
        readonly isInitialPurchase: boolean;
        readonly isPaymentMethodUpdate: boolean;
        readonly currency: string;
        readonly discount: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly amount: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly coupons: ReadonlyArray<{
            readonly __typename?: 'Coupon';
            readonly id: number;
            readonly code: string;
        }>;
    } | null;
};

export type LastTransactionFragment = {
    readonly __typename?: 'Transaction';
    readonly id: number;
    readonly errorCode: string | null;
    readonly errorMessage: string | null;
    readonly paymentStatus: PaymentStatus;
    readonly isInitialPurchase: boolean;
    readonly isPaymentMethodUpdate: boolean;
    readonly currency: string;
    readonly discount: {
        readonly __typename?: 'Price';
        readonly currency: Currency;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly forHumans: string;
    };
    readonly amount: {
        readonly __typename?: 'Price';
        readonly currency: Currency;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly forHumans: string;
    };
    readonly coupons: ReadonlyArray<{
        readonly __typename?: 'Coupon';
        readonly id: number;
        readonly code: string;
    }>;
};

export type TrialStoreBannerQueryVariables = Exact<{ [key: string]: never }>;

export type TrialStoreBannerQuery = {
    readonly __typename?: 'Query';
    readonly appropriatePromoCampaign: {
        readonly __typename?: 'PromoCampaign';
        readonly bannerText: string;
        readonly campaignEndDate: string;
        readonly campaignStartDate: string;
        readonly type: PromoCampaignType;
    } | null;
};

export type OnboardingChecklistQueryVariables = Exact<{
    slug: Scalars['String']['input'];
}>;

export type OnboardingChecklistQuery = {
    readonly __typename?: 'Query';
    readonly checklist: ReadonlyArray<{
        readonly __typename?: 'ChecklistItem';
        readonly id: string;
        readonly description: string;
        readonly isCompleted: boolean;
        readonly slug: ChecklistItemSlug;
        readonly title: string;
    }>;
};

export type OnboardingAchievementQueryVariables = Exact<{
    slug: Scalars['String']['input'];
}>;

export type OnboardingAchievementQuery = {
    readonly __typename?: 'Query';
    readonly studentAchievement: {
        readonly __typename?: 'Achievement';
        readonly claimId: string | null;
        readonly name: string;
        readonly value: number;
        readonly currentProgress: number;
        readonly progressLabel: string;
        readonly achievedLabel: string;
        readonly rewardText: string;
        readonly congratulationsText: string;
        readonly achievedAt: string | null;
        readonly claimedAt: string | null;
        readonly claimed: boolean;
        readonly achieved: boolean;
        readonly id: string;
        readonly slug: string;
    } | null;
};

export type ClosestAppointmentsQueryVariables = Exact<{
    historicalStartDate: Scalars['DateTime']['input'];
    ongoingStartDate: Scalars['DateTime']['input'];
}>;

export type ClosestAppointmentsQuery = {
    readonly __typename?: 'Query';
    readonly pastAppointments: ReadonlyArray<{
        readonly __typename?: 'Appointment';
        readonly id: string;
        readonly status: AppointmentStatus;
        readonly class: {
            readonly __typename?: 'Klass';
            readonly uid: string;
            readonly startDate: string;
            readonly endDate: string;
        };
    }>;
    readonly futureAppointments: ReadonlyArray<{
        readonly __typename?: 'Appointment';
        readonly id: string;
        readonly status: AppointmentStatus;
        readonly class: {
            readonly __typename?: 'Klass';
            readonly uid: string;
            readonly startDate: string;
            readonly endDate: string;
        };
    }>;
};

export type StudentWeeklyGoalFragment = {
    readonly __typename?: 'StudentGoal';
    readonly id: string;
    readonly active: boolean;
    readonly value: number;
    readonly createdAt: string;
};

export type StudentGoalWidgetDataFragment = {
    readonly __typename?: 'WidgetData';
    readonly firstAppointmentDate: string | null;
    readonly hasNext: boolean;
    readonly hasPrevious: boolean;
    readonly range: string;
    readonly weeklyProgress: ReadonlyArray<{
        readonly __typename?: 'WeeklyProgress';
        readonly shortLabel: string;
        readonly longLabel: string;
        readonly attendance: number;
        readonly goal: number | null;
    }>;
};

export type SetWeeklyGoalMutationVariables = Exact<{
    value: Scalars['Int']['input'];
}>;

export type SetWeeklyGoalMutation = {
    readonly __typename?: 'Mutation';
    readonly setWeeklyGoal: {
        readonly __typename?: 'StudentGoal';
        readonly id: string;
        readonly active: boolean;
        readonly value: number;
        readonly createdAt: string;
    };
};

export type WeeklyProgressQueryVariables = Exact<{
    page: Scalars['Int']['input'];
}>;

export type WeeklyProgressQuery = {
    readonly __typename?: 'Query';
    readonly activeGoal: {
        readonly __typename?: 'StudentGoal';
        readonly id: string;
        readonly active: boolean;
        readonly value: number;
        readonly createdAt: string;
    } | null;
    readonly studentGoalWidgetData: {
        readonly __typename?: 'WidgetData';
        readonly firstAppointmentDate: string | null;
        readonly hasNext: boolean;
        readonly hasPrevious: boolean;
        readonly range: string;
        readonly weeklyProgress: ReadonlyArray<{
            readonly __typename?: 'WeeklyProgress';
            readonly shortLabel: string;
            readonly longLabel: string;
            readonly attendance: number;
            readonly goal: number | null;
        }>;
    };
};

export type GoalSettingQueryVariables = Exact<{
    page: Scalars['Int']['input'];
}>;

export type GoalSettingQuery = {
    readonly __typename?: 'Query';
    readonly subscriptions: {
        readonly __typename?: 'StudentSubscriptionInfo';
        readonly currentSubscription: {
            readonly __typename?: 'Subscription';
            readonly id: number;
        } | null;
    };
    readonly appointments: {
        readonly __typename?: 'WidgetData';
        readonly firstAppointmentDate: string | null;
    };
    readonly activeGoal: { readonly __typename?: 'StudentGoal'; readonly id: string } | null;
};

export type StudentAchievementsQueryVariables = Exact<{ [key: string]: never }>;

export type StudentAchievementsQuery = {
    readonly __typename?: 'Query';
    readonly studentAchievements: ReadonlyArray<{
        readonly __typename?: 'Achievement';
        readonly claimId: string | null;
        readonly name: string;
        readonly value: number;
        readonly currentProgress: number;
        readonly progressLabel: string;
        readonly achievedLabel: string;
        readonly rewardText: string;
        readonly congratulationsText: string;
        readonly achievedAt: string | null;
        readonly claimedAt: string | null;
        readonly claimed: boolean;
        readonly achieved: boolean;
        readonly id: string;
        readonly slug: string;
    }>;
};

export type ClaimAttendanceAchievementMutationVariables = Exact<{
    claimId: Scalars['Ulid']['input'];
}>;

export type ClaimAttendanceAchievementMutation = {
    readonly __typename?: 'Mutation';
    readonly claimAttendanceAchievement: {
        readonly __typename?: 'Achievement';
        readonly claimId: string | null;
        readonly name: string;
        readonly value: number;
        readonly currentProgress: number;
        readonly progressLabel: string;
        readonly achievedLabel: string;
        readonly rewardText: string;
        readonly congratulationsText: string;
        readonly achievedAt: string | null;
        readonly claimedAt: string | null;
        readonly claimed: boolean;
        readonly achieved: boolean;
        readonly id: string;
        readonly slug: string;
    };
};

export type AchievementFragment = {
    readonly __typename?: 'Achievement';
    readonly claimId: string | null;
    readonly name: string;
    readonly value: number;
    readonly currentProgress: number;
    readonly progressLabel: string;
    readonly achievedLabel: string;
    readonly rewardText: string;
    readonly congratulationsText: string;
    readonly achievedAt: string | null;
    readonly claimedAt: string | null;
    readonly claimed: boolean;
    readonly achieved: boolean;
    readonly id: string;
    readonly slug: string;
};

export type PromoteGuestUserMutationVariables = Exact<{
    password: Scalars['String']['input'];
    name?: InputMaybe<Scalars['String']['input']>;
}>;

export type PromoteGuestUserMutation = {
    readonly __typename?: 'Mutation';
    readonly promoteGuestUser: { readonly __typename?: 'User'; readonly id: number };
};

export type BookingPreviewResultsQueryVariables = Exact<{
    bookingFilter: ProvideBookableClassSlotsInput;
}>;

export type BookingPreviewResultsQuery = {
    readonly __typename?: 'Query';
    readonly classes: ReadonlyArray<{
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly type: ClassType;
        readonly isExisting: boolean;
        readonly isForNewClassroom: boolean;
        readonly module: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
        };
        readonly learningUnit: {
            readonly __typename?: 'LearningUnit';
            readonly id: number;
            readonly chapter: number;
        } | null;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        } | null;
        readonly students: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly shortName: string;
            readonly id: number;
            readonly firstLetterOfFirstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        }>;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly label: string;
            readonly title: string | null;
            readonly type: LessonType | null;
            readonly studentStatus: StudentStatus;
            readonly availableWithoutPayment: boolean;
        } | null;
        readonly appointment: {
            readonly __typename?: 'Appointment';
            readonly id: string;
            readonly status: AppointmentStatus;
        } | null;
    }>;
};

export type AnswerBookingExperienceSurveyMutationVariables = Exact<{
    score: Scalars['Int']['input'];
}>;

export type AnswerBookingExperienceSurveyMutation = {
    readonly __typename?: 'Mutation';
    readonly answerBookingExperienceSurvey: {
        readonly __typename?: 'ClassBookingExperienceSurvey';
        readonly id: string;
    };
};

export type AnswerBookingExperienceSurveyDetailsMutationVariables = Exact<{
    extraFeedback?: InputMaybe<
        ReadonlyArray<BookingExperienceExtraFeedback> | BookingExperienceExtraFeedback
    >;
    comment?: InputMaybe<Scalars['String']['input']>;
    answer?: InputMaybe<BookingExperienceAnswerType>;
}>;

export type AnswerBookingExperienceSurveyDetailsMutation = {
    readonly __typename?: 'Mutation';
    readonly answerBookingExperienceSurveyDetails: {
        readonly __typename?: 'ClassBookingExperienceSurvey';
        readonly id: string;
    };
};

export type DismissBookingExperienceSurveyMutationVariables = Exact<{ [key: string]: never }>;

export type DismissBookingExperienceSurveyMutation = {
    readonly __typename?: 'Mutation';
    readonly dismissBookingExperienceSurvey: {
        readonly __typename?: 'ClassBookingExperienceSurvey';
        readonly id: string;
    };
};

export type StudentGetBookingFeedbackFormQueryVariables = Exact<{ [key: string]: never }>;

export type StudentGetBookingFeedbackFormQuery = {
    readonly __typename?: 'Query';
    readonly bookingExperienceQuestions: ReadonlyArray<{
        readonly __typename?: 'BookingExperienceSurveyQuestion';
        readonly key: string;
        readonly translatedQuestion: string;
        readonly screenPosition: number;
        readonly fieldType: string;
        readonly answers: ReadonlyArray<{
            readonly __typename?: 'BookingExperienceSurveyAnswer';
            readonly value: string;
            readonly translatedAnswer: string;
        }>;
    }>;
};

export type BookingSearchResultsQueryVariables = Exact<{
    bookingFilter: ProvideBookableClassSlotsInput;
}>;

export type BookingSearchResultsQuery = {
    readonly __typename?: 'Query';
    readonly classes: ReadonlyArray<{
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly type: ClassType;
        readonly isExisting: boolean;
        readonly isForNewClassroom: boolean;
        readonly module: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
        };
        readonly learningUnit: {
            readonly __typename?: 'LearningUnit';
            readonly id: number;
            readonly chapter: number;
        } | null;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        } | null;
        readonly students: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly shortName: string;
            readonly id: number;
            readonly firstLetterOfFirstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        }>;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly label: string;
            readonly title: string | null;
            readonly type: LessonType | null;
            readonly studentStatus: StudentStatus;
            readonly availableWithoutPayment: boolean;
        } | null;
        readonly appointment: {
            readonly __typename?: 'Appointment';
            readonly id: string;
            readonly status: AppointmentStatus;
        } | null;
    }>;
};

export type BookingSuggestedResultsQueryVariables = Exact<{
    nextAvailableClassDateBookingFilter: ProvideBookableClassSlotsInput;
    suggestedClassesBookingFilter: ProvideBookableClassSlotsInput;
}>;

export type BookingSuggestedResultsQuery = {
    readonly __typename?: 'Query';
    readonly nextAvailableClassDate: string;
    readonly suggestedClasses: ReadonlyArray<{
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly type: ClassType;
        readonly isExisting: boolean;
        readonly isForNewClassroom: boolean;
        readonly module: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
        };
        readonly learningUnit: {
            readonly __typename?: 'LearningUnit';
            readonly id: number;
            readonly chapter: number;
        } | null;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        } | null;
        readonly students: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly shortName: string;
            readonly id: number;
            readonly firstLetterOfFirstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        }>;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly label: string;
            readonly title: string | null;
            readonly type: LessonType | null;
            readonly studentStatus: StudentStatus;
            readonly availableWithoutPayment: boolean;
        } | null;
        readonly appointment: {
            readonly __typename?: 'Appointment';
            readonly id: string;
            readonly status: AppointmentStatus;
        } | null;
    }>;
};

export type BookingWidgetQueryVariables = Exact<{
    bookingFilter: ProvideBookableClassSlotsInput;
}>;

export type BookingWidgetQuery = {
    readonly __typename?: 'Query';
    readonly classes: ReadonlyArray<{
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly isExisting: boolean;
        readonly startDate: string;
        readonly endDate: string;
        readonly isForNewClassroom: boolean;
        readonly students: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly id: number;
            readonly firstLetterOfFirstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        }>;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        } | null;
        readonly appointment: {
            readonly __typename?: 'Appointment';
            readonly id: string;
            readonly status: AppointmentStatus;
        } | null;
    }>;
};

export type ClassDetailsTileAppointmentFragment = {
    readonly __typename?: 'Appointment';
    readonly id: string;
    readonly createdAt: string;
    readonly label: string | null;
    readonly status: AppointmentStatus;
    readonly isRatedByStudent: boolean;
    readonly hasAttemptToJoin: boolean;
};

export type ClassDetailsTileLessonFragment = {
    readonly __typename?: 'Lesson';
    readonly id: number;
};

export type ClassDetailsTileTeacherFragment = {
    readonly __typename?: 'Teacher';
    readonly id: number;
    readonly uid: string;
    readonly firstName: string;
    readonly photo: {
        readonly __typename?: 'Image';
        readonly id: number;
        readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
    } | null;
};

export type ClassDetailsTileStudentFragment = {
    readonly __typename?: 'Student';
    readonly id: number;
    readonly shortName: string;
    readonly photo: {
        readonly __typename?: 'Image';
        readonly id: number;
        readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
    } | null;
};

export type ClassDetailsTileClassRootFragment = {
    readonly __typename?: 'Klass';
    readonly uid: string;
    readonly startDate: string;
    readonly endDate: string;
    readonly status: ClassStatus;
    readonly type: ClassType;
    readonly isExisting: boolean;
    readonly availableSeats: number;
    readonly isForNewClassroom: boolean;
};

export type ClassDetailsTileClassFragment = {
    readonly __typename?: 'Klass';
    readonly uid: string;
    readonly startDate: string;
    readonly endDate: string;
    readonly status: ClassStatus;
    readonly type: ClassType;
    readonly isExisting: boolean;
    readonly availableSeats: number;
    readonly isForNewClassroom: boolean;
    readonly appointment: {
        readonly __typename?: 'Appointment';
        readonly id: string;
        readonly createdAt: string;
        readonly label: string | null;
        readonly status: AppointmentStatus;
        readonly isRatedByStudent: boolean;
        readonly hasAttemptToJoin: boolean;
    } | null;
    readonly lesson: { readonly __typename?: 'Lesson'; readonly id: number } | null;
    readonly module: { readonly __typename?: 'Module'; readonly id: number };
    readonly students: ReadonlyArray<{
        readonly __typename?: 'Student';
        readonly id: number;
        readonly shortName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly id: number;
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
        } | null;
    }>;
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly uid: string;
        readonly firstName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly id: number;
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
        } | null;
    } | null;
};

export type CancelClassMutationVariables = Exact<{
    classId: Scalars['String']['input'];
    isImmediate?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CancelClassMutation = {
    readonly __typename?: 'Mutation';
    readonly cancelClass: {
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly status: ClassStatus;
        readonly type: ClassType;
        readonly isExisting: boolean;
        readonly availableSeats: number;
        readonly isForNewClassroom: boolean;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly studentStatus: StudentStatus;
            readonly id: number;
        } | null;
        readonly appointment: {
            readonly __typename?: 'Appointment';
            readonly id: string;
            readonly createdAt: string;
            readonly label: string | null;
            readonly status: AppointmentStatus;
            readonly isRatedByStudent: boolean;
            readonly hasAttemptToJoin: boolean;
        } | null;
        readonly module: { readonly __typename?: 'Module'; readonly id: number };
        readonly students: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly id: number;
            readonly shortName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly id: number;
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
            } | null;
        }>;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly uid: string;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly id: number;
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        } | null;
    };
};

export type RecommendedOrientationClassesQueryVariables = Exact<{
    bookingFilter: ProvideBookableClassSlotsInput;
}>;

export type RecommendedOrientationClassesQuery = {
    readonly __typename?: 'Query';
    readonly recommendedOrientationClasses: ReadonlyArray<{
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly type: ClassType;
        readonly isExisting: boolean;
        readonly isForNewClassroom: boolean;
        readonly module: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
        };
        readonly learningUnit: {
            readonly __typename?: 'LearningUnit';
            readonly id: number;
            readonly chapter: number;
        } | null;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        } | null;
        readonly students: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly shortName: string;
            readonly id: number;
            readonly firstLetterOfFirstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        }>;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly label: string;
            readonly title: string | null;
            readonly type: LessonType | null;
            readonly studentStatus: StudentStatus;
            readonly availableWithoutPayment: boolean;
        } | null;
        readonly appointment: {
            readonly __typename?: 'Appointment';
            readonly id: string;
            readonly status: AppointmentStatus;
        } | null;
    }>;
};

export type ShouldDisplayClassroomPromoBannerStudentQueryVariables = Exact<{
    [key: string]: never;
}>;

export type ShouldDisplayClassroomPromoBannerStudentQuery = {
    readonly __typename?: 'Query';
    readonly shouldDisplayClassroomPromoBanner: boolean;
};

export type BaseActivityChallengeParticipantFragment = {
    readonly __typename?: 'ActivityChallengeParticipant';
    readonly id: string;
    readonly classesAttendedCount: number;
    readonly trainingSessionsCount: number;
    readonly learnedWordsCount: number;
    readonly completedActivities: number;
    readonly status: ActivityChallengeParticipantStatusEnum;
};

export type BaseActivityChallengeFragment = {
    readonly __typename?: 'ActivityChallenge';
    readonly name: string;
    readonly publicName: string;
    readonly description: string;
    readonly overviewFirstParagraph: string;
    readonly overviewSecondParagraph: string;
    readonly desktopBackgroundTheme: BackgroundThemeEnum;
    readonly mobileBackgroundTheme: BackgroundThemeEnum;
    readonly startDate: string;
    readonly endDate: string;
    readonly participants: number;
    readonly desktopBackgroundImage: {
        readonly __typename?: 'Media';
        readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
    } | null;
    readonly mobileBackgroundImage: {
        readonly __typename?: 'Media';
        readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
    } | null;
    readonly challengeIcon: {
        readonly __typename?: 'Media';
        readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
    } | null;
    readonly prizes: ReadonlyArray<{
        readonly __typename?: 'ActivityChallengePrize';
        readonly value: number;
        readonly noun: string;
        readonly nounTranslation: string;
        readonly explanation: string;
        readonly audioUrl: string | null;
        readonly prizeIcon: {
            readonly __typename?: 'Media';
            readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
        } | null;
    }>;
    readonly currentParticipant: {
        readonly __typename?: 'ActivityChallengeParticipant';
        readonly id: string;
        readonly classesAttendedCount: number;
        readonly trainingSessionsCount: number;
        readonly learnedWordsCount: number;
        readonly completedActivities: number;
        readonly status: ActivityChallengeParticipantStatusEnum;
    } | null;
};

export type ChallengeParticipantsFragment = {
    readonly __typename?: 'ActivityChallenge';
    readonly participants: number;
};

export type UpdateChallengeAfterJoinFragment = {
    readonly __typename?: 'ActivityChallenge';
    readonly participants: number;
    readonly currentParticipant: {
        readonly __typename?: 'ActivityChallengeParticipant';
        readonly id: string;
        readonly classesAttendedCount: number;
        readonly trainingSessionsCount: number;
        readonly learnedWordsCount: number;
        readonly completedActivities: number;
        readonly status: ActivityChallengeParticipantStatusEnum;
    } | null;
};

export type JoinActivityChallengeMutationVariables = Exact<{
    challengeName: Scalars['String']['input'];
}>;

export type JoinActivityChallengeMutation = {
    readonly __typename?: 'Mutation';
    readonly joinChallenge: {
        readonly __typename?: 'ActivityChallengeParticipant';
        readonly id: string;
        readonly classesAttendedCount: number;
        readonly trainingSessionsCount: number;
        readonly learnedWordsCount: number;
        readonly completedActivities: number;
        readonly status: ActivityChallengeParticipantStatusEnum;
    };
};

export type ActivityChallengePageQueryVariables = Exact<{
    challengeName: Scalars['String']['input'];
}>;

export type ActivityChallengePageQuery = {
    readonly __typename?: 'Query';
    readonly activityChallenge: {
        readonly __typename?: 'ActivityChallenge';
        readonly name: string;
        readonly publicName: string;
        readonly description: string;
        readonly overviewFirstParagraph: string;
        readonly overviewSecondParagraph: string;
        readonly desktopBackgroundTheme: BackgroundThemeEnum;
        readonly mobileBackgroundTheme: BackgroundThemeEnum;
        readonly startDate: string;
        readonly endDate: string;
        readonly participants: number;
        readonly desktopBackgroundImage: {
            readonly __typename?: 'Media';
            readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
        } | null;
        readonly mobileBackgroundImage: {
            readonly __typename?: 'Media';
            readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
        } | null;
        readonly challengeIcon: {
            readonly __typename?: 'Media';
            readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
        } | null;
        readonly prizes: ReadonlyArray<{
            readonly __typename?: 'ActivityChallengePrize';
            readonly value: number;
            readonly noun: string;
            readonly nounTranslation: string;
            readonly explanation: string;
            readonly audioUrl: string | null;
            readonly prizeIcon: {
                readonly __typename?: 'Media';
                readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
            } | null;
        }>;
        readonly currentParticipant: {
            readonly __typename?: 'ActivityChallengeParticipant';
            readonly id: string;
            readonly classesAttendedCount: number;
            readonly trainingSessionsCount: number;
            readonly learnedWordsCount: number;
            readonly completedActivities: number;
            readonly status: ActivityChallengeParticipantStatusEnum;
        } | null;
    };
};

export type BasicActivityChallengeDataQueryVariables = Exact<{
    challengeName: Scalars['String']['input'];
}>;

export type BasicActivityChallengeDataQuery = {
    readonly __typename?: 'Query';
    readonly activityChallenge: {
        readonly __typename?: 'ActivityChallenge';
        readonly name: string;
        readonly publicName: string;
        readonly description: string;
        readonly overviewFirstParagraph: string;
        readonly overviewSecondParagraph: string;
        readonly desktopBackgroundTheme: BackgroundThemeEnum;
        readonly mobileBackgroundTheme: BackgroundThemeEnum;
        readonly startDate: string;
        readonly endDate: string;
        readonly participants: number;
        readonly desktopBackgroundImage: {
            readonly __typename?: 'Media';
            readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
        } | null;
        readonly mobileBackgroundImage: {
            readonly __typename?: 'Media';
            readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
        } | null;
        readonly challengeIcon: {
            readonly __typename?: 'Media';
            readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
        } | null;
        readonly prizes: ReadonlyArray<{
            readonly __typename?: 'ActivityChallengePrize';
            readonly value: number;
            readonly noun: string;
            readonly nounTranslation: string;
            readonly explanation: string;
            readonly audioUrl: string | null;
            readonly prizeIcon: {
                readonly __typename?: 'Media';
                readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
            } | null;
        }>;
        readonly currentParticipant: {
            readonly __typename?: 'ActivityChallengeParticipant';
            readonly id: string;
            readonly classesAttendedCount: number;
            readonly trainingSessionsCount: number;
            readonly learnedWordsCount: number;
            readonly completedActivities: number;
            readonly status: ActivityChallengeParticipantStatusEnum;
        } | null;
    };
};

export type BasicActivityChallengeListDataQueryVariables = Exact<{
    canJoinOnly: Scalars['Boolean']['input'];
}>;

export type BasicActivityChallengeListDataQuery = {
    readonly __typename?: 'Query';
    readonly activityChallenges: ReadonlyArray<{
        readonly __typename?: 'ActivityChallenge';
        readonly name: string;
        readonly publicName: string;
        readonly description: string;
        readonly overviewFirstParagraph: string;
        readonly overviewSecondParagraph: string;
        readonly desktopBackgroundTheme: BackgroundThemeEnum;
        readonly mobileBackgroundTheme: BackgroundThemeEnum;
        readonly startDate: string;
        readonly endDate: string;
        readonly participants: number;
        readonly desktopBackgroundImage: {
            readonly __typename?: 'Media';
            readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
        } | null;
        readonly mobileBackgroundImage: {
            readonly __typename?: 'Media';
            readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
        } | null;
        readonly challengeIcon: {
            readonly __typename?: 'Media';
            readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
        } | null;
        readonly prizes: ReadonlyArray<{
            readonly __typename?: 'ActivityChallengePrize';
            readonly value: number;
            readonly noun: string;
            readonly nounTranslation: string;
            readonly explanation: string;
            readonly audioUrl: string | null;
            readonly prizeIcon: {
                readonly __typename?: 'Media';
                readonly urls: { readonly __typename?: 'MediaUrl'; readonly original: string };
            } | null;
        }>;
        readonly currentParticipant: {
            readonly __typename?: 'ActivityChallengeParticipant';
            readonly id: string;
            readonly classesAttendedCount: number;
            readonly trainingSessionsCount: number;
            readonly learnedWordsCount: number;
            readonly completedActivities: number;
            readonly status: ActivityChallengeParticipantStatusEnum;
        } | null;
    }>;
};

export type SubmitStudentClassReviewMutationVariables = Exact<{
    classId: Scalars['String']['input'];
    teacherRating: Scalars['Float']['input'];
    teacherFeedbackAnswers: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input'];
    teacherCustomFeedback: Scalars['String']['input'];
    techQualityRating?: InputMaybe<Scalars['Float']['input']>;
    techQualityFeedbackAnswers?: InputMaybe<
        ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']
    >;
    techQualityCustomFeedback?: InputMaybe<Scalars['String']['input']>;
    learningMaterialRating: Scalars['Float']['input'];
    learningMaterialFeedbackAnswers:
        | ReadonlyArray<Scalars['String']['input']>
        | Scalars['String']['input'];
    learningMaterialCustomFeedback: Scalars['String']['input'];
}>;

export type SubmitStudentClassReviewMutation = {
    readonly __typename?: 'Mutation';
    readonly submitStudentClassReview: {
        readonly __typename?: 'ClassFeedback';
        readonly id: number;
    };
};

export type HeaderStudentModuleFragment = {
    readonly __typename?: 'StudentModule';
    readonly id: number;
    readonly code: string;
    readonly cefrLevelName: string | null;
    readonly name: string;
    readonly longName: string;
    readonly title: string | null;
    readonly longDescription: string;
    readonly chaptersCount: number;
    readonly completedChaptersCount: number;
    readonly totalClasses: number;
    readonly completedClasses: number;
    readonly totalLingobites: number;
    readonly completedLingobites: number;
};

export type ModulePageHeaderQueryVariables = Exact<{
    sectionId: Scalars['Int']['input'];
    moduleId: Scalars['Int']['input'];
    curriculumId: Scalars['Int']['input'];
}>;

export type ModulePageHeaderQuery = {
    readonly __typename?: 'Query';
    readonly sectionCurricula: ReadonlyArray<{
        readonly __typename?: 'SectionCurriculum';
        readonly slug: string;
        readonly section: {
            readonly __typename?: 'Section';
            readonly id: number;
            readonly name: SectionName;
        };
        readonly curriculum: {
            readonly __typename?: 'Curriculum';
            readonly id: number;
            readonly name: string;
            readonly default: boolean;
        };
    }>;
    readonly studentModules: ReadonlyArray<{
        readonly __typename?: 'StudentModule';
        readonly id: number;
        readonly code: string;
        readonly cefrLevelName: string | null;
        readonly name: string;
        readonly longName: string;
        readonly title: string | null;
        readonly longDescription: string;
        readonly chaptersCount: number;
        readonly completedChaptersCount: number;
        readonly totalClasses: number;
        readonly completedClasses: number;
        readonly totalLingobites: number;
        readonly completedLingobites: number;
    }>;
    readonly activeModule: {
        readonly __typename?: 'StudentModule';
        readonly id: number;
        readonly code: string;
        readonly cefrLevelName: string | null;
        readonly name: string;
        readonly longName: string;
        readonly title: string | null;
        readonly longDescription: string;
        readonly chaptersCount: number;
        readonly completedChaptersCount: number;
        readonly totalClasses: number;
        readonly completedClasses: number;
        readonly totalLingobites: number;
        readonly completedLingobites: number;
    } | null;
    readonly canChangeLearningLanguage: {
        readonly __typename?: 'CanChangeLanguage';
        readonly isShown: boolean;
        readonly isEnabled: boolean;
    };
};

export type SelectorItemModuleFragment = {
    readonly __typename?: 'StudentModule';
    readonly id: number;
    readonly name: string;
    readonly title: string | null;
    readonly cefrLevelName: string | null;
    readonly code: string;
    readonly longDescription: string;
    readonly chaptersCount: number;
    readonly completedChaptersCount: number;
    readonly completedClasses: number;
};

export type ModuleSelectorOverlayQueryVariables = Exact<{
    sectionId: Scalars['Int']['input'];
    curriculumId: Scalars['Int']['input'];
}>;

export type ModuleSelectorOverlayQuery = {
    readonly __typename?: 'Query';
    readonly studentModules: ReadonlyArray<{
        readonly __typename?: 'StudentModule';
        readonly id: number;
        readonly code: string;
        readonly cefrLevelName: string | null;
        readonly name: string;
        readonly longName: string;
        readonly title: string | null;
        readonly longDescription: string;
        readonly chaptersCount: number;
        readonly completedChaptersCount: number;
        readonly totalClasses: number;
        readonly completedClasses: number;
        readonly totalLingobites: number;
        readonly completedLingobites: number;
    }>;
    readonly sectionCurricula: ReadonlyArray<{
        readonly __typename?: 'SectionCurriculum';
        readonly slug: string;
        readonly section: {
            readonly __typename?: 'Section';
            readonly id: number;
            readonly name: SectionName;
        };
        readonly curriculum: {
            readonly __typename?: 'Curriculum';
            readonly id: number;
            readonly name: string;
            readonly default: boolean;
        };
    }>;
};

export type OrientationLessonDataQueryVariables = Exact<{ [key: string]: never }>;

export type OrientationLessonDataQuery = {
    readonly __typename?: 'Query';
    readonly orientationLesson: {
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly studentStatus: StudentStatus;
        readonly type: LessonType | null;
        readonly label: string;
        readonly title: string | null;
        readonly learningOutcomes: ReadonlyArray<string> | null;
        readonly presentationUrl: string | null;
        readonly units: ReadonlyArray<{
            readonly __typename?: 'LearningUnit';
            readonly chapter: number;
        } | null>;
        readonly module: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
            readonly code: string;
            readonly longName: string;
            readonly title: string;
            readonly completedClasses: number;
        };
    } | null;
};

export type StudentCreditsInfoQueryVariables = Exact<{ [key: string]: never }>;

export type StudentCreditsInfoQuery = {
    readonly __typename?: 'Query';
    readonly studentCreditsInfo: {
        readonly __typename?: 'StudentCreditsInfo';
        readonly groupCredits: number;
        readonly privateCredits: number;
    };
};

export type BannerBookingWidgetQueryVariables = Exact<{
    bookingFilter: ProvideBookableClassSlotsInput;
}>;

export type BannerBookingWidgetQuery = {
    readonly __typename?: 'Query';
    readonly classes: ReadonlyArray<{
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly isExisting: boolean;
        readonly startDate: string;
        readonly endDate: string;
        readonly type: ClassType;
        readonly isForNewClassroom: boolean;
    }>;
};

export type LastPublishedLearningTeamCourseQueryVariables = Exact<{ [key: string]: never }>;

export type LastPublishedLearningTeamCourseQuery = {
    readonly __typename?: 'Query';
    readonly lastPublishedLearningTeamCourse: {
        readonly __typename?: 'LearningTeamCourse';
        readonly id: string;
    } | null;
};

export type TodayPageLingobitesQueryVariables = Exact<{
    limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type TodayPageLingobitesQuery = {
    readonly __typename?: 'Query';
    readonly recommendedLessonsForPracticeSequenceQuiz: ReadonlyArray<{
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly title: string | null;
        readonly studentStatus: StudentStatus;
        readonly practiceSequenceScore: number | null;
        readonly studentLearningUnit: {
            readonly __typename?: 'LearningUnit';
            readonly name: string;
        } | null;
    }>;
};

export type PracticeRecommenderQueryVariables = Exact<{ [key: string]: never }>;

export type PracticeRecommenderQuery = {
    readonly __typename?: 'Query';
    readonly recommendedActivities: ReadonlyArray<{
        readonly __typename?: 'Activity';
        readonly type: ActivityTypeEnum;
        readonly completed: boolean;
        readonly quizProgress: {
            readonly __typename?: 'QuizProgress';
            readonly lastReviewed: string | null;
            readonly periodProgress: {
                readonly __typename?: 'LearningProgress';
                readonly reviewed: number;
            } | null;
        } | null;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly title: string | null;
            readonly practiceSequenceScore: number | null;
            readonly vocabularyItems: ReadonlyArray<{
                readonly __typename?: 'VocabularyItem';
                readonly id: string;
                readonly isCompleted: boolean;
            }>;
        } | null;
    }>;
};

export type QuizProgressTodayPageQueryVariables = Exact<{
    from?: InputMaybe<Scalars['String']['input']>;
    to?: InputMaybe<Scalars['String']['input']>;
}>;

export type QuizProgressTodayPageQuery = {
    readonly __typename?: 'Query';
    readonly quizEngineProgress: {
        readonly __typename?: 'QuizProgress';
        readonly uncompleted: number;
        readonly lastReviewed: string | null;
        readonly periodProgress: {
            readonly __typename?: 'LearningProgress';
            readonly reviewed: number;
        } | null;
    };
};

export type ReviewClassCardAppointmentFragment = {
    readonly __typename?: 'Appointment';
    readonly id: string;
    readonly label: string | null;
    readonly klass: {
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly type: ClassType;
        readonly students: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly id: number;
            readonly firstLetterOfFirstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        }>;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly title: string | null;
            readonly label: string;
        } | null;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        } | null;
    };
};

export type StudentLastTwoWeeksClassesPendingReviewQueryVariables = Exact<{ [key: string]: never }>;

export type StudentLastTwoWeeksClassesPendingReviewQuery = {
    readonly __typename?: 'Query';
    readonly studentLastTwoWeeksClassesPendingReview: ReadonlyArray<{
        readonly __typename?: 'Appointment';
        readonly id: string;
        readonly label: string | null;
        readonly klass: {
            readonly __typename?: 'Klass';
            readonly uid: string;
            readonly startDate: string;
            readonly endDate: string;
            readonly type: ClassType;
            readonly students: ReadonlyArray<{
                readonly __typename?: 'Student';
                readonly id: number;
                readonly firstLetterOfFirstName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
                } | null;
            }>;
            readonly lesson: {
                readonly __typename?: 'Lesson';
                readonly title: string | null;
                readonly label: string;
            } | null;
            readonly teacher: {
                readonly __typename?: 'Teacher';
                readonly id: number;
                readonly firstName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
                } | null;
            } | null;
        };
    }>;
};

export type AppointmentsListIdsQueryVariables = Exact<{
    limit?: InputMaybe<Scalars['Int']['input']>;
    fromDate?: InputMaybe<Scalars['DateTime']['input']>;
    toDate?: InputMaybe<Scalars['DateTime']['input']>;
    appointmentStatus?: InputMaybe<Scalars['String']['input']>;
}>;

export type AppointmentsListIdsQuery = {
    readonly __typename?: 'Query';
    readonly appointmentsList: ReadonlyArray<{
        readonly __typename?: 'Appointment';
        readonly id: string;
    }>;
};

export type UpcomingAppointmentCardFragment = {
    readonly __typename?: 'Appointment';
    readonly id: string;
    readonly status: AppointmentStatus;
    readonly label: string | null;
    readonly klass: {
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly status: ClassStatus;
        readonly type: ClassType;
        readonly isForNewClassroom: boolean;
        readonly students: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly id: number;
            readonly firstLetterOfFirstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        }>;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly title: string | null;
            readonly description: string | null;
            readonly label: string;
        } | null;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        } | null;
    };
};

export type UpcomingAppointmentsQueryVariables = Exact<{
    listLimit: Scalars['Int']['input'];
    listOffset?: InputMaybe<Scalars['Int']['input']>;
    listFromDate: Scalars['DateTime']['input'];
    listToDate: Scalars['DateTime']['input'];
    countFromDate: Scalars['DateTime']['input'];
    classroomCountFromDate: Scalars['DateTime']['input'];
    listSort?: InputMaybe<Scalars['String']['input']>;
    appointmentStatus: Scalars['String']['input'];
}>;

export type UpcomingAppointmentsQuery = {
    readonly __typename?: 'Query';
    readonly upcomingAppointmentsCount: number;
    readonly upcomingClassroomAppointmentsCount: number;
    readonly appointmentsList: ReadonlyArray<{
        readonly __typename?: 'Appointment';
        readonly id: string;
        readonly status: AppointmentStatus;
        readonly label: string | null;
        readonly klass: {
            readonly __typename?: 'Klass';
            readonly uid: string;
            readonly startDate: string;
            readonly endDate: string;
            readonly status: ClassStatus;
            readonly type: ClassType;
            readonly isForNewClassroom: boolean;
            readonly students: ReadonlyArray<{
                readonly __typename?: 'Student';
                readonly id: number;
                readonly firstLetterOfFirstName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
                } | null;
            }>;
            readonly lesson: {
                readonly __typename?: 'Lesson';
                readonly title: string | null;
                readonly description: string | null;
                readonly label: string;
            } | null;
            readonly teacher: {
                readonly __typename?: 'Teacher';
                readonly id: number;
                readonly firstName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
                } | null;
            } | null;
        };
    }>;
    readonly student: {
        readonly __typename?: 'Student';
        readonly id: number;
        readonly hasBookedClass: boolean;
    };
};

export type StudentPastAppointmentsCountQueryVariables = Exact<{
    pastToDate?: InputMaybe<Scalars['DateTime']['input']>;
}>;

export type StudentPastAppointmentsCountQuery = {
    readonly __typename?: 'Query';
    readonly pastAppointmentsCount: number;
};

export type YourTeamWidgetDataFragment = {
    readonly __typename?: 'StudentLearningTeam';
    readonly status: LearningTeamStatus;
    readonly courseId: string;
    readonly courseStartDate: string;
    readonly courseEndDate: string;
    readonly timeSlots: ReadonlyArray<{
        readonly __typename?: 'LearningTeamTimeSlot';
        readonly dayOfWeek: DayOfWeek;
        readonly startHour: number;
    }>;
    readonly teachers: ReadonlyArray<{
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly firstName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
        } | null;
    }>;
    readonly participants: ReadonlyArray<{
        readonly __typename?: 'Student';
        readonly id: number;
        readonly shortName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
        } | null;
    }>;
    readonly timezone: { readonly __typename?: 'Timezone'; readonly tz: string };
};

export type YourTeamWidgetQueryVariables = Exact<{ [key: string]: never }>;

export type YourTeamWidgetQuery = {
    readonly __typename?: 'Query';
    readonly studentLearningTeamInfo: {
        readonly __typename?: 'StudentLearningTeam';
        readonly status: LearningTeamStatus;
        readonly courseId: string;
        readonly courseStartDate: string;
        readonly courseEndDate: string;
        readonly timeSlots: ReadonlyArray<{
            readonly __typename?: 'LearningTeamTimeSlot';
            readonly dayOfWeek: DayOfWeek;
            readonly startHour: number;
        }>;
        readonly teachers: ReadonlyArray<{
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
            } | null;
        }>;
        readonly participants: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly id: number;
            readonly shortName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
            } | null;
        }>;
        readonly timezone: { readonly __typename?: 'Timezone'; readonly tz: string };
    };
};

export type AppointmentsListQueryVariables = Exact<{
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    fromDate?: InputMaybe<Scalars['DateTime']['input']>;
    toDate?: InputMaybe<Scalars['DateTime']['input']>;
    sort?: InputMaybe<Scalars['String']['input']>;
}>;

export type AppointmentsListQuery = {
    readonly __typename?: 'Query';
    readonly appointmentsList: ReadonlyArray<{
        readonly __typename?: 'Appointment';
        readonly id: string;
        readonly status: AppointmentStatus;
        readonly label: string | null;
        readonly klass: {
            readonly __typename?: 'Klass';
            readonly uid: string;
            readonly startDate: string;
            readonly endDate: string;
            readonly type: ClassType;
            readonly students: ReadonlyArray<{
                readonly __typename?: 'Student';
                readonly id: number;
                readonly firstLetterOfFirstName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
                } | null;
            }>;
            readonly lesson: {
                readonly __typename?: 'Lesson';
                readonly title: string | null;
                readonly label: string;
            } | null;
            readonly teacher: {
                readonly __typename?: 'Teacher';
                readonly id: number;
                readonly firstName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
                } | null;
            } | null;
        };
    }>;
};

export type TotalCountsMyClassesQueryVariables = Exact<{
    upcomingFromDate?: InputMaybe<Scalars['DateTime']['input']>;
    pastToDate?: InputMaybe<Scalars['DateTime']['input']>;
}>;

export type TotalCountsMyClassesQuery = {
    readonly __typename?: 'Query';
    readonly upcomingAppointmentsCount: number;
    readonly pastAppointmentsCount: number;
};

export type AppointmentDatesQueryVariables = Exact<{
    fromDate: Scalars['DateTime']['input'];
    toDate: Scalars['DateTime']['input'];
    sort?: InputMaybe<Scalars['String']['input']>;
}>;

export type AppointmentDatesQuery = {
    readonly __typename?: 'Query';
    readonly appointmentDates: ReadonlyArray<string>;
};

export type HeaderAvatarQueryVariables = Exact<{ [key: string]: never }>;

export type HeaderAvatarQuery = {
    readonly __typename?: 'Query';
    readonly currentUser: {
        readonly __typename?: 'User';
        readonly id: number;
        readonly firstname: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly id: number;
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail_large: string };
        } | null;
    };
};

export type OnboardingClassFragment = {
    readonly __typename?: 'Klass';
    readonly uid: string;
    readonly status: ClassStatus;
    readonly type: ClassType;
    readonly isExisting: boolean;
    readonly startDate: string;
    readonly endDate: string;
    readonly isForNewClassroom: boolean;
    readonly duration: number;
    readonly module: { readonly __typename?: 'Module'; readonly id: number; readonly name: string };
    readonly lesson: {
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly title: string | null;
        readonly label: string;
        readonly learningOutcome: string | null;
    } | null;
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly firstName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
        } | null;
    } | null;
};

export type AnonymousOrientationGroupGetStartedClassesQueryVariables = Exact<{
    sectionName: Scalars['SectionName']['input'];
    moduleId: Scalars['Int']['input'];
    curriculumId: Scalars['Int']['input'];
    timezone: Scalars['String']['input'];
}>;

export type AnonymousOrientationGroupGetStartedClassesQuery = {
    readonly __typename?: 'Query';
    readonly anonymousOrientationGroupClasses: ReadonlyArray<{
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly startDate: string;
        readonly lesson: { readonly __typename?: 'Lesson'; readonly id: number } | null;
    }>;
};

export type AnonymousOrientationIndividualGetStartedClassesQueryVariables = Exact<{
    sectionName: Scalars['SectionName']['input'];
    moduleId: Scalars['Int']['input'];
    curriculumId: Scalars['Int']['input'];
    timezone: Scalars['String']['input'];
}>;

export type AnonymousOrientationIndividualGetStartedClassesQuery = {
    readonly __typename?: 'Query';
    readonly anonymousOrientationIndividualClasses: ReadonlyArray<{
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly startDate: string;
        readonly lesson: { readonly __typename?: 'Lesson'; readonly id: number } | null;
    }>;
};

export type GetStartedModulesQueryVariables = Exact<{
    sectionName: Scalars['SectionName']['input'];
    curriculumId: Scalars['Int']['input'];
}>;

export type GetStartedModulesQuery = {
    readonly __typename?: 'Query';
    readonly startingModules: ReadonlyArray<{
        readonly __typename?: 'StartingModule';
        readonly id: string;
        readonly code: string;
        readonly title: string;
        readonly description: string;
        readonly modules: ReadonlyArray<{
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
            readonly code: string;
        }> | null;
    }>;
};

export type MarkOnboardingSurveyAsAnsweredMutationVariables = Exact<{ [key: string]: never }>;

export type MarkOnboardingSurveyAsAnsweredMutation = {
    readonly __typename?: 'Mutation';
    readonly submitOnboardingSurvey: boolean;
};

export type PostCheckoutClassFragment = {
    readonly __typename?: 'Klass';
    readonly uid: string;
    readonly status: ClassStatus;
    readonly type: ClassType;
    readonly isExisting: boolean;
    readonly startDate: string;
    readonly endDate: string;
    readonly isForNewClassroom: boolean;
    readonly sourcePresentation: string | null;
    readonly module: { readonly __typename?: 'Module'; readonly id: number; readonly name: string };
    readonly lesson: {
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly title: string | null;
        readonly label: string;
        readonly learningOutcomes: ReadonlyArray<string> | null;
        readonly type: LessonType | null;
        readonly section: { readonly __typename?: 'Section'; readonly name: SectionName };
        readonly module: { readonly __typename?: 'Module'; readonly name: string };
        readonly units: ReadonlyArray<{
            readonly __typename?: 'LearningUnit';
            readonly chapter: number;
        } | null>;
    } | null;
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly lastName: string | null;
        readonly firstName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
        } | null;
    } | null;
    readonly students: ReadonlyArray<{
        readonly __typename?: 'Student';
        readonly id: number;
        readonly shortName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly id: number;
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
        } | null;
    }>;
};

export type PostCheckoutTeacherAvatarFragment = {
    readonly __typename?: 'Teacher';
    readonly lastName: string | null;
    readonly firstName: string;
    readonly photo: {
        readonly __typename?: 'Image';
        readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
    } | null;
};

export type PostCheckoutStudentFragment = {
    readonly __typename?: 'Student';
    readonly id: number;
    readonly shortName: string;
    readonly photo: {
        readonly __typename?: 'Image';
        readonly id: number;
        readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
    } | null;
};

export type OnboardingOrientationLessonQueryVariables = Exact<{ [key: string]: never }>;

export type OnboardingOrientationLessonQuery = {
    readonly __typename?: 'Query';
    readonly orientationLesson: {
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly learningOutcomes: ReadonlyArray<string> | null;
        readonly title: string | null;
    } | null;
};

export type PostCheckoutSearchResultsQueryVariables = Exact<{
    bookingFilter: ProvideBookableClassSlotsInput;
}>;

export type PostCheckoutSearchResultsQuery = {
    readonly __typename?: 'Query';
    readonly classes: ReadonlyArray<{
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly status: ClassStatus;
        readonly type: ClassType;
        readonly isExisting: boolean;
        readonly startDate: string;
        readonly endDate: string;
        readonly isForNewClassroom: boolean;
        readonly sourcePresentation: string | null;
        readonly module: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
        };
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly title: string | null;
            readonly label: string;
            readonly learningOutcomes: ReadonlyArray<string> | null;
            readonly type: LessonType | null;
            readonly section: { readonly __typename?: 'Section'; readonly name: SectionName };
            readonly module: { readonly __typename?: 'Module'; readonly name: string };
            readonly units: ReadonlyArray<{
                readonly __typename?: 'LearningUnit';
                readonly chapter: number;
            } | null>;
        } | null;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly lastName: string | null;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        } | null;
        readonly students: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly id: number;
            readonly shortName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly id: number;
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
            } | null;
        }>;
    }>;
};

export type PostCheckoutOrientationIndividualClassesQueryVariables = Exact<{
    sectionName: Scalars['SectionName']['input'];
    moduleId: Scalars['Int']['input'];
    curriculumId: Scalars['Int']['input'];
    timezone: Scalars['String']['input'];
}>;

export type PostCheckoutOrientationIndividualClassesQuery = {
    readonly __typename?: 'Query';
    readonly classes: ReadonlyArray<{
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly status: ClassStatus;
        readonly type: ClassType;
        readonly isExisting: boolean;
        readonly startDate: string;
        readonly endDate: string;
        readonly isForNewClassroom: boolean;
        readonly sourcePresentation: string | null;
        readonly module: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
        };
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly title: string | null;
            readonly label: string;
            readonly learningOutcomes: ReadonlyArray<string> | null;
            readonly type: LessonType | null;
            readonly section: { readonly __typename?: 'Section'; readonly name: SectionName };
            readonly module: { readonly __typename?: 'Module'; readonly name: string };
            readonly units: ReadonlyArray<{
                readonly __typename?: 'LearningUnit';
                readonly chapter: number;
            } | null>;
        } | null;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly lastName: string | null;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        } | null;
        readonly students: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly id: number;
            readonly shortName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly id: number;
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
            } | null;
        }>;
    }>;
};

export type BookedClassesQueryVariables = Exact<{
    listLimit: Scalars['Int']['input'];
    listFromDate: Scalars['DateTime']['input'];
    listToDate: Scalars['DateTime']['input'];
    appointmentStatus: Scalars['String']['input'];
}>;

export type BookedClassesQuery = {
    readonly __typename?: 'Query';
    readonly student: {
        readonly __typename?: 'Student';
        readonly id: number;
        readonly hasBookedClass: boolean;
    };
    readonly appointmentsList: ReadonlyArray<{
        readonly __typename?: 'Appointment';
        readonly id: string;
        readonly label: string | null;
        readonly module: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
        };
        readonly class: {
            readonly __typename?: 'Klass';
            readonly uid: string;
            readonly status: ClassStatus;
            readonly type: ClassType;
            readonly isExisting: boolean;
            readonly startDate: string;
            readonly endDate: string;
            readonly isForNewClassroom: boolean;
            readonly sourcePresentation: string | null;
            readonly module: {
                readonly __typename?: 'Module';
                readonly id: number;
                readonly name: string;
            };
            readonly lesson: {
                readonly __typename?: 'Lesson';
                readonly id: number;
                readonly title: string | null;
                readonly label: string;
                readonly learningOutcomes: ReadonlyArray<string> | null;
                readonly type: LessonType | null;
                readonly section: { readonly __typename?: 'Section'; readonly name: SectionName };
                readonly module: { readonly __typename?: 'Module'; readonly name: string };
                readonly units: ReadonlyArray<{
                    readonly __typename?: 'LearningUnit';
                    readonly chapter: number;
                } | null>;
            } | null;
            readonly teacher: {
                readonly __typename?: 'Teacher';
                readonly id: number;
                readonly lastName: string | null;
                readonly firstName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
                } | null;
            } | null;
            readonly students: ReadonlyArray<{
                readonly __typename?: 'Student';
                readonly id: number;
                readonly shortName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly id: number;
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
                } | null;
            }>;
        };
    }>;
};

export type ReportPsFlashcardMutationVariables = Exact<{
    input: FlashcardFeedbackInput;
}>;

export type ReportPsFlashcardMutation = {
    readonly __typename?: 'Mutation';
    readonly submitPracticeSequenceFlashcardFeedback: boolean;
};

export type ReportPsSingleChoiceMutationVariables = Exact<{
    input: SingleChoiceFeedbackInput;
}>;

export type ReportPsSingleChoiceMutation = {
    readonly __typename?: 'Mutation';
    readonly submitPracticeSequenceSingleChoiceFeedback: boolean;
};

export type ReportPsMatchingMutationVariables = Exact<{
    input: LessonBasedExerciseFeedbackInput;
}>;

export type ReportPsMatchingMutation = {
    readonly __typename?: 'Mutation';
    readonly submitMatchingExerciseFeedback: boolean;
};

export type ReportPsDialogueMutationVariables = Exact<{
    input: LessonBasedExerciseFeedbackInput;
}>;

export type ReportPsDialogueMutation = {
    readonly __typename?: 'Mutation';
    readonly submitBinaryDialogFeedback: boolean;
};

export type PracticeSequenceQuizSessionFragment = {
    readonly __typename?: 'Session';
    readonly id: string;
    readonly completedAt: string | null;
    readonly flashcards: ReadonlyArray<{
        readonly __typename?: 'Flashcard';
        readonly knownStatus: KnownStatus;
        readonly item: {
            readonly __typename?: 'VocabularyItem';
            readonly id: string;
            readonly item: string | null;
            readonly title: string;
            readonly gender: GenderType | null;
            readonly wordClass: VocabularyItemWordClass;
            readonly plural: string | null;
            readonly sampleSentenceOne: string;
            readonly section: { readonly __typename?: 'Section'; readonly name: SectionName };
            readonly audioUrls: {
                readonly __typename?: 'VocabularyItemAudio';
                readonly target: string;
                readonly translation: string;
            } | null;
        };
    }>;
    readonly exercises: ReadonlyArray<{
        readonly __typename?: 'QuizExercise';
        readonly id: string;
        readonly type: SessionExerciseType;
        readonly questions: ReadonlyArray<{
            readonly __typename?: 'Question';
            readonly id: string;
            readonly title: string;
            readonly audioUrl: string | null;
            readonly choices: ReadonlyArray<{
                readonly __typename?: 'Choice';
                readonly id: string;
                readonly text: string;
                readonly isCorrect: boolean;
                readonly audioUrl: string | null;
                readonly translation: {
                    readonly __typename?: 'QuestionTranslations';
                    readonly byWord: ReadonlyArray<{
                        readonly __typename?: 'QuestionByWordTranslations';
                        readonly id: string;
                        readonly source: string;
                        readonly translation: string | null;
                    }>;
                } | null;
            }>;
            readonly meta: {
                readonly __typename?: 'QuestionMeta';
                readonly gender: GenderType | null;
                readonly plural: string | null;
                readonly sampleSentence: string | null;
                readonly vocabularyItemId: string | null;
                readonly wordClass: string | null;
                readonly translation: string | null;
            } | null;
            readonly binaryDialogMeta: {
                readonly __typename?: 'QuestionBinaryDialogMeta';
                readonly gender: string;
                readonly speaker: string;
            } | null;
            readonly records: ReadonlyArray<{
                readonly __typename?: 'QuestionRecord';
                readonly isSkipped: boolean;
                readonly score: number;
            }>;
            readonly translation: {
                readonly __typename?: 'QuestionTranslations';
                readonly wholeSentenceToTarget: string;
            } | null;
            readonly titleTranslation: {
                readonly __typename?: 'QuestionTranslations';
                readonly byWord: ReadonlyArray<{
                    readonly __typename?: 'QuestionByWordTranslations';
                    readonly id: string;
                    readonly source: string;
                    readonly translation: string | null;
                }>;
            } | null;
        }>;
    }>;
};

export type RetryPracticeSequenceMutationVariables = Exact<{
    lessonId: Scalars['Int']['input'];
}>;

export type RetryPracticeSequenceMutation = {
    readonly __typename?: 'Mutation';
    readonly restartPracticeSequenceQuiz: { readonly __typename?: 'Session'; readonly id: string };
};

export type PracticeSequencePageQueryVariables = Exact<{
    lessonId: Scalars['Int']['input'];
}>;

export type PracticeSequencePageQuery = {
    readonly __typename?: 'Query';
    readonly lesson: {
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly title: string | null;
        readonly studentLearningUnit: {
            readonly __typename?: 'LearningUnit';
            readonly id: number;
        } | null;
    };
    readonly lastLessonAppointment: {
        readonly __typename?: 'Appointment';
        readonly class: {
            readonly __typename?: 'Klass';
            readonly uid: string;
            readonly startDate: string;
        };
    } | null;
    readonly sequence: {
        readonly __typename?: 'Session';
        readonly id: string;
        readonly completedAt: string | null;
        readonly flashcards: ReadonlyArray<{
            readonly __typename?: 'Flashcard';
            readonly knownStatus: KnownStatus;
            readonly item: {
                readonly __typename?: 'VocabularyItem';
                readonly id: string;
                readonly item: string | null;
                readonly title: string;
                readonly gender: GenderType | null;
                readonly wordClass: VocabularyItemWordClass;
                readonly plural: string | null;
                readonly sampleSentenceOne: string;
                readonly section: { readonly __typename?: 'Section'; readonly name: SectionName };
                readonly audioUrls: {
                    readonly __typename?: 'VocabularyItemAudio';
                    readonly target: string;
                    readonly translation: string;
                } | null;
            };
        }>;
        readonly exercises: ReadonlyArray<{
            readonly __typename?: 'QuizExercise';
            readonly id: string;
            readonly type: SessionExerciseType;
            readonly questions: ReadonlyArray<{
                readonly __typename?: 'Question';
                readonly id: string;
                readonly title: string;
                readonly audioUrl: string | null;
                readonly choices: ReadonlyArray<{
                    readonly __typename?: 'Choice';
                    readonly id: string;
                    readonly text: string;
                    readonly isCorrect: boolean;
                    readonly audioUrl: string | null;
                    readonly translation: {
                        readonly __typename?: 'QuestionTranslations';
                        readonly byWord: ReadonlyArray<{
                            readonly __typename?: 'QuestionByWordTranslations';
                            readonly id: string;
                            readonly source: string;
                            readonly translation: string | null;
                        }>;
                    } | null;
                }>;
                readonly meta: {
                    readonly __typename?: 'QuestionMeta';
                    readonly gender: GenderType | null;
                    readonly plural: string | null;
                    readonly sampleSentence: string | null;
                    readonly vocabularyItemId: string | null;
                    readonly wordClass: string | null;
                    readonly translation: string | null;
                } | null;
                readonly binaryDialogMeta: {
                    readonly __typename?: 'QuestionBinaryDialogMeta';
                    readonly gender: string;
                    readonly speaker: string;
                } | null;
                readonly records: ReadonlyArray<{
                    readonly __typename?: 'QuestionRecord';
                    readonly isSkipped: boolean;
                    readonly score: number;
                }>;
                readonly translation: {
                    readonly __typename?: 'QuestionTranslations';
                    readonly wholeSentenceToTarget: string;
                } | null;
                readonly titleTranslation: {
                    readonly __typename?: 'QuestionTranslations';
                    readonly byWord: ReadonlyArray<{
                        readonly __typename?: 'QuestionByWordTranslations';
                        readonly id: string;
                        readonly source: string;
                        readonly translation: string | null;
                    }>;
                } | null;
            }>;
        }>;
    };
};

export type PracticeSequenceNextItemQueryVariables = Exact<{ [key: string]: never }>;

export type PracticeSequenceNextItemQuery = {
    readonly __typename?: 'Query';
    readonly nextLessonsForPracticeSequenceQuiz: ReadonlyArray<{
        readonly __typename?: 'Lesson';
        readonly id: number;
    }>;
};

export type PsOutroQueryVariables = Exact<{
    sessionId: Scalars['String']['input'];
}>;

export type PsOutroQuery = {
    readonly __typename?: 'Query';
    readonly sessionScore: {
        readonly __typename?: 'SessionScore';
        readonly scorePct: number;
        readonly exercises: ReadonlyArray<{
            readonly __typename?: 'ExerciseScore';
            readonly type: SessionExerciseType;
            readonly scorePct: number;
            readonly scorePctAvg: number;
        }>;
    };
};

export type PsStage1ScoreQueryVariables = Exact<{
    sessionId: Scalars['String']['input'];
}>;

export type PsStage1ScoreQuery = {
    readonly __typename?: 'Query';
    readonly sessionScore: {
        readonly __typename?: 'SessionScore';
        readonly exercises: ReadonlyArray<{
            readonly __typename?: 'ExerciseScore';
            readonly type: SessionExerciseType;
            readonly scorePct: number;
        }>;
    };
};

export type SubmitPsFlashcardMutationVariables = Exact<{
    input: ReportVocabResultInput;
}>;

export type SubmitPsFlashcardMutation = {
    readonly __typename?: 'Mutation';
    readonly quizReportVocabResult: boolean;
};

export type SubmitPsQuestionMutationVariables = Exact<{
    input: ReportResultInput;
}>;

export type SubmitPsQuestionMutation = {
    readonly __typename?: 'Mutation';
    readonly quizEngineReportResult: {
        readonly __typename?: 'ReportResultPayload';
        readonly score: number;
    };
};

export type SubmitPsQuestionsMutationVariables = Exact<{
    input: ReportMultipleResultInput;
}>;

export type SubmitPsQuestionsMutation = {
    readonly __typename?: 'Mutation';
    readonly quizEngineReportResults: ReadonlyArray<{
        readonly __typename?: 'ReportResultPayload';
        readonly score: number;
    }>;
};

export type WhyAreYouLearningMutationVariables = Exact<{
    answers:
        | ReadonlyArray<InputMaybe<WhyAreYouLearningAnswerEnum>>
        | InputMaybe<WhyAreYouLearningAnswerEnum>;
    otherOption?: InputMaybe<Scalars['String']['input']>;
}>;

export type WhyAreYouLearningMutation = {
    readonly __typename?: 'Mutation';
    readonly whyAreYouLearning: boolean;
};

export type DeleteWhyAreYouLearningMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteWhyAreYouLearningMutation = {
    readonly __typename?: 'Mutation';
    readonly deleteWhyAreYouLearning: boolean;
};

export type LearningNeedsMutationVariables = Exact<{
    learningNeeds: Scalars['String']['input'];
}>;

export type LearningNeedsMutation = {
    readonly __typename?: 'Mutation';
    readonly learningNeeds: boolean;
};

export type DeleteLearningNeedsMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteLearningNeedsMutation = {
    readonly __typename?: 'Mutation';
    readonly deleteLearningNeeds: boolean;
};

export type AddLanguageYouSpeakMutationVariables = Exact<{
    language: UserLanguage;
    languageFluency: LanguageFluency;
}>;

export type AddLanguageYouSpeakMutation = {
    readonly __typename?: 'Mutation';
    readonly createSpokenLanguage: boolean;
};

export type UpdateLanguageYouSpeakMutationVariables = Exact<{
    newLanguage: UserLanguage;
    currentLanguage: UserLanguage;
    languageFluency: LanguageFluency;
}>;

export type UpdateLanguageYouSpeakMutation = {
    readonly __typename?: 'Mutation';
    readonly updateSpokenLanguage: boolean;
};

export type DeleteLanguageYouSpeakMutationVariables = Exact<{
    language: UserLanguage;
}>;

export type DeleteLanguageYouSpeakMutation = {
    readonly __typename?: 'Mutation';
    readonly deleteSpokenLanguage: boolean;
};

export type UpdateStudentMarketingPreferencesMutationVariables = Exact<{
    preferences: StudentMarketingPreferencesInput;
}>;

export type UpdateStudentMarketingPreferencesMutation = {
    readonly __typename?: 'Mutation';
    readonly updateStudentMarketingPreferences: boolean;
};

export type UpdateUserPhoneMutationVariables = Exact<{
    phoneNumber: Scalars['String']['input'];
}>;

export type UpdateUserPhoneMutation = {
    readonly __typename?: 'Mutation';
    readonly updateUserPhone: {
        readonly __typename?: 'User';
        readonly id: number;
        readonly phoneNumber: string | null;
    };
};

export type GetStudentMarketingPreferencesQueryVariables = Exact<{ [key: string]: never }>;

export type GetStudentMarketingPreferencesQuery = {
    readonly __typename?: 'Query';
    readonly student: {
        readonly __typename?: 'Student';
        readonly preferences: {
            readonly __typename?: 'StudentPreferences';
            readonly marketingPreferences: {
                readonly __typename?: 'StudentMarketingPreferences';
                readonly marketingCommunicationEmail: boolean;
                readonly marketingCommunicationPhone: boolean;
                readonly marketingCommunicationWhatsApp: boolean;
            };
        };
    };
};

export type StudentSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type StudentSettingsQuery = {
    readonly __typename?: 'Query';
    readonly shouldShowProfileSettingsPageKysBanner: boolean;
    readonly student: {
        readonly __typename?: 'Student';
        readonly id: number;
        readonly businessModelType: BusinessModelType;
        readonly studentProfile: {
            readonly __typename?: 'StudentProfile';
            readonly learningNeeds: string | null;
            readonly whyAreYouLearning: {
                readonly __typename?: 'WhyAreYouLearningSection';
                readonly questions: ReadonlyArray<string>;
                readonly answers: ReadonlyArray<string>;
                readonly otherOption: string | null;
            };
            readonly spokenLanguages: ReadonlyArray<{
                readonly __typename?: 'UserSpokenLanguage';
                readonly language: UserLanguage;
                readonly languageFluency: LanguageFluency;
            }>;
        };
        readonly section: {
            readonly __typename?: 'Section';
            readonly id: number;
            readonly name: SectionName;
        };
    };
};

export type YearlyActivityAllSummaryFragment = {
    readonly __typename?: 'StudentYearlyActivitySummary';
    readonly year: number;
    readonly classesAttendedCount: number | null;
    readonly attendedMinutes: number | null;
    readonly weeksStraightCount: number | null;
    readonly exercisesCompletedCount: number | null;
    readonly rank: string | null;
    readonly teachersCount: number | null;
    readonly studentsCount: number | null;
    readonly certificatesEarned: ReadonlyArray<{
        readonly __typename?: 'CertificateEarnedType';
        readonly name: string;
    }>;
    readonly modulesAttended: ReadonlyArray<{
        readonly __typename?: 'ModuleAttendedType';
        readonly name: string;
    }>;
    readonly topFeedbacksCount: ReadonlyArray<{
        readonly __typename?: 'FeedbackCountType';
        readonly name: string;
        readonly value: string;
    }>;
};

export type YearlyRecapAllSummaryQueryVariables = Exact<{
    year: Scalars['Int']['input'];
}>;

export type YearlyRecapAllSummaryQuery = {
    readonly __typename?: 'Query';
    readonly studentYearlySummary: {
        readonly __typename?: 'StudentYearlyActivitySummary';
        readonly year: number;
        readonly classesAttendedCount: number | null;
        readonly attendedMinutes: number | null;
        readonly weeksStraightCount: number | null;
        readonly exercisesCompletedCount: number | null;
        readonly rank: string | null;
        readonly teachersCount: number | null;
        readonly studentsCount: number | null;
        readonly certificatesEarned: ReadonlyArray<{
            readonly __typename?: 'CertificateEarnedType';
            readonly name: string;
        }>;
        readonly modulesAttended: ReadonlyArray<{
            readonly __typename?: 'ModuleAttendedType';
            readonly name: string;
        }>;
        readonly topFeedbacksCount: ReadonlyArray<{
            readonly __typename?: 'FeedbackCountType';
            readonly name: string;
            readonly value: string;
        }>;
    } | null;
};

export type TeacherAgreementsDataFragment = {
    readonly __typename?: 'Agreement';
    readonly isAccepted: boolean;
    readonly id: string;
    readonly body: string;
};

export type TeacherUpdateAgreementsMutationVariables = Exact<{
    agreementId: Scalars['String']['input'];
}>;

export type TeacherUpdateAgreementsMutation = {
    readonly __typename?: 'Mutation';
    readonly teacherAcceptAgreement: {
        readonly __typename?: 'Agreement';
        readonly isAccepted: boolean;
        readonly id: string;
        readonly body: string;
    };
};

export type TeacherGetAgreementsQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherGetAgreementsQuery = {
    readonly __typename?: 'Query';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly agreements: ReadonlyArray<{
            readonly __typename?: 'Agreement';
            readonly isAccepted: boolean;
            readonly id: string;
            readonly body: string;
        }>;
    } | null;
};

export type TeacherCalendarTeacherCalendarFragment = {
    readonly __typename?: 'TeacherCalendar';
    readonly startOfWeekDate: string;
    readonly totalAvailableClassesPerWeek: number | null;
    readonly totalBookedClassesThisWeek: number;
    readonly timeSlots: ReadonlyArray<{
        readonly __typename?: 'TeacherCalendarTimeSlot';
        readonly startDate: string;
        readonly events: ReadonlyArray<{
            readonly __typename?: 'TeacherCalendarEvent';
            readonly classType: ClassType;
            readonly classUniqId: string;
            readonly isCommittedByTeacher: boolean;
            readonly isB2G: boolean;
            readonly isBonus: boolean;
            readonly classDuration: number;
        }>;
    }>;
};

export type TeacherCalendarTeacherCalendarTimeSlotFragment = {
    readonly __typename?: 'TeacherCalendarTimeSlot';
    readonly startDate: string;
    readonly events: ReadonlyArray<{
        readonly __typename?: 'TeacherCalendarEvent';
        readonly classType: ClassType;
        readonly classUniqId: string;
        readonly isCommittedByTeacher: boolean;
        readonly isB2G: boolean;
        readonly isBonus: boolean;
        readonly classDuration: number;
    }>;
};

export type TeacherCalendarTeacherCalendarEventFragment = {
    readonly __typename?: 'TeacherCalendarEvent';
    readonly classType: ClassType;
    readonly classUniqId: string;
    readonly isCommittedByTeacher: boolean;
    readonly isB2G: boolean;
    readonly isBonus: boolean;
    readonly classDuration: number;
};

export type TeacherDetailsDialogTeacherCalendarEventDetailsListFragment = {
    readonly __typename?: 'TeacherCalendarEventDetailsList';
    readonly eventStartDate: string;
    readonly isPriceVisible: boolean;
    readonly events: ReadonlyArray<{
        readonly __typename?: 'TeacherCalendarEventDetails';
        readonly classUniqId: string;
        readonly classType: ClassType;
        readonly isB2G: boolean;
        readonly isBonus: boolean;
        readonly isLanguageClass: boolean;
        readonly classDuration: number;
        readonly module: string | null;
        readonly courseId: string | null;
        readonly previousClassLogsUrl: string | null;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly label: string;
            readonly title: string | null;
            readonly learningOutcomes: ReadonlyArray<string> | null;
            readonly description: string | null;
        } | null;
        readonly students: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly id: number;
            readonly shortName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        }>;
        readonly price: { readonly __typename?: 'Price'; readonly forHumans: string } | null;
    }>;
};

export type TeacherCalendarCommitDetailsDialogMutationVariables = Exact<{
    classId: Scalars['String']['input'];
}>;

export type TeacherCalendarCommitDetailsDialogMutation = {
    readonly __typename?: 'Mutation';
    readonly teacherCommitToClass: {
        readonly __typename?: 'TeacherCalendarTimeSlot';
        readonly startDate: string;
        readonly events: ReadonlyArray<{
            readonly __typename?: 'TeacherCalendarEvent';
            readonly classType: ClassType;
            readonly classUniqId: string;
            readonly isCommittedByTeacher: boolean;
            readonly isB2G: boolean;
            readonly isBonus: boolean;
            readonly classDuration: number;
        }>;
    };
};

export type AppealPenaltyMutationVariables = Exact<{
    explanationNote: Scalars['String']['input'];
}>;

export type AppealPenaltyMutation = {
    readonly __typename?: 'Mutation';
    readonly appealPenalty: boolean;
};

export type TeacherCalendarGetTeacherCalendarQueryVariables = Exact<{
    weekStartDate?: InputMaybe<Scalars['DateTime']['input']>;
}>;

export type TeacherCalendarGetTeacherCalendarQuery = {
    readonly __typename?: 'Query';
    readonly teacherCalendar: {
        readonly __typename?: 'TeacherCalendar';
        readonly startOfWeekDate: string;
        readonly totalAvailableClassesPerWeek: number | null;
        readonly totalBookedClassesThisWeek: number;
        readonly timeSlots: ReadonlyArray<{
            readonly __typename?: 'TeacherCalendarTimeSlot';
            readonly startDate: string;
            readonly events: ReadonlyArray<{
                readonly __typename?: 'TeacherCalendarEvent';
                readonly classType: ClassType;
                readonly classUniqId: string;
                readonly isCommittedByTeacher: boolean;
                readonly isB2G: boolean;
                readonly isBonus: boolean;
                readonly classDuration: number;
            }>;
        }>;
    };
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly penaltyUntil: string | null;
        readonly canAppealPenalty: boolean;
    } | null;
};

export type TeacherCalendarGetDetailsDialogQueryVariables = Exact<{
    eventStartDate: Scalars['DateTime']['input'];
}>;

export type TeacherCalendarGetDetailsDialogQuery = {
    readonly __typename?: 'Query';
    readonly teacherCalendarEventDetails: {
        readonly __typename?: 'TeacherCalendarEventDetailsList';
        readonly eventStartDate: string;
        readonly isPriceVisible: boolean;
        readonly events: ReadonlyArray<{
            readonly __typename?: 'TeacherCalendarEventDetails';
            readonly classUniqId: string;
            readonly classType: ClassType;
            readonly isB2G: boolean;
            readonly isBonus: boolean;
            readonly isLanguageClass: boolean;
            readonly classDuration: number;
            readonly module: string | null;
            readonly courseId: string | null;
            readonly previousClassLogsUrl: string | null;
            readonly lesson: {
                readonly __typename?: 'Lesson';
                readonly label: string;
                readonly title: string | null;
                readonly learningOutcomes: ReadonlyArray<string> | null;
                readonly description: string | null;
            } | null;
            readonly students: ReadonlyArray<{
                readonly __typename?: 'Student';
                readonly id: number;
                readonly shortName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
                } | null;
            }>;
            readonly price: { readonly __typename?: 'Price'; readonly forHumans: string } | null;
        }>;
    };
};

export type TeacherCalendarGetGoToNextClassButtonQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherCalendarGetGoToNextClassButtonQuery = {
    readonly __typename?: 'Query';
    readonly teacherNextClass: { readonly __typename?: 'Klass'; readonly uid: string } | null;
};

export type TeacherInvoiceFragment = {
    readonly __typename?: 'TeacherInvoice';
    readonly id: number;
    readonly pdfUrl: string | null;
    readonly date: string | null;
    readonly total: number;
    readonly currency: Currency;
    readonly pending: boolean;
    readonly items: ReadonlyArray<{
        readonly __typename?: 'TeacherInvoiceItem';
        readonly id: number;
        readonly date: string;
        readonly description: string;
        readonly bonusType: boolean;
        readonly class: {
            readonly __typename?: 'Klass';
            readonly uid: string;
            readonly labels: ReadonlyArray<KlassLabel>;
            readonly startDate: string;
            readonly endDate: string;
            readonly total: {
                readonly __typename?: 'Price';
                readonly amountInMajor: number;
                readonly currency: Currency;
            } | null;
            readonly lesson: {
                readonly __typename?: 'Lesson';
                readonly id: number;
                readonly title: string | null;
            } | null;
        } | null;
        readonly amount: {
            readonly __typename?: 'Price';
            readonly amountInMajor: number;
            readonly currency: Currency;
        };
    }>;
};

export type TeacherInvoiceItemFragment = {
    readonly __typename?: 'TeacherInvoiceItem';
    readonly id: number;
    readonly date: string;
    readonly description: string;
    readonly bonusType: boolean;
    readonly class: {
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly labels: ReadonlyArray<KlassLabel>;
        readonly startDate: string;
        readonly endDate: string;
        readonly total: {
            readonly __typename?: 'Price';
            readonly amountInMajor: number;
            readonly currency: Currency;
        } | null;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly title: string | null;
        } | null;
    } | null;
    readonly amount: {
        readonly __typename?: 'Price';
        readonly amountInMajor: number;
        readonly currency: Currency;
    };
};

export type TeacherInvoiceClassFragment = {
    readonly __typename?: 'Klass';
    readonly uid: string;
    readonly labels: ReadonlyArray<KlassLabel>;
    readonly startDate: string;
    readonly endDate: string;
    readonly total: {
        readonly __typename?: 'Price';
        readonly amountInMajor: number;
        readonly currency: Currency;
    } | null;
    readonly lesson: {
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly title: string | null;
    } | null;
};

export type TeacherSubmittedLogFragment = {
    readonly __typename?: 'ClassLog';
    readonly id: number;
    readonly class: {
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly labels: ReadonlyArray<KlassLabel>;
        readonly startDate: string;
        readonly endDate: string;
        readonly total: {
            readonly __typename?: 'Price';
            readonly amountInMajor: number;
            readonly currency: Currency;
        } | null;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly title: string | null;
        } | null;
    };
};

export type TeacherSubmittedMonthFragment = {
    readonly __typename?: 'ClassLogGroupDTO';
    readonly date: string;
    readonly classLogs: ReadonlyArray<{
        readonly __typename?: 'ClassLog';
        readonly id: number;
        readonly class: {
            readonly __typename?: 'Klass';
            readonly uid: string;
            readonly labels: ReadonlyArray<KlassLabel>;
            readonly startDate: string;
            readonly endDate: string;
            readonly total: {
                readonly __typename?: 'Price';
                readonly amountInMajor: number;
                readonly currency: Currency;
            } | null;
            readonly lesson: {
                readonly __typename?: 'Lesson';
                readonly id: number;
                readonly title: string | null;
            } | null;
        };
    }>;
};

export type TeacherOpenLogsQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherOpenLogsQuery = {
    readonly __typename?: 'Query';
    readonly teacherOpenLogs: ReadonlyArray<{
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly labels: ReadonlyArray<KlassLabel>;
        readonly startDate: string;
        readonly endDate: string;
        readonly total: {
            readonly __typename?: 'Price';
            readonly amountInMajor: number;
            readonly currency: Currency;
        } | null;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly title: string | null;
        } | null;
    }>;
};

export type TeacherSubmittedLogsQueryVariables = Exact<{
    limit: Scalars['Int']['input'];
    offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type TeacherSubmittedLogsQuery = {
    readonly __typename?: 'Query';
    readonly teacherSubmittedLogs: ReadonlyArray<{
        readonly __typename?: 'ClassLogGroupDTO';
        readonly date: string;
        readonly classLogs: ReadonlyArray<{
            readonly __typename?: 'ClassLog';
            readonly id: number;
            readonly class: {
                readonly __typename?: 'Klass';
                readonly uid: string;
                readonly labels: ReadonlyArray<KlassLabel>;
                readonly startDate: string;
                readonly endDate: string;
                readonly total: {
                    readonly __typename?: 'Price';
                    readonly amountInMajor: number;
                    readonly currency: Currency;
                } | null;
                readonly lesson: {
                    readonly __typename?: 'Lesson';
                    readonly id: number;
                    readonly title: string | null;
                } | null;
            };
        }>;
    }>;
};

export type TeacherInvoicesQueryVariables = Exact<{
    limit: Scalars['Int']['input'];
    offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type TeacherInvoicesQuery = {
    readonly __typename?: 'Query';
    readonly teacherInvoices: ReadonlyArray<{
        readonly __typename?: 'TeacherInvoice';
        readonly id: number;
        readonly pdfUrl: string | null;
        readonly date: string | null;
        readonly total: number;
        readonly currency: Currency;
        readonly pending: boolean;
        readonly items: ReadonlyArray<{
            readonly __typename?: 'TeacherInvoiceItem';
            readonly id: number;
            readonly date: string;
            readonly description: string;
            readonly bonusType: boolean;
            readonly class: {
                readonly __typename?: 'Klass';
                readonly uid: string;
                readonly labels: ReadonlyArray<KlassLabel>;
                readonly startDate: string;
                readonly endDate: string;
                readonly total: {
                    readonly __typename?: 'Price';
                    readonly amountInMajor: number;
                    readonly currency: Currency;
                } | null;
                readonly lesson: {
                    readonly __typename?: 'Lesson';
                    readonly id: number;
                    readonly title: string | null;
                } | null;
            } | null;
            readonly amount: {
                readonly __typename?: 'Price';
                readonly amountInMajor: number;
                readonly currency: Currency;
            };
        }>;
    }>;
};

export type AdminCommentFragment = {
    readonly __typename?: 'Klass';
    readonly classNote: string | null;
};

export type ClassCancelFragment = {
    readonly __typename?: 'Klass';
    readonly isLanguageClass: boolean;
    readonly startDate: string;
    readonly endDate: string;
    readonly type: ClassType;
    readonly labels: ReadonlyArray<KlassLabel>;
    readonly statusByTeacher: ClassStatusByTeacherType | null;
    readonly isPriceVisible: boolean;
    readonly uid: string;
    readonly total: { readonly __typename?: 'Price'; readonly forHumans: string } | null;
    readonly attendedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
    readonly missedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
    readonly lesson: {
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly title: string | null;
    } | null;
};

export type SaveTeacherAppointmentLogMutationVariables = Exact<{
    classId: Scalars['String']['input'];
    studentId: Scalars['Int']['input'];
    isAttended: Scalars['Boolean']['input'];
    answerIds: ReadonlyArray<Scalars['Ulid']['input']> | Scalars['Ulid']['input'];
    feedback?: InputMaybe<Scalars['String']['input']>;
    recommendedLevel?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SaveTeacherAppointmentLogMutation = {
    readonly __typename?: 'Mutation';
    readonly teacherLogAppointment: {
        readonly __typename?: 'Klass';
        readonly type: ClassType;
        readonly isForNewClassroom: boolean;
        readonly businessModelType: BusinessModelType;
        readonly uid: string;
        readonly labels: ReadonlyArray<KlassLabel>;
        readonly previousClassLogsUrl: string | null;
        readonly isLanguageClass: boolean;
        readonly courseId: string | null;
        readonly endDate: string;
        readonly startDate: string;
        readonly status: ClassStatus;
        readonly annotatedPresentation: string | null;
        readonly isPresentationMode: boolean;
        readonly statusByTeacher: ClassStatusByTeacherType | null;
        readonly isPriceVisible: boolean;
        readonly classNote: string | null;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly title: string | null;
            readonly presentationUrl: string | null;
            readonly presentationNumber: string | null;
            readonly id: number;
            readonly description: string | null;
            readonly learningOutcomes: ReadonlyArray<string> | null;
            readonly presentation: {
                readonly __typename?: 'Media';
                readonly updatedAt: string;
            } | null;
        } | null;
        readonly teacherFeedback: {
            readonly __typename?: 'ClassTeacherFeedback';
            readonly id: number;
        } | null;
        readonly classLog: {
            readonly __typename?: 'ClassLog';
            readonly createdAt: string | null;
            readonly updatedAt: string | null;
            readonly id: number;
            readonly logMessage: string | null;
        } | null;
        readonly activeAppointments: ReadonlyArray<{
            readonly __typename?: 'Appointment';
            readonly id: string;
            readonly attendanceStatus: AppointmentStatusBySystem | null;
            readonly statusBySystem: AppointmentStatusBySystem | null;
            readonly isNewStudent: boolean;
            readonly student: {
                readonly __typename?: 'Student';
                readonly id: number;
                readonly shortName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
                } | null;
                readonly curriculum: {
                    readonly __typename?: 'Curriculum';
                    readonly id: number;
                    readonly name: string;
                };
            };
            readonly appointmentLog: {
                readonly __typename?: 'AppointmentLog';
                readonly feedback: string | null;
                readonly isAttended: boolean;
                readonly createdAt: string;
                readonly updatedAt: string;
                readonly answers: ReadonlyArray<{
                    readonly __typename?: 'AppointmentLogAnswer';
                    readonly id: string;
                    readonly answer: string;
                    readonly appointmentLogQuestion: {
                        readonly __typename?: 'AppointmentLogQuestion';
                        readonly id: string;
                    };
                }>;
                readonly recommendedLevel: {
                    readonly __typename?: 'Module';
                    readonly id: number;
                    readonly name: string;
                } | null;
            } | null;
        }>;
        readonly prepareClassInfo: {
            readonly __typename?: 'PrepareClassInfo';
            readonly isAllowedToLeaveFeedback: boolean;
            readonly prepareClassStudents: ReadonlyArray<{
                readonly __typename?: 'PrepareClassStudent';
                readonly studentTeacherInteractions: number;
                readonly lastInteraction: string | null;
                readonly studentAppointmentLabel: StudentAppointmentLabel;
                readonly student: {
                    readonly __typename?: 'Student';
                    readonly id: number;
                    readonly registrationDate: string | null;
                    readonly shortName: string;
                    readonly firstName: string;
                    readonly completedClasses: number;
                    readonly section: {
                        readonly __typename?: 'Section';
                        readonly id: number;
                        readonly name: SectionName;
                    };
                    readonly photo: {
                        readonly __typename?: 'Image';
                        readonly urls: {
                            readonly __typename?: 'ImageUrl';
                            readonly thumbnail: string;
                            readonly medium: string;
                        };
                    } | null;
                    readonly lastCertificate: {
                        readonly __typename?: 'StudentLanguageLevel';
                        readonly module: { readonly __typename?: 'Module'; readonly name: string };
                    } | null;
                    readonly currentStudentModule: {
                        readonly __typename?: 'StudentModule';
                        readonly name: string;
                        readonly completedClasses: number;
                        readonly totalClasses: number;
                    };
                    readonly studentProfile: {
                        readonly __typename?: 'StudentProfile';
                        readonly learningNeeds: string | null;
                        readonly studentSkills: ReadonlyArray<{
                            readonly __typename?: 'StudentSkill';
                            readonly sentiment: AppointmentLogQuestionSentiment | null;
                            readonly answer: string;
                            readonly count: number;
                        }>;
                        readonly whyAreYouLearning: {
                            readonly __typename?: 'WhyAreYouLearningSection';
                            readonly answers: ReadonlyArray<string>;
                            readonly otherOption: string | null;
                        };
                        readonly spokenLanguages: ReadonlyArray<{
                            readonly __typename?: 'UserSpokenLanguage';
                            readonly language: UserLanguage;
                            readonly languageFluency: LanguageFluency;
                        }>;
                    };
                };
                readonly studentModules: ReadonlyArray<{
                    readonly __typename?: 'StudentModule';
                    readonly name: string;
                    readonly completedClasses: number;
                    readonly totalClasses: number;
                }>;
            }>;
            readonly kysFeedback: {
                readonly __typename?: 'KysFeedback';
                readonly id: string;
                readonly isUseful: boolean;
            } | null;
        };
        readonly total: { readonly __typename?: 'Price'; readonly forHumans: string } | null;
        readonly attendedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
        readonly missedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
    };
};

export type SubmitTeacherClassLogMutationVariables = Exact<{
    classId: Scalars['String']['input'];
    logMessage: Scalars['String']['input'];
}>;

export type SubmitTeacherClassLogMutation = {
    readonly __typename?: 'Mutation';
    readonly teacherSubmitClassLog: {
        readonly __typename?: 'Klass';
        readonly type: ClassType;
        readonly isForNewClassroom: boolean;
        readonly businessModelType: BusinessModelType;
        readonly uid: string;
        readonly labels: ReadonlyArray<KlassLabel>;
        readonly previousClassLogsUrl: string | null;
        readonly isLanguageClass: boolean;
        readonly courseId: string | null;
        readonly endDate: string;
        readonly startDate: string;
        readonly status: ClassStatus;
        readonly annotatedPresentation: string | null;
        readonly isPresentationMode: boolean;
        readonly statusByTeacher: ClassStatusByTeacherType | null;
        readonly isPriceVisible: boolean;
        readonly classNote: string | null;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly title: string | null;
            readonly presentationUrl: string | null;
            readonly presentationNumber: string | null;
            readonly id: number;
            readonly description: string | null;
            readonly learningOutcomes: ReadonlyArray<string> | null;
            readonly presentation: {
                readonly __typename?: 'Media';
                readonly updatedAt: string;
            } | null;
        } | null;
        readonly teacherFeedback: {
            readonly __typename?: 'ClassTeacherFeedback';
            readonly id: number;
        } | null;
        readonly classLog: {
            readonly __typename?: 'ClassLog';
            readonly createdAt: string | null;
            readonly updatedAt: string | null;
            readonly id: number;
            readonly logMessage: string | null;
        } | null;
        readonly activeAppointments: ReadonlyArray<{
            readonly __typename?: 'Appointment';
            readonly id: string;
            readonly attendanceStatus: AppointmentStatusBySystem | null;
            readonly statusBySystem: AppointmentStatusBySystem | null;
            readonly isNewStudent: boolean;
            readonly student: {
                readonly __typename?: 'Student';
                readonly id: number;
                readonly shortName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
                } | null;
                readonly curriculum: {
                    readonly __typename?: 'Curriculum';
                    readonly id: number;
                    readonly name: string;
                };
            };
            readonly appointmentLog: {
                readonly __typename?: 'AppointmentLog';
                readonly feedback: string | null;
                readonly isAttended: boolean;
                readonly createdAt: string;
                readonly updatedAt: string;
                readonly answers: ReadonlyArray<{
                    readonly __typename?: 'AppointmentLogAnswer';
                    readonly id: string;
                    readonly answer: string;
                    readonly appointmentLogQuestion: {
                        readonly __typename?: 'AppointmentLogQuestion';
                        readonly id: string;
                    };
                }>;
                readonly recommendedLevel: {
                    readonly __typename?: 'Module';
                    readonly id: number;
                    readonly name: string;
                } | null;
            } | null;
        }>;
        readonly prepareClassInfo: {
            readonly __typename?: 'PrepareClassInfo';
            readonly isAllowedToLeaveFeedback: boolean;
            readonly prepareClassStudents: ReadonlyArray<{
                readonly __typename?: 'PrepareClassStudent';
                readonly studentTeacherInteractions: number;
                readonly lastInteraction: string | null;
                readonly studentAppointmentLabel: StudentAppointmentLabel;
                readonly student: {
                    readonly __typename?: 'Student';
                    readonly id: number;
                    readonly registrationDate: string | null;
                    readonly shortName: string;
                    readonly firstName: string;
                    readonly completedClasses: number;
                    readonly section: {
                        readonly __typename?: 'Section';
                        readonly id: number;
                        readonly name: SectionName;
                    };
                    readonly photo: {
                        readonly __typename?: 'Image';
                        readonly urls: {
                            readonly __typename?: 'ImageUrl';
                            readonly thumbnail: string;
                            readonly medium: string;
                        };
                    } | null;
                    readonly lastCertificate: {
                        readonly __typename?: 'StudentLanguageLevel';
                        readonly module: { readonly __typename?: 'Module'; readonly name: string };
                    } | null;
                    readonly currentStudentModule: {
                        readonly __typename?: 'StudentModule';
                        readonly name: string;
                        readonly completedClasses: number;
                        readonly totalClasses: number;
                    };
                    readonly studentProfile: {
                        readonly __typename?: 'StudentProfile';
                        readonly learningNeeds: string | null;
                        readonly studentSkills: ReadonlyArray<{
                            readonly __typename?: 'StudentSkill';
                            readonly sentiment: AppointmentLogQuestionSentiment | null;
                            readonly answer: string;
                            readonly count: number;
                        }>;
                        readonly whyAreYouLearning: {
                            readonly __typename?: 'WhyAreYouLearningSection';
                            readonly answers: ReadonlyArray<string>;
                            readonly otherOption: string | null;
                        };
                        readonly spokenLanguages: ReadonlyArray<{
                            readonly __typename?: 'UserSpokenLanguage';
                            readonly language: UserLanguage;
                            readonly languageFluency: LanguageFluency;
                        }>;
                    };
                };
                readonly studentModules: ReadonlyArray<{
                    readonly __typename?: 'StudentModule';
                    readonly name: string;
                    readonly completedClasses: number;
                    readonly totalClasses: number;
                }>;
            }>;
            readonly kysFeedback: {
                readonly __typename?: 'KysFeedback';
                readonly id: string;
                readonly isUseful: boolean;
            } | null;
        };
        readonly total: { readonly __typename?: 'Price'; readonly forHumans: string } | null;
        readonly attendedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
        readonly missedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
    };
};

export type ConfirmCancelClassMutationVariables = Exact<{
    classId: Scalars['String']['input'];
}>;

export type ConfirmCancelClassMutation = {
    readonly __typename?: 'Mutation';
    readonly teacherCancelClass: boolean;
};

export type ConfirmNoStudentsShowedUpMutationVariables = Exact<{
    classId: Scalars['String']['input'];
}>;

export type ConfirmNoStudentsShowedUpMutation = {
    readonly __typename?: 'Mutation';
    readonly teacherLogNoShow: {
        readonly __typename?: 'Klass';
        readonly type: ClassType;
        readonly isForNewClassroom: boolean;
        readonly businessModelType: BusinessModelType;
        readonly uid: string;
        readonly labels: ReadonlyArray<KlassLabel>;
        readonly previousClassLogsUrl: string | null;
        readonly isLanguageClass: boolean;
        readonly courseId: string | null;
        readonly endDate: string;
        readonly startDate: string;
        readonly status: ClassStatus;
        readonly annotatedPresentation: string | null;
        readonly isPresentationMode: boolean;
        readonly statusByTeacher: ClassStatusByTeacherType | null;
        readonly isPriceVisible: boolean;
        readonly classNote: string | null;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly title: string | null;
            readonly presentationUrl: string | null;
            readonly presentationNumber: string | null;
            readonly id: number;
            readonly description: string | null;
            readonly learningOutcomes: ReadonlyArray<string> | null;
            readonly presentation: {
                readonly __typename?: 'Media';
                readonly updatedAt: string;
            } | null;
        } | null;
        readonly teacherFeedback: {
            readonly __typename?: 'ClassTeacherFeedback';
            readonly id: number;
        } | null;
        readonly classLog: {
            readonly __typename?: 'ClassLog';
            readonly createdAt: string | null;
            readonly updatedAt: string | null;
            readonly id: number;
            readonly logMessage: string | null;
        } | null;
        readonly activeAppointments: ReadonlyArray<{
            readonly __typename?: 'Appointment';
            readonly id: string;
            readonly attendanceStatus: AppointmentStatusBySystem | null;
            readonly statusBySystem: AppointmentStatusBySystem | null;
            readonly isNewStudent: boolean;
            readonly student: {
                readonly __typename?: 'Student';
                readonly id: number;
                readonly shortName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
                } | null;
                readonly curriculum: {
                    readonly __typename?: 'Curriculum';
                    readonly id: number;
                    readonly name: string;
                };
            };
            readonly appointmentLog: {
                readonly __typename?: 'AppointmentLog';
                readonly feedback: string | null;
                readonly isAttended: boolean;
                readonly createdAt: string;
                readonly updatedAt: string;
                readonly answers: ReadonlyArray<{
                    readonly __typename?: 'AppointmentLogAnswer';
                    readonly id: string;
                    readonly answer: string;
                    readonly appointmentLogQuestion: {
                        readonly __typename?: 'AppointmentLogQuestion';
                        readonly id: string;
                    };
                }>;
                readonly recommendedLevel: {
                    readonly __typename?: 'Module';
                    readonly id: number;
                    readonly name: string;
                } | null;
            } | null;
        }>;
        readonly prepareClassInfo: {
            readonly __typename?: 'PrepareClassInfo';
            readonly isAllowedToLeaveFeedback: boolean;
            readonly prepareClassStudents: ReadonlyArray<{
                readonly __typename?: 'PrepareClassStudent';
                readonly studentTeacherInteractions: number;
                readonly lastInteraction: string | null;
                readonly studentAppointmentLabel: StudentAppointmentLabel;
                readonly student: {
                    readonly __typename?: 'Student';
                    readonly id: number;
                    readonly registrationDate: string | null;
                    readonly shortName: string;
                    readonly firstName: string;
                    readonly completedClasses: number;
                    readonly section: {
                        readonly __typename?: 'Section';
                        readonly id: number;
                        readonly name: SectionName;
                    };
                    readonly photo: {
                        readonly __typename?: 'Image';
                        readonly urls: {
                            readonly __typename?: 'ImageUrl';
                            readonly thumbnail: string;
                            readonly medium: string;
                        };
                    } | null;
                    readonly lastCertificate: {
                        readonly __typename?: 'StudentLanguageLevel';
                        readonly module: { readonly __typename?: 'Module'; readonly name: string };
                    } | null;
                    readonly currentStudentModule: {
                        readonly __typename?: 'StudentModule';
                        readonly name: string;
                        readonly completedClasses: number;
                        readonly totalClasses: number;
                    };
                    readonly studentProfile: {
                        readonly __typename?: 'StudentProfile';
                        readonly learningNeeds: string | null;
                        readonly studentSkills: ReadonlyArray<{
                            readonly __typename?: 'StudentSkill';
                            readonly sentiment: AppointmentLogQuestionSentiment | null;
                            readonly answer: string;
                            readonly count: number;
                        }>;
                        readonly whyAreYouLearning: {
                            readonly __typename?: 'WhyAreYouLearningSection';
                            readonly answers: ReadonlyArray<string>;
                            readonly otherOption: string | null;
                        };
                        readonly spokenLanguages: ReadonlyArray<{
                            readonly __typename?: 'UserSpokenLanguage';
                            readonly language: UserLanguage;
                            readonly languageFluency: LanguageFluency;
                        }>;
                    };
                };
                readonly studentModules: ReadonlyArray<{
                    readonly __typename?: 'StudentModule';
                    readonly name: string;
                    readonly completedClasses: number;
                    readonly totalClasses: number;
                }>;
            }>;
            readonly kysFeedback: {
                readonly __typename?: 'KysFeedback';
                readonly id: string;
                readonly isUseful: boolean;
            } | null;
        };
        readonly total: { readonly __typename?: 'Price'; readonly forHumans: string } | null;
        readonly attendedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
        readonly missedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
    };
};

export type TeacherLogTechnicalProblemMutationVariables = Exact<{
    classId: Scalars['String']['input'];
    description: Scalars['String']['input'];
    screenshots: ReadonlyArray<Scalars['Upload']['input']> | Scalars['Upload']['input'];
}>;

export type TeacherLogTechnicalProblemMutation = {
    readonly __typename?: 'Mutation';
    readonly teacherLogTechnicalProblem: {
        readonly __typename?: 'Klass';
        readonly type: ClassType;
        readonly isForNewClassroom: boolean;
        readonly businessModelType: BusinessModelType;
        readonly uid: string;
        readonly labels: ReadonlyArray<KlassLabel>;
        readonly previousClassLogsUrl: string | null;
        readonly isLanguageClass: boolean;
        readonly courseId: string | null;
        readonly endDate: string;
        readonly startDate: string;
        readonly status: ClassStatus;
        readonly annotatedPresentation: string | null;
        readonly isPresentationMode: boolean;
        readonly statusByTeacher: ClassStatusByTeacherType | null;
        readonly isPriceVisible: boolean;
        readonly classNote: string | null;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly title: string | null;
            readonly presentationUrl: string | null;
            readonly presentationNumber: string | null;
            readonly id: number;
            readonly description: string | null;
            readonly learningOutcomes: ReadonlyArray<string> | null;
            readonly presentation: {
                readonly __typename?: 'Media';
                readonly updatedAt: string;
            } | null;
        } | null;
        readonly teacherFeedback: {
            readonly __typename?: 'ClassTeacherFeedback';
            readonly id: number;
        } | null;
        readonly classLog: {
            readonly __typename?: 'ClassLog';
            readonly createdAt: string | null;
            readonly updatedAt: string | null;
            readonly id: number;
            readonly logMessage: string | null;
        } | null;
        readonly activeAppointments: ReadonlyArray<{
            readonly __typename?: 'Appointment';
            readonly id: string;
            readonly attendanceStatus: AppointmentStatusBySystem | null;
            readonly statusBySystem: AppointmentStatusBySystem | null;
            readonly isNewStudent: boolean;
            readonly student: {
                readonly __typename?: 'Student';
                readonly id: number;
                readonly shortName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
                } | null;
                readonly curriculum: {
                    readonly __typename?: 'Curriculum';
                    readonly id: number;
                    readonly name: string;
                };
            };
            readonly appointmentLog: {
                readonly __typename?: 'AppointmentLog';
                readonly feedback: string | null;
                readonly isAttended: boolean;
                readonly createdAt: string;
                readonly updatedAt: string;
                readonly answers: ReadonlyArray<{
                    readonly __typename?: 'AppointmentLogAnswer';
                    readonly id: string;
                    readonly answer: string;
                    readonly appointmentLogQuestion: {
                        readonly __typename?: 'AppointmentLogQuestion';
                        readonly id: string;
                    };
                }>;
                readonly recommendedLevel: {
                    readonly __typename?: 'Module';
                    readonly id: number;
                    readonly name: string;
                } | null;
            } | null;
        }>;
        readonly prepareClassInfo: {
            readonly __typename?: 'PrepareClassInfo';
            readonly isAllowedToLeaveFeedback: boolean;
            readonly prepareClassStudents: ReadonlyArray<{
                readonly __typename?: 'PrepareClassStudent';
                readonly studentTeacherInteractions: number;
                readonly lastInteraction: string | null;
                readonly studentAppointmentLabel: StudentAppointmentLabel;
                readonly student: {
                    readonly __typename?: 'Student';
                    readonly id: number;
                    readonly registrationDate: string | null;
                    readonly shortName: string;
                    readonly firstName: string;
                    readonly completedClasses: number;
                    readonly section: {
                        readonly __typename?: 'Section';
                        readonly id: number;
                        readonly name: SectionName;
                    };
                    readonly photo: {
                        readonly __typename?: 'Image';
                        readonly urls: {
                            readonly __typename?: 'ImageUrl';
                            readonly thumbnail: string;
                            readonly medium: string;
                        };
                    } | null;
                    readonly lastCertificate: {
                        readonly __typename?: 'StudentLanguageLevel';
                        readonly module: { readonly __typename?: 'Module'; readonly name: string };
                    } | null;
                    readonly currentStudentModule: {
                        readonly __typename?: 'StudentModule';
                        readonly name: string;
                        readonly completedClasses: number;
                        readonly totalClasses: number;
                    };
                    readonly studentProfile: {
                        readonly __typename?: 'StudentProfile';
                        readonly learningNeeds: string | null;
                        readonly studentSkills: ReadonlyArray<{
                            readonly __typename?: 'StudentSkill';
                            readonly sentiment: AppointmentLogQuestionSentiment | null;
                            readonly answer: string;
                            readonly count: number;
                        }>;
                        readonly whyAreYouLearning: {
                            readonly __typename?: 'WhyAreYouLearningSection';
                            readonly answers: ReadonlyArray<string>;
                            readonly otherOption: string | null;
                        };
                        readonly spokenLanguages: ReadonlyArray<{
                            readonly __typename?: 'UserSpokenLanguage';
                            readonly language: UserLanguage;
                            readonly languageFluency: LanguageFluency;
                        }>;
                    };
                };
                readonly studentModules: ReadonlyArray<{
                    readonly __typename?: 'StudentModule';
                    readonly name: string;
                    readonly completedClasses: number;
                    readonly totalClasses: number;
                }>;
            }>;
            readonly kysFeedback: {
                readonly __typename?: 'KysFeedback';
                readonly id: string;
                readonly isUseful: boolean;
            } | null;
        };
        readonly total: { readonly __typename?: 'Price'; readonly forHumans: string } | null;
        readonly attendedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
        readonly missedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
    };
};

export type ClassActionsFragment = {
    readonly __typename?: 'Klass';
    readonly isLanguageClass: boolean;
    readonly startDate: string;
    readonly endDate: string;
    readonly uid: string;
    readonly labels: ReadonlyArray<KlassLabel>;
    readonly previousClassLogsUrl: string | null;
    readonly type: ClassType;
    readonly statusByTeacher: ClassStatusByTeacherType | null;
    readonly isPriceVisible: boolean;
    readonly classLog: {
        readonly __typename?: 'ClassLog';
        readonly id: number;
        readonly createdAt: string | null;
        readonly updatedAt: string | null;
        readonly logMessage: string | null;
    } | null;
    readonly activeAppointments: ReadonlyArray<{
        readonly __typename?: 'Appointment';
        readonly id: string;
        readonly attendanceStatus: AppointmentStatusBySystem | null;
        readonly statusBySystem: AppointmentStatusBySystem | null;
        readonly isNewStudent: boolean;
        readonly student: {
            readonly __typename?: 'Student';
            readonly id: number;
            readonly shortName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
            } | null;
            readonly curriculum: {
                readonly __typename?: 'Curriculum';
                readonly id: number;
                readonly name: string;
            };
        };
        readonly appointmentLog: {
            readonly __typename?: 'AppointmentLog';
            readonly feedback: string | null;
            readonly isAttended: boolean;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly answers: ReadonlyArray<{
                readonly __typename?: 'AppointmentLogAnswer';
                readonly id: string;
                readonly answer: string;
                readonly appointmentLogQuestion: {
                    readonly __typename?: 'AppointmentLogQuestion';
                    readonly id: string;
                };
            }>;
            readonly recommendedLevel: {
                readonly __typename?: 'Module';
                readonly id: number;
                readonly name: string;
            } | null;
        } | null;
    }>;
    readonly prepareClassInfo: {
        readonly __typename?: 'PrepareClassInfo';
        readonly isAllowedToLeaveFeedback: boolean;
        readonly prepareClassStudents: ReadonlyArray<{
            readonly __typename?: 'PrepareClassStudent';
            readonly studentTeacherInteractions: number;
            readonly lastInteraction: string | null;
            readonly studentAppointmentLabel: StudentAppointmentLabel;
            readonly student: {
                readonly __typename?: 'Student';
                readonly id: number;
                readonly registrationDate: string | null;
                readonly shortName: string;
                readonly firstName: string;
                readonly completedClasses: number;
                readonly section: {
                    readonly __typename?: 'Section';
                    readonly id: number;
                    readonly name: SectionName;
                };
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: {
                        readonly __typename?: 'ImageUrl';
                        readonly thumbnail: string;
                        readonly medium: string;
                    };
                } | null;
                readonly lastCertificate: {
                    readonly __typename?: 'StudentLanguageLevel';
                    readonly module: { readonly __typename?: 'Module'; readonly name: string };
                } | null;
                readonly currentStudentModule: {
                    readonly __typename?: 'StudentModule';
                    readonly name: string;
                    readonly completedClasses: number;
                    readonly totalClasses: number;
                };
                readonly studentProfile: {
                    readonly __typename?: 'StudentProfile';
                    readonly learningNeeds: string | null;
                    readonly studentSkills: ReadonlyArray<{
                        readonly __typename?: 'StudentSkill';
                        readonly sentiment: AppointmentLogQuestionSentiment | null;
                        readonly answer: string;
                        readonly count: number;
                    }>;
                    readonly whyAreYouLearning: {
                        readonly __typename?: 'WhyAreYouLearningSection';
                        readonly answers: ReadonlyArray<string>;
                        readonly otherOption: string | null;
                    };
                    readonly spokenLanguages: ReadonlyArray<{
                        readonly __typename?: 'UserSpokenLanguage';
                        readonly language: UserLanguage;
                        readonly languageFluency: LanguageFluency;
                    }>;
                };
            };
            readonly studentModules: ReadonlyArray<{
                readonly __typename?: 'StudentModule';
                readonly name: string;
                readonly completedClasses: number;
                readonly totalClasses: number;
            }>;
        }>;
        readonly kysFeedback: {
            readonly __typename?: 'KysFeedback';
            readonly id: string;
            readonly isUseful: boolean;
        } | null;
    };
    readonly total: { readonly __typename?: 'Price'; readonly forHumans: string } | null;
    readonly attendedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
    readonly missedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
    readonly lesson: {
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly title: string | null;
    } | null;
};

export type SubmitTeacherMissedClassReportMutationVariables = Exact<{
    classId: Scalars['String']['input'];
    reason: MissedStatusReason;
    details?: InputMaybe<Scalars['String']['input']>;
}>;

export type SubmitTeacherMissedClassReportMutation = {
    readonly __typename?: 'Mutation';
    readonly iMissedClass: boolean;
};

export type ClassDetailsFragment = {
    readonly __typename?: 'Klass';
    readonly courseId: string | null;
    readonly endDate: string;
    readonly startDate: string;
    readonly status: ClassStatus;
    readonly type: ClassType;
    readonly uid: string;
    readonly statusByTeacher: ClassStatusByTeacherType | null;
    readonly classLog: {
        readonly __typename?: 'ClassLog';
        readonly createdAt: string | null;
        readonly updatedAt: string | null;
    } | null;
    readonly activeAppointments: ReadonlyArray<{
        readonly __typename?: 'Appointment';
        readonly id: string;
        readonly attendanceStatus: AppointmentStatusBySystem | null;
        readonly statusBySystem: AppointmentStatusBySystem | null;
        readonly isNewStudent: boolean;
        readonly student: {
            readonly __typename?: 'Student';
            readonly id: number;
            readonly shortName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
            } | null;
            readonly curriculum: {
                readonly __typename?: 'Curriculum';
                readonly id: number;
                readonly name: string;
            };
        };
        readonly appointmentLog: {
            readonly __typename?: 'AppointmentLog';
            readonly feedback: string | null;
            readonly isAttended: boolean;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly answers: ReadonlyArray<{
                readonly __typename?: 'AppointmentLogAnswer';
                readonly id: string;
                readonly answer: string;
                readonly appointmentLogQuestion: {
                    readonly __typename?: 'AppointmentLogQuestion';
                    readonly id: string;
                };
            }>;
            readonly recommendedLevel: {
                readonly __typename?: 'Module';
                readonly id: number;
                readonly name: string;
            } | null;
        } | null;
    }>;
};

export type StudentModulesQueryVariables = Exact<{
    studentId?: InputMaybe<Scalars['Int']['input']>;
    curriculumId?: InputMaybe<Scalars['Int']['input']>;
    section?: InputMaybe<Scalars['SectionName']['input']>;
}>;

export type StudentModulesQuery = {
    readonly __typename?: 'Query';
    readonly studentModules: ReadonlyArray<{
        readonly __typename?: 'StudentModule';
        readonly id: number;
        readonly name: string;
        readonly cefrLevelName: string | null;
    }>;
};

export type ClassLogFormFragment = {
    readonly __typename?: 'Klass';
    readonly uid: string;
    readonly labels: ReadonlyArray<KlassLabel>;
    readonly previousClassLogsUrl: string | null;
    readonly isLanguageClass: boolean;
    readonly classLog: {
        readonly __typename?: 'ClassLog';
        readonly id: number;
        readonly createdAt: string | null;
        readonly updatedAt: string | null;
        readonly logMessage: string | null;
    } | null;
    readonly activeAppointments: ReadonlyArray<{
        readonly __typename?: 'Appointment';
        readonly id: string;
        readonly attendanceStatus: AppointmentStatusBySystem | null;
        readonly statusBySystem: AppointmentStatusBySystem | null;
        readonly isNewStudent: boolean;
        readonly student: {
            readonly __typename?: 'Student';
            readonly id: number;
            readonly shortName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
            } | null;
            readonly curriculum: {
                readonly __typename?: 'Curriculum';
                readonly id: number;
                readonly name: string;
            };
        };
        readonly appointmentLog: {
            readonly __typename?: 'AppointmentLog';
            readonly feedback: string | null;
            readonly isAttended: boolean;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly answers: ReadonlyArray<{
                readonly __typename?: 'AppointmentLogAnswer';
                readonly id: string;
                readonly answer: string;
                readonly appointmentLogQuestion: {
                    readonly __typename?: 'AppointmentLogQuestion';
                    readonly id: string;
                };
            }>;
            readonly recommendedLevel: {
                readonly __typename?: 'Module';
                readonly id: number;
                readonly name: string;
            } | null;
        } | null;
    }>;
    readonly prepareClassInfo: {
        readonly __typename?: 'PrepareClassInfo';
        readonly isAllowedToLeaveFeedback: boolean;
        readonly prepareClassStudents: ReadonlyArray<{
            readonly __typename?: 'PrepareClassStudent';
            readonly studentTeacherInteractions: number;
            readonly lastInteraction: string | null;
            readonly studentAppointmentLabel: StudentAppointmentLabel;
            readonly student: {
                readonly __typename?: 'Student';
                readonly id: number;
                readonly registrationDate: string | null;
                readonly shortName: string;
                readonly firstName: string;
                readonly completedClasses: number;
                readonly section: {
                    readonly __typename?: 'Section';
                    readonly id: number;
                    readonly name: SectionName;
                };
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: {
                        readonly __typename?: 'ImageUrl';
                        readonly thumbnail: string;
                        readonly medium: string;
                    };
                } | null;
                readonly lastCertificate: {
                    readonly __typename?: 'StudentLanguageLevel';
                    readonly module: { readonly __typename?: 'Module'; readonly name: string };
                } | null;
                readonly currentStudentModule: {
                    readonly __typename?: 'StudentModule';
                    readonly name: string;
                    readonly completedClasses: number;
                    readonly totalClasses: number;
                };
                readonly studentProfile: {
                    readonly __typename?: 'StudentProfile';
                    readonly learningNeeds: string | null;
                    readonly studentSkills: ReadonlyArray<{
                        readonly __typename?: 'StudentSkill';
                        readonly sentiment: AppointmentLogQuestionSentiment | null;
                        readonly answer: string;
                        readonly count: number;
                    }>;
                    readonly whyAreYouLearning: {
                        readonly __typename?: 'WhyAreYouLearningSection';
                        readonly answers: ReadonlyArray<string>;
                        readonly otherOption: string | null;
                    };
                    readonly spokenLanguages: ReadonlyArray<{
                        readonly __typename?: 'UserSpokenLanguage';
                        readonly language: UserLanguage;
                        readonly languageFluency: LanguageFluency;
                    }>;
                };
            };
            readonly studentModules: ReadonlyArray<{
                readonly __typename?: 'StudentModule';
                readonly name: string;
                readonly completedClasses: number;
                readonly totalClasses: number;
            }>;
        }>;
        readonly kysFeedback: {
            readonly __typename?: 'KysFeedback';
            readonly id: string;
            readonly isUseful: boolean;
        } | null;
    };
};

export type AppointmentFragment = {
    readonly __typename?: 'Appointment';
    readonly id: string;
    readonly attendanceStatus: AppointmentStatusBySystem | null;
    readonly statusBySystem: AppointmentStatusBySystem | null;
    readonly isNewStudent: boolean;
    readonly student: {
        readonly __typename?: 'Student';
        readonly id: number;
        readonly shortName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
        } | null;
        readonly curriculum: {
            readonly __typename?: 'Curriculum';
            readonly id: number;
            readonly name: string;
        };
    };
    readonly appointmentLog: {
        readonly __typename?: 'AppointmentLog';
        readonly feedback: string | null;
        readonly isAttended: boolean;
        readonly createdAt: string;
        readonly updatedAt: string;
        readonly answers: ReadonlyArray<{
            readonly __typename?: 'AppointmentLogAnswer';
            readonly id: string;
            readonly answer: string;
            readonly appointmentLogQuestion: {
                readonly __typename?: 'AppointmentLogQuestion';
                readonly id: string;
            };
        }>;
        readonly recommendedLevel: {
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
        } | null;
    } | null;
};

export type ClassLogFragment = {
    readonly __typename?: 'ClassLog';
    readonly id: number;
    readonly createdAt: string | null;
    readonly updatedAt: string | null;
    readonly logMessage: string | null;
};

export type KysFeedbackFragment = {
    readonly __typename?: 'PrepareClassInfo';
    readonly isAllowedToLeaveFeedback: boolean;
    readonly kysFeedback: {
        readonly __typename?: 'KysFeedback';
        readonly id: string;
        readonly isUseful: boolean;
    } | null;
};

export type PrepareClassInfoFragment = {
    readonly __typename?: 'PrepareClassInfo';
    readonly isAllowedToLeaveFeedback: boolean;
    readonly prepareClassStudents: ReadonlyArray<{
        readonly __typename?: 'PrepareClassStudent';
        readonly studentTeacherInteractions: number;
        readonly lastInteraction: string | null;
        readonly studentAppointmentLabel: StudentAppointmentLabel;
        readonly student: {
            readonly __typename?: 'Student';
            readonly id: number;
            readonly registrationDate: string | null;
            readonly shortName: string;
            readonly firstName: string;
            readonly completedClasses: number;
            readonly section: {
                readonly __typename?: 'Section';
                readonly id: number;
                readonly name: SectionName;
            };
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: {
                    readonly __typename?: 'ImageUrl';
                    readonly thumbnail: string;
                    readonly medium: string;
                };
            } | null;
            readonly lastCertificate: {
                readonly __typename?: 'StudentLanguageLevel';
                readonly module: { readonly __typename?: 'Module'; readonly name: string };
            } | null;
            readonly currentStudentModule: {
                readonly __typename?: 'StudentModule';
                readonly name: string;
                readonly completedClasses: number;
                readonly totalClasses: number;
            };
            readonly studentProfile: {
                readonly __typename?: 'StudentProfile';
                readonly learningNeeds: string | null;
                readonly studentSkills: ReadonlyArray<{
                    readonly __typename?: 'StudentSkill';
                    readonly sentiment: AppointmentLogQuestionSentiment | null;
                    readonly answer: string;
                    readonly count: number;
                }>;
                readonly whyAreYouLearning: {
                    readonly __typename?: 'WhyAreYouLearningSection';
                    readonly answers: ReadonlyArray<string>;
                    readonly otherOption: string | null;
                };
                readonly spokenLanguages: ReadonlyArray<{
                    readonly __typename?: 'UserSpokenLanguage';
                    readonly language: UserLanguage;
                    readonly languageFluency: LanguageFluency;
                }>;
            };
        };
        readonly studentModules: ReadonlyArray<{
            readonly __typename?: 'StudentModule';
            readonly name: string;
            readonly completedClasses: number;
            readonly totalClasses: number;
        }>;
    }>;
    readonly kysFeedback: {
        readonly __typename?: 'KysFeedback';
        readonly id: string;
        readonly isUseful: boolean;
    } | null;
};

export type TeacherCancelClassObservationMutationVariables = Exact<{
    classId: Scalars['String']['input'];
}>;

export type TeacherCancelClassObservationMutation = {
    readonly __typename?: 'Mutation';
    readonly teacherCancelClassObservation: {
        readonly __typename?: 'ClassObservation';
        readonly status: ClassObservationStatusType;
    } | null;
};

export type SubmitKysFeedbackMutationVariables = Exact<{
    classId: Scalars['String']['input'];
    isKysUseful: Scalars['Boolean']['input'];
}>;

export type SubmitKysFeedbackMutation = {
    readonly __typename?: 'Mutation';
    readonly submitKysFeedback: {
        readonly __typename?: 'ClassTeacherFeedback';
        readonly id: number;
    };
};

export type KysFeedbackQueryVariables = Exact<{
    classId: Scalars['String']['input'];
}>;

export type KysFeedbackQuery = {
    readonly __typename?: 'Query';
    readonly teacherClass: {
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly prepareClassInfo: {
            readonly __typename?: 'PrepareClassInfo';
            readonly isAllowedToLeaveFeedback: boolean;
            readonly kysFeedback: {
                readonly __typename?: 'KysFeedback';
                readonly id: string;
                readonly isUseful: boolean;
            } | null;
        };
    };
};

export type ClassPresentationFragment = {
    readonly __typename?: 'Klass';
    readonly uid: string;
    readonly annotatedPresentation: string | null;
    readonly isPresentationMode: boolean;
    readonly lesson: {
        readonly __typename?: 'Lesson';
        readonly title: string | null;
        readonly presentationUrl: string | null;
        readonly presentationNumber: string | null;
        readonly presentation: { readonly __typename?: 'Media'; readonly updatedAt: string } | null;
    } | null;
};

export type SubmitPresentationFeedbackMutationVariables = Exact<{
    classId: Scalars['String']['input'];
    feedback: Scalars['String']['input'];
}>;

export type SubmitPresentationFeedbackMutation = {
    readonly __typename?: 'Mutation';
    readonly submitPresentationFeedback: boolean;
};

export type ClassRemunerationFragment = {
    readonly __typename?: 'Klass';
    readonly type: ClassType;
    readonly labels: ReadonlyArray<KlassLabel>;
    readonly statusByTeacher: ClassStatusByTeacherType | null;
    readonly isPriceVisible: boolean;
    readonly total: { readonly __typename?: 'Price'; readonly forHumans: string } | null;
    readonly attendedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
    readonly missedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
};

export type ClassStatusBannerFragment = {
    readonly __typename?: 'Klass';
    readonly statusByTeacher: ClassStatusByTeacherType | null;
    readonly classLog: {
        readonly __typename?: 'ClassLog';
        readonly createdAt: string | null;
        readonly updatedAt: string | null;
    } | null;
    readonly activeAppointments: ReadonlyArray<{
        readonly __typename?: 'Appointment';
        readonly id: string;
        readonly attendanceStatus: AppointmentStatusBySystem | null;
        readonly statusBySystem: AppointmentStatusBySystem | null;
        readonly isNewStudent: boolean;
        readonly student: {
            readonly __typename?: 'Student';
            readonly id: number;
            readonly shortName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
            } | null;
            readonly curriculum: {
                readonly __typename?: 'Curriculum';
                readonly id: number;
                readonly name: string;
            };
        };
        readonly appointmentLog: {
            readonly __typename?: 'AppointmentLog';
            readonly feedback: string | null;
            readonly isAttended: boolean;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly answers: ReadonlyArray<{
                readonly __typename?: 'AppointmentLogAnswer';
                readonly id: string;
                readonly answer: string;
                readonly appointmentLogQuestion: {
                    readonly __typename?: 'AppointmentLogQuestion';
                    readonly id: string;
                };
            }>;
            readonly recommendedLevel: {
                readonly __typename?: 'Module';
                readonly id: number;
                readonly name: string;
            } | null;
        } | null;
    }>;
};

export type ClassSummaryFragment = {
    readonly __typename?: 'Klass';
    readonly uid: string;
    readonly type: ClassType;
    readonly labels: ReadonlyArray<KlassLabel>;
    readonly statusByTeacher: ClassStatusByTeacherType | null;
    readonly isPriceVisible: boolean;
    readonly startDate: string;
    readonly endDate: string;
    readonly lesson: {
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly title: string | null;
    } | null;
    readonly total: { readonly __typename?: 'Price'; readonly forHumans: string } | null;
    readonly attendedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
    readonly missedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
};

export type LessonDetailsFragment = {
    readonly __typename?: 'Lesson';
    readonly title: string | null;
    readonly description: string | null;
    readonly learningOutcomes: ReadonlyArray<string> | null;
};

export type TeacherClassFragment = {
    readonly __typename?: 'Klass';
    readonly type: ClassType;
    readonly isForNewClassroom: boolean;
    readonly businessModelType: BusinessModelType;
    readonly uid: string;
    readonly labels: ReadonlyArray<KlassLabel>;
    readonly previousClassLogsUrl: string | null;
    readonly isLanguageClass: boolean;
    readonly courseId: string | null;
    readonly endDate: string;
    readonly startDate: string;
    readonly status: ClassStatus;
    readonly annotatedPresentation: string | null;
    readonly isPresentationMode: boolean;
    readonly statusByTeacher: ClassStatusByTeacherType | null;
    readonly isPriceVisible: boolean;
    readonly classNote: string | null;
    readonly lesson: {
        readonly __typename?: 'Lesson';
        readonly title: string | null;
        readonly presentationUrl: string | null;
        readonly presentationNumber: string | null;
        readonly id: number;
        readonly description: string | null;
        readonly learningOutcomes: ReadonlyArray<string> | null;
        readonly presentation: { readonly __typename?: 'Media'; readonly updatedAt: string } | null;
    } | null;
    readonly teacherFeedback: {
        readonly __typename?: 'ClassTeacherFeedback';
        readonly id: number;
    } | null;
    readonly classLog: {
        readonly __typename?: 'ClassLog';
        readonly createdAt: string | null;
        readonly updatedAt: string | null;
        readonly id: number;
        readonly logMessage: string | null;
    } | null;
    readonly activeAppointments: ReadonlyArray<{
        readonly __typename?: 'Appointment';
        readonly id: string;
        readonly attendanceStatus: AppointmentStatusBySystem | null;
        readonly statusBySystem: AppointmentStatusBySystem | null;
        readonly isNewStudent: boolean;
        readonly student: {
            readonly __typename?: 'Student';
            readonly id: number;
            readonly shortName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
            } | null;
            readonly curriculum: {
                readonly __typename?: 'Curriculum';
                readonly id: number;
                readonly name: string;
            };
        };
        readonly appointmentLog: {
            readonly __typename?: 'AppointmentLog';
            readonly feedback: string | null;
            readonly isAttended: boolean;
            readonly createdAt: string;
            readonly updatedAt: string;
            readonly answers: ReadonlyArray<{
                readonly __typename?: 'AppointmentLogAnswer';
                readonly id: string;
                readonly answer: string;
                readonly appointmentLogQuestion: {
                    readonly __typename?: 'AppointmentLogQuestion';
                    readonly id: string;
                };
            }>;
            readonly recommendedLevel: {
                readonly __typename?: 'Module';
                readonly id: number;
                readonly name: string;
            } | null;
        } | null;
    }>;
    readonly prepareClassInfo: {
        readonly __typename?: 'PrepareClassInfo';
        readonly isAllowedToLeaveFeedback: boolean;
        readonly prepareClassStudents: ReadonlyArray<{
            readonly __typename?: 'PrepareClassStudent';
            readonly studentTeacherInteractions: number;
            readonly lastInteraction: string | null;
            readonly studentAppointmentLabel: StudentAppointmentLabel;
            readonly student: {
                readonly __typename?: 'Student';
                readonly id: number;
                readonly registrationDate: string | null;
                readonly shortName: string;
                readonly firstName: string;
                readonly completedClasses: number;
                readonly section: {
                    readonly __typename?: 'Section';
                    readonly id: number;
                    readonly name: SectionName;
                };
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: {
                        readonly __typename?: 'ImageUrl';
                        readonly thumbnail: string;
                        readonly medium: string;
                    };
                } | null;
                readonly lastCertificate: {
                    readonly __typename?: 'StudentLanguageLevel';
                    readonly module: { readonly __typename?: 'Module'; readonly name: string };
                } | null;
                readonly currentStudentModule: {
                    readonly __typename?: 'StudentModule';
                    readonly name: string;
                    readonly completedClasses: number;
                    readonly totalClasses: number;
                };
                readonly studentProfile: {
                    readonly __typename?: 'StudentProfile';
                    readonly learningNeeds: string | null;
                    readonly studentSkills: ReadonlyArray<{
                        readonly __typename?: 'StudentSkill';
                        readonly sentiment: AppointmentLogQuestionSentiment | null;
                        readonly answer: string;
                        readonly count: number;
                    }>;
                    readonly whyAreYouLearning: {
                        readonly __typename?: 'WhyAreYouLearningSection';
                        readonly answers: ReadonlyArray<string>;
                        readonly otherOption: string | null;
                    };
                    readonly spokenLanguages: ReadonlyArray<{
                        readonly __typename?: 'UserSpokenLanguage';
                        readonly language: UserLanguage;
                        readonly languageFluency: LanguageFluency;
                    }>;
                };
            };
            readonly studentModules: ReadonlyArray<{
                readonly __typename?: 'StudentModule';
                readonly name: string;
                readonly completedClasses: number;
                readonly totalClasses: number;
            }>;
        }>;
        readonly kysFeedback: {
            readonly __typename?: 'KysFeedback';
            readonly id: string;
            readonly isUseful: boolean;
        } | null;
    };
    readonly total: { readonly __typename?: 'Price'; readonly forHumans: string } | null;
    readonly attendedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
    readonly missedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
};

export type AppointmentLogQuestionFragment = {
    readonly __typename?: 'AppointmentLogQuestion';
    readonly id: string;
    readonly isRequired: boolean;
    readonly min: number;
    readonly max: number;
    readonly question: string;
    readonly answers: ReadonlyArray<{
        readonly __typename?: 'AppointmentLogAnswer';
        readonly id: string;
        readonly answer: string;
    }>;
};

export type TeacherClassQueryVariables = Exact<{
    classId: Scalars['String']['input'];
}>;

export type TeacherClassQuery = {
    readonly __typename?: 'Query';
    readonly teacherClass: {
        readonly __typename?: 'Klass';
        readonly type: ClassType;
        readonly isForNewClassroom: boolean;
        readonly businessModelType: BusinessModelType;
        readonly uid: string;
        readonly labels: ReadonlyArray<KlassLabel>;
        readonly previousClassLogsUrl: string | null;
        readonly isLanguageClass: boolean;
        readonly courseId: string | null;
        readonly endDate: string;
        readonly startDate: string;
        readonly status: ClassStatus;
        readonly annotatedPresentation: string | null;
        readonly isPresentationMode: boolean;
        readonly statusByTeacher: ClassStatusByTeacherType | null;
        readonly isPriceVisible: boolean;
        readonly classNote: string | null;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly title: string | null;
            readonly presentationUrl: string | null;
            readonly presentationNumber: string | null;
            readonly id: number;
            readonly description: string | null;
            readonly learningOutcomes: ReadonlyArray<string> | null;
            readonly presentation: {
                readonly __typename?: 'Media';
                readonly updatedAt: string;
            } | null;
        } | null;
        readonly teacherFeedback: {
            readonly __typename?: 'ClassTeacherFeedback';
            readonly id: number;
        } | null;
        readonly classLog: {
            readonly __typename?: 'ClassLog';
            readonly createdAt: string | null;
            readonly updatedAt: string | null;
            readonly id: number;
            readonly logMessage: string | null;
        } | null;
        readonly activeAppointments: ReadonlyArray<{
            readonly __typename?: 'Appointment';
            readonly id: string;
            readonly attendanceStatus: AppointmentStatusBySystem | null;
            readonly statusBySystem: AppointmentStatusBySystem | null;
            readonly isNewStudent: boolean;
            readonly student: {
                readonly __typename?: 'Student';
                readonly id: number;
                readonly shortName: string;
                readonly photo: {
                    readonly __typename?: 'Image';
                    readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
                } | null;
                readonly curriculum: {
                    readonly __typename?: 'Curriculum';
                    readonly id: number;
                    readonly name: string;
                };
            };
            readonly appointmentLog: {
                readonly __typename?: 'AppointmentLog';
                readonly feedback: string | null;
                readonly isAttended: boolean;
                readonly createdAt: string;
                readonly updatedAt: string;
                readonly answers: ReadonlyArray<{
                    readonly __typename?: 'AppointmentLogAnswer';
                    readonly id: string;
                    readonly answer: string;
                    readonly appointmentLogQuestion: {
                        readonly __typename?: 'AppointmentLogQuestion';
                        readonly id: string;
                    };
                }>;
                readonly recommendedLevel: {
                    readonly __typename?: 'Module';
                    readonly id: number;
                    readonly name: string;
                } | null;
            } | null;
        }>;
        readonly prepareClassInfo: {
            readonly __typename?: 'PrepareClassInfo';
            readonly isAllowedToLeaveFeedback: boolean;
            readonly prepareClassStudents: ReadonlyArray<{
                readonly __typename?: 'PrepareClassStudent';
                readonly studentTeacherInteractions: number;
                readonly lastInteraction: string | null;
                readonly studentAppointmentLabel: StudentAppointmentLabel;
                readonly student: {
                    readonly __typename?: 'Student';
                    readonly id: number;
                    readonly registrationDate: string | null;
                    readonly shortName: string;
                    readonly firstName: string;
                    readonly completedClasses: number;
                    readonly section: {
                        readonly __typename?: 'Section';
                        readonly id: number;
                        readonly name: SectionName;
                    };
                    readonly photo: {
                        readonly __typename?: 'Image';
                        readonly urls: {
                            readonly __typename?: 'ImageUrl';
                            readonly thumbnail: string;
                            readonly medium: string;
                        };
                    } | null;
                    readonly lastCertificate: {
                        readonly __typename?: 'StudentLanguageLevel';
                        readonly module: { readonly __typename?: 'Module'; readonly name: string };
                    } | null;
                    readonly currentStudentModule: {
                        readonly __typename?: 'StudentModule';
                        readonly name: string;
                        readonly completedClasses: number;
                        readonly totalClasses: number;
                    };
                    readonly studentProfile: {
                        readonly __typename?: 'StudentProfile';
                        readonly learningNeeds: string | null;
                        readonly studentSkills: ReadonlyArray<{
                            readonly __typename?: 'StudentSkill';
                            readonly sentiment: AppointmentLogQuestionSentiment | null;
                            readonly answer: string;
                            readonly count: number;
                        }>;
                        readonly whyAreYouLearning: {
                            readonly __typename?: 'WhyAreYouLearningSection';
                            readonly answers: ReadonlyArray<string>;
                            readonly otherOption: string | null;
                        };
                        readonly spokenLanguages: ReadonlyArray<{
                            readonly __typename?: 'UserSpokenLanguage';
                            readonly language: UserLanguage;
                            readonly languageFluency: LanguageFluency;
                        }>;
                    };
                };
                readonly studentModules: ReadonlyArray<{
                    readonly __typename?: 'StudentModule';
                    readonly name: string;
                    readonly completedClasses: number;
                    readonly totalClasses: number;
                }>;
            }>;
            readonly kysFeedback: {
                readonly __typename?: 'KysFeedback';
                readonly id: string;
                readonly isUseful: boolean;
            } | null;
        };
        readonly total: { readonly __typename?: 'Price'; readonly forHumans: string } | null;
        readonly attendedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
        readonly missedPrice: { readonly __typename?: 'Price'; readonly forHumans: string };
    };
    readonly teacherClassAppointmentLogQuestions: ReadonlyArray<{
        readonly __typename?: 'AppointmentLogQuestion';
        readonly id: string;
        readonly isRequired: boolean;
        readonly min: number;
        readonly max: number;
        readonly question: string;
        readonly answers: ReadonlyArray<{
            readonly __typename?: 'AppointmentLogAnswer';
            readonly id: string;
            readonly answer: string;
        }>;
    }>;
    readonly teacherClassObservation: {
        readonly __typename?: 'ClassObservation';
        readonly id: string;
        readonly observerFullName: string;
        readonly status: ClassObservationStatusType;
    } | null;
};

export type StudentAppointmentsDatesQueryVariables = Exact<{
    studentId: Scalars['Int']['input'];
    fromDate?: InputMaybe<Scalars['DateTime']['input']>;
    toDate?: InputMaybe<Scalars['DateTime']['input']>;
    sort?: InputMaybe<Scalars['String']['input']>;
    appointmentStatus?: InputMaybe<Scalars['String']['input']>;
    appointmentStatuses?: InputMaybe<
        ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']
    >;
}>;

export type StudentAppointmentsDatesQuery = {
    readonly __typename?: 'Query';
    readonly studentAppointmentDates: ReadonlyArray<string>;
};

export type TeacherCoursesTeacherAvailableCourseFragment = {
    readonly __typename?: 'TeacherAvailableCourse';
    readonly id: string;
    readonly name: string;
    readonly startDate: string;
    readonly endDate: string;
    readonly classesCount: number;
    readonly studentsCount: number;
    readonly material: string;
    readonly courseType: string;
    readonly courseTimes: ReadonlyArray<{
        readonly __typename?: 'CourseDayAndHour';
        readonly dayOfWeek: DayOfWeek;
        readonly hour: string;
    }>;
    readonly courseModules: ReadonlyArray<{
        readonly __typename?: 'Module';
        readonly cefrLevelName: string | null;
    }>;
    readonly price: {
        readonly __typename?: 'Price';
        readonly forHumans: string;
        readonly amount: number;
        readonly currency: Currency;
    };
};

export type TeacherCoursesCourseHolidayFragment = {
    readonly __typename?: 'CourseHoliday';
    readonly startDate: string;
    readonly endDate: string;
};

export type TeacherCoursesCourseAssignResultFragment = {
    readonly __typename?: 'CourseAssignResult';
    readonly bookedClasses: ReadonlyArray<{ readonly __typename?: 'Klass'; readonly uid: string }>;
    readonly errors: ReadonlyArray<{
        readonly __typename?: 'CourseAssignError';
        readonly reason: CourseAssignErrorReason;
        readonly class: { readonly __typename?: 'Klass'; readonly uid: string };
    }>;
    readonly overriddenClasses: ReadonlyArray<{
        readonly __typename?: 'CourseAssignOverlap';
        readonly overlappedScheduledClass: { readonly __typename?: 'Klass'; readonly uid: string };
        readonly overlappingCourseClass: { readonly __typename?: 'Klass'; readonly uid: string };
    }>;
};

export type TeacherBookCourseMutationVariables = Exact<{
    courseId: Scalars['Ulid']['input'];
}>;

export type TeacherBookCourseMutation = {
    readonly __typename?: 'Mutation';
    readonly teacherBookCourse: {
        readonly __typename?: 'CourseAssignResult';
        readonly bookedClasses: ReadonlyArray<{
            readonly __typename?: 'Klass';
            readonly uid: string;
        }>;
        readonly errors: ReadonlyArray<{
            readonly __typename?: 'CourseAssignError';
            readonly reason: CourseAssignErrorReason;
            readonly class: { readonly __typename?: 'Klass'; readonly uid: string };
        }>;
        readonly overriddenClasses: ReadonlyArray<{
            readonly __typename?: 'CourseAssignOverlap';
            readonly overlappedScheduledClass: {
                readonly __typename?: 'Klass';
                readonly uid: string;
            };
            readonly overlappingCourseClass: {
                readonly __typename?: 'Klass';
                readonly uid: string;
            };
        }>;
    };
};

export type TeacherCoursesGetTeacherCoursesQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherCoursesGetTeacherCoursesQuery = {
    readonly __typename?: 'Query';
    readonly teacherAvailableCourses: {
        readonly __typename?: 'TeacherAvailableCoursesListDTO';
        readonly isPriceVisible: boolean;
        readonly courses: ReadonlyArray<{
            readonly __typename?: 'TeacherAvailableCourse';
            readonly id: string;
            readonly name: string;
            readonly startDate: string;
            readonly endDate: string;
            readonly classesCount: number;
            readonly studentsCount: number;
            readonly material: string;
            readonly courseType: string;
            readonly courseTimes: ReadonlyArray<{
                readonly __typename?: 'CourseDayAndHour';
                readonly dayOfWeek: DayOfWeek;
                readonly hour: string;
            }>;
            readonly courseModules: ReadonlyArray<{
                readonly __typename?: 'Module';
                readonly cefrLevelName: string | null;
            }>;
            readonly price: {
                readonly __typename?: 'Price';
                readonly forHumans: string;
                readonly amount: number;
                readonly currency: Currency;
            };
        }>;
    };
    readonly courseHolidays: ReadonlyArray<{
        readonly __typename?: 'CourseHoliday';
        readonly startDate: string;
        readonly endDate: string;
    }>;
};

export type TeacherCoursesGetTeacherTagQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherCoursesGetTeacherTagQuery = {
    readonly __typename?: 'Query';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly section: { readonly __typename?: 'Section'; readonly name: SectionName } | null;
        readonly teacherInfo: {
            readonly __typename?: 'TeacherInfo';
            readonly isB2G: boolean;
        } | null;
    } | null;
};

export type TeacherLearningMaterialsQueryVariables = Exact<{
    limit: Scalars['Int']['input'];
    page?: Scalars['Int']['input'];
}>;

export type TeacherLearningMaterialsQuery = {
    readonly __typename?: 'Query';
    readonly teacherLearningMaterials: {
        readonly __typename?: 'Pagination_Module';
        readonly hasMore: boolean;
        readonly currentPage: number;
        readonly items: ReadonlyArray<{
            readonly __typename?: 'Module';
            readonly id: number;
            readonly name: string;
            readonly longName: string;
            readonly code: string;
            readonly description: string;
            readonly cefrLevelName: string | null;
            readonly learningUnits: ReadonlyArray<{
                readonly __typename?: 'LearningUnit';
                readonly id: number;
                readonly name: string;
                readonly chapter: number;
                readonly lessons: ReadonlyArray<{
                    readonly __typename?: 'Lesson';
                    readonly id: number;
                    readonly description: string | null;
                    readonly learningOutcomes: ReadonlyArray<string> | null;
                    readonly presentationNumber: string | null;
                    readonly presentationUrl: string | null;
                    readonly title: string | null;
                    readonly type: LessonType | null;
                    readonly presentation: {
                        readonly __typename?: 'Media';
                        readonly updatedAt: string;
                    } | null;
                }>;
            }>;
        }>;
    };
};

export type TeacherSetIsOnboardedMutationVariables = Exact<{ [key: string]: never }>;

export type TeacherSetIsOnboardedMutation = {
    readonly __typename?: 'Mutation';
    readonly teacherSetIsOnboarded: boolean;
};

export type TeacherGetIsOnboardedQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherGetIsOnboardedQuery = {
    readonly __typename?: 'Query';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly teacherInfo: {
            readonly __typename?: 'TeacherInfo';
            readonly isOnboarded: boolean;
            readonly isB2G: boolean;
        } | null;
    } | null;
};

export type TeacherAccountDataFragment = {
    readonly __typename?: 'Teacher';
    readonly id: number;
    readonly uid: string;
    readonly email: string;
    readonly agreements: ReadonlyArray<{
        readonly __typename?: 'Agreement';
        readonly id: string;
        readonly isAccepted: boolean;
        readonly body: string;
        readonly acceptedAt: string | null;
        readonly type: AgreementType | null;
    }>;
};

export type TeacherGetAccountQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherGetAccountQuery = {
    readonly __typename?: 'Query';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly uid: string;
        readonly email: string;
        readonly agreements: ReadonlyArray<{
            readonly __typename?: 'Agreement';
            readonly id: string;
            readonly isAccepted: boolean;
            readonly body: string;
            readonly acceptedAt: string | null;
            readonly type: AgreementType | null;
        }>;
    } | null;
};

export type TeacherProfileCertificateFragment = {
    readonly __typename?: 'TeacherCertificate';
    readonly id: string;
    readonly url: string;
    readonly type: CertificateType | null;
    readonly label: string | null;
};

export type TeacherUploadCertificateMutationVariables = Exact<{
    type: CertificateType;
    certificates: ReadonlyArray<Scalars['Upload']['input']> | Scalars['Upload']['input'];
}>;

export type TeacherUploadCertificateMutation = {
    readonly __typename?: 'Mutation';
    readonly teacherUploadCertificate: ReadonlyArray<{
        readonly __typename?: 'TeacherCertificate';
        readonly id: string;
        readonly url: string;
        readonly type: CertificateType | null;
        readonly label: string | null;
    }>;
};

export type TeacherRemoveCertificateMutationVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type TeacherRemoveCertificateMutation = {
    readonly __typename?: 'Mutation';
    readonly teacherRemoveCertificate: boolean;
};

export type TeacherCertificateQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherCertificateQuery = {
    readonly __typename?: 'Query';
    readonly teacherCertificates: ReadonlyArray<{
        readonly __typename?: 'TeacherCertificate';
        readonly id: string;
        readonly url: string;
        readonly type: CertificateType | null;
        readonly label: string | null;
    }>;
};

export type TeacherAvailableCertificateTypesQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherAvailableCertificateTypesQuery = {
    readonly __typename?: 'Query';
    readonly certificateTypes: ReadonlyArray<{
        readonly __typename?: 'TeacherAvailableCertificateType';
        readonly type: string;
        readonly label: string;
    }>;
};

export type TeacherInvoiceDataFragment = {
    readonly __typename?: 'Teacher';
    readonly id: number;
    readonly teacherInfo: {
        readonly __typename?: 'TeacherInfo';
        readonly address: string | null;
        readonly address2: string | null;
        readonly bic: string;
        readonly city: string | null;
        readonly defaultPaymentMethod: PaymentMethod | null;
        readonly iban: string;
        readonly paypal: string;
        readonly postalCode: string | null;
        readonly state: string | null;
        readonly incomeTaxNumber: string;
        readonly country: { readonly __typename?: 'Country'; readonly code: string } | null;
    } | null;
};

export type TeacherUpdateInvoiceMutationVariables = Exact<{
    paymentInfoInput: UpdatePaymentInfoInput;
}>;

export type TeacherUpdateInvoiceMutation = {
    readonly __typename?: 'Mutation';
    readonly teacherUpdatePaymentInfo: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly teacherInfo: {
            readonly __typename?: 'TeacherInfo';
            readonly address: string | null;
            readonly address2: string | null;
            readonly bic: string;
            readonly city: string | null;
            readonly defaultPaymentMethod: PaymentMethod | null;
            readonly iban: string;
            readonly paypal: string;
            readonly postalCode: string | null;
            readonly state: string | null;
            readonly incomeTaxNumber: string;
            readonly country: { readonly __typename?: 'Country'; readonly code: string } | null;
        } | null;
    };
};

export type TeacherGetInvoiceQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherGetInvoiceQuery = {
    readonly __typename?: 'Query';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly teacherInfo: {
            readonly __typename?: 'TeacherInfo';
            readonly address: string | null;
            readonly address2: string | null;
            readonly bic: string;
            readonly city: string | null;
            readonly defaultPaymentMethod: PaymentMethod | null;
            readonly iban: string;
            readonly paypal: string;
            readonly postalCode: string | null;
            readonly state: string | null;
            readonly incomeTaxNumber: string;
            readonly country: { readonly __typename?: 'Country'; readonly code: string } | null;
        } | null;
    } | null;
};

export type TeacherLanguagesFragment = {
    readonly __typename?: 'Teacher';
    readonly id: number;
    readonly teacherInfo: {
        readonly __typename?: 'TeacherInfo';
        readonly languages: ReadonlyArray<{
            readonly __typename?: 'Language';
            readonly language: SpokenLanguage;
            readonly fluency: LanguageFluency;
        }>;
    } | null;
};

export type TeacherUpdateLanguagesMutationVariables = Exact<{
    teacherLanguages: TeacherLanguagesInput;
}>;

export type TeacherUpdateLanguagesMutation = {
    readonly __typename?: 'Mutation';
    readonly teacherUpdateLanguages: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly teacherInfo: {
            readonly __typename?: 'TeacherInfo';
            readonly languages: ReadonlyArray<{
                readonly __typename?: 'Language';
                readonly language: SpokenLanguage;
                readonly fluency: LanguageFluency;
            }>;
        } | null;
    };
};

export type TeacherProfileLanguagesQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherProfileLanguagesQuery = {
    readonly __typename?: 'Query';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly teacherInfo: {
            readonly __typename?: 'TeacherInfo';
            readonly languages: ReadonlyArray<{
                readonly __typename?: 'Language';
                readonly language: SpokenLanguage;
                readonly fluency: LanguageFluency;
            }>;
        } | null;
    } | null;
};

export type TeacherNotificationsSettingsFragment = {
    readonly __typename?: 'Teacher';
    readonly id: number;
    readonly notificationSettings: {
        readonly __typename?: 'TeacherNotificationSettings';
        readonly feedback: boolean;
        readonly otherClassCancellation: boolean;
        readonly shortNoticeCancellation: boolean;
    } | null;
};

export type TeacherUpdateNotificationSettingsMutationVariables = Exact<{
    notificationSettings: TeacherNotificationSettingsInput;
}>;

export type TeacherUpdateNotificationSettingsMutation = {
    readonly __typename?: 'Mutation';
    readonly teacherUpdateNotificationSettings: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly notificationSettings: {
            readonly __typename?: 'TeacherNotificationSettings';
            readonly feedback: boolean;
            readonly otherClassCancellation: boolean;
            readonly shortNoticeCancellation: boolean;
        } | null;
    };
};

export type TeacherNotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherNotificationsQuery = {
    readonly __typename?: 'Query';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly notificationSettings: {
            readonly __typename?: 'TeacherNotificationSettings';
            readonly feedback: boolean;
            readonly otherClassCancellation: boolean;
            readonly shortNoticeCancellation: boolean;
        } | null;
    } | null;
};

export type TeacherPreferencesDataFragment = {
    readonly __typename?: 'Teacher';
    readonly id: number;
    readonly teacherPreferences: {
        readonly __typename?: 'TeacherPreference';
        readonly groupClasses: boolean;
        readonly privateClasses: boolean;
        readonly specializedClasses: boolean;
        readonly timeFormat: TimeFormat | null;
        readonly timezone: string;
        readonly weekStart: number;
    };
};

export type TeacherUpdatePreferencesMutationVariables = Exact<{
    teacherPreferences: UpdateTeacherPreferencesInput;
}>;

export type TeacherUpdatePreferencesMutation = {
    readonly __typename?: 'Mutation';
    readonly teacherUpdatePreferences: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly teacherPreferences: {
            readonly __typename?: 'TeacherPreference';
            readonly groupClasses: boolean;
            readonly privateClasses: boolean;
            readonly specializedClasses: boolean;
            readonly timeFormat: TimeFormat | null;
            readonly timezone: string;
            readonly weekStart: number;
        };
    };
};

export type TeacherGetPreferencesQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherGetPreferencesQuery = {
    readonly __typename?: 'Query';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly teacherPreferences: {
            readonly __typename?: 'TeacherPreference';
            readonly groupClasses: boolean;
            readonly privateClasses: boolean;
            readonly specializedClasses: boolean;
            readonly timeFormat: TimeFormat | null;
            readonly timezone: string;
            readonly weekStart: number;
        };
    } | null;
};

export type TeacherPersonalInformationTeacherFragment = {
    readonly __typename?: 'Teacher';
    readonly id: number;
    readonly firstName: string;
    readonly lastName: string | null;
    readonly photo: {
        readonly __typename?: 'Image';
        readonly id: number;
        readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
    } | null;
    readonly teacherInfo: { readonly __typename?: 'TeacherInfo'; readonly aboutMe: string } | null;
};

export type TeacherUpdatePersonalInformationMutationVariables = Exact<{
    input: ProfileInput;
}>;

export type TeacherUpdatePersonalInformationMutation = {
    readonly __typename?: 'Mutation';
    readonly teacherUpdateProfile: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly firstName: string;
        readonly lastName: string | null;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly id: number;
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
        } | null;
        readonly teacherInfo: {
            readonly __typename?: 'TeacherInfo';
            readonly aboutMe: string;
        } | null;
    };
};

export type TeacherGetPersonalInformationQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherGetPersonalInformationQuery = {
    readonly __typename?: 'Query';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly firstName: string;
        readonly lastName: string | null;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly id: number;
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
        } | null;
        readonly teacherInfo: {
            readonly __typename?: 'TeacherInfo';
            readonly aboutMe: string;
        } | null;
    } | null;
};

export type TeacherRatingAndFeedbackQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherRatingAndFeedbackQuery = {
    readonly __typename?: 'Query';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly section: {
            readonly __typename?: 'Section';
            readonly id: number;
            readonly name: SectionName;
        } | null;
        readonly rating: {
            readonly __typename?: 'TeacherRating';
            readonly date: string;
            readonly personalAverage: number;
            readonly sectionAverage: number;
            readonly classesTaught: number;
        };
        readonly feedback: ReadonlyArray<{
            readonly __typename?: 'TeacherFeedback';
            readonly classStartDate: string;
            readonly lessonTitle: string;
            readonly feedback: string;
        }>;
    } | null;
};

export type ProfileTeacherFragment = {
    readonly __typename?: 'Teacher';
    readonly id: number;
    readonly firstName: string;
    readonly lastName: string | null;
    readonly photo: {
        readonly __typename?: 'Image';
        readonly id: number;
        readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
    } | null;
    readonly teacherInfo: { readonly __typename?: 'TeacherInfo'; readonly aboutMe: string } | null;
};

export type TeacherUpdateProfileMutationVariables = Exact<{
    input: ProfileInput;
}>;

export type TeacherUpdateProfileMutation = {
    readonly __typename?: 'Mutation';
    readonly teacherUpdateProfile: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly firstName: string;
        readonly lastName: string | null;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly id: number;
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
        } | null;
        readonly teacherInfo: {
            readonly __typename?: 'TeacherInfo';
            readonly aboutMe: string;
        } | null;
    };
};

export type TeacherProfileQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherProfileQuery = {
    readonly __typename?: 'Query';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly firstName: string;
        readonly lastName: string | null;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly id: number;
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
        } | null;
        readonly teacherInfo: {
            readonly __typename?: 'TeacherInfo';
            readonly aboutMe: string;
        } | null;
    } | null;
};

export type VocabularyListQueryVariables = Exact<{
    limit: Scalars['Int']['input'];
    page: Scalars['Int']['input'];
    moduleIds?: InputMaybe<ReadonlyArray<Scalars['Int']['input']> | Scalars['Int']['input']>;
    wordStatuses?: InputMaybe<ReadonlyArray<WordStatus> | WordStatus>;
}>;

export type VocabularyListQuery = {
    readonly __typename?: 'Query';
    readonly cumulativeVocabList: {
        readonly __typename?: 'Pagination_CumulativeVocabWord';
        readonly count: number;
        readonly hasMore: boolean;
        readonly currentPage: number;
        readonly items: ReadonlyArray<{
            readonly __typename?: 'CumulativeVocabWord';
            readonly id: string;
            readonly status: WordStatus;
            readonly target: string;
            readonly translation: string;
            readonly audioUrls: {
                readonly __typename?: 'VocabularyItemAudio';
                readonly target: string;
            } | null;
        }>;
    };
};

export type AttendedClassesForVocabularyListQueryVariables = Exact<{ [key: string]: never }>;

export type AttendedClassesForVocabularyListQuery = {
    readonly __typename?: 'Query';
    readonly student: {
        readonly __typename?: 'Student';
        readonly id: number;
        readonly completedClasses: number;
    };
};

export type BookableClassFragment = {
    readonly __typename?: 'Klass';
    readonly uid: string;
    readonly startDate: string;
    readonly endDate: string;
    readonly type: ClassType;
    readonly isExisting: boolean;
    readonly isForNewClassroom: boolean;
    readonly module: { readonly __typename?: 'Module'; readonly id: number; readonly name: string };
    readonly learningUnit: {
        readonly __typename?: 'LearningUnit';
        readonly id: number;
        readonly chapter: number;
    } | null;
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly firstName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
        } | null;
    } | null;
    readonly students: ReadonlyArray<{
        readonly __typename?: 'Student';
        readonly shortName: string;
        readonly id: number;
        readonly firstLetterOfFirstName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
        } | null;
    }>;
    readonly lesson: {
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly label: string;
        readonly title: string | null;
        readonly type: LessonType | null;
        readonly studentStatus: StudentStatus;
        readonly availableWithoutPayment: boolean;
    } | null;
    readonly appointment: {
        readonly __typename?: 'Appointment';
        readonly id: string;
        readonly status: AppointmentStatus;
    } | null;
};

export type DisconnectCalendarMutationVariables = Exact<{
    calendarType: CalendarType;
}>;

export type DisconnectCalendarMutation = {
    readonly __typename?: 'Mutation';
    readonly calendarDisconnect: boolean;
};

export type StudentCalendarsQueryVariables = Exact<{ [key: string]: never }>;

export type StudentCalendarsQuery = {
    readonly __typename?: 'Query';
    readonly calendarList: ReadonlyArray<{
        readonly __typename?: 'UserExternalCalendarDTO';
        readonly id: string | null;
        readonly type: CalendarType;
        readonly status: AuthTokenStatus | null;
        readonly email: string | null;
    }>;
};

export type ConnectCalendarQueryVariables = Exact<{
    calendarType: CalendarType;
}>;

export type ConnectCalendarQuery = {
    readonly __typename?: 'Query';
    readonly calendarAuthRedirectUrl: string;
};

export type TeacherChatParticipantPhotoFragment = {
    readonly __typename?: 'Teacher';
    readonly id: number;
    readonly uid: string;
    readonly photo: {
        readonly __typename?: 'Image';
        readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
    } | null;
};

export type StudentChatParticipantPhotoFragment = {
    readonly __typename?: 'Student';
    readonly id: number;
    readonly uid: string;
    readonly photo: {
        readonly __typename?: 'Image';
        readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
    } | null;
};

export type ClassChatParticipantPhotosFragment = {
    readonly __typename?: 'Klass';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly uid: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
        } | null;
    } | null;
    readonly students: ReadonlyArray<{
        readonly __typename?: 'Student';
        readonly id: number;
        readonly uid: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
        } | null;
    }>;
};

export type GetClassChatHistoryQueryVariables = Exact<{
    classUid: Scalars['String']['input'];
}>;

export type GetClassChatHistoryQuery = {
    readonly __typename?: 'Query';
    readonly chatHistory: {
        readonly __typename?: 'ClassChatHistory';
        readonly content: ReadonlyArray<Record<string, string>>;
    } | null;
};

export type ChatHistoryMetadataQueryVariables = Exact<{
    classUid: Scalars['String']['input'];
}>;

export type ChatHistoryMetadataQuery = {
    readonly __typename?: 'Query';
    readonly class: {
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly uid: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
            } | null;
        } | null;
        readonly students: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly id: number;
            readonly uid: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
            } | null;
        }>;
    };
    readonly currentUser: { readonly __typename?: 'User'; readonly uid: string };
};

export type TeacherParticipantPhotoFragment = {
    readonly __typename?: 'Teacher';
    readonly id: number;
    readonly uid: string;
    readonly firstName: string;
    readonly photo: {
        readonly __typename?: 'Image';
        readonly urls: {
            readonly __typename?: 'ImageUrl';
            readonly thumbnail: string;
            readonly medium: string;
        };
    } | null;
};

export type StudentParticipantPhotoFragment = {
    readonly __typename?: 'Student';
    readonly id: number;
    readonly uid: string;
    readonly firstName: string;
    readonly photo: {
        readonly __typename?: 'Image';
        readonly urls: {
            readonly __typename?: 'ImageUrl';
            readonly thumbnail: string;
            readonly medium: string;
        };
    } | null;
};

export type ClassParticipantPhotosFragment = {
    readonly __typename?: 'Klass';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly uid: string;
        readonly firstName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: {
                readonly __typename?: 'ImageUrl';
                readonly thumbnail: string;
                readonly medium: string;
            };
        } | null;
    } | null;
    readonly students: ReadonlyArray<{
        readonly __typename?: 'Student';
        readonly id: number;
        readonly uid: string;
        readonly firstName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: {
                readonly __typename?: 'ImageUrl';
                readonly thumbnail: string;
                readonly medium: string;
            };
        } | null;
    }>;
};

export type ClassParticipantCompletedClassesFragment = {
    readonly __typename?: 'Klass';
    readonly students: ReadonlyArray<{
        readonly __typename?: 'Student';
        readonly completedClasses: number;
    }>;
};

export type ClassParticipantsQueryVariables = Exact<{
    classUid: Scalars['String']['input'];
}>;

export type ClassParticipantsQuery = {
    readonly __typename?: 'Query';
    readonly class: {
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly uid: string;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: {
                    readonly __typename?: 'ImageUrl';
                    readonly thumbnail: string;
                    readonly medium: string;
                };
            } | null;
        } | null;
        readonly students: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly completedClasses: number;
            readonly id: number;
            readonly uid: string;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: {
                    readonly __typename?: 'ImageUrl';
                    readonly thumbnail: string;
                    readonly medium: string;
                };
            } | null;
        }>;
    };
};

export type DemoClassParticipantsQueryVariables = Exact<{
    classUid: Scalars['String']['input'];
}>;

export type DemoClassParticipantsQuery = {
    readonly __typename?: 'Query';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly uid: string;
        readonly firstName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: {
                readonly __typename?: 'ImageUrl';
                readonly thumbnail: string;
                readonly medium: string;
            };
        } | null;
    } | null;
};

export type PostClassPresentationOverlayQueryVariables = Exact<{
    classUid: Scalars['String']['input'];
}>;

export type PostClassPresentationOverlayQuery = {
    readonly __typename?: 'Query';
    readonly class: {
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly sourcePresentation: string | null;
    };
    readonly classLessonMaterialSnapshot: {
        readonly __typename?: 'ClassMaterialSnapshot';
        readonly snapshot: string;
    };
};

export type ClassFeedbackAnswerFragment = {
    readonly __typename?: 'ClassFeedbackAnswer';
    readonly answerType: ClassFeedbackAnswerType;
    readonly sentiment: ClassFeedbackSentimentType;
    readonly active: boolean;
    readonly value: string;
    readonly label: string;
};

export type ClassFeedbackAnswersQueryVariables = Exact<{
    type: Scalars['String']['input'];
    sentiment: Scalars['String']['input'];
}>;

export type ClassFeedbackAnswersQuery = {
    readonly __typename?: 'Query';
    readonly classFeedbackAnswers: ReadonlyArray<{
        readonly __typename?: 'ClassFeedbackAnswer';
        readonly answerType: ClassFeedbackAnswerType;
        readonly sentiment: ClassFeedbackSentimentType;
        readonly active: boolean;
        readonly value: string;
        readonly label: string;
    }>;
};

export type ClassStateKlassFragment = {
    readonly __typename?: 'Klass';
    readonly uid: string;
    readonly endDate: string;
    readonly startDate: string;
};

export type ClassCardStudentFragment = {
    readonly __typename?: 'Student';
    readonly id: number;
    readonly firstLetterOfFirstName: string;
    readonly photo: {
        readonly __typename?: 'Image';
        readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
    } | null;
};

export type ClassCardTeacherFragment = {
    readonly __typename?: 'Teacher';
    readonly id: number;
    readonly firstName: string;
    readonly photo: {
        readonly __typename?: 'Image';
        readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
    } | null;
};

export type ClassCardAppointmentFragment = {
    readonly __typename?: 'Appointment';
    readonly id: string;
    readonly status: AppointmentStatus;
    readonly label: string | null;
    readonly klass: {
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly type: ClassType;
        readonly students: ReadonlyArray<{
            readonly __typename?: 'Student';
            readonly id: number;
            readonly firstLetterOfFirstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        }>;
        readonly lesson: {
            readonly __typename?: 'Lesson';
            readonly title: string | null;
            readonly label: string;
        } | null;
        readonly teacher: {
            readonly __typename?: 'Teacher';
            readonly id: number;
            readonly firstName: string;
            readonly photo: {
                readonly __typename?: 'Image';
                readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
            } | null;
        } | null;
    };
};

export type ClassStateStudentQueryVariables = Exact<{
    classId: Scalars['String']['input'];
}>;

export type ClassStateStudentQuery = {
    readonly __typename?: 'Query';
    readonly class: {
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly endDate: string;
        readonly startDate: string;
        readonly appointment: {
            readonly __typename?: 'Appointment';
            readonly id: string;
            readonly createdAt: string;
            readonly status: AppointmentStatus;
        } | null;
    };
};

export type ClassStateTeacherQueryVariables = Exact<{
    classId: Scalars['String']['input'];
}>;

export type ClassStateTeacherQuery = {
    readonly __typename?: 'Query';
    readonly class: {
        readonly __typename?: 'Klass';
        readonly classLogEnabled: boolean;
        readonly confirmedAt: string | null;
        readonly uid: string;
        readonly endDate: string;
        readonly startDate: string;
    };
};

export type UpcomingAppointmentsCountQueryVariables = Exact<{ [key: string]: never }>;

export type UpcomingAppointmentsCountQuery = {
    readonly __typename?: 'Query';
    readonly upcomingAppointmentsCount: number;
};

export type SaveClassroomHistoryEventMutationVariables = Exact<{
    classId: Scalars['String']['input'];
    eventName: VirtualClassroomEvent;
    studentIds?: InputMaybe<ReadonlyArray<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;

export type SaveClassroomHistoryEventMutation = {
    readonly __typename?: 'Mutation';
    readonly saveClassroomEvent: boolean;
};

export type ClassroomStudentDataFragment = {
    readonly __typename?: 'Query';
    readonly student: {
        readonly __typename?: 'Student';
        readonly id: number;
        readonly uid: string;
        readonly firstName: string;
        readonly timezone: string;
        readonly timeFormat: TimeFormat;
        readonly weekStart: number | null;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: {
                readonly __typename?: 'ImageUrl';
                readonly medium: string;
                readonly thumbnail: string;
            };
        } | null;
    };
};

export type ClassroomTeacherDataFragment = {
    readonly __typename?: 'Query';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly uid: string;
        readonly firstName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: {
                readonly __typename?: 'ImageUrl';
                readonly medium: string;
                readonly thumbnail: string;
            };
        } | null;
        readonly teacherPreferences: {
            readonly __typename?: 'TeacherPreference';
            readonly timezone: string;
            readonly timeFormat: TimeFormat | null;
            readonly weekStart: number;
        };
    } | null;
};

export type ClassStateClassroomQueryVariables = Exact<{
    classUid: Scalars['String']['input'];
}>;

export type ClassStateClassroomQuery = {
    readonly __typename?: 'Query';
    readonly class: {
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly endDate: string;
        readonly startDate: string;
    };
};

export type ClassroomUserRoleDataQueryVariables = Exact<{
    classUid: Scalars['String']['input'];
}>;

export type ClassroomUserRoleDataQuery = {
    readonly __typename?: 'Query';
    readonly currentUser: {
        readonly __typename?: 'User';
        readonly id: number;
        readonly uid: string;
    };
    readonly class: {
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly teacher: { readonly __typename?: 'Teacher'; readonly uid: string } | null;
    };
};

export type ClassroomDemoUserRoleDataQueryVariables = Exact<{
    classUid: Scalars['String']['input'];
}>;

export type ClassroomDemoUserRoleDataQuery = {
    readonly __typename?: 'Query';
    readonly currentUser: {
        readonly __typename?: 'User';
        readonly id: number;
        readonly uid: string;
    };
    readonly teacher: { readonly __typename?: 'Teacher'; readonly uid: string } | null;
};

export type ClassroomStudentDataQueryVariables = Exact<{ [key: string]: never }>;

export type ClassroomStudentDataQuery = {
    readonly __typename?: 'Query';
    readonly student: {
        readonly __typename?: 'Student';
        readonly id: number;
        readonly uid: string;
        readonly firstName: string;
        readonly timezone: string;
        readonly timeFormat: TimeFormat;
        readonly weekStart: number | null;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: {
                readonly __typename?: 'ImageUrl';
                readonly medium: string;
                readonly thumbnail: string;
            };
        } | null;
    };
};

export type ClassroomTeacherDataQueryVariables = Exact<{ [key: string]: never }>;

export type ClassroomTeacherDataQuery = {
    readonly __typename?: 'Query';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly uid: string;
        readonly firstName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: {
                readonly __typename?: 'ImageUrl';
                readonly medium: string;
                readonly thumbnail: string;
            };
        } | null;
        readonly teacherPreferences: {
            readonly __typename?: 'TeacherPreference';
            readonly timezone: string;
            readonly timeFormat: TimeFormat | null;
            readonly weekStart: number;
        };
    } | null;
};

export type PresentationModeAuthDataQueryVariables = Exact<{
    classUid: Scalars['String']['input'];
}>;

export type PresentationModeAuthDataQuery = {
    readonly __typename?: 'Query';
    readonly presentationModeAuthData: {
        readonly __typename?: 'AuthData';
        readonly userId: number;
        readonly jwtToken: string;
        readonly pdfUrl: string;
    };
};

export type ClassroomPresentationFeatureAvailabilityQueryVariables = Exact<{
    classUid: Scalars['String']['input'];
}>;

export type ClassroomPresentationFeatureAvailabilityQuery = {
    readonly __typename?: 'Query';
    readonly class: {
        readonly __typename?: 'Klass';
        readonly uid: string;
        readonly isPresentationMode: boolean;
    };
};

export type StudentPaymentMethodAddedQueryVariables = Exact<{ [key: string]: never }>;

export type StudentPaymentMethodAddedQuery = {
    readonly __typename?: 'Query';
    readonly subscriptions: {
        readonly __typename?: 'StudentSubscriptionInfo';
        readonly currentSubscription: {
            readonly __typename?: 'Subscription';
            readonly id: number;
            readonly expiresAt: string | null;
            readonly paymentMethodAdded: boolean | null;
        } | null;
    };
};

export type ExerciseFragment = {
    readonly __typename?: 'H5PExercise';
    readonly id: string;
    readonly title: string;
    readonly type: ExerciseType | null;
    readonly url: string;
    readonly score: number | null;
};

export type ExtraCreditPurchaseFragment = {
    readonly __typename?: 'ExtraCreditPurchase';
    readonly id: string;
    readonly type: PurchaseType;
    readonly displayName: string;
    readonly privateCredits: number;
    readonly groupCredits: number;
    readonly isSubscription: boolean;
    readonly canUseCoupon: boolean;
    readonly contentBlocks: ReadonlyArray<{
        readonly __typename?: 'ContentBlock';
        readonly location: Location;
        readonly label: string | null;
        readonly text: string;
        readonly icon: Icon | null;
        readonly isStarred: boolean;
        readonly table: string[][] | null;
    }>;
    readonly coupons: ReadonlyArray<{
        readonly __typename?: 'Coupon';
        readonly id: number;
        readonly code: string;
    }>;
    readonly total: {
        readonly __typename?: 'Price';
        readonly currency: Currency;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly forHumans: string;
    };
    readonly subtotal: {
        readonly __typename?: 'Price';
        readonly currency: Currency;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly forHumans: string;
    };
    readonly chargeTotal: {
        readonly __typename?: 'Price';
        readonly currency: Currency;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly forHumans: string;
    };
    readonly discount: {
        readonly __typename?: 'Price';
        readonly currency: Currency;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly forHumans: string;
    };
    readonly agreements: ReadonlyArray<{
        readonly __typename?: 'Agreement';
        readonly id: string;
        readonly acceptanceText: string;
        readonly pdfLink: string | null;
    }>;
    readonly validityInterval: {
        readonly __typename?: 'DateInterval';
        readonly years: number;
        readonly months: number;
        readonly weeks: number;
        readonly days: number;
        readonly hours: number;
        readonly minutes: number;
        readonly seconds: number;
        readonly microseconds: number;
        readonly forHumans: string;
    };
    readonly lastTransaction: {
        readonly __typename?: 'Transaction';
        readonly id: number;
        readonly errorCode: string | null;
        readonly errorMessage: string | null;
        readonly paymentStatus: PaymentStatus;
        readonly isInitialPurchase: boolean;
        readonly isPaymentMethodUpdate: boolean;
        readonly currency: string;
        readonly discount: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly amount: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
    } | null;
};

export type IntervalFragment = {
    readonly __typename?: 'DateInterval';
    readonly years: number;
    readonly months: number;
    readonly weeks: number;
    readonly days: number;
    readonly hours: number;
    readonly minutes: number;
    readonly seconds: number;
    readonly microseconds: number;
    readonly forHumans: string;
};

export type LearningUnitFragment = {
    readonly __typename?: 'LearningUnit';
    readonly id: number;
    readonly name: string;
    readonly chapter: number;
    readonly lessons: ReadonlyArray<{
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly description: string | null;
        readonly learningOutcomes: ReadonlyArray<string> | null;
        readonly presentationNumber: string | null;
        readonly presentationUrl: string | null;
        readonly title: string | null;
        readonly type: LessonType | null;
        readonly presentation: { readonly __typename?: 'Media'; readonly updatedAt: string } | null;
    }>;
};

export type LegacyPurchaseFragment = {
    readonly __typename?: 'LegacyPurchase';
    readonly id: string;
    readonly type: PurchaseType;
    readonly displayName: string;
    readonly privateCredits: number;
    readonly groupCredits: number;
    readonly isSubscription: boolean;
    readonly canUseCoupon: boolean;
    readonly productDescription: string | null;
    readonly withTrial: boolean;
    readonly contentBlocks: ReadonlyArray<{
        readonly __typename?: 'ContentBlock';
        readonly location: Location;
        readonly label: string | null;
        readonly text: string;
        readonly icon: Icon | null;
        readonly isStarred: boolean;
        readonly table: string[][] | null;
    }>;
    readonly coupons: ReadonlyArray<{
        readonly __typename?: 'Coupon';
        readonly id: number;
        readonly code: string;
    }>;
    readonly total: {
        readonly __typename?: 'Price';
        readonly currency: Currency;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly forHumans: string;
    };
    readonly subtotal: {
        readonly __typename?: 'Price';
        readonly currency: Currency;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly forHumans: string;
    };
    readonly chargeTotal: {
        readonly __typename?: 'Price';
        readonly currency: Currency;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly forHumans: string;
    };
    readonly discount: {
        readonly __typename?: 'Price';
        readonly currency: Currency;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly forHumans: string;
    };
    readonly agreements: ReadonlyArray<{
        readonly __typename?: 'Agreement';
        readonly id: string;
        readonly acceptanceText: string;
        readonly pdfLink: string | null;
    }>;
    readonly validityInterval: {
        readonly __typename?: 'DateInterval';
        readonly years: number;
        readonly months: number;
        readonly weeks: number;
        readonly days: number;
        readonly hours: number;
        readonly minutes: number;
        readonly seconds: number;
        readonly microseconds: number;
        readonly forHumans: string;
    };
    readonly lastTransaction: {
        readonly __typename?: 'Transaction';
        readonly id: number;
        readonly errorCode: string | null;
        readonly errorMessage: string | null;
        readonly paymentStatus: PaymentStatus;
        readonly isInitialPurchase: boolean;
        readonly isPaymentMethodUpdate: boolean;
        readonly currency: string;
        readonly discount: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly amount: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
    } | null;
    readonly rollover: {
        readonly __typename?: 'LegacyRolloverData';
        readonly productId: number;
        readonly trialInterval: {
            readonly __typename?: 'DateInterval';
            readonly years: number;
            readonly months: number;
            readonly weeks: number;
            readonly days: number;
            readonly hours: number;
            readonly minutes: number;
            readonly seconds: number;
            readonly microseconds: number;
            readonly forHumans: string;
        };
        readonly recurrenceInterval: {
            readonly __typename?: 'DateInterval';
            readonly years: number;
            readonly months: number;
            readonly weeks: number;
            readonly days: number;
            readonly hours: number;
            readonly minutes: number;
            readonly seconds: number;
            readonly microseconds: number;
            readonly forHumans: string;
        } | null;
        readonly subTotal: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly total: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
    } | null;
    readonly recurrenceInterval: {
        readonly __typename?: 'DateInterval';
        readonly years: number;
        readonly months: number;
        readonly weeks: number;
        readonly days: number;
        readonly hours: number;
        readonly minutes: number;
        readonly seconds: number;
        readonly microseconds: number;
        readonly forHumans: string;
    } | null;
};

export type OwnReferralFragment = {
    readonly __typename?: 'OwnReferral';
    readonly code: string;
    readonly ownerGroupCredits: number;
    readonly userDiscount: ReadonlyArray<{
        readonly __typename?: 'Price';
        readonly amountInMajor: number;
        readonly currency: Currency;
        readonly amount: number;
    }>;
};

export type PriceFragment = {
    readonly __typename?: 'Price';
    readonly currency: Currency;
    readonly amount: number;
    readonly amountInMajor: number;
    readonly forHumans: string;
};

export type PurchaseAgreementFragment = {
    readonly __typename?: 'Agreement';
    readonly id: string;
    readonly acceptanceText: string;
    readonly pdfLink: string | null;
};

export type LegacyRolloverDataFragment = {
    readonly __typename?: 'LegacyRolloverData';
    readonly productId: number;
    readonly trialInterval: {
        readonly __typename?: 'DateInterval';
        readonly years: number;
        readonly months: number;
        readonly weeks: number;
        readonly days: number;
        readonly hours: number;
        readonly minutes: number;
        readonly seconds: number;
        readonly microseconds: number;
        readonly forHumans: string;
    };
    readonly recurrenceInterval: {
        readonly __typename?: 'DateInterval';
        readonly years: number;
        readonly months: number;
        readonly weeks: number;
        readonly days: number;
        readonly hours: number;
        readonly minutes: number;
        readonly seconds: number;
        readonly microseconds: number;
        readonly forHumans: string;
    } | null;
    readonly subTotal: {
        readonly __typename?: 'Price';
        readonly currency: Currency;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly forHumans: string;
    };
    readonly total: {
        readonly __typename?: 'Price';
        readonly currency: Currency;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly forHumans: string;
    };
};

export type CourseSectionFragment = {
    readonly __typename?: 'Section';
    readonly id: number;
    readonly name: SectionName;
};

export type SubscriptionPurchaseFragment = {
    readonly __typename?: 'SubscriptionPurchase';
    readonly id: string;
    readonly type: PurchaseType;
    readonly displayName: string;
    readonly privateCredits: number;
    readonly groupCredits: number;
    readonly isSubscription: boolean;
    readonly canUseCoupon: boolean;
    readonly withTrial: boolean;
    readonly contentBlocks: ReadonlyArray<{
        readonly __typename?: 'ContentBlock';
        readonly location: Location;
        readonly label: string | null;
        readonly text: string;
        readonly icon: Icon | null;
        readonly isStarred: boolean;
        readonly table: string[][] | null;
    }>;
    readonly coupons: ReadonlyArray<{
        readonly __typename?: 'Coupon';
        readonly id: number;
        readonly code: string;
    }>;
    readonly total: {
        readonly __typename?: 'Price';
        readonly currency: Currency;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly forHumans: string;
    };
    readonly subtotal: {
        readonly __typename?: 'Price';
        readonly currency: Currency;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly forHumans: string;
    };
    readonly chargeTotal: {
        readonly __typename?: 'Price';
        readonly currency: Currency;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly forHumans: string;
    };
    readonly discount: {
        readonly __typename?: 'Price';
        readonly currency: Currency;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly forHumans: string;
    };
    readonly agreements: ReadonlyArray<{
        readonly __typename?: 'Agreement';
        readonly id: string;
        readonly acceptanceText: string;
        readonly pdfLink: string | null;
    }>;
    readonly validityInterval: {
        readonly __typename?: 'DateInterval';
        readonly years: number;
        readonly months: number;
        readonly weeks: number;
        readonly days: number;
        readonly hours: number;
        readonly minutes: number;
        readonly seconds: number;
        readonly microseconds: number;
        readonly forHumans: string;
    };
    readonly lastTransaction: {
        readonly __typename?: 'Transaction';
        readonly id: number;
        readonly errorCode: string | null;
        readonly errorMessage: string | null;
        readonly paymentStatus: PaymentStatus;
        readonly isInitialPurchase: boolean;
        readonly isPaymentMethodUpdate: boolean;
        readonly currency: string;
        readonly discount: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly amount: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
    } | null;
    readonly recurrenceInterval: {
        readonly __typename?: 'DateInterval';
        readonly years: number;
        readonly months: number;
        readonly weeks: number;
        readonly days: number;
        readonly hours: number;
        readonly minutes: number;
        readonly seconds: number;
        readonly microseconds: number;
        readonly forHumans: string;
    } | null;
    readonly newSection: { readonly __typename?: 'Section'; readonly name: SectionName } | null;
    readonly curriculum: { readonly __typename?: 'Curriculum'; readonly id: number } | null;
};

export type TransactionFragment = {
    readonly __typename?: 'Transaction';
    readonly id: number;
    readonly errorCode: string | null;
    readonly errorMessage: string | null;
    readonly paymentStatus: PaymentStatus;
    readonly isInitialPurchase: boolean;
    readonly isPaymentMethodUpdate: boolean;
    readonly currency: string;
    readonly discount: {
        readonly __typename?: 'Price';
        readonly currency: Currency;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly forHumans: string;
    };
    readonly amount: {
        readonly __typename?: 'Price';
        readonly currency: Currency;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly forHumans: string;
    };
};

export type CreateAppleCreditPurchaseMutationVariables = Exact<{
    price: PriceInput;
    transactionReceipt: Scalars['String']['input'];
}>;

export type CreateAppleCreditPurchaseMutation = {
    readonly __typename?: 'Mutation';
    readonly createAppleCreditPurchase: {
        readonly __typename?: 'ExtraCreditPurchase';
        readonly id: string;
    };
};

export type CreateExtraCreditPurchaseMutationVariables = Exact<{
    privateCredits: Scalars['Int']['input'];
    groupCredits: Scalars['Int']['input'];
    currency: Currency;
}>;

export type CreateExtraCreditPurchaseMutation = {
    readonly __typename?: 'Mutation';
    readonly purchase: {
        readonly __typename?: 'ExtraCreditPurchase';
        readonly id: string;
        readonly type: PurchaseType;
        readonly displayName: string;
        readonly privateCredits: number;
        readonly groupCredits: number;
        readonly isSubscription: boolean;
        readonly canUseCoupon: boolean;
        readonly contentBlocks: ReadonlyArray<{
            readonly __typename?: 'ContentBlock';
            readonly location: Location;
            readonly label: string | null;
            readonly text: string;
            readonly icon: Icon | null;
            readonly isStarred: boolean;
            readonly table: string[][] | null;
        }>;
        readonly coupons: ReadonlyArray<{
            readonly __typename?: 'Coupon';
            readonly id: number;
            readonly code: string;
        }>;
        readonly total: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly subtotal: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly chargeTotal: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly discount: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly agreements: ReadonlyArray<{
            readonly __typename?: 'Agreement';
            readonly id: string;
            readonly acceptanceText: string;
            readonly pdfLink: string | null;
        }>;
        readonly validityInterval: {
            readonly __typename?: 'DateInterval';
            readonly years: number;
            readonly months: number;
            readonly weeks: number;
            readonly days: number;
            readonly hours: number;
            readonly minutes: number;
            readonly seconds: number;
            readonly microseconds: number;
            readonly forHumans: string;
        };
        readonly lastTransaction: {
            readonly __typename?: 'Transaction';
            readonly id: number;
            readonly errorCode: string | null;
            readonly errorMessage: string | null;
            readonly paymentStatus: PaymentStatus;
            readonly isInitialPurchase: boolean;
            readonly isPaymentMethodUpdate: boolean;
            readonly currency: string;
            readonly discount: {
                readonly __typename?: 'Price';
                readonly currency: Currency;
                readonly amount: number;
                readonly amountInMajor: number;
                readonly forHumans: string;
            };
            readonly amount: {
                readonly __typename?: 'Price';
                readonly currency: Currency;
                readonly amount: number;
                readonly amountInMajor: number;
                readonly forHumans: string;
            };
        } | null;
    };
};

export type CreateLegacyPurchaseMutationVariables = Exact<{
    productId: Scalars['Int']['input'];
    currency: Currency;
    couponCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateLegacyPurchaseMutation = {
    readonly __typename?: 'Mutation';
    readonly purchase: {
        readonly __typename?: 'LegacyPurchase';
        readonly id: string;
        readonly type: PurchaseType;
        readonly displayName: string;
        readonly privateCredits: number;
        readonly groupCredits: number;
        readonly isSubscription: boolean;
        readonly canUseCoupon: boolean;
        readonly productDescription: string | null;
        readonly withTrial: boolean;
        readonly contentBlocks: ReadonlyArray<{
            readonly __typename?: 'ContentBlock';
            readonly location: Location;
            readonly label: string | null;
            readonly text: string;
            readonly icon: Icon | null;
            readonly isStarred: boolean;
            readonly table: string[][] | null;
        }>;
        readonly coupons: ReadonlyArray<{
            readonly __typename?: 'Coupon';
            readonly id: number;
            readonly code: string;
        }>;
        readonly total: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly subtotal: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly chargeTotal: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly discount: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly agreements: ReadonlyArray<{
            readonly __typename?: 'Agreement';
            readonly id: string;
            readonly acceptanceText: string;
            readonly pdfLink: string | null;
        }>;
        readonly validityInterval: {
            readonly __typename?: 'DateInterval';
            readonly years: number;
            readonly months: number;
            readonly weeks: number;
            readonly days: number;
            readonly hours: number;
            readonly minutes: number;
            readonly seconds: number;
            readonly microseconds: number;
            readonly forHumans: string;
        };
        readonly lastTransaction: {
            readonly __typename?: 'Transaction';
            readonly id: number;
            readonly errorCode: string | null;
            readonly errorMessage: string | null;
            readonly paymentStatus: PaymentStatus;
            readonly isInitialPurchase: boolean;
            readonly isPaymentMethodUpdate: boolean;
            readonly currency: string;
            readonly discount: {
                readonly __typename?: 'Price';
                readonly currency: Currency;
                readonly amount: number;
                readonly amountInMajor: number;
                readonly forHumans: string;
            };
            readonly amount: {
                readonly __typename?: 'Price';
                readonly currency: Currency;
                readonly amount: number;
                readonly amountInMajor: number;
                readonly forHumans: string;
            };
        } | null;
        readonly rollover: {
            readonly __typename?: 'LegacyRolloverData';
            readonly productId: number;
            readonly trialInterval: {
                readonly __typename?: 'DateInterval';
                readonly years: number;
                readonly months: number;
                readonly weeks: number;
                readonly days: number;
                readonly hours: number;
                readonly minutes: number;
                readonly seconds: number;
                readonly microseconds: number;
                readonly forHumans: string;
            };
            readonly recurrenceInterval: {
                readonly __typename?: 'DateInterval';
                readonly years: number;
                readonly months: number;
                readonly weeks: number;
                readonly days: number;
                readonly hours: number;
                readonly minutes: number;
                readonly seconds: number;
                readonly microseconds: number;
                readonly forHumans: string;
            } | null;
            readonly subTotal: {
                readonly __typename?: 'Price';
                readonly currency: Currency;
                readonly amount: number;
                readonly amountInMajor: number;
                readonly forHumans: string;
            };
            readonly total: {
                readonly __typename?: 'Price';
                readonly currency: Currency;
                readonly amount: number;
                readonly amountInMajor: number;
                readonly forHumans: string;
            };
        } | null;
        readonly recurrenceInterval: {
            readonly __typename?: 'DateInterval';
            readonly years: number;
            readonly months: number;
            readonly weeks: number;
            readonly days: number;
            readonly hours: number;
            readonly minutes: number;
            readonly seconds: number;
            readonly microseconds: number;
            readonly forHumans: string;
        } | null;
    };
};

export type CreatePackagePurchaseMutationVariables = Exact<{
    packageId: Scalars['Ulid']['input'];
    currency: Currency;
    withTrial: Scalars['Boolean']['input'];
    couponCode?: InputMaybe<Scalars['String']['input']>;
    options?: InputMaybe<PurchaseOptionsInput>;
}>;

export type CreatePackagePurchaseMutation = {
    readonly __typename?: 'Mutation';
    readonly purchase:
        | {
              readonly __typename?: 'ExtraCreditPurchase';
              readonly id: string;
              readonly type: PurchaseType;
              readonly agreements: ReadonlyArray<{
                  readonly __typename?: 'Agreement';
                  readonly id: string;
              }>;
          }
        | {
              readonly __typename?: 'LegacyPurchase';
              readonly id: string;
              readonly type: PurchaseType;
              readonly agreements: ReadonlyArray<{
                  readonly __typename?: 'Agreement';
                  readonly id: string;
              }>;
          }
        | {
              readonly __typename?: 'SubscriptionPurchase';
              readonly id: string;
              readonly type: PurchaseType;
              readonly agreements: ReadonlyArray<{
                  readonly __typename?: 'Agreement';
                  readonly id: string;
              }>;
          };
};

export type DismissStudentNotificationMutationVariables = Exact<{
    studentNotificationId: Scalars['Ulid']['input'];
}>;

export type DismissStudentNotificationMutation = {
    readonly __typename?: 'Mutation';
    readonly dismissStudentNotification: boolean;
};

export type ReviewMachineNextMutationVariables = Exact<{ [key: string]: never }>;

export type ReviewMachineNextMutation = {
    readonly __typename?: 'Mutation';
    readonly reviewMachineNext: ReviewMachineProvider | null;
};

export type ReviewMachineReviewedMutationVariables = Exact<{
    provider: ReviewMachineProvider;
}>;

export type ReviewMachineReviewedMutation = {
    readonly __typename?: 'Mutation';
    readonly reviewMachineReviewed: boolean;
};

export type SubmitExerciseScoreMutationVariables = Exact<{
    exerciseId: Scalars['Int']['input'];
    score: Scalars['Float']['input'];
    lessonId: Scalars['Int']['input'];
    type: ExerciseType;
}>;

export type SubmitExerciseScoreMutation = {
    readonly __typename?: 'Mutation';
    readonly submitExerciseScore: {
        readonly __typename?: 'PracticeItem';
        readonly exercises: ReadonlyArray<{
            readonly __typename?: 'H5PExercise';
            readonly id: string;
            readonly type: ExerciseType | null;
            readonly url: string;
            readonly score: number | null;
        }>;
        readonly lesson: { readonly __typename?: 'PracticeItemLesson'; readonly id: number };
    };
};

export type UpdateExtraCreditPurchaseMutationVariables = Exact<{
    id: Scalars['String']['input'];
    privateCredits?: InputMaybe<Scalars['Int']['input']>;
    groupCredits?: InputMaybe<Scalars['Int']['input']>;
    currency?: InputMaybe<Currency>;
}>;

export type UpdateExtraCreditPurchaseMutation = {
    readonly __typename?: 'Mutation';
    readonly purchase: {
        readonly __typename?: 'ExtraCreditPurchase';
        readonly id: string;
        readonly type: PurchaseType;
        readonly displayName: string;
        readonly privateCredits: number;
        readonly groupCredits: number;
        readonly isSubscription: boolean;
        readonly canUseCoupon: boolean;
        readonly contentBlocks: ReadonlyArray<{
            readonly __typename?: 'ContentBlock';
            readonly location: Location;
            readonly label: string | null;
            readonly text: string;
            readonly icon: Icon | null;
            readonly isStarred: boolean;
            readonly table: string[][] | null;
        }>;
        readonly coupons: ReadonlyArray<{
            readonly __typename?: 'Coupon';
            readonly id: number;
            readonly code: string;
        }>;
        readonly total: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly subtotal: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly chargeTotal: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly discount: {
            readonly __typename?: 'Price';
            readonly currency: Currency;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly forHumans: string;
        };
        readonly agreements: ReadonlyArray<{
            readonly __typename?: 'Agreement';
            readonly id: string;
            readonly acceptanceText: string;
            readonly pdfLink: string | null;
        }>;
        readonly validityInterval: {
            readonly __typename?: 'DateInterval';
            readonly years: number;
            readonly months: number;
            readonly weeks: number;
            readonly days: number;
            readonly hours: number;
            readonly minutes: number;
            readonly seconds: number;
            readonly microseconds: number;
            readonly forHumans: string;
        };
        readonly lastTransaction: {
            readonly __typename?: 'Transaction';
            readonly id: number;
            readonly errorCode: string | null;
            readonly errorMessage: string | null;
            readonly paymentStatus: PaymentStatus;
            readonly isInitialPurchase: boolean;
            readonly isPaymentMethodUpdate: boolean;
            readonly currency: string;
            readonly discount: {
                readonly __typename?: 'Price';
                readonly currency: Currency;
                readonly amount: number;
                readonly amountInMajor: number;
                readonly forHumans: string;
            };
            readonly amount: {
                readonly __typename?: 'Price';
                readonly currency: Currency;
                readonly amount: number;
                readonly amountInMajor: number;
                readonly forHumans: string;
            };
        } | null;
    };
};

export type UpdatePackagePurchaseMutationVariables = Exact<{
    id: Scalars['String']['input'];
    currency?: InputMaybe<Currency>;
    couponCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdatePackagePurchaseMutation = {
    readonly __typename?: 'Mutation';
    readonly updatePurchase:
        | {
              readonly __typename?: 'ExtraCreditPurchase';
              readonly id: string;
              readonly type: PurchaseType;
              readonly displayName: string;
              readonly privateCredits: number;
              readonly groupCredits: number;
              readonly isSubscription: boolean;
              readonly canUseCoupon: boolean;
              readonly contentBlocks: ReadonlyArray<{
                  readonly __typename?: 'ContentBlock';
                  readonly location: Location;
                  readonly label: string | null;
                  readonly text: string;
                  readonly icon: Icon | null;
                  readonly isStarred: boolean;
                  readonly table: string[][] | null;
              }>;
              readonly coupons: ReadonlyArray<{
                  readonly __typename?: 'Coupon';
                  readonly id: number;
                  readonly code: string;
              }>;
              readonly total: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly subtotal: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly chargeTotal: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly discount: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly agreements: ReadonlyArray<{
                  readonly __typename?: 'Agreement';
                  readonly id: string;
                  readonly acceptanceText: string;
                  readonly pdfLink: string | null;
              }>;
              readonly validityInterval: {
                  readonly __typename?: 'DateInterval';
                  readonly years: number;
                  readonly months: number;
                  readonly weeks: number;
                  readonly days: number;
                  readonly hours: number;
                  readonly minutes: number;
                  readonly seconds: number;
                  readonly microseconds: number;
                  readonly forHumans: string;
              };
              readonly lastTransaction: {
                  readonly __typename?: 'Transaction';
                  readonly id: number;
                  readonly errorCode: string | null;
                  readonly errorMessage: string | null;
                  readonly paymentStatus: PaymentStatus;
                  readonly isInitialPurchase: boolean;
                  readonly isPaymentMethodUpdate: boolean;
                  readonly currency: string;
                  readonly discount: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
                  readonly amount: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
              } | null;
          }
        | {
              readonly __typename?: 'LegacyPurchase';
              readonly id: string;
              readonly type: PurchaseType;
              readonly displayName: string;
              readonly privateCredits: number;
              readonly groupCredits: number;
              readonly isSubscription: boolean;
              readonly canUseCoupon: boolean;
              readonly productDescription: string | null;
              readonly withTrial: boolean;
              readonly contentBlocks: ReadonlyArray<{
                  readonly __typename?: 'ContentBlock';
                  readonly location: Location;
                  readonly label: string | null;
                  readonly text: string;
                  readonly icon: Icon | null;
                  readonly isStarred: boolean;
                  readonly table: string[][] | null;
              }>;
              readonly coupons: ReadonlyArray<{
                  readonly __typename?: 'Coupon';
                  readonly id: number;
                  readonly code: string;
              }>;
              readonly total: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly subtotal: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly chargeTotal: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly discount: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly agreements: ReadonlyArray<{
                  readonly __typename?: 'Agreement';
                  readonly id: string;
                  readonly acceptanceText: string;
                  readonly pdfLink: string | null;
              }>;
              readonly validityInterval: {
                  readonly __typename?: 'DateInterval';
                  readonly years: number;
                  readonly months: number;
                  readonly weeks: number;
                  readonly days: number;
                  readonly hours: number;
                  readonly minutes: number;
                  readonly seconds: number;
                  readonly microseconds: number;
                  readonly forHumans: string;
              };
              readonly lastTransaction: {
                  readonly __typename?: 'Transaction';
                  readonly id: number;
                  readonly errorCode: string | null;
                  readonly errorMessage: string | null;
                  readonly paymentStatus: PaymentStatus;
                  readonly isInitialPurchase: boolean;
                  readonly isPaymentMethodUpdate: boolean;
                  readonly currency: string;
                  readonly discount: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
                  readonly amount: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
              } | null;
              readonly rollover: {
                  readonly __typename?: 'LegacyRolloverData';
                  readonly productId: number;
                  readonly trialInterval: {
                      readonly __typename?: 'DateInterval';
                      readonly years: number;
                      readonly months: number;
                      readonly weeks: number;
                      readonly days: number;
                      readonly hours: number;
                      readonly minutes: number;
                      readonly seconds: number;
                      readonly microseconds: number;
                      readonly forHumans: string;
                  };
                  readonly recurrenceInterval: {
                      readonly __typename?: 'DateInterval';
                      readonly years: number;
                      readonly months: number;
                      readonly weeks: number;
                      readonly days: number;
                      readonly hours: number;
                      readonly minutes: number;
                      readonly seconds: number;
                      readonly microseconds: number;
                      readonly forHumans: string;
                  } | null;
                  readonly subTotal: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
                  readonly total: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
              } | null;
              readonly recurrenceInterval: {
                  readonly __typename?: 'DateInterval';
                  readonly years: number;
                  readonly months: number;
                  readonly weeks: number;
                  readonly days: number;
                  readonly hours: number;
                  readonly minutes: number;
                  readonly seconds: number;
                  readonly microseconds: number;
                  readonly forHumans: string;
              } | null;
          }
        | {
              readonly __typename?: 'SubscriptionPurchase';
              readonly id: string;
              readonly type: PurchaseType;
              readonly displayName: string;
              readonly privateCredits: number;
              readonly groupCredits: number;
              readonly isSubscription: boolean;
              readonly canUseCoupon: boolean;
              readonly withTrial: boolean;
              readonly contentBlocks: ReadonlyArray<{
                  readonly __typename?: 'ContentBlock';
                  readonly location: Location;
                  readonly label: string | null;
                  readonly text: string;
                  readonly icon: Icon | null;
                  readonly isStarred: boolean;
                  readonly table: string[][] | null;
              }>;
              readonly coupons: ReadonlyArray<{
                  readonly __typename?: 'Coupon';
                  readonly id: number;
                  readonly code: string;
              }>;
              readonly total: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly subtotal: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly chargeTotal: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly discount: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly agreements: ReadonlyArray<{
                  readonly __typename?: 'Agreement';
                  readonly id: string;
                  readonly acceptanceText: string;
                  readonly pdfLink: string | null;
              }>;
              readonly validityInterval: {
                  readonly __typename?: 'DateInterval';
                  readonly years: number;
                  readonly months: number;
                  readonly weeks: number;
                  readonly days: number;
                  readonly hours: number;
                  readonly minutes: number;
                  readonly seconds: number;
                  readonly microseconds: number;
                  readonly forHumans: string;
              };
              readonly lastTransaction: {
                  readonly __typename?: 'Transaction';
                  readonly id: number;
                  readonly errorCode: string | null;
                  readonly errorMessage: string | null;
                  readonly paymentStatus: PaymentStatus;
                  readonly isInitialPurchase: boolean;
                  readonly isPaymentMethodUpdate: boolean;
                  readonly currency: string;
                  readonly discount: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
                  readonly amount: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
              } | null;
              readonly recurrenceInterval: {
                  readonly __typename?: 'DateInterval';
                  readonly years: number;
                  readonly months: number;
                  readonly weeks: number;
                  readonly days: number;
                  readonly hours: number;
                  readonly minutes: number;
                  readonly seconds: number;
                  readonly microseconds: number;
                  readonly forHumans: string;
              } | null;
              readonly newSection: {
                  readonly __typename?: 'Section';
                  readonly name: SectionName;
              } | null;
              readonly curriculum: {
                  readonly __typename?: 'Curriculum';
                  readonly id: number;
              } | null;
          };
};

export type UpdateUserLanguageAndSubscriptionMutationVariables = Exact<{
    agreements: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input'];
    purchaseId: Scalars['String']['input'];
    newSectionName: Scalars['SectionName']['input'];
    newCurriculumId: Scalars['Int']['input'];
    couponCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateUserLanguageAndSubscriptionMutation = {
    readonly __typename?: 'Mutation';
    readonly updateUserLanguageAndSubscription: {
        readonly __typename?: 'Subscription';
        readonly id: number;
        readonly status: SubscriptionStatus;
        readonly expiresAt: string | null;
    };
};

export type ClassroomUrlQueryVariables = Exact<{
    classId: Scalars['String']['input'];
}>;

export type ClassroomUrlQuery = { readonly __typename?: 'Query'; readonly classroomUrl: string };

export type ConfigurationQueryVariables = Exact<{ [key: string]: never }>;

export type ConfigurationQuery = {
    readonly __typename?: 'Query';
    readonly config: {
        readonly __typename?: 'SystemConfig';
        readonly languages: Record<string, string>;
        readonly currencies: ReadonlyArray<Currency>;
        readonly minimumCharges: Record<string, unknown>;
        readonly classConfig: {
            readonly __typename?: 'ClassConfig';
            readonly refundableAfterBookingForMinutes: number;
            readonly refundableDaysBeforeStart: number;
            readonly studentCancelLateHours: number;
            readonly classStartTimePoint: number;
            readonly classDefaultDurationMinutes: number;
        };
        readonly timezones: ReadonlyArray<{
            readonly __typename?: 'Timezone';
            readonly label: string;
            readonly tz: string;
        }>;
    };
};

export type CountriesQueryVariables = Exact<{ [key: string]: never }>;

export type CountriesQuery = {
    readonly __typename?: 'Query';
    readonly countries: ReadonlyArray<{
        readonly __typename?: 'Country';
        readonly code: string;
        readonly commonName: string;
    }>;
};

export type LessonQueryVariables = Exact<{
    id: Scalars['Int']['input'];
}>;

export type LessonQuery = {
    readonly __typename?: 'Query';
    readonly lesson: {
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly type: LessonType | null;
        readonly label: string;
        readonly title: string | null;
        readonly description: string | null;
        readonly learningOutcomes: ReadonlyArray<string> | null;
        readonly learningOutcome: string | null;
    };
};

export type OwnReferralQueryVariables = Exact<{ [key: string]: never }>;

export type OwnReferralQuery = {
    readonly __typename?: 'Query';
    readonly ownReferral: {
        readonly __typename?: 'OwnReferral';
        readonly code: string;
        readonly ownerGroupCredits: number;
        readonly userDiscount: ReadonlyArray<{
            readonly __typename?: 'Price';
            readonly amountInMajor: number;
            readonly currency: Currency;
            readonly amount: number;
        }>;
    } | null;
};

export type PackageFragment = {
    readonly __typename?: 'Variant';
    readonly id: string | null;
    readonly isCurrent: boolean;
    readonly recurringSpecialType: RecurringSpecialType | null;
    readonly classType: ClassType;
    readonly groupCredits: number;
    readonly privateCredits: number;
    readonly type: PurchaseType;
    readonly sectionCurriculum: {
        readonly __typename?: 'SectionCurriculum';
        readonly slug: string;
        readonly section: {
            readonly __typename?: 'Section';
            readonly id: number;
            readonly name: SectionName;
        };
        readonly curriculum: {
            readonly __typename?: 'Curriculum';
            readonly id: number;
            readonly name: string;
            readonly default: boolean;
        };
    } | null;
    readonly contentBlocks: ReadonlyArray<{
        readonly __typename?: 'ContentBlock';
        readonly table: string[][] | null;
        readonly text: string;
        readonly label: string | null;
        readonly location: Location;
        readonly icon: Icon | null;
        readonly isStarred: boolean;
    }>;
    readonly curriculum: {
        readonly __typename?: 'Curriculum';
        readonly id: number;
        readonly name: string;
    } | null;
    readonly subTotal: {
        readonly __typename?: 'Price';
        readonly forHumans: string;
        readonly amount: number;
        readonly amountInMajor: number;
        readonly currency: Currency;
    };
};

export type PackagesQueryVariables = Exact<{
    sectionName: Scalars['SectionName']['input'];
    currency: Currency;
    store: Store;
    ignoreSectionValidation?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type PackagesQuery = {
    readonly __typename?: 'Query';
    readonly packages: ReadonlyArray<{
        readonly __typename?: 'Variant';
        readonly id: string | null;
        readonly isCurrent: boolean;
        readonly recurringSpecialType: RecurringSpecialType | null;
        readonly classType: ClassType;
        readonly groupCredits: number;
        readonly privateCredits: number;
        readonly type: PurchaseType;
        readonly sectionCurriculum: {
            readonly __typename?: 'SectionCurriculum';
            readonly slug: string;
            readonly section: {
                readonly __typename?: 'Section';
                readonly id: number;
                readonly name: SectionName;
            };
            readonly curriculum: {
                readonly __typename?: 'Curriculum';
                readonly id: number;
                readonly name: string;
                readonly default: boolean;
            };
        } | null;
        readonly contentBlocks: ReadonlyArray<{
            readonly __typename?: 'ContentBlock';
            readonly table: string[][] | null;
            readonly text: string;
            readonly label: string | null;
            readonly location: Location;
            readonly icon: Icon | null;
            readonly isStarred: boolean;
        }>;
        readonly curriculum: {
            readonly __typename?: 'Curriculum';
            readonly id: number;
            readonly name: string;
        } | null;
        readonly subTotal: {
            readonly __typename?: 'Price';
            readonly forHumans: string;
            readonly amount: number;
            readonly amountInMajor: number;
            readonly currency: Currency;
        };
    }>;
};

export type PurchaseQueryVariables = Exact<{
    purchaseId: Scalars['String']['input'];
}>;

export type PurchaseQuery = {
    readonly __typename?: 'Query';
    readonly purchase:
        | {
              readonly __typename?: 'ExtraCreditPurchase';
              readonly id: string;
              readonly type: PurchaseType;
              readonly displayName: string;
              readonly privateCredits: number;
              readonly groupCredits: number;
              readonly isSubscription: boolean;
              readonly canUseCoupon: boolean;
              readonly contentBlocks: ReadonlyArray<{
                  readonly __typename?: 'ContentBlock';
                  readonly location: Location;
                  readonly label: string | null;
                  readonly text: string;
                  readonly icon: Icon | null;
                  readonly isStarred: boolean;
                  readonly table: string[][] | null;
              }>;
              readonly coupons: ReadonlyArray<{
                  readonly __typename?: 'Coupon';
                  readonly id: number;
                  readonly code: string;
              }>;
              readonly total: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly subtotal: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly chargeTotal: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly discount: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly agreements: ReadonlyArray<{
                  readonly __typename?: 'Agreement';
                  readonly id: string;
                  readonly acceptanceText: string;
                  readonly pdfLink: string | null;
              }>;
              readonly validityInterval: {
                  readonly __typename?: 'DateInterval';
                  readonly years: number;
                  readonly months: number;
                  readonly weeks: number;
                  readonly days: number;
                  readonly hours: number;
                  readonly minutes: number;
                  readonly seconds: number;
                  readonly microseconds: number;
                  readonly forHumans: string;
              };
              readonly lastTransaction: {
                  readonly __typename?: 'Transaction';
                  readonly id: number;
                  readonly errorCode: string | null;
                  readonly errorMessage: string | null;
                  readonly paymentStatus: PaymentStatus;
                  readonly isInitialPurchase: boolean;
                  readonly isPaymentMethodUpdate: boolean;
                  readonly currency: string;
                  readonly discount: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
                  readonly amount: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
              } | null;
          }
        | {
              readonly __typename?: 'LegacyPurchase';
              readonly id: string;
              readonly type: PurchaseType;
              readonly displayName: string;
              readonly privateCredits: number;
              readonly groupCredits: number;
              readonly isSubscription: boolean;
              readonly canUseCoupon: boolean;
              readonly productDescription: string | null;
              readonly withTrial: boolean;
              readonly contentBlocks: ReadonlyArray<{
                  readonly __typename?: 'ContentBlock';
                  readonly location: Location;
                  readonly label: string | null;
                  readonly text: string;
                  readonly icon: Icon | null;
                  readonly isStarred: boolean;
                  readonly table: string[][] | null;
              }>;
              readonly coupons: ReadonlyArray<{
                  readonly __typename?: 'Coupon';
                  readonly id: number;
                  readonly code: string;
              }>;
              readonly total: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly subtotal: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly chargeTotal: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly discount: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly agreements: ReadonlyArray<{
                  readonly __typename?: 'Agreement';
                  readonly id: string;
                  readonly acceptanceText: string;
                  readonly pdfLink: string | null;
              }>;
              readonly validityInterval: {
                  readonly __typename?: 'DateInterval';
                  readonly years: number;
                  readonly months: number;
                  readonly weeks: number;
                  readonly days: number;
                  readonly hours: number;
                  readonly minutes: number;
                  readonly seconds: number;
                  readonly microseconds: number;
                  readonly forHumans: string;
              };
              readonly lastTransaction: {
                  readonly __typename?: 'Transaction';
                  readonly id: number;
                  readonly errorCode: string | null;
                  readonly errorMessage: string | null;
                  readonly paymentStatus: PaymentStatus;
                  readonly isInitialPurchase: boolean;
                  readonly isPaymentMethodUpdate: boolean;
                  readonly currency: string;
                  readonly discount: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
                  readonly amount: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
              } | null;
              readonly rollover: {
                  readonly __typename?: 'LegacyRolloverData';
                  readonly productId: number;
                  readonly trialInterval: {
                      readonly __typename?: 'DateInterval';
                      readonly years: number;
                      readonly months: number;
                      readonly weeks: number;
                      readonly days: number;
                      readonly hours: number;
                      readonly minutes: number;
                      readonly seconds: number;
                      readonly microseconds: number;
                      readonly forHumans: string;
                  };
                  readonly recurrenceInterval: {
                      readonly __typename?: 'DateInterval';
                      readonly years: number;
                      readonly months: number;
                      readonly weeks: number;
                      readonly days: number;
                      readonly hours: number;
                      readonly minutes: number;
                      readonly seconds: number;
                      readonly microseconds: number;
                      readonly forHumans: string;
                  } | null;
                  readonly subTotal: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
                  readonly total: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
              } | null;
              readonly recurrenceInterval: {
                  readonly __typename?: 'DateInterval';
                  readonly years: number;
                  readonly months: number;
                  readonly weeks: number;
                  readonly days: number;
                  readonly hours: number;
                  readonly minutes: number;
                  readonly seconds: number;
                  readonly microseconds: number;
                  readonly forHumans: string;
              } | null;
          }
        | {
              readonly __typename?: 'SubscriptionPurchase';
              readonly id: string;
              readonly type: PurchaseType;
              readonly displayName: string;
              readonly privateCredits: number;
              readonly groupCredits: number;
              readonly isSubscription: boolean;
              readonly canUseCoupon: boolean;
              readonly withTrial: boolean;
              readonly contentBlocks: ReadonlyArray<{
                  readonly __typename?: 'ContentBlock';
                  readonly location: Location;
                  readonly label: string | null;
                  readonly text: string;
                  readonly icon: Icon | null;
                  readonly isStarred: boolean;
                  readonly table: string[][] | null;
              }>;
              readonly coupons: ReadonlyArray<{
                  readonly __typename?: 'Coupon';
                  readonly id: number;
                  readonly code: string;
              }>;
              readonly total: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly subtotal: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly chargeTotal: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly discount: {
                  readonly __typename?: 'Price';
                  readonly currency: Currency;
                  readonly amount: number;
                  readonly amountInMajor: number;
                  readonly forHumans: string;
              };
              readonly agreements: ReadonlyArray<{
                  readonly __typename?: 'Agreement';
                  readonly id: string;
                  readonly acceptanceText: string;
                  readonly pdfLink: string | null;
              }>;
              readonly validityInterval: {
                  readonly __typename?: 'DateInterval';
                  readonly years: number;
                  readonly months: number;
                  readonly weeks: number;
                  readonly days: number;
                  readonly hours: number;
                  readonly minutes: number;
                  readonly seconds: number;
                  readonly microseconds: number;
                  readonly forHumans: string;
              };
              readonly lastTransaction: {
                  readonly __typename?: 'Transaction';
                  readonly id: number;
                  readonly errorCode: string | null;
                  readonly errorMessage: string | null;
                  readonly paymentStatus: PaymentStatus;
                  readonly isInitialPurchase: boolean;
                  readonly isPaymentMethodUpdate: boolean;
                  readonly currency: string;
                  readonly discount: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
                  readonly amount: {
                      readonly __typename?: 'Price';
                      readonly currency: Currency;
                      readonly amount: number;
                      readonly amountInMajor: number;
                      readonly forHumans: string;
                  };
              } | null;
              readonly recurrenceInterval: {
                  readonly __typename?: 'DateInterval';
                  readonly years: number;
                  readonly months: number;
                  readonly weeks: number;
                  readonly days: number;
                  readonly hours: number;
                  readonly minutes: number;
                  readonly seconds: number;
                  readonly microseconds: number;
                  readonly forHumans: string;
              } | null;
              readonly newSection: {
                  readonly __typename?: 'Section';
                  readonly name: SectionName;
              } | null;
              readonly curriculum: {
                  readonly __typename?: 'Curriculum';
                  readonly id: number;
              } | null;
          }
        | null;
    readonly student: { readonly __typename?: 'Student'; readonly onboarded: boolean };
};

export type ReferralByCodeQueryVariables = Exact<{
    code: Scalars['String']['input'];
}>;

export type ReferralByCodeQuery = {
    readonly __typename?: 'Query';
    readonly referralByCode: {
        readonly __typename?: 'OthersReferral';
        readonly ownerFirstName: string;
        readonly userDiscount: ReadonlyArray<{
            readonly __typename?: 'Price';
            readonly amount: number;
            readonly amountInMajor: number;
            readonly currency: Currency;
        }>;
        readonly ownerPhoto: {
            readonly __typename?: 'Image';
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly thumbnail: string };
        } | null;
    } | null;
};

export type UserCreditsQueryVariables = Exact<{ [key: string]: never }>;

export type UserCreditsQuery = {
    readonly __typename?: 'Query';
    readonly studentCreditsInfo: {
        readonly __typename?: 'StudentCreditsInfo';
        readonly canBuyExtraCredits: boolean;
        readonly groupCredits: number;
        readonly privateCredits: number;
        readonly contentBlocks: ReadonlyArray<{
            readonly __typename?: 'ContentBlock';
            readonly table: string[][] | null;
            readonly label: string | null;
            readonly location: Location;
            readonly icon: Icon | null;
            readonly text: string;
            readonly isStarred: boolean;
        }>;
    };
};

export type VocabularyItemsQueryVariables = Exact<{
    lessonIds: ReadonlyArray<Scalars['Int']['input']> | Scalars['Int']['input'];
    learningUnitId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type VocabularyItemsQuery = {
    readonly __typename?: 'Query';
    readonly vocabularyItems: ReadonlyArray<{
        readonly __typename?: 'VocabularyItem';
        readonly id: string;
        readonly title: string;
        readonly plural: string | null;
        readonly wordClass: VocabularyItemWordClass;
        readonly gender: GenderType | null;
        readonly sampleSentenceOne: string;
        readonly item: string | null;
        readonly isCompleted: boolean;
        readonly lessons: ReadonlyArray<{
            readonly __typename?: 'Lesson';
            readonly id: number;
            readonly title: string | null;
        }>;
        readonly audioUrls: {
            readonly __typename?: 'VocabularyItemAudio';
            readonly target: string;
            readonly translation: string;
        } | null;
    }>;
};

export type StudentCurrentSubscriptionTypeQueryVariables = Exact<{ [key: string]: never }>;

export type StudentCurrentSubscriptionTypeQuery = {
    readonly __typename?: 'Query';
    readonly subscriptions: {
        readonly __typename?: 'StudentSubscriptionInfo';
        readonly currentSubscription: {
            readonly __typename?: 'Subscription';
            readonly id: number;
            readonly type: SubscriptionType;
        } | null;
    };
};

export type StudentLearningTeamStatusQueryVariables = Exact<{ [key: string]: never }>;

export type StudentLearningTeamStatusQuery = {
    readonly __typename?: 'Query';
    readonly studentLearningTeamInfo: {
        readonly __typename?: 'StudentLearningTeam';
        readonly courseId: string;
        readonly status: LearningTeamStatus;
    };
};

export type LearningTeamsModuleQueryVariables = Exact<{ [key: string]: never }>;

export type LearningTeamsModuleQuery = {
    readonly __typename?: 'Query';
    readonly studentLearningTeamInfo: {
        readonly __typename?: 'StudentLearningTeam';
        readonly courseId: string;
        readonly modules: ReadonlyArray<{
            readonly __typename?: 'StudentModule';
            readonly id: number;
        }>;
    };
};

export type LearningTeamsCourseDateQueryVariables = Exact<{ [key: string]: never }>;

export type LearningTeamsCourseDateQuery = {
    readonly __typename?: 'Query';
    readonly studentLearningTeamInfo: {
        readonly __typename?: 'StudentLearningTeam';
        readonly courseId: string;
        readonly courseEndDate: string;
        readonly courseStartDate: string;
    };
};

export type PrivacyPolicyQueryVariables = Exact<{ [key: string]: never }>;

export type PrivacyPolicyQuery = {
    readonly __typename?: 'Query';
    readonly privacyPolicy: { readonly __typename?: 'PrivacyPolicy'; readonly body: string };
};

export type StudentNotificationFragment = {
    readonly __typename?: 'StudentNotification';
    readonly id: string;
    readonly createdAt: string;
    readonly contentKeys: ReadonlyArray<string>;
    readonly contentValues: ReadonlyArray<string>;
    readonly type: string;
};

export type StudentNotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type StudentNotificationsQuery = {
    readonly __typename?: 'Query';
    readonly studentNotification: ReadonlyArray<{
        readonly __typename?: 'StudentNotification';
        readonly id: string;
        readonly createdAt: string;
        readonly contentKeys: ReadonlyArray<string>;
        readonly contentValues: ReadonlyArray<string>;
        readonly type: string;
    }>;
};

export type OrientationModulesQueryVariables = Exact<{
    sectionName: Scalars['SectionName']['input'];
}>;

export type OrientationModulesQuery = {
    readonly __typename?: 'Query';
    readonly orientationModules: ReadonlyArray<string>;
};

export type OrientationLessonQueryVariables = Exact<{ [key: string]: never }>;

export type OrientationLessonQuery = {
    readonly __typename?: 'Query';
    readonly orientationLesson: {
        readonly __typename?: 'Lesson';
        readonly id: number;
        readonly studentStatus: StudentStatus;
    } | null;
};

export type SectionCurriculumFragment = {
    readonly __typename?: 'SectionCurriculum';
    readonly slug: string;
    readonly section: {
        readonly __typename?: 'Section';
        readonly id: number;
        readonly name: SectionName;
    };
    readonly curriculum: {
        readonly __typename?: 'Curriculum';
        readonly id: number;
        readonly name: string;
        readonly default: boolean;
    };
};

export type SectionCurriculaQueryVariables = Exact<{ [key: string]: never }>;

export type SectionCurriculaQuery = {
    readonly __typename?: 'Query';
    readonly sectionCurricula: ReadonlyArray<{
        readonly __typename?: 'SectionCurriculum';
        readonly slug: string;
        readonly section: {
            readonly __typename?: 'Section';
            readonly id: number;
            readonly name: SectionName;
        };
        readonly curriculum: {
            readonly __typename?: 'Curriculum';
            readonly id: number;
            readonly name: string;
            readonly default: boolean;
        };
    }>;
};

export type MarkWidgetAsDisplayedMutationVariables = Exact<{
    widgetClass: UserDisplayedWidgetClassEnum;
}>;

export type MarkWidgetAsDisplayedMutation = {
    readonly __typename?: 'Mutation';
    readonly markWidgetAsDisplayed: {
        readonly __typename?: 'MarkWidgetAsDisplayedResultType';
        readonly status: boolean;
    };
};

export type CurrentSprintFragment = {
    readonly __typename?: 'Sprint';
    readonly id: string;
    readonly name: string;
    readonly startDateInStudentTimezone: string;
    readonly durationMonths: number;
    readonly startDate: string;
};

export type CurrentStudentSprintQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentStudentSprintQuery = {
    readonly __typename?: 'Query';
    readonly currentStudentSprint: {
        readonly __typename?: 'Sprint';
        readonly id: string;
        readonly name: string;
        readonly startDateInStudentTimezone: string;
        readonly durationMonths: number;
        readonly startDate: string;
    } | null;
};

export type CompleteChecklistItemsMutationVariables = Exact<{
    slugs: ReadonlyArray<ChecklistItemSlug> | ChecklistItemSlug;
}>;

export type CompleteChecklistItemsMutation = {
    readonly __typename?: 'Mutation';
    readonly completeChecklistItems: ReadonlyArray<{
        readonly __typename?: 'ChecklistItem';
        readonly id: string;
        readonly isCompleted: boolean;
    }>;
};

export type SchedulePauseMutationVariables = Exact<{
    pauseStartTime: Scalars['DateTime']['input'];
    pauseEndTime: Scalars['DateTime']['input'];
}>;

export type SchedulePauseMutation = {
    readonly __typename?: 'Mutation';
    readonly schedulePause: boolean;
};

export type UpcomingAppointmentDatesQueryVariables = Exact<{ [key: string]: never }>;

export type UpcomingAppointmentDatesQuery = {
    readonly __typename?: 'Query';
    readonly upcomingAppointmentDates: ReadonlyArray<string>;
};

export type AppointmentsCountQueryVariables = Exact<{ [key: string]: never }>;

export type AppointmentsCountQuery = {
    readonly __typename?: 'Query';
    readonly upcomingAppointmentsCount: number;
    readonly pastAppointmentsCount: number;
};

export type TeacherUiEventDataQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherUiEventDataQuery = {
    readonly __typename?: 'Query';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly uid: string;
        readonly section: { readonly __typename?: 'Section'; readonly name: SectionName } | null;
    } | null;
};

export type ShouldDisplayClassroomPromoBannerTeacherQueryVariables = Exact<{
    [key: string]: never;
}>;

export type ShouldDisplayClassroomPromoBannerTeacherQuery = {
    readonly __typename?: 'Query';
    readonly shouldDisplayClassroomPromoBanner: boolean;
};

export type TeacherClassDetailsTileParticipantsStudentFragment = {
    readonly __typename?: 'Student';
    readonly id: number;
    readonly shortName: string;
    readonly photo: {
        readonly __typename?: 'Image';
        readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
    } | null;
};

export type TeacherClassDetailsTileDateKlassFragment = {
    readonly __typename?: 'Klass';
    readonly startDate: string;
    readonly endDate: string;
};

export type TeacherClassDetailsTileRemunerationKlassFragment = {
    readonly __typename?: 'Klass';
    readonly total: { readonly __typename?: 'Price'; readonly forHumans: string } | null;
};

export type TeacherClassDetailsTileHeaderTeacherCalendarEventDetailsFragment = {
    readonly __typename?: 'TeacherCalendarEventDetails';
    readonly classType: ClassType;
    readonly isB2G: boolean;
    readonly isBonus: boolean;
    readonly isLanguageClass: boolean;
    readonly classDuration: number;
    readonly module: string | null;
    readonly lesson: {
        readonly __typename?: 'Lesson';
        readonly label: string;
        readonly title: string | null;
    } | null;
};

export type TeacherClassDetailsTileDescriptionTeacherCalendarEventDetailsFragment = {
    readonly __typename?: 'TeacherCalendarEventDetails';
    readonly courseId: string | null;
    readonly lesson: {
        readonly __typename?: 'Lesson';
        readonly learningOutcomes: ReadonlyArray<string> | null;
        readonly description: string | null;
    } | null;
};

export type TeacherClassDetailsTileInfoTeacherCalendarEventDetailsFragment = {
    readonly __typename?: 'TeacherCalendarEventDetails';
    readonly previousClassLogsUrl: string | null;
    readonly students: ReadonlyArray<{
        readonly __typename?: 'Student';
        readonly id: number;
        readonly shortName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
        } | null;
    }>;
    readonly price: { readonly __typename?: 'Price'; readonly forHumans: string } | null;
};

export type TeacherClassDetailsTileTeacherCalendarEventDetailsFragment = {
    readonly __typename?: 'TeacherCalendarEventDetails';
    readonly classType: ClassType;
    readonly isB2G: boolean;
    readonly isBonus: boolean;
    readonly isLanguageClass: boolean;
    readonly classDuration: number;
    readonly module: string | null;
    readonly courseId: string | null;
    readonly previousClassLogsUrl: string | null;
    readonly lesson: {
        readonly __typename?: 'Lesson';
        readonly label: string;
        readonly title: string | null;
        readonly learningOutcomes: ReadonlyArray<string> | null;
        readonly description: string | null;
    } | null;
    readonly students: ReadonlyArray<{
        readonly __typename?: 'Student';
        readonly id: number;
        readonly shortName: string;
        readonly photo: {
            readonly __typename?: 'Image';
            readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
        } | null;
    }>;
    readonly price: { readonly __typename?: 'Price'; readonly forHumans: string } | null;
};

export type TeacherPauseTeacherFragment = {
    readonly __typename?: 'Teacher';
    readonly id: number;
    readonly pausedFrom: string | null;
    readonly pausedUntil: string | null;
};

export type TeacherSetPauseMutationVariables = Exact<{
    pauseFrom: Scalars['DateTime']['input'];
    pauseUntil: Scalars['DateTime']['input'];
}>;

export type TeacherSetPauseMutation = {
    readonly __typename?: 'Mutation';
    readonly pauseTeaching: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly pausedFrom: string | null;
        readonly pausedUntil: string | null;
    };
};

export type TeacherRemovePauseMutationVariables = Exact<{ [key: string]: never }>;

export type TeacherRemovePauseMutation = {
    readonly __typename?: 'Mutation';
    readonly removePauseTeaching: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly pausedFrom: string | null;
        readonly pausedUntil: string | null;
    };
};

export type TeacherGetPauseQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherGetPauseQuery = {
    readonly __typename?: 'Query';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly pausedFrom: string | null;
        readonly pausedUntil: string | null;
    } | null;
};

export type TeacherGetTeacherTimeFormatQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherGetTeacherTimeFormatQuery = {
    readonly __typename?: 'Query';
    readonly teacher: {
        readonly __typename?: 'Teacher';
        readonly id: number;
        readonly teacherPreferences: {
            readonly __typename?: 'TeacherPreference';
            readonly timeFormat: TimeFormat | null;
        };
    } | null;
};

export type TeacherAvatarFragment = {
    readonly __typename?: 'Teacher';
    readonly firstName: string;
    readonly photo: {
        readonly __typename?: 'Image';
        readonly urls: { readonly __typename?: 'ImageUrl'; readonly medium: string };
    } | null;
};

export type ConfigurationTimezonesFragment = {
    readonly __typename?: 'SystemConfig';
    readonly timezones: ReadonlyArray<{
        readonly __typename?: 'Timezone';
        readonly label: string;
        readonly tz: string;
    }>;
};

export const CourseOverviewLessonFragmentDoc = gql`
    fragment CourseOverviewLesson on Lesson {
        id
        availableWithoutPayment
        completedCurrentVersion
        label
        studentStatus
        title
        type
        lingobitesAvailable
        soonestBookedDate
        vocabularyItems {
            id
        }
        practiceSequenceScore
    }
`;
export const FlashCardsVocabularyItemFragmentDoc = gql`
    fragment FlashCardsVocabularyItem on VocabularyItem {
        id
        gender
        item
        sampleSentenceOne
        section {
            id
            name
        }
        title
        wordClass
        plural
        flashcardIsCompleted
    }
`;
export const FlashCardsLearningUnitFragmentDoc = gql`
    fragment FlashCardsLearningUnit on LearningUnit {
        id
        name
        chapter
        module {
            name
        }
        vocabularyItems {
            ...FlashCardsVocabularyItem
        }
    }
    ${FlashCardsVocabularyItemFragmentDoc}
`;
export const LearningUnitExerciseFragmentDoc = gql`
    fragment LearningUnitExercise on LearningUnit {
        id
        module {
            id
            name
        }
        lessons {
            id
            type
        }
        exercise {
            id
            link
            score
            title
        }
    }
`;
export const CourseOverviewLearningUnitFragmentDoc = gql`
    fragment CourseOverviewLearningUnit on LearningUnit {
        id
        chapter
        color
        icon
        name
        lastBookedDate
        lessons {
            ...CourseOverviewLesson
        }
        ...FlashCardsLearningUnit
        ...LearningUnitExercise
    }
    ${CourseOverviewLessonFragmentDoc}
    ${FlashCardsLearningUnitFragmentDoc}
    ${LearningUnitExerciseFragmentDoc}
`;
export const ExerciseDescriptionFragmentDoc = gql`
    fragment exerciseDescription on H5PExercise {
        id
        title
        type
        url
        score
    }
`;
export const PracticeItemHeaderFragmentDoc = gql`
    fragment practiceItemHeader on PracticeItem {
        exercises {
            ...exerciseDescription
        }
        lesson {
            id
            title
            number
        }
        learningUnit {
            chapter
        }
        module {
            id
            name
        }
    }
    ${ExerciseDescriptionFragmentDoc}
`;
export const GrammarItemFragmentDoc = gql`
    fragment GrammarItem on GrammarItem {
        id
        title
        content
        lesson {
            id
            title
        }
        isCompleted
    }
`;
export const ClassStateKlassFragmentDoc = gql`
    fragment classStateKlass on Klass {
        uid
        endDate
        startDate
    }
`;
export const TeacherParticipantPhotoFragmentDoc = gql`
    fragment teacherParticipantPhoto on Teacher {
        id
        uid
        firstName
        photo {
            urls {
                thumbnail
                medium
            }
        }
    }
`;
export const StudentParticipantPhotoFragmentDoc = gql`
    fragment studentParticipantPhoto on Student {
        id
        uid
        firstName
        photo {
            urls {
                thumbnail
                medium
            }
        }
    }
`;
export const ClassParticipantPhotosFragmentDoc = gql`
    fragment classParticipantPhotos on Klass {
        teacher {
            ...teacherParticipantPhoto
        }
        students {
            ...studentParticipantPhoto
        }
    }
    ${TeacherParticipantPhotoFragmentDoc}
    ${StudentParticipantPhotoFragmentDoc}
`;
export const ClassParticipantCompletedClassesFragmentDoc = gql`
    fragment classParticipantCompletedClasses on Klass {
        students {
            completedClasses
        }
    }
`;
export const ClassroomClassDetailsFragmentDoc = gql`
    fragment classroomClassDetails on Klass {
        ...classStateKlass
        ...classParticipantPhotos
        ...classParticipantCompletedClasses
        isPresentationMode
        type
        lesson {
            id
            title
            description
            learningOutcomes
        }
    }
    ${ClassStateKlassFragmentDoc}
    ${ClassParticipantPhotosFragmentDoc}
    ${ClassParticipantCompletedClassesFragmentDoc}
`;
export const BackgroundOptionsFragmentDoc = gql`
    fragment backgroundOptions on Query {
        backgroundOptions {
            assetId
            imageHd
            imageThumbnail
        }
    }
`;
export const ClassFeedbackFragmentDoc = gql`
    fragment ClassFeedback on Klass {
        uid
        classLog {
            logMessage
            classroomChatLogUrl
            hasClassroomChatLog
        }
        teacher {
            firstName
            photo {
                urls {
                    medium
                }
            }
        }
        lesson {
            id
            title
            description
        }
    }
`;
export const AppointmentLogFragmentDoc = gql`
    fragment AppointmentLog on AppointmentLog {
        feedback
        questions {
            id
            label
            question
            sentiment
            studentAnswers {
                id
                answer
            }
        }
        recommendedLevel {
            id
            name
        }
    }
`;
export const ExerciseFragmentDoc = gql`
    fragment exercise on H5PExercise {
        id
        title
        type
        url
        score
    }
`;
export const LearningUnitPracticeItemFragmentDoc = gql`
    fragment LearningUnitPracticeItem on PracticeItem {
        exercises @include(if: $includeH5PExercises) {
            ...exercise
        }
        quiz @client {
            ...exercise
        }
        homeworks @client {
            ...exercise
        }
        pendingHomeworks @client {
            ...exercise
        }
        lesson {
            id
        }
        grammarItems {
            id
            title
            isCompleted
        }
        vocabularyItems {
            id
            isCompleted
        }
    }
    ${ExerciseFragmentDoc}
`;
export const StudentClassAppointmentFragmentDoc = gql`
    fragment StudentClassAppointment on Appointment {
        id
        status
    }
`;
export const PracticeSectionKlassFragmentDoc = gql`
    fragment PracticeSectionKlass on Klass {
        learningUnit {
            id
        }
    }
`;
export const ClassDetailsTileClassRootFragmentDoc = gql`
    fragment ClassDetailsTileClassRoot on Klass {
        uid
        startDate
        endDate
        status
        type
        isExisting
        availableSeats
        isForNewClassroom
    }
`;
export const ClassDetailsTileAppointmentFragmentDoc = gql`
    fragment ClassDetailsTileAppointment on Appointment {
        id
        createdAt
        label
        status
        isRatedByStudent
        hasAttemptToJoin
    }
`;
export const ClassDetailsTileLessonFragmentDoc = gql`
    fragment ClassDetailsTileLesson on Lesson {
        id
    }
`;
export const ClassDetailsTileStudentFragmentDoc = gql`
    fragment ClassDetailsTileStudent on Student {
        id
        shortName
        photo {
            id
            urls {
                thumbnail
            }
        }
    }
`;
export const ClassDetailsTileTeacherFragmentDoc = gql`
    fragment ClassDetailsTileTeacher on Teacher {
        id
        uid
        firstName
        photo {
            id
            urls {
                medium
            }
        }
    }
`;
export const ClassDetailsTileClassFragmentDoc = gql`
    fragment ClassDetailsTileClass on Klass {
        ...ClassDetailsTileClassRoot
        appointment {
            ...ClassDetailsTileAppointment
        }
        lesson {
            ...ClassDetailsTileLesson
        }
        module {
            id
        }
        students {
            ...ClassDetailsTileStudent
        }
        teacher {
            ...ClassDetailsTileTeacher
        }
    }
    ${ClassDetailsTileClassRootFragmentDoc}
    ${ClassDetailsTileAppointmentFragmentDoc}
    ${ClassDetailsTileLessonFragmentDoc}
    ${ClassDetailsTileStudentFragmentDoc}
    ${ClassDetailsTileTeacherFragmentDoc}
`;
export const StudentClassResourcesFragmentDoc = gql`
    fragment StudentClassResources on Klass {
        annotatedPresentation
    }
`;
export const StudentClassFeedbackFragmentDoc = gql`
    fragment StudentClassFeedback on Klass {
        uid
        appointment {
            status
            isRatedByStudent
        }
    }
`;
export const StudentClassModuleFragmentDoc = gql`
    fragment StudentClassModule on Module {
        id
        name
    }
`;
export const StudentClassLessonFragmentDoc = gql`
    fragment StudentClassLesson on Lesson {
        id
        type
        title
        description
        learningOutcomes
        label
        presentationUrl
        module {
            id
        }
        studentLearningUnit {
            id
        }
        lingobitesAvailable
        practiceSequenceScore
    }
`;
export const StudentClassTeacherFragmentDoc = gql`
    fragment StudentClassTeacher on Teacher {
        id
        uid
        firstName
        photo {
            urls {
                medium
            }
        }
        teacherInfo {
            aboutMe
            languages {
                language
                fluency
            }
            country {
                code
                commonName
            }
        }
        section {
            name
        }
    }
`;
export const StudentClassKlassFragmentDoc = gql`
    fragment StudentClassKlass on Klass {
        uid
        appointment {
            ...StudentClassAppointment
        }
        ...PracticeSectionKlass
        ...ClassDetailsTileClass
        ...StudentClassResources
        ...StudentClassFeedback
        module {
            ...StudentClassModule
        }
        lesson {
            ...StudentClassLesson
            studentStatus
        }
        teacher {
            ...StudentClassTeacher
        }
        sourcePresentation
        availableSeats
        isForNewClassroom
        isPresentationMode
    }
    ${StudentClassAppointmentFragmentDoc}
    ${PracticeSectionKlassFragmentDoc}
    ${ClassDetailsTileClassFragmentDoc}
    ${StudentClassResourcesFragmentDoc}
    ${StudentClassFeedbackFragmentDoc}
    ${StudentClassModuleFragmentDoc}
    ${StudentClassLessonFragmentDoc}
    ${StudentClassTeacherFragmentDoc}
`;
export const StudentLessonLessonFragmentDoc = gql`
    fragment StudentLessonLesson on Lesson {
        id
        type
        description
        learningOutcomes
        module {
            id
            name
        }
        lingobitesAvailable
        practiceSequenceScore
        presentationUrl
        studentLearningUnit {
            id
        }
        title
        label
        studentStatus
    }
`;
export const LessonDetailsTileAppointmentFragmentDoc = gql`
    fragment LessonDetailsTileAppointment on Appointment {
        ...ClassDetailsTileAppointment
        class {
            ...ClassDetailsTileClassRoot
            annotatedPresentation
            sourcePresentation
            isPresentationMode
            lesson {
                ...ClassDetailsTileLesson
            }
            module {
                id
            }
            students {
                ...ClassDetailsTileStudent
            }
            teacher {
                ...ClassDetailsTileTeacher
            }
            appointment {
                id
            }
        }
    }
    ${ClassDetailsTileAppointmentFragmentDoc}
    ${ClassDetailsTileClassRootFragmentDoc}
    ${ClassDetailsTileLessonFragmentDoc}
    ${ClassDetailsTileStudentFragmentDoc}
    ${ClassDetailsTileTeacherFragmentDoc}
`;
export const TeacherPageProfileFragmentDoc = gql`
    fragment TeacherPageProfile on Teacher {
        id
        firstName
        photo {
            urls {
                medium
            }
        }
        teacherInfo {
            aboutMe
            country {
                code
                commonName
            }
            languages {
                language
                fluency
            }
        }
        canTeachPrivateClass
        canTeachGroupClass
        section {
            name
        }
    }
`;
export const HeaderFragmentDoc = gql`
    fragment Header on Teacher {
        id
        firstName
        photo {
            urls {
                thumbnail
            }
        }
    }
`;
export const CreatedAdminFragmentDoc = gql`
    fragment CreatedAdmin on CreateAdminResponse {
        admin {
            email
        }
    }
`;
export const CreatedStudentFragmentDoc = gql`
    fragment CreatedStudent on CreateTestStudentResponse {
        student {
            email
        }
        appointments {
            class {
                uid
                startDate
                status
                type
                lesson {
                    id
                }
            }
        }
        subscriptions {
            allowsPause
            allowsCancellation
            expirationDate
            lastChargeDate
            nextChargeDate
            resumingDate
            months
            id
            status
            price {
                forHumans
                amount
                amountInMajor
            }
            expired
        }
        credits {
            type
            amount
            expirationDate
            availabilityDate
        }
    }
`;
export const CreatedTeacherFragmentDoc = gql`
    fragment CreatedTeacher on CreateTeacherResponse {
        teacher {
            email
            id
        }
        classes {
            uid
        }
        invoices {
            id
        }
    }
`;
export const CancelQuestionnaireQuestionFragmentDoc = gql`
    fragment CancelQuestionnaireQuestion on QuestionnaireQuestion {
        id
        question
        description
        answers {
            id
            answer
        }
    }
`;
export const CancelQuestionnaireQuestionsFragmentDoc = gql`
    fragment CancelQuestionnaireQuestions on SprintCancelRolloverQuestionnaireQuestion {
        mood
        question {
            ...CancelQuestionnaireQuestion
        }
    }
    ${CancelQuestionnaireQuestionFragmentDoc}
`;
export const CancelSubscriptionDataFragmentDoc = gql`
    fragment CancelSubscriptionData on SprintCancelRolloverQuestionnaire {
        questions {
            ...CancelQuestionnaireQuestions
        }
    }
    ${CancelQuestionnaireQuestionsFragmentDoc}
`;
export const PriceFragmentDoc = gql`
    fragment price on Price {
        currency
        amount
        amountInMajor
        forHumans
    }
`;
export const UserSubscriptionFragmentDoc = gql`
    fragment UserSubscription on Subscription {
        id
        isInTrial
        activatedAt
        createdAt
        expiresAt
        status
        canChange
        canPause
        canUnpause
        pauseScheduled
        startDelayedUntil
        price {
            ...price
        }
        canCancel
        canUncancel
        canStartNextNow
        canReactivate
        canStartNew
        canCancelTrial
        isRecurring
        origin
        subscribeNow
        canSkipTrial
        type
        paymentMethodAdded
        contentBlocks {
            text
            location
            label
            icon
            table
            isStarred
        }
    }
    ${PriceFragmentDoc}
`;
export const LastTransactionFragmentDoc = gql`
    fragment LastTransaction on Transaction {
        id
        errorCode
        errorMessage
        paymentStatus
        isInitialPurchase
        isPaymentMethodUpdate
        currency
        discount {
            ...price
        }
        amount {
            ...price
        }
        coupons {
            id
            code
        }
    }
    ${PriceFragmentDoc}
`;
export const StudentWeeklyGoalFragmentDoc = gql`
    fragment StudentWeeklyGoal on StudentGoal {
        id
        active
        value
        createdAt
    }
`;
export const StudentGoalWidgetDataFragmentDoc = gql`
    fragment StudentGoalWidgetData on WidgetData {
        weeklyProgress {
            shortLabel
            longLabel
            attendance
            goal
        }
        firstAppointmentDate
        hasNext
        hasPrevious
        range
    }
`;
export const AchievementFragmentDoc = gql`
    fragment Achievement on Achievement {
        claimId
        name
        value
        currentProgress
        progressLabel
        achievedLabel
        rewardText
        congratulationsText
        achievedAt
        claimedAt
        claimed
        achieved
        id
        slug
    }
`;
export const BaseActivityChallengeParticipantFragmentDoc = gql`
    fragment BaseActivityChallengeParticipant on ActivityChallengeParticipant {
        id
        classesAttendedCount
        trainingSessionsCount
        learnedWordsCount
        completedActivities
        status
    }
`;
export const BaseActivityChallengeFragmentDoc = gql`
    fragment BaseActivityChallenge on ActivityChallenge {
        name
        publicName
        description
        overviewFirstParagraph
        overviewSecondParagraph
        desktopBackgroundTheme
        desktopBackgroundImage {
            urls {
                original
            }
        }
        mobileBackgroundTheme
        mobileBackgroundImage {
            urls {
                original
            }
        }
        challengeIcon {
            urls {
                original
            }
        }
        startDate
        endDate
        participants
        prizes {
            value
            noun
            nounTranslation
            explanation
            audioUrl
            prizeIcon {
                urls {
                    original
                }
            }
        }
        currentParticipant {
            ...BaseActivityChallengeParticipant
        }
    }
    ${BaseActivityChallengeParticipantFragmentDoc}
`;
export const ChallengeParticipantsFragmentDoc = gql`
    fragment ChallengeParticipants on ActivityChallenge {
        participants
    }
`;
export const UpdateChallengeAfterJoinFragmentDoc = gql`
    fragment UpdateChallengeAfterJoin on ActivityChallenge {
        participants
        currentParticipant {
            ...BaseActivityChallengeParticipant
        }
    }
    ${BaseActivityChallengeParticipantFragmentDoc}
`;
export const HeaderStudentModuleFragmentDoc = gql`
    fragment HeaderStudentModule on StudentModule {
        id
        code
        cefrLevelName
        name
        longName
        title
        longDescription
        chaptersCount
        completedChaptersCount
        totalClasses
        completedClasses
        totalLingobites
        completedLingobites
    }
`;
export const SelectorItemModuleFragmentDoc = gql`
    fragment SelectorItemModule on StudentModule {
        id
        name
        title
        cefrLevelName
        code
        longDescription
        chaptersCount
        completedChaptersCount
        completedClasses
    }
`;
export const ClassCardStudentFragmentDoc = gql`
    fragment ClassCardStudent on Student {
        id
        firstLetterOfFirstName
        photo {
            urls {
                medium
            }
        }
    }
`;
export const ClassCardTeacherFragmentDoc = gql`
    fragment ClassCardTeacher on Teacher {
        id
        firstName
        photo {
            urls {
                medium
            }
        }
    }
`;
export const ReviewClassCardAppointmentFragmentDoc = gql`
    fragment ReviewClassCardAppointment on Appointment {
        id
        label
        klass: class {
            uid
            startDate
            endDate
            type
            students {
                ...ClassCardStudent
            }
            lesson {
                title
                label
            }
            teacher {
                ...ClassCardTeacher
            }
        }
    }
    ${ClassCardStudentFragmentDoc}
    ${ClassCardTeacherFragmentDoc}
`;
export const UpcomingAppointmentCardFragmentDoc = gql`
    fragment UpcomingAppointmentCard on Appointment {
        id
        status
        label
        klass: class {
            uid
            startDate
            endDate
            status
            type
            students {
                ...ClassCardStudent
            }
            lesson {
                title
                description
                label
            }
            teacher {
                ...ClassCardTeacher
            }
            isForNewClassroom
        }
    }
    ${ClassCardStudentFragmentDoc}
    ${ClassCardTeacherFragmentDoc}
`;
export const YourTeamWidgetDataFragmentDoc = gql`
    fragment YourTeamWidgetData on StudentLearningTeam {
        status
        courseId
        timeSlots {
            dayOfWeek
            startHour
        }
        courseStartDate
        courseEndDate
        teachers {
            id
            firstName
            photo {
                urls {
                    thumbnail
                }
            }
        }
        participants {
            id
            shortName
            photo {
                urls {
                    thumbnail
                }
            }
        }
        timezone {
            tz
        }
    }
`;
export const TeacherAvatarFragmentDoc = gql`
    fragment TeacherAvatar on Teacher {
        firstName
        photo {
            urls {
                medium
            }
        }
    }
`;
export const OnboardingClassFragmentDoc = gql`
    fragment OnboardingClass on Klass {
        uid
        status
        type
        isExisting
        startDate
        endDate
        isForNewClassroom
        duration @client
        module {
            id
            name
        }
        lesson {
            id
            title
            label
            learningOutcome
        }
        teacher {
            id
            ...TeacherAvatar
        }
    }
    ${TeacherAvatarFragmentDoc}
`;
export const PostCheckoutTeacherAvatarFragmentDoc = gql`
    fragment PostCheckoutTeacherAvatar on Teacher {
        ...TeacherAvatar
        lastName
    }
    ${TeacherAvatarFragmentDoc}
`;
export const PostCheckoutStudentFragmentDoc = gql`
    fragment PostCheckoutStudent on Student {
        id
        shortName
        photo {
            id
            urls {
                thumbnail
            }
        }
    }
`;
export const PostCheckoutClassFragmentDoc = gql`
    fragment PostCheckoutClass on Klass {
        uid
        status
        type
        isExisting
        startDate
        endDate
        isForNewClassroom
        module {
            id
            name
        }
        sourcePresentation
        lesson {
            id
            title
            label
            learningOutcomes
            section {
                name
            }
            module {
                name
            }
            units {
                chapter
            }
            type
        }
        teacher {
            id
            ...PostCheckoutTeacherAvatar
        }
        students {
            ...PostCheckoutStudent
        }
    }
    ${PostCheckoutTeacherAvatarFragmentDoc}
    ${PostCheckoutStudentFragmentDoc}
`;
export const PracticeSequenceQuizSessionFragmentDoc = gql`
    fragment PracticeSequenceQuizSession on Session {
        id
        completedAt
        flashcards {
            knownStatus
            item {
                id
                item
                title
                gender
                wordClass
                plural
                section {
                    name
                }
                sampleSentenceOne
                audioUrls {
                    target
                    translation
                }
            }
        }
        exercises {
            id
            type
            questions {
                id
                title
                audioUrl
                choices {
                    id
                    text
                    isCorrect
                    audioUrl
                    translation {
                        byWord {
                            id
                            source
                            translation
                        }
                    }
                }
                meta {
                    gender
                    plural
                    sampleSentence
                    vocabularyItemId
                    wordClass
                    translation
                }
                binaryDialogMeta {
                    gender
                    speaker
                }
                records {
                    isSkipped
                    score
                }
                translation {
                    wholeSentenceToTarget
                }
                titleTranslation {
                    byWord {
                        id
                        source
                        translation
                    }
                }
            }
        }
    }
`;
export const YearlyActivityAllSummaryFragmentDoc = gql`
    fragment YearlyActivityAllSummary on StudentYearlyActivitySummary {
        year
        classesAttendedCount
        attendedMinutes
        weeksStraightCount
        exercisesCompletedCount
        rank
        teachersCount
        studentsCount
        certificatesEarned {
            name
        }
        modulesAttended {
            name
        }
        topFeedbacksCount {
            name
            value
        }
    }
`;
export const TeacherAgreementsDataFragmentDoc = gql`
    fragment TeacherAgreementsData on Agreement {
        isAccepted
        id
        body
    }
`;
export const TeacherCalendarTeacherCalendarEventFragmentDoc = gql`
    fragment TeacherCalendarTeacherCalendarEvent on TeacherCalendarEvent {
        classType
        classUniqId
        isCommittedByTeacher
        isB2G
        isBonus
        classDuration
    }
`;
export const TeacherCalendarTeacherCalendarTimeSlotFragmentDoc = gql`
    fragment TeacherCalendarTeacherCalendarTimeSlot on TeacherCalendarTimeSlot {
        startDate
        events {
            ...TeacherCalendarTeacherCalendarEvent
        }
    }
    ${TeacherCalendarTeacherCalendarEventFragmentDoc}
`;
export const TeacherCalendarTeacherCalendarFragmentDoc = gql`
    fragment TeacherCalendarTeacherCalendar on TeacherCalendar {
        startOfWeekDate
        totalAvailableClassesPerWeek
        totalBookedClassesThisWeek
        timeSlots {
            ...TeacherCalendarTeacherCalendarTimeSlot
        }
    }
    ${TeacherCalendarTeacherCalendarTimeSlotFragmentDoc}
`;
export const TeacherClassDetailsTileHeaderTeacherCalendarEventDetailsFragmentDoc = gql`
    fragment TeacherClassDetailsTileHeaderTeacherCalendarEventDetails on TeacherCalendarEventDetails {
        classType
        isB2G
        isBonus
        isLanguageClass
        classDuration
        lesson {
            label
            title
        }
        module
    }
`;
export const TeacherClassDetailsTileDescriptionTeacherCalendarEventDetailsFragmentDoc = gql`
    fragment TeacherClassDetailsTileDescriptionTeacherCalendarEventDetails on TeacherCalendarEventDetails {
        courseId
        lesson {
            learningOutcomes
            description
        }
    }
`;
export const TeacherClassDetailsTileParticipantsStudentFragmentDoc = gql`
    fragment TeacherClassDetailsTileParticipantsStudent on Student {
        id
        shortName
        photo {
            urls {
                medium
            }
        }
    }
`;
export const TeacherClassDetailsTileInfoTeacherCalendarEventDetailsFragmentDoc = gql`
    fragment TeacherClassDetailsTileInfoTeacherCalendarEventDetails on TeacherCalendarEventDetails {
        students {
            ...TeacherClassDetailsTileParticipantsStudent
        }
        price {
            forHumans
        }
        previousClassLogsUrl
    }
    ${TeacherClassDetailsTileParticipantsStudentFragmentDoc}
`;
export const TeacherClassDetailsTileTeacherCalendarEventDetailsFragmentDoc = gql`
    fragment TeacherClassDetailsTileTeacherCalendarEventDetails on TeacherCalendarEventDetails {
        ...TeacherClassDetailsTileHeaderTeacherCalendarEventDetails
        ...TeacherClassDetailsTileDescriptionTeacherCalendarEventDetails
        ...TeacherClassDetailsTileInfoTeacherCalendarEventDetails
    }
    ${TeacherClassDetailsTileHeaderTeacherCalendarEventDetailsFragmentDoc}
    ${TeacherClassDetailsTileDescriptionTeacherCalendarEventDetailsFragmentDoc}
    ${TeacherClassDetailsTileInfoTeacherCalendarEventDetailsFragmentDoc}
`;
export const TeacherDetailsDialogTeacherCalendarEventDetailsListFragmentDoc = gql`
    fragment TeacherDetailsDialogTeacherCalendarEventDetailsList on TeacherCalendarEventDetailsList {
        eventStartDate
        events {
            classUniqId
            ...TeacherClassDetailsTileTeacherCalendarEventDetails
        }
        isPriceVisible
    }
    ${TeacherClassDetailsTileTeacherCalendarEventDetailsFragmentDoc}
`;
export const TeacherInvoiceClassFragmentDoc = gql`
    fragment teacherInvoiceClass on Klass {
        uid
        total {
            amountInMajor
            currency
        }
        labels
        lesson {
            id
            title
        }
        startDate
        endDate
    }
`;
export const TeacherInvoiceItemFragmentDoc = gql`
    fragment teacherInvoiceItem on TeacherInvoiceItem {
        id
        class {
            ...teacherInvoiceClass
        }
        amount {
            amountInMajor
            currency
        }
        date
        description
        bonusType
    }
    ${TeacherInvoiceClassFragmentDoc}
`;
export const TeacherInvoiceFragmentDoc = gql`
    fragment teacherInvoice on TeacherInvoice {
        id
        pdfUrl
        date
        total
        currency
        pending
        items {
            ...teacherInvoiceItem
        }
    }
    ${TeacherInvoiceItemFragmentDoc}
`;
export const TeacherSubmittedLogFragmentDoc = gql`
    fragment teacherSubmittedLog on ClassLog {
        class {
            ...teacherInvoiceClass
        }
        id
    }
    ${TeacherInvoiceClassFragmentDoc}
`;
export const TeacherSubmittedMonthFragmentDoc = gql`
    fragment teacherSubmittedMonth on ClassLogGroupDTO {
        classLogs {
            ...teacherSubmittedLog
        }
        date
    }
    ${TeacherSubmittedLogFragmentDoc}
`;
export const ClassRemunerationFragmentDoc = gql`
    fragment ClassRemuneration on Klass {
        type
        labels
        total {
            forHumans
        }
        attendedPrice {
            forHumans
        }
        missedPrice {
            forHumans
        }
        statusByTeacher
        isPriceVisible
    }
`;
export const TeacherClassDetailsTileDateKlassFragmentDoc = gql`
    fragment TeacherClassDetailsTileDateKlass on Klass {
        startDate
        endDate
    }
`;
export const ClassSummaryFragmentDoc = gql`
    fragment ClassSummary on Klass {
        ...ClassRemuneration
        ...TeacherClassDetailsTileDateKlass
        uid
        type
        lesson {
            id
            title
        }
    }
    ${ClassRemunerationFragmentDoc}
    ${TeacherClassDetailsTileDateKlassFragmentDoc}
`;
export const ClassCancelFragmentDoc = gql`
    fragment ClassCancel on Klass {
        ...ClassRemuneration
        ...ClassSummary
        isLanguageClass
        startDate
        endDate
    }
    ${ClassRemunerationFragmentDoc}
    ${ClassSummaryFragmentDoc}
`;
export const ClassLogFragmentDoc = gql`
    fragment ClassLog on ClassLog {
        id
        createdAt
        updatedAt
        logMessage
    }
`;
export const AppointmentFragmentDoc = gql`
    fragment Appointment on Appointment {
        id
        attendanceStatus @client
        statusBySystem
        isNewStudent
        student {
            id
            shortName
            photo {
                urls {
                    thumbnail
                }
            }
            curriculum {
                id
                name
            }
        }
        appointmentLog {
            feedback
            answers {
                id
                answer
                appointmentLogQuestion {
                    id
                }
            }
            isAttended
            recommendedLevel {
                id
                name
            }
            createdAt
            updatedAt
        }
    }
`;
export const KysFeedbackFragmentDoc = gql`
    fragment KysFeedback on PrepareClassInfo {
        kysFeedback {
            id
            isUseful
        }
        isAllowedToLeaveFeedback
    }
`;
export const PrepareClassInfoFragmentDoc = gql`
    fragment PrepareClassInfo on PrepareClassInfo {
        prepareClassStudents {
            studentTeacherInteractions
            lastInteraction
            studentAppointmentLabel
            student {
                id
                registrationDate
                section {
                    id
                    name
                }
                photo {
                    urls {
                        thumbnail
                        medium
                    }
                }
                shortName
                firstName
                completedClasses
                lastCertificate {
                    module {
                        name
                    }
                }
                currentStudentModule {
                    name
                    completedClasses
                    totalClasses
                }
                studentProfile {
                    studentSkills {
                        sentiment
                        answer
                        count
                    }
                    whyAreYouLearning {
                        answers
                        otherOption
                    }
                    learningNeeds
                    spokenLanguages {
                        language
                        languageFluency
                    }
                }
            }
            studentModules {
                name
                completedClasses
                totalClasses
            }
        }
        ...KysFeedback
    }
    ${KysFeedbackFragmentDoc}
`;
export const ClassLogFormFragmentDoc = gql`
    fragment ClassLogForm on Klass {
        uid
        labels
        classLog {
            ...ClassLog
        }
        previousClassLogsUrl
        activeAppointments {
            ...Appointment
        }
        isLanguageClass
        prepareClassInfo {
            ...PrepareClassInfo
        }
    }
    ${ClassLogFragmentDoc}
    ${AppointmentFragmentDoc}
    ${PrepareClassInfoFragmentDoc}
`;
export const ClassActionsFragmentDoc = gql`
    fragment ClassActions on Klass {
        ...ClassCancel
        ...ClassLogForm
    }
    ${ClassCancelFragmentDoc}
    ${ClassLogFormFragmentDoc}
`;
export const ClassStatusBannerFragmentDoc = gql`
    fragment ClassStatusBanner on Klass {
        statusByTeacher
        classLog {
            createdAt
            updatedAt
        }
        activeAppointments {
            ...Appointment
        }
    }
    ${AppointmentFragmentDoc}
`;
export const ClassDetailsFragmentDoc = gql`
    fragment ClassDetails on Klass {
        ...ClassStatusBanner
        courseId
        endDate
        startDate
        status
        type
        uid
    }
    ${ClassStatusBannerFragmentDoc}
`;
export const ClassPresentationFragmentDoc = gql`
    fragment ClassPresentation on Klass {
        uid
        annotatedPresentation
        isPresentationMode
        lesson {
            title
            presentationUrl
            presentationNumber
            presentation {
                updatedAt
            }
        }
    }
`;
export const AdminCommentFragmentDoc = gql`
    fragment AdminComment on Klass {
        classNote
    }
`;
export const LessonDetailsFragmentDoc = gql`
    fragment LessonDetails on Lesson {
        title
        description
        learningOutcomes
    }
`;
export const TeacherClassFragmentDoc = gql`
    fragment TeacherClass on Klass {
        ...ClassActions
        ...ClassLogForm
        ...ClassDetails
        ...ClassPresentation
        ...ClassRemuneration
        ...AdminComment
        type
        lesson {
            ...LessonDetails
        }
        isForNewClassroom
        businessModelType
        teacherFeedback {
            id
        }
    }
    ${ClassActionsFragmentDoc}
    ${ClassLogFormFragmentDoc}
    ${ClassDetailsFragmentDoc}
    ${ClassPresentationFragmentDoc}
    ${ClassRemunerationFragmentDoc}
    ${AdminCommentFragmentDoc}
    ${LessonDetailsFragmentDoc}
`;
export const AppointmentLogQuestionFragmentDoc = gql`
    fragment AppointmentLogQuestion on AppointmentLogQuestion {
        id
        isRequired
        min
        max
        question
        answers {
            id
            answer
        }
    }
`;
export const TeacherCoursesTeacherAvailableCourseFragmentDoc = gql`
    fragment TeacherCoursesTeacherAvailableCourse on TeacherAvailableCourse {
        id
        name
        startDate
        endDate
        classesCount
        studentsCount
        material
        courseType
        courseTimes {
            dayOfWeek
            hour
        }
        courseModules {
            cefrLevelName
        }
        price {
            forHumans
            amount
            currency
        }
    }
`;
export const TeacherCoursesCourseHolidayFragmentDoc = gql`
    fragment TeacherCoursesCourseHoliday on CourseHoliday {
        startDate
        endDate
    }
`;
export const TeacherCoursesCourseAssignResultFragmentDoc = gql`
    fragment TeacherCoursesCourseAssignResult on CourseAssignResult {
        bookedClasses {
            uid
        }
        errors {
            class {
                uid
            }
            reason
        }
        overriddenClasses {
            overlappedScheduledClass {
                uid
            }
            overlappingCourseClass {
                uid
            }
        }
    }
`;
export const TeacherAccountDataFragmentDoc = gql`
    fragment TeacherAccountData on Teacher {
        id
        uid
        email
        agreements {
            id
            isAccepted
            body
            acceptedAt
            type
        }
    }
`;
export const TeacherProfileCertificateFragmentDoc = gql`
    fragment TeacherProfileCertificate on TeacherCertificate {
        id
        url
        type
        label
    }
`;
export const TeacherInvoiceDataFragmentDoc = gql`
    fragment TeacherInvoiceData on Teacher {
        id
        teacherInfo {
            address
            address2
            bic
            city
            country {
                code
            }
            defaultPaymentMethod
            iban
            paypal
            postalCode
            state
            incomeTaxNumber
        }
    }
`;
export const TeacherLanguagesFragmentDoc = gql`
    fragment teacherLanguages on Teacher {
        id
        teacherInfo {
            languages {
                language
                fluency
            }
        }
    }
`;
export const TeacherNotificationsSettingsFragmentDoc = gql`
    fragment teacherNotificationsSettings on Teacher {
        id
        notificationSettings {
            feedback
            otherClassCancellation
            shortNoticeCancellation
        }
    }
`;
export const TeacherPreferencesDataFragmentDoc = gql`
    fragment TeacherPreferencesData on Teacher {
        id
        teacherPreferences {
            groupClasses
            privateClasses
            specializedClasses
            timeFormat
            timezone
            weekStart
        }
    }
`;
export const TeacherPersonalInformationTeacherFragmentDoc = gql`
    fragment TeacherPersonalInformationTeacher on Teacher {
        id
        photo {
            id
            urls {
                thumbnail
            }
        }
        firstName
        lastName
        teacherInfo {
            aboutMe
        }
    }
`;
export const ProfileTeacherFragmentDoc = gql`
    fragment ProfileTeacher on Teacher {
        id
        photo {
            id
            urls {
                thumbnail
            }
        }
        firstName
        lastName
        teacherInfo {
            aboutMe
        }
    }
`;
export const BookableClassFragmentDoc = gql`
    fragment BookableClass on Klass {
        uid
        startDate
        endDate
        type
        isExisting
        isForNewClassroom
        module {
            id
            name
        }
        learningUnit {
            id
            chapter
        }
        teacher {
            ...ClassCardTeacher
        }
        students {
            ...ClassCardStudent
            shortName
        }
        lesson {
            id
            label
            title
            type
            studentStatus
            availableWithoutPayment
        }
        appointment {
            id
            status
        }
    }
    ${ClassCardTeacherFragmentDoc}
    ${ClassCardStudentFragmentDoc}
`;
export const TeacherChatParticipantPhotoFragmentDoc = gql`
    fragment teacherChatParticipantPhoto on Teacher {
        id
        uid
        photo {
            urls {
                thumbnail
            }
        }
    }
`;
export const StudentChatParticipantPhotoFragmentDoc = gql`
    fragment studentChatParticipantPhoto on Student {
        id
        uid
        photo {
            urls {
                thumbnail
            }
        }
    }
`;
export const ClassChatParticipantPhotosFragmentDoc = gql`
    fragment classChatParticipantPhotos on Klass {
        teacher {
            ...teacherChatParticipantPhoto
        }
        students {
            ...studentChatParticipantPhoto
        }
    }
    ${TeacherChatParticipantPhotoFragmentDoc}
    ${StudentChatParticipantPhotoFragmentDoc}
`;
export const ClassFeedbackAnswerFragmentDoc = gql`
    fragment classFeedbackAnswer on ClassFeedbackAnswer {
        value: id
        label: answer
        answerType
        sentiment
        active
    }
`;
export const ClassCardAppointmentFragmentDoc = gql`
    fragment ClassCardAppointment on Appointment {
        id
        status
        label
        klass: class {
            uid
            startDate
            endDate
            type
            students {
                ...ClassCardStudent
            }
            lesson {
                title
                label
            }
            teacher {
                ...ClassCardTeacher
            }
        }
    }
    ${ClassCardStudentFragmentDoc}
    ${ClassCardTeacherFragmentDoc}
`;
export const ClassroomStudentDataFragmentDoc = gql`
    fragment classroomStudentData on Query {
        student {
            id
            uid
            firstName
            photo {
                urls {
                    medium
                    thumbnail
                }
            }
            timezone
            timeFormat
            weekStart
        }
    }
`;
export const ClassroomTeacherDataFragmentDoc = gql`
    fragment classroomTeacherData on Query {
        teacher {
            id
            uid
            firstName
            photo {
                urls {
                    medium
                    thumbnail
                }
            }
            teacherPreferences {
                timezone
                timeFormat
                weekStart
            }
        }
    }
`;
export const PurchaseAgreementFragmentDoc = gql`
    fragment purchaseAgreement on Agreement {
        id
        acceptanceText
        pdfLink
    }
`;
export const IntervalFragmentDoc = gql`
    fragment interval on DateInterval {
        years
        months
        weeks
        days
        hours
        minutes
        seconds
        microseconds
        forHumans
    }
`;
export const TransactionFragmentDoc = gql`
    fragment transaction on Transaction {
        id
        errorCode
        errorMessage
        paymentStatus
        isInitialPurchase
        isPaymentMethodUpdate
        currency
        discount {
            ...price
        }
        amount {
            ...price
        }
    }
    ${PriceFragmentDoc}
`;
export const ExtraCreditPurchaseFragmentDoc = gql`
    fragment extraCreditPurchase on ExtraCreditPurchase {
        id
        type
        displayName
        privateCredits
        groupCredits
        isSubscription
        canUseCoupon
        contentBlocks {
            location
            label
            text
            icon
            isStarred
            table
        }
        coupons {
            id
            code
        }
        total {
            ...price
        }
        subtotal {
            ...price
        }
        chargeTotal {
            ...price
        }
        discount {
            ...price
        }
        agreements {
            ...purchaseAgreement
        }
        validityInterval {
            ...interval
        }
        lastTransaction {
            ...transaction
        }
    }
    ${PriceFragmentDoc}
    ${PurchaseAgreementFragmentDoc}
    ${IntervalFragmentDoc}
    ${TransactionFragmentDoc}
`;
export const LearningUnitFragmentDoc = gql`
    fragment learningUnit on LearningUnit {
        id
        name
        chapter
        lessons {
            id
            description
            learningOutcomes
            presentation {
                updatedAt
            }
            presentationNumber
            presentationUrl
            title
            type
        }
    }
`;
export const LegacyRolloverDataFragmentDoc = gql`
    fragment legacyRolloverData on LegacyRolloverData {
        productId
        trialInterval {
            ...interval
        }
        recurrenceInterval {
            ...interval
        }
        subTotal {
            ...price
        }
        total {
            ...price
        }
    }
    ${IntervalFragmentDoc}
    ${PriceFragmentDoc}
`;
export const LegacyPurchaseFragmentDoc = gql`
    fragment legacyPurchase on LegacyPurchase {
        id
        type
        displayName
        privateCredits
        groupCredits
        isSubscription
        canUseCoupon
        contentBlocks {
            location
            label
            text
            icon
            isStarred
            table
        }
        coupons {
            id
            code
        }
        total {
            ...price
        }
        subtotal {
            ...price
        }
        chargeTotal {
            ...price
        }
        discount {
            ...price
        }
        agreements {
            ...purchaseAgreement
        }
        validityInterval {
            ...interval
        }
        lastTransaction {
            ...transaction
        }
        productDescription
        rollover {
            ...legacyRolloverData
        }
        recurrenceInterval {
            ...interval
        }
        withTrial
    }
    ${PriceFragmentDoc}
    ${PurchaseAgreementFragmentDoc}
    ${IntervalFragmentDoc}
    ${TransactionFragmentDoc}
    ${LegacyRolloverDataFragmentDoc}
`;
export const OwnReferralFragmentDoc = gql`
    fragment OwnReferral on OwnReferral {
        code
        ownerGroupCredits
        userDiscount {
            amountInMajor
            currency
            amount
        }
    }
`;
export const CourseSectionFragmentDoc = gql`
    fragment CourseSection on Section {
        id
        name
    }
`;
export const SubscriptionPurchaseFragmentDoc = gql`
    fragment subscriptionPurchase on SubscriptionPurchase {
        id
        type
        displayName
        privateCredits
        groupCredits
        isSubscription
        canUseCoupon
        contentBlocks {
            location
            label
            text
            icon
            isStarred
            table
        }
        coupons {
            id
            code
        }
        total {
            ...price
        }
        subtotal {
            ...price
        }
        chargeTotal {
            ...price
        }
        discount {
            ...price
        }
        agreements {
            ...purchaseAgreement
        }
        validityInterval {
            ...interval
        }
        lastTransaction {
            ...transaction
        }
        recurrenceInterval {
            ...interval
        }
        withTrial
        newSection {
            name
        }
        curriculum {
            id
        }
    }
    ${PriceFragmentDoc}
    ${PurchaseAgreementFragmentDoc}
    ${IntervalFragmentDoc}
    ${TransactionFragmentDoc}
`;
export const SectionCurriculumFragmentDoc = gql`
    fragment SectionCurriculum on SectionCurriculum {
        slug @client
        section {
            id
            name
        }
        curriculum {
            id
            name
            default
        }
    }
`;
export const PackageFragmentDoc = gql`
    fragment package on Variant {
        id
        isCurrent
        sectionCurriculum {
            ...SectionCurriculum
        }
        contentBlocks {
            table
            text
            label
            location
            icon
            isStarred
        }
        curriculum {
            id
            name
        }
        recurringSpecialType
        classType @client
        groupCredits
        privateCredits
        type
        subTotal {
            forHumans
            amount
            amountInMajor
            currency
        }
    }
    ${SectionCurriculumFragmentDoc}
`;
export const StudentNotificationFragmentDoc = gql`
    fragment StudentNotification on StudentNotification {
        id
        createdAt
        contentKeys
        contentValues
        type
    }
`;
export const CurrentSprintFragmentDoc = gql`
    fragment CurrentSprint on Sprint {
        id
        name
        startDateInStudentTimezone
        durationMonths
        startDate
    }
`;
export const TeacherClassDetailsTileRemunerationKlassFragmentDoc = gql`
    fragment TeacherClassDetailsTileRemunerationKlass on Klass {
        total {
            forHumans
        }
    }
`;
export const TeacherPauseTeacherFragmentDoc = gql`
    fragment TeacherPauseTeacher on Teacher {
        id
        pausedFrom
        pausedUntil
    }
`;
export const ConfigurationTimezonesFragmentDoc = gql`
    fragment ConfigurationTimezones on SystemConfig {
        timezones {
            label
            tz
        }
    }
`;
export const LearningUnitsDocument = gql`
    query learningUnits($moduleId: Int) {
        learningUnits(moduleId: $moduleId) {
            ...CourseOverviewLearningUnit
        }
    }
    ${CourseOverviewLearningUnitFragmentDoc}
`;

/**
 * __useLearningUnitsQuery__
 *
 * To run a query within a React component, call `useLearningUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningUnitsQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useLearningUnitsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        LearningUnitsQuery,
        LearningUnitsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<LearningUnitsQuery, LearningUnitsQueryVariables>(
        LearningUnitsDocument,
        options,
    );
}
export function useLearningUnitsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        LearningUnitsQuery,
        LearningUnitsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<LearningUnitsQuery, LearningUnitsQueryVariables>(
        LearningUnitsDocument,
        options,
    );
}
export function useLearningUnitsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        LearningUnitsQuery,
        LearningUnitsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<LearningUnitsQuery, LearningUnitsQueryVariables>(
        LearningUnitsDocument,
        options,
    );
}
export type LearningUnitsQueryHookResult = ReturnType<typeof useLearningUnitsQuery>;
export type LearningUnitsLazyQueryHookResult = ReturnType<typeof useLearningUnitsLazyQuery>;
export type LearningUnitsSuspenseQueryHookResult = ReturnType<typeof useLearningUnitsSuspenseQuery>;
export type LearningUnitsQueryResult = Apollo.QueryResult<
    LearningUnitsQuery,
    LearningUnitsQueryVariables
>;
export const RestartExercisesDocument = gql`
    mutation RestartExercises($lessonId: Int!, $type: ExerciseType!) {
        restartExercises(lessonId: $lessonId, type: $type) {
            exercises {
                id
                type
                url
                score
            }
            lesson {
                id
            }
        }
    }
`;
export type RestartExercisesMutationFn = Apollo.MutationFunction<
    RestartExercisesMutation,
    RestartExercisesMutationVariables
>;

/**
 * __useRestartExercisesMutation__
 *
 * To run a mutation, you first call `useRestartExercisesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartExercisesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartExercisesMutation, { data, loading, error }] = useRestartExercisesMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useRestartExercisesMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        RestartExercisesMutation,
        RestartExercisesMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        RestartExercisesMutation,
        RestartExercisesMutationVariables
    >(RestartExercisesDocument, options);
}
export type RestartExercisesMutationHookResult = ReturnType<typeof useRestartExercisesMutation>;
export type RestartExercisesMutationResult = Apollo.MutationResult<RestartExercisesMutation>;
export type RestartExercisesMutationOptions = Apollo.BaseMutationOptions<
    RestartExercisesMutation,
    RestartExercisesMutationVariables
>;
export const ExercisesDocument = gql`
    query exercises($lessonId: Int!, $type: ExerciseType, $status: ExerciseStatus) {
        practiceItem(lessonId: $lessonId) {
            ...practiceItemHeader
        }
        exercises(lessonId: $lessonId, type: $type, status: $status) @client {
            ...exerciseDescription
        }
    }
    ${PracticeItemHeaderFragmentDoc}
    ${ExerciseDescriptionFragmentDoc}
`;

/**
 * __useExercisesQuery__
 *
 * To run a query within a React component, call `useExercisesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExercisesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExercisesQuery({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *      type: // value for 'type'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useExercisesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ExercisesQuery, ExercisesQueryVariables> &
        ({ variables: ExercisesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ExercisesQuery, ExercisesQueryVariables>(
        ExercisesDocument,
        options,
    );
}
export function useExercisesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExercisesQuery, ExercisesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ExercisesQuery, ExercisesQueryVariables>(
        ExercisesDocument,
        options,
    );
}
export function useExercisesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ExercisesQuery,
        ExercisesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ExercisesQuery, ExercisesQueryVariables>(
        ExercisesDocument,
        options,
    );
}
export type ExercisesQueryHookResult = ReturnType<typeof useExercisesQuery>;
export type ExercisesLazyQueryHookResult = ReturnType<typeof useExercisesLazyQuery>;
export type ExercisesSuspenseQueryHookResult = ReturnType<typeof useExercisesSuspenseQuery>;
export type ExercisesQueryResult = Apollo.QueryResult<ExercisesQuery, ExercisesQueryVariables>;
export const LearningUnitExercisesDocument = gql`
    query learningUnitExercises($learningUnitId: Int!) {
        learningUnit(learningUnitId: $learningUnitId) {
            id
            name
            chapter
            module {
                id
                name
            }
        }
        exercise(learningUnitId: $learningUnitId) {
            id
            score
            link
            title
        }
    }
`;

/**
 * __useLearningUnitExercisesQuery__
 *
 * To run a query within a React component, call `useLearningUnitExercisesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningUnitExercisesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningUnitExercisesQuery({
 *   variables: {
 *      learningUnitId: // value for 'learningUnitId'
 *   },
 * });
 */
export function useLearningUnitExercisesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        LearningUnitExercisesQuery,
        LearningUnitExercisesQueryVariables
    > &
        ({ variables: LearningUnitExercisesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        LearningUnitExercisesQuery,
        LearningUnitExercisesQueryVariables
    >(LearningUnitExercisesDocument, options);
}
export function useLearningUnitExercisesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        LearningUnitExercisesQuery,
        LearningUnitExercisesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        LearningUnitExercisesQuery,
        LearningUnitExercisesQueryVariables
    >(LearningUnitExercisesDocument, options);
}
export function useLearningUnitExercisesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        LearningUnitExercisesQuery,
        LearningUnitExercisesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        LearningUnitExercisesQuery,
        LearningUnitExercisesQueryVariables
    >(LearningUnitExercisesDocument, options);
}
export type LearningUnitExercisesQueryHookResult = ReturnType<typeof useLearningUnitExercisesQuery>;
export type LearningUnitExercisesLazyQueryHookResult = ReturnType<
    typeof useLearningUnitExercisesLazyQuery
>;
export type LearningUnitExercisesSuspenseQueryHookResult = ReturnType<
    typeof useLearningUnitExercisesSuspenseQuery
>;
export type LearningUnitExercisesQueryResult = Apollo.QueryResult<
    LearningUnitExercisesQuery,
    LearningUnitExercisesQueryVariables
>;
export const GrammarItemDocument = gql`
    query grammarItem($id: String!) {
        grammarItem(id: $id) {
            ...GrammarItem
        }
    }
    ${GrammarItemFragmentDoc}
`;

/**
 * __useGrammarItemQuery__
 *
 * To run a query within a React component, call `useGrammarItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrammarItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrammarItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGrammarItemQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<GrammarItemQuery, GrammarItemQueryVariables> &
        ({ variables: GrammarItemQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GrammarItemQuery, GrammarItemQueryVariables>(
        GrammarItemDocument,
        options,
    );
}
export function useGrammarItemLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GrammarItemQuery,
        GrammarItemQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GrammarItemQuery, GrammarItemQueryVariables>(
        GrammarItemDocument,
        options,
    );
}
export function useGrammarItemSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        GrammarItemQuery,
        GrammarItemQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<GrammarItemQuery, GrammarItemQueryVariables>(
        GrammarItemDocument,
        options,
    );
}
export type GrammarItemQueryHookResult = ReturnType<typeof useGrammarItemQuery>;
export type GrammarItemLazyQueryHookResult = ReturnType<typeof useGrammarItemLazyQuery>;
export type GrammarItemSuspenseQueryHookResult = ReturnType<typeof useGrammarItemSuspenseQuery>;
export type GrammarItemQueryResult = Apollo.QueryResult<
    GrammarItemQuery,
    GrammarItemQueryVariables
>;
export const GrammarItemsDocument = gql`
    query grammarItems($lessonId: Int!) {
        grammarItems(lessonIds: [$lessonId]) {
            ...GrammarItem
        }
    }
    ${GrammarItemFragmentDoc}
`;

/**
 * __useGrammarItemsQuery__
 *
 * To run a query within a React component, call `useGrammarItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrammarItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrammarItemsQuery({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useGrammarItemsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<GrammarItemsQuery, GrammarItemsQueryVariables> &
        ({ variables: GrammarItemsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GrammarItemsQuery, GrammarItemsQueryVariables>(
        GrammarItemsDocument,
        options,
    );
}
export function useGrammarItemsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GrammarItemsQuery,
        GrammarItemsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GrammarItemsQuery, GrammarItemsQueryVariables>(
        GrammarItemsDocument,
        options,
    );
}
export function useGrammarItemsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        GrammarItemsQuery,
        GrammarItemsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<GrammarItemsQuery, GrammarItemsQueryVariables>(
        GrammarItemsDocument,
        options,
    );
}
export type GrammarItemsQueryHookResult = ReturnType<typeof useGrammarItemsQuery>;
export type GrammarItemsLazyQueryHookResult = ReturnType<typeof useGrammarItemsLazyQuery>;
export type GrammarItemsSuspenseQueryHookResult = ReturnType<typeof useGrammarItemsSuspenseQuery>;
export type GrammarItemsQueryResult = Apollo.QueryResult<
    GrammarItemsQuery,
    GrammarItemsQueryVariables
>;
export const SetGrammarItemStatusDocument = gql`
    mutation SetGrammarItemStatus($id: String!, $status: GrammarStatus!) {
        setGrammarStatus(id: $id, status: $status) {
            ...GrammarItem
        }
    }
    ${GrammarItemFragmentDoc}
`;
export type SetGrammarItemStatusMutationFn = Apollo.MutationFunction<
    SetGrammarItemStatusMutation,
    SetGrammarItemStatusMutationVariables
>;

/**
 * __useSetGrammarItemStatusMutation__
 *
 * To run a mutation, you first call `useSetGrammarItemStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGrammarItemStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGrammarItemStatusMutation, { data, loading, error }] = useSetGrammarItemStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetGrammarItemStatusMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SetGrammarItemStatusMutation,
        SetGrammarItemStatusMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SetGrammarItemStatusMutation,
        SetGrammarItemStatusMutationVariables
    >(SetGrammarItemStatusDocument, options);
}
export type SetGrammarItemStatusMutationHookResult = ReturnType<
    typeof useSetGrammarItemStatusMutation
>;
export type SetGrammarItemStatusMutationResult =
    Apollo.MutationResult<SetGrammarItemStatusMutation>;
export type SetGrammarItemStatusMutationOptions = Apollo.BaseMutationOptions<
    SetGrammarItemStatusMutation,
    SetGrammarItemStatusMutationVariables
>;
export const SetVocabularyItemsStatusByLessonDocument = gql`
    mutation SetVocabularyItemsStatusByLesson($lessonId: Int!, $status: VocabularyStatus!) {
        setVocabularyItemsStatusByLesson(lessonId: $lessonId, status: $status) {
            id
            isCompleted
        }
    }
`;
export type SetVocabularyItemsStatusByLessonMutationFn = Apollo.MutationFunction<
    SetVocabularyItemsStatusByLessonMutation,
    SetVocabularyItemsStatusByLessonMutationVariables
>;

/**
 * __useSetVocabularyItemsStatusByLessonMutation__
 *
 * To run a mutation, you first call `useSetVocabularyItemsStatusByLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVocabularyItemsStatusByLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVocabularyItemsStatusByLessonMutation, { data, loading, error }] = useSetVocabularyItemsStatusByLessonMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetVocabularyItemsStatusByLessonMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SetVocabularyItemsStatusByLessonMutation,
        SetVocabularyItemsStatusByLessonMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SetVocabularyItemsStatusByLessonMutation,
        SetVocabularyItemsStatusByLessonMutationVariables
    >(SetVocabularyItemsStatusByLessonDocument, options);
}
export type SetVocabularyItemsStatusByLessonMutationHookResult = ReturnType<
    typeof useSetVocabularyItemsStatusByLessonMutation
>;
export type SetVocabularyItemsStatusByLessonMutationResult =
    Apollo.MutationResult<SetVocabularyItemsStatusByLessonMutation>;
export type SetVocabularyItemsStatusByLessonMutationOptions = Apollo.BaseMutationOptions<
    SetVocabularyItemsStatusByLessonMutation,
    SetVocabularyItemsStatusByLessonMutationVariables
>;
export const PracticeItemsDocument = gql`
    query practiceItems(
        $lessonId: Int
        $moduleId: Int
        $type: ExerciseType
        $status: PracticeItemStatus
        $offset: Int
        $limit: Int
        $includeH5P: Boolean!
    ) {
        practiceItems(
            lessonId: $lessonId
            moduleId: $moduleId
            type: $type
            status: $status
            offset: $offset
            limit: $limit
        ) {
            exercises @include(if: $includeH5P) {
                ...exercise
            }
            quiz @client {
                ...exercise
            }
            homeworks @client {
                ...exercise
            }
            pendingHomeworks @client {
                ...exercise
            }
            isCompleted
            vocabularyItems {
                id
                title
                isCompleted
            }
            grammarItems {
                id
                title
                isCompleted
            }
            lesson {
                id
                title
                number
                presentationUrl
            }
            learningUnit {
                chapter
            }
            module {
                id
                name
            }
            class {
                uid
                annotatedPresentation
                sourcePresentation
                isForNewClassroom
                isPresentationMode
            }
            classLog {
                id
                logMessage
                classroomChatLogUrl
                hasClassroomChatLog
            }
        }
    }
    ${ExerciseFragmentDoc}
`;

/**
 * __usePracticeItemsQuery__
 *
 * To run a query within a React component, call `usePracticeItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticeItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticeItemsQuery({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *      moduleId: // value for 'moduleId'
 *      type: // value for 'type'
 *      status: // value for 'status'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      includeH5P: // value for 'includeH5P'
 *   },
 * });
 */
export function usePracticeItemsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        PracticeItemsQuery,
        PracticeItemsQueryVariables
    > &
        ({ variables: PracticeItemsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PracticeItemsQuery, PracticeItemsQueryVariables>(
        PracticeItemsDocument,
        options,
    );
}
export function usePracticeItemsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PracticeItemsQuery,
        PracticeItemsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PracticeItemsQuery, PracticeItemsQueryVariables>(
        PracticeItemsDocument,
        options,
    );
}
export function usePracticeItemsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        PracticeItemsQuery,
        PracticeItemsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<PracticeItemsQuery, PracticeItemsQueryVariables>(
        PracticeItemsDocument,
        options,
    );
}
export type PracticeItemsQueryHookResult = ReturnType<typeof usePracticeItemsQuery>;
export type PracticeItemsLazyQueryHookResult = ReturnType<typeof usePracticeItemsLazyQuery>;
export type PracticeItemsSuspenseQueryHookResult = ReturnType<typeof usePracticeItemsSuspenseQuery>;
export type PracticeItemsQueryResult = Apollo.QueryResult<
    PracticeItemsQuery,
    PracticeItemsQueryVariables
>;
export const PracticePageLingobitesDocument = gql`
    query practicePageLingobites($limit: Int) {
        nextLessonsForPracticeSequenceQuiz(limit: $limit) {
            id
            title
            studentLearningUnit {
                name
            }
            studentStatus
            practiceSequenceScore
        }
    }
`;

/**
 * __usePracticePageLingobitesQuery__
 *
 * To run a query within a React component, call `usePracticePageLingobitesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticePageLingobitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticePageLingobitesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePracticePageLingobitesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PracticePageLingobitesQuery,
        PracticePageLingobitesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PracticePageLingobitesQuery,
        PracticePageLingobitesQueryVariables
    >(PracticePageLingobitesDocument, options);
}
export function usePracticePageLingobitesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PracticePageLingobitesQuery,
        PracticePageLingobitesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PracticePageLingobitesQuery,
        PracticePageLingobitesQueryVariables
    >(PracticePageLingobitesDocument, options);
}
export function usePracticePageLingobitesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        PracticePageLingobitesQuery,
        PracticePageLingobitesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        PracticePageLingobitesQuery,
        PracticePageLingobitesQueryVariables
    >(PracticePageLingobitesDocument, options);
}
export type PracticePageLingobitesQueryHookResult = ReturnType<
    typeof usePracticePageLingobitesQuery
>;
export type PracticePageLingobitesLazyQueryHookResult = ReturnType<
    typeof usePracticePageLingobitesLazyQuery
>;
export type PracticePageLingobitesSuspenseQueryHookResult = ReturnType<
    typeof usePracticePageLingobitesSuspenseQuery
>;
export type PracticePageLingobitesQueryResult = Apollo.QueryResult<
    PracticePageLingobitesQuery,
    PracticePageLingobitesQueryVariables
>;
export const RemoveAvatarDocument = gql`
    mutation RemoveAvatar {
        removeAvatar
    }
`;
export type RemoveAvatarMutationFn = Apollo.MutationFunction<
    RemoveAvatarMutation,
    RemoveAvatarMutationVariables
>;

/**
 * __useRemoveAvatarMutation__
 *
 * To run a mutation, you first call `useRemoveAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAvatarMutation, { data, loading, error }] = useRemoveAvatarMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveAvatarMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        RemoveAvatarMutation,
        RemoveAvatarMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<RemoveAvatarMutation, RemoveAvatarMutationVariables>(
        RemoveAvatarDocument,
        options,
    );
}
export type RemoveAvatarMutationHookResult = ReturnType<typeof useRemoveAvatarMutation>;
export type RemoveAvatarMutationResult = Apollo.MutationResult<RemoveAvatarMutation>;
export type RemoveAvatarMutationOptions = Apollo.BaseMutationOptions<
    RemoveAvatarMutation,
    RemoveAvatarMutationVariables
>;
export const UpdateAvatarDocument = gql`
    mutation UpdateAvatar($avatar: String!) {
        updateAvatar(avatar: $avatar) {
            id
            photo {
                id
                urls {
                    thumbnail_large
                    thumbnail_large2x
                }
            }
        }
    }
`;
export type UpdateAvatarMutationFn = Apollo.MutationFunction<
    UpdateAvatarMutation,
    UpdateAvatarMutationVariables
>;

/**
 * __useUpdateAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAvatarMutation, { data, loading, error }] = useUpdateAvatarMutation({
 *   variables: {
 *      avatar: // value for 'avatar'
 *   },
 * });
 */
export function useUpdateAvatarMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateAvatarMutation,
        UpdateAvatarMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<UpdateAvatarMutation, UpdateAvatarMutationVariables>(
        UpdateAvatarDocument,
        options,
    );
}
export type UpdateAvatarMutationHookResult = ReturnType<typeof useUpdateAvatarMutation>;
export type UpdateAvatarMutationResult = Apollo.MutationResult<UpdateAvatarMutation>;
export type UpdateAvatarMutationOptions = Apollo.BaseMutationOptions<
    UpdateAvatarMutation,
    UpdateAvatarMutationVariables
>;
export const ProfileImageDocument = gql`
    query profileImage {
        currentUser {
            id
            photo {
                id
                urls {
                    thumbnail_large
                    thumbnail_large2x
                }
            }
        }
    }
`;

/**
 * __useProfileImageQuery__
 *
 * To run a query within a React component, call `useProfileImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileImageQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileImageQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<ProfileImageQuery, ProfileImageQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ProfileImageQuery, ProfileImageQueryVariables>(
        ProfileImageDocument,
        options,
    );
}
export function useProfileImageLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ProfileImageQuery,
        ProfileImageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ProfileImageQuery, ProfileImageQueryVariables>(
        ProfileImageDocument,
        options,
    );
}
export function useProfileImageSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ProfileImageQuery,
        ProfileImageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ProfileImageQuery, ProfileImageQueryVariables>(
        ProfileImageDocument,
        options,
    );
}
export type ProfileImageQueryHookResult = ReturnType<typeof useProfileImageQuery>;
export type ProfileImageLazyQueryHookResult = ReturnType<typeof useProfileImageLazyQuery>;
export type ProfileImageSuspenseQueryHookResult = ReturnType<typeof useProfileImageSuspenseQuery>;
export type ProfileImageQueryResult = Apollo.QueryResult<
    ProfileImageQuery,
    ProfileImageQueryVariables
>;
export const UpdateLearningLanguageForNotSubscriberDocument = gql`
    mutation updateLearningLanguageForNotSubscriber(
        $newSectionName: SectionName!
        $newCurriculumId: Int!
    ) {
        updateLearningLanguageForNotSubscriber(
            newSectionName: $newSectionName
            newCurriculumId: $newCurriculumId
        )
    }
`;
export type UpdateLearningLanguageForNotSubscriberMutationFn = Apollo.MutationFunction<
    UpdateLearningLanguageForNotSubscriberMutation,
    UpdateLearningLanguageForNotSubscriberMutationVariables
>;

/**
 * __useUpdateLearningLanguageForNotSubscriberMutation__
 *
 * To run a mutation, you first call `useUpdateLearningLanguageForNotSubscriberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLearningLanguageForNotSubscriberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLearningLanguageForNotSubscriberMutation, { data, loading, error }] = useUpdateLearningLanguageForNotSubscriberMutation({
 *   variables: {
 *      newSectionName: // value for 'newSectionName'
 *      newCurriculumId: // value for 'newCurriculumId'
 *   },
 * });
 */
export function useUpdateLearningLanguageForNotSubscriberMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateLearningLanguageForNotSubscriberMutation,
        UpdateLearningLanguageForNotSubscriberMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateLearningLanguageForNotSubscriberMutation,
        UpdateLearningLanguageForNotSubscriberMutationVariables
    >(UpdateLearningLanguageForNotSubscriberDocument, options);
}
export type UpdateLearningLanguageForNotSubscriberMutationHookResult = ReturnType<
    typeof useUpdateLearningLanguageForNotSubscriberMutation
>;
export type UpdateLearningLanguageForNotSubscriberMutationResult =
    Apollo.MutationResult<UpdateLearningLanguageForNotSubscriberMutation>;
export type UpdateLearningLanguageForNotSubscriberMutationOptions = Apollo.BaseMutationOptions<
    UpdateLearningLanguageForNotSubscriberMutation,
    UpdateLearningLanguageForNotSubscriberMutationVariables
>;
export const LearningLanguageDocument = gql`
    query learningLanguage {
        sectionCurricula {
            ...SectionCurriculum
        }
        canChangeLearningLanguage {
            isShown
            isEnabled
        }
    }
    ${SectionCurriculumFragmentDoc}
`;

/**
 * __useLearningLanguageQuery__
 *
 * To run a query within a React component, call `useLearningLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningLanguageQuery({
 *   variables: {
 *   },
 * });
 */
export function useLearningLanguageQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        LearningLanguageQuery,
        LearningLanguageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<LearningLanguageQuery, LearningLanguageQueryVariables>(
        LearningLanguageDocument,
        options,
    );
}
export function useLearningLanguageLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        LearningLanguageQuery,
        LearningLanguageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<LearningLanguageQuery, LearningLanguageQueryVariables>(
        LearningLanguageDocument,
        options,
    );
}
export function useLearningLanguageSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        LearningLanguageQuery,
        LearningLanguageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<LearningLanguageQuery, LearningLanguageQueryVariables>(
        LearningLanguageDocument,
        options,
    );
}
export type LearningLanguageQueryHookResult = ReturnType<typeof useLearningLanguageQuery>;
export type LearningLanguageLazyQueryHookResult = ReturnType<typeof useLearningLanguageLazyQuery>;
export type LearningLanguageSuspenseQueryHookResult = ReturnType<
    typeof useLearningLanguageSuspenseQuery
>;
export type LearningLanguageQueryResult = Apollo.QueryResult<
    LearningLanguageQuery,
    LearningLanguageQueryVariables
>;
export const PrivateFramePreloadDocument = gql`
    query privateFramePreload {
        subscriptions: studentSubscriptionInfo {
            currentSubscription {
                id
                type
                paymentMethodAdded
            }
        }
        config {
            ...ConfigurationTimezones
            languages
            currencies
            minimumCharges
            classConfig {
                refundableAfterBookingForMinutes
                refundableDaysBeforeStart
                studentCancelLateHours
                classStartTimePoint
                classDefaultDurationMinutes
            }
        }
    }
    ${ConfigurationTimezonesFragmentDoc}
`;

/**
 * __usePrivateFramePreloadQuery__
 *
 * To run a query within a React component, call `usePrivateFramePreloadQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivateFramePreloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivateFramePreloadQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrivateFramePreloadQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PrivateFramePreloadQuery,
        PrivateFramePreloadQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PrivateFramePreloadQuery, PrivateFramePreloadQueryVariables>(
        PrivateFramePreloadDocument,
        options,
    );
}
export function usePrivateFramePreloadLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PrivateFramePreloadQuery,
        PrivateFramePreloadQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PrivateFramePreloadQuery,
        PrivateFramePreloadQueryVariables
    >(PrivateFramePreloadDocument, options);
}
export function usePrivateFramePreloadSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        PrivateFramePreloadQuery,
        PrivateFramePreloadQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        PrivateFramePreloadQuery,
        PrivateFramePreloadQueryVariables
    >(PrivateFramePreloadDocument, options);
}
export type PrivateFramePreloadQueryHookResult = ReturnType<typeof usePrivateFramePreloadQuery>;
export type PrivateFramePreloadLazyQueryHookResult = ReturnType<
    typeof usePrivateFramePreloadLazyQuery
>;
export type PrivateFramePreloadSuspenseQueryHookResult = ReturnType<
    typeof usePrivateFramePreloadSuspenseQuery
>;
export type PrivateFramePreloadQueryResult = Apollo.QueryResult<
    PrivateFramePreloadQuery,
    PrivateFramePreloadQueryVariables
>;
export const AnonymousOrientationIndividualClassesDocument = gql`
    query anonymousOrientationIndividualClasses(
        $sectionName: SectionName!
        $moduleId: Int!
        $curriculumId: Int!
        $timezone: String!
    ) {
        anonymousOrientationIndividualClasses(
            sectionName: $sectionName
            moduleId: $moduleId
            curriculumId: $curriculumId
            timezone: $timezone
        ) {
            ...OnboardingClass
        }
    }
    ${OnboardingClassFragmentDoc}
`;

/**
 * __useAnonymousOrientationIndividualClassesQuery__
 *
 * To run a query within a React component, call `useAnonymousOrientationIndividualClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnonymousOrientationIndividualClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnonymousOrientationIndividualClassesQuery({
 *   variables: {
 *      sectionName: // value for 'sectionName'
 *      moduleId: // value for 'moduleId'
 *      curriculumId: // value for 'curriculumId'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useAnonymousOrientationIndividualClassesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        AnonymousOrientationIndividualClassesQuery,
        AnonymousOrientationIndividualClassesQueryVariables
    > &
        (
            | { variables: AnonymousOrientationIndividualClassesQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        AnonymousOrientationIndividualClassesQuery,
        AnonymousOrientationIndividualClassesQueryVariables
    >(AnonymousOrientationIndividualClassesDocument, options);
}
export function useAnonymousOrientationIndividualClassesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        AnonymousOrientationIndividualClassesQuery,
        AnonymousOrientationIndividualClassesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        AnonymousOrientationIndividualClassesQuery,
        AnonymousOrientationIndividualClassesQueryVariables
    >(AnonymousOrientationIndividualClassesDocument, options);
}
export function useAnonymousOrientationIndividualClassesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        AnonymousOrientationIndividualClassesQuery,
        AnonymousOrientationIndividualClassesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        AnonymousOrientationIndividualClassesQuery,
        AnonymousOrientationIndividualClassesQueryVariables
    >(AnonymousOrientationIndividualClassesDocument, options);
}
export type AnonymousOrientationIndividualClassesQueryHookResult = ReturnType<
    typeof useAnonymousOrientationIndividualClassesQuery
>;
export type AnonymousOrientationIndividualClassesLazyQueryHookResult = ReturnType<
    typeof useAnonymousOrientationIndividualClassesLazyQuery
>;
export type AnonymousOrientationIndividualClassesSuspenseQueryHookResult = ReturnType<
    typeof useAnonymousOrientationIndividualClassesSuspenseQuery
>;
export type AnonymousOrientationIndividualClassesQueryResult = Apollo.QueryResult<
    AnonymousOrientationIndividualClassesQuery,
    AnonymousOrientationIndividualClassesQueryVariables
>;
export const OnboardingSearchResultsDocument = gql`
    query onboardingSearchResults($bookingFilter: ProvideBookableClassSlotsInput!) {
        classes(bookingFilter: $bookingFilter) {
            ...OnboardingClass
        }
    }
    ${OnboardingClassFragmentDoc}
`;

/**
 * __useOnboardingSearchResultsQuery__
 *
 * To run a query within a React component, call `useOnboardingSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingSearchResultsQuery({
 *   variables: {
 *      bookingFilter: // value for 'bookingFilter'
 *   },
 * });
 */
export function useOnboardingSearchResultsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        OnboardingSearchResultsQuery,
        OnboardingSearchResultsQueryVariables
    > &
        ({ variables: OnboardingSearchResultsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        OnboardingSearchResultsQuery,
        OnboardingSearchResultsQueryVariables
    >(OnboardingSearchResultsDocument, options);
}
export function useOnboardingSearchResultsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        OnboardingSearchResultsQuery,
        OnboardingSearchResultsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        OnboardingSearchResultsQuery,
        OnboardingSearchResultsQueryVariables
    >(OnboardingSearchResultsDocument, options);
}
export function useOnboardingSearchResultsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        OnboardingSearchResultsQuery,
        OnboardingSearchResultsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        OnboardingSearchResultsQuery,
        OnboardingSearchResultsQueryVariables
    >(OnboardingSearchResultsDocument, options);
}
export type OnboardingSearchResultsQueryHookResult = ReturnType<
    typeof useOnboardingSearchResultsQuery
>;
export type OnboardingSearchResultsLazyQueryHookResult = ReturnType<
    typeof useOnboardingSearchResultsLazyQuery
>;
export type OnboardingSearchResultsSuspenseQueryHookResult = ReturnType<
    typeof useOnboardingSearchResultsSuspenseQuery
>;
export type OnboardingSearchResultsQueryResult = Apollo.QueryResult<
    OnboardingSearchResultsQuery,
    OnboardingSearchResultsQueryVariables
>;
export const OnboardingClassDocument = gql`
    query onboardingClass($classId: String!) {
        onboardingClass(classId: $classId) @client {
            ...OnboardingClass
        }
    }
    ${OnboardingClassFragmentDoc}
`;

/**
 * __useOnboardingClassQuery__
 *
 * To run a query within a React component, call `useOnboardingClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingClassQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useOnboardingClassQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        OnboardingClassQuery,
        OnboardingClassQueryVariables
    > &
        ({ variables: OnboardingClassQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<OnboardingClassQuery, OnboardingClassQueryVariables>(
        OnboardingClassDocument,
        options,
    );
}
export function useOnboardingClassLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        OnboardingClassQuery,
        OnboardingClassQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<OnboardingClassQuery, OnboardingClassQueryVariables>(
        OnboardingClassDocument,
        options,
    );
}
export function useOnboardingClassSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        OnboardingClassQuery,
        OnboardingClassQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<OnboardingClassQuery, OnboardingClassQueryVariables>(
        OnboardingClassDocument,
        options,
    );
}
export type OnboardingClassQueryHookResult = ReturnType<typeof useOnboardingClassQuery>;
export type OnboardingClassLazyQueryHookResult = ReturnType<typeof useOnboardingClassLazyQuery>;
export type OnboardingClassSuspenseQueryHookResult = ReturnType<
    typeof useOnboardingClassSuspenseQuery
>;
export type OnboardingClassQueryResult = Apollo.QueryResult<
    OnboardingClassQuery,
    OnboardingClassQueryVariables
>;
export const ClassroomClassDetailsDocument = gql`
    query classroomClassDetails($classUid: String!) {
        class(id: $classUid) {
            ...classroomClassDetails
        }
    }
    ${ClassroomClassDetailsFragmentDoc}
`;

/**
 * __useClassroomClassDetailsQuery__
 *
 * To run a query within a React component, call `useClassroomClassDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassroomClassDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassroomClassDetailsQuery({
 *   variables: {
 *      classUid: // value for 'classUid'
 *   },
 * });
 */
export function useClassroomClassDetailsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ClassroomClassDetailsQuery,
        ClassroomClassDetailsQueryVariables
    > &
        ({ variables: ClassroomClassDetailsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        ClassroomClassDetailsQuery,
        ClassroomClassDetailsQueryVariables
    >(ClassroomClassDetailsDocument, options);
}
export function useClassroomClassDetailsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ClassroomClassDetailsQuery,
        ClassroomClassDetailsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ClassroomClassDetailsQuery,
        ClassroomClassDetailsQueryVariables
    >(ClassroomClassDetailsDocument, options);
}
export function useClassroomClassDetailsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ClassroomClassDetailsQuery,
        ClassroomClassDetailsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ClassroomClassDetailsQuery,
        ClassroomClassDetailsQueryVariables
    >(ClassroomClassDetailsDocument, options);
}
export type ClassroomClassDetailsQueryHookResult = ReturnType<typeof useClassroomClassDetailsQuery>;
export type ClassroomClassDetailsLazyQueryHookResult = ReturnType<
    typeof useClassroomClassDetailsLazyQuery
>;
export type ClassroomClassDetailsSuspenseQueryHookResult = ReturnType<
    typeof useClassroomClassDetailsSuspenseQuery
>;
export type ClassroomClassDetailsQueryResult = Apollo.QueryResult<
    ClassroomClassDetailsQuery,
    ClassroomClassDetailsQueryVariables
>;
export const ClassroomSessionParticipantsDocument = gql`
    query classroomSessionParticipants($apiSessionId: Ulid!) {
        zoomSessionParticipants(zoomSessionId: $apiSessionId) {
            id
            sdkUserId
            status
        }
    }
`;

/**
 * __useClassroomSessionParticipantsQuery__
 *
 * To run a query within a React component, call `useClassroomSessionParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassroomSessionParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassroomSessionParticipantsQuery({
 *   variables: {
 *      apiSessionId: // value for 'apiSessionId'
 *   },
 * });
 */
export function useClassroomSessionParticipantsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ClassroomSessionParticipantsQuery,
        ClassroomSessionParticipantsQueryVariables
    > &
        (
            | { variables: ClassroomSessionParticipantsQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        ClassroomSessionParticipantsQuery,
        ClassroomSessionParticipantsQueryVariables
    >(ClassroomSessionParticipantsDocument, options);
}
export function useClassroomSessionParticipantsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ClassroomSessionParticipantsQuery,
        ClassroomSessionParticipantsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ClassroomSessionParticipantsQuery,
        ClassroomSessionParticipantsQueryVariables
    >(ClassroomSessionParticipantsDocument, options);
}
export function useClassroomSessionParticipantsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ClassroomSessionParticipantsQuery,
        ClassroomSessionParticipantsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ClassroomSessionParticipantsQuery,
        ClassroomSessionParticipantsQueryVariables
    >(ClassroomSessionParticipantsDocument, options);
}
export type ClassroomSessionParticipantsQueryHookResult = ReturnType<
    typeof useClassroomSessionParticipantsQuery
>;
export type ClassroomSessionParticipantsLazyQueryHookResult = ReturnType<
    typeof useClassroomSessionParticipantsLazyQuery
>;
export type ClassroomSessionParticipantsSuspenseQueryHookResult = ReturnType<
    typeof useClassroomSessionParticipantsSuspenseQuery
>;
export type ClassroomSessionParticipantsQueryResult = Apollo.QueryResult<
    ClassroomSessionParticipantsQuery,
    ClassroomSessionParticipantsQueryVariables
>;
export const BackgroundOptionsDocument = gql`
    query backgroundOptions {
        ...backgroundOptions
    }
    ${BackgroundOptionsFragmentDoc}
`;

/**
 * __useBackgroundOptionsQuery__
 *
 * To run a query within a React component, call `useBackgroundOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackgroundOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackgroundOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBackgroundOptionsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        BackgroundOptionsQuery,
        BackgroundOptionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<BackgroundOptionsQuery, BackgroundOptionsQueryVariables>(
        BackgroundOptionsDocument,
        options,
    );
}
export function useBackgroundOptionsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BackgroundOptionsQuery,
        BackgroundOptionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<BackgroundOptionsQuery, BackgroundOptionsQueryVariables>(
        BackgroundOptionsDocument,
        options,
    );
}
export function useBackgroundOptionsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        BackgroundOptionsQuery,
        BackgroundOptionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        BackgroundOptionsQuery,
        BackgroundOptionsQueryVariables
    >(BackgroundOptionsDocument, options);
}
export type BackgroundOptionsQueryHookResult = ReturnType<typeof useBackgroundOptionsQuery>;
export type BackgroundOptionsLazyQueryHookResult = ReturnType<typeof useBackgroundOptionsLazyQuery>;
export type BackgroundOptionsSuspenseQueryHookResult = ReturnType<
    typeof useBackgroundOptionsSuspenseQuery
>;
export type BackgroundOptionsQueryResult = Apollo.QueryResult<
    BackgroundOptionsQuery,
    BackgroundOptionsQueryVariables
>;
export const UpdateClassroomParticipantDocument = gql`
    mutation updateClassroomParticipant($participant: ZoomSessionParticipantInput!) {
        updateClassroomUser(input: $participant)
    }
`;
export type UpdateClassroomParticipantMutationFn = Apollo.MutationFunction<
    UpdateClassroomParticipantMutation,
    UpdateClassroomParticipantMutationVariables
>;

/**
 * __useUpdateClassroomParticipantMutation__
 *
 * To run a mutation, you first call `useUpdateClassroomParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassroomParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassroomParticipantMutation, { data, loading, error }] = useUpdateClassroomParticipantMutation({
 *   variables: {
 *      participant: // value for 'participant'
 *   },
 * });
 */
export function useUpdateClassroomParticipantMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateClassroomParticipantMutation,
        UpdateClassroomParticipantMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateClassroomParticipantMutation,
        UpdateClassroomParticipantMutationVariables
    >(UpdateClassroomParticipantDocument, options);
}
export type UpdateClassroomParticipantMutationHookResult = ReturnType<
    typeof useUpdateClassroomParticipantMutation
>;
export type UpdateClassroomParticipantMutationResult =
    Apollo.MutationResult<UpdateClassroomParticipantMutation>;
export type UpdateClassroomParticipantMutationOptions = Apollo.BaseMutationOptions<
    UpdateClassroomParticipantMutation,
    UpdateClassroomParticipantMutationVariables
>;
export const CreateChatHistoryDocument = gql`
    mutation createChatHistory($chatHistory: ClassChatHistoryInput!) {
        createClassChatHistory(input: $chatHistory)
    }
`;
export type CreateChatHistoryMutationFn = Apollo.MutationFunction<
    CreateChatHistoryMutation,
    CreateChatHistoryMutationVariables
>;

/**
 * __useCreateChatHistoryMutation__
 *
 * To run a mutation, you first call `useCreateChatHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatHistoryMutation, { data, loading, error }] = useCreateChatHistoryMutation({
 *   variables: {
 *      chatHistory: // value for 'chatHistory'
 *   },
 * });
 */
export function useCreateChatHistoryMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateChatHistoryMutation,
        CreateChatHistoryMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateChatHistoryMutation,
        CreateChatHistoryMutationVariables
    >(CreateChatHistoryDocument, options);
}
export type CreateChatHistoryMutationHookResult = ReturnType<typeof useCreateChatHistoryMutation>;
export type CreateChatHistoryMutationResult = Apollo.MutationResult<CreateChatHistoryMutation>;
export type CreateChatHistoryMutationOptions = Apollo.BaseMutationOptions<
    CreateChatHistoryMutation,
    CreateChatHistoryMutationVariables
>;
export const CreateZoomSessionDocument = gql`
    mutation createZoomSession($classUid: String!, $audioCompatibilityMode: Boolean = false) {
        createZoomSession(classUid: $classUid) {
            id
            zoomSessionName
            token(audioCompatibilityMode: $audioCompatibilityMode)
        }
    }
`;
export type CreateZoomSessionMutationFn = Apollo.MutationFunction<
    CreateZoomSessionMutation,
    CreateZoomSessionMutationVariables
>;

/**
 * __useCreateZoomSessionMutation__
 *
 * To run a mutation, you first call `useCreateZoomSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateZoomSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createZoomSessionMutation, { data, loading, error }] = useCreateZoomSessionMutation({
 *   variables: {
 *      classUid: // value for 'classUid'
 *      audioCompatibilityMode: // value for 'audioCompatibilityMode'
 *   },
 * });
 */
export function useCreateZoomSessionMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateZoomSessionMutation,
        CreateZoomSessionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateZoomSessionMutation,
        CreateZoomSessionMutationVariables
    >(CreateZoomSessionDocument, options);
}
export type CreateZoomSessionMutationHookResult = ReturnType<typeof useCreateZoomSessionMutation>;
export type CreateZoomSessionMutationResult = Apollo.MutationResult<CreateZoomSessionMutation>;
export type CreateZoomSessionMutationOptions = Apollo.BaseMutationOptions<
    CreateZoomSessionMutation,
    CreateZoomSessionMutationVariables
>;
export const CreateDemoZoomSessionDocument = gql`
    mutation createDemoZoomSession($classUid: String!, $audioCompatibilityMode: Boolean = false) {
        createZoomSession: createDemoZoomSession(demoClassUid: $classUid) {
            id
            zoomSessionName
            token(audioCompatibilityMode: $audioCompatibilityMode)
        }
    }
`;
export type CreateDemoZoomSessionMutationFn = Apollo.MutationFunction<
    CreateDemoZoomSessionMutation,
    CreateDemoZoomSessionMutationVariables
>;

/**
 * __useCreateDemoZoomSessionMutation__
 *
 * To run a mutation, you first call `useCreateDemoZoomSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDemoZoomSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDemoZoomSessionMutation, { data, loading, error }] = useCreateDemoZoomSessionMutation({
 *   variables: {
 *      classUid: // value for 'classUid'
 *      audioCompatibilityMode: // value for 'audioCompatibilityMode'
 *   },
 * });
 */
export function useCreateDemoZoomSessionMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateDemoZoomSessionMutation,
        CreateDemoZoomSessionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateDemoZoomSessionMutation,
        CreateDemoZoomSessionMutationVariables
    >(CreateDemoZoomSessionDocument, options);
}
export type CreateDemoZoomSessionMutationHookResult = ReturnType<
    typeof useCreateDemoZoomSessionMutation
>;
export type CreateDemoZoomSessionMutationResult =
    Apollo.MutationResult<CreateDemoZoomSessionMutation>;
export type CreateDemoZoomSessionMutationOptions = Apollo.BaseMutationOptions<
    CreateDemoZoomSessionMutation,
    CreateDemoZoomSessionMutationVariables
>;
export const LogClassroomParticipantDocument = gql`
    mutation logClassroomParticipant($participant: ZoomSessionParticipantInput!) {
        setCurrentClassroomUser(input: $participant) {
            id
            status
            sdkUserId
            user {
                id
            }
        }
    }
`;
export type LogClassroomParticipantMutationFn = Apollo.MutationFunction<
    LogClassroomParticipantMutation,
    LogClassroomParticipantMutationVariables
>;

/**
 * __useLogClassroomParticipantMutation__
 *
 * To run a mutation, you first call `useLogClassroomParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogClassroomParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logClassroomParticipantMutation, { data, loading, error }] = useLogClassroomParticipantMutation({
 *   variables: {
 *      participant: // value for 'participant'
 *   },
 * });
 */
export function useLogClassroomParticipantMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        LogClassroomParticipantMutation,
        LogClassroomParticipantMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        LogClassroomParticipantMutation,
        LogClassroomParticipantMutationVariables
    >(LogClassroomParticipantDocument, options);
}
export type LogClassroomParticipantMutationHookResult = ReturnType<
    typeof useLogClassroomParticipantMutation
>;
export type LogClassroomParticipantMutationResult =
    Apollo.MutationResult<LogClassroomParticipantMutation>;
export type LogClassroomParticipantMutationOptions = Apollo.BaseMutationOptions<
    LogClassroomParticipantMutation,
    LogClassroomParticipantMutationVariables
>;
export const PrivatePageDataDocument = gql`
    query privatePageData($classUid: String!) {
        class(id: $classUid) {
            ...classroomClassDetails
        }
        currentUser {
            id
            uid
            firstname
        }
        ...backgroundOptions
    }
    ${ClassroomClassDetailsFragmentDoc}
    ${BackgroundOptionsFragmentDoc}
`;

/**
 * __usePrivatePageDataQuery__
 *
 * To run a query within a React component, call `usePrivatePageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivatePageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivatePageDataQuery({
 *   variables: {
 *      classUid: // value for 'classUid'
 *   },
 * });
 */
export function usePrivatePageDataQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        PrivatePageDataQuery,
        PrivatePageDataQueryVariables
    > &
        ({ variables: PrivatePageDataQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PrivatePageDataQuery, PrivatePageDataQueryVariables>(
        PrivatePageDataDocument,
        options,
    );
}
export function usePrivatePageDataLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PrivatePageDataQuery,
        PrivatePageDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PrivatePageDataQuery, PrivatePageDataQueryVariables>(
        PrivatePageDataDocument,
        options,
    );
}
export function usePrivatePageDataSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        PrivatePageDataQuery,
        PrivatePageDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<PrivatePageDataQuery, PrivatePageDataQueryVariables>(
        PrivatePageDataDocument,
        options,
    );
}
export type PrivatePageDataQueryHookResult = ReturnType<typeof usePrivatePageDataQuery>;
export type PrivatePageDataLazyQueryHookResult = ReturnType<typeof usePrivatePageDataLazyQuery>;
export type PrivatePageDataSuspenseQueryHookResult = ReturnType<
    typeof usePrivatePageDataSuspenseQuery
>;
export type PrivatePageDataQueryResult = Apollo.QueryResult<
    PrivatePageDataQuery,
    PrivatePageDataQueryVariables
>;
export const PrivatePageDemoDataDocument = gql`
    query privatePageDemoData {
        currentUser {
            id
            uid
            firstname
        }
        teacher {
            ...teacherParticipantPhoto
        }
        ...backgroundOptions
    }
    ${TeacherParticipantPhotoFragmentDoc}
    ${BackgroundOptionsFragmentDoc}
`;

/**
 * __usePrivatePageDemoDataQuery__
 *
 * To run a query within a React component, call `usePrivatePageDemoDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivatePageDemoDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivatePageDemoDataQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrivatePageDemoDataQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PrivatePageDemoDataQuery,
        PrivatePageDemoDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PrivatePageDemoDataQuery, PrivatePageDemoDataQueryVariables>(
        PrivatePageDemoDataDocument,
        options,
    );
}
export function usePrivatePageDemoDataLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PrivatePageDemoDataQuery,
        PrivatePageDemoDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PrivatePageDemoDataQuery,
        PrivatePageDemoDataQueryVariables
    >(PrivatePageDemoDataDocument, options);
}
export function usePrivatePageDemoDataSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        PrivatePageDemoDataQuery,
        PrivatePageDemoDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        PrivatePageDemoDataQuery,
        PrivatePageDemoDataQueryVariables
    >(PrivatePageDemoDataDocument, options);
}
export type PrivatePageDemoDataQueryHookResult = ReturnType<typeof usePrivatePageDemoDataQuery>;
export type PrivatePageDemoDataLazyQueryHookResult = ReturnType<
    typeof usePrivatePageDemoDataLazyQuery
>;
export type PrivatePageDemoDataSuspenseQueryHookResult = ReturnType<
    typeof usePrivatePageDemoDataSuspenseQuery
>;
export type PrivatePageDemoDataQueryResult = Apollo.QueryResult<
    PrivatePageDemoDataQuery,
    PrivatePageDemoDataQueryVariables
>;
export const VideoClientProviderDataDocument = gql`
    query videoClientProviderData {
        currentUser {
            id
            firstname
        }
    }
`;

/**
 * __useVideoClientProviderDataQuery__
 *
 * To run a query within a React component, call `useVideoClientProviderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoClientProviderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoClientProviderDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useVideoClientProviderDataQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        VideoClientProviderDataQuery,
        VideoClientProviderDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        VideoClientProviderDataQuery,
        VideoClientProviderDataQueryVariables
    >(VideoClientProviderDataDocument, options);
}
export function useVideoClientProviderDataLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        VideoClientProviderDataQuery,
        VideoClientProviderDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        VideoClientProviderDataQuery,
        VideoClientProviderDataQueryVariables
    >(VideoClientProviderDataDocument, options);
}
export function useVideoClientProviderDataSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        VideoClientProviderDataQuery,
        VideoClientProviderDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        VideoClientProviderDataQuery,
        VideoClientProviderDataQueryVariables
    >(VideoClientProviderDataDocument, options);
}
export type VideoClientProviderDataQueryHookResult = ReturnType<
    typeof useVideoClientProviderDataQuery
>;
export type VideoClientProviderDataLazyQueryHookResult = ReturnType<
    typeof useVideoClientProviderDataLazyQuery
>;
export type VideoClientProviderDataSuspenseQueryHookResult = ReturnType<
    typeof useVideoClientProviderDataSuspenseQuery
>;
export type VideoClientProviderDataQueryResult = Apollo.QueryResult<
    VideoClientProviderDataQuery,
    VideoClientProviderDataQueryVariables
>;
export const PageMetaDataDocument = gql`
    query pageMetaData($classUid: String!) {
        class(id: $classUid) {
            uid
            lesson {
                title
            }
        }
    }
`;

/**
 * __usePageMetaDataQuery__
 *
 * To run a query within a React component, call `usePageMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageMetaDataQuery({
 *   variables: {
 *      classUid: // value for 'classUid'
 *   },
 * });
 */
export function usePageMetaDataQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<PageMetaDataQuery, PageMetaDataQueryVariables> &
        ({ variables: PageMetaDataQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PageMetaDataQuery, PageMetaDataQueryVariables>(
        PageMetaDataDocument,
        options,
    );
}
export function usePageMetaDataLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PageMetaDataQuery,
        PageMetaDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PageMetaDataQuery, PageMetaDataQueryVariables>(
        PageMetaDataDocument,
        options,
    );
}
export function usePageMetaDataSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        PageMetaDataQuery,
        PageMetaDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<PageMetaDataQuery, PageMetaDataQueryVariables>(
        PageMetaDataDocument,
        options,
    );
}
export type PageMetaDataQueryHookResult = ReturnType<typeof usePageMetaDataQuery>;
export type PageMetaDataLazyQueryHookResult = ReturnType<typeof usePageMetaDataLazyQuery>;
export type PageMetaDataSuspenseQueryHookResult = ReturnType<typeof usePageMetaDataSuspenseQuery>;
export type PageMetaDataQueryResult = Apollo.QueryResult<
    PageMetaDataQuery,
    PageMetaDataQueryVariables
>;
export const DemoEntryDocument = gql`
    query demoEntry {
        currentUser {
            id
            firstname
        }
    }
`;

/**
 * __useDemoEntryQuery__
 *
 * To run a query within a React component, call `useDemoEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoEntryQuery({
 *   variables: {
 *   },
 * });
 */
export function useDemoEntryQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<DemoEntryQuery, DemoEntryQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<DemoEntryQuery, DemoEntryQueryVariables>(
        DemoEntryDocument,
        options,
    );
}
export function useDemoEntryLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DemoEntryQuery, DemoEntryQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<DemoEntryQuery, DemoEntryQueryVariables>(
        DemoEntryDocument,
        options,
    );
}
export function useDemoEntrySuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        DemoEntryQuery,
        DemoEntryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<DemoEntryQuery, DemoEntryQueryVariables>(
        DemoEntryDocument,
        options,
    );
}
export type DemoEntryQueryHookResult = ReturnType<typeof useDemoEntryQuery>;
export type DemoEntryLazyQueryHookResult = ReturnType<typeof useDemoEntryLazyQuery>;
export type DemoEntrySuspenseQueryHookResult = ReturnType<typeof useDemoEntrySuspenseQuery>;
export type DemoEntryQueryResult = Apollo.QueryResult<DemoEntryQuery, DemoEntryQueryVariables>;
export const ClassFeedbackDocument = gql`
    query ClassFeedback($classUid: String!) {
        class(id: $classUid) {
            ...ClassFeedback
        }
        appointmentLog(classId: $classUid) {
            ...AppointmentLog
        }
        shouldShowClassDetailsPageKysBanner: isWidgetEligibleForDisplay(
            widgetClass: StudentFillProfileReminderClassDetailsPageBanner
        )
    }
    ${ClassFeedbackFragmentDoc}
    ${AppointmentLogFragmentDoc}
`;

/**
 * __useClassFeedbackQuery__
 *
 * To run a query within a React component, call `useClassFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassFeedbackQuery({
 *   variables: {
 *      classUid: // value for 'classUid'
 *   },
 * });
 */
export function useClassFeedbackQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ClassFeedbackQuery,
        ClassFeedbackQueryVariables
    > &
        ({ variables: ClassFeedbackQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ClassFeedbackQuery, ClassFeedbackQueryVariables>(
        ClassFeedbackDocument,
        options,
    );
}
export function useClassFeedbackLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ClassFeedbackQuery,
        ClassFeedbackQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ClassFeedbackQuery, ClassFeedbackQueryVariables>(
        ClassFeedbackDocument,
        options,
    );
}
export function useClassFeedbackSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ClassFeedbackQuery,
        ClassFeedbackQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ClassFeedbackQuery, ClassFeedbackQueryVariables>(
        ClassFeedbackDocument,
        options,
    );
}
export type ClassFeedbackQueryHookResult = ReturnType<typeof useClassFeedbackQuery>;
export type ClassFeedbackLazyQueryHookResult = ReturnType<typeof useClassFeedbackLazyQuery>;
export type ClassFeedbackSuspenseQueryHookResult = ReturnType<typeof useClassFeedbackSuspenseQuery>;
export type ClassFeedbackQueryResult = Apollo.QueryResult<
    ClassFeedbackQuery,
    ClassFeedbackQueryVariables
>;
export const LearningUnitPracticeResourcesDocument = gql`
    query learningUnitPracticeResources(
        $learningUnitId: Int!
        $lessonId: Int!
        $includeH5PExercises: Boolean = false
    ) {
        learningUnit(learningUnitId: $learningUnitId) {
            id
            name
            chapter
            module {
                id
                name
            }
            ...FlashCardsLearningUnit
            ...LearningUnitExercise
        }
        practiceItem(lessonId: $lessonId) {
            ...LearningUnitPracticeItem
        }
    }
    ${FlashCardsLearningUnitFragmentDoc}
    ${LearningUnitExerciseFragmentDoc}
    ${LearningUnitPracticeItemFragmentDoc}
`;

/**
 * __useLearningUnitPracticeResourcesQuery__
 *
 * To run a query within a React component, call `useLearningUnitPracticeResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningUnitPracticeResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningUnitPracticeResourcesQuery({
 *   variables: {
 *      learningUnitId: // value for 'learningUnitId'
 *      lessonId: // value for 'lessonId'
 *      includeH5PExercises: // value for 'includeH5PExercises'
 *   },
 * });
 */
export function useLearningUnitPracticeResourcesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        LearningUnitPracticeResourcesQuery,
        LearningUnitPracticeResourcesQueryVariables
    > &
        (
            | { variables: LearningUnitPracticeResourcesQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        LearningUnitPracticeResourcesQuery,
        LearningUnitPracticeResourcesQueryVariables
    >(LearningUnitPracticeResourcesDocument, options);
}
export function useLearningUnitPracticeResourcesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        LearningUnitPracticeResourcesQuery,
        LearningUnitPracticeResourcesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        LearningUnitPracticeResourcesQuery,
        LearningUnitPracticeResourcesQueryVariables
    >(LearningUnitPracticeResourcesDocument, options);
}
export function useLearningUnitPracticeResourcesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        LearningUnitPracticeResourcesQuery,
        LearningUnitPracticeResourcesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        LearningUnitPracticeResourcesQuery,
        LearningUnitPracticeResourcesQueryVariables
    >(LearningUnitPracticeResourcesDocument, options);
}
export type LearningUnitPracticeResourcesQueryHookResult = ReturnType<
    typeof useLearningUnitPracticeResourcesQuery
>;
export type LearningUnitPracticeResourcesLazyQueryHookResult = ReturnType<
    typeof useLearningUnitPracticeResourcesLazyQuery
>;
export type LearningUnitPracticeResourcesSuspenseQueryHookResult = ReturnType<
    typeof useLearningUnitPracticeResourcesSuspenseQuery
>;
export type LearningUnitPracticeResourcesQueryResult = Apollo.QueryResult<
    LearningUnitPracticeResourcesQuery,
    LearningUnitPracticeResourcesQueryVariables
>;
export const StudentClassDocument = gql`
    query studentClass($classId: String!) {
        class(id: $classId) {
            ...StudentClassKlass
        }
    }
    ${StudentClassKlassFragmentDoc}
`;

/**
 * __useStudentClassQuery__
 *
 * To run a query within a React component, call `useStudentClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentClassQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useStudentClassQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<StudentClassQuery, StudentClassQueryVariables> &
        ({ variables: StudentClassQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<StudentClassQuery, StudentClassQueryVariables>(
        StudentClassDocument,
        options,
    );
}
export function useStudentClassLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        StudentClassQuery,
        StudentClassQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<StudentClassQuery, StudentClassQueryVariables>(
        StudentClassDocument,
        options,
    );
}
export function useStudentClassSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        StudentClassQuery,
        StudentClassQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<StudentClassQuery, StudentClassQueryVariables>(
        StudentClassDocument,
        options,
    );
}
export type StudentClassQueryHookResult = ReturnType<typeof useStudentClassQuery>;
export type StudentClassLazyQueryHookResult = ReturnType<typeof useStudentClassLazyQuery>;
export type StudentClassSuspenseQueryHookResult = ReturnType<typeof useStudentClassSuspenseQuery>;
export type StudentClassQueryResult = Apollo.QueryResult<
    StudentClassQuery,
    StudentClassQueryVariables
>;
export const StudentLessonDocument = gql`
    query studentLesson($lessonId: Int!) {
        lesson(id: $lessonId) {
            ...StudentLessonLesson
        }
        lastLessonAppointment(lessonId: $lessonId) {
            ...LessonDetailsTileAppointment
        }
    }
    ${StudentLessonLessonFragmentDoc}
    ${LessonDetailsTileAppointmentFragmentDoc}
`;

/**
 * __useStudentLessonQuery__
 *
 * To run a query within a React component, call `useStudentLessonQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentLessonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentLessonQuery({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useStudentLessonQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        StudentLessonQuery,
        StudentLessonQueryVariables
    > &
        ({ variables: StudentLessonQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<StudentLessonQuery, StudentLessonQueryVariables>(
        StudentLessonDocument,
        options,
    );
}
export function useStudentLessonLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        StudentLessonQuery,
        StudentLessonQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<StudentLessonQuery, StudentLessonQueryVariables>(
        StudentLessonDocument,
        options,
    );
}
export function useStudentLessonSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        StudentLessonQuery,
        StudentLessonQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<StudentLessonQuery, StudentLessonQueryVariables>(
        StudentLessonDocument,
        options,
    );
}
export type StudentLessonQueryHookResult = ReturnType<typeof useStudentLessonQuery>;
export type StudentLessonLazyQueryHookResult = ReturnType<typeof useStudentLessonLazyQuery>;
export type StudentLessonSuspenseQueryHookResult = ReturnType<typeof useStudentLessonSuspenseQuery>;
export type StudentLessonQueryResult = Apollo.QueryResult<
    StudentLessonQuery,
    StudentLessonQueryVariables
>;
export const StudentCoursePageDocument = gql`
    query studentCoursePage($sectionId: Int!, $moduleId: Int!, $curriculumId: Int!) {
        sections {
            ...CourseSection
        }
        activeSection: section(id: $sectionId) @client {
            ...CourseSection
        }
        sectionCurricula {
            ...SectionCurriculum
        }
        studentModules(curriculumId: $curriculumId) {
            ...HeaderStudentModule
        }
        activeModule: studentModule(id: $moduleId) @client {
            ...HeaderStudentModule
        }
        learningUnits(moduleId: $moduleId) {
            ...CourseOverviewLearningUnit
        }
        suggestOpenDiscussion
    }
    ${CourseSectionFragmentDoc}
    ${SectionCurriculumFragmentDoc}
    ${HeaderStudentModuleFragmentDoc}
    ${CourseOverviewLearningUnitFragmentDoc}
`;

/**
 * __useStudentCoursePageQuery__
 *
 * To run a query within a React component, call `useStudentCoursePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentCoursePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentCoursePageQuery({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      moduleId: // value for 'moduleId'
 *      curriculumId: // value for 'curriculumId'
 *   },
 * });
 */
export function useStudentCoursePageQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        StudentCoursePageQuery,
        StudentCoursePageQueryVariables
    > &
        ({ variables: StudentCoursePageQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<StudentCoursePageQuery, StudentCoursePageQueryVariables>(
        StudentCoursePageDocument,
        options,
    );
}
export function useStudentCoursePageLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        StudentCoursePageQuery,
        StudentCoursePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<StudentCoursePageQuery, StudentCoursePageQueryVariables>(
        StudentCoursePageDocument,
        options,
    );
}
export function useStudentCoursePageSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        StudentCoursePageQuery,
        StudentCoursePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        StudentCoursePageQuery,
        StudentCoursePageQueryVariables
    >(StudentCoursePageDocument, options);
}
export type StudentCoursePageQueryHookResult = ReturnType<typeof useStudentCoursePageQuery>;
export type StudentCoursePageLazyQueryHookResult = ReturnType<typeof useStudentCoursePageLazyQuery>;
export type StudentCoursePageSuspenseQueryHookResult = ReturnType<
    typeof useStudentCoursePageSuspenseQuery
>;
export type StudentCoursePageQueryResult = Apollo.QueryResult<
    StudentCoursePageQuery,
    StudentCoursePageQueryVariables
>;
export const TodayPageDocument = gql`
    query todayPage(
        $appointmentsLimit: Int!
        $appointmentsOffset: Int
        $appointmentsFromDate: DateTime!
        $appointmentsToDate: DateTime!
        $appointmentsCountFromDate: DateTime!
        $appointmentsSort: String
        $appointmentStatus: String!
        $goalWidgetPage: Int!
        $recapYear: Int!
    ) {
        studentLastTwoWeeksClassesPendingReview {
            ...ReviewClassCardAppointment
        }
        studentNotification {
            ...StudentNotification
        }
        currentStudentSprint {
            ...CurrentSprint
        }
        sectionCurricula {
            ...SectionCurriculum
        }
        upcomingAppointmentsCount(
            fromDate: $appointmentsCountFromDate
            appointmentStatus: $appointmentStatus
        )
        appointmentsList(
            limit: $appointmentsLimit
            offset: $appointmentsOffset
            fromDate: $appointmentsFromDate
            toDate: $appointmentsToDate
            sort: $appointmentsSort
            appointmentStatus: $appointmentStatus
        ) {
            ...UpcomingAppointmentCard
        }
        student {
            id
            hasBookedClass
        }
        studentAchievements {
            ...Achievement
        }
        ownReferral {
            ...OwnReferral
        }
        activeGoal {
            ...StudentWeeklyGoal
        }
        studentGoalWidgetData(page: $goalWidgetPage) {
            ...StudentGoalWidgetData
        }
        activityChallenges(canJoinOnly: true) {
            ...BaseActivityChallenge
        }
        studentYearlySummary(year: $recapYear) {
            ...YearlyActivityAllSummary
        }
        shouldShowTodayPageKysBanner: isWidgetEligibleForDisplay(
            widgetClass: StudentFillProfileReminderTodayPageBanner
        )
    }
    ${ReviewClassCardAppointmentFragmentDoc}
    ${StudentNotificationFragmentDoc}
    ${CurrentSprintFragmentDoc}
    ${SectionCurriculumFragmentDoc}
    ${UpcomingAppointmentCardFragmentDoc}
    ${AchievementFragmentDoc}
    ${OwnReferralFragmentDoc}
    ${StudentWeeklyGoalFragmentDoc}
    ${StudentGoalWidgetDataFragmentDoc}
    ${BaseActivityChallengeFragmentDoc}
    ${YearlyActivityAllSummaryFragmentDoc}
`;

/**
 * __useTodayPageQuery__
 *
 * To run a query within a React component, call `useTodayPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodayPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodayPageQuery({
 *   variables: {
 *      appointmentsLimit: // value for 'appointmentsLimit'
 *      appointmentsOffset: // value for 'appointmentsOffset'
 *      appointmentsFromDate: // value for 'appointmentsFromDate'
 *      appointmentsToDate: // value for 'appointmentsToDate'
 *      appointmentsCountFromDate: // value for 'appointmentsCountFromDate'
 *      appointmentsSort: // value for 'appointmentsSort'
 *      appointmentStatus: // value for 'appointmentStatus'
 *      goalWidgetPage: // value for 'goalWidgetPage'
 *      recapYear: // value for 'recapYear'
 *   },
 * });
 */
export function useTodayPageQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<TodayPageQuery, TodayPageQueryVariables> &
        ({ variables: TodayPageQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TodayPageQuery, TodayPageQueryVariables>(
        TodayPageDocument,
        options,
    );
}
export function useTodayPageLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TodayPageQuery, TodayPageQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TodayPageQuery, TodayPageQueryVariables>(
        TodayPageDocument,
        options,
    );
}
export function useTodayPageSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TodayPageQuery,
        TodayPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<TodayPageQuery, TodayPageQueryVariables>(
        TodayPageDocument,
        options,
    );
}
export type TodayPageQueryHookResult = ReturnType<typeof useTodayPageQuery>;
export type TodayPageLazyQueryHookResult = ReturnType<typeof useTodayPageLazyQuery>;
export type TodayPageSuspenseQueryHookResult = ReturnType<typeof useTodayPageSuspenseQuery>;
export type TodayPageQueryResult = Apollo.QueryResult<TodayPageQuery, TodayPageQueryVariables>;
export const FlashcardReportDialogSubmitDocument = gql`
    mutation FlashcardReportDialogSubmit(
        $answersIds: [String!]!
        $feedback: String
        $vocabularyItemId: String!
    ) {
        submitChapterFlashcardsFeedback(
            feedbackInput: {
                answersIds: $answersIds
                feedback: $feedback
                vocabularyItemId: $vocabularyItemId
            }
        )
    }
`;
export type FlashcardReportDialogSubmitMutationFn = Apollo.MutationFunction<
    FlashcardReportDialogSubmitMutation,
    FlashcardReportDialogSubmitMutationVariables
>;

/**
 * __useFlashcardReportDialogSubmitMutation__
 *
 * To run a mutation, you first call `useFlashcardReportDialogSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFlashcardReportDialogSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [flashcardReportDialogSubmitMutation, { data, loading, error }] = useFlashcardReportDialogSubmitMutation({
 *   variables: {
 *      answersIds: // value for 'answersIds'
 *      feedback: // value for 'feedback'
 *      vocabularyItemId: // value for 'vocabularyItemId'
 *   },
 * });
 */
export function useFlashcardReportDialogSubmitMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        FlashcardReportDialogSubmitMutation,
        FlashcardReportDialogSubmitMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        FlashcardReportDialogSubmitMutation,
        FlashcardReportDialogSubmitMutationVariables
    >(FlashcardReportDialogSubmitDocument, options);
}
export type FlashcardReportDialogSubmitMutationHookResult = ReturnType<
    typeof useFlashcardReportDialogSubmitMutation
>;
export type FlashcardReportDialogSubmitMutationResult =
    Apollo.MutationResult<FlashcardReportDialogSubmitMutation>;
export type FlashcardReportDialogSubmitMutationOptions = Apollo.BaseMutationOptions<
    FlashcardReportDialogSubmitMutation,
    FlashcardReportDialogSubmitMutationVariables
>;
export const SetFlashcardStatusDocument = gql`
    mutation setFlashcardStatus($itemId: String!, $status: VocabularyStatus!) {
        setFlashcardStatus(vocabularyItemId: $itemId, status: $status) {
            id
            flashcardIsCompleted
        }
    }
`;
export type SetFlashcardStatusMutationFn = Apollo.MutationFunction<
    SetFlashcardStatusMutation,
    SetFlashcardStatusMutationVariables
>;

/**
 * __useSetFlashcardStatusMutation__
 *
 * To run a mutation, you first call `useSetFlashcardStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFlashcardStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFlashcardStatusMutation, { data, loading, error }] = useSetFlashcardStatusMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetFlashcardStatusMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SetFlashcardStatusMutation,
        SetFlashcardStatusMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SetFlashcardStatusMutation,
        SetFlashcardStatusMutationVariables
    >(SetFlashcardStatusDocument, options);
}
export type SetFlashcardStatusMutationHookResult = ReturnType<typeof useSetFlashcardStatusMutation>;
export type SetFlashcardStatusMutationResult = Apollo.MutationResult<SetFlashcardStatusMutation>;
export type SetFlashcardStatusMutationOptions = Apollo.BaseMutationOptions<
    SetFlashcardStatusMutation,
    SetFlashcardStatusMutationVariables
>;
export const ResetFlashcardStatusDocument = gql`
    mutation resetFlashcardStatus($learningUnitId: Int!) {
        resetFlashcardsStatus(learningUnitId: $learningUnitId)
    }
`;
export type ResetFlashcardStatusMutationFn = Apollo.MutationFunction<
    ResetFlashcardStatusMutation,
    ResetFlashcardStatusMutationVariables
>;

/**
 * __useResetFlashcardStatusMutation__
 *
 * To run a mutation, you first call `useResetFlashcardStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetFlashcardStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetFlashcardStatusMutation, { data, loading, error }] = useResetFlashcardStatusMutation({
 *   variables: {
 *      learningUnitId: // value for 'learningUnitId'
 *   },
 * });
 */
export function useResetFlashcardStatusMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        ResetFlashcardStatusMutation,
        ResetFlashcardStatusMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        ResetFlashcardStatusMutation,
        ResetFlashcardStatusMutationVariables
    >(ResetFlashcardStatusDocument, options);
}
export type ResetFlashcardStatusMutationHookResult = ReturnType<
    typeof useResetFlashcardStatusMutation
>;
export type ResetFlashcardStatusMutationResult =
    Apollo.MutationResult<ResetFlashcardStatusMutation>;
export type ResetFlashcardStatusMutationOptions = Apollo.BaseMutationOptions<
    ResetFlashcardStatusMutation,
    ResetFlashcardStatusMutationVariables
>;
export const LearningUnitFlashcardsDocument = gql`
    query LearningUnitFlashcards($learningUnitId: Int!) {
        learningUnit(learningUnitId: $learningUnitId) {
            ...FlashCardsLearningUnit
            vocabularyItems {
                audioUrls {
                    target
                    translation
                }
            }
        }
    }
    ${FlashCardsLearningUnitFragmentDoc}
`;

/**
 * __useLearningUnitFlashcardsQuery__
 *
 * To run a query within a React component, call `useLearningUnitFlashcardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningUnitFlashcardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningUnitFlashcardsQuery({
 *   variables: {
 *      learningUnitId: // value for 'learningUnitId'
 *   },
 * });
 */
export function useLearningUnitFlashcardsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        LearningUnitFlashcardsQuery,
        LearningUnitFlashcardsQueryVariables
    > &
        ({ variables: LearningUnitFlashcardsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        LearningUnitFlashcardsQuery,
        LearningUnitFlashcardsQueryVariables
    >(LearningUnitFlashcardsDocument, options);
}
export function useLearningUnitFlashcardsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        LearningUnitFlashcardsQuery,
        LearningUnitFlashcardsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        LearningUnitFlashcardsQuery,
        LearningUnitFlashcardsQueryVariables
    >(LearningUnitFlashcardsDocument, options);
}
export function useLearningUnitFlashcardsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        LearningUnitFlashcardsQuery,
        LearningUnitFlashcardsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        LearningUnitFlashcardsQuery,
        LearningUnitFlashcardsQueryVariables
    >(LearningUnitFlashcardsDocument, options);
}
export type LearningUnitFlashcardsQueryHookResult = ReturnType<
    typeof useLearningUnitFlashcardsQuery
>;
export type LearningUnitFlashcardsLazyQueryHookResult = ReturnType<
    typeof useLearningUnitFlashcardsLazyQuery
>;
export type LearningUnitFlashcardsSuspenseQueryHookResult = ReturnType<
    typeof useLearningUnitFlashcardsSuspenseQuery
>;
export type LearningUnitFlashcardsQueryResult = Apollo.QueryResult<
    LearningUnitFlashcardsQuery,
    LearningUnitFlashcardsQueryVariables
>;
export const ActivitiesProfilePageDocument = gql`
    query activitiesProfilePage($vocabTrainerEnabled: Boolean!) {
        student {
            id
            completedClasses
            module {
                id
                name
                totalClasses
                completedClasses
            }
            section {
                id
                name
            }
            lastCertificate {
                module {
                    id
                    name
                }
            }
        }
        vocabTrainer: quizEngineProgress @include(if: $vocabTrainerEnabled) {
            known
        }
    }
`;

/**
 * __useActivitiesProfilePageQuery__
 *
 * To run a query within a React component, call `useActivitiesProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivitiesProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivitiesProfilePageQuery({
 *   variables: {
 *      vocabTrainerEnabled: // value for 'vocabTrainerEnabled'
 *   },
 * });
 */
export function useActivitiesProfilePageQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ActivitiesProfilePageQuery,
        ActivitiesProfilePageQueryVariables
    > &
        ({ variables: ActivitiesProfilePageQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        ActivitiesProfilePageQuery,
        ActivitiesProfilePageQueryVariables
    >(ActivitiesProfilePageDocument, options);
}
export function useActivitiesProfilePageLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ActivitiesProfilePageQuery,
        ActivitiesProfilePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ActivitiesProfilePageQuery,
        ActivitiesProfilePageQueryVariables
    >(ActivitiesProfilePageDocument, options);
}
export function useActivitiesProfilePageSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ActivitiesProfilePageQuery,
        ActivitiesProfilePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ActivitiesProfilePageQuery,
        ActivitiesProfilePageQueryVariables
    >(ActivitiesProfilePageDocument, options);
}
export type ActivitiesProfilePageQueryHookResult = ReturnType<typeof useActivitiesProfilePageQuery>;
export type ActivitiesProfilePageLazyQueryHookResult = ReturnType<
    typeof useActivitiesProfilePageLazyQuery
>;
export type ActivitiesProfilePageSuspenseQueryHookResult = ReturnType<
    typeof useActivitiesProfilePageSuspenseQuery
>;
export type ActivitiesProfilePageQueryResult = Apollo.QueryResult<
    ActivitiesProfilePageQuery,
    ActivitiesProfilePageQueryVariables
>;
export const SetProfileFeedbackDocument = gql`
    mutation SetProfileFeedback($feedback: Boolean!) {
        setProfileFeedback(feedback: $feedback)
    }
`;
export type SetProfileFeedbackMutationFn = Apollo.MutationFunction<
    SetProfileFeedbackMutation,
    SetProfileFeedbackMutationVariables
>;

/**
 * __useSetProfileFeedbackMutation__
 *
 * To run a mutation, you first call `useSetProfileFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProfileFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProfileFeedbackMutation, { data, loading, error }] = useSetProfileFeedbackMutation({
 *   variables: {
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useSetProfileFeedbackMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SetProfileFeedbackMutation,
        SetProfileFeedbackMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SetProfileFeedbackMutation,
        SetProfileFeedbackMutationVariables
    >(SetProfileFeedbackDocument, options);
}
export type SetProfileFeedbackMutationHookResult = ReturnType<typeof useSetProfileFeedbackMutation>;
export type SetProfileFeedbackMutationResult = Apollo.MutationResult<SetProfileFeedbackMutation>;
export type SetProfileFeedbackMutationOptions = Apollo.BaseMutationOptions<
    SetProfileFeedbackMutation,
    SetProfileFeedbackMutationVariables
>;
export const StudentProfileFeedbackDocument = gql`
    query studentProfileFeedback {
        student {
            studentProfile {
                profileFeedback
            }
        }
    }
`;

/**
 * __useStudentProfileFeedbackQuery__
 *
 * To run a query within a React component, call `useStudentProfileFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentProfileFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentProfileFeedbackQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentProfileFeedbackQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        StudentProfileFeedbackQuery,
        StudentProfileFeedbackQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        StudentProfileFeedbackQuery,
        StudentProfileFeedbackQueryVariables
    >(StudentProfileFeedbackDocument, options);
}
export function useStudentProfileFeedbackLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        StudentProfileFeedbackQuery,
        StudentProfileFeedbackQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        StudentProfileFeedbackQuery,
        StudentProfileFeedbackQueryVariables
    >(StudentProfileFeedbackDocument, options);
}
export function useStudentProfileFeedbackSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        StudentProfileFeedbackQuery,
        StudentProfileFeedbackQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        StudentProfileFeedbackQuery,
        StudentProfileFeedbackQueryVariables
    >(StudentProfileFeedbackDocument, options);
}
export type StudentProfileFeedbackQueryHookResult = ReturnType<
    typeof useStudentProfileFeedbackQuery
>;
export type StudentProfileFeedbackLazyQueryHookResult = ReturnType<
    typeof useStudentProfileFeedbackLazyQuery
>;
export type StudentProfileFeedbackSuspenseQueryHookResult = ReturnType<
    typeof useStudentProfileFeedbackSuspenseQuery
>;
export type StudentProfileFeedbackQueryResult = Apollo.QueryResult<
    StudentProfileFeedbackQuery,
    StudentProfileFeedbackQueryVariables
>;
export const StudentProfilePageDocument = gql`
    query studentProfilePage($recapEnabled: Boolean!, $recapYear: Int!) {
        student {
            id
            shortName
            firstName
            registrationDate
            photo {
                id
                urls {
                    default: medium
                    retina: medium2x
                }
            }
            completedClasses
            currentStudentModule {
                name
                completedClasses
                totalClasses
            }
            studentProfile {
                studentSkills {
                    sentiment
                    answer
                    count
                }
                whyAreYouLearning {
                    otherOption
                    answers
                }
                spokenLanguages {
                    language
                    languageFluency
                }
            }
            section {
                name
            }
            businessModelType
        }
        studentYearlySummary(year: $recapYear) @include(if: $recapEnabled) {
            year
        }
        studentAchievements {
            id
            slug
            claimedAt
        }
        studentModules {
            name
            completedClasses
            totalClasses
        }
        shouldShowProfilePageKysBanner: isWidgetEligibleForDisplay(
            widgetClass: StudentFillProfileReminderProfilePageBanner
        )
    }
`;

/**
 * __useStudentProfilePageQuery__
 *
 * To run a query within a React component, call `useStudentProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentProfilePageQuery({
 *   variables: {
 *      recapEnabled: // value for 'recapEnabled'
 *      recapYear: // value for 'recapYear'
 *   },
 * });
 */
export function useStudentProfilePageQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        StudentProfilePageQuery,
        StudentProfilePageQueryVariables
    > &
        ({ variables: StudentProfilePageQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<StudentProfilePageQuery, StudentProfilePageQueryVariables>(
        StudentProfilePageDocument,
        options,
    );
}
export function useStudentProfilePageLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        StudentProfilePageQuery,
        StudentProfilePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<StudentProfilePageQuery, StudentProfilePageQueryVariables>(
        StudentProfilePageDocument,
        options,
    );
}
export function useStudentProfilePageSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        StudentProfilePageQuery,
        StudentProfilePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        StudentProfilePageQuery,
        StudentProfilePageQueryVariables
    >(StudentProfilePageDocument, options);
}
export type StudentProfilePageQueryHookResult = ReturnType<typeof useStudentProfilePageQuery>;
export type StudentProfilePageLazyQueryHookResult = ReturnType<
    typeof useStudentProfilePageLazyQuery
>;
export type StudentProfilePageSuspenseQueryHookResult = ReturnType<
    typeof useStudentProfilePageSuspenseQuery
>;
export type StudentProfilePageQueryResult = Apollo.QueryResult<
    StudentProfilePageQuery,
    StudentProfilePageQueryVariables
>;
export const TeacherDetailsPageDocument = gql`
    query teacherDetailsPage($teacherUid: String!) {
        teacherProfile(teacherUserUid: $teacherUid) {
            ...TeacherPageProfile
        }
        teacherUpcomingClasses(teacherUserUid: $teacherUid) {
            ...BookableClass
        }
    }
    ${TeacherPageProfileFragmentDoc}
    ${BookableClassFragmentDoc}
`;

/**
 * __useTeacherDetailsPageQuery__
 *
 * To run a query within a React component, call `useTeacherDetailsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherDetailsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherDetailsPageQuery({
 *   variables: {
 *      teacherUid: // value for 'teacherUid'
 *   },
 * });
 */
export function useTeacherDetailsPageQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        TeacherDetailsPageQuery,
        TeacherDetailsPageQueryVariables
    > &
        ({ variables: TeacherDetailsPageQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TeacherDetailsPageQuery, TeacherDetailsPageQueryVariables>(
        TeacherDetailsPageDocument,
        options,
    );
}
export function useTeacherDetailsPageLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherDetailsPageQuery,
        TeacherDetailsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TeacherDetailsPageQuery, TeacherDetailsPageQueryVariables>(
        TeacherDetailsPageDocument,
        options,
    );
}
export function useTeacherDetailsPageSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherDetailsPageQuery,
        TeacherDetailsPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherDetailsPageQuery,
        TeacherDetailsPageQueryVariables
    >(TeacherDetailsPageDocument, options);
}
export type TeacherDetailsPageQueryHookResult = ReturnType<typeof useTeacherDetailsPageQuery>;
export type TeacherDetailsPageLazyQueryHookResult = ReturnType<
    typeof useTeacherDetailsPageLazyQuery
>;
export type TeacherDetailsPageSuspenseQueryHookResult = ReturnType<
    typeof useTeacherDetailsPageSuspenseQuery
>;
export type TeacherDetailsPageQueryResult = Apollo.QueryResult<
    TeacherDetailsPageQuery,
    TeacherDetailsPageQueryVariables
>;
export const TeacherOpenLogsCountDocument = gql`
    query teacherOpenLogsCount {
        teacherOpenLogsCount
    }
`;

/**
 * __useTeacherOpenLogsCountQuery__
 *
 * To run a query within a React component, call `useTeacherOpenLogsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherOpenLogsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherOpenLogsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherOpenLogsCountQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherOpenLogsCountQuery,
        TeacherOpenLogsCountQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TeacherOpenLogsCountQuery, TeacherOpenLogsCountQueryVariables>(
        TeacherOpenLogsCountDocument,
        options,
    );
}
export function useTeacherOpenLogsCountLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherOpenLogsCountQuery,
        TeacherOpenLogsCountQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        TeacherOpenLogsCountQuery,
        TeacherOpenLogsCountQueryVariables
    >(TeacherOpenLogsCountDocument, options);
}
export function useTeacherOpenLogsCountSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherOpenLogsCountQuery,
        TeacherOpenLogsCountQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherOpenLogsCountQuery,
        TeacherOpenLogsCountQueryVariables
    >(TeacherOpenLogsCountDocument, options);
}
export type TeacherOpenLogsCountQueryHookResult = ReturnType<typeof useTeacherOpenLogsCountQuery>;
export type TeacherOpenLogsCountLazyQueryHookResult = ReturnType<
    typeof useTeacherOpenLogsCountLazyQuery
>;
export type TeacherOpenLogsCountSuspenseQueryHookResult = ReturnType<
    typeof useTeacherOpenLogsCountSuspenseQuery
>;
export type TeacherOpenLogsCountQueryResult = Apollo.QueryResult<
    TeacherOpenLogsCountQuery,
    TeacherOpenLogsCountQueryVariables
>;
export const TeacherDocument = gql`
    query Teacher {
        teacher {
            ...Header
            teacherPreferences {
                timezone
                timeFormat
                weekStart
            }
        }
    }
    ${HeaderFragmentDoc}
`;

/**
 * __useTeacherQuery__
 *
 * To run a query within a React component, call `useTeacherQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<TeacherQuery, TeacherQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TeacherQuery, TeacherQueryVariables>(TeacherDocument, options);
}
export function useTeacherLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeacherQuery, TeacherQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TeacherQuery, TeacherQueryVariables>(
        TeacherDocument,
        options,
    );
}
export function useTeacherSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<TeacherQuery, TeacherQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<TeacherQuery, TeacherQueryVariables>(
        TeacherDocument,
        options,
    );
}
export type TeacherQueryHookResult = ReturnType<typeof useTeacherQuery>;
export type TeacherLazyQueryHookResult = ReturnType<typeof useTeacherLazyQuery>;
export type TeacherSuspenseQueryHookResult = ReturnType<typeof useTeacherSuspenseQuery>;
export type TeacherQueryResult = Apollo.QueryResult<TeacherQuery, TeacherQueryVariables>;
export const TeacherUidDocument = gql`
    query TeacherUid {
        teacher {
            id
            uid
        }
    }
`;

/**
 * __useTeacherUidQuery__
 *
 * To run a query within a React component, call `useTeacherUidQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherUidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherUidQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherUidQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<TeacherUidQuery, TeacherUidQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TeacherUidQuery, TeacherUidQueryVariables>(
        TeacherUidDocument,
        options,
    );
}
export function useTeacherUidLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeacherUidQuery, TeacherUidQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TeacherUidQuery, TeacherUidQueryVariables>(
        TeacherUidDocument,
        options,
    );
}
export function useTeacherUidSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherUidQuery,
        TeacherUidQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<TeacherUidQuery, TeacherUidQueryVariables>(
        TeacherUidDocument,
        options,
    );
}
export type TeacherUidQueryHookResult = ReturnType<typeof useTeacherUidQuery>;
export type TeacherUidLazyQueryHookResult = ReturnType<typeof useTeacherUidLazyQuery>;
export type TeacherUidSuspenseQueryHookResult = ReturnType<typeof useTeacherUidSuspenseQuery>;
export type TeacherUidQueryResult = Apollo.QueryResult<TeacherUidQuery, TeacherUidQueryVariables>;
export const CreateTestAdminDocument = gql`
    mutation createTestAdmin($features: [Feature]) {
        createTestAdmin(features: $features) {
            ...CreatedAdmin
        }
    }
    ${CreatedAdminFragmentDoc}
`;
export type CreateTestAdminMutationFn = Apollo.MutationFunction<
    CreateTestAdminMutation,
    CreateTestAdminMutationVariables
>;

/**
 * __useCreateTestAdminMutation__
 *
 * To run a mutation, you first call `useCreateTestAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestAdminMutation, { data, loading, error }] = useCreateTestAdminMutation({
 *   variables: {
 *      features: // value for 'features'
 *   },
 * });
 */
export function useCreateTestAdminMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateTestAdminMutation,
        CreateTestAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateTestAdminMutation, CreateTestAdminMutationVariables>(
        CreateTestAdminDocument,
        options,
    );
}
export type CreateTestAdminMutationHookResult = ReturnType<typeof useCreateTestAdminMutation>;
export type CreateTestAdminMutationResult = Apollo.MutationResult<CreateTestAdminMutation>;
export type CreateTestAdminMutationOptions = Apollo.BaseMutationOptions<
    CreateTestAdminMutation,
    CreateTestAdminMutationVariables
>;
export const CreateTestStudentDocument = gql`
    mutation createTestStudent(
        $sectionName: SectionName!
        $features: [Feature]
        $featureFlags: [FeatureFlag]
    ) {
        createTestStudent(
            sectionName: $sectionName
            features: $features
            featureFlags: $featureFlags
        ) {
            ...CreatedStudent
        }
    }
    ${CreatedStudentFragmentDoc}
`;
export type CreateTestStudentMutationFn = Apollo.MutationFunction<
    CreateTestStudentMutation,
    CreateTestStudentMutationVariables
>;

/**
 * __useCreateTestStudentMutation__
 *
 * To run a mutation, you first call `useCreateTestStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestStudentMutation, { data, loading, error }] = useCreateTestStudentMutation({
 *   variables: {
 *      sectionName: // value for 'sectionName'
 *      features: // value for 'features'
 *      featureFlags: // value for 'featureFlags'
 *   },
 * });
 */
export function useCreateTestStudentMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateTestStudentMutation,
        CreateTestStudentMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateTestStudentMutation,
        CreateTestStudentMutationVariables
    >(CreateTestStudentDocument, options);
}
export type CreateTestStudentMutationHookResult = ReturnType<typeof useCreateTestStudentMutation>;
export type CreateTestStudentMutationResult = Apollo.MutationResult<CreateTestStudentMutation>;
export type CreateTestStudentMutationOptions = Apollo.BaseMutationOptions<
    CreateTestStudentMutation,
    CreateTestStudentMutationVariables
>;
export const CreateTestStudentNotificationDocument = gql`
    mutation createTestStudentNotification($type: String!, $data: String!) {
        createTestStudentNotification(type: $type, data: $data)
    }
`;
export type CreateTestStudentNotificationMutationFn = Apollo.MutationFunction<
    CreateTestStudentNotificationMutation,
    CreateTestStudentNotificationMutationVariables
>;

/**
 * __useCreateTestStudentNotificationMutation__
 *
 * To run a mutation, you first call `useCreateTestStudentNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestStudentNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestStudentNotificationMutation, { data, loading, error }] = useCreateTestStudentNotificationMutation({
 *   variables: {
 *      type: // value for 'type'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTestStudentNotificationMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateTestStudentNotificationMutation,
        CreateTestStudentNotificationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateTestStudentNotificationMutation,
        CreateTestStudentNotificationMutationVariables
    >(CreateTestStudentNotificationDocument, options);
}
export type CreateTestStudentNotificationMutationHookResult = ReturnType<
    typeof useCreateTestStudentNotificationMutation
>;
export type CreateTestStudentNotificationMutationResult =
    Apollo.MutationResult<CreateTestStudentNotificationMutation>;
export type CreateTestStudentNotificationMutationOptions = Apollo.BaseMutationOptions<
    CreateTestStudentNotificationMutation,
    CreateTestStudentNotificationMutationVariables
>;
export const CreateTestTeacherDocument = gql`
    mutation createTestTeacher(
        $sectionName: SectionName!
        $features: [Feature]
        $featureFlags: [FeatureFlag]
    ) {
        createTestTeacher(
            sectionName: $sectionName
            features: $features
            featureFlags: $featureFlags
        ) {
            ...CreatedTeacher
        }
    }
    ${CreatedTeacherFragmentDoc}
`;
export type CreateTestTeacherMutationFn = Apollo.MutationFunction<
    CreateTestTeacherMutation,
    CreateTestTeacherMutationVariables
>;

/**
 * __useCreateTestTeacherMutation__
 *
 * To run a mutation, you first call `useCreateTestTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestTeacherMutation, { data, loading, error }] = useCreateTestTeacherMutation({
 *   variables: {
 *      sectionName: // value for 'sectionName'
 *      features: // value for 'features'
 *      featureFlags: // value for 'featureFlags'
 *   },
 * });
 */
export function useCreateTestTeacherMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateTestTeacherMutation,
        CreateTestTeacherMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateTestTeacherMutation,
        CreateTestTeacherMutationVariables
    >(CreateTestTeacherDocument, options);
}
export type CreateTestTeacherMutationHookResult = ReturnType<typeof useCreateTestTeacherMutation>;
export type CreateTestTeacherMutationResult = Apollo.MutationResult<CreateTestTeacherMutation>;
export type CreateTestTeacherMutationOptions = Apollo.BaseMutationOptions<
    CreateTestTeacherMutation,
    CreateTestTeacherMutationVariables
>;
export const SubmitClassroomClassReviewDocument = gql`
    mutation submitClassroomClassReview(
        $classId: String!
        $techQualityRating: Float!
        $techQualityFeedbackAnswers: [String!]
        $techQualityCustomFeedback: String
        $browserName: String
        $browserVersion: String
        $isMobile: Boolean
    ) {
        submitClassroomClassReview(
            classId: $classId
            technicalQualityFeedbackInput: {
                techQualityRating: $techQualityRating
                techQualityFeedbackAnswers: $techQualityFeedbackAnswers
                techQualityCustomFeedback: $techQualityCustomFeedback
                browserName: $browserName
                browserVersion: $browserVersion
                isMobile: $isMobile
            }
        ) {
            ... on ClassFeedback {
                id
            }
            ... on ClassTeacherFeedback {
                id
            }
        }
    }
`;
export type SubmitClassroomClassReviewMutationFn = Apollo.MutationFunction<
    SubmitClassroomClassReviewMutation,
    SubmitClassroomClassReviewMutationVariables
>;

/**
 * __useSubmitClassroomClassReviewMutation__
 *
 * To run a mutation, you first call `useSubmitClassroomClassReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitClassroomClassReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitClassroomClassReviewMutation, { data, loading, error }] = useSubmitClassroomClassReviewMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      techQualityRating: // value for 'techQualityRating'
 *      techQualityFeedbackAnswers: // value for 'techQualityFeedbackAnswers'
 *      techQualityCustomFeedback: // value for 'techQualityCustomFeedback'
 *      browserName: // value for 'browserName'
 *      browserVersion: // value for 'browserVersion'
 *      isMobile: // value for 'isMobile'
 *   },
 * });
 */
export function useSubmitClassroomClassReviewMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SubmitClassroomClassReviewMutation,
        SubmitClassroomClassReviewMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SubmitClassroomClassReviewMutation,
        SubmitClassroomClassReviewMutationVariables
    >(SubmitClassroomClassReviewDocument, options);
}
export type SubmitClassroomClassReviewMutationHookResult = ReturnType<
    typeof useSubmitClassroomClassReviewMutation
>;
export type SubmitClassroomClassReviewMutationResult =
    Apollo.MutationResult<SubmitClassroomClassReviewMutation>;
export type SubmitClassroomClassReviewMutationOptions = Apollo.BaseMutationOptions<
    SubmitClassroomClassReviewMutation,
    SubmitClassroomClassReviewMutationVariables
>;
export const IsEmailVerifiedDocument = gql`
    query isEmailVerified {
        currentUser {
            id
            isEmailVerified
        }
    }
`;

/**
 * __useIsEmailVerifiedQuery__
 *
 * To run a query within a React component, call `useIsEmailVerifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmailVerifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmailVerifiedQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsEmailVerifiedQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        IsEmailVerifiedQuery,
        IsEmailVerifiedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<IsEmailVerifiedQuery, IsEmailVerifiedQueryVariables>(
        IsEmailVerifiedDocument,
        options,
    );
}
export function useIsEmailVerifiedLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        IsEmailVerifiedQuery,
        IsEmailVerifiedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<IsEmailVerifiedQuery, IsEmailVerifiedQueryVariables>(
        IsEmailVerifiedDocument,
        options,
    );
}
export function useIsEmailVerifiedSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        IsEmailVerifiedQuery,
        IsEmailVerifiedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<IsEmailVerifiedQuery, IsEmailVerifiedQueryVariables>(
        IsEmailVerifiedDocument,
        options,
    );
}
export type IsEmailVerifiedQueryHookResult = ReturnType<typeof useIsEmailVerifiedQuery>;
export type IsEmailVerifiedLazyQueryHookResult = ReturnType<typeof useIsEmailVerifiedLazyQuery>;
export type IsEmailVerifiedSuspenseQueryHookResult = ReturnType<
    typeof useIsEmailVerifiedSuspenseQuery
>;
export type IsEmailVerifiedQueryResult = Apollo.QueryResult<
    IsEmailVerifiedQuery,
    IsEmailVerifiedQueryVariables
>;
export const CreateFreeTrialPurchaseDocument = gql`
    mutation createFreeTrialPurchase($packageId: Ulid!) {
        purchase: createFreeTrialPurchase(packageId: $packageId) {
            ...extraCreditPurchase
            ...legacyPurchase
            ...subscriptionPurchase
        }
    }
    ${ExtraCreditPurchaseFragmentDoc}
    ${LegacyPurchaseFragmentDoc}
    ${SubscriptionPurchaseFragmentDoc}
`;
export type CreateFreeTrialPurchaseMutationFn = Apollo.MutationFunction<
    CreateFreeTrialPurchaseMutation,
    CreateFreeTrialPurchaseMutationVariables
>;

/**
 * __useCreateFreeTrialPurchaseMutation__
 *
 * To run a mutation, you first call `useCreateFreeTrialPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFreeTrialPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFreeTrialPurchaseMutation, { data, loading, error }] = useCreateFreeTrialPurchaseMutation({
 *   variables: {
 *      packageId: // value for 'packageId'
 *   },
 * });
 */
export function useCreateFreeTrialPurchaseMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateFreeTrialPurchaseMutation,
        CreateFreeTrialPurchaseMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateFreeTrialPurchaseMutation,
        CreateFreeTrialPurchaseMutationVariables
    >(CreateFreeTrialPurchaseDocument, options);
}
export type CreateFreeTrialPurchaseMutationHookResult = ReturnType<
    typeof useCreateFreeTrialPurchaseMutation
>;
export type CreateFreeTrialPurchaseMutationResult =
    Apollo.MutationResult<CreateFreeTrialPurchaseMutation>;
export type CreateFreeTrialPurchaseMutationOptions = Apollo.BaseMutationOptions<
    CreateFreeTrialPurchaseMutation,
    CreateFreeTrialPurchaseMutationVariables
>;
export const SetLessonsStatusDocument = gql`
    mutation SetLessonsStatus($lessonsStatus: [LessonStatusInput!]!) {
        setLessonsStatus(lessonsStatus: $lessonsStatus)
    }
`;
export type SetLessonsStatusMutationFn = Apollo.MutationFunction<
    SetLessonsStatusMutation,
    SetLessonsStatusMutationVariables
>;

/**
 * __useSetLessonsStatusMutation__
 *
 * To run a mutation, you first call `useSetLessonsStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLessonsStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLessonsStatusMutation, { data, loading, error }] = useSetLessonsStatusMutation({
 *   variables: {
 *      lessonsStatus: // value for 'lessonsStatus'
 *   },
 * });
 */
export function useSetLessonsStatusMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SetLessonsStatusMutation,
        SetLessonsStatusMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SetLessonsStatusMutation,
        SetLessonsStatusMutationVariables
    >(SetLessonsStatusDocument, options);
}
export type SetLessonsStatusMutationHookResult = ReturnType<typeof useSetLessonsStatusMutation>;
export type SetLessonsStatusMutationResult = Apollo.MutationResult<SetLessonsStatusMutation>;
export type SetLessonsStatusMutationOptions = Apollo.BaseMutationOptions<
    SetLessonsStatusMutation,
    SetLessonsStatusMutationVariables
>;
export const ExerciseDocument = gql`
    query exercise($learningUnitId: Int!) {
        exercise(learningUnitId: $learningUnitId) {
            id
            score
            link
            title
        }
    }
`;

/**
 * __useExerciseQuery__
 *
 * To run a query within a React component, call `useExerciseQuery` and pass it any options that fit your needs.
 * When your component renders, `useExerciseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExerciseQuery({
 *   variables: {
 *      learningUnitId: // value for 'learningUnitId'
 *   },
 * });
 */
export function useExerciseQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ExerciseQuery, ExerciseQueryVariables> &
        ({ variables: ExerciseQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ExerciseQuery, ExerciseQueryVariables>(
        ExerciseDocument,
        options,
    );
}
export function useExerciseLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExerciseQuery, ExerciseQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ExerciseQuery, ExerciseQueryVariables>(
        ExerciseDocument,
        options,
    );
}
export function useExerciseSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<ExerciseQuery, ExerciseQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ExerciseQuery, ExerciseQueryVariables>(
        ExerciseDocument,
        options,
    );
}
export type ExerciseQueryHookResult = ReturnType<typeof useExerciseQuery>;
export type ExerciseLazyQueryHookResult = ReturnType<typeof useExerciseLazyQuery>;
export type ExerciseSuspenseQueryHookResult = ReturnType<typeof useExerciseSuspenseQuery>;
export type ExerciseQueryResult = Apollo.QueryResult<ExerciseQuery, ExerciseQueryVariables>;
export const PersonalizedFeedbackSubmitDocument = gql`
    mutation PersonalizedFeedbackSubmit($firstAnswer: String!, $secondAnswer: String!) {
        submitPersonalizedFeedback(firstAnswer: $firstAnswer, secondAnswer: $secondAnswer)
    }
`;
export type PersonalizedFeedbackSubmitMutationFn = Apollo.MutationFunction<
    PersonalizedFeedbackSubmitMutation,
    PersonalizedFeedbackSubmitMutationVariables
>;

/**
 * __usePersonalizedFeedbackSubmitMutation__
 *
 * To run a mutation, you first call `usePersonalizedFeedbackSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonalizedFeedbackSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personalizedFeedbackSubmitMutation, { data, loading, error }] = usePersonalizedFeedbackSubmitMutation({
 *   variables: {
 *      firstAnswer: // value for 'firstAnswer'
 *      secondAnswer: // value for 'secondAnswer'
 *   },
 * });
 */
export function usePersonalizedFeedbackSubmitMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        PersonalizedFeedbackSubmitMutation,
        PersonalizedFeedbackSubmitMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        PersonalizedFeedbackSubmitMutation,
        PersonalizedFeedbackSubmitMutationVariables
    >(PersonalizedFeedbackSubmitDocument, options);
}
export type PersonalizedFeedbackSubmitMutationHookResult = ReturnType<
    typeof usePersonalizedFeedbackSubmitMutation
>;
export type PersonalizedFeedbackSubmitMutationResult =
    Apollo.MutationResult<PersonalizedFeedbackSubmitMutation>;
export type PersonalizedFeedbackSubmitMutationOptions = Apollo.BaseMutationOptions<
    PersonalizedFeedbackSubmitMutation,
    PersonalizedFeedbackSubmitMutationVariables
>;
export const PersonalizedFeedbackQuestionsDocument = gql`
    query PersonalizedFeedbackQuestions {
        personalizedFeedbackQuestions {
            question
            answers {
                answer
            }
        }
    }
`;

/**
 * __usePersonalizedFeedbackQuestionsQuery__
 *
 * To run a query within a React component, call `usePersonalizedFeedbackQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalizedFeedbackQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalizedFeedbackQuestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalizedFeedbackQuestionsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PersonalizedFeedbackQuestionsQuery,
        PersonalizedFeedbackQuestionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PersonalizedFeedbackQuestionsQuery,
        PersonalizedFeedbackQuestionsQueryVariables
    >(PersonalizedFeedbackQuestionsDocument, options);
}
export function usePersonalizedFeedbackQuestionsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PersonalizedFeedbackQuestionsQuery,
        PersonalizedFeedbackQuestionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PersonalizedFeedbackQuestionsQuery,
        PersonalizedFeedbackQuestionsQueryVariables
    >(PersonalizedFeedbackQuestionsDocument, options);
}
export function usePersonalizedFeedbackQuestionsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        PersonalizedFeedbackQuestionsQuery,
        PersonalizedFeedbackQuestionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        PersonalizedFeedbackQuestionsQuery,
        PersonalizedFeedbackQuestionsQueryVariables
    >(PersonalizedFeedbackQuestionsDocument, options);
}
export type PersonalizedFeedbackQuestionsQueryHookResult = ReturnType<
    typeof usePersonalizedFeedbackQuestionsQuery
>;
export type PersonalizedFeedbackQuestionsLazyQueryHookResult = ReturnType<
    typeof usePersonalizedFeedbackQuestionsLazyQuery
>;
export type PersonalizedFeedbackQuestionsSuspenseQueryHookResult = ReturnType<
    typeof usePersonalizedFeedbackQuestionsSuspenseQuery
>;
export type PersonalizedFeedbackQuestionsQueryResult = Apollo.QueryResult<
    PersonalizedFeedbackQuestionsQuery,
    PersonalizedFeedbackQuestionsQueryVariables
>;
export const SubmitPracticeSatisfactionScoreDocument = gql`
    mutation submitPracticeSatisfactionScore($score: Int!) {
        submitPracticeSatisfactionScore(score: $score)
    }
`;
export type SubmitPracticeSatisfactionScoreMutationFn = Apollo.MutationFunction<
    SubmitPracticeSatisfactionScoreMutation,
    SubmitPracticeSatisfactionScoreMutationVariables
>;

/**
 * __useSubmitPracticeSatisfactionScoreMutation__
 *
 * To run a mutation, you first call `useSubmitPracticeSatisfactionScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPracticeSatisfactionScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPracticeSatisfactionScoreMutation, { data, loading, error }] = useSubmitPracticeSatisfactionScoreMutation({
 *   variables: {
 *      score: // value for 'score'
 *   },
 * });
 */
export function useSubmitPracticeSatisfactionScoreMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SubmitPracticeSatisfactionScoreMutation,
        SubmitPracticeSatisfactionScoreMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SubmitPracticeSatisfactionScoreMutation,
        SubmitPracticeSatisfactionScoreMutationVariables
    >(SubmitPracticeSatisfactionScoreDocument, options);
}
export type SubmitPracticeSatisfactionScoreMutationHookResult = ReturnType<
    typeof useSubmitPracticeSatisfactionScoreMutation
>;
export type SubmitPracticeSatisfactionScoreMutationResult =
    Apollo.MutationResult<SubmitPracticeSatisfactionScoreMutation>;
export type SubmitPracticeSatisfactionScoreMutationOptions = Apollo.BaseMutationOptions<
    SubmitPracticeSatisfactionScoreMutation,
    SubmitPracticeSatisfactionScoreMutationVariables
>;
export const SubmitPracticeSatisfactionFeedbackDocument = gql`
    mutation submitPracticeSatisfactionFeedback($feedback: [String!]!) {
        submitPracticeSatisfactionFeedback(feedback: $feedback)
    }
`;
export type SubmitPracticeSatisfactionFeedbackMutationFn = Apollo.MutationFunction<
    SubmitPracticeSatisfactionFeedbackMutation,
    SubmitPracticeSatisfactionFeedbackMutationVariables
>;

/**
 * __useSubmitPracticeSatisfactionFeedbackMutation__
 *
 * To run a mutation, you first call `useSubmitPracticeSatisfactionFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPracticeSatisfactionFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPracticeSatisfactionFeedbackMutation, { data, loading, error }] = useSubmitPracticeSatisfactionFeedbackMutation({
 *   variables: {
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useSubmitPracticeSatisfactionFeedbackMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SubmitPracticeSatisfactionFeedbackMutation,
        SubmitPracticeSatisfactionFeedbackMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SubmitPracticeSatisfactionFeedbackMutation,
        SubmitPracticeSatisfactionFeedbackMutationVariables
    >(SubmitPracticeSatisfactionFeedbackDocument, options);
}
export type SubmitPracticeSatisfactionFeedbackMutationHookResult = ReturnType<
    typeof useSubmitPracticeSatisfactionFeedbackMutation
>;
export type SubmitPracticeSatisfactionFeedbackMutationResult =
    Apollo.MutationResult<SubmitPracticeSatisfactionFeedbackMutation>;
export type SubmitPracticeSatisfactionFeedbackMutationOptions = Apollo.BaseMutationOptions<
    SubmitPracticeSatisfactionFeedbackMutation,
    SubmitPracticeSatisfactionFeedbackMutationVariables
>;
export const ShowPracticeSatisfactionSurveyDocument = gql`
    query showPracticeSatisfactionSurvey {
        student {
            id
            showPracticeSatisfactionSurvey
        }
    }
`;

/**
 * __useShowPracticeSatisfactionSurveyQuery__
 *
 * To run a query within a React component, call `useShowPracticeSatisfactionSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowPracticeSatisfactionSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowPracticeSatisfactionSurveyQuery({
 *   variables: {
 *   },
 * });
 */
export function useShowPracticeSatisfactionSurveyQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        ShowPracticeSatisfactionSurveyQuery,
        ShowPracticeSatisfactionSurveyQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        ShowPracticeSatisfactionSurveyQuery,
        ShowPracticeSatisfactionSurveyQueryVariables
    >(ShowPracticeSatisfactionSurveyDocument, options);
}
export function useShowPracticeSatisfactionSurveyLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ShowPracticeSatisfactionSurveyQuery,
        ShowPracticeSatisfactionSurveyQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ShowPracticeSatisfactionSurveyQuery,
        ShowPracticeSatisfactionSurveyQueryVariables
    >(ShowPracticeSatisfactionSurveyDocument, options);
}
export function useShowPracticeSatisfactionSurveySuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ShowPracticeSatisfactionSurveyQuery,
        ShowPracticeSatisfactionSurveyQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ShowPracticeSatisfactionSurveyQuery,
        ShowPracticeSatisfactionSurveyQueryVariables
    >(ShowPracticeSatisfactionSurveyDocument, options);
}
export type ShowPracticeSatisfactionSurveyQueryHookResult = ReturnType<
    typeof useShowPracticeSatisfactionSurveyQuery
>;
export type ShowPracticeSatisfactionSurveyLazyQueryHookResult = ReturnType<
    typeof useShowPracticeSatisfactionSurveyLazyQuery
>;
export type ShowPracticeSatisfactionSurveySuspenseQueryHookResult = ReturnType<
    typeof useShowPracticeSatisfactionSurveySuspenseQuery
>;
export type ShowPracticeSatisfactionSurveyQueryResult = Apollo.QueryResult<
    ShowPracticeSatisfactionSurveyQuery,
    ShowPracticeSatisfactionSurveyQueryVariables
>;
export const SkipTrialSubscriptionDocument = gql`
    mutation skipTrialSubscription {
        subscription: skipTrial {
            ...UserSubscription
        }
    }
    ${UserSubscriptionFragmentDoc}
`;
export type SkipTrialSubscriptionMutationFn = Apollo.MutationFunction<
    SkipTrialSubscriptionMutation,
    SkipTrialSubscriptionMutationVariables
>;

/**
 * __useSkipTrialSubscriptionMutation__
 *
 * To run a mutation, you first call `useSkipTrialSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkipTrialSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skipTrialSubscriptionMutation, { data, loading, error }] = useSkipTrialSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useSkipTrialSubscriptionMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SkipTrialSubscriptionMutation,
        SkipTrialSubscriptionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SkipTrialSubscriptionMutation,
        SkipTrialSubscriptionMutationVariables
    >(SkipTrialSubscriptionDocument, options);
}
export type SkipTrialSubscriptionMutationHookResult = ReturnType<
    typeof useSkipTrialSubscriptionMutation
>;
export type SkipTrialSubscriptionMutationResult =
    Apollo.MutationResult<SkipTrialSubscriptionMutation>;
export type SkipTrialSubscriptionMutationOptions = Apollo.BaseMutationOptions<
    SkipTrialSubscriptionMutation,
    SkipTrialSubscriptionMutationVariables
>;
export const CancelSubscriptionWithFeedbackDocument = gql`
    mutation cancelSubscriptionWithFeedback(
        $reason: String!
        $mood: EmojiType
        $reasonComment: String!
    ) {
        cancelSubscriptionWithFeedback(
            reason: $reason
            mood: $mood
            reasonComment: $reasonComment
        ) {
            mood
        }
    }
`;
export type CancelSubscriptionWithFeedbackMutationFn = Apollo.MutationFunction<
    CancelSubscriptionWithFeedbackMutation,
    CancelSubscriptionWithFeedbackMutationVariables
>;

/**
 * __useCancelSubscriptionWithFeedbackMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionWithFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionWithFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionWithFeedbackMutation, { data, loading, error }] = useCancelSubscriptionWithFeedbackMutation({
 *   variables: {
 *      reason: // value for 'reason'
 *      mood: // value for 'mood'
 *      reasonComment: // value for 'reasonComment'
 *   },
 * });
 */
export function useCancelSubscriptionWithFeedbackMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CancelSubscriptionWithFeedbackMutation,
        CancelSubscriptionWithFeedbackMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CancelSubscriptionWithFeedbackMutation,
        CancelSubscriptionWithFeedbackMutationVariables
    >(CancelSubscriptionWithFeedbackDocument, options);
}
export type CancelSubscriptionWithFeedbackMutationHookResult = ReturnType<
    typeof useCancelSubscriptionWithFeedbackMutation
>;
export type CancelSubscriptionWithFeedbackMutationResult =
    Apollo.MutationResult<CancelSubscriptionWithFeedbackMutation>;
export type CancelSubscriptionWithFeedbackMutationOptions = Apollo.BaseMutationOptions<
    CancelSubscriptionWithFeedbackMutation,
    CancelSubscriptionWithFeedbackMutationVariables
>;
export const DelayNextSubscriptionStartDateDocument = gql`
    mutation delayNextSubscriptionStartDate($delayUntil: DateTime!) {
        delayNextSubscriptionStartDate(delayUntil: $delayUntil)
    }
`;
export type DelayNextSubscriptionStartDateMutationFn = Apollo.MutationFunction<
    DelayNextSubscriptionStartDateMutation,
    DelayNextSubscriptionStartDateMutationVariables
>;

/**
 * __useDelayNextSubscriptionStartDateMutation__
 *
 * To run a mutation, you first call `useDelayNextSubscriptionStartDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelayNextSubscriptionStartDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [delayNextSubscriptionStartDateMutation, { data, loading, error }] = useDelayNextSubscriptionStartDateMutation({
 *   variables: {
 *      delayUntil: // value for 'delayUntil'
 *   },
 * });
 */
export function useDelayNextSubscriptionStartDateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DelayNextSubscriptionStartDateMutation,
        DelayNextSubscriptionStartDateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DelayNextSubscriptionStartDateMutation,
        DelayNextSubscriptionStartDateMutationVariables
    >(DelayNextSubscriptionStartDateDocument, options);
}
export type DelayNextSubscriptionStartDateMutationHookResult = ReturnType<
    typeof useDelayNextSubscriptionStartDateMutation
>;
export type DelayNextSubscriptionStartDateMutationResult =
    Apollo.MutationResult<DelayNextSubscriptionStartDateMutation>;
export type DelayNextSubscriptionStartDateMutationOptions = Apollo.BaseMutationOptions<
    DelayNextSubscriptionStartDateMutation,
    DelayNextSubscriptionStartDateMutationVariables
>;
export const CancelSprintRolloverWithQuestionnaireDocument = gql`
    mutation cancelSprintRolloverWithQuestionnaire(
        $sprintCancelRolloverQuestionnaire: SprintCancelRolloverQuestionnaireInput!
    ) {
        cancelSprintRolloverWithQuestionnaire(
            sprintCancelRolloverQuestionnaire: $sprintCancelRolloverQuestionnaire
        )
    }
`;
export type CancelSprintRolloverWithQuestionnaireMutationFn = Apollo.MutationFunction<
    CancelSprintRolloverWithQuestionnaireMutation,
    CancelSprintRolloverWithQuestionnaireMutationVariables
>;

/**
 * __useCancelSprintRolloverWithQuestionnaireMutation__
 *
 * To run a mutation, you first call `useCancelSprintRolloverWithQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSprintRolloverWithQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSprintRolloverWithQuestionnaireMutation, { data, loading, error }] = useCancelSprintRolloverWithQuestionnaireMutation({
 *   variables: {
 *      sprintCancelRolloverQuestionnaire: // value for 'sprintCancelRolloverQuestionnaire'
 *   },
 * });
 */
export function useCancelSprintRolloverWithQuestionnaireMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CancelSprintRolloverWithQuestionnaireMutation,
        CancelSprintRolloverWithQuestionnaireMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CancelSprintRolloverWithQuestionnaireMutation,
        CancelSprintRolloverWithQuestionnaireMutationVariables
    >(CancelSprintRolloverWithQuestionnaireDocument, options);
}
export type CancelSprintRolloverWithQuestionnaireMutationHookResult = ReturnType<
    typeof useCancelSprintRolloverWithQuestionnaireMutation
>;
export type CancelSprintRolloverWithQuestionnaireMutationResult =
    Apollo.MutationResult<CancelSprintRolloverWithQuestionnaireMutation>;
export type CancelSprintRolloverWithQuestionnaireMutationOptions = Apollo.BaseMutationOptions<
    CancelSprintRolloverWithQuestionnaireMutation,
    CancelSprintRolloverWithQuestionnaireMutationVariables
>;
export const CancelTrialSubscriptionDocument = gql`
    mutation cancelTrialSubscription {
        subscription: cancelTrial {
            id
            status
        }
    }
`;
export type CancelTrialSubscriptionMutationFn = Apollo.MutationFunction<
    CancelTrialSubscriptionMutation,
    CancelTrialSubscriptionMutationVariables
>;

/**
 * __useCancelTrialSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelTrialSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTrialSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTrialSubscriptionMutation, { data, loading, error }] = useCancelTrialSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelTrialSubscriptionMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CancelTrialSubscriptionMutation,
        CancelTrialSubscriptionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CancelTrialSubscriptionMutation,
        CancelTrialSubscriptionMutationVariables
    >(CancelTrialSubscriptionDocument, options);
}
export type CancelTrialSubscriptionMutationHookResult = ReturnType<
    typeof useCancelTrialSubscriptionMutation
>;
export type CancelTrialSubscriptionMutationResult =
    Apollo.MutationResult<CancelTrialSubscriptionMutation>;
export type CancelTrialSubscriptionMutationOptions = Apollo.BaseMutationOptions<
    CancelTrialSubscriptionMutation,
    CancelTrialSubscriptionMutationVariables
>;
export const CancelScheduledPauseDocument = gql`
    mutation cancelScheduledPause {
        cancelScheduledPause {
            ...UserSubscription
        }
    }
    ${UserSubscriptionFragmentDoc}
`;
export type CancelScheduledPauseMutationFn = Apollo.MutationFunction<
    CancelScheduledPauseMutation,
    CancelScheduledPauseMutationVariables
>;

/**
 * __useCancelScheduledPauseMutation__
 *
 * To run a mutation, you first call `useCancelScheduledPauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelScheduledPauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelScheduledPauseMutation, { data, loading, error }] = useCancelScheduledPauseMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelScheduledPauseMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CancelScheduledPauseMutation,
        CancelScheduledPauseMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CancelScheduledPauseMutation,
        CancelScheduledPauseMutationVariables
    >(CancelScheduledPauseDocument, options);
}
export type CancelScheduledPauseMutationHookResult = ReturnType<
    typeof useCancelScheduledPauseMutation
>;
export type CancelScheduledPauseMutationResult =
    Apollo.MutationResult<CancelScheduledPauseMutation>;
export type CancelScheduledPauseMutationOptions = Apollo.BaseMutationOptions<
    CancelScheduledPauseMutation,
    CancelScheduledPauseMutationVariables
>;
export const CancelNextSubscriptionDelayedStartDocument = gql`
    mutation cancelNextSubscriptionDelayedStart {
        cancelNextSubscriptionDelayedStart
    }
`;
export type CancelNextSubscriptionDelayedStartMutationFn = Apollo.MutationFunction<
    CancelNextSubscriptionDelayedStartMutation,
    CancelNextSubscriptionDelayedStartMutationVariables
>;

/**
 * __useCancelNextSubscriptionDelayedStartMutation__
 *
 * To run a mutation, you first call `useCancelNextSubscriptionDelayedStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelNextSubscriptionDelayedStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelNextSubscriptionDelayedStartMutation, { data, loading, error }] = useCancelNextSubscriptionDelayedStartMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelNextSubscriptionDelayedStartMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CancelNextSubscriptionDelayedStartMutation,
        CancelNextSubscriptionDelayedStartMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CancelNextSubscriptionDelayedStartMutation,
        CancelNextSubscriptionDelayedStartMutationVariables
    >(CancelNextSubscriptionDelayedStartDocument, options);
}
export type CancelNextSubscriptionDelayedStartMutationHookResult = ReturnType<
    typeof useCancelNextSubscriptionDelayedStartMutation
>;
export type CancelNextSubscriptionDelayedStartMutationResult =
    Apollo.MutationResult<CancelNextSubscriptionDelayedStartMutation>;
export type CancelNextSubscriptionDelayedStartMutationOptions = Apollo.BaseMutationOptions<
    CancelNextSubscriptionDelayedStartMutation,
    CancelNextSubscriptionDelayedStartMutationVariables
>;
export const DeleteNextSubscriptionDocument = gql`
    mutation deleteNextSubscription {
        deleteNextSubscription
    }
`;
export type DeleteNextSubscriptionMutationFn = Apollo.MutationFunction<
    DeleteNextSubscriptionMutation,
    DeleteNextSubscriptionMutationVariables
>;

/**
 * __useDeleteNextSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeleteNextSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNextSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNextSubscriptionMutation, { data, loading, error }] = useDeleteNextSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteNextSubscriptionMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteNextSubscriptionMutation,
        DeleteNextSubscriptionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteNextSubscriptionMutation,
        DeleteNextSubscriptionMutationVariables
    >(DeleteNextSubscriptionDocument, options);
}
export type DeleteNextSubscriptionMutationHookResult = ReturnType<
    typeof useDeleteNextSubscriptionMutation
>;
export type DeleteNextSubscriptionMutationResult =
    Apollo.MutationResult<DeleteNextSubscriptionMutation>;
export type DeleteNextSubscriptionMutationOptions = Apollo.BaseMutationOptions<
    DeleteNextSubscriptionMutation,
    DeleteNextSubscriptionMutationVariables
>;
export const RolloverSubscriptionDocument = gql`
    mutation rolloverSubscription {
        rolloverSubscription
    }
`;
export type RolloverSubscriptionMutationFn = Apollo.MutationFunction<
    RolloverSubscriptionMutation,
    RolloverSubscriptionMutationVariables
>;

/**
 * __useRolloverSubscriptionMutation__
 *
 * To run a mutation, you first call `useRolloverSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRolloverSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rolloverSubscriptionMutation, { data, loading, error }] = useRolloverSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useRolloverSubscriptionMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        RolloverSubscriptionMutation,
        RolloverSubscriptionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        RolloverSubscriptionMutation,
        RolloverSubscriptionMutationVariables
    >(RolloverSubscriptionDocument, options);
}
export type RolloverSubscriptionMutationHookResult = ReturnType<
    typeof useRolloverSubscriptionMutation
>;
export type RolloverSubscriptionMutationResult =
    Apollo.MutationResult<RolloverSubscriptionMutation>;
export type RolloverSubscriptionMutationOptions = Apollo.BaseMutationOptions<
    RolloverSubscriptionMutation,
    RolloverSubscriptionMutationVariables
>;
export const UserSubscriptionsDocument = gql`
    query userSubscriptions {
        subscriptions: studentSubscriptionInfo {
            currentSubscription {
                ...UserSubscription
            }
            latestSubscription {
                ...UserSubscription
            }
            nextSubscription {
                ...UserSubscription
            }
        }
    }
    ${UserSubscriptionFragmentDoc}
`;

/**
 * __useUserSubscriptionsQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSubscriptionsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        UserSubscriptionsQuery,
        UserSubscriptionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<UserSubscriptionsQuery, UserSubscriptionsQueryVariables>(
        UserSubscriptionsDocument,
        options,
    );
}
export function useUserSubscriptionsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        UserSubscriptionsQuery,
        UserSubscriptionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<UserSubscriptionsQuery, UserSubscriptionsQueryVariables>(
        UserSubscriptionsDocument,
        options,
    );
}
export function useUserSubscriptionsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        UserSubscriptionsQuery,
        UserSubscriptionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        UserSubscriptionsQuery,
        UserSubscriptionsQueryVariables
    >(UserSubscriptionsDocument, options);
}
export type UserSubscriptionsQueryHookResult = ReturnType<typeof useUserSubscriptionsQuery>;
export type UserSubscriptionsLazyQueryHookResult = ReturnType<typeof useUserSubscriptionsLazyQuery>;
export type UserSubscriptionsSuspenseQueryHookResult = ReturnType<
    typeof useUserSubscriptionsSuspenseQuery
>;
export type UserSubscriptionsQueryResult = Apollo.QueryResult<
    UserSubscriptionsQuery,
    UserSubscriptionsQueryVariables
>;
export const SprintCancelRolloverQuestionnaireDocument = gql`
    query sprintCancelRolloverQuestionnaire {
        sprintCancelRolloverQuestionnaire {
            currentSprintQuestionnaireFinished
            ...CancelSubscriptionData
        }
    }
    ${CancelSubscriptionDataFragmentDoc}
`;

/**
 * __useSprintCancelRolloverQuestionnaireQuery__
 *
 * To run a query within a React component, call `useSprintCancelRolloverQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useSprintCancelRolloverQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSprintCancelRolloverQuestionnaireQuery({
 *   variables: {
 *   },
 * });
 */
export function useSprintCancelRolloverQuestionnaireQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        SprintCancelRolloverQuestionnaireQuery,
        SprintCancelRolloverQuestionnaireQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        SprintCancelRolloverQuestionnaireQuery,
        SprintCancelRolloverQuestionnaireQueryVariables
    >(SprintCancelRolloverQuestionnaireDocument, options);
}
export function useSprintCancelRolloverQuestionnaireLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        SprintCancelRolloverQuestionnaireQuery,
        SprintCancelRolloverQuestionnaireQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        SprintCancelRolloverQuestionnaireQuery,
        SprintCancelRolloverQuestionnaireQueryVariables
    >(SprintCancelRolloverQuestionnaireDocument, options);
}
export function useSprintCancelRolloverQuestionnaireSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        SprintCancelRolloverQuestionnaireQuery,
        SprintCancelRolloverQuestionnaireQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        SprintCancelRolloverQuestionnaireQuery,
        SprintCancelRolloverQuestionnaireQueryVariables
    >(SprintCancelRolloverQuestionnaireDocument, options);
}
export type SprintCancelRolloverQuestionnaireQueryHookResult = ReturnType<
    typeof useSprintCancelRolloverQuestionnaireQuery
>;
export type SprintCancelRolloverQuestionnaireLazyQueryHookResult = ReturnType<
    typeof useSprintCancelRolloverQuestionnaireLazyQuery
>;
export type SprintCancelRolloverQuestionnaireSuspenseQueryHookResult = ReturnType<
    typeof useSprintCancelRolloverQuestionnaireSuspenseQuery
>;
export type SprintCancelRolloverQuestionnaireQueryResult = Apollo.QueryResult<
    SprintCancelRolloverQuestionnaireQuery,
    SprintCancelRolloverQuestionnaireQueryVariables
>;
export const StudentCompletedClassesDocument = gql`
    query studentCompletedClasses {
        student {
            completedClasses
        }
    }
`;

/**
 * __useStudentCompletedClassesQuery__
 *
 * To run a query within a React component, call `useStudentCompletedClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentCompletedClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentCompletedClassesQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentCompletedClassesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        StudentCompletedClassesQuery,
        StudentCompletedClassesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        StudentCompletedClassesQuery,
        StudentCompletedClassesQueryVariables
    >(StudentCompletedClassesDocument, options);
}
export function useStudentCompletedClassesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        StudentCompletedClassesQuery,
        StudentCompletedClassesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        StudentCompletedClassesQuery,
        StudentCompletedClassesQueryVariables
    >(StudentCompletedClassesDocument, options);
}
export function useStudentCompletedClassesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        StudentCompletedClassesQuery,
        StudentCompletedClassesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        StudentCompletedClassesQuery,
        StudentCompletedClassesQueryVariables
    >(StudentCompletedClassesDocument, options);
}
export type StudentCompletedClassesQueryHookResult = ReturnType<
    typeof useStudentCompletedClassesQuery
>;
export type StudentCompletedClassesLazyQueryHookResult = ReturnType<
    typeof useStudentCompletedClassesLazyQuery
>;
export type StudentCompletedClassesSuspenseQueryHookResult = ReturnType<
    typeof useStudentCompletedClassesSuspenseQuery
>;
export type StudentCompletedClassesQueryResult = Apollo.QueryResult<
    StudentCompletedClassesQuery,
    StudentCompletedClassesQueryVariables
>;
export const PracticeQuizDashboardDocument = gql`
    query practiceQuizDashboard($to: String!) {
        quizEngineProgress(to: $to) {
            total
            known
            reviewing
            uncompleted
            lastReviewed
            periodProgress {
                known
            }
        }
    }
`;

/**
 * __usePracticeQuizDashboardQuery__
 *
 * To run a query within a React component, call `usePracticeQuizDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticeQuizDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticeQuizDashboardQuery({
 *   variables: {
 *      to: // value for 'to'
 *   },
 * });
 */
export function usePracticeQuizDashboardQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        PracticeQuizDashboardQuery,
        PracticeQuizDashboardQueryVariables
    > &
        ({ variables: PracticeQuizDashboardQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PracticeQuizDashboardQuery,
        PracticeQuizDashboardQueryVariables
    >(PracticeQuizDashboardDocument, options);
}
export function usePracticeQuizDashboardLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PracticeQuizDashboardQuery,
        PracticeQuizDashboardQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PracticeQuizDashboardQuery,
        PracticeQuizDashboardQueryVariables
    >(PracticeQuizDashboardDocument, options);
}
export function usePracticeQuizDashboardSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        PracticeQuizDashboardQuery,
        PracticeQuizDashboardQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        PracticeQuizDashboardQuery,
        PracticeQuizDashboardQueryVariables
    >(PracticeQuizDashboardDocument, options);
}
export type PracticeQuizDashboardQueryHookResult = ReturnType<typeof usePracticeQuizDashboardQuery>;
export type PracticeQuizDashboardLazyQueryHookResult = ReturnType<
    typeof usePracticeQuizDashboardLazyQuery
>;
export type PracticeQuizDashboardSuspenseQueryHookResult = ReturnType<
    typeof usePracticeQuizDashboardSuspenseQuery
>;
export type PracticeQuizDashboardQueryResult = Apollo.QueryResult<
    PracticeQuizDashboardQuery,
    PracticeQuizDashboardQueryVariables
>;
export const FinishQuizStatsDocument = gql`
    query finishQuizStats {
        quizEngineProgress {
            total
            known
            reviewing
            reviewed
        }
    }
`;

/**
 * __useFinishQuizStatsQuery__
 *
 * To run a query within a React component, call `useFinishQuizStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinishQuizStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinishQuizStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFinishQuizStatsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        FinishQuizStatsQuery,
        FinishQuizStatsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<FinishQuizStatsQuery, FinishQuizStatsQueryVariables>(
        FinishQuizStatsDocument,
        options,
    );
}
export function useFinishQuizStatsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        FinishQuizStatsQuery,
        FinishQuizStatsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<FinishQuizStatsQuery, FinishQuizStatsQueryVariables>(
        FinishQuizStatsDocument,
        options,
    );
}
export function useFinishQuizStatsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        FinishQuizStatsQuery,
        FinishQuizStatsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<FinishQuizStatsQuery, FinishQuizStatsQueryVariables>(
        FinishQuizStatsDocument,
        options,
    );
}
export type FinishQuizStatsQueryHookResult = ReturnType<typeof useFinishQuizStatsQuery>;
export type FinishQuizStatsLazyQueryHookResult = ReturnType<typeof useFinishQuizStatsLazyQuery>;
export type FinishQuizStatsSuspenseQueryHookResult = ReturnType<
    typeof useFinishQuizStatsSuspenseQuery
>;
export type FinishQuizStatsQueryResult = Apollo.QueryResult<
    FinishQuizStatsQuery,
    FinishQuizStatsQueryVariables
>;
export const ResetResultsDocument = gql`
    mutation resetResults {
        quizEngineResetResult
    }
`;
export type ResetResultsMutationFn = Apollo.MutationFunction<
    ResetResultsMutation,
    ResetResultsMutationVariables
>;

/**
 * __useResetResultsMutation__
 *
 * To run a mutation, you first call `useResetResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetResultsMutation, { data, loading, error }] = useResetResultsMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetResultsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        ResetResultsMutation,
        ResetResultsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<ResetResultsMutation, ResetResultsMutationVariables>(
        ResetResultsDocument,
        options,
    );
}
export type ResetResultsMutationHookResult = ReturnType<typeof useResetResultsMutation>;
export type ResetResultsMutationResult = Apollo.MutationResult<ResetResultsMutation>;
export type ResetResultsMutationOptions = Apollo.BaseMutationOptions<
    ResetResultsMutation,
    ResetResultsMutationVariables
>;
export const ReportItemDialogSubmitDocument = gql`
    mutation ReportItemDialogSubmit(
        $answersIds: [String!]!
        $questionId: String!
        $exerciseId: String!
        $feedback: String
        $vocabularyItemId: String!
    ) {
        submitVocabTrainerFeedback(
            feedbackInput: {
                answersIds: $answersIds
                exerciseId: $exerciseId
                questionId: $questionId
                feedback: $feedback
                vocabularyItemId: $vocabularyItemId
            }
        )
    }
`;
export type ReportItemDialogSubmitMutationFn = Apollo.MutationFunction<
    ReportItemDialogSubmitMutation,
    ReportItemDialogSubmitMutationVariables
>;

/**
 * __useReportItemDialogSubmitMutation__
 *
 * To run a mutation, you first call `useReportItemDialogSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportItemDialogSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportItemDialogSubmitMutation, { data, loading, error }] = useReportItemDialogSubmitMutation({
 *   variables: {
 *      answersIds: // value for 'answersIds'
 *      questionId: // value for 'questionId'
 *      exerciseId: // value for 'exerciseId'
 *      feedback: // value for 'feedback'
 *      vocabularyItemId: // value for 'vocabularyItemId'
 *   },
 * });
 */
export function useReportItemDialogSubmitMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        ReportItemDialogSubmitMutation,
        ReportItemDialogSubmitMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        ReportItemDialogSubmitMutation,
        ReportItemDialogSubmitMutationVariables
    >(ReportItemDialogSubmitDocument, options);
}
export type ReportItemDialogSubmitMutationHookResult = ReturnType<
    typeof useReportItemDialogSubmitMutation
>;
export type ReportItemDialogSubmitMutationResult =
    Apollo.MutationResult<ReportItemDialogSubmitMutation>;
export type ReportItemDialogSubmitMutationOptions = Apollo.BaseMutationOptions<
    ReportItemDialogSubmitMutation,
    ReportItemDialogSubmitMutationVariables
>;
export const ReportItemDialogOptionsDocument = gql`
    query ReportItemDialogOptions($exerciseType: SessionExerciseType) {
        quizFeedbackQuestions(exerciseType: $exerciseType) {
            id
            answers {
                id
                answer
                slug
            }
        }
    }
`;

/**
 * __useReportItemDialogOptionsQuery__
 *
 * To run a query within a React component, call `useReportItemDialogOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportItemDialogOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportItemDialogOptionsQuery({
 *   variables: {
 *      exerciseType: // value for 'exerciseType'
 *   },
 * });
 */
export function useReportItemDialogOptionsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        ReportItemDialogOptionsQuery,
        ReportItemDialogOptionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        ReportItemDialogOptionsQuery,
        ReportItemDialogOptionsQueryVariables
    >(ReportItemDialogOptionsDocument, options);
}
export function useReportItemDialogOptionsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ReportItemDialogOptionsQuery,
        ReportItemDialogOptionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ReportItemDialogOptionsQuery,
        ReportItemDialogOptionsQueryVariables
    >(ReportItemDialogOptionsDocument, options);
}
export function useReportItemDialogOptionsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ReportItemDialogOptionsQuery,
        ReportItemDialogOptionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ReportItemDialogOptionsQuery,
        ReportItemDialogOptionsQueryVariables
    >(ReportItemDialogOptionsDocument, options);
}
export type ReportItemDialogOptionsQueryHookResult = ReturnType<
    typeof useReportItemDialogOptionsQuery
>;
export type ReportItemDialogOptionsLazyQueryHookResult = ReturnType<
    typeof useReportItemDialogOptionsLazyQuery
>;
export type ReportItemDialogOptionsSuspenseQueryHookResult = ReturnType<
    typeof useReportItemDialogOptionsSuspenseQuery
>;
export type ReportItemDialogOptionsQueryResult = Apollo.QueryResult<
    ReportItemDialogOptionsQuery,
    ReportItemDialogOptionsQueryVariables
>;
export const ReportQuizResultDocument = gql`
    mutation reportQuizResult(
        $sessionId: String!
        $questionId: String!
        $answers: [String!]!
        $isSkipped: Boolean
    ) {
        quizEngineReportResult(
            input: {
                sessionId: $sessionId
                questionId: $questionId
                answers: $answers
                isSkipped: $isSkipped
            }
        ) {
            score
        }
    }
`;
export type ReportQuizResultMutationFn = Apollo.MutationFunction<
    ReportQuizResultMutation,
    ReportQuizResultMutationVariables
>;

/**
 * __useReportQuizResultMutation__
 *
 * To run a mutation, you first call `useReportQuizResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportQuizResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportQuizResultMutation, { data, loading, error }] = useReportQuizResultMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      questionId: // value for 'questionId'
 *      answers: // value for 'answers'
 *      isSkipped: // value for 'isSkipped'
 *   },
 * });
 */
export function useReportQuizResultMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        ReportQuizResultMutation,
        ReportQuizResultMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        ReportQuizResultMutation,
        ReportQuizResultMutationVariables
    >(ReportQuizResultDocument, options);
}
export type ReportQuizResultMutationHookResult = ReturnType<typeof useReportQuizResultMutation>;
export type ReportQuizResultMutationResult = Apollo.MutationResult<ReportQuizResultMutation>;
export type ReportQuizResultMutationOptions = Apollo.BaseMutationOptions<
    ReportQuizResultMutation,
    ReportQuizResultMutationVariables
>;
export const QuizStartPageProgressDocument = gql`
    query quizStartPageProgress {
        quizEngineProgress {
            total
            completed
            uncompleted
            known
            reviewing
        }
    }
`;

/**
 * __useQuizStartPageProgressQuery__
 *
 * To run a query within a React component, call `useQuizStartPageProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuizStartPageProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizStartPageProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuizStartPageProgressQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        QuizStartPageProgressQuery,
        QuizStartPageProgressQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        QuizStartPageProgressQuery,
        QuizStartPageProgressQueryVariables
    >(QuizStartPageProgressDocument, options);
}
export function useQuizStartPageProgressLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        QuizStartPageProgressQuery,
        QuizStartPageProgressQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        QuizStartPageProgressQuery,
        QuizStartPageProgressQueryVariables
    >(QuizStartPageProgressDocument, options);
}
export function useQuizStartPageProgressSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        QuizStartPageProgressQuery,
        QuizStartPageProgressQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        QuizStartPageProgressQuery,
        QuizStartPageProgressQueryVariables
    >(QuizStartPageProgressDocument, options);
}
export type QuizStartPageProgressQueryHookResult = ReturnType<typeof useQuizStartPageProgressQuery>;
export type QuizStartPageProgressLazyQueryHookResult = ReturnType<
    typeof useQuizStartPageProgressLazyQuery
>;
export type QuizStartPageProgressSuspenseQueryHookResult = ReturnType<
    typeof useQuizStartPageProgressSuspenseQuery
>;
export type QuizStartPageProgressQueryResult = Apollo.QueryResult<
    QuizStartPageProgressQuery,
    QuizStartPageProgressQueryVariables
>;
export const QuizStartPageSessionDocument = gql`
    query quizStartPageSession {
        quizEngineProgress {
            total
            completed
            uncompleted
            known
            reviewing
        }
        quizEngineQuiz(filter: { first: 15 }) {
            hasMore
            session {
                id
                exercises {
                    id
                    vocabularyId
                    questions {
                        id
                        title
                        audioUrl
                        meta {
                            gender
                            plural
                            wordClass
                            sampleSentence
                            translations {
                                de
                                en
                                es
                                fr
                                ru
                            }
                        }
                        translation {
                            wholeSentenceToTarget
                        }
                        titleTranslation {
                            byWord {
                                id
                                source
                                translation
                            }
                        }
                        choices {
                            id
                            isCorrect
                            text
                            audioUrl
                            translation {
                                byWord {
                                    id
                                    source
                                    translation
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useQuizStartPageSessionQuery__
 *
 * To run a query within a React component, call `useQuizStartPageSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuizStartPageSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizStartPageSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuizStartPageSessionQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        QuizStartPageSessionQuery,
        QuizStartPageSessionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<QuizStartPageSessionQuery, QuizStartPageSessionQueryVariables>(
        QuizStartPageSessionDocument,
        options,
    );
}
export function useQuizStartPageSessionLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        QuizStartPageSessionQuery,
        QuizStartPageSessionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        QuizStartPageSessionQuery,
        QuizStartPageSessionQueryVariables
    >(QuizStartPageSessionDocument, options);
}
export function useQuizStartPageSessionSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        QuizStartPageSessionQuery,
        QuizStartPageSessionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        QuizStartPageSessionQuery,
        QuizStartPageSessionQueryVariables
    >(QuizStartPageSessionDocument, options);
}
export type QuizStartPageSessionQueryHookResult = ReturnType<typeof useQuizStartPageSessionQuery>;
export type QuizStartPageSessionLazyQueryHookResult = ReturnType<
    typeof useQuizStartPageSessionLazyQuery
>;
export type QuizStartPageSessionSuspenseQueryHookResult = ReturnType<
    typeof useQuizStartPageSessionSuspenseQuery
>;
export type QuizStartPageSessionQueryResult = Apollo.QueryResult<
    QuizStartPageSessionQuery,
    QuizStartPageSessionQueryVariables
>;
export const ConvertGroupCreditsIntoPrivateDocument = gql`
    mutation ConvertGroupCreditsIntoPrivate($numberOfCredits: Int!) {
        convertGroupCreditsIntoPrivate(numberOfCredits: $numberOfCredits)
    }
`;
export type ConvertGroupCreditsIntoPrivateMutationFn = Apollo.MutationFunction<
    ConvertGroupCreditsIntoPrivateMutation,
    ConvertGroupCreditsIntoPrivateMutationVariables
>;

/**
 * __useConvertGroupCreditsIntoPrivateMutation__
 *
 * To run a mutation, you first call `useConvertGroupCreditsIntoPrivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertGroupCreditsIntoPrivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertGroupCreditsIntoPrivateMutation, { data, loading, error }] = useConvertGroupCreditsIntoPrivateMutation({
 *   variables: {
 *      numberOfCredits: // value for 'numberOfCredits'
 *   },
 * });
 */
export function useConvertGroupCreditsIntoPrivateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        ConvertGroupCreditsIntoPrivateMutation,
        ConvertGroupCreditsIntoPrivateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        ConvertGroupCreditsIntoPrivateMutation,
        ConvertGroupCreditsIntoPrivateMutationVariables
    >(ConvertGroupCreditsIntoPrivateDocument, options);
}
export type ConvertGroupCreditsIntoPrivateMutationHookResult = ReturnType<
    typeof useConvertGroupCreditsIntoPrivateMutation
>;
export type ConvertGroupCreditsIntoPrivateMutationResult =
    Apollo.MutationResult<ConvertGroupCreditsIntoPrivateMutation>;
export type ConvertGroupCreditsIntoPrivateMutationOptions = Apollo.BaseMutationOptions<
    ConvertGroupCreditsIntoPrivateMutation,
    ConvertGroupCreditsIntoPrivateMutationVariables
>;
export const LastTransactionDocument = gql`
    query lastTransaction {
        lastTransaction {
            ...LastTransaction
        }
    }
    ${LastTransactionFragmentDoc}
`;

/**
 * __useLastTransactionQuery__
 *
 * To run a query within a React component, call `useLastTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastTransactionQuery({
 *   variables: {
 *   },
 * });
 */
export function useLastTransactionQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        LastTransactionQuery,
        LastTransactionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<LastTransactionQuery, LastTransactionQueryVariables>(
        LastTransactionDocument,
        options,
    );
}
export function useLastTransactionLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        LastTransactionQuery,
        LastTransactionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<LastTransactionQuery, LastTransactionQueryVariables>(
        LastTransactionDocument,
        options,
    );
}
export function useLastTransactionSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        LastTransactionQuery,
        LastTransactionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<LastTransactionQuery, LastTransactionQueryVariables>(
        LastTransactionDocument,
        options,
    );
}
export type LastTransactionQueryHookResult = ReturnType<typeof useLastTransactionQuery>;
export type LastTransactionLazyQueryHookResult = ReturnType<typeof useLastTransactionLazyQuery>;
export type LastTransactionSuspenseQueryHookResult = ReturnType<
    typeof useLastTransactionSuspenseQuery
>;
export type LastTransactionQueryResult = Apollo.QueryResult<
    LastTransactionQuery,
    LastTransactionQueryVariables
>;
export const TrialStoreBannerDocument = gql`
    query trialStoreBanner {
        appropriatePromoCampaign {
            bannerText
            campaignEndDate
            campaignStartDate
            type
        }
    }
`;

/**
 * __useTrialStoreBannerQuery__
 *
 * To run a query within a React component, call `useTrialStoreBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialStoreBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialStoreBannerQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrialStoreBannerQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TrialStoreBannerQuery,
        TrialStoreBannerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TrialStoreBannerQuery, TrialStoreBannerQueryVariables>(
        TrialStoreBannerDocument,
        options,
    );
}
export function useTrialStoreBannerLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TrialStoreBannerQuery,
        TrialStoreBannerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TrialStoreBannerQuery, TrialStoreBannerQueryVariables>(
        TrialStoreBannerDocument,
        options,
    );
}
export function useTrialStoreBannerSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TrialStoreBannerQuery,
        TrialStoreBannerQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<TrialStoreBannerQuery, TrialStoreBannerQueryVariables>(
        TrialStoreBannerDocument,
        options,
    );
}
export type TrialStoreBannerQueryHookResult = ReturnType<typeof useTrialStoreBannerQuery>;
export type TrialStoreBannerLazyQueryHookResult = ReturnType<typeof useTrialStoreBannerLazyQuery>;
export type TrialStoreBannerSuspenseQueryHookResult = ReturnType<
    typeof useTrialStoreBannerSuspenseQuery
>;
export type TrialStoreBannerQueryResult = Apollo.QueryResult<
    TrialStoreBannerQuery,
    TrialStoreBannerQueryVariables
>;
export const OnboardingChecklistDocument = gql`
    query OnboardingChecklist($slug: String!) {
        checklist(slug: $slug) {
            id
            description
            isCompleted
            slug
            title
        }
    }
`;

/**
 * __useOnboardingChecklistQuery__
 *
 * To run a query within a React component, call `useOnboardingChecklistQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingChecklistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingChecklistQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useOnboardingChecklistQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        OnboardingChecklistQuery,
        OnboardingChecklistQueryVariables
    > &
        ({ variables: OnboardingChecklistQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<OnboardingChecklistQuery, OnboardingChecklistQueryVariables>(
        OnboardingChecklistDocument,
        options,
    );
}
export function useOnboardingChecklistLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        OnboardingChecklistQuery,
        OnboardingChecklistQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        OnboardingChecklistQuery,
        OnboardingChecklistQueryVariables
    >(OnboardingChecklistDocument, options);
}
export function useOnboardingChecklistSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        OnboardingChecklistQuery,
        OnboardingChecklistQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        OnboardingChecklistQuery,
        OnboardingChecklistQueryVariables
    >(OnboardingChecklistDocument, options);
}
export type OnboardingChecklistQueryHookResult = ReturnType<typeof useOnboardingChecklistQuery>;
export type OnboardingChecklistLazyQueryHookResult = ReturnType<
    typeof useOnboardingChecklistLazyQuery
>;
export type OnboardingChecklistSuspenseQueryHookResult = ReturnType<
    typeof useOnboardingChecklistSuspenseQuery
>;
export type OnboardingChecklistQueryResult = Apollo.QueryResult<
    OnboardingChecklistQuery,
    OnboardingChecklistQueryVariables
>;
export const OnboardingAchievementDocument = gql`
    query OnboardingAchievement($slug: String!) {
        studentAchievement(slug: $slug) {
            ...Achievement
        }
    }
    ${AchievementFragmentDoc}
`;

/**
 * __useOnboardingAchievementQuery__
 *
 * To run a query within a React component, call `useOnboardingAchievementQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingAchievementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingAchievementQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useOnboardingAchievementQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        OnboardingAchievementQuery,
        OnboardingAchievementQueryVariables
    > &
        ({ variables: OnboardingAchievementQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        OnboardingAchievementQuery,
        OnboardingAchievementQueryVariables
    >(OnboardingAchievementDocument, options);
}
export function useOnboardingAchievementLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        OnboardingAchievementQuery,
        OnboardingAchievementQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        OnboardingAchievementQuery,
        OnboardingAchievementQueryVariables
    >(OnboardingAchievementDocument, options);
}
export function useOnboardingAchievementSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        OnboardingAchievementQuery,
        OnboardingAchievementQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        OnboardingAchievementQuery,
        OnboardingAchievementQueryVariables
    >(OnboardingAchievementDocument, options);
}
export type OnboardingAchievementQueryHookResult = ReturnType<typeof useOnboardingAchievementQuery>;
export type OnboardingAchievementLazyQueryHookResult = ReturnType<
    typeof useOnboardingAchievementLazyQuery
>;
export type OnboardingAchievementSuspenseQueryHookResult = ReturnType<
    typeof useOnboardingAchievementSuspenseQuery
>;
export type OnboardingAchievementQueryResult = Apollo.QueryResult<
    OnboardingAchievementQuery,
    OnboardingAchievementQueryVariables
>;
export const ClosestAppointmentsDocument = gql`
    query closestAppointments($historicalStartDate: DateTime!, $ongoingStartDate: DateTime!) {
        pastAppointments: appointmentsList(
            fromDate: $historicalStartDate
            appointmentStatus: "attended"
            sort: "DESC"
            limit: 1
        ) {
            id
            status
            class {
                uid
                startDate
                endDate
            }
        }
        futureAppointments: appointmentsList(
            fromDate: $ongoingStartDate
            appointmentStatus: "active"
            sort: "ASC"
            limit: 1
        ) {
            id
            status
            class {
                uid
                startDate
                endDate
            }
        }
    }
`;

/**
 * __useClosestAppointmentsQuery__
 *
 * To run a query within a React component, call `useClosestAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClosestAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClosestAppointmentsQuery({
 *   variables: {
 *      historicalStartDate: // value for 'historicalStartDate'
 *      ongoingStartDate: // value for 'ongoingStartDate'
 *   },
 * });
 */
export function useClosestAppointmentsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ClosestAppointmentsQuery,
        ClosestAppointmentsQueryVariables
    > &
        ({ variables: ClosestAppointmentsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ClosestAppointmentsQuery, ClosestAppointmentsQueryVariables>(
        ClosestAppointmentsDocument,
        options,
    );
}
export function useClosestAppointmentsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ClosestAppointmentsQuery,
        ClosestAppointmentsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ClosestAppointmentsQuery,
        ClosestAppointmentsQueryVariables
    >(ClosestAppointmentsDocument, options);
}
export function useClosestAppointmentsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ClosestAppointmentsQuery,
        ClosestAppointmentsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ClosestAppointmentsQuery,
        ClosestAppointmentsQueryVariables
    >(ClosestAppointmentsDocument, options);
}
export type ClosestAppointmentsQueryHookResult = ReturnType<typeof useClosestAppointmentsQuery>;
export type ClosestAppointmentsLazyQueryHookResult = ReturnType<
    typeof useClosestAppointmentsLazyQuery
>;
export type ClosestAppointmentsSuspenseQueryHookResult = ReturnType<
    typeof useClosestAppointmentsSuspenseQuery
>;
export type ClosestAppointmentsQueryResult = Apollo.QueryResult<
    ClosestAppointmentsQuery,
    ClosestAppointmentsQueryVariables
>;
export const SetWeeklyGoalDocument = gql`
    mutation setWeeklyGoal($value: Int!) {
        setWeeklyGoal(value: $value) {
            ...StudentWeeklyGoal
        }
    }
    ${StudentWeeklyGoalFragmentDoc}
`;
export type SetWeeklyGoalMutationFn = Apollo.MutationFunction<
    SetWeeklyGoalMutation,
    SetWeeklyGoalMutationVariables
>;

/**
 * __useSetWeeklyGoalMutation__
 *
 * To run a mutation, you first call `useSetWeeklyGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWeeklyGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWeeklyGoalMutation, { data, loading, error }] = useSetWeeklyGoalMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetWeeklyGoalMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SetWeeklyGoalMutation,
        SetWeeklyGoalMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<SetWeeklyGoalMutation, SetWeeklyGoalMutationVariables>(
        SetWeeklyGoalDocument,
        options,
    );
}
export type SetWeeklyGoalMutationHookResult = ReturnType<typeof useSetWeeklyGoalMutation>;
export type SetWeeklyGoalMutationResult = Apollo.MutationResult<SetWeeklyGoalMutation>;
export type SetWeeklyGoalMutationOptions = Apollo.BaseMutationOptions<
    SetWeeklyGoalMutation,
    SetWeeklyGoalMutationVariables
>;
export const WeeklyProgressDocument = gql`
    query weeklyProgress($page: Int!) {
        activeGoal {
            ...StudentWeeklyGoal
        }
        studentGoalWidgetData(page: $page) {
            ...StudentGoalWidgetData
        }
    }
    ${StudentWeeklyGoalFragmentDoc}
    ${StudentGoalWidgetDataFragmentDoc}
`;

/**
 * __useWeeklyProgressQuery__
 *
 * To run a query within a React component, call `useWeeklyProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeeklyProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeeklyProgressQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useWeeklyProgressQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        WeeklyProgressQuery,
        WeeklyProgressQueryVariables
    > &
        ({ variables: WeeklyProgressQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<WeeklyProgressQuery, WeeklyProgressQueryVariables>(
        WeeklyProgressDocument,
        options,
    );
}
export function useWeeklyProgressLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        WeeklyProgressQuery,
        WeeklyProgressQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<WeeklyProgressQuery, WeeklyProgressQueryVariables>(
        WeeklyProgressDocument,
        options,
    );
}
export function useWeeklyProgressSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        WeeklyProgressQuery,
        WeeklyProgressQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<WeeklyProgressQuery, WeeklyProgressQueryVariables>(
        WeeklyProgressDocument,
        options,
    );
}
export type WeeklyProgressQueryHookResult = ReturnType<typeof useWeeklyProgressQuery>;
export type WeeklyProgressLazyQueryHookResult = ReturnType<typeof useWeeklyProgressLazyQuery>;
export type WeeklyProgressSuspenseQueryHookResult = ReturnType<
    typeof useWeeklyProgressSuspenseQuery
>;
export type WeeklyProgressQueryResult = Apollo.QueryResult<
    WeeklyProgressQuery,
    WeeklyProgressQueryVariables
>;
export const GoalSettingDocument = gql`
    query goalSetting($page: Int!) {
        subscriptions: studentSubscriptionInfo {
            currentSubscription {
                id
            }
        }
        appointments: studentGoalWidgetData(page: $page) {
            firstAppointmentDate
        }
        activeGoal {
            id
        }
    }
`;

/**
 * __useGoalSettingQuery__
 *
 * To run a query within a React component, call `useGoalSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalSettingQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGoalSettingQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<GoalSettingQuery, GoalSettingQueryVariables> &
        ({ variables: GoalSettingQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GoalSettingQuery, GoalSettingQueryVariables>(
        GoalSettingDocument,
        options,
    );
}
export function useGoalSettingLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GoalSettingQuery,
        GoalSettingQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GoalSettingQuery, GoalSettingQueryVariables>(
        GoalSettingDocument,
        options,
    );
}
export function useGoalSettingSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        GoalSettingQuery,
        GoalSettingQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<GoalSettingQuery, GoalSettingQueryVariables>(
        GoalSettingDocument,
        options,
    );
}
export type GoalSettingQueryHookResult = ReturnType<typeof useGoalSettingQuery>;
export type GoalSettingLazyQueryHookResult = ReturnType<typeof useGoalSettingLazyQuery>;
export type GoalSettingSuspenseQueryHookResult = ReturnType<typeof useGoalSettingSuspenseQuery>;
export type GoalSettingQueryResult = Apollo.QueryResult<
    GoalSettingQuery,
    GoalSettingQueryVariables
>;
export const StudentAchievementsDocument = gql`
    query studentAchievements {
        studentAchievements {
            ...Achievement
        }
    }
    ${AchievementFragmentDoc}
`;

/**
 * __useStudentAchievementsQuery__
 *
 * To run a query within a React component, call `useStudentAchievementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentAchievementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentAchievementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentAchievementsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        StudentAchievementsQuery,
        StudentAchievementsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<StudentAchievementsQuery, StudentAchievementsQueryVariables>(
        StudentAchievementsDocument,
        options,
    );
}
export function useStudentAchievementsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        StudentAchievementsQuery,
        StudentAchievementsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        StudentAchievementsQuery,
        StudentAchievementsQueryVariables
    >(StudentAchievementsDocument, options);
}
export function useStudentAchievementsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        StudentAchievementsQuery,
        StudentAchievementsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        StudentAchievementsQuery,
        StudentAchievementsQueryVariables
    >(StudentAchievementsDocument, options);
}
export type StudentAchievementsQueryHookResult = ReturnType<typeof useStudentAchievementsQuery>;
export type StudentAchievementsLazyQueryHookResult = ReturnType<
    typeof useStudentAchievementsLazyQuery
>;
export type StudentAchievementsSuspenseQueryHookResult = ReturnType<
    typeof useStudentAchievementsSuspenseQuery
>;
export type StudentAchievementsQueryResult = Apollo.QueryResult<
    StudentAchievementsQuery,
    StudentAchievementsQueryVariables
>;
export const ClaimAttendanceAchievementDocument = gql`
    mutation claimAttendanceAchievement($claimId: Ulid!) {
        claimAttendanceAchievement(claimId: $claimId) {
            ...Achievement
        }
    }
    ${AchievementFragmentDoc}
`;
export type ClaimAttendanceAchievementMutationFn = Apollo.MutationFunction<
    ClaimAttendanceAchievementMutation,
    ClaimAttendanceAchievementMutationVariables
>;

/**
 * __useClaimAttendanceAchievementMutation__
 *
 * To run a mutation, you first call `useClaimAttendanceAchievementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimAttendanceAchievementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimAttendanceAchievementMutation, { data, loading, error }] = useClaimAttendanceAchievementMutation({
 *   variables: {
 *      claimId: // value for 'claimId'
 *   },
 * });
 */
export function useClaimAttendanceAchievementMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        ClaimAttendanceAchievementMutation,
        ClaimAttendanceAchievementMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        ClaimAttendanceAchievementMutation,
        ClaimAttendanceAchievementMutationVariables
    >(ClaimAttendanceAchievementDocument, options);
}
export type ClaimAttendanceAchievementMutationHookResult = ReturnType<
    typeof useClaimAttendanceAchievementMutation
>;
export type ClaimAttendanceAchievementMutationResult =
    Apollo.MutationResult<ClaimAttendanceAchievementMutation>;
export type ClaimAttendanceAchievementMutationOptions = Apollo.BaseMutationOptions<
    ClaimAttendanceAchievementMutation,
    ClaimAttendanceAchievementMutationVariables
>;
export const PromoteGuestUserDocument = gql`
    mutation promoteGuestUser($password: String!, $name: String) {
        promoteGuestUser(password: $password, name: $name) {
            id
        }
    }
`;
export type PromoteGuestUserMutationFn = Apollo.MutationFunction<
    PromoteGuestUserMutation,
    PromoteGuestUserMutationVariables
>;

/**
 * __usePromoteGuestUserMutation__
 *
 * To run a mutation, you first call `usePromoteGuestUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromoteGuestUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promoteGuestUserMutation, { data, loading, error }] = usePromoteGuestUserMutation({
 *   variables: {
 *      password: // value for 'password'
 *      name: // value for 'name'
 *   },
 * });
 */
export function usePromoteGuestUserMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        PromoteGuestUserMutation,
        PromoteGuestUserMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        PromoteGuestUserMutation,
        PromoteGuestUserMutationVariables
    >(PromoteGuestUserDocument, options);
}
export type PromoteGuestUserMutationHookResult = ReturnType<typeof usePromoteGuestUserMutation>;
export type PromoteGuestUserMutationResult = Apollo.MutationResult<PromoteGuestUserMutation>;
export type PromoteGuestUserMutationOptions = Apollo.BaseMutationOptions<
    PromoteGuestUserMutation,
    PromoteGuestUserMutationVariables
>;
export const BookingPreviewResultsDocument = gql`
    query bookingPreviewResults($bookingFilter: ProvideBookableClassSlotsInput!) {
        classes(bookingFilter: $bookingFilter) {
            ...BookableClass
        }
    }
    ${BookableClassFragmentDoc}
`;

/**
 * __useBookingPreviewResultsQuery__
 *
 * To run a query within a React component, call `useBookingPreviewResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingPreviewResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingPreviewResultsQuery({
 *   variables: {
 *      bookingFilter: // value for 'bookingFilter'
 *   },
 * });
 */
export function useBookingPreviewResultsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        BookingPreviewResultsQuery,
        BookingPreviewResultsQueryVariables
    > &
        ({ variables: BookingPreviewResultsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        BookingPreviewResultsQuery,
        BookingPreviewResultsQueryVariables
    >(BookingPreviewResultsDocument, options);
}
export function useBookingPreviewResultsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BookingPreviewResultsQuery,
        BookingPreviewResultsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        BookingPreviewResultsQuery,
        BookingPreviewResultsQueryVariables
    >(BookingPreviewResultsDocument, options);
}
export function useBookingPreviewResultsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        BookingPreviewResultsQuery,
        BookingPreviewResultsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        BookingPreviewResultsQuery,
        BookingPreviewResultsQueryVariables
    >(BookingPreviewResultsDocument, options);
}
export type BookingPreviewResultsQueryHookResult = ReturnType<typeof useBookingPreviewResultsQuery>;
export type BookingPreviewResultsLazyQueryHookResult = ReturnType<
    typeof useBookingPreviewResultsLazyQuery
>;
export type BookingPreviewResultsSuspenseQueryHookResult = ReturnType<
    typeof useBookingPreviewResultsSuspenseQuery
>;
export type BookingPreviewResultsQueryResult = Apollo.QueryResult<
    BookingPreviewResultsQuery,
    BookingPreviewResultsQueryVariables
>;
export const AnswerBookingExperienceSurveyDocument = gql`
    mutation answerBookingExperienceSurvey($score: Int!) {
        answerBookingExperienceSurvey(score: $score) {
            id
        }
    }
`;
export type AnswerBookingExperienceSurveyMutationFn = Apollo.MutationFunction<
    AnswerBookingExperienceSurveyMutation,
    AnswerBookingExperienceSurveyMutationVariables
>;

/**
 * __useAnswerBookingExperienceSurveyMutation__
 *
 * To run a mutation, you first call `useAnswerBookingExperienceSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerBookingExperienceSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerBookingExperienceSurveyMutation, { data, loading, error }] = useAnswerBookingExperienceSurveyMutation({
 *   variables: {
 *      score: // value for 'score'
 *   },
 * });
 */
export function useAnswerBookingExperienceSurveyMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        AnswerBookingExperienceSurveyMutation,
        AnswerBookingExperienceSurveyMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        AnswerBookingExperienceSurveyMutation,
        AnswerBookingExperienceSurveyMutationVariables
    >(AnswerBookingExperienceSurveyDocument, options);
}
export type AnswerBookingExperienceSurveyMutationHookResult = ReturnType<
    typeof useAnswerBookingExperienceSurveyMutation
>;
export type AnswerBookingExperienceSurveyMutationResult =
    Apollo.MutationResult<AnswerBookingExperienceSurveyMutation>;
export type AnswerBookingExperienceSurveyMutationOptions = Apollo.BaseMutationOptions<
    AnswerBookingExperienceSurveyMutation,
    AnswerBookingExperienceSurveyMutationVariables
>;
export const AnswerBookingExperienceSurveyDetailsDocument = gql`
    mutation answerBookingExperienceSurveyDetails(
        $extraFeedback: [BookingExperienceExtraFeedback!]
        $comment: String
        $answer: BookingExperienceAnswerType
    ) {
        answerBookingExperienceSurveyDetails(
            extraFeedback: $extraFeedback
            comment: $comment
            answer: $answer
        ) {
            id
        }
    }
`;
export type AnswerBookingExperienceSurveyDetailsMutationFn = Apollo.MutationFunction<
    AnswerBookingExperienceSurveyDetailsMutation,
    AnswerBookingExperienceSurveyDetailsMutationVariables
>;

/**
 * __useAnswerBookingExperienceSurveyDetailsMutation__
 *
 * To run a mutation, you first call `useAnswerBookingExperienceSurveyDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerBookingExperienceSurveyDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerBookingExperienceSurveyDetailsMutation, { data, loading, error }] = useAnswerBookingExperienceSurveyDetailsMutation({
 *   variables: {
 *      extraFeedback: // value for 'extraFeedback'
 *      comment: // value for 'comment'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useAnswerBookingExperienceSurveyDetailsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        AnswerBookingExperienceSurveyDetailsMutation,
        AnswerBookingExperienceSurveyDetailsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        AnswerBookingExperienceSurveyDetailsMutation,
        AnswerBookingExperienceSurveyDetailsMutationVariables
    >(AnswerBookingExperienceSurveyDetailsDocument, options);
}
export type AnswerBookingExperienceSurveyDetailsMutationHookResult = ReturnType<
    typeof useAnswerBookingExperienceSurveyDetailsMutation
>;
export type AnswerBookingExperienceSurveyDetailsMutationResult =
    Apollo.MutationResult<AnswerBookingExperienceSurveyDetailsMutation>;
export type AnswerBookingExperienceSurveyDetailsMutationOptions = Apollo.BaseMutationOptions<
    AnswerBookingExperienceSurveyDetailsMutation,
    AnswerBookingExperienceSurveyDetailsMutationVariables
>;
export const DismissBookingExperienceSurveyDocument = gql`
    mutation dismissBookingExperienceSurvey {
        dismissBookingExperienceSurvey {
            id
        }
    }
`;
export type DismissBookingExperienceSurveyMutationFn = Apollo.MutationFunction<
    DismissBookingExperienceSurveyMutation,
    DismissBookingExperienceSurveyMutationVariables
>;

/**
 * __useDismissBookingExperienceSurveyMutation__
 *
 * To run a mutation, you first call `useDismissBookingExperienceSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissBookingExperienceSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissBookingExperienceSurveyMutation, { data, loading, error }] = useDismissBookingExperienceSurveyMutation({
 *   variables: {
 *   },
 * });
 */
export function useDismissBookingExperienceSurveyMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DismissBookingExperienceSurveyMutation,
        DismissBookingExperienceSurveyMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DismissBookingExperienceSurveyMutation,
        DismissBookingExperienceSurveyMutationVariables
    >(DismissBookingExperienceSurveyDocument, options);
}
export type DismissBookingExperienceSurveyMutationHookResult = ReturnType<
    typeof useDismissBookingExperienceSurveyMutation
>;
export type DismissBookingExperienceSurveyMutationResult =
    Apollo.MutationResult<DismissBookingExperienceSurveyMutation>;
export type DismissBookingExperienceSurveyMutationOptions = Apollo.BaseMutationOptions<
    DismissBookingExperienceSurveyMutation,
    DismissBookingExperienceSurveyMutationVariables
>;
export const StudentGetBookingFeedbackFormDocument = gql`
    query StudentGetBookingFeedbackForm {
        bookingExperienceQuestions {
            key
            translatedQuestion
            screenPosition
            fieldType
            answers {
                value
                translatedAnswer
            }
        }
    }
`;

/**
 * __useStudentGetBookingFeedbackFormQuery__
 *
 * To run a query within a React component, call `useStudentGetBookingFeedbackFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentGetBookingFeedbackFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentGetBookingFeedbackFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentGetBookingFeedbackFormQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        StudentGetBookingFeedbackFormQuery,
        StudentGetBookingFeedbackFormQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        StudentGetBookingFeedbackFormQuery,
        StudentGetBookingFeedbackFormQueryVariables
    >(StudentGetBookingFeedbackFormDocument, options);
}
export function useStudentGetBookingFeedbackFormLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        StudentGetBookingFeedbackFormQuery,
        StudentGetBookingFeedbackFormQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        StudentGetBookingFeedbackFormQuery,
        StudentGetBookingFeedbackFormQueryVariables
    >(StudentGetBookingFeedbackFormDocument, options);
}
export function useStudentGetBookingFeedbackFormSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        StudentGetBookingFeedbackFormQuery,
        StudentGetBookingFeedbackFormQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        StudentGetBookingFeedbackFormQuery,
        StudentGetBookingFeedbackFormQueryVariables
    >(StudentGetBookingFeedbackFormDocument, options);
}
export type StudentGetBookingFeedbackFormQueryHookResult = ReturnType<
    typeof useStudentGetBookingFeedbackFormQuery
>;
export type StudentGetBookingFeedbackFormLazyQueryHookResult = ReturnType<
    typeof useStudentGetBookingFeedbackFormLazyQuery
>;
export type StudentGetBookingFeedbackFormSuspenseQueryHookResult = ReturnType<
    typeof useStudentGetBookingFeedbackFormSuspenseQuery
>;
export type StudentGetBookingFeedbackFormQueryResult = Apollo.QueryResult<
    StudentGetBookingFeedbackFormQuery,
    StudentGetBookingFeedbackFormQueryVariables
>;
export const BookingSearchResultsDocument = gql`
    query bookingSearchResults($bookingFilter: ProvideBookableClassSlotsInput!) {
        classes(bookingFilter: $bookingFilter) {
            ...BookableClass
        }
    }
    ${BookableClassFragmentDoc}
`;

/**
 * __useBookingSearchResultsQuery__
 *
 * To run a query within a React component, call `useBookingSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingSearchResultsQuery({
 *   variables: {
 *      bookingFilter: // value for 'bookingFilter'
 *   },
 * });
 */
export function useBookingSearchResultsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        BookingSearchResultsQuery,
        BookingSearchResultsQueryVariables
    > &
        ({ variables: BookingSearchResultsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<BookingSearchResultsQuery, BookingSearchResultsQueryVariables>(
        BookingSearchResultsDocument,
        options,
    );
}
export function useBookingSearchResultsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BookingSearchResultsQuery,
        BookingSearchResultsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        BookingSearchResultsQuery,
        BookingSearchResultsQueryVariables
    >(BookingSearchResultsDocument, options);
}
export function useBookingSearchResultsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        BookingSearchResultsQuery,
        BookingSearchResultsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        BookingSearchResultsQuery,
        BookingSearchResultsQueryVariables
    >(BookingSearchResultsDocument, options);
}
export type BookingSearchResultsQueryHookResult = ReturnType<typeof useBookingSearchResultsQuery>;
export type BookingSearchResultsLazyQueryHookResult = ReturnType<
    typeof useBookingSearchResultsLazyQuery
>;
export type BookingSearchResultsSuspenseQueryHookResult = ReturnType<
    typeof useBookingSearchResultsSuspenseQuery
>;
export type BookingSearchResultsQueryResult = Apollo.QueryResult<
    BookingSearchResultsQuery,
    BookingSearchResultsQueryVariables
>;
export const BookingSuggestedResultsDocument = gql`
    query bookingSuggestedResults(
        $nextAvailableClassDateBookingFilter: ProvideBookableClassSlotsInput!
        $suggestedClassesBookingFilter: ProvideBookableClassSlotsInput!
    ) {
        nextAvailableClassDate: nextAvailableClassDate(
            bookingFilter: $nextAvailableClassDateBookingFilter
        )
        suggestedClasses: classes(bookingFilter: $suggestedClassesBookingFilter) {
            ...BookableClass
        }
    }
    ${BookableClassFragmentDoc}
`;

/**
 * __useBookingSuggestedResultsQuery__
 *
 * To run a query within a React component, call `useBookingSuggestedResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingSuggestedResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingSuggestedResultsQuery({
 *   variables: {
 *      nextAvailableClassDateBookingFilter: // value for 'nextAvailableClassDateBookingFilter'
 *      suggestedClassesBookingFilter: // value for 'suggestedClassesBookingFilter'
 *   },
 * });
 */
export function useBookingSuggestedResultsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        BookingSuggestedResultsQuery,
        BookingSuggestedResultsQueryVariables
    > &
        ({ variables: BookingSuggestedResultsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        BookingSuggestedResultsQuery,
        BookingSuggestedResultsQueryVariables
    >(BookingSuggestedResultsDocument, options);
}
export function useBookingSuggestedResultsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BookingSuggestedResultsQuery,
        BookingSuggestedResultsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        BookingSuggestedResultsQuery,
        BookingSuggestedResultsQueryVariables
    >(BookingSuggestedResultsDocument, options);
}
export function useBookingSuggestedResultsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        BookingSuggestedResultsQuery,
        BookingSuggestedResultsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        BookingSuggestedResultsQuery,
        BookingSuggestedResultsQueryVariables
    >(BookingSuggestedResultsDocument, options);
}
export type BookingSuggestedResultsQueryHookResult = ReturnType<
    typeof useBookingSuggestedResultsQuery
>;
export type BookingSuggestedResultsLazyQueryHookResult = ReturnType<
    typeof useBookingSuggestedResultsLazyQuery
>;
export type BookingSuggestedResultsSuspenseQueryHookResult = ReturnType<
    typeof useBookingSuggestedResultsSuspenseQuery
>;
export type BookingSuggestedResultsQueryResult = Apollo.QueryResult<
    BookingSuggestedResultsQuery,
    BookingSuggestedResultsQueryVariables
>;
export const BookingWidgetDocument = gql`
    query bookingWidget($bookingFilter: ProvideBookableClassSlotsInput!) {
        classes(bookingFilter: $bookingFilter) {
            uid
            isExisting
            startDate
            endDate
            isForNewClassroom
            students {
                ...ClassCardStudent
            }
            teacher {
                ...ClassCardTeacher
            }
            appointment {
                id
                status
            }
        }
    }
    ${ClassCardStudentFragmentDoc}
    ${ClassCardTeacherFragmentDoc}
`;

/**
 * __useBookingWidgetQuery__
 *
 * To run a query within a React component, call `useBookingWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingWidgetQuery({
 *   variables: {
 *      bookingFilter: // value for 'bookingFilter'
 *   },
 * });
 */
export function useBookingWidgetQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        BookingWidgetQuery,
        BookingWidgetQueryVariables
    > &
        ({ variables: BookingWidgetQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<BookingWidgetQuery, BookingWidgetQueryVariables>(
        BookingWidgetDocument,
        options,
    );
}
export function useBookingWidgetLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BookingWidgetQuery,
        BookingWidgetQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<BookingWidgetQuery, BookingWidgetQueryVariables>(
        BookingWidgetDocument,
        options,
    );
}
export function useBookingWidgetSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        BookingWidgetQuery,
        BookingWidgetQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<BookingWidgetQuery, BookingWidgetQueryVariables>(
        BookingWidgetDocument,
        options,
    );
}
export type BookingWidgetQueryHookResult = ReturnType<typeof useBookingWidgetQuery>;
export type BookingWidgetLazyQueryHookResult = ReturnType<typeof useBookingWidgetLazyQuery>;
export type BookingWidgetSuspenseQueryHookResult = ReturnType<typeof useBookingWidgetSuspenseQuery>;
export type BookingWidgetQueryResult = Apollo.QueryResult<
    BookingWidgetQuery,
    BookingWidgetQueryVariables
>;
export const CancelClassDocument = gql`
    mutation cancelClass($classId: String!, $isImmediate: Boolean = false) {
        cancelClass(classId: $classId, isImmediate: $isImmediate) {
            ...ClassDetailsTileClass
            lesson {
                ...ClassDetailsTileLesson
                studentStatus
            }
        }
    }
    ${ClassDetailsTileClassFragmentDoc}
    ${ClassDetailsTileLessonFragmentDoc}
`;
export type CancelClassMutationFn = Apollo.MutationFunction<
    CancelClassMutation,
    CancelClassMutationVariables
>;

/**
 * __useCancelClassMutation__
 *
 * To run a mutation, you first call `useCancelClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelClassMutation, { data, loading, error }] = useCancelClassMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      isImmediate: // value for 'isImmediate'
 *   },
 * });
 */
export function useCancelClassMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CancelClassMutation,
        CancelClassMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CancelClassMutation, CancelClassMutationVariables>(
        CancelClassDocument,
        options,
    );
}
export type CancelClassMutationHookResult = ReturnType<typeof useCancelClassMutation>;
export type CancelClassMutationResult = Apollo.MutationResult<CancelClassMutation>;
export type CancelClassMutationOptions = Apollo.BaseMutationOptions<
    CancelClassMutation,
    CancelClassMutationVariables
>;
export const RecommendedOrientationClassesDocument = gql`
    query recommendedOrientationClasses($bookingFilter: ProvideBookableClassSlotsInput!) {
        recommendedOrientationClasses: classes(bookingFilter: $bookingFilter) {
            ...BookableClass
        }
    }
    ${BookableClassFragmentDoc}
`;

/**
 * __useRecommendedOrientationClassesQuery__
 *
 * To run a query within a React component, call `useRecommendedOrientationClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedOrientationClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedOrientationClassesQuery({
 *   variables: {
 *      bookingFilter: // value for 'bookingFilter'
 *   },
 * });
 */
export function useRecommendedOrientationClassesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        RecommendedOrientationClassesQuery,
        RecommendedOrientationClassesQueryVariables
    > &
        (
            | { variables: RecommendedOrientationClassesQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        RecommendedOrientationClassesQuery,
        RecommendedOrientationClassesQueryVariables
    >(RecommendedOrientationClassesDocument, options);
}
export function useRecommendedOrientationClassesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        RecommendedOrientationClassesQuery,
        RecommendedOrientationClassesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        RecommendedOrientationClassesQuery,
        RecommendedOrientationClassesQueryVariables
    >(RecommendedOrientationClassesDocument, options);
}
export function useRecommendedOrientationClassesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        RecommendedOrientationClassesQuery,
        RecommendedOrientationClassesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        RecommendedOrientationClassesQuery,
        RecommendedOrientationClassesQueryVariables
    >(RecommendedOrientationClassesDocument, options);
}
export type RecommendedOrientationClassesQueryHookResult = ReturnType<
    typeof useRecommendedOrientationClassesQuery
>;
export type RecommendedOrientationClassesLazyQueryHookResult = ReturnType<
    typeof useRecommendedOrientationClassesLazyQuery
>;
export type RecommendedOrientationClassesSuspenseQueryHookResult = ReturnType<
    typeof useRecommendedOrientationClassesSuspenseQuery
>;
export type RecommendedOrientationClassesQueryResult = Apollo.QueryResult<
    RecommendedOrientationClassesQuery,
    RecommendedOrientationClassesQueryVariables
>;
export const ShouldDisplayClassroomPromoBannerStudentDocument = gql`
    query shouldDisplayClassroomPromoBannerStudent {
        shouldDisplayClassroomPromoBanner: isWidgetEligibleForDisplay(
            widgetClass: StudentFutureClassInClassroomBanner
        )
    }
`;

/**
 * __useShouldDisplayClassroomPromoBannerStudentQuery__
 *
 * To run a query within a React component, call `useShouldDisplayClassroomPromoBannerStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useShouldDisplayClassroomPromoBannerStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShouldDisplayClassroomPromoBannerStudentQuery({
 *   variables: {
 *   },
 * });
 */
export function useShouldDisplayClassroomPromoBannerStudentQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        ShouldDisplayClassroomPromoBannerStudentQuery,
        ShouldDisplayClassroomPromoBannerStudentQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        ShouldDisplayClassroomPromoBannerStudentQuery,
        ShouldDisplayClassroomPromoBannerStudentQueryVariables
    >(ShouldDisplayClassroomPromoBannerStudentDocument, options);
}
export function useShouldDisplayClassroomPromoBannerStudentLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ShouldDisplayClassroomPromoBannerStudentQuery,
        ShouldDisplayClassroomPromoBannerStudentQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ShouldDisplayClassroomPromoBannerStudentQuery,
        ShouldDisplayClassroomPromoBannerStudentQueryVariables
    >(ShouldDisplayClassroomPromoBannerStudentDocument, options);
}
export function useShouldDisplayClassroomPromoBannerStudentSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ShouldDisplayClassroomPromoBannerStudentQuery,
        ShouldDisplayClassroomPromoBannerStudentQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ShouldDisplayClassroomPromoBannerStudentQuery,
        ShouldDisplayClassroomPromoBannerStudentQueryVariables
    >(ShouldDisplayClassroomPromoBannerStudentDocument, options);
}
export type ShouldDisplayClassroomPromoBannerStudentQueryHookResult = ReturnType<
    typeof useShouldDisplayClassroomPromoBannerStudentQuery
>;
export type ShouldDisplayClassroomPromoBannerStudentLazyQueryHookResult = ReturnType<
    typeof useShouldDisplayClassroomPromoBannerStudentLazyQuery
>;
export type ShouldDisplayClassroomPromoBannerStudentSuspenseQueryHookResult = ReturnType<
    typeof useShouldDisplayClassroomPromoBannerStudentSuspenseQuery
>;
export type ShouldDisplayClassroomPromoBannerStudentQueryResult = Apollo.QueryResult<
    ShouldDisplayClassroomPromoBannerStudentQuery,
    ShouldDisplayClassroomPromoBannerStudentQueryVariables
>;
export const JoinActivityChallengeDocument = gql`
    mutation joinActivityChallenge($challengeName: String!) {
        joinChallenge(challengeName: $challengeName) {
            ...BaseActivityChallengeParticipant
        }
    }
    ${BaseActivityChallengeParticipantFragmentDoc}
`;
export type JoinActivityChallengeMutationFn = Apollo.MutationFunction<
    JoinActivityChallengeMutation,
    JoinActivityChallengeMutationVariables
>;

/**
 * __useJoinActivityChallengeMutation__
 *
 * To run a mutation, you first call `useJoinActivityChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinActivityChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinActivityChallengeMutation, { data, loading, error }] = useJoinActivityChallengeMutation({
 *   variables: {
 *      challengeName: // value for 'challengeName'
 *   },
 * });
 */
export function useJoinActivityChallengeMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        JoinActivityChallengeMutation,
        JoinActivityChallengeMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        JoinActivityChallengeMutation,
        JoinActivityChallengeMutationVariables
    >(JoinActivityChallengeDocument, options);
}
export type JoinActivityChallengeMutationHookResult = ReturnType<
    typeof useJoinActivityChallengeMutation
>;
export type JoinActivityChallengeMutationResult =
    Apollo.MutationResult<JoinActivityChallengeMutation>;
export type JoinActivityChallengeMutationOptions = Apollo.BaseMutationOptions<
    JoinActivityChallengeMutation,
    JoinActivityChallengeMutationVariables
>;
export const ActivityChallengePageDocument = gql`
    query activityChallengePage($challengeName: String!) {
        activityChallenge(challengeName: $challengeName) {
            ...BaseActivityChallenge
        }
    }
    ${BaseActivityChallengeFragmentDoc}
`;

/**
 * __useActivityChallengePageQuery__
 *
 * To run a query within a React component, call `useActivityChallengePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityChallengePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityChallengePageQuery({
 *   variables: {
 *      challengeName: // value for 'challengeName'
 *   },
 * });
 */
export function useActivityChallengePageQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ActivityChallengePageQuery,
        ActivityChallengePageQueryVariables
    > &
        ({ variables: ActivityChallengePageQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        ActivityChallengePageQuery,
        ActivityChallengePageQueryVariables
    >(ActivityChallengePageDocument, options);
}
export function useActivityChallengePageLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ActivityChallengePageQuery,
        ActivityChallengePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ActivityChallengePageQuery,
        ActivityChallengePageQueryVariables
    >(ActivityChallengePageDocument, options);
}
export function useActivityChallengePageSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ActivityChallengePageQuery,
        ActivityChallengePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ActivityChallengePageQuery,
        ActivityChallengePageQueryVariables
    >(ActivityChallengePageDocument, options);
}
export type ActivityChallengePageQueryHookResult = ReturnType<typeof useActivityChallengePageQuery>;
export type ActivityChallengePageLazyQueryHookResult = ReturnType<
    typeof useActivityChallengePageLazyQuery
>;
export type ActivityChallengePageSuspenseQueryHookResult = ReturnType<
    typeof useActivityChallengePageSuspenseQuery
>;
export type ActivityChallengePageQueryResult = Apollo.QueryResult<
    ActivityChallengePageQuery,
    ActivityChallengePageQueryVariables
>;
export const BasicActivityChallengeDataDocument = gql`
    query basicActivityChallengeData($challengeName: String!) {
        activityChallenge(challengeName: $challengeName) {
            ...BaseActivityChallenge
        }
    }
    ${BaseActivityChallengeFragmentDoc}
`;

/**
 * __useBasicActivityChallengeDataQuery__
 *
 * To run a query within a React component, call `useBasicActivityChallengeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasicActivityChallengeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasicActivityChallengeDataQuery({
 *   variables: {
 *      challengeName: // value for 'challengeName'
 *   },
 * });
 */
export function useBasicActivityChallengeDataQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        BasicActivityChallengeDataQuery,
        BasicActivityChallengeDataQueryVariables
    > &
        (
            | { variables: BasicActivityChallengeDataQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        BasicActivityChallengeDataQuery,
        BasicActivityChallengeDataQueryVariables
    >(BasicActivityChallengeDataDocument, options);
}
export function useBasicActivityChallengeDataLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BasicActivityChallengeDataQuery,
        BasicActivityChallengeDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        BasicActivityChallengeDataQuery,
        BasicActivityChallengeDataQueryVariables
    >(BasicActivityChallengeDataDocument, options);
}
export function useBasicActivityChallengeDataSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        BasicActivityChallengeDataQuery,
        BasicActivityChallengeDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        BasicActivityChallengeDataQuery,
        BasicActivityChallengeDataQueryVariables
    >(BasicActivityChallengeDataDocument, options);
}
export type BasicActivityChallengeDataQueryHookResult = ReturnType<
    typeof useBasicActivityChallengeDataQuery
>;
export type BasicActivityChallengeDataLazyQueryHookResult = ReturnType<
    typeof useBasicActivityChallengeDataLazyQuery
>;
export type BasicActivityChallengeDataSuspenseQueryHookResult = ReturnType<
    typeof useBasicActivityChallengeDataSuspenseQuery
>;
export type BasicActivityChallengeDataQueryResult = Apollo.QueryResult<
    BasicActivityChallengeDataQuery,
    BasicActivityChallengeDataQueryVariables
>;
export const BasicActivityChallengeListDataDocument = gql`
    query basicActivityChallengeListData($canJoinOnly: Boolean!) {
        activityChallenges(canJoinOnly: $canJoinOnly) {
            ...BaseActivityChallenge
        }
    }
    ${BaseActivityChallengeFragmentDoc}
`;

/**
 * __useBasicActivityChallengeListDataQuery__
 *
 * To run a query within a React component, call `useBasicActivityChallengeListDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasicActivityChallengeListDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasicActivityChallengeListDataQuery({
 *   variables: {
 *      canJoinOnly: // value for 'canJoinOnly'
 *   },
 * });
 */
export function useBasicActivityChallengeListDataQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        BasicActivityChallengeListDataQuery,
        BasicActivityChallengeListDataQueryVariables
    > &
        (
            | { variables: BasicActivityChallengeListDataQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        BasicActivityChallengeListDataQuery,
        BasicActivityChallengeListDataQueryVariables
    >(BasicActivityChallengeListDataDocument, options);
}
export function useBasicActivityChallengeListDataLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BasicActivityChallengeListDataQuery,
        BasicActivityChallengeListDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        BasicActivityChallengeListDataQuery,
        BasicActivityChallengeListDataQueryVariables
    >(BasicActivityChallengeListDataDocument, options);
}
export function useBasicActivityChallengeListDataSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        BasicActivityChallengeListDataQuery,
        BasicActivityChallengeListDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        BasicActivityChallengeListDataQuery,
        BasicActivityChallengeListDataQueryVariables
    >(BasicActivityChallengeListDataDocument, options);
}
export type BasicActivityChallengeListDataQueryHookResult = ReturnType<
    typeof useBasicActivityChallengeListDataQuery
>;
export type BasicActivityChallengeListDataLazyQueryHookResult = ReturnType<
    typeof useBasicActivityChallengeListDataLazyQuery
>;
export type BasicActivityChallengeListDataSuspenseQueryHookResult = ReturnType<
    typeof useBasicActivityChallengeListDataSuspenseQuery
>;
export type BasicActivityChallengeListDataQueryResult = Apollo.QueryResult<
    BasicActivityChallengeListDataQuery,
    BasicActivityChallengeListDataQueryVariables
>;
export const SubmitStudentClassReviewDocument = gql`
    mutation submitStudentClassReview(
        $classId: String!
        $teacherRating: Float!
        $teacherFeedbackAnswers: [String!]!
        $teacherCustomFeedback: String!
        $techQualityRating: Float
        $techQualityFeedbackAnswers: [String!]
        $techQualityCustomFeedback: String
        $learningMaterialRating: Float!
        $learningMaterialFeedbackAnswers: [String!]!
        $learningMaterialCustomFeedback: String!
    ) {
        submitStudentClassReview(
            classId: $classId
            input: {
                teacherFeedbackInput: {
                    teacherRating: $teacherRating
                    teacherFeedbackAnswers: $teacherFeedbackAnswers
                    teacherCustomFeedback: $teacherCustomFeedback
                }
                technicalQualityInput: {
                    techQualityRating: $techQualityRating
                    techQualityFeedbackAnswers: $techQualityFeedbackAnswers
                    techQualityCustomFeedback: $techQualityCustomFeedback
                }
                learningMaterialFeedbackInput: {
                    learningMaterialRating: $learningMaterialRating
                    learningMaterialFeedbackAnswers: $learningMaterialFeedbackAnswers
                    learningMaterialCustomFeedback: $learningMaterialCustomFeedback
                }
            }
        ) {
            id
        }
    }
`;
export type SubmitStudentClassReviewMutationFn = Apollo.MutationFunction<
    SubmitStudentClassReviewMutation,
    SubmitStudentClassReviewMutationVariables
>;

/**
 * __useSubmitStudentClassReviewMutation__
 *
 * To run a mutation, you first call `useSubmitStudentClassReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitStudentClassReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitStudentClassReviewMutation, { data, loading, error }] = useSubmitStudentClassReviewMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      teacherRating: // value for 'teacherRating'
 *      teacherFeedbackAnswers: // value for 'teacherFeedbackAnswers'
 *      teacherCustomFeedback: // value for 'teacherCustomFeedback'
 *      techQualityRating: // value for 'techQualityRating'
 *      techQualityFeedbackAnswers: // value for 'techQualityFeedbackAnswers'
 *      techQualityCustomFeedback: // value for 'techQualityCustomFeedback'
 *      learningMaterialRating: // value for 'learningMaterialRating'
 *      learningMaterialFeedbackAnswers: // value for 'learningMaterialFeedbackAnswers'
 *      learningMaterialCustomFeedback: // value for 'learningMaterialCustomFeedback'
 *   },
 * });
 */
export function useSubmitStudentClassReviewMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SubmitStudentClassReviewMutation,
        SubmitStudentClassReviewMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SubmitStudentClassReviewMutation,
        SubmitStudentClassReviewMutationVariables
    >(SubmitStudentClassReviewDocument, options);
}
export type SubmitStudentClassReviewMutationHookResult = ReturnType<
    typeof useSubmitStudentClassReviewMutation
>;
export type SubmitStudentClassReviewMutationResult =
    Apollo.MutationResult<SubmitStudentClassReviewMutation>;
export type SubmitStudentClassReviewMutationOptions = Apollo.BaseMutationOptions<
    SubmitStudentClassReviewMutation,
    SubmitStudentClassReviewMutationVariables
>;
export const ModulePageHeaderDocument = gql`
    query modulePageHeader($sectionId: Int!, $moduleId: Int!, $curriculumId: Int!) {
        sectionCurricula {
            ...SectionCurriculum
        }
        studentModules(curriculumId: $curriculumId) {
            ...HeaderStudentModule
        }
        activeModule: studentModule(id: $moduleId) @client {
            ...HeaderStudentModule
        }
        canChangeLearningLanguage {
            isShown
            isEnabled
        }
    }
    ${SectionCurriculumFragmentDoc}
    ${HeaderStudentModuleFragmentDoc}
`;

/**
 * __useModulePageHeaderQuery__
 *
 * To run a query within a React component, call `useModulePageHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useModulePageHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModulePageHeaderQuery({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      moduleId: // value for 'moduleId'
 *      curriculumId: // value for 'curriculumId'
 *   },
 * });
 */
export function useModulePageHeaderQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ModulePageHeaderQuery,
        ModulePageHeaderQueryVariables
    > &
        ({ variables: ModulePageHeaderQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ModulePageHeaderQuery, ModulePageHeaderQueryVariables>(
        ModulePageHeaderDocument,
        options,
    );
}
export function useModulePageHeaderLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ModulePageHeaderQuery,
        ModulePageHeaderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ModulePageHeaderQuery, ModulePageHeaderQueryVariables>(
        ModulePageHeaderDocument,
        options,
    );
}
export function useModulePageHeaderSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ModulePageHeaderQuery,
        ModulePageHeaderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ModulePageHeaderQuery, ModulePageHeaderQueryVariables>(
        ModulePageHeaderDocument,
        options,
    );
}
export type ModulePageHeaderQueryHookResult = ReturnType<typeof useModulePageHeaderQuery>;
export type ModulePageHeaderLazyQueryHookResult = ReturnType<typeof useModulePageHeaderLazyQuery>;
export type ModulePageHeaderSuspenseQueryHookResult = ReturnType<
    typeof useModulePageHeaderSuspenseQuery
>;
export type ModulePageHeaderQueryResult = Apollo.QueryResult<
    ModulePageHeaderQuery,
    ModulePageHeaderQueryVariables
>;
export const ModuleSelectorOverlayDocument = gql`
    query moduleSelectorOverlay($sectionId: Int!, $curriculumId: Int!) {
        studentModules(curriculumId: $curriculumId) {
            ...HeaderStudentModule
        }
        sectionCurricula {
            ...SectionCurriculum
        }
    }
    ${HeaderStudentModuleFragmentDoc}
    ${SectionCurriculumFragmentDoc}
`;

/**
 * __useModuleSelectorOverlayQuery__
 *
 * To run a query within a React component, call `useModuleSelectorOverlayQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleSelectorOverlayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleSelectorOverlayQuery({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      curriculumId: // value for 'curriculumId'
 *   },
 * });
 */
export function useModuleSelectorOverlayQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ModuleSelectorOverlayQuery,
        ModuleSelectorOverlayQueryVariables
    > &
        ({ variables: ModuleSelectorOverlayQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        ModuleSelectorOverlayQuery,
        ModuleSelectorOverlayQueryVariables
    >(ModuleSelectorOverlayDocument, options);
}
export function useModuleSelectorOverlayLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ModuleSelectorOverlayQuery,
        ModuleSelectorOverlayQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ModuleSelectorOverlayQuery,
        ModuleSelectorOverlayQueryVariables
    >(ModuleSelectorOverlayDocument, options);
}
export function useModuleSelectorOverlaySuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ModuleSelectorOverlayQuery,
        ModuleSelectorOverlayQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ModuleSelectorOverlayQuery,
        ModuleSelectorOverlayQueryVariables
    >(ModuleSelectorOverlayDocument, options);
}
export type ModuleSelectorOverlayQueryHookResult = ReturnType<typeof useModuleSelectorOverlayQuery>;
export type ModuleSelectorOverlayLazyQueryHookResult = ReturnType<
    typeof useModuleSelectorOverlayLazyQuery
>;
export type ModuleSelectorOverlaySuspenseQueryHookResult = ReturnType<
    typeof useModuleSelectorOverlaySuspenseQuery
>;
export type ModuleSelectorOverlayQueryResult = Apollo.QueryResult<
    ModuleSelectorOverlayQuery,
    ModuleSelectorOverlayQueryVariables
>;
export const OrientationLessonDataDocument = gql`
    query orientationLessonData {
        orientationLesson {
            id
            studentStatus
            type
            label
            title
            units {
                chapter
            }
            learningOutcomes
            presentationUrl
            module {
                id
                name
                code
                longName
                title
                completedClasses
            }
        }
    }
`;

/**
 * __useOrientationLessonDataQuery__
 *
 * To run a query within a React component, call `useOrientationLessonDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrientationLessonDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrientationLessonDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrientationLessonDataQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        OrientationLessonDataQuery,
        OrientationLessonDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        OrientationLessonDataQuery,
        OrientationLessonDataQueryVariables
    >(OrientationLessonDataDocument, options);
}
export function useOrientationLessonDataLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        OrientationLessonDataQuery,
        OrientationLessonDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        OrientationLessonDataQuery,
        OrientationLessonDataQueryVariables
    >(OrientationLessonDataDocument, options);
}
export function useOrientationLessonDataSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        OrientationLessonDataQuery,
        OrientationLessonDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        OrientationLessonDataQuery,
        OrientationLessonDataQueryVariables
    >(OrientationLessonDataDocument, options);
}
export type OrientationLessonDataQueryHookResult = ReturnType<typeof useOrientationLessonDataQuery>;
export type OrientationLessonDataLazyQueryHookResult = ReturnType<
    typeof useOrientationLessonDataLazyQuery
>;
export type OrientationLessonDataSuspenseQueryHookResult = ReturnType<
    typeof useOrientationLessonDataSuspenseQuery
>;
export type OrientationLessonDataQueryResult = Apollo.QueryResult<
    OrientationLessonDataQuery,
    OrientationLessonDataQueryVariables
>;
export const StudentCreditsInfoDocument = gql`
    query studentCreditsInfo {
        studentCreditsInfo {
            groupCredits
            privateCredits
        }
    }
`;

/**
 * __useStudentCreditsInfoQuery__
 *
 * To run a query within a React component, call `useStudentCreditsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentCreditsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentCreditsInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentCreditsInfoQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        StudentCreditsInfoQuery,
        StudentCreditsInfoQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<StudentCreditsInfoQuery, StudentCreditsInfoQueryVariables>(
        StudentCreditsInfoDocument,
        options,
    );
}
export function useStudentCreditsInfoLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        StudentCreditsInfoQuery,
        StudentCreditsInfoQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<StudentCreditsInfoQuery, StudentCreditsInfoQueryVariables>(
        StudentCreditsInfoDocument,
        options,
    );
}
export function useStudentCreditsInfoSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        StudentCreditsInfoQuery,
        StudentCreditsInfoQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        StudentCreditsInfoQuery,
        StudentCreditsInfoQueryVariables
    >(StudentCreditsInfoDocument, options);
}
export type StudentCreditsInfoQueryHookResult = ReturnType<typeof useStudentCreditsInfoQuery>;
export type StudentCreditsInfoLazyQueryHookResult = ReturnType<
    typeof useStudentCreditsInfoLazyQuery
>;
export type StudentCreditsInfoSuspenseQueryHookResult = ReturnType<
    typeof useStudentCreditsInfoSuspenseQuery
>;
export type StudentCreditsInfoQueryResult = Apollo.QueryResult<
    StudentCreditsInfoQuery,
    StudentCreditsInfoQueryVariables
>;
export const BannerBookingWidgetDocument = gql`
    query bannerBookingWidget($bookingFilter: ProvideBookableClassSlotsInput!) {
        classes(bookingFilter: $bookingFilter) {
            uid
            isExisting
            startDate
            endDate
            type
            isForNewClassroom
        }
    }
`;

/**
 * __useBannerBookingWidgetQuery__
 *
 * To run a query within a React component, call `useBannerBookingWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useBannerBookingWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBannerBookingWidgetQuery({
 *   variables: {
 *      bookingFilter: // value for 'bookingFilter'
 *   },
 * });
 */
export function useBannerBookingWidgetQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        BannerBookingWidgetQuery,
        BannerBookingWidgetQueryVariables
    > &
        ({ variables: BannerBookingWidgetQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<BannerBookingWidgetQuery, BannerBookingWidgetQueryVariables>(
        BannerBookingWidgetDocument,
        options,
    );
}
export function useBannerBookingWidgetLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BannerBookingWidgetQuery,
        BannerBookingWidgetQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        BannerBookingWidgetQuery,
        BannerBookingWidgetQueryVariables
    >(BannerBookingWidgetDocument, options);
}
export function useBannerBookingWidgetSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        BannerBookingWidgetQuery,
        BannerBookingWidgetQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        BannerBookingWidgetQuery,
        BannerBookingWidgetQueryVariables
    >(BannerBookingWidgetDocument, options);
}
export type BannerBookingWidgetQueryHookResult = ReturnType<typeof useBannerBookingWidgetQuery>;
export type BannerBookingWidgetLazyQueryHookResult = ReturnType<
    typeof useBannerBookingWidgetLazyQuery
>;
export type BannerBookingWidgetSuspenseQueryHookResult = ReturnType<
    typeof useBannerBookingWidgetSuspenseQuery
>;
export type BannerBookingWidgetQueryResult = Apollo.QueryResult<
    BannerBookingWidgetQuery,
    BannerBookingWidgetQueryVariables
>;
export const LastPublishedLearningTeamCourseDocument = gql`
    query lastPublishedLearningTeamCourse {
        lastPublishedLearningTeamCourse {
            id
        }
    }
`;

/**
 * __useLastPublishedLearningTeamCourseQuery__
 *
 * To run a query within a React component, call `useLastPublishedLearningTeamCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastPublishedLearningTeamCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastPublishedLearningTeamCourseQuery({
 *   variables: {
 *   },
 * });
 */
export function useLastPublishedLearningTeamCourseQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        LastPublishedLearningTeamCourseQuery,
        LastPublishedLearningTeamCourseQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        LastPublishedLearningTeamCourseQuery,
        LastPublishedLearningTeamCourseQueryVariables
    >(LastPublishedLearningTeamCourseDocument, options);
}
export function useLastPublishedLearningTeamCourseLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        LastPublishedLearningTeamCourseQuery,
        LastPublishedLearningTeamCourseQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        LastPublishedLearningTeamCourseQuery,
        LastPublishedLearningTeamCourseQueryVariables
    >(LastPublishedLearningTeamCourseDocument, options);
}
export function useLastPublishedLearningTeamCourseSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        LastPublishedLearningTeamCourseQuery,
        LastPublishedLearningTeamCourseQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        LastPublishedLearningTeamCourseQuery,
        LastPublishedLearningTeamCourseQueryVariables
    >(LastPublishedLearningTeamCourseDocument, options);
}
export type LastPublishedLearningTeamCourseQueryHookResult = ReturnType<
    typeof useLastPublishedLearningTeamCourseQuery
>;
export type LastPublishedLearningTeamCourseLazyQueryHookResult = ReturnType<
    typeof useLastPublishedLearningTeamCourseLazyQuery
>;
export type LastPublishedLearningTeamCourseSuspenseQueryHookResult = ReturnType<
    typeof useLastPublishedLearningTeamCourseSuspenseQuery
>;
export type LastPublishedLearningTeamCourseQueryResult = Apollo.QueryResult<
    LastPublishedLearningTeamCourseQuery,
    LastPublishedLearningTeamCourseQueryVariables
>;
export const TodayPageLingobitesDocument = gql`
    query todayPageLingobites($limit: Int) {
        recommendedLessonsForPracticeSequenceQuiz(limit: $limit) {
            id
            title
            studentLearningUnit {
                name
            }
            studentStatus
            practiceSequenceScore
        }
    }
`;

/**
 * __useTodayPageLingobitesQuery__
 *
 * To run a query within a React component, call `useTodayPageLingobitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodayPageLingobitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodayPageLingobitesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTodayPageLingobitesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TodayPageLingobitesQuery,
        TodayPageLingobitesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TodayPageLingobitesQuery, TodayPageLingobitesQueryVariables>(
        TodayPageLingobitesDocument,
        options,
    );
}
export function useTodayPageLingobitesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TodayPageLingobitesQuery,
        TodayPageLingobitesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        TodayPageLingobitesQuery,
        TodayPageLingobitesQueryVariables
    >(TodayPageLingobitesDocument, options);
}
export function useTodayPageLingobitesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TodayPageLingobitesQuery,
        TodayPageLingobitesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TodayPageLingobitesQuery,
        TodayPageLingobitesQueryVariables
    >(TodayPageLingobitesDocument, options);
}
export type TodayPageLingobitesQueryHookResult = ReturnType<typeof useTodayPageLingobitesQuery>;
export type TodayPageLingobitesLazyQueryHookResult = ReturnType<
    typeof useTodayPageLingobitesLazyQuery
>;
export type TodayPageLingobitesSuspenseQueryHookResult = ReturnType<
    typeof useTodayPageLingobitesSuspenseQuery
>;
export type TodayPageLingobitesQueryResult = Apollo.QueryResult<
    TodayPageLingobitesQuery,
    TodayPageLingobitesQueryVariables
>;
export const PracticeRecommenderDocument = gql`
    query PracticeRecommender {
        recommendedActivities {
            type
            completed
            quizProgress {
                lastReviewed
                periodProgress {
                    reviewed
                }
            }
            lesson {
                id
                title
                practiceSequenceScore
                vocabularyItems {
                    id
                    isCompleted
                }
            }
        }
    }
`;

/**
 * __usePracticeRecommenderQuery__
 *
 * To run a query within a React component, call `usePracticeRecommenderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticeRecommenderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticeRecommenderQuery({
 *   variables: {
 *   },
 * });
 */
export function usePracticeRecommenderQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PracticeRecommenderQuery,
        PracticeRecommenderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PracticeRecommenderQuery, PracticeRecommenderQueryVariables>(
        PracticeRecommenderDocument,
        options,
    );
}
export function usePracticeRecommenderLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PracticeRecommenderQuery,
        PracticeRecommenderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PracticeRecommenderQuery,
        PracticeRecommenderQueryVariables
    >(PracticeRecommenderDocument, options);
}
export function usePracticeRecommenderSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        PracticeRecommenderQuery,
        PracticeRecommenderQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        PracticeRecommenderQuery,
        PracticeRecommenderQueryVariables
    >(PracticeRecommenderDocument, options);
}
export type PracticeRecommenderQueryHookResult = ReturnType<typeof usePracticeRecommenderQuery>;
export type PracticeRecommenderLazyQueryHookResult = ReturnType<
    typeof usePracticeRecommenderLazyQuery
>;
export type PracticeRecommenderSuspenseQueryHookResult = ReturnType<
    typeof usePracticeRecommenderSuspenseQuery
>;
export type PracticeRecommenderQueryResult = Apollo.QueryResult<
    PracticeRecommenderQuery,
    PracticeRecommenderQueryVariables
>;
export const QuizProgressTodayPageDocument = gql`
    query quizProgressTodayPage($from: String, $to: String) {
        quizEngineProgress(from: $from, to: $to) {
            uncompleted
            lastReviewed
            periodProgress {
                reviewed
            }
        }
    }
`;

/**
 * __useQuizProgressTodayPageQuery__
 *
 * To run a query within a React component, call `useQuizProgressTodayPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuizProgressTodayPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizProgressTodayPageQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useQuizProgressTodayPageQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        QuizProgressTodayPageQuery,
        QuizProgressTodayPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        QuizProgressTodayPageQuery,
        QuizProgressTodayPageQueryVariables
    >(QuizProgressTodayPageDocument, options);
}
export function useQuizProgressTodayPageLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        QuizProgressTodayPageQuery,
        QuizProgressTodayPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        QuizProgressTodayPageQuery,
        QuizProgressTodayPageQueryVariables
    >(QuizProgressTodayPageDocument, options);
}
export function useQuizProgressTodayPageSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        QuizProgressTodayPageQuery,
        QuizProgressTodayPageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        QuizProgressTodayPageQuery,
        QuizProgressTodayPageQueryVariables
    >(QuizProgressTodayPageDocument, options);
}
export type QuizProgressTodayPageQueryHookResult = ReturnType<typeof useQuizProgressTodayPageQuery>;
export type QuizProgressTodayPageLazyQueryHookResult = ReturnType<
    typeof useQuizProgressTodayPageLazyQuery
>;
export type QuizProgressTodayPageSuspenseQueryHookResult = ReturnType<
    typeof useQuizProgressTodayPageSuspenseQuery
>;
export type QuizProgressTodayPageQueryResult = Apollo.QueryResult<
    QuizProgressTodayPageQuery,
    QuizProgressTodayPageQueryVariables
>;
export const StudentLastTwoWeeksClassesPendingReviewDocument = gql`
    query studentLastTwoWeeksClassesPendingReview {
        studentLastTwoWeeksClassesPendingReview {
            ...ReviewClassCardAppointment
        }
    }
    ${ReviewClassCardAppointmentFragmentDoc}
`;

/**
 * __useStudentLastTwoWeeksClassesPendingReviewQuery__
 *
 * To run a query within a React component, call `useStudentLastTwoWeeksClassesPendingReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentLastTwoWeeksClassesPendingReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentLastTwoWeeksClassesPendingReviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentLastTwoWeeksClassesPendingReviewQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        StudentLastTwoWeeksClassesPendingReviewQuery,
        StudentLastTwoWeeksClassesPendingReviewQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        StudentLastTwoWeeksClassesPendingReviewQuery,
        StudentLastTwoWeeksClassesPendingReviewQueryVariables
    >(StudentLastTwoWeeksClassesPendingReviewDocument, options);
}
export function useStudentLastTwoWeeksClassesPendingReviewLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        StudentLastTwoWeeksClassesPendingReviewQuery,
        StudentLastTwoWeeksClassesPendingReviewQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        StudentLastTwoWeeksClassesPendingReviewQuery,
        StudentLastTwoWeeksClassesPendingReviewQueryVariables
    >(StudentLastTwoWeeksClassesPendingReviewDocument, options);
}
export function useStudentLastTwoWeeksClassesPendingReviewSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        StudentLastTwoWeeksClassesPendingReviewQuery,
        StudentLastTwoWeeksClassesPendingReviewQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        StudentLastTwoWeeksClassesPendingReviewQuery,
        StudentLastTwoWeeksClassesPendingReviewQueryVariables
    >(StudentLastTwoWeeksClassesPendingReviewDocument, options);
}
export type StudentLastTwoWeeksClassesPendingReviewQueryHookResult = ReturnType<
    typeof useStudentLastTwoWeeksClassesPendingReviewQuery
>;
export type StudentLastTwoWeeksClassesPendingReviewLazyQueryHookResult = ReturnType<
    typeof useStudentLastTwoWeeksClassesPendingReviewLazyQuery
>;
export type StudentLastTwoWeeksClassesPendingReviewSuspenseQueryHookResult = ReturnType<
    typeof useStudentLastTwoWeeksClassesPendingReviewSuspenseQuery
>;
export type StudentLastTwoWeeksClassesPendingReviewQueryResult = Apollo.QueryResult<
    StudentLastTwoWeeksClassesPendingReviewQuery,
    StudentLastTwoWeeksClassesPendingReviewQueryVariables
>;
export const AppointmentsListIdsDocument = gql`
    query appointmentsListIds(
        $limit: Int
        $fromDate: DateTime
        $toDate: DateTime
        $appointmentStatus: String
    ) {
        appointmentsList(
            limit: $limit
            fromDate: $fromDate
            toDate: $toDate
            appointmentStatus: $appointmentStatus
        ) {
            id
        }
    }
`;

/**
 * __useAppointmentsListIdsQuery__
 *
 * To run a query within a React component, call `useAppointmentsListIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsListIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsListIdsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      appointmentStatus: // value for 'appointmentStatus'
 *   },
 * });
 */
export function useAppointmentsListIdsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        AppointmentsListIdsQuery,
        AppointmentsListIdsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<AppointmentsListIdsQuery, AppointmentsListIdsQueryVariables>(
        AppointmentsListIdsDocument,
        options,
    );
}
export function useAppointmentsListIdsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        AppointmentsListIdsQuery,
        AppointmentsListIdsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        AppointmentsListIdsQuery,
        AppointmentsListIdsQueryVariables
    >(AppointmentsListIdsDocument, options);
}
export function useAppointmentsListIdsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        AppointmentsListIdsQuery,
        AppointmentsListIdsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        AppointmentsListIdsQuery,
        AppointmentsListIdsQueryVariables
    >(AppointmentsListIdsDocument, options);
}
export type AppointmentsListIdsQueryHookResult = ReturnType<typeof useAppointmentsListIdsQuery>;
export type AppointmentsListIdsLazyQueryHookResult = ReturnType<
    typeof useAppointmentsListIdsLazyQuery
>;
export type AppointmentsListIdsSuspenseQueryHookResult = ReturnType<
    typeof useAppointmentsListIdsSuspenseQuery
>;
export type AppointmentsListIdsQueryResult = Apollo.QueryResult<
    AppointmentsListIdsQuery,
    AppointmentsListIdsQueryVariables
>;
export const UpcomingAppointmentsDocument = gql`
    query upcomingAppointments(
        $listLimit: Int!
        $listOffset: Int
        $listFromDate: DateTime!
        $listToDate: DateTime!
        $countFromDate: DateTime!
        $classroomCountFromDate: DateTime!
        $listSort: String
        $appointmentStatus: String!
    ) {
        upcomingAppointmentsCount(fromDate: $countFromDate, appointmentStatus: $appointmentStatus)
        upcomingClassroomAppointmentsCount: upcomingAppointmentsCount(
            fromDate: $classroomCountFromDate
            appointmentStatus: $appointmentStatus
            classProvider: lingoda_classroom
        )
        appointmentsList(
            limit: $listLimit
            offset: $listOffset
            fromDate: $listFromDate
            toDate: $listToDate
            sort: $listSort
            appointmentStatus: $appointmentStatus
        ) {
            ...UpcomingAppointmentCard
        }
        student {
            id
            hasBookedClass
        }
    }
    ${UpcomingAppointmentCardFragmentDoc}
`;

/**
 * __useUpcomingAppointmentsQuery__
 *
 * To run a query within a React component, call `useUpcomingAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingAppointmentsQuery({
 *   variables: {
 *      listLimit: // value for 'listLimit'
 *      listOffset: // value for 'listOffset'
 *      listFromDate: // value for 'listFromDate'
 *      listToDate: // value for 'listToDate'
 *      countFromDate: // value for 'countFromDate'
 *      classroomCountFromDate: // value for 'classroomCountFromDate'
 *      listSort: // value for 'listSort'
 *      appointmentStatus: // value for 'appointmentStatus'
 *   },
 * });
 */
export function useUpcomingAppointmentsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        UpcomingAppointmentsQuery,
        UpcomingAppointmentsQueryVariables
    > &
        ({ variables: UpcomingAppointmentsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<UpcomingAppointmentsQuery, UpcomingAppointmentsQueryVariables>(
        UpcomingAppointmentsDocument,
        options,
    );
}
export function useUpcomingAppointmentsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        UpcomingAppointmentsQuery,
        UpcomingAppointmentsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        UpcomingAppointmentsQuery,
        UpcomingAppointmentsQueryVariables
    >(UpcomingAppointmentsDocument, options);
}
export function useUpcomingAppointmentsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        UpcomingAppointmentsQuery,
        UpcomingAppointmentsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        UpcomingAppointmentsQuery,
        UpcomingAppointmentsQueryVariables
    >(UpcomingAppointmentsDocument, options);
}
export type UpcomingAppointmentsQueryHookResult = ReturnType<typeof useUpcomingAppointmentsQuery>;
export type UpcomingAppointmentsLazyQueryHookResult = ReturnType<
    typeof useUpcomingAppointmentsLazyQuery
>;
export type UpcomingAppointmentsSuspenseQueryHookResult = ReturnType<
    typeof useUpcomingAppointmentsSuspenseQuery
>;
export type UpcomingAppointmentsQueryResult = Apollo.QueryResult<
    UpcomingAppointmentsQuery,
    UpcomingAppointmentsQueryVariables
>;
export const StudentPastAppointmentsCountDocument = gql`
    query studentPastAppointmentsCount($pastToDate: DateTime) {
        pastAppointmentsCount(toDate: $pastToDate)
    }
`;

/**
 * __useStudentPastAppointmentsCountQuery__
 *
 * To run a query within a React component, call `useStudentPastAppointmentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentPastAppointmentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentPastAppointmentsCountQuery({
 *   variables: {
 *      pastToDate: // value for 'pastToDate'
 *   },
 * });
 */
export function useStudentPastAppointmentsCountQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        StudentPastAppointmentsCountQuery,
        StudentPastAppointmentsCountQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        StudentPastAppointmentsCountQuery,
        StudentPastAppointmentsCountQueryVariables
    >(StudentPastAppointmentsCountDocument, options);
}
export function useStudentPastAppointmentsCountLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        StudentPastAppointmentsCountQuery,
        StudentPastAppointmentsCountQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        StudentPastAppointmentsCountQuery,
        StudentPastAppointmentsCountQueryVariables
    >(StudentPastAppointmentsCountDocument, options);
}
export function useStudentPastAppointmentsCountSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        StudentPastAppointmentsCountQuery,
        StudentPastAppointmentsCountQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        StudentPastAppointmentsCountQuery,
        StudentPastAppointmentsCountQueryVariables
    >(StudentPastAppointmentsCountDocument, options);
}
export type StudentPastAppointmentsCountQueryHookResult = ReturnType<
    typeof useStudentPastAppointmentsCountQuery
>;
export type StudentPastAppointmentsCountLazyQueryHookResult = ReturnType<
    typeof useStudentPastAppointmentsCountLazyQuery
>;
export type StudentPastAppointmentsCountSuspenseQueryHookResult = ReturnType<
    typeof useStudentPastAppointmentsCountSuspenseQuery
>;
export type StudentPastAppointmentsCountQueryResult = Apollo.QueryResult<
    StudentPastAppointmentsCountQuery,
    StudentPastAppointmentsCountQueryVariables
>;
export const YourTeamWidgetDocument = gql`
    query yourTeamWidget {
        studentLearningTeamInfo {
            ...YourTeamWidgetData
        }
    }
    ${YourTeamWidgetDataFragmentDoc}
`;

/**
 * __useYourTeamWidgetQuery__
 *
 * To run a query within a React component, call `useYourTeamWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useYourTeamWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYourTeamWidgetQuery({
 *   variables: {
 *   },
 * });
 */
export function useYourTeamWidgetQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        YourTeamWidgetQuery,
        YourTeamWidgetQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<YourTeamWidgetQuery, YourTeamWidgetQueryVariables>(
        YourTeamWidgetDocument,
        options,
    );
}
export function useYourTeamWidgetLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        YourTeamWidgetQuery,
        YourTeamWidgetQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<YourTeamWidgetQuery, YourTeamWidgetQueryVariables>(
        YourTeamWidgetDocument,
        options,
    );
}
export function useYourTeamWidgetSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        YourTeamWidgetQuery,
        YourTeamWidgetQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<YourTeamWidgetQuery, YourTeamWidgetQueryVariables>(
        YourTeamWidgetDocument,
        options,
    );
}
export type YourTeamWidgetQueryHookResult = ReturnType<typeof useYourTeamWidgetQuery>;
export type YourTeamWidgetLazyQueryHookResult = ReturnType<typeof useYourTeamWidgetLazyQuery>;
export type YourTeamWidgetSuspenseQueryHookResult = ReturnType<
    typeof useYourTeamWidgetSuspenseQuery
>;
export type YourTeamWidgetQueryResult = Apollo.QueryResult<
    YourTeamWidgetQuery,
    YourTeamWidgetQueryVariables
>;
export const AppointmentsListDocument = gql`
    query appointmentsList(
        $limit: Int
        $offset: Int
        $fromDate: DateTime
        $toDate: DateTime
        $sort: String
    ) {
        appointmentsList(
            limit: $limit
            offset: $offset
            fromDate: $fromDate
            toDate: $toDate
            sort: $sort
        ) {
            ...ClassCardAppointment
        }
    }
    ${ClassCardAppointmentFragmentDoc}
`;

/**
 * __useAppointmentsListQuery__
 *
 * To run a query within a React component, call `useAppointmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAppointmentsListQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        AppointmentsListQuery,
        AppointmentsListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<AppointmentsListQuery, AppointmentsListQueryVariables>(
        AppointmentsListDocument,
        options,
    );
}
export function useAppointmentsListLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        AppointmentsListQuery,
        AppointmentsListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<AppointmentsListQuery, AppointmentsListQueryVariables>(
        AppointmentsListDocument,
        options,
    );
}
export function useAppointmentsListSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        AppointmentsListQuery,
        AppointmentsListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<AppointmentsListQuery, AppointmentsListQueryVariables>(
        AppointmentsListDocument,
        options,
    );
}
export type AppointmentsListQueryHookResult = ReturnType<typeof useAppointmentsListQuery>;
export type AppointmentsListLazyQueryHookResult = ReturnType<typeof useAppointmentsListLazyQuery>;
export type AppointmentsListSuspenseQueryHookResult = ReturnType<
    typeof useAppointmentsListSuspenseQuery
>;
export type AppointmentsListQueryResult = Apollo.QueryResult<
    AppointmentsListQuery,
    AppointmentsListQueryVariables
>;
export const TotalCountsMyClassesDocument = gql`
    query totalCountsMyClasses($upcomingFromDate: DateTime, $pastToDate: DateTime) {
        upcomingAppointmentsCount(fromDate: $upcomingFromDate)
        pastAppointmentsCount(toDate: $pastToDate)
    }
`;

/**
 * __useTotalCountsMyClassesQuery__
 *
 * To run a query within a React component, call `useTotalCountsMyClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalCountsMyClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalCountsMyClassesQuery({
 *   variables: {
 *      upcomingFromDate: // value for 'upcomingFromDate'
 *      pastToDate: // value for 'pastToDate'
 *   },
 * });
 */
export function useTotalCountsMyClassesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TotalCountsMyClassesQuery,
        TotalCountsMyClassesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TotalCountsMyClassesQuery, TotalCountsMyClassesQueryVariables>(
        TotalCountsMyClassesDocument,
        options,
    );
}
export function useTotalCountsMyClassesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TotalCountsMyClassesQuery,
        TotalCountsMyClassesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        TotalCountsMyClassesQuery,
        TotalCountsMyClassesQueryVariables
    >(TotalCountsMyClassesDocument, options);
}
export function useTotalCountsMyClassesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TotalCountsMyClassesQuery,
        TotalCountsMyClassesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TotalCountsMyClassesQuery,
        TotalCountsMyClassesQueryVariables
    >(TotalCountsMyClassesDocument, options);
}
export type TotalCountsMyClassesQueryHookResult = ReturnType<typeof useTotalCountsMyClassesQuery>;
export type TotalCountsMyClassesLazyQueryHookResult = ReturnType<
    typeof useTotalCountsMyClassesLazyQuery
>;
export type TotalCountsMyClassesSuspenseQueryHookResult = ReturnType<
    typeof useTotalCountsMyClassesSuspenseQuery
>;
export type TotalCountsMyClassesQueryResult = Apollo.QueryResult<
    TotalCountsMyClassesQuery,
    TotalCountsMyClassesQueryVariables
>;
export const AppointmentDatesDocument = gql`
    query appointmentDates($fromDate: DateTime!, $toDate: DateTime!, $sort: String = "ASC") {
        appointmentDates(fromDate: $fromDate, toDate: $toDate, sort: $sort)
    }
`;

/**
 * __useAppointmentDatesQuery__
 *
 * To run a query within a React component, call `useAppointmentDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentDatesQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAppointmentDatesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        AppointmentDatesQuery,
        AppointmentDatesQueryVariables
    > &
        ({ variables: AppointmentDatesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<AppointmentDatesQuery, AppointmentDatesQueryVariables>(
        AppointmentDatesDocument,
        options,
    );
}
export function useAppointmentDatesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        AppointmentDatesQuery,
        AppointmentDatesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<AppointmentDatesQuery, AppointmentDatesQueryVariables>(
        AppointmentDatesDocument,
        options,
    );
}
export function useAppointmentDatesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        AppointmentDatesQuery,
        AppointmentDatesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<AppointmentDatesQuery, AppointmentDatesQueryVariables>(
        AppointmentDatesDocument,
        options,
    );
}
export type AppointmentDatesQueryHookResult = ReturnType<typeof useAppointmentDatesQuery>;
export type AppointmentDatesLazyQueryHookResult = ReturnType<typeof useAppointmentDatesLazyQuery>;
export type AppointmentDatesSuspenseQueryHookResult = ReturnType<
    typeof useAppointmentDatesSuspenseQuery
>;
export type AppointmentDatesQueryResult = Apollo.QueryResult<
    AppointmentDatesQuery,
    AppointmentDatesQueryVariables
>;
export const HeaderAvatarDocument = gql`
    query headerAvatar {
        currentUser {
            id
            firstname
            photo {
                id
                urls {
                    thumbnail_large
                }
            }
        }
    }
`;

/**
 * __useHeaderAvatarQuery__
 *
 * To run a query within a React component, call `useHeaderAvatarQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeaderAvatarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeaderAvatarQuery({
 *   variables: {
 *   },
 * });
 */
export function useHeaderAvatarQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<HeaderAvatarQuery, HeaderAvatarQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<HeaderAvatarQuery, HeaderAvatarQueryVariables>(
        HeaderAvatarDocument,
        options,
    );
}
export function useHeaderAvatarLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        HeaderAvatarQuery,
        HeaderAvatarQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<HeaderAvatarQuery, HeaderAvatarQueryVariables>(
        HeaderAvatarDocument,
        options,
    );
}
export function useHeaderAvatarSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        HeaderAvatarQuery,
        HeaderAvatarQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<HeaderAvatarQuery, HeaderAvatarQueryVariables>(
        HeaderAvatarDocument,
        options,
    );
}
export type HeaderAvatarQueryHookResult = ReturnType<typeof useHeaderAvatarQuery>;
export type HeaderAvatarLazyQueryHookResult = ReturnType<typeof useHeaderAvatarLazyQuery>;
export type HeaderAvatarSuspenseQueryHookResult = ReturnType<typeof useHeaderAvatarSuspenseQuery>;
export type HeaderAvatarQueryResult = Apollo.QueryResult<
    HeaderAvatarQuery,
    HeaderAvatarQueryVariables
>;
export const AnonymousOrientationGroupGetStartedClassesDocument = gql`
    query anonymousOrientationGroupGetStartedClasses(
        $sectionName: SectionName!
        $moduleId: Int!
        $curriculumId: Int!
        $timezone: String!
    ) {
        anonymousOrientationGroupClasses(
            sectionName: $sectionName
            moduleId: $moduleId
            curriculumId: $curriculumId
            timezone: $timezone
        ) {
            uid
            startDate
            lesson {
                id
            }
        }
    }
`;

/**
 * __useAnonymousOrientationGroupGetStartedClassesQuery__
 *
 * To run a query within a React component, call `useAnonymousOrientationGroupGetStartedClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnonymousOrientationGroupGetStartedClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnonymousOrientationGroupGetStartedClassesQuery({
 *   variables: {
 *      sectionName: // value for 'sectionName'
 *      moduleId: // value for 'moduleId'
 *      curriculumId: // value for 'curriculumId'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useAnonymousOrientationGroupGetStartedClassesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        AnonymousOrientationGroupGetStartedClassesQuery,
        AnonymousOrientationGroupGetStartedClassesQueryVariables
    > &
        (
            | {
                  variables: AnonymousOrientationGroupGetStartedClassesQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        AnonymousOrientationGroupGetStartedClassesQuery,
        AnonymousOrientationGroupGetStartedClassesQueryVariables
    >(AnonymousOrientationGroupGetStartedClassesDocument, options);
}
export function useAnonymousOrientationGroupGetStartedClassesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        AnonymousOrientationGroupGetStartedClassesQuery,
        AnonymousOrientationGroupGetStartedClassesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        AnonymousOrientationGroupGetStartedClassesQuery,
        AnonymousOrientationGroupGetStartedClassesQueryVariables
    >(AnonymousOrientationGroupGetStartedClassesDocument, options);
}
export function useAnonymousOrientationGroupGetStartedClassesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        AnonymousOrientationGroupGetStartedClassesQuery,
        AnonymousOrientationGroupGetStartedClassesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        AnonymousOrientationGroupGetStartedClassesQuery,
        AnonymousOrientationGroupGetStartedClassesQueryVariables
    >(AnonymousOrientationGroupGetStartedClassesDocument, options);
}
export type AnonymousOrientationGroupGetStartedClassesQueryHookResult = ReturnType<
    typeof useAnonymousOrientationGroupGetStartedClassesQuery
>;
export type AnonymousOrientationGroupGetStartedClassesLazyQueryHookResult = ReturnType<
    typeof useAnonymousOrientationGroupGetStartedClassesLazyQuery
>;
export type AnonymousOrientationGroupGetStartedClassesSuspenseQueryHookResult = ReturnType<
    typeof useAnonymousOrientationGroupGetStartedClassesSuspenseQuery
>;
export type AnonymousOrientationGroupGetStartedClassesQueryResult = Apollo.QueryResult<
    AnonymousOrientationGroupGetStartedClassesQuery,
    AnonymousOrientationGroupGetStartedClassesQueryVariables
>;
export const AnonymousOrientationIndividualGetStartedClassesDocument = gql`
    query anonymousOrientationIndividualGetStartedClasses(
        $sectionName: SectionName!
        $moduleId: Int!
        $curriculumId: Int!
        $timezone: String!
    ) {
        anonymousOrientationIndividualClasses(
            sectionName: $sectionName
            moduleId: $moduleId
            curriculumId: $curriculumId
            timezone: $timezone
        ) {
            uid
            startDate
            lesson {
                id
            }
        }
    }
`;

/**
 * __useAnonymousOrientationIndividualGetStartedClassesQuery__
 *
 * To run a query within a React component, call `useAnonymousOrientationIndividualGetStartedClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnonymousOrientationIndividualGetStartedClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnonymousOrientationIndividualGetStartedClassesQuery({
 *   variables: {
 *      sectionName: // value for 'sectionName'
 *      moduleId: // value for 'moduleId'
 *      curriculumId: // value for 'curriculumId'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useAnonymousOrientationIndividualGetStartedClassesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        AnonymousOrientationIndividualGetStartedClassesQuery,
        AnonymousOrientationIndividualGetStartedClassesQueryVariables
    > &
        (
            | {
                  variables: AnonymousOrientationIndividualGetStartedClassesQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        AnonymousOrientationIndividualGetStartedClassesQuery,
        AnonymousOrientationIndividualGetStartedClassesQueryVariables
    >(AnonymousOrientationIndividualGetStartedClassesDocument, options);
}
export function useAnonymousOrientationIndividualGetStartedClassesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        AnonymousOrientationIndividualGetStartedClassesQuery,
        AnonymousOrientationIndividualGetStartedClassesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        AnonymousOrientationIndividualGetStartedClassesQuery,
        AnonymousOrientationIndividualGetStartedClassesQueryVariables
    >(AnonymousOrientationIndividualGetStartedClassesDocument, options);
}
export function useAnonymousOrientationIndividualGetStartedClassesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        AnonymousOrientationIndividualGetStartedClassesQuery,
        AnonymousOrientationIndividualGetStartedClassesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        AnonymousOrientationIndividualGetStartedClassesQuery,
        AnonymousOrientationIndividualGetStartedClassesQueryVariables
    >(AnonymousOrientationIndividualGetStartedClassesDocument, options);
}
export type AnonymousOrientationIndividualGetStartedClassesQueryHookResult = ReturnType<
    typeof useAnonymousOrientationIndividualGetStartedClassesQuery
>;
export type AnonymousOrientationIndividualGetStartedClassesLazyQueryHookResult = ReturnType<
    typeof useAnonymousOrientationIndividualGetStartedClassesLazyQuery
>;
export type AnonymousOrientationIndividualGetStartedClassesSuspenseQueryHookResult = ReturnType<
    typeof useAnonymousOrientationIndividualGetStartedClassesSuspenseQuery
>;
export type AnonymousOrientationIndividualGetStartedClassesQueryResult = Apollo.QueryResult<
    AnonymousOrientationIndividualGetStartedClassesQuery,
    AnonymousOrientationIndividualGetStartedClassesQueryVariables
>;
export const GetStartedModulesDocument = gql`
    query getStartedModules($sectionName: SectionName!, $curriculumId: Int!) {
        startingModules(sectionName: $sectionName, curriculumId: $curriculumId) {
            id
            code
            title
            description
            modules {
                id
                name
                code
            }
        }
    }
`;

/**
 * __useGetStartedModulesQuery__
 *
 * To run a query within a React component, call `useGetStartedModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStartedModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStartedModulesQuery({
 *   variables: {
 *      sectionName: // value for 'sectionName'
 *      curriculumId: // value for 'curriculumId'
 *   },
 * });
 */
export function useGetStartedModulesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetStartedModulesQuery,
        GetStartedModulesQueryVariables
    > &
        ({ variables: GetStartedModulesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetStartedModulesQuery, GetStartedModulesQueryVariables>(
        GetStartedModulesDocument,
        options,
    );
}
export function useGetStartedModulesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetStartedModulesQuery,
        GetStartedModulesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetStartedModulesQuery, GetStartedModulesQueryVariables>(
        GetStartedModulesDocument,
        options,
    );
}
export function useGetStartedModulesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        GetStartedModulesQuery,
        GetStartedModulesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetStartedModulesQuery,
        GetStartedModulesQueryVariables
    >(GetStartedModulesDocument, options);
}
export type GetStartedModulesQueryHookResult = ReturnType<typeof useGetStartedModulesQuery>;
export type GetStartedModulesLazyQueryHookResult = ReturnType<typeof useGetStartedModulesLazyQuery>;
export type GetStartedModulesSuspenseQueryHookResult = ReturnType<
    typeof useGetStartedModulesSuspenseQuery
>;
export type GetStartedModulesQueryResult = Apollo.QueryResult<
    GetStartedModulesQuery,
    GetStartedModulesQueryVariables
>;
export const MarkOnboardingSurveyAsAnsweredDocument = gql`
    mutation markOnboardingSurveyAsAnswered {
        submitOnboardingSurvey(isCompletedOnboardingSurvey: true)
    }
`;
export type MarkOnboardingSurveyAsAnsweredMutationFn = Apollo.MutationFunction<
    MarkOnboardingSurveyAsAnsweredMutation,
    MarkOnboardingSurveyAsAnsweredMutationVariables
>;

/**
 * __useMarkOnboardingSurveyAsAnsweredMutation__
 *
 * To run a mutation, you first call `useMarkOnboardingSurveyAsAnsweredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkOnboardingSurveyAsAnsweredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markOnboardingSurveyAsAnsweredMutation, { data, loading, error }] = useMarkOnboardingSurveyAsAnsweredMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkOnboardingSurveyAsAnsweredMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        MarkOnboardingSurveyAsAnsweredMutation,
        MarkOnboardingSurveyAsAnsweredMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        MarkOnboardingSurveyAsAnsweredMutation,
        MarkOnboardingSurveyAsAnsweredMutationVariables
    >(MarkOnboardingSurveyAsAnsweredDocument, options);
}
export type MarkOnboardingSurveyAsAnsweredMutationHookResult = ReturnType<
    typeof useMarkOnboardingSurveyAsAnsweredMutation
>;
export type MarkOnboardingSurveyAsAnsweredMutationResult =
    Apollo.MutationResult<MarkOnboardingSurveyAsAnsweredMutation>;
export type MarkOnboardingSurveyAsAnsweredMutationOptions = Apollo.BaseMutationOptions<
    MarkOnboardingSurveyAsAnsweredMutation,
    MarkOnboardingSurveyAsAnsweredMutationVariables
>;
export const OnboardingOrientationLessonDocument = gql`
    query onboardingOrientationLesson {
        orientationLesson {
            id
            learningOutcomes
            title
        }
    }
`;

/**
 * __useOnboardingOrientationLessonQuery__
 *
 * To run a query within a React component, call `useOnboardingOrientationLessonQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingOrientationLessonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingOrientationLessonQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingOrientationLessonQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        OnboardingOrientationLessonQuery,
        OnboardingOrientationLessonQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        OnboardingOrientationLessonQuery,
        OnboardingOrientationLessonQueryVariables
    >(OnboardingOrientationLessonDocument, options);
}
export function useOnboardingOrientationLessonLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        OnboardingOrientationLessonQuery,
        OnboardingOrientationLessonQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        OnboardingOrientationLessonQuery,
        OnboardingOrientationLessonQueryVariables
    >(OnboardingOrientationLessonDocument, options);
}
export function useOnboardingOrientationLessonSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        OnboardingOrientationLessonQuery,
        OnboardingOrientationLessonQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        OnboardingOrientationLessonQuery,
        OnboardingOrientationLessonQueryVariables
    >(OnboardingOrientationLessonDocument, options);
}
export type OnboardingOrientationLessonQueryHookResult = ReturnType<
    typeof useOnboardingOrientationLessonQuery
>;
export type OnboardingOrientationLessonLazyQueryHookResult = ReturnType<
    typeof useOnboardingOrientationLessonLazyQuery
>;
export type OnboardingOrientationLessonSuspenseQueryHookResult = ReturnType<
    typeof useOnboardingOrientationLessonSuspenseQuery
>;
export type OnboardingOrientationLessonQueryResult = Apollo.QueryResult<
    OnboardingOrientationLessonQuery,
    OnboardingOrientationLessonQueryVariables
>;
export const PostCheckoutSearchResultsDocument = gql`
    query postCheckoutSearchResults($bookingFilter: ProvideBookableClassSlotsInput!) {
        classes(bookingFilter: $bookingFilter) {
            ...PostCheckoutClass
        }
    }
    ${PostCheckoutClassFragmentDoc}
`;

/**
 * __usePostCheckoutSearchResultsQuery__
 *
 * To run a query within a React component, call `usePostCheckoutSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostCheckoutSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostCheckoutSearchResultsQuery({
 *   variables: {
 *      bookingFilter: // value for 'bookingFilter'
 *   },
 * });
 */
export function usePostCheckoutSearchResultsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        PostCheckoutSearchResultsQuery,
        PostCheckoutSearchResultsQueryVariables
    > &
        (
            | { variables: PostCheckoutSearchResultsQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PostCheckoutSearchResultsQuery,
        PostCheckoutSearchResultsQueryVariables
    >(PostCheckoutSearchResultsDocument, options);
}
export function usePostCheckoutSearchResultsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PostCheckoutSearchResultsQuery,
        PostCheckoutSearchResultsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PostCheckoutSearchResultsQuery,
        PostCheckoutSearchResultsQueryVariables
    >(PostCheckoutSearchResultsDocument, options);
}
export function usePostCheckoutSearchResultsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        PostCheckoutSearchResultsQuery,
        PostCheckoutSearchResultsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        PostCheckoutSearchResultsQuery,
        PostCheckoutSearchResultsQueryVariables
    >(PostCheckoutSearchResultsDocument, options);
}
export type PostCheckoutSearchResultsQueryHookResult = ReturnType<
    typeof usePostCheckoutSearchResultsQuery
>;
export type PostCheckoutSearchResultsLazyQueryHookResult = ReturnType<
    typeof usePostCheckoutSearchResultsLazyQuery
>;
export type PostCheckoutSearchResultsSuspenseQueryHookResult = ReturnType<
    typeof usePostCheckoutSearchResultsSuspenseQuery
>;
export type PostCheckoutSearchResultsQueryResult = Apollo.QueryResult<
    PostCheckoutSearchResultsQuery,
    PostCheckoutSearchResultsQueryVariables
>;
export const PostCheckoutOrientationIndividualClassesDocument = gql`
    query postCheckoutOrientationIndividualClasses(
        $sectionName: SectionName!
        $moduleId: Int!
        $curriculumId: Int!
        $timezone: String!
    ) {
        classes: anonymousOrientationIndividualClasses(
            sectionName: $sectionName
            moduleId: $moduleId
            curriculumId: $curriculumId
            timezone: $timezone
        ) {
            ...PostCheckoutClass
        }
    }
    ${PostCheckoutClassFragmentDoc}
`;

/**
 * __usePostCheckoutOrientationIndividualClassesQuery__
 *
 * To run a query within a React component, call `usePostCheckoutOrientationIndividualClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostCheckoutOrientationIndividualClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostCheckoutOrientationIndividualClassesQuery({
 *   variables: {
 *      sectionName: // value for 'sectionName'
 *      moduleId: // value for 'moduleId'
 *      curriculumId: // value for 'curriculumId'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function usePostCheckoutOrientationIndividualClassesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        PostCheckoutOrientationIndividualClassesQuery,
        PostCheckoutOrientationIndividualClassesQueryVariables
    > &
        (
            | { variables: PostCheckoutOrientationIndividualClassesQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PostCheckoutOrientationIndividualClassesQuery,
        PostCheckoutOrientationIndividualClassesQueryVariables
    >(PostCheckoutOrientationIndividualClassesDocument, options);
}
export function usePostCheckoutOrientationIndividualClassesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PostCheckoutOrientationIndividualClassesQuery,
        PostCheckoutOrientationIndividualClassesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PostCheckoutOrientationIndividualClassesQuery,
        PostCheckoutOrientationIndividualClassesQueryVariables
    >(PostCheckoutOrientationIndividualClassesDocument, options);
}
export function usePostCheckoutOrientationIndividualClassesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        PostCheckoutOrientationIndividualClassesQuery,
        PostCheckoutOrientationIndividualClassesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        PostCheckoutOrientationIndividualClassesQuery,
        PostCheckoutOrientationIndividualClassesQueryVariables
    >(PostCheckoutOrientationIndividualClassesDocument, options);
}
export type PostCheckoutOrientationIndividualClassesQueryHookResult = ReturnType<
    typeof usePostCheckoutOrientationIndividualClassesQuery
>;
export type PostCheckoutOrientationIndividualClassesLazyQueryHookResult = ReturnType<
    typeof usePostCheckoutOrientationIndividualClassesLazyQuery
>;
export type PostCheckoutOrientationIndividualClassesSuspenseQueryHookResult = ReturnType<
    typeof usePostCheckoutOrientationIndividualClassesSuspenseQuery
>;
export type PostCheckoutOrientationIndividualClassesQueryResult = Apollo.QueryResult<
    PostCheckoutOrientationIndividualClassesQuery,
    PostCheckoutOrientationIndividualClassesQueryVariables
>;
export const BookedClassesDocument = gql`
    query bookedClasses(
        $listLimit: Int!
        $listFromDate: DateTime!
        $listToDate: DateTime!
        $appointmentStatus: String!
    ) {
        student {
            id
            hasBookedClass
        }
        appointmentsList(
            limit: $listLimit
            fromDate: $listFromDate
            toDate: $listToDate
            appointmentStatus: $appointmentStatus
        ) {
            id
            label
            module {
                id
                name
            }
            class {
                ...PostCheckoutClass
            }
        }
    }
    ${PostCheckoutClassFragmentDoc}
`;

/**
 * __useBookedClassesQuery__
 *
 * To run a query within a React component, call `useBookedClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookedClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookedClassesQuery({
 *   variables: {
 *      listLimit: // value for 'listLimit'
 *      listFromDate: // value for 'listFromDate'
 *      listToDate: // value for 'listToDate'
 *      appointmentStatus: // value for 'appointmentStatus'
 *   },
 * });
 */
export function useBookedClassesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        BookedClassesQuery,
        BookedClassesQueryVariables
    > &
        ({ variables: BookedClassesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<BookedClassesQuery, BookedClassesQueryVariables>(
        BookedClassesDocument,
        options,
    );
}
export function useBookedClassesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BookedClassesQuery,
        BookedClassesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<BookedClassesQuery, BookedClassesQueryVariables>(
        BookedClassesDocument,
        options,
    );
}
export function useBookedClassesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        BookedClassesQuery,
        BookedClassesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<BookedClassesQuery, BookedClassesQueryVariables>(
        BookedClassesDocument,
        options,
    );
}
export type BookedClassesQueryHookResult = ReturnType<typeof useBookedClassesQuery>;
export type BookedClassesLazyQueryHookResult = ReturnType<typeof useBookedClassesLazyQuery>;
export type BookedClassesSuspenseQueryHookResult = ReturnType<typeof useBookedClassesSuspenseQuery>;
export type BookedClassesQueryResult = Apollo.QueryResult<
    BookedClassesQuery,
    BookedClassesQueryVariables
>;
export const ReportPsFlashcardDocument = gql`
    mutation reportPsFlashcard($input: FlashcardFeedbackInput!) {
        submitPracticeSequenceFlashcardFeedback(feedbackInput: $input)
    }
`;
export type ReportPsFlashcardMutationFn = Apollo.MutationFunction<
    ReportPsFlashcardMutation,
    ReportPsFlashcardMutationVariables
>;

/**
 * __useReportPsFlashcardMutation__
 *
 * To run a mutation, you first call `useReportPsFlashcardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportPsFlashcardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportPsFlashcardMutation, { data, loading, error }] = useReportPsFlashcardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportPsFlashcardMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        ReportPsFlashcardMutation,
        ReportPsFlashcardMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        ReportPsFlashcardMutation,
        ReportPsFlashcardMutationVariables
    >(ReportPsFlashcardDocument, options);
}
export type ReportPsFlashcardMutationHookResult = ReturnType<typeof useReportPsFlashcardMutation>;
export type ReportPsFlashcardMutationResult = Apollo.MutationResult<ReportPsFlashcardMutation>;
export type ReportPsFlashcardMutationOptions = Apollo.BaseMutationOptions<
    ReportPsFlashcardMutation,
    ReportPsFlashcardMutationVariables
>;
export const ReportPsSingleChoiceDocument = gql`
    mutation reportPsSingleChoice($input: SingleChoiceFeedbackInput!) {
        submitPracticeSequenceSingleChoiceFeedback(feedbackInput: $input)
    }
`;
export type ReportPsSingleChoiceMutationFn = Apollo.MutationFunction<
    ReportPsSingleChoiceMutation,
    ReportPsSingleChoiceMutationVariables
>;

/**
 * __useReportPsSingleChoiceMutation__
 *
 * To run a mutation, you first call `useReportPsSingleChoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportPsSingleChoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportPsSingleChoiceMutation, { data, loading, error }] = useReportPsSingleChoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportPsSingleChoiceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        ReportPsSingleChoiceMutation,
        ReportPsSingleChoiceMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        ReportPsSingleChoiceMutation,
        ReportPsSingleChoiceMutationVariables
    >(ReportPsSingleChoiceDocument, options);
}
export type ReportPsSingleChoiceMutationHookResult = ReturnType<
    typeof useReportPsSingleChoiceMutation
>;
export type ReportPsSingleChoiceMutationResult =
    Apollo.MutationResult<ReportPsSingleChoiceMutation>;
export type ReportPsSingleChoiceMutationOptions = Apollo.BaseMutationOptions<
    ReportPsSingleChoiceMutation,
    ReportPsSingleChoiceMutationVariables
>;
export const ReportPsMatchingDocument = gql`
    mutation reportPsMatching($input: LessonBasedExerciseFeedbackInput!) {
        submitMatchingExerciseFeedback(feedbackInput: $input)
    }
`;
export type ReportPsMatchingMutationFn = Apollo.MutationFunction<
    ReportPsMatchingMutation,
    ReportPsMatchingMutationVariables
>;

/**
 * __useReportPsMatchingMutation__
 *
 * To run a mutation, you first call `useReportPsMatchingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportPsMatchingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportPsMatchingMutation, { data, loading, error }] = useReportPsMatchingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportPsMatchingMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        ReportPsMatchingMutation,
        ReportPsMatchingMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        ReportPsMatchingMutation,
        ReportPsMatchingMutationVariables
    >(ReportPsMatchingDocument, options);
}
export type ReportPsMatchingMutationHookResult = ReturnType<typeof useReportPsMatchingMutation>;
export type ReportPsMatchingMutationResult = Apollo.MutationResult<ReportPsMatchingMutation>;
export type ReportPsMatchingMutationOptions = Apollo.BaseMutationOptions<
    ReportPsMatchingMutation,
    ReportPsMatchingMutationVariables
>;
export const ReportPsDialogueDocument = gql`
    mutation reportPsDialogue($input: LessonBasedExerciseFeedbackInput!) {
        submitBinaryDialogFeedback(feedbackInput: $input)
    }
`;
export type ReportPsDialogueMutationFn = Apollo.MutationFunction<
    ReportPsDialogueMutation,
    ReportPsDialogueMutationVariables
>;

/**
 * __useReportPsDialogueMutation__
 *
 * To run a mutation, you first call `useReportPsDialogueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportPsDialogueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportPsDialogueMutation, { data, loading, error }] = useReportPsDialogueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportPsDialogueMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        ReportPsDialogueMutation,
        ReportPsDialogueMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        ReportPsDialogueMutation,
        ReportPsDialogueMutationVariables
    >(ReportPsDialogueDocument, options);
}
export type ReportPsDialogueMutationHookResult = ReturnType<typeof useReportPsDialogueMutation>;
export type ReportPsDialogueMutationResult = Apollo.MutationResult<ReportPsDialogueMutation>;
export type ReportPsDialogueMutationOptions = Apollo.BaseMutationOptions<
    ReportPsDialogueMutation,
    ReportPsDialogueMutationVariables
>;
export const RetryPracticeSequenceDocument = gql`
    mutation retryPracticeSequence($lessonId: Int!) {
        restartPracticeSequenceQuiz(lessonId: $lessonId) {
            id
        }
    }
`;
export type RetryPracticeSequenceMutationFn = Apollo.MutationFunction<
    RetryPracticeSequenceMutation,
    RetryPracticeSequenceMutationVariables
>;

/**
 * __useRetryPracticeSequenceMutation__
 *
 * To run a mutation, you first call `useRetryPracticeSequenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryPracticeSequenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryPracticeSequenceMutation, { data, loading, error }] = useRetryPracticeSequenceMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useRetryPracticeSequenceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        RetryPracticeSequenceMutation,
        RetryPracticeSequenceMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        RetryPracticeSequenceMutation,
        RetryPracticeSequenceMutationVariables
    >(RetryPracticeSequenceDocument, options);
}
export type RetryPracticeSequenceMutationHookResult = ReturnType<
    typeof useRetryPracticeSequenceMutation
>;
export type RetryPracticeSequenceMutationResult =
    Apollo.MutationResult<RetryPracticeSequenceMutation>;
export type RetryPracticeSequenceMutationOptions = Apollo.BaseMutationOptions<
    RetryPracticeSequenceMutation,
    RetryPracticeSequenceMutationVariables
>;
export const PracticeSequencePageDocument = gql`
    query practiceSequencePage($lessonId: Int!) {
        lesson(id: $lessonId) {
            id
            title
            studentLearningUnit {
                id
            }
        }
        lastLessonAppointment(lessonId: $lessonId) {
            class {
                uid
                startDate
            }
        }
        sequence: practiceSequenceQuiz(lessonId: $lessonId) {
            ...PracticeSequenceQuizSession
        }
    }
    ${PracticeSequenceQuizSessionFragmentDoc}
`;

/**
 * __usePracticeSequencePageQuery__
 *
 * To run a query within a React component, call `usePracticeSequencePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticeSequencePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticeSequencePageQuery({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function usePracticeSequencePageQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        PracticeSequencePageQuery,
        PracticeSequencePageQueryVariables
    > &
        ({ variables: PracticeSequencePageQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PracticeSequencePageQuery, PracticeSequencePageQueryVariables>(
        PracticeSequencePageDocument,
        options,
    );
}
export function usePracticeSequencePageLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PracticeSequencePageQuery,
        PracticeSequencePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PracticeSequencePageQuery,
        PracticeSequencePageQueryVariables
    >(PracticeSequencePageDocument, options);
}
export function usePracticeSequencePageSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        PracticeSequencePageQuery,
        PracticeSequencePageQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        PracticeSequencePageQuery,
        PracticeSequencePageQueryVariables
    >(PracticeSequencePageDocument, options);
}
export type PracticeSequencePageQueryHookResult = ReturnType<typeof usePracticeSequencePageQuery>;
export type PracticeSequencePageLazyQueryHookResult = ReturnType<
    typeof usePracticeSequencePageLazyQuery
>;
export type PracticeSequencePageSuspenseQueryHookResult = ReturnType<
    typeof usePracticeSequencePageSuspenseQuery
>;
export type PracticeSequencePageQueryResult = Apollo.QueryResult<
    PracticeSequencePageQuery,
    PracticeSequencePageQueryVariables
>;
export const PracticeSequenceNextItemDocument = gql`
    query practiceSequenceNextItem {
        nextLessonsForPracticeSequenceQuiz(limit: 1) {
            id
        }
    }
`;

/**
 * __usePracticeSequenceNextItemQuery__
 *
 * To run a query within a React component, call `usePracticeSequenceNextItemQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticeSequenceNextItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticeSequenceNextItemQuery({
 *   variables: {
 *   },
 * });
 */
export function usePracticeSequenceNextItemQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PracticeSequenceNextItemQuery,
        PracticeSequenceNextItemQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PracticeSequenceNextItemQuery,
        PracticeSequenceNextItemQueryVariables
    >(PracticeSequenceNextItemDocument, options);
}
export function usePracticeSequenceNextItemLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PracticeSequenceNextItemQuery,
        PracticeSequenceNextItemQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PracticeSequenceNextItemQuery,
        PracticeSequenceNextItemQueryVariables
    >(PracticeSequenceNextItemDocument, options);
}
export function usePracticeSequenceNextItemSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        PracticeSequenceNextItemQuery,
        PracticeSequenceNextItemQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        PracticeSequenceNextItemQuery,
        PracticeSequenceNextItemQueryVariables
    >(PracticeSequenceNextItemDocument, options);
}
export type PracticeSequenceNextItemQueryHookResult = ReturnType<
    typeof usePracticeSequenceNextItemQuery
>;
export type PracticeSequenceNextItemLazyQueryHookResult = ReturnType<
    typeof usePracticeSequenceNextItemLazyQuery
>;
export type PracticeSequenceNextItemSuspenseQueryHookResult = ReturnType<
    typeof usePracticeSequenceNextItemSuspenseQuery
>;
export type PracticeSequenceNextItemQueryResult = Apollo.QueryResult<
    PracticeSequenceNextItemQuery,
    PracticeSequenceNextItemQueryVariables
>;
export const PsOutroDocument = gql`
    query psOutro($sessionId: String!) {
        sessionScore: quizSessionScore(sessionId: $sessionId) {
            scorePct: totalPercentage
            exercises {
                type
                scorePct: percentage
                scorePctAvg: avgPercentage
            }
        }
    }
`;

/**
 * __usePsOutroQuery__
 *
 * To run a query within a React component, call `usePsOutroQuery` and pass it any options that fit your needs.
 * When your component renders, `usePsOutroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePsOutroQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function usePsOutroQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<PsOutroQuery, PsOutroQueryVariables> &
        ({ variables: PsOutroQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PsOutroQuery, PsOutroQueryVariables>(PsOutroDocument, options);
}
export function usePsOutroLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PsOutroQuery, PsOutroQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PsOutroQuery, PsOutroQueryVariables>(
        PsOutroDocument,
        options,
    );
}
export function usePsOutroSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<PsOutroQuery, PsOutroQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<PsOutroQuery, PsOutroQueryVariables>(
        PsOutroDocument,
        options,
    );
}
export type PsOutroQueryHookResult = ReturnType<typeof usePsOutroQuery>;
export type PsOutroLazyQueryHookResult = ReturnType<typeof usePsOutroLazyQuery>;
export type PsOutroSuspenseQueryHookResult = ReturnType<typeof usePsOutroSuspenseQuery>;
export type PsOutroQueryResult = Apollo.QueryResult<PsOutroQuery, PsOutroQueryVariables>;
export const PsStage1ScoreDocument = gql`
    query psStage1Score($sessionId: String!) {
        sessionScore: quizSessionScore(sessionId: $sessionId) {
            exercises {
                type
                scorePct: percentage
            }
        }
    }
`;

/**
 * __usePsStage1ScoreQuery__
 *
 * To run a query within a React component, call `usePsStage1ScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `usePsStage1ScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePsStage1ScoreQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function usePsStage1ScoreQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        PsStage1ScoreQuery,
        PsStage1ScoreQueryVariables
    > &
        ({ variables: PsStage1ScoreQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PsStage1ScoreQuery, PsStage1ScoreQueryVariables>(
        PsStage1ScoreDocument,
        options,
    );
}
export function usePsStage1ScoreLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PsStage1ScoreQuery,
        PsStage1ScoreQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PsStage1ScoreQuery, PsStage1ScoreQueryVariables>(
        PsStage1ScoreDocument,
        options,
    );
}
export function usePsStage1ScoreSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        PsStage1ScoreQuery,
        PsStage1ScoreQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<PsStage1ScoreQuery, PsStage1ScoreQueryVariables>(
        PsStage1ScoreDocument,
        options,
    );
}
export type PsStage1ScoreQueryHookResult = ReturnType<typeof usePsStage1ScoreQuery>;
export type PsStage1ScoreLazyQueryHookResult = ReturnType<typeof usePsStage1ScoreLazyQuery>;
export type PsStage1ScoreSuspenseQueryHookResult = ReturnType<typeof usePsStage1ScoreSuspenseQuery>;
export type PsStage1ScoreQueryResult = Apollo.QueryResult<
    PsStage1ScoreQuery,
    PsStage1ScoreQueryVariables
>;
export const SubmitPsFlashcardDocument = gql`
    mutation submitPsFlashcard($input: ReportVocabResultInput!) {
        quizReportVocabResult(input: $input)
    }
`;
export type SubmitPsFlashcardMutationFn = Apollo.MutationFunction<
    SubmitPsFlashcardMutation,
    SubmitPsFlashcardMutationVariables
>;

/**
 * __useSubmitPsFlashcardMutation__
 *
 * To run a mutation, you first call `useSubmitPsFlashcardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPsFlashcardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPsFlashcardMutation, { data, loading, error }] = useSubmitPsFlashcardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitPsFlashcardMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SubmitPsFlashcardMutation,
        SubmitPsFlashcardMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SubmitPsFlashcardMutation,
        SubmitPsFlashcardMutationVariables
    >(SubmitPsFlashcardDocument, options);
}
export type SubmitPsFlashcardMutationHookResult = ReturnType<typeof useSubmitPsFlashcardMutation>;
export type SubmitPsFlashcardMutationResult = Apollo.MutationResult<SubmitPsFlashcardMutation>;
export type SubmitPsFlashcardMutationOptions = Apollo.BaseMutationOptions<
    SubmitPsFlashcardMutation,
    SubmitPsFlashcardMutationVariables
>;
export const SubmitPsQuestionDocument = gql`
    mutation submitPsQuestion($input: ReportResultInput!) {
        quizEngineReportResult(input: $input) {
            score
        }
    }
`;
export type SubmitPsQuestionMutationFn = Apollo.MutationFunction<
    SubmitPsQuestionMutation,
    SubmitPsQuestionMutationVariables
>;

/**
 * __useSubmitPsQuestionMutation__
 *
 * To run a mutation, you first call `useSubmitPsQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPsQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPsQuestionMutation, { data, loading, error }] = useSubmitPsQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitPsQuestionMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SubmitPsQuestionMutation,
        SubmitPsQuestionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SubmitPsQuestionMutation,
        SubmitPsQuestionMutationVariables
    >(SubmitPsQuestionDocument, options);
}
export type SubmitPsQuestionMutationHookResult = ReturnType<typeof useSubmitPsQuestionMutation>;
export type SubmitPsQuestionMutationResult = Apollo.MutationResult<SubmitPsQuestionMutation>;
export type SubmitPsQuestionMutationOptions = Apollo.BaseMutationOptions<
    SubmitPsQuestionMutation,
    SubmitPsQuestionMutationVariables
>;
export const SubmitPsQuestionsDocument = gql`
    mutation submitPsQuestions($input: ReportMultipleResultInput!) {
        quizEngineReportResults(input: $input) {
            score
        }
    }
`;
export type SubmitPsQuestionsMutationFn = Apollo.MutationFunction<
    SubmitPsQuestionsMutation,
    SubmitPsQuestionsMutationVariables
>;

/**
 * __useSubmitPsQuestionsMutation__
 *
 * To run a mutation, you first call `useSubmitPsQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPsQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPsQuestionsMutation, { data, loading, error }] = useSubmitPsQuestionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitPsQuestionsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SubmitPsQuestionsMutation,
        SubmitPsQuestionsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SubmitPsQuestionsMutation,
        SubmitPsQuestionsMutationVariables
    >(SubmitPsQuestionsDocument, options);
}
export type SubmitPsQuestionsMutationHookResult = ReturnType<typeof useSubmitPsQuestionsMutation>;
export type SubmitPsQuestionsMutationResult = Apollo.MutationResult<SubmitPsQuestionsMutation>;
export type SubmitPsQuestionsMutationOptions = Apollo.BaseMutationOptions<
    SubmitPsQuestionsMutation,
    SubmitPsQuestionsMutationVariables
>;
export const WhyAreYouLearningDocument = gql`
    mutation whyAreYouLearning($answers: [WhyAreYouLearningAnswerEnum]!, $otherOption: String) {
        whyAreYouLearning(answers: $answers, otherOption: $otherOption)
    }
`;
export type WhyAreYouLearningMutationFn = Apollo.MutationFunction<
    WhyAreYouLearningMutation,
    WhyAreYouLearningMutationVariables
>;

/**
 * __useWhyAreYouLearningMutation__
 *
 * To run a mutation, you first call `useWhyAreYouLearningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWhyAreYouLearningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [whyAreYouLearningMutation, { data, loading, error }] = useWhyAreYouLearningMutation({
 *   variables: {
 *      answers: // value for 'answers'
 *      otherOption: // value for 'otherOption'
 *   },
 * });
 */
export function useWhyAreYouLearningMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        WhyAreYouLearningMutation,
        WhyAreYouLearningMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        WhyAreYouLearningMutation,
        WhyAreYouLearningMutationVariables
    >(WhyAreYouLearningDocument, options);
}
export type WhyAreYouLearningMutationHookResult = ReturnType<typeof useWhyAreYouLearningMutation>;
export type WhyAreYouLearningMutationResult = Apollo.MutationResult<WhyAreYouLearningMutation>;
export type WhyAreYouLearningMutationOptions = Apollo.BaseMutationOptions<
    WhyAreYouLearningMutation,
    WhyAreYouLearningMutationVariables
>;
export const DeleteWhyAreYouLearningDocument = gql`
    mutation deleteWhyAreYouLearning {
        deleteWhyAreYouLearning
    }
`;
export type DeleteWhyAreYouLearningMutationFn = Apollo.MutationFunction<
    DeleteWhyAreYouLearningMutation,
    DeleteWhyAreYouLearningMutationVariables
>;

/**
 * __useDeleteWhyAreYouLearningMutation__
 *
 * To run a mutation, you first call `useDeleteWhyAreYouLearningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWhyAreYouLearningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWhyAreYouLearningMutation, { data, loading, error }] = useDeleteWhyAreYouLearningMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteWhyAreYouLearningMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteWhyAreYouLearningMutation,
        DeleteWhyAreYouLearningMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteWhyAreYouLearningMutation,
        DeleteWhyAreYouLearningMutationVariables
    >(DeleteWhyAreYouLearningDocument, options);
}
export type DeleteWhyAreYouLearningMutationHookResult = ReturnType<
    typeof useDeleteWhyAreYouLearningMutation
>;
export type DeleteWhyAreYouLearningMutationResult =
    Apollo.MutationResult<DeleteWhyAreYouLearningMutation>;
export type DeleteWhyAreYouLearningMutationOptions = Apollo.BaseMutationOptions<
    DeleteWhyAreYouLearningMutation,
    DeleteWhyAreYouLearningMutationVariables
>;
export const LearningNeedsDocument = gql`
    mutation learningNeeds($learningNeeds: String!) {
        learningNeeds(learningNeeds: $learningNeeds)
    }
`;
export type LearningNeedsMutationFn = Apollo.MutationFunction<
    LearningNeedsMutation,
    LearningNeedsMutationVariables
>;

/**
 * __useLearningNeedsMutation__
 *
 * To run a mutation, you first call `useLearningNeedsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLearningNeedsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [learningNeedsMutation, { data, loading, error }] = useLearningNeedsMutation({
 *   variables: {
 *      learningNeeds: // value for 'learningNeeds'
 *   },
 * });
 */
export function useLearningNeedsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        LearningNeedsMutation,
        LearningNeedsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<LearningNeedsMutation, LearningNeedsMutationVariables>(
        LearningNeedsDocument,
        options,
    );
}
export type LearningNeedsMutationHookResult = ReturnType<typeof useLearningNeedsMutation>;
export type LearningNeedsMutationResult = Apollo.MutationResult<LearningNeedsMutation>;
export type LearningNeedsMutationOptions = Apollo.BaseMutationOptions<
    LearningNeedsMutation,
    LearningNeedsMutationVariables
>;
export const DeleteLearningNeedsDocument = gql`
    mutation deleteLearningNeeds {
        deleteLearningNeeds
    }
`;
export type DeleteLearningNeedsMutationFn = Apollo.MutationFunction<
    DeleteLearningNeedsMutation,
    DeleteLearningNeedsMutationVariables
>;

/**
 * __useDeleteLearningNeedsMutation__
 *
 * To run a mutation, you first call `useDeleteLearningNeedsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLearningNeedsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLearningNeedsMutation, { data, loading, error }] = useDeleteLearningNeedsMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteLearningNeedsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteLearningNeedsMutation,
        DeleteLearningNeedsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteLearningNeedsMutation,
        DeleteLearningNeedsMutationVariables
    >(DeleteLearningNeedsDocument, options);
}
export type DeleteLearningNeedsMutationHookResult = ReturnType<
    typeof useDeleteLearningNeedsMutation
>;
export type DeleteLearningNeedsMutationResult = Apollo.MutationResult<DeleteLearningNeedsMutation>;
export type DeleteLearningNeedsMutationOptions = Apollo.BaseMutationOptions<
    DeleteLearningNeedsMutation,
    DeleteLearningNeedsMutationVariables
>;
export const AddLanguageYouSpeakDocument = gql`
    mutation addLanguageYouSpeak($language: UserLanguage!, $languageFluency: LanguageFluency!) {
        createSpokenLanguage(language: $language, languageFluency: $languageFluency)
    }
`;
export type AddLanguageYouSpeakMutationFn = Apollo.MutationFunction<
    AddLanguageYouSpeakMutation,
    AddLanguageYouSpeakMutationVariables
>;

/**
 * __useAddLanguageYouSpeakMutation__
 *
 * To run a mutation, you first call `useAddLanguageYouSpeakMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLanguageYouSpeakMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLanguageYouSpeakMutation, { data, loading, error }] = useAddLanguageYouSpeakMutation({
 *   variables: {
 *      language: // value for 'language'
 *      languageFluency: // value for 'languageFluency'
 *   },
 * });
 */
export function useAddLanguageYouSpeakMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        AddLanguageYouSpeakMutation,
        AddLanguageYouSpeakMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        AddLanguageYouSpeakMutation,
        AddLanguageYouSpeakMutationVariables
    >(AddLanguageYouSpeakDocument, options);
}
export type AddLanguageYouSpeakMutationHookResult = ReturnType<
    typeof useAddLanguageYouSpeakMutation
>;
export type AddLanguageYouSpeakMutationResult = Apollo.MutationResult<AddLanguageYouSpeakMutation>;
export type AddLanguageYouSpeakMutationOptions = Apollo.BaseMutationOptions<
    AddLanguageYouSpeakMutation,
    AddLanguageYouSpeakMutationVariables
>;
export const UpdateLanguageYouSpeakDocument = gql`
    mutation updateLanguageYouSpeak(
        $newLanguage: UserLanguage!
        $currentLanguage: UserLanguage!
        $languageFluency: LanguageFluency!
    ) {
        updateSpokenLanguage(
            currentLanguage: $currentLanguage
            newLanguage: $newLanguage
            languageFluency: $languageFluency
        )
    }
`;
export type UpdateLanguageYouSpeakMutationFn = Apollo.MutationFunction<
    UpdateLanguageYouSpeakMutation,
    UpdateLanguageYouSpeakMutationVariables
>;

/**
 * __useUpdateLanguageYouSpeakMutation__
 *
 * To run a mutation, you first call `useUpdateLanguageYouSpeakMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLanguageYouSpeakMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLanguageYouSpeakMutation, { data, loading, error }] = useUpdateLanguageYouSpeakMutation({
 *   variables: {
 *      newLanguage: // value for 'newLanguage'
 *      currentLanguage: // value for 'currentLanguage'
 *      languageFluency: // value for 'languageFluency'
 *   },
 * });
 */
export function useUpdateLanguageYouSpeakMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateLanguageYouSpeakMutation,
        UpdateLanguageYouSpeakMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateLanguageYouSpeakMutation,
        UpdateLanguageYouSpeakMutationVariables
    >(UpdateLanguageYouSpeakDocument, options);
}
export type UpdateLanguageYouSpeakMutationHookResult = ReturnType<
    typeof useUpdateLanguageYouSpeakMutation
>;
export type UpdateLanguageYouSpeakMutationResult =
    Apollo.MutationResult<UpdateLanguageYouSpeakMutation>;
export type UpdateLanguageYouSpeakMutationOptions = Apollo.BaseMutationOptions<
    UpdateLanguageYouSpeakMutation,
    UpdateLanguageYouSpeakMutationVariables
>;
export const DeleteLanguageYouSpeakDocument = gql`
    mutation deleteLanguageYouSpeak($language: UserLanguage!) {
        deleteSpokenLanguage(language: $language)
    }
`;
export type DeleteLanguageYouSpeakMutationFn = Apollo.MutationFunction<
    DeleteLanguageYouSpeakMutation,
    DeleteLanguageYouSpeakMutationVariables
>;

/**
 * __useDeleteLanguageYouSpeakMutation__
 *
 * To run a mutation, you first call `useDeleteLanguageYouSpeakMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLanguageYouSpeakMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLanguageYouSpeakMutation, { data, loading, error }] = useDeleteLanguageYouSpeakMutation({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useDeleteLanguageYouSpeakMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteLanguageYouSpeakMutation,
        DeleteLanguageYouSpeakMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteLanguageYouSpeakMutation,
        DeleteLanguageYouSpeakMutationVariables
    >(DeleteLanguageYouSpeakDocument, options);
}
export type DeleteLanguageYouSpeakMutationHookResult = ReturnType<
    typeof useDeleteLanguageYouSpeakMutation
>;
export type DeleteLanguageYouSpeakMutationResult =
    Apollo.MutationResult<DeleteLanguageYouSpeakMutation>;
export type DeleteLanguageYouSpeakMutationOptions = Apollo.BaseMutationOptions<
    DeleteLanguageYouSpeakMutation,
    DeleteLanguageYouSpeakMutationVariables
>;
export const UpdateStudentMarketingPreferencesDocument = gql`
    mutation updateStudentMarketingPreferences($preferences: StudentMarketingPreferencesInput!) {
        updateStudentMarketingPreferences(preferences: $preferences)
    }
`;
export type UpdateStudentMarketingPreferencesMutationFn = Apollo.MutationFunction<
    UpdateStudentMarketingPreferencesMutation,
    UpdateStudentMarketingPreferencesMutationVariables
>;

/**
 * __useUpdateStudentMarketingPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateStudentMarketingPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentMarketingPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentMarketingPreferencesMutation, { data, loading, error }] = useUpdateStudentMarketingPreferencesMutation({
 *   variables: {
 *      preferences: // value for 'preferences'
 *   },
 * });
 */
export function useUpdateStudentMarketingPreferencesMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateStudentMarketingPreferencesMutation,
        UpdateStudentMarketingPreferencesMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateStudentMarketingPreferencesMutation,
        UpdateStudentMarketingPreferencesMutationVariables
    >(UpdateStudentMarketingPreferencesDocument, options);
}
export type UpdateStudentMarketingPreferencesMutationHookResult = ReturnType<
    typeof useUpdateStudentMarketingPreferencesMutation
>;
export type UpdateStudentMarketingPreferencesMutationResult =
    Apollo.MutationResult<UpdateStudentMarketingPreferencesMutation>;
export type UpdateStudentMarketingPreferencesMutationOptions = Apollo.BaseMutationOptions<
    UpdateStudentMarketingPreferencesMutation,
    UpdateStudentMarketingPreferencesMutationVariables
>;
export const UpdateUserPhoneDocument = gql`
    mutation updateUserPhone($phoneNumber: String!) {
        updateUserPhone(phoneNumber: $phoneNumber) {
            id
            phoneNumber
        }
    }
`;
export type UpdateUserPhoneMutationFn = Apollo.MutationFunction<
    UpdateUserPhoneMutation,
    UpdateUserPhoneMutationVariables
>;

/**
 * __useUpdateUserPhoneMutation__
 *
 * To run a mutation, you first call `useUpdateUserPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPhoneMutation, { data, loading, error }] = useUpdateUserPhoneMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUpdateUserPhoneMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateUserPhoneMutation,
        UpdateUserPhoneMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<UpdateUserPhoneMutation, UpdateUserPhoneMutationVariables>(
        UpdateUserPhoneDocument,
        options,
    );
}
export type UpdateUserPhoneMutationHookResult = ReturnType<typeof useUpdateUserPhoneMutation>;
export type UpdateUserPhoneMutationResult = Apollo.MutationResult<UpdateUserPhoneMutation>;
export type UpdateUserPhoneMutationOptions = Apollo.BaseMutationOptions<
    UpdateUserPhoneMutation,
    UpdateUserPhoneMutationVariables
>;
export const GetStudentMarketingPreferencesDocument = gql`
    query getStudentMarketingPreferences {
        student {
            preferences {
                marketingPreferences {
                    marketingCommunicationEmail
                    marketingCommunicationPhone
                    marketingCommunicationWhatsApp
                }
            }
        }
    }
`;

/**
 * __useGetStudentMarketingPreferencesQuery__
 *
 * To run a query within a React component, call `useGetStudentMarketingPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentMarketingPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentMarketingPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStudentMarketingPreferencesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GetStudentMarketingPreferencesQuery,
        GetStudentMarketingPreferencesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetStudentMarketingPreferencesQuery,
        GetStudentMarketingPreferencesQueryVariables
    >(GetStudentMarketingPreferencesDocument, options);
}
export function useGetStudentMarketingPreferencesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetStudentMarketingPreferencesQuery,
        GetStudentMarketingPreferencesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetStudentMarketingPreferencesQuery,
        GetStudentMarketingPreferencesQueryVariables
    >(GetStudentMarketingPreferencesDocument, options);
}
export function useGetStudentMarketingPreferencesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        GetStudentMarketingPreferencesQuery,
        GetStudentMarketingPreferencesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetStudentMarketingPreferencesQuery,
        GetStudentMarketingPreferencesQueryVariables
    >(GetStudentMarketingPreferencesDocument, options);
}
export type GetStudentMarketingPreferencesQueryHookResult = ReturnType<
    typeof useGetStudentMarketingPreferencesQuery
>;
export type GetStudentMarketingPreferencesLazyQueryHookResult = ReturnType<
    typeof useGetStudentMarketingPreferencesLazyQuery
>;
export type GetStudentMarketingPreferencesSuspenseQueryHookResult = ReturnType<
    typeof useGetStudentMarketingPreferencesSuspenseQuery
>;
export type GetStudentMarketingPreferencesQueryResult = Apollo.QueryResult<
    GetStudentMarketingPreferencesQuery,
    GetStudentMarketingPreferencesQueryVariables
>;
export const StudentSettingsDocument = gql`
    query studentSettings {
        student {
            id
            businessModelType
            studentProfile {
                whyAreYouLearning {
                    questions
                    answers
                    otherOption
                }
                learningNeeds
                spokenLanguages {
                    language
                    languageFluency
                }
            }
            section {
                id
                name
            }
        }
        shouldShowProfileSettingsPageKysBanner: isWidgetEligibleForDisplay(
            widgetClass: StudentFillProfileReminderProfileSettingsPageBanner
        )
    }
`;

/**
 * __useStudentSettingsQuery__
 *
 * To run a query within a React component, call `useStudentSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentSettingsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        StudentSettingsQuery,
        StudentSettingsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<StudentSettingsQuery, StudentSettingsQueryVariables>(
        StudentSettingsDocument,
        options,
    );
}
export function useStudentSettingsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        StudentSettingsQuery,
        StudentSettingsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<StudentSettingsQuery, StudentSettingsQueryVariables>(
        StudentSettingsDocument,
        options,
    );
}
export function useStudentSettingsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        StudentSettingsQuery,
        StudentSettingsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<StudentSettingsQuery, StudentSettingsQueryVariables>(
        StudentSettingsDocument,
        options,
    );
}
export type StudentSettingsQueryHookResult = ReturnType<typeof useStudentSettingsQuery>;
export type StudentSettingsLazyQueryHookResult = ReturnType<typeof useStudentSettingsLazyQuery>;
export type StudentSettingsSuspenseQueryHookResult = ReturnType<
    typeof useStudentSettingsSuspenseQuery
>;
export type StudentSettingsQueryResult = Apollo.QueryResult<
    StudentSettingsQuery,
    StudentSettingsQueryVariables
>;
export const YearlyRecapAllSummaryDocument = gql`
    query yearlyRecapAllSummary($year: Int!) {
        studentYearlySummary(year: $year) {
            ...YearlyActivityAllSummary
        }
    }
    ${YearlyActivityAllSummaryFragmentDoc}
`;

/**
 * __useYearlyRecapAllSummaryQuery__
 *
 * To run a query within a React component, call `useYearlyRecapAllSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useYearlyRecapAllSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYearlyRecapAllSummaryQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useYearlyRecapAllSummaryQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        YearlyRecapAllSummaryQuery,
        YearlyRecapAllSummaryQueryVariables
    > &
        ({ variables: YearlyRecapAllSummaryQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        YearlyRecapAllSummaryQuery,
        YearlyRecapAllSummaryQueryVariables
    >(YearlyRecapAllSummaryDocument, options);
}
export function useYearlyRecapAllSummaryLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        YearlyRecapAllSummaryQuery,
        YearlyRecapAllSummaryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        YearlyRecapAllSummaryQuery,
        YearlyRecapAllSummaryQueryVariables
    >(YearlyRecapAllSummaryDocument, options);
}
export function useYearlyRecapAllSummarySuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        YearlyRecapAllSummaryQuery,
        YearlyRecapAllSummaryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        YearlyRecapAllSummaryQuery,
        YearlyRecapAllSummaryQueryVariables
    >(YearlyRecapAllSummaryDocument, options);
}
export type YearlyRecapAllSummaryQueryHookResult = ReturnType<typeof useYearlyRecapAllSummaryQuery>;
export type YearlyRecapAllSummaryLazyQueryHookResult = ReturnType<
    typeof useYearlyRecapAllSummaryLazyQuery
>;
export type YearlyRecapAllSummarySuspenseQueryHookResult = ReturnType<
    typeof useYearlyRecapAllSummarySuspenseQuery
>;
export type YearlyRecapAllSummaryQueryResult = Apollo.QueryResult<
    YearlyRecapAllSummaryQuery,
    YearlyRecapAllSummaryQueryVariables
>;
export const TeacherUpdateAgreementsDocument = gql`
    mutation TeacherUpdateAgreements($agreementId: String!) {
        teacherAcceptAgreement(agreementId: $agreementId) {
            ...TeacherAgreementsData
        }
    }
    ${TeacherAgreementsDataFragmentDoc}
`;
export type TeacherUpdateAgreementsMutationFn = Apollo.MutationFunction<
    TeacherUpdateAgreementsMutation,
    TeacherUpdateAgreementsMutationVariables
>;

/**
 * __useTeacherUpdateAgreementsMutation__
 *
 * To run a mutation, you first call `useTeacherUpdateAgreementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherUpdateAgreementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherUpdateAgreementsMutation, { data, loading, error }] = useTeacherUpdateAgreementsMutation({
 *   variables: {
 *      agreementId: // value for 'agreementId'
 *   },
 * });
 */
export function useTeacherUpdateAgreementsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        TeacherUpdateAgreementsMutation,
        TeacherUpdateAgreementsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        TeacherUpdateAgreementsMutation,
        TeacherUpdateAgreementsMutationVariables
    >(TeacherUpdateAgreementsDocument, options);
}
export type TeacherUpdateAgreementsMutationHookResult = ReturnType<
    typeof useTeacherUpdateAgreementsMutation
>;
export type TeacherUpdateAgreementsMutationResult =
    Apollo.MutationResult<TeacherUpdateAgreementsMutation>;
export type TeacherUpdateAgreementsMutationOptions = Apollo.BaseMutationOptions<
    TeacherUpdateAgreementsMutation,
    TeacherUpdateAgreementsMutationVariables
>;
export const TeacherGetAgreementsDocument = gql`
    query TeacherGetAgreements {
        teacher {
            id
            agreements {
                ...TeacherAgreementsData
            }
        }
    }
    ${TeacherAgreementsDataFragmentDoc}
`;

/**
 * __useTeacherGetAgreementsQuery__
 *
 * To run a query within a React component, call `useTeacherGetAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherGetAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherGetAgreementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherGetAgreementsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherGetAgreementsQuery,
        TeacherGetAgreementsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TeacherGetAgreementsQuery, TeacherGetAgreementsQueryVariables>(
        TeacherGetAgreementsDocument,
        options,
    );
}
export function useTeacherGetAgreementsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherGetAgreementsQuery,
        TeacherGetAgreementsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        TeacherGetAgreementsQuery,
        TeacherGetAgreementsQueryVariables
    >(TeacherGetAgreementsDocument, options);
}
export function useTeacherGetAgreementsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherGetAgreementsQuery,
        TeacherGetAgreementsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherGetAgreementsQuery,
        TeacherGetAgreementsQueryVariables
    >(TeacherGetAgreementsDocument, options);
}
export type TeacherGetAgreementsQueryHookResult = ReturnType<typeof useTeacherGetAgreementsQuery>;
export type TeacherGetAgreementsLazyQueryHookResult = ReturnType<
    typeof useTeacherGetAgreementsLazyQuery
>;
export type TeacherGetAgreementsSuspenseQueryHookResult = ReturnType<
    typeof useTeacherGetAgreementsSuspenseQuery
>;
export type TeacherGetAgreementsQueryResult = Apollo.QueryResult<
    TeacherGetAgreementsQuery,
    TeacherGetAgreementsQueryVariables
>;
export const TeacherCalendarCommitDetailsDialogDocument = gql`
    mutation TeacherCalendarCommitDetailsDialog($classId: String!) {
        teacherCommitToClass(classId: $classId) {
            ...TeacherCalendarTeacherCalendarTimeSlot
        }
    }
    ${TeacherCalendarTeacherCalendarTimeSlotFragmentDoc}
`;
export type TeacherCalendarCommitDetailsDialogMutationFn = Apollo.MutationFunction<
    TeacherCalendarCommitDetailsDialogMutation,
    TeacherCalendarCommitDetailsDialogMutationVariables
>;

/**
 * __useTeacherCalendarCommitDetailsDialogMutation__
 *
 * To run a mutation, you first call `useTeacherCalendarCommitDetailsDialogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherCalendarCommitDetailsDialogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherCalendarCommitDetailsDialogMutation, { data, loading, error }] = useTeacherCalendarCommitDetailsDialogMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useTeacherCalendarCommitDetailsDialogMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        TeacherCalendarCommitDetailsDialogMutation,
        TeacherCalendarCommitDetailsDialogMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        TeacherCalendarCommitDetailsDialogMutation,
        TeacherCalendarCommitDetailsDialogMutationVariables
    >(TeacherCalendarCommitDetailsDialogDocument, options);
}
export type TeacherCalendarCommitDetailsDialogMutationHookResult = ReturnType<
    typeof useTeacherCalendarCommitDetailsDialogMutation
>;
export type TeacherCalendarCommitDetailsDialogMutationResult =
    Apollo.MutationResult<TeacherCalendarCommitDetailsDialogMutation>;
export type TeacherCalendarCommitDetailsDialogMutationOptions = Apollo.BaseMutationOptions<
    TeacherCalendarCommitDetailsDialogMutation,
    TeacherCalendarCommitDetailsDialogMutationVariables
>;
export const AppealPenaltyDocument = gql`
    mutation AppealPenalty($explanationNote: String!) {
        appealPenalty(explanationNote: $explanationNote)
    }
`;
export type AppealPenaltyMutationFn = Apollo.MutationFunction<
    AppealPenaltyMutation,
    AppealPenaltyMutationVariables
>;

/**
 * __useAppealPenaltyMutation__
 *
 * To run a mutation, you first call `useAppealPenaltyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppealPenaltyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appealPenaltyMutation, { data, loading, error }] = useAppealPenaltyMutation({
 *   variables: {
 *      explanationNote: // value for 'explanationNote'
 *   },
 * });
 */
export function useAppealPenaltyMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        AppealPenaltyMutation,
        AppealPenaltyMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<AppealPenaltyMutation, AppealPenaltyMutationVariables>(
        AppealPenaltyDocument,
        options,
    );
}
export type AppealPenaltyMutationHookResult = ReturnType<typeof useAppealPenaltyMutation>;
export type AppealPenaltyMutationResult = Apollo.MutationResult<AppealPenaltyMutation>;
export type AppealPenaltyMutationOptions = Apollo.BaseMutationOptions<
    AppealPenaltyMutation,
    AppealPenaltyMutationVariables
>;
export const TeacherCalendarGetTeacherCalendarDocument = gql`
    query TeacherCalendarGetTeacherCalendar($weekStartDate: DateTime) {
        teacherCalendar(weekStartDate: $weekStartDate) {
            ...TeacherCalendarTeacherCalendar
        }
        teacher {
            id
            penaltyUntil
            canAppealPenalty
        }
    }
    ${TeacherCalendarTeacherCalendarFragmentDoc}
`;

/**
 * __useTeacherCalendarGetTeacherCalendarQuery__
 *
 * To run a query within a React component, call `useTeacherCalendarGetTeacherCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherCalendarGetTeacherCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherCalendarGetTeacherCalendarQuery({
 *   variables: {
 *      weekStartDate: // value for 'weekStartDate'
 *   },
 * });
 */
export function useTeacherCalendarGetTeacherCalendarQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherCalendarGetTeacherCalendarQuery,
        TeacherCalendarGetTeacherCalendarQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        TeacherCalendarGetTeacherCalendarQuery,
        TeacherCalendarGetTeacherCalendarQueryVariables
    >(TeacherCalendarGetTeacherCalendarDocument, options);
}
export function useTeacherCalendarGetTeacherCalendarLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherCalendarGetTeacherCalendarQuery,
        TeacherCalendarGetTeacherCalendarQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        TeacherCalendarGetTeacherCalendarQuery,
        TeacherCalendarGetTeacherCalendarQueryVariables
    >(TeacherCalendarGetTeacherCalendarDocument, options);
}
export function useTeacherCalendarGetTeacherCalendarSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherCalendarGetTeacherCalendarQuery,
        TeacherCalendarGetTeacherCalendarQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherCalendarGetTeacherCalendarQuery,
        TeacherCalendarGetTeacherCalendarQueryVariables
    >(TeacherCalendarGetTeacherCalendarDocument, options);
}
export type TeacherCalendarGetTeacherCalendarQueryHookResult = ReturnType<
    typeof useTeacherCalendarGetTeacherCalendarQuery
>;
export type TeacherCalendarGetTeacherCalendarLazyQueryHookResult = ReturnType<
    typeof useTeacherCalendarGetTeacherCalendarLazyQuery
>;
export type TeacherCalendarGetTeacherCalendarSuspenseQueryHookResult = ReturnType<
    typeof useTeacherCalendarGetTeacherCalendarSuspenseQuery
>;
export type TeacherCalendarGetTeacherCalendarQueryResult = Apollo.QueryResult<
    TeacherCalendarGetTeacherCalendarQuery,
    TeacherCalendarGetTeacherCalendarQueryVariables
>;
export const TeacherCalendarGetDetailsDialogDocument = gql`
    query TeacherCalendarGetDetailsDialog($eventStartDate: DateTime!) {
        teacherCalendarEventDetails(eventStartDate: $eventStartDate) {
            ...TeacherDetailsDialogTeacherCalendarEventDetailsList
        }
    }
    ${TeacherDetailsDialogTeacherCalendarEventDetailsListFragmentDoc}
`;

/**
 * __useTeacherCalendarGetDetailsDialogQuery__
 *
 * To run a query within a React component, call `useTeacherCalendarGetDetailsDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherCalendarGetDetailsDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherCalendarGetDetailsDialogQuery({
 *   variables: {
 *      eventStartDate: // value for 'eventStartDate'
 *   },
 * });
 */
export function useTeacherCalendarGetDetailsDialogQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        TeacherCalendarGetDetailsDialogQuery,
        TeacherCalendarGetDetailsDialogQueryVariables
    > &
        (
            | { variables: TeacherCalendarGetDetailsDialogQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        TeacherCalendarGetDetailsDialogQuery,
        TeacherCalendarGetDetailsDialogQueryVariables
    >(TeacherCalendarGetDetailsDialogDocument, options);
}
export function useTeacherCalendarGetDetailsDialogLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherCalendarGetDetailsDialogQuery,
        TeacherCalendarGetDetailsDialogQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        TeacherCalendarGetDetailsDialogQuery,
        TeacherCalendarGetDetailsDialogQueryVariables
    >(TeacherCalendarGetDetailsDialogDocument, options);
}
export function useTeacherCalendarGetDetailsDialogSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherCalendarGetDetailsDialogQuery,
        TeacherCalendarGetDetailsDialogQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherCalendarGetDetailsDialogQuery,
        TeacherCalendarGetDetailsDialogQueryVariables
    >(TeacherCalendarGetDetailsDialogDocument, options);
}
export type TeacherCalendarGetDetailsDialogQueryHookResult = ReturnType<
    typeof useTeacherCalendarGetDetailsDialogQuery
>;
export type TeacherCalendarGetDetailsDialogLazyQueryHookResult = ReturnType<
    typeof useTeacherCalendarGetDetailsDialogLazyQuery
>;
export type TeacherCalendarGetDetailsDialogSuspenseQueryHookResult = ReturnType<
    typeof useTeacherCalendarGetDetailsDialogSuspenseQuery
>;
export type TeacherCalendarGetDetailsDialogQueryResult = Apollo.QueryResult<
    TeacherCalendarGetDetailsDialogQuery,
    TeacherCalendarGetDetailsDialogQueryVariables
>;
export const TeacherCalendarGetGoToNextClassButtonDocument = gql`
    query TeacherCalendarGetGoToNextClassButton {
        teacherNextClass {
            uid
        }
    }
`;

/**
 * __useTeacherCalendarGetGoToNextClassButtonQuery__
 *
 * To run a query within a React component, call `useTeacherCalendarGetGoToNextClassButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherCalendarGetGoToNextClassButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherCalendarGetGoToNextClassButtonQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherCalendarGetGoToNextClassButtonQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherCalendarGetGoToNextClassButtonQuery,
        TeacherCalendarGetGoToNextClassButtonQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        TeacherCalendarGetGoToNextClassButtonQuery,
        TeacherCalendarGetGoToNextClassButtonQueryVariables
    >(TeacherCalendarGetGoToNextClassButtonDocument, options);
}
export function useTeacherCalendarGetGoToNextClassButtonLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherCalendarGetGoToNextClassButtonQuery,
        TeacherCalendarGetGoToNextClassButtonQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        TeacherCalendarGetGoToNextClassButtonQuery,
        TeacherCalendarGetGoToNextClassButtonQueryVariables
    >(TeacherCalendarGetGoToNextClassButtonDocument, options);
}
export function useTeacherCalendarGetGoToNextClassButtonSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherCalendarGetGoToNextClassButtonQuery,
        TeacherCalendarGetGoToNextClassButtonQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherCalendarGetGoToNextClassButtonQuery,
        TeacherCalendarGetGoToNextClassButtonQueryVariables
    >(TeacherCalendarGetGoToNextClassButtonDocument, options);
}
export type TeacherCalendarGetGoToNextClassButtonQueryHookResult = ReturnType<
    typeof useTeacherCalendarGetGoToNextClassButtonQuery
>;
export type TeacherCalendarGetGoToNextClassButtonLazyQueryHookResult = ReturnType<
    typeof useTeacherCalendarGetGoToNextClassButtonLazyQuery
>;
export type TeacherCalendarGetGoToNextClassButtonSuspenseQueryHookResult = ReturnType<
    typeof useTeacherCalendarGetGoToNextClassButtonSuspenseQuery
>;
export type TeacherCalendarGetGoToNextClassButtonQueryResult = Apollo.QueryResult<
    TeacherCalendarGetGoToNextClassButtonQuery,
    TeacherCalendarGetGoToNextClassButtonQueryVariables
>;
export const TeacherOpenLogsDocument = gql`
    query teacherOpenLogs {
        teacherOpenLogs {
            ...teacherInvoiceClass
        }
    }
    ${TeacherInvoiceClassFragmentDoc}
`;

/**
 * __useTeacherOpenLogsQuery__
 *
 * To run a query within a React component, call `useTeacherOpenLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherOpenLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherOpenLogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherOpenLogsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherOpenLogsQuery,
        TeacherOpenLogsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TeacherOpenLogsQuery, TeacherOpenLogsQueryVariables>(
        TeacherOpenLogsDocument,
        options,
    );
}
export function useTeacherOpenLogsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherOpenLogsQuery,
        TeacherOpenLogsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TeacherOpenLogsQuery, TeacherOpenLogsQueryVariables>(
        TeacherOpenLogsDocument,
        options,
    );
}
export function useTeacherOpenLogsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherOpenLogsQuery,
        TeacherOpenLogsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<TeacherOpenLogsQuery, TeacherOpenLogsQueryVariables>(
        TeacherOpenLogsDocument,
        options,
    );
}
export type TeacherOpenLogsQueryHookResult = ReturnType<typeof useTeacherOpenLogsQuery>;
export type TeacherOpenLogsLazyQueryHookResult = ReturnType<typeof useTeacherOpenLogsLazyQuery>;
export type TeacherOpenLogsSuspenseQueryHookResult = ReturnType<
    typeof useTeacherOpenLogsSuspenseQuery
>;
export type TeacherOpenLogsQueryResult = Apollo.QueryResult<
    TeacherOpenLogsQuery,
    TeacherOpenLogsQueryVariables
>;
export const TeacherSubmittedLogsDocument = gql`
    query teacherSubmittedLogs($limit: Int!, $offset: Int) {
        teacherSubmittedLogs(limit: $limit, offset: $offset) {
            ...teacherSubmittedMonth
        }
    }
    ${TeacherSubmittedMonthFragmentDoc}
`;

/**
 * __useTeacherSubmittedLogsQuery__
 *
 * To run a query within a React component, call `useTeacherSubmittedLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherSubmittedLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherSubmittedLogsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useTeacherSubmittedLogsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        TeacherSubmittedLogsQuery,
        TeacherSubmittedLogsQueryVariables
    > &
        ({ variables: TeacherSubmittedLogsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TeacherSubmittedLogsQuery, TeacherSubmittedLogsQueryVariables>(
        TeacherSubmittedLogsDocument,
        options,
    );
}
export function useTeacherSubmittedLogsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherSubmittedLogsQuery,
        TeacherSubmittedLogsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        TeacherSubmittedLogsQuery,
        TeacherSubmittedLogsQueryVariables
    >(TeacherSubmittedLogsDocument, options);
}
export function useTeacherSubmittedLogsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherSubmittedLogsQuery,
        TeacherSubmittedLogsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherSubmittedLogsQuery,
        TeacherSubmittedLogsQueryVariables
    >(TeacherSubmittedLogsDocument, options);
}
export type TeacherSubmittedLogsQueryHookResult = ReturnType<typeof useTeacherSubmittedLogsQuery>;
export type TeacherSubmittedLogsLazyQueryHookResult = ReturnType<
    typeof useTeacherSubmittedLogsLazyQuery
>;
export type TeacherSubmittedLogsSuspenseQueryHookResult = ReturnType<
    typeof useTeacherSubmittedLogsSuspenseQuery
>;
export type TeacherSubmittedLogsQueryResult = Apollo.QueryResult<
    TeacherSubmittedLogsQuery,
    TeacherSubmittedLogsQueryVariables
>;
export const TeacherInvoicesDocument = gql`
    query teacherInvoices($limit: Int!, $offset: Int) {
        teacherInvoices(limit: $limit, offset: $offset) {
            ...teacherInvoice
        }
    }
    ${TeacherInvoiceFragmentDoc}
`;

/**
 * __useTeacherInvoicesQuery__
 *
 * To run a query within a React component, call `useTeacherInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherInvoicesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useTeacherInvoicesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        TeacherInvoicesQuery,
        TeacherInvoicesQueryVariables
    > &
        ({ variables: TeacherInvoicesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TeacherInvoicesQuery, TeacherInvoicesQueryVariables>(
        TeacherInvoicesDocument,
        options,
    );
}
export function useTeacherInvoicesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherInvoicesQuery,
        TeacherInvoicesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TeacherInvoicesQuery, TeacherInvoicesQueryVariables>(
        TeacherInvoicesDocument,
        options,
    );
}
export function useTeacherInvoicesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherInvoicesQuery,
        TeacherInvoicesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<TeacherInvoicesQuery, TeacherInvoicesQueryVariables>(
        TeacherInvoicesDocument,
        options,
    );
}
export type TeacherInvoicesQueryHookResult = ReturnType<typeof useTeacherInvoicesQuery>;
export type TeacherInvoicesLazyQueryHookResult = ReturnType<typeof useTeacherInvoicesLazyQuery>;
export type TeacherInvoicesSuspenseQueryHookResult = ReturnType<
    typeof useTeacherInvoicesSuspenseQuery
>;
export type TeacherInvoicesQueryResult = Apollo.QueryResult<
    TeacherInvoicesQuery,
    TeacherInvoicesQueryVariables
>;
export const SaveTeacherAppointmentLogDocument = gql`
    mutation saveTeacherAppointmentLog(
        $classId: String!
        $studentId: Int!
        $isAttended: Boolean!
        $answerIds: [Ulid!]!
        $feedback: String
        $recommendedLevel: Int
    ) {
        teacherLogAppointment(
            classId: $classId
            studentId: $studentId
            isAttended: $isAttended
            answerIds: $answerIds
            feedback: $feedback
            recommendedLevel: $recommendedLevel
        ) {
            ...TeacherClass
        }
    }
    ${TeacherClassFragmentDoc}
`;
export type SaveTeacherAppointmentLogMutationFn = Apollo.MutationFunction<
    SaveTeacherAppointmentLogMutation,
    SaveTeacherAppointmentLogMutationVariables
>;

/**
 * __useSaveTeacherAppointmentLogMutation__
 *
 * To run a mutation, you first call `useSaveTeacherAppointmentLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTeacherAppointmentLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTeacherAppointmentLogMutation, { data, loading, error }] = useSaveTeacherAppointmentLogMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      studentId: // value for 'studentId'
 *      isAttended: // value for 'isAttended'
 *      answerIds: // value for 'answerIds'
 *      feedback: // value for 'feedback'
 *      recommendedLevel: // value for 'recommendedLevel'
 *   },
 * });
 */
export function useSaveTeacherAppointmentLogMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SaveTeacherAppointmentLogMutation,
        SaveTeacherAppointmentLogMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SaveTeacherAppointmentLogMutation,
        SaveTeacherAppointmentLogMutationVariables
    >(SaveTeacherAppointmentLogDocument, options);
}
export type SaveTeacherAppointmentLogMutationHookResult = ReturnType<
    typeof useSaveTeacherAppointmentLogMutation
>;
export type SaveTeacherAppointmentLogMutationResult =
    Apollo.MutationResult<SaveTeacherAppointmentLogMutation>;
export type SaveTeacherAppointmentLogMutationOptions = Apollo.BaseMutationOptions<
    SaveTeacherAppointmentLogMutation,
    SaveTeacherAppointmentLogMutationVariables
>;
export const SubmitTeacherClassLogDocument = gql`
    mutation submitTeacherClassLog($classId: String!, $logMessage: String!) {
        teacherSubmitClassLog(classId: $classId, logMessage: $logMessage) {
            ...TeacherClass
        }
    }
    ${TeacherClassFragmentDoc}
`;
export type SubmitTeacherClassLogMutationFn = Apollo.MutationFunction<
    SubmitTeacherClassLogMutation,
    SubmitTeacherClassLogMutationVariables
>;

/**
 * __useSubmitTeacherClassLogMutation__
 *
 * To run a mutation, you first call `useSubmitTeacherClassLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitTeacherClassLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitTeacherClassLogMutation, { data, loading, error }] = useSubmitTeacherClassLogMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      logMessage: // value for 'logMessage'
 *   },
 * });
 */
export function useSubmitTeacherClassLogMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SubmitTeacherClassLogMutation,
        SubmitTeacherClassLogMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SubmitTeacherClassLogMutation,
        SubmitTeacherClassLogMutationVariables
    >(SubmitTeacherClassLogDocument, options);
}
export type SubmitTeacherClassLogMutationHookResult = ReturnType<
    typeof useSubmitTeacherClassLogMutation
>;
export type SubmitTeacherClassLogMutationResult =
    Apollo.MutationResult<SubmitTeacherClassLogMutation>;
export type SubmitTeacherClassLogMutationOptions = Apollo.BaseMutationOptions<
    SubmitTeacherClassLogMutation,
    SubmitTeacherClassLogMutationVariables
>;
export const ConfirmCancelClassDocument = gql`
    mutation confirmCancelClass($classId: String!) {
        teacherCancelClass(classId: $classId)
    }
`;
export type ConfirmCancelClassMutationFn = Apollo.MutationFunction<
    ConfirmCancelClassMutation,
    ConfirmCancelClassMutationVariables
>;

/**
 * __useConfirmCancelClassMutation__
 *
 * To run a mutation, you first call `useConfirmCancelClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmCancelClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmCancelClassMutation, { data, loading, error }] = useConfirmCancelClassMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useConfirmCancelClassMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        ConfirmCancelClassMutation,
        ConfirmCancelClassMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        ConfirmCancelClassMutation,
        ConfirmCancelClassMutationVariables
    >(ConfirmCancelClassDocument, options);
}
export type ConfirmCancelClassMutationHookResult = ReturnType<typeof useConfirmCancelClassMutation>;
export type ConfirmCancelClassMutationResult = Apollo.MutationResult<ConfirmCancelClassMutation>;
export type ConfirmCancelClassMutationOptions = Apollo.BaseMutationOptions<
    ConfirmCancelClassMutation,
    ConfirmCancelClassMutationVariables
>;
export const ConfirmNoStudentsShowedUpDocument = gql`
    mutation confirmNoStudentsShowedUp($classId: String!) {
        teacherLogNoShow(classId: $classId) {
            ...TeacherClass
        }
    }
    ${TeacherClassFragmentDoc}
`;
export type ConfirmNoStudentsShowedUpMutationFn = Apollo.MutationFunction<
    ConfirmNoStudentsShowedUpMutation,
    ConfirmNoStudentsShowedUpMutationVariables
>;

/**
 * __useConfirmNoStudentsShowedUpMutation__
 *
 * To run a mutation, you first call `useConfirmNoStudentsShowedUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmNoStudentsShowedUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmNoStudentsShowedUpMutation, { data, loading, error }] = useConfirmNoStudentsShowedUpMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useConfirmNoStudentsShowedUpMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        ConfirmNoStudentsShowedUpMutation,
        ConfirmNoStudentsShowedUpMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        ConfirmNoStudentsShowedUpMutation,
        ConfirmNoStudentsShowedUpMutationVariables
    >(ConfirmNoStudentsShowedUpDocument, options);
}
export type ConfirmNoStudentsShowedUpMutationHookResult = ReturnType<
    typeof useConfirmNoStudentsShowedUpMutation
>;
export type ConfirmNoStudentsShowedUpMutationResult =
    Apollo.MutationResult<ConfirmNoStudentsShowedUpMutation>;
export type ConfirmNoStudentsShowedUpMutationOptions = Apollo.BaseMutationOptions<
    ConfirmNoStudentsShowedUpMutation,
    ConfirmNoStudentsShowedUpMutationVariables
>;
export const TeacherLogTechnicalProblemDocument = gql`
    mutation teacherLogTechnicalProblem(
        $classId: String!
        $description: String!
        $screenshots: [Upload!]!
    ) {
        teacherLogTechnicalProblem(
            classId: $classId
            description: $description
            screenshots: $screenshots
        ) {
            ...TeacherClass
        }
    }
    ${TeacherClassFragmentDoc}
`;
export type TeacherLogTechnicalProblemMutationFn = Apollo.MutationFunction<
    TeacherLogTechnicalProblemMutation,
    TeacherLogTechnicalProblemMutationVariables
>;

/**
 * __useTeacherLogTechnicalProblemMutation__
 *
 * To run a mutation, you first call `useTeacherLogTechnicalProblemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherLogTechnicalProblemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherLogTechnicalProblemMutation, { data, loading, error }] = useTeacherLogTechnicalProblemMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      description: // value for 'description'
 *      screenshots: // value for 'screenshots'
 *   },
 * });
 */
export function useTeacherLogTechnicalProblemMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        TeacherLogTechnicalProblemMutation,
        TeacherLogTechnicalProblemMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        TeacherLogTechnicalProblemMutation,
        TeacherLogTechnicalProblemMutationVariables
    >(TeacherLogTechnicalProblemDocument, options);
}
export type TeacherLogTechnicalProblemMutationHookResult = ReturnType<
    typeof useTeacherLogTechnicalProblemMutation
>;
export type TeacherLogTechnicalProblemMutationResult =
    Apollo.MutationResult<TeacherLogTechnicalProblemMutation>;
export type TeacherLogTechnicalProblemMutationOptions = Apollo.BaseMutationOptions<
    TeacherLogTechnicalProblemMutation,
    TeacherLogTechnicalProblemMutationVariables
>;
export const SubmitTeacherMissedClassReportDocument = gql`
    mutation submitTeacherMissedClassReport(
        $classId: String!
        $reason: MissedStatusReason!
        $details: String
    ) {
        iMissedClass(classId: $classId, reason: $reason, details: $details)
    }
`;
export type SubmitTeacherMissedClassReportMutationFn = Apollo.MutationFunction<
    SubmitTeacherMissedClassReportMutation,
    SubmitTeacherMissedClassReportMutationVariables
>;

/**
 * __useSubmitTeacherMissedClassReportMutation__
 *
 * To run a mutation, you first call `useSubmitTeacherMissedClassReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitTeacherMissedClassReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitTeacherMissedClassReportMutation, { data, loading, error }] = useSubmitTeacherMissedClassReportMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      reason: // value for 'reason'
 *      details: // value for 'details'
 *   },
 * });
 */
export function useSubmitTeacherMissedClassReportMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SubmitTeacherMissedClassReportMutation,
        SubmitTeacherMissedClassReportMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SubmitTeacherMissedClassReportMutation,
        SubmitTeacherMissedClassReportMutationVariables
    >(SubmitTeacherMissedClassReportDocument, options);
}
export type SubmitTeacherMissedClassReportMutationHookResult = ReturnType<
    typeof useSubmitTeacherMissedClassReportMutation
>;
export type SubmitTeacherMissedClassReportMutationResult =
    Apollo.MutationResult<SubmitTeacherMissedClassReportMutation>;
export type SubmitTeacherMissedClassReportMutationOptions = Apollo.BaseMutationOptions<
    SubmitTeacherMissedClassReportMutation,
    SubmitTeacherMissedClassReportMutationVariables
>;
export const StudentModulesDocument = gql`
    query studentModules($studentId: Int, $curriculumId: Int, $section: SectionName) {
        studentModules(studentId: $studentId, curriculumId: $curriculumId, sectionName: $section) {
            id
            name
            cefrLevelName
        }
    }
`;

/**
 * __useStudentModulesQuery__
 *
 * To run a query within a React component, call `useStudentModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentModulesQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      curriculumId: // value for 'curriculumId'
 *      section: // value for 'section'
 *   },
 * });
 */
export function useStudentModulesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        StudentModulesQuery,
        StudentModulesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<StudentModulesQuery, StudentModulesQueryVariables>(
        StudentModulesDocument,
        options,
    );
}
export function useStudentModulesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        StudentModulesQuery,
        StudentModulesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<StudentModulesQuery, StudentModulesQueryVariables>(
        StudentModulesDocument,
        options,
    );
}
export function useStudentModulesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        StudentModulesQuery,
        StudentModulesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<StudentModulesQuery, StudentModulesQueryVariables>(
        StudentModulesDocument,
        options,
    );
}
export type StudentModulesQueryHookResult = ReturnType<typeof useStudentModulesQuery>;
export type StudentModulesLazyQueryHookResult = ReturnType<typeof useStudentModulesLazyQuery>;
export type StudentModulesSuspenseQueryHookResult = ReturnType<
    typeof useStudentModulesSuspenseQuery
>;
export type StudentModulesQueryResult = Apollo.QueryResult<
    StudentModulesQuery,
    StudentModulesQueryVariables
>;
export const TeacherCancelClassObservationDocument = gql`
    mutation teacherCancelClassObservation($classId: String!) {
        teacherCancelClassObservation(classId: $classId) {
            status
        }
    }
`;
export type TeacherCancelClassObservationMutationFn = Apollo.MutationFunction<
    TeacherCancelClassObservationMutation,
    TeacherCancelClassObservationMutationVariables
>;

/**
 * __useTeacherCancelClassObservationMutation__
 *
 * To run a mutation, you first call `useTeacherCancelClassObservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherCancelClassObservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherCancelClassObservationMutation, { data, loading, error }] = useTeacherCancelClassObservationMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useTeacherCancelClassObservationMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        TeacherCancelClassObservationMutation,
        TeacherCancelClassObservationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        TeacherCancelClassObservationMutation,
        TeacherCancelClassObservationMutationVariables
    >(TeacherCancelClassObservationDocument, options);
}
export type TeacherCancelClassObservationMutationHookResult = ReturnType<
    typeof useTeacherCancelClassObservationMutation
>;
export type TeacherCancelClassObservationMutationResult =
    Apollo.MutationResult<TeacherCancelClassObservationMutation>;
export type TeacherCancelClassObservationMutationOptions = Apollo.BaseMutationOptions<
    TeacherCancelClassObservationMutation,
    TeacherCancelClassObservationMutationVariables
>;
export const SubmitKysFeedbackDocument = gql`
    mutation SubmitKysFeedback($classId: String!, $isKysUseful: Boolean!) {
        submitKysFeedback(classId: $classId, isKysUseful: $isKysUseful) {
            id
        }
    }
`;
export type SubmitKysFeedbackMutationFn = Apollo.MutationFunction<
    SubmitKysFeedbackMutation,
    SubmitKysFeedbackMutationVariables
>;

/**
 * __useSubmitKysFeedbackMutation__
 *
 * To run a mutation, you first call `useSubmitKysFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitKysFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitKysFeedbackMutation, { data, loading, error }] = useSubmitKysFeedbackMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      isKysUseful: // value for 'isKysUseful'
 *   },
 * });
 */
export function useSubmitKysFeedbackMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SubmitKysFeedbackMutation,
        SubmitKysFeedbackMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SubmitKysFeedbackMutation,
        SubmitKysFeedbackMutationVariables
    >(SubmitKysFeedbackDocument, options);
}
export type SubmitKysFeedbackMutationHookResult = ReturnType<typeof useSubmitKysFeedbackMutation>;
export type SubmitKysFeedbackMutationResult = Apollo.MutationResult<SubmitKysFeedbackMutation>;
export type SubmitKysFeedbackMutationOptions = Apollo.BaseMutationOptions<
    SubmitKysFeedbackMutation,
    SubmitKysFeedbackMutationVariables
>;
export const KysFeedbackDocument = gql`
    query KysFeedback($classId: String!) {
        teacherClass(classId: $classId) {
            uid
            prepareClassInfo {
                ...KysFeedback
            }
        }
    }
    ${KysFeedbackFragmentDoc}
`;

/**
 * __useKysFeedbackQuery__
 *
 * To run a query within a React component, call `useKysFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useKysFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKysFeedbackQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useKysFeedbackQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<KysFeedbackQuery, KysFeedbackQueryVariables> &
        ({ variables: KysFeedbackQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<KysFeedbackQuery, KysFeedbackQueryVariables>(
        KysFeedbackDocument,
        options,
    );
}
export function useKysFeedbackLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        KysFeedbackQuery,
        KysFeedbackQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<KysFeedbackQuery, KysFeedbackQueryVariables>(
        KysFeedbackDocument,
        options,
    );
}
export function useKysFeedbackSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        KysFeedbackQuery,
        KysFeedbackQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<KysFeedbackQuery, KysFeedbackQueryVariables>(
        KysFeedbackDocument,
        options,
    );
}
export type KysFeedbackQueryHookResult = ReturnType<typeof useKysFeedbackQuery>;
export type KysFeedbackLazyQueryHookResult = ReturnType<typeof useKysFeedbackLazyQuery>;
export type KysFeedbackSuspenseQueryHookResult = ReturnType<typeof useKysFeedbackSuspenseQuery>;
export type KysFeedbackQueryResult = Apollo.QueryResult<
    KysFeedbackQuery,
    KysFeedbackQueryVariables
>;
export const SubmitPresentationFeedbackDocument = gql`
    mutation submitPresentationFeedback($classId: String!, $feedback: String!) {
        submitPresentationFeedback(classId: $classId, feedback: $feedback)
    }
`;
export type SubmitPresentationFeedbackMutationFn = Apollo.MutationFunction<
    SubmitPresentationFeedbackMutation,
    SubmitPresentationFeedbackMutationVariables
>;

/**
 * __useSubmitPresentationFeedbackMutation__
 *
 * To run a mutation, you first call `useSubmitPresentationFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPresentationFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPresentationFeedbackMutation, { data, loading, error }] = useSubmitPresentationFeedbackMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useSubmitPresentationFeedbackMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SubmitPresentationFeedbackMutation,
        SubmitPresentationFeedbackMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SubmitPresentationFeedbackMutation,
        SubmitPresentationFeedbackMutationVariables
    >(SubmitPresentationFeedbackDocument, options);
}
export type SubmitPresentationFeedbackMutationHookResult = ReturnType<
    typeof useSubmitPresentationFeedbackMutation
>;
export type SubmitPresentationFeedbackMutationResult =
    Apollo.MutationResult<SubmitPresentationFeedbackMutation>;
export type SubmitPresentationFeedbackMutationOptions = Apollo.BaseMutationOptions<
    SubmitPresentationFeedbackMutation,
    SubmitPresentationFeedbackMutationVariables
>;
export const TeacherClassDocument = gql`
    query TeacherClass($classId: String!) {
        teacherClass(classId: $classId) {
            ...TeacherClass
        }
        teacherClassAppointmentLogQuestions(classId: $classId) {
            ...AppointmentLogQuestion
        }
        teacherClassObservation(classId: $classId) {
            id
            observerFullName
            status
        }
    }
    ${TeacherClassFragmentDoc}
    ${AppointmentLogQuestionFragmentDoc}
`;

/**
 * __useTeacherClassQuery__
 *
 * To run a query within a React component, call `useTeacherClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherClassQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useTeacherClassQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<TeacherClassQuery, TeacherClassQueryVariables> &
        ({ variables: TeacherClassQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TeacherClassQuery, TeacherClassQueryVariables>(
        TeacherClassDocument,
        options,
    );
}
export function useTeacherClassLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherClassQuery,
        TeacherClassQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TeacherClassQuery, TeacherClassQueryVariables>(
        TeacherClassDocument,
        options,
    );
}
export function useTeacherClassSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherClassQuery,
        TeacherClassQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<TeacherClassQuery, TeacherClassQueryVariables>(
        TeacherClassDocument,
        options,
    );
}
export type TeacherClassQueryHookResult = ReturnType<typeof useTeacherClassQuery>;
export type TeacherClassLazyQueryHookResult = ReturnType<typeof useTeacherClassLazyQuery>;
export type TeacherClassSuspenseQueryHookResult = ReturnType<typeof useTeacherClassSuspenseQuery>;
export type TeacherClassQueryResult = Apollo.QueryResult<
    TeacherClassQuery,
    TeacherClassQueryVariables
>;
export const StudentAppointmentsDatesDocument = gql`
    query StudentAppointmentsDates(
        $studentId: Int!
        $fromDate: DateTime = null
        $toDate: DateTime = null
        $sort: String = "ASC"
        $appointmentStatus: String = null
        $appointmentStatuses: [String!] = []
    ) {
        studentAppointmentDates(
            studentId: $studentId
            fromDate: $fromDate
            toDate: $toDate
            sort: $sort
            appointmentStatus: $appointmentStatus
            appointmentStatuses: $appointmentStatuses
        )
    }
`;

/**
 * __useStudentAppointmentsDatesQuery__
 *
 * To run a query within a React component, call `useStudentAppointmentsDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentAppointmentsDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentAppointmentsDatesQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      sort: // value for 'sort'
 *      appointmentStatus: // value for 'appointmentStatus'
 *      appointmentStatuses: // value for 'appointmentStatuses'
 *   },
 * });
 */
export function useStudentAppointmentsDatesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        StudentAppointmentsDatesQuery,
        StudentAppointmentsDatesQueryVariables
    > &
        ({ variables: StudentAppointmentsDatesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        StudentAppointmentsDatesQuery,
        StudentAppointmentsDatesQueryVariables
    >(StudentAppointmentsDatesDocument, options);
}
export function useStudentAppointmentsDatesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        StudentAppointmentsDatesQuery,
        StudentAppointmentsDatesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        StudentAppointmentsDatesQuery,
        StudentAppointmentsDatesQueryVariables
    >(StudentAppointmentsDatesDocument, options);
}
export function useStudentAppointmentsDatesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        StudentAppointmentsDatesQuery,
        StudentAppointmentsDatesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        StudentAppointmentsDatesQuery,
        StudentAppointmentsDatesQueryVariables
    >(StudentAppointmentsDatesDocument, options);
}
export type StudentAppointmentsDatesQueryHookResult = ReturnType<
    typeof useStudentAppointmentsDatesQuery
>;
export type StudentAppointmentsDatesLazyQueryHookResult = ReturnType<
    typeof useStudentAppointmentsDatesLazyQuery
>;
export type StudentAppointmentsDatesSuspenseQueryHookResult = ReturnType<
    typeof useStudentAppointmentsDatesSuspenseQuery
>;
export type StudentAppointmentsDatesQueryResult = Apollo.QueryResult<
    StudentAppointmentsDatesQuery,
    StudentAppointmentsDatesQueryVariables
>;
export const TeacherBookCourseDocument = gql`
    mutation teacherBookCourse($courseId: Ulid!) {
        teacherBookCourse(courseId: $courseId) {
            ...TeacherCoursesCourseAssignResult
        }
    }
    ${TeacherCoursesCourseAssignResultFragmentDoc}
`;
export type TeacherBookCourseMutationFn = Apollo.MutationFunction<
    TeacherBookCourseMutation,
    TeacherBookCourseMutationVariables
>;

/**
 * __useTeacherBookCourseMutation__
 *
 * To run a mutation, you first call `useTeacherBookCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherBookCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherBookCourseMutation, { data, loading, error }] = useTeacherBookCourseMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useTeacherBookCourseMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        TeacherBookCourseMutation,
        TeacherBookCourseMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        TeacherBookCourseMutation,
        TeacherBookCourseMutationVariables
    >(TeacherBookCourseDocument, options);
}
export type TeacherBookCourseMutationHookResult = ReturnType<typeof useTeacherBookCourseMutation>;
export type TeacherBookCourseMutationResult = Apollo.MutationResult<TeacherBookCourseMutation>;
export type TeacherBookCourseMutationOptions = Apollo.BaseMutationOptions<
    TeacherBookCourseMutation,
    TeacherBookCourseMutationVariables
>;
export const TeacherCoursesGetTeacherCoursesDocument = gql`
    query TeacherCoursesGetTeacherCourses {
        teacherAvailableCourses {
            courses {
                ...TeacherCoursesTeacherAvailableCourse
            }
            isPriceVisible
        }
        courseHolidays {
            ...TeacherCoursesCourseHoliday
        }
    }
    ${TeacherCoursesTeacherAvailableCourseFragmentDoc}
    ${TeacherCoursesCourseHolidayFragmentDoc}
`;

/**
 * __useTeacherCoursesGetTeacherCoursesQuery__
 *
 * To run a query within a React component, call `useTeacherCoursesGetTeacherCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherCoursesGetTeacherCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherCoursesGetTeacherCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherCoursesGetTeacherCoursesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherCoursesGetTeacherCoursesQuery,
        TeacherCoursesGetTeacherCoursesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        TeacherCoursesGetTeacherCoursesQuery,
        TeacherCoursesGetTeacherCoursesQueryVariables
    >(TeacherCoursesGetTeacherCoursesDocument, options);
}
export function useTeacherCoursesGetTeacherCoursesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherCoursesGetTeacherCoursesQuery,
        TeacherCoursesGetTeacherCoursesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        TeacherCoursesGetTeacherCoursesQuery,
        TeacherCoursesGetTeacherCoursesQueryVariables
    >(TeacherCoursesGetTeacherCoursesDocument, options);
}
export function useTeacherCoursesGetTeacherCoursesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherCoursesGetTeacherCoursesQuery,
        TeacherCoursesGetTeacherCoursesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherCoursesGetTeacherCoursesQuery,
        TeacherCoursesGetTeacherCoursesQueryVariables
    >(TeacherCoursesGetTeacherCoursesDocument, options);
}
export type TeacherCoursesGetTeacherCoursesQueryHookResult = ReturnType<
    typeof useTeacherCoursesGetTeacherCoursesQuery
>;
export type TeacherCoursesGetTeacherCoursesLazyQueryHookResult = ReturnType<
    typeof useTeacherCoursesGetTeacherCoursesLazyQuery
>;
export type TeacherCoursesGetTeacherCoursesSuspenseQueryHookResult = ReturnType<
    typeof useTeacherCoursesGetTeacherCoursesSuspenseQuery
>;
export type TeacherCoursesGetTeacherCoursesQueryResult = Apollo.QueryResult<
    TeacherCoursesGetTeacherCoursesQuery,
    TeacherCoursesGetTeacherCoursesQueryVariables
>;
export const TeacherCoursesGetTeacherTagDocument = gql`
    query TeacherCoursesGetTeacherTag {
        teacher {
            id
            section {
                name
            }
            teacherInfo {
                isB2G
            }
        }
    }
`;

/**
 * __useTeacherCoursesGetTeacherTagQuery__
 *
 * To run a query within a React component, call `useTeacherCoursesGetTeacherTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherCoursesGetTeacherTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherCoursesGetTeacherTagQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherCoursesGetTeacherTagQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherCoursesGetTeacherTagQuery,
        TeacherCoursesGetTeacherTagQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        TeacherCoursesGetTeacherTagQuery,
        TeacherCoursesGetTeacherTagQueryVariables
    >(TeacherCoursesGetTeacherTagDocument, options);
}
export function useTeacherCoursesGetTeacherTagLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherCoursesGetTeacherTagQuery,
        TeacherCoursesGetTeacherTagQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        TeacherCoursesGetTeacherTagQuery,
        TeacherCoursesGetTeacherTagQueryVariables
    >(TeacherCoursesGetTeacherTagDocument, options);
}
export function useTeacherCoursesGetTeacherTagSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherCoursesGetTeacherTagQuery,
        TeacherCoursesGetTeacherTagQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherCoursesGetTeacherTagQuery,
        TeacherCoursesGetTeacherTagQueryVariables
    >(TeacherCoursesGetTeacherTagDocument, options);
}
export type TeacherCoursesGetTeacherTagQueryHookResult = ReturnType<
    typeof useTeacherCoursesGetTeacherTagQuery
>;
export type TeacherCoursesGetTeacherTagLazyQueryHookResult = ReturnType<
    typeof useTeacherCoursesGetTeacherTagLazyQuery
>;
export type TeacherCoursesGetTeacherTagSuspenseQueryHookResult = ReturnType<
    typeof useTeacherCoursesGetTeacherTagSuspenseQuery
>;
export type TeacherCoursesGetTeacherTagQueryResult = Apollo.QueryResult<
    TeacherCoursesGetTeacherTagQuery,
    TeacherCoursesGetTeacherTagQueryVariables
>;
export const TeacherLearningMaterialsDocument = gql`
    query teacherLearningMaterials($limit: Int!, $page: Int! = 1) {
        teacherLearningMaterials(limit: $limit, page: $page) {
            items {
                id
                name
                longName
                code
                description
                cefrLevelName
                learningUnits {
                    ...learningUnit
                }
            }
            hasMore
            currentPage
        }
    }
    ${LearningUnitFragmentDoc}
`;

/**
 * __useTeacherLearningMaterialsQuery__
 *
 * To run a query within a React component, call `useTeacherLearningMaterialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherLearningMaterialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherLearningMaterialsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useTeacherLearningMaterialsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        TeacherLearningMaterialsQuery,
        TeacherLearningMaterialsQueryVariables
    > &
        ({ variables: TeacherLearningMaterialsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        TeacherLearningMaterialsQuery,
        TeacherLearningMaterialsQueryVariables
    >(TeacherLearningMaterialsDocument, options);
}
export function useTeacherLearningMaterialsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherLearningMaterialsQuery,
        TeacherLearningMaterialsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        TeacherLearningMaterialsQuery,
        TeacherLearningMaterialsQueryVariables
    >(TeacherLearningMaterialsDocument, options);
}
export function useTeacherLearningMaterialsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherLearningMaterialsQuery,
        TeacherLearningMaterialsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherLearningMaterialsQuery,
        TeacherLearningMaterialsQueryVariables
    >(TeacherLearningMaterialsDocument, options);
}
export type TeacherLearningMaterialsQueryHookResult = ReturnType<
    typeof useTeacherLearningMaterialsQuery
>;
export type TeacherLearningMaterialsLazyQueryHookResult = ReturnType<
    typeof useTeacherLearningMaterialsLazyQuery
>;
export type TeacherLearningMaterialsSuspenseQueryHookResult = ReturnType<
    typeof useTeacherLearningMaterialsSuspenseQuery
>;
export type TeacherLearningMaterialsQueryResult = Apollo.QueryResult<
    TeacherLearningMaterialsQuery,
    TeacherLearningMaterialsQueryVariables
>;
export const TeacherSetIsOnboardedDocument = gql`
    mutation TeacherSetIsOnboarded {
        teacherSetIsOnboarded
    }
`;
export type TeacherSetIsOnboardedMutationFn = Apollo.MutationFunction<
    TeacherSetIsOnboardedMutation,
    TeacherSetIsOnboardedMutationVariables
>;

/**
 * __useTeacherSetIsOnboardedMutation__
 *
 * To run a mutation, you first call `useTeacherSetIsOnboardedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherSetIsOnboardedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherSetIsOnboardedMutation, { data, loading, error }] = useTeacherSetIsOnboardedMutation({
 *   variables: {
 *   },
 * });
 */
export function useTeacherSetIsOnboardedMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        TeacherSetIsOnboardedMutation,
        TeacherSetIsOnboardedMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        TeacherSetIsOnboardedMutation,
        TeacherSetIsOnboardedMutationVariables
    >(TeacherSetIsOnboardedDocument, options);
}
export type TeacherSetIsOnboardedMutationHookResult = ReturnType<
    typeof useTeacherSetIsOnboardedMutation
>;
export type TeacherSetIsOnboardedMutationResult =
    Apollo.MutationResult<TeacherSetIsOnboardedMutation>;
export type TeacherSetIsOnboardedMutationOptions = Apollo.BaseMutationOptions<
    TeacherSetIsOnboardedMutation,
    TeacherSetIsOnboardedMutationVariables
>;
export const TeacherGetIsOnboardedDocument = gql`
    query TeacherGetIsOnboarded {
        teacher {
            id
            teacherInfo {
                isOnboarded
                isB2G
            }
        }
    }
`;

/**
 * __useTeacherGetIsOnboardedQuery__
 *
 * To run a query within a React component, call `useTeacherGetIsOnboardedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherGetIsOnboardedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherGetIsOnboardedQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherGetIsOnboardedQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherGetIsOnboardedQuery,
        TeacherGetIsOnboardedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        TeacherGetIsOnboardedQuery,
        TeacherGetIsOnboardedQueryVariables
    >(TeacherGetIsOnboardedDocument, options);
}
export function useTeacherGetIsOnboardedLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherGetIsOnboardedQuery,
        TeacherGetIsOnboardedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        TeacherGetIsOnboardedQuery,
        TeacherGetIsOnboardedQueryVariables
    >(TeacherGetIsOnboardedDocument, options);
}
export function useTeacherGetIsOnboardedSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherGetIsOnboardedQuery,
        TeacherGetIsOnboardedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherGetIsOnboardedQuery,
        TeacherGetIsOnboardedQueryVariables
    >(TeacherGetIsOnboardedDocument, options);
}
export type TeacherGetIsOnboardedQueryHookResult = ReturnType<typeof useTeacherGetIsOnboardedQuery>;
export type TeacherGetIsOnboardedLazyQueryHookResult = ReturnType<
    typeof useTeacherGetIsOnboardedLazyQuery
>;
export type TeacherGetIsOnboardedSuspenseQueryHookResult = ReturnType<
    typeof useTeacherGetIsOnboardedSuspenseQuery
>;
export type TeacherGetIsOnboardedQueryResult = Apollo.QueryResult<
    TeacherGetIsOnboardedQuery,
    TeacherGetIsOnboardedQueryVariables
>;
export const TeacherGetAccountDocument = gql`
    query TeacherGetAccount {
        teacher {
            ...TeacherAccountData
        }
    }
    ${TeacherAccountDataFragmentDoc}
`;

/**
 * __useTeacherGetAccountQuery__
 *
 * To run a query within a React component, call `useTeacherGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherGetAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherGetAccountQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherGetAccountQuery,
        TeacherGetAccountQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TeacherGetAccountQuery, TeacherGetAccountQueryVariables>(
        TeacherGetAccountDocument,
        options,
    );
}
export function useTeacherGetAccountLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherGetAccountQuery,
        TeacherGetAccountQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TeacherGetAccountQuery, TeacherGetAccountQueryVariables>(
        TeacherGetAccountDocument,
        options,
    );
}
export function useTeacherGetAccountSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherGetAccountQuery,
        TeacherGetAccountQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherGetAccountQuery,
        TeacherGetAccountQueryVariables
    >(TeacherGetAccountDocument, options);
}
export type TeacherGetAccountQueryHookResult = ReturnType<typeof useTeacherGetAccountQuery>;
export type TeacherGetAccountLazyQueryHookResult = ReturnType<typeof useTeacherGetAccountLazyQuery>;
export type TeacherGetAccountSuspenseQueryHookResult = ReturnType<
    typeof useTeacherGetAccountSuspenseQuery
>;
export type TeacherGetAccountQueryResult = Apollo.QueryResult<
    TeacherGetAccountQuery,
    TeacherGetAccountQueryVariables
>;
export const TeacherUploadCertificateDocument = gql`
    mutation TeacherUploadCertificate($type: CertificateType!, $certificates: [Upload!]!) {
        teacherUploadCertificate(type: $type, certificates: $certificates) {
            ...TeacherProfileCertificate
        }
    }
    ${TeacherProfileCertificateFragmentDoc}
`;
export type TeacherUploadCertificateMutationFn = Apollo.MutationFunction<
    TeacherUploadCertificateMutation,
    TeacherUploadCertificateMutationVariables
>;

/**
 * __useTeacherUploadCertificateMutation__
 *
 * To run a mutation, you first call `useTeacherUploadCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherUploadCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherUploadCertificateMutation, { data, loading, error }] = useTeacherUploadCertificateMutation({
 *   variables: {
 *      type: // value for 'type'
 *      certificates: // value for 'certificates'
 *   },
 * });
 */
export function useTeacherUploadCertificateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        TeacherUploadCertificateMutation,
        TeacherUploadCertificateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        TeacherUploadCertificateMutation,
        TeacherUploadCertificateMutationVariables
    >(TeacherUploadCertificateDocument, options);
}
export type TeacherUploadCertificateMutationHookResult = ReturnType<
    typeof useTeacherUploadCertificateMutation
>;
export type TeacherUploadCertificateMutationResult =
    Apollo.MutationResult<TeacherUploadCertificateMutation>;
export type TeacherUploadCertificateMutationOptions = Apollo.BaseMutationOptions<
    TeacherUploadCertificateMutation,
    TeacherUploadCertificateMutationVariables
>;
export const TeacherRemoveCertificateDocument = gql`
    mutation TeacherRemoveCertificate($id: String!) {
        teacherRemoveCertificate(id: $id)
    }
`;
export type TeacherRemoveCertificateMutationFn = Apollo.MutationFunction<
    TeacherRemoveCertificateMutation,
    TeacherRemoveCertificateMutationVariables
>;

/**
 * __useTeacherRemoveCertificateMutation__
 *
 * To run a mutation, you first call `useTeacherRemoveCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherRemoveCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherRemoveCertificateMutation, { data, loading, error }] = useTeacherRemoveCertificateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeacherRemoveCertificateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        TeacherRemoveCertificateMutation,
        TeacherRemoveCertificateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        TeacherRemoveCertificateMutation,
        TeacherRemoveCertificateMutationVariables
    >(TeacherRemoveCertificateDocument, options);
}
export type TeacherRemoveCertificateMutationHookResult = ReturnType<
    typeof useTeacherRemoveCertificateMutation
>;
export type TeacherRemoveCertificateMutationResult =
    Apollo.MutationResult<TeacherRemoveCertificateMutation>;
export type TeacherRemoveCertificateMutationOptions = Apollo.BaseMutationOptions<
    TeacherRemoveCertificateMutation,
    TeacherRemoveCertificateMutationVariables
>;
export const TeacherCertificateDocument = gql`
    query TeacherCertificate {
        teacherCertificates {
            ...TeacherProfileCertificate
        }
    }
    ${TeacherProfileCertificateFragmentDoc}
`;

/**
 * __useTeacherCertificateQuery__
 *
 * To run a query within a React component, call `useTeacherCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherCertificateQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherCertificateQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherCertificateQuery,
        TeacherCertificateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TeacherCertificateQuery, TeacherCertificateQueryVariables>(
        TeacherCertificateDocument,
        options,
    );
}
export function useTeacherCertificateLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherCertificateQuery,
        TeacherCertificateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TeacherCertificateQuery, TeacherCertificateQueryVariables>(
        TeacherCertificateDocument,
        options,
    );
}
export function useTeacherCertificateSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherCertificateQuery,
        TeacherCertificateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherCertificateQuery,
        TeacherCertificateQueryVariables
    >(TeacherCertificateDocument, options);
}
export type TeacherCertificateQueryHookResult = ReturnType<typeof useTeacherCertificateQuery>;
export type TeacherCertificateLazyQueryHookResult = ReturnType<
    typeof useTeacherCertificateLazyQuery
>;
export type TeacherCertificateSuspenseQueryHookResult = ReturnType<
    typeof useTeacherCertificateSuspenseQuery
>;
export type TeacherCertificateQueryResult = Apollo.QueryResult<
    TeacherCertificateQuery,
    TeacherCertificateQueryVariables
>;
export const TeacherAvailableCertificateTypesDocument = gql`
    query TeacherAvailableCertificateTypes {
        certificateTypes {
            type
            label
        }
    }
`;

/**
 * __useTeacherAvailableCertificateTypesQuery__
 *
 * To run a query within a React component, call `useTeacherAvailableCertificateTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherAvailableCertificateTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherAvailableCertificateTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherAvailableCertificateTypesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherAvailableCertificateTypesQuery,
        TeacherAvailableCertificateTypesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        TeacherAvailableCertificateTypesQuery,
        TeacherAvailableCertificateTypesQueryVariables
    >(TeacherAvailableCertificateTypesDocument, options);
}
export function useTeacherAvailableCertificateTypesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherAvailableCertificateTypesQuery,
        TeacherAvailableCertificateTypesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        TeacherAvailableCertificateTypesQuery,
        TeacherAvailableCertificateTypesQueryVariables
    >(TeacherAvailableCertificateTypesDocument, options);
}
export function useTeacherAvailableCertificateTypesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherAvailableCertificateTypesQuery,
        TeacherAvailableCertificateTypesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherAvailableCertificateTypesQuery,
        TeacherAvailableCertificateTypesQueryVariables
    >(TeacherAvailableCertificateTypesDocument, options);
}
export type TeacherAvailableCertificateTypesQueryHookResult = ReturnType<
    typeof useTeacherAvailableCertificateTypesQuery
>;
export type TeacherAvailableCertificateTypesLazyQueryHookResult = ReturnType<
    typeof useTeacherAvailableCertificateTypesLazyQuery
>;
export type TeacherAvailableCertificateTypesSuspenseQueryHookResult = ReturnType<
    typeof useTeacherAvailableCertificateTypesSuspenseQuery
>;
export type TeacherAvailableCertificateTypesQueryResult = Apollo.QueryResult<
    TeacherAvailableCertificateTypesQuery,
    TeacherAvailableCertificateTypesQueryVariables
>;
export const TeacherUpdateInvoiceDocument = gql`
    mutation TeacherUpdateInvoice($paymentInfoInput: UpdatePaymentInfoInput!) {
        teacherUpdatePaymentInfo(paymentInfoInput: $paymentInfoInput) {
            ...TeacherInvoiceData
        }
    }
    ${TeacherInvoiceDataFragmentDoc}
`;
export type TeacherUpdateInvoiceMutationFn = Apollo.MutationFunction<
    TeacherUpdateInvoiceMutation,
    TeacherUpdateInvoiceMutationVariables
>;

/**
 * __useTeacherUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useTeacherUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherUpdateInvoiceMutation, { data, loading, error }] = useTeacherUpdateInvoiceMutation({
 *   variables: {
 *      paymentInfoInput: // value for 'paymentInfoInput'
 *   },
 * });
 */
export function useTeacherUpdateInvoiceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        TeacherUpdateInvoiceMutation,
        TeacherUpdateInvoiceMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        TeacherUpdateInvoiceMutation,
        TeacherUpdateInvoiceMutationVariables
    >(TeacherUpdateInvoiceDocument, options);
}
export type TeacherUpdateInvoiceMutationHookResult = ReturnType<
    typeof useTeacherUpdateInvoiceMutation
>;
export type TeacherUpdateInvoiceMutationResult =
    Apollo.MutationResult<TeacherUpdateInvoiceMutation>;
export type TeacherUpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<
    TeacherUpdateInvoiceMutation,
    TeacherUpdateInvoiceMutationVariables
>;
export const TeacherGetInvoiceDocument = gql`
    query TeacherGetInvoice {
        teacher {
            ...TeacherInvoiceData
        }
    }
    ${TeacherInvoiceDataFragmentDoc}
`;

/**
 * __useTeacherGetInvoiceQuery__
 *
 * To run a query within a React component, call `useTeacherGetInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherGetInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherGetInvoiceQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherGetInvoiceQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherGetInvoiceQuery,
        TeacherGetInvoiceQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TeacherGetInvoiceQuery, TeacherGetInvoiceQueryVariables>(
        TeacherGetInvoiceDocument,
        options,
    );
}
export function useTeacherGetInvoiceLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherGetInvoiceQuery,
        TeacherGetInvoiceQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TeacherGetInvoiceQuery, TeacherGetInvoiceQueryVariables>(
        TeacherGetInvoiceDocument,
        options,
    );
}
export function useTeacherGetInvoiceSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherGetInvoiceQuery,
        TeacherGetInvoiceQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherGetInvoiceQuery,
        TeacherGetInvoiceQueryVariables
    >(TeacherGetInvoiceDocument, options);
}
export type TeacherGetInvoiceQueryHookResult = ReturnType<typeof useTeacherGetInvoiceQuery>;
export type TeacherGetInvoiceLazyQueryHookResult = ReturnType<typeof useTeacherGetInvoiceLazyQuery>;
export type TeacherGetInvoiceSuspenseQueryHookResult = ReturnType<
    typeof useTeacherGetInvoiceSuspenseQuery
>;
export type TeacherGetInvoiceQueryResult = Apollo.QueryResult<
    TeacherGetInvoiceQuery,
    TeacherGetInvoiceQueryVariables
>;
export const TeacherUpdateLanguagesDocument = gql`
    mutation teacherUpdateLanguages($teacherLanguages: TeacherLanguagesInput!) {
        teacherUpdateLanguages(teacherLanguagesInput: $teacherLanguages) {
            ...teacherLanguages
        }
    }
    ${TeacherLanguagesFragmentDoc}
`;
export type TeacherUpdateLanguagesMutationFn = Apollo.MutationFunction<
    TeacherUpdateLanguagesMutation,
    TeacherUpdateLanguagesMutationVariables
>;

/**
 * __useTeacherUpdateLanguagesMutation__
 *
 * To run a mutation, you first call `useTeacherUpdateLanguagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherUpdateLanguagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherUpdateLanguagesMutation, { data, loading, error }] = useTeacherUpdateLanguagesMutation({
 *   variables: {
 *      teacherLanguages: // value for 'teacherLanguages'
 *   },
 * });
 */
export function useTeacherUpdateLanguagesMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        TeacherUpdateLanguagesMutation,
        TeacherUpdateLanguagesMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        TeacherUpdateLanguagesMutation,
        TeacherUpdateLanguagesMutationVariables
    >(TeacherUpdateLanguagesDocument, options);
}
export type TeacherUpdateLanguagesMutationHookResult = ReturnType<
    typeof useTeacherUpdateLanguagesMutation
>;
export type TeacherUpdateLanguagesMutationResult =
    Apollo.MutationResult<TeacherUpdateLanguagesMutation>;
export type TeacherUpdateLanguagesMutationOptions = Apollo.BaseMutationOptions<
    TeacherUpdateLanguagesMutation,
    TeacherUpdateLanguagesMutationVariables
>;
export const TeacherProfileLanguagesDocument = gql`
    query teacherProfileLanguages {
        teacher {
            ...teacherLanguages
        }
    }
    ${TeacherLanguagesFragmentDoc}
`;

/**
 * __useTeacherProfileLanguagesQuery__
 *
 * To run a query within a React component, call `useTeacherProfileLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherProfileLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherProfileLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherProfileLanguagesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherProfileLanguagesQuery,
        TeacherProfileLanguagesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        TeacherProfileLanguagesQuery,
        TeacherProfileLanguagesQueryVariables
    >(TeacherProfileLanguagesDocument, options);
}
export function useTeacherProfileLanguagesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherProfileLanguagesQuery,
        TeacherProfileLanguagesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        TeacherProfileLanguagesQuery,
        TeacherProfileLanguagesQueryVariables
    >(TeacherProfileLanguagesDocument, options);
}
export function useTeacherProfileLanguagesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherProfileLanguagesQuery,
        TeacherProfileLanguagesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherProfileLanguagesQuery,
        TeacherProfileLanguagesQueryVariables
    >(TeacherProfileLanguagesDocument, options);
}
export type TeacherProfileLanguagesQueryHookResult = ReturnType<
    typeof useTeacherProfileLanguagesQuery
>;
export type TeacherProfileLanguagesLazyQueryHookResult = ReturnType<
    typeof useTeacherProfileLanguagesLazyQuery
>;
export type TeacherProfileLanguagesSuspenseQueryHookResult = ReturnType<
    typeof useTeacherProfileLanguagesSuspenseQuery
>;
export type TeacherProfileLanguagesQueryResult = Apollo.QueryResult<
    TeacherProfileLanguagesQuery,
    TeacherProfileLanguagesQueryVariables
>;
export const TeacherUpdateNotificationSettingsDocument = gql`
    mutation teacherUpdateNotificationSettings(
        $notificationSettings: TeacherNotificationSettingsInput!
    ) {
        teacherUpdateNotificationSettings(notificationSettingsInput: $notificationSettings) {
            ...teacherNotificationsSettings
        }
    }
    ${TeacherNotificationsSettingsFragmentDoc}
`;
export type TeacherUpdateNotificationSettingsMutationFn = Apollo.MutationFunction<
    TeacherUpdateNotificationSettingsMutation,
    TeacherUpdateNotificationSettingsMutationVariables
>;

/**
 * __useTeacherUpdateNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useTeacherUpdateNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherUpdateNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherUpdateNotificationSettingsMutation, { data, loading, error }] = useTeacherUpdateNotificationSettingsMutation({
 *   variables: {
 *      notificationSettings: // value for 'notificationSettings'
 *   },
 * });
 */
export function useTeacherUpdateNotificationSettingsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        TeacherUpdateNotificationSettingsMutation,
        TeacherUpdateNotificationSettingsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        TeacherUpdateNotificationSettingsMutation,
        TeacherUpdateNotificationSettingsMutationVariables
    >(TeacherUpdateNotificationSettingsDocument, options);
}
export type TeacherUpdateNotificationSettingsMutationHookResult = ReturnType<
    typeof useTeacherUpdateNotificationSettingsMutation
>;
export type TeacherUpdateNotificationSettingsMutationResult =
    Apollo.MutationResult<TeacherUpdateNotificationSettingsMutation>;
export type TeacherUpdateNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<
    TeacherUpdateNotificationSettingsMutation,
    TeacherUpdateNotificationSettingsMutationVariables
>;
export const TeacherNotificationsDocument = gql`
    query teacherNotifications {
        teacher {
            ...teacherNotificationsSettings
        }
    }
    ${TeacherNotificationsSettingsFragmentDoc}
`;

/**
 * __useTeacherNotificationsQuery__
 *
 * To run a query within a React component, call `useTeacherNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherNotificationsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherNotificationsQuery,
        TeacherNotificationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TeacherNotificationsQuery, TeacherNotificationsQueryVariables>(
        TeacherNotificationsDocument,
        options,
    );
}
export function useTeacherNotificationsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherNotificationsQuery,
        TeacherNotificationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        TeacherNotificationsQuery,
        TeacherNotificationsQueryVariables
    >(TeacherNotificationsDocument, options);
}
export function useTeacherNotificationsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherNotificationsQuery,
        TeacherNotificationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherNotificationsQuery,
        TeacherNotificationsQueryVariables
    >(TeacherNotificationsDocument, options);
}
export type TeacherNotificationsQueryHookResult = ReturnType<typeof useTeacherNotificationsQuery>;
export type TeacherNotificationsLazyQueryHookResult = ReturnType<
    typeof useTeacherNotificationsLazyQuery
>;
export type TeacherNotificationsSuspenseQueryHookResult = ReturnType<
    typeof useTeacherNotificationsSuspenseQuery
>;
export type TeacherNotificationsQueryResult = Apollo.QueryResult<
    TeacherNotificationsQuery,
    TeacherNotificationsQueryVariables
>;
export const TeacherUpdatePreferencesDocument = gql`
    mutation TeacherUpdatePreferences($teacherPreferences: UpdateTeacherPreferencesInput!) {
        teacherUpdatePreferences(teacherPreferencesInput: $teacherPreferences) {
            ...TeacherPreferencesData
        }
    }
    ${TeacherPreferencesDataFragmentDoc}
`;
export type TeacherUpdatePreferencesMutationFn = Apollo.MutationFunction<
    TeacherUpdatePreferencesMutation,
    TeacherUpdatePreferencesMutationVariables
>;

/**
 * __useTeacherUpdatePreferencesMutation__
 *
 * To run a mutation, you first call `useTeacherUpdatePreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherUpdatePreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherUpdatePreferencesMutation, { data, loading, error }] = useTeacherUpdatePreferencesMutation({
 *   variables: {
 *      teacherPreferences: // value for 'teacherPreferences'
 *   },
 * });
 */
export function useTeacherUpdatePreferencesMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        TeacherUpdatePreferencesMutation,
        TeacherUpdatePreferencesMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        TeacherUpdatePreferencesMutation,
        TeacherUpdatePreferencesMutationVariables
    >(TeacherUpdatePreferencesDocument, options);
}
export type TeacherUpdatePreferencesMutationHookResult = ReturnType<
    typeof useTeacherUpdatePreferencesMutation
>;
export type TeacherUpdatePreferencesMutationResult =
    Apollo.MutationResult<TeacherUpdatePreferencesMutation>;
export type TeacherUpdatePreferencesMutationOptions = Apollo.BaseMutationOptions<
    TeacherUpdatePreferencesMutation,
    TeacherUpdatePreferencesMutationVariables
>;
export const TeacherGetPreferencesDocument = gql`
    query TeacherGetPreferences {
        teacher {
            ...TeacherPreferencesData
        }
    }
    ${TeacherPreferencesDataFragmentDoc}
`;

/**
 * __useTeacherGetPreferencesQuery__
 *
 * To run a query within a React component, call `useTeacherGetPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherGetPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherGetPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherGetPreferencesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherGetPreferencesQuery,
        TeacherGetPreferencesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        TeacherGetPreferencesQuery,
        TeacherGetPreferencesQueryVariables
    >(TeacherGetPreferencesDocument, options);
}
export function useTeacherGetPreferencesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherGetPreferencesQuery,
        TeacherGetPreferencesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        TeacherGetPreferencesQuery,
        TeacherGetPreferencesQueryVariables
    >(TeacherGetPreferencesDocument, options);
}
export function useTeacherGetPreferencesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherGetPreferencesQuery,
        TeacherGetPreferencesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherGetPreferencesQuery,
        TeacherGetPreferencesQueryVariables
    >(TeacherGetPreferencesDocument, options);
}
export type TeacherGetPreferencesQueryHookResult = ReturnType<typeof useTeacherGetPreferencesQuery>;
export type TeacherGetPreferencesLazyQueryHookResult = ReturnType<
    typeof useTeacherGetPreferencesLazyQuery
>;
export type TeacherGetPreferencesSuspenseQueryHookResult = ReturnType<
    typeof useTeacherGetPreferencesSuspenseQuery
>;
export type TeacherGetPreferencesQueryResult = Apollo.QueryResult<
    TeacherGetPreferencesQuery,
    TeacherGetPreferencesQueryVariables
>;
export const TeacherUpdatePersonalInformationDocument = gql`
    mutation TeacherUpdatePersonalInformation($input: ProfileInput!) {
        teacherUpdateProfile(input: $input) {
            ...TeacherPersonalInformationTeacher
        }
    }
    ${TeacherPersonalInformationTeacherFragmentDoc}
`;
export type TeacherUpdatePersonalInformationMutationFn = Apollo.MutationFunction<
    TeacherUpdatePersonalInformationMutation,
    TeacherUpdatePersonalInformationMutationVariables
>;

/**
 * __useTeacherUpdatePersonalInformationMutation__
 *
 * To run a mutation, you first call `useTeacherUpdatePersonalInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherUpdatePersonalInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherUpdatePersonalInformationMutation, { data, loading, error }] = useTeacherUpdatePersonalInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeacherUpdatePersonalInformationMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        TeacherUpdatePersonalInformationMutation,
        TeacherUpdatePersonalInformationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        TeacherUpdatePersonalInformationMutation,
        TeacherUpdatePersonalInformationMutationVariables
    >(TeacherUpdatePersonalInformationDocument, options);
}
export type TeacherUpdatePersonalInformationMutationHookResult = ReturnType<
    typeof useTeacherUpdatePersonalInformationMutation
>;
export type TeacherUpdatePersonalInformationMutationResult =
    Apollo.MutationResult<TeacherUpdatePersonalInformationMutation>;
export type TeacherUpdatePersonalInformationMutationOptions = Apollo.BaseMutationOptions<
    TeacherUpdatePersonalInformationMutation,
    TeacherUpdatePersonalInformationMutationVariables
>;
export const TeacherGetPersonalInformationDocument = gql`
    query TeacherGetPersonalInformation {
        teacher {
            ...TeacherPersonalInformationTeacher
        }
    }
    ${TeacherPersonalInformationTeacherFragmentDoc}
`;

/**
 * __useTeacherGetPersonalInformationQuery__
 *
 * To run a query within a React component, call `useTeacherGetPersonalInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherGetPersonalInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherGetPersonalInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherGetPersonalInformationQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherGetPersonalInformationQuery,
        TeacherGetPersonalInformationQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        TeacherGetPersonalInformationQuery,
        TeacherGetPersonalInformationQueryVariables
    >(TeacherGetPersonalInformationDocument, options);
}
export function useTeacherGetPersonalInformationLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherGetPersonalInformationQuery,
        TeacherGetPersonalInformationQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        TeacherGetPersonalInformationQuery,
        TeacherGetPersonalInformationQueryVariables
    >(TeacherGetPersonalInformationDocument, options);
}
export function useTeacherGetPersonalInformationSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherGetPersonalInformationQuery,
        TeacherGetPersonalInformationQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherGetPersonalInformationQuery,
        TeacherGetPersonalInformationQueryVariables
    >(TeacherGetPersonalInformationDocument, options);
}
export type TeacherGetPersonalInformationQueryHookResult = ReturnType<
    typeof useTeacherGetPersonalInformationQuery
>;
export type TeacherGetPersonalInformationLazyQueryHookResult = ReturnType<
    typeof useTeacherGetPersonalInformationLazyQuery
>;
export type TeacherGetPersonalInformationSuspenseQueryHookResult = ReturnType<
    typeof useTeacherGetPersonalInformationSuspenseQuery
>;
export type TeacherGetPersonalInformationQueryResult = Apollo.QueryResult<
    TeacherGetPersonalInformationQuery,
    TeacherGetPersonalInformationQueryVariables
>;
export const TeacherRatingAndFeedbackDocument = gql`
    query teacherRatingAndFeedback {
        teacher {
            id
            section {
                id
                name
            }
            rating {
                date
                personalAverage
                sectionAverage
                classesTaught
            }
            feedback {
                classStartDate
                lessonTitle
                feedback
            }
        }
    }
`;

/**
 * __useTeacherRatingAndFeedbackQuery__
 *
 * To run a query within a React component, call `useTeacherRatingAndFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherRatingAndFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherRatingAndFeedbackQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherRatingAndFeedbackQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherRatingAndFeedbackQuery,
        TeacherRatingAndFeedbackQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        TeacherRatingAndFeedbackQuery,
        TeacherRatingAndFeedbackQueryVariables
    >(TeacherRatingAndFeedbackDocument, options);
}
export function useTeacherRatingAndFeedbackLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherRatingAndFeedbackQuery,
        TeacherRatingAndFeedbackQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        TeacherRatingAndFeedbackQuery,
        TeacherRatingAndFeedbackQueryVariables
    >(TeacherRatingAndFeedbackDocument, options);
}
export function useTeacherRatingAndFeedbackSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherRatingAndFeedbackQuery,
        TeacherRatingAndFeedbackQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherRatingAndFeedbackQuery,
        TeacherRatingAndFeedbackQueryVariables
    >(TeacherRatingAndFeedbackDocument, options);
}
export type TeacherRatingAndFeedbackQueryHookResult = ReturnType<
    typeof useTeacherRatingAndFeedbackQuery
>;
export type TeacherRatingAndFeedbackLazyQueryHookResult = ReturnType<
    typeof useTeacherRatingAndFeedbackLazyQuery
>;
export type TeacherRatingAndFeedbackSuspenseQueryHookResult = ReturnType<
    typeof useTeacherRatingAndFeedbackSuspenseQuery
>;
export type TeacherRatingAndFeedbackQueryResult = Apollo.QueryResult<
    TeacherRatingAndFeedbackQuery,
    TeacherRatingAndFeedbackQueryVariables
>;
export const TeacherUpdateProfileDocument = gql`
    mutation TeacherUpdateProfile($input: ProfileInput!) {
        teacherUpdateProfile(input: $input) {
            ...ProfileTeacher
        }
    }
    ${ProfileTeacherFragmentDoc}
`;
export type TeacherUpdateProfileMutationFn = Apollo.MutationFunction<
    TeacherUpdateProfileMutation,
    TeacherUpdateProfileMutationVariables
>;

/**
 * __useTeacherUpdateProfileMutation__
 *
 * To run a mutation, you first call `useTeacherUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherUpdateProfileMutation, { data, loading, error }] = useTeacherUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeacherUpdateProfileMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        TeacherUpdateProfileMutation,
        TeacherUpdateProfileMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        TeacherUpdateProfileMutation,
        TeacherUpdateProfileMutationVariables
    >(TeacherUpdateProfileDocument, options);
}
export type TeacherUpdateProfileMutationHookResult = ReturnType<
    typeof useTeacherUpdateProfileMutation
>;
export type TeacherUpdateProfileMutationResult =
    Apollo.MutationResult<TeacherUpdateProfileMutation>;
export type TeacherUpdateProfileMutationOptions = Apollo.BaseMutationOptions<
    TeacherUpdateProfileMutation,
    TeacherUpdateProfileMutationVariables
>;
export const TeacherProfileDocument = gql`
    query TeacherProfile {
        teacher {
            ...ProfileTeacher
        }
    }
    ${ProfileTeacherFragmentDoc}
`;

/**
 * __useTeacherProfileQuery__
 *
 * To run a query within a React component, call `useTeacherProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherProfileQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherProfileQuery,
        TeacherProfileQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TeacherProfileQuery, TeacherProfileQueryVariables>(
        TeacherProfileDocument,
        options,
    );
}
export function useTeacherProfileLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherProfileQuery,
        TeacherProfileQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TeacherProfileQuery, TeacherProfileQueryVariables>(
        TeacherProfileDocument,
        options,
    );
}
export function useTeacherProfileSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherProfileQuery,
        TeacherProfileQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<TeacherProfileQuery, TeacherProfileQueryVariables>(
        TeacherProfileDocument,
        options,
    );
}
export type TeacherProfileQueryHookResult = ReturnType<typeof useTeacherProfileQuery>;
export type TeacherProfileLazyQueryHookResult = ReturnType<typeof useTeacherProfileLazyQuery>;
export type TeacherProfileSuspenseQueryHookResult = ReturnType<
    typeof useTeacherProfileSuspenseQuery
>;
export type TeacherProfileQueryResult = Apollo.QueryResult<
    TeacherProfileQuery,
    TeacherProfileQueryVariables
>;
export const VocabularyListDocument = gql`
    query vocabularyList(
        $limit: Int!
        $page: Int!
        $moduleIds: [Int!]
        $wordStatuses: [WordStatus!]
    ) {
        cumulativeVocabList(
            limit: $limit
            page: $page
            moduleIds: $moduleIds
            wordStatuses: $wordStatuses
        ) {
            count
            hasMore
            currentPage
            items {
                id
                status
                target
                translation
                audioUrls {
                    target
                }
            }
        }
    }
`;

/**
 * __useVocabularyListQuery__
 *
 * To run a query within a React component, call `useVocabularyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useVocabularyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVocabularyListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      moduleIds: // value for 'moduleIds'
 *      wordStatuses: // value for 'wordStatuses'
 *   },
 * });
 */
export function useVocabularyListQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        VocabularyListQuery,
        VocabularyListQueryVariables
    > &
        ({ variables: VocabularyListQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<VocabularyListQuery, VocabularyListQueryVariables>(
        VocabularyListDocument,
        options,
    );
}
export function useVocabularyListLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        VocabularyListQuery,
        VocabularyListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<VocabularyListQuery, VocabularyListQueryVariables>(
        VocabularyListDocument,
        options,
    );
}
export function useVocabularyListSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        VocabularyListQuery,
        VocabularyListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<VocabularyListQuery, VocabularyListQueryVariables>(
        VocabularyListDocument,
        options,
    );
}
export type VocabularyListQueryHookResult = ReturnType<typeof useVocabularyListQuery>;
export type VocabularyListLazyQueryHookResult = ReturnType<typeof useVocabularyListLazyQuery>;
export type VocabularyListSuspenseQueryHookResult = ReturnType<
    typeof useVocabularyListSuspenseQuery
>;
export type VocabularyListQueryResult = Apollo.QueryResult<
    VocabularyListQuery,
    VocabularyListQueryVariables
>;
export const AttendedClassesForVocabularyListDocument = gql`
    query attendedClassesForVocabularyList {
        student {
            id
            completedClasses
        }
    }
`;

/**
 * __useAttendedClassesForVocabularyListQuery__
 *
 * To run a query within a React component, call `useAttendedClassesForVocabularyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendedClassesForVocabularyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendedClassesForVocabularyListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAttendedClassesForVocabularyListQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        AttendedClassesForVocabularyListQuery,
        AttendedClassesForVocabularyListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        AttendedClassesForVocabularyListQuery,
        AttendedClassesForVocabularyListQueryVariables
    >(AttendedClassesForVocabularyListDocument, options);
}
export function useAttendedClassesForVocabularyListLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        AttendedClassesForVocabularyListQuery,
        AttendedClassesForVocabularyListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        AttendedClassesForVocabularyListQuery,
        AttendedClassesForVocabularyListQueryVariables
    >(AttendedClassesForVocabularyListDocument, options);
}
export function useAttendedClassesForVocabularyListSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        AttendedClassesForVocabularyListQuery,
        AttendedClassesForVocabularyListQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        AttendedClassesForVocabularyListQuery,
        AttendedClassesForVocabularyListQueryVariables
    >(AttendedClassesForVocabularyListDocument, options);
}
export type AttendedClassesForVocabularyListQueryHookResult = ReturnType<
    typeof useAttendedClassesForVocabularyListQuery
>;
export type AttendedClassesForVocabularyListLazyQueryHookResult = ReturnType<
    typeof useAttendedClassesForVocabularyListLazyQuery
>;
export type AttendedClassesForVocabularyListSuspenseQueryHookResult = ReturnType<
    typeof useAttendedClassesForVocabularyListSuspenseQuery
>;
export type AttendedClassesForVocabularyListQueryResult = Apollo.QueryResult<
    AttendedClassesForVocabularyListQuery,
    AttendedClassesForVocabularyListQueryVariables
>;
export const DisconnectCalendarDocument = gql`
    mutation disconnectCalendar($calendarType: CalendarType!) {
        calendarDisconnect(calendarType: $calendarType)
    }
`;
export type DisconnectCalendarMutationFn = Apollo.MutationFunction<
    DisconnectCalendarMutation,
    DisconnectCalendarMutationVariables
>;

/**
 * __useDisconnectCalendarMutation__
 *
 * To run a mutation, you first call `useDisconnectCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectCalendarMutation, { data, loading, error }] = useDisconnectCalendarMutation({
 *   variables: {
 *      calendarType: // value for 'calendarType'
 *   },
 * });
 */
export function useDisconnectCalendarMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DisconnectCalendarMutation,
        DisconnectCalendarMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DisconnectCalendarMutation,
        DisconnectCalendarMutationVariables
    >(DisconnectCalendarDocument, options);
}
export type DisconnectCalendarMutationHookResult = ReturnType<typeof useDisconnectCalendarMutation>;
export type DisconnectCalendarMutationResult = Apollo.MutationResult<DisconnectCalendarMutation>;
export type DisconnectCalendarMutationOptions = Apollo.BaseMutationOptions<
    DisconnectCalendarMutation,
    DisconnectCalendarMutationVariables
>;
export const StudentCalendarsDocument = gql`
    query studentCalendars {
        calendarList {
            id
            type
            status
            email
        }
    }
`;

/**
 * __useStudentCalendarsQuery__
 *
 * To run a query within a React component, call `useStudentCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentCalendarsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentCalendarsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        StudentCalendarsQuery,
        StudentCalendarsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<StudentCalendarsQuery, StudentCalendarsQueryVariables>(
        StudentCalendarsDocument,
        options,
    );
}
export function useStudentCalendarsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        StudentCalendarsQuery,
        StudentCalendarsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<StudentCalendarsQuery, StudentCalendarsQueryVariables>(
        StudentCalendarsDocument,
        options,
    );
}
export function useStudentCalendarsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        StudentCalendarsQuery,
        StudentCalendarsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<StudentCalendarsQuery, StudentCalendarsQueryVariables>(
        StudentCalendarsDocument,
        options,
    );
}
export type StudentCalendarsQueryHookResult = ReturnType<typeof useStudentCalendarsQuery>;
export type StudentCalendarsLazyQueryHookResult = ReturnType<typeof useStudentCalendarsLazyQuery>;
export type StudentCalendarsSuspenseQueryHookResult = ReturnType<
    typeof useStudentCalendarsSuspenseQuery
>;
export type StudentCalendarsQueryResult = Apollo.QueryResult<
    StudentCalendarsQuery,
    StudentCalendarsQueryVariables
>;
export const ConnectCalendarDocument = gql`
    query connectCalendar($calendarType: CalendarType!) {
        calendarAuthRedirectUrl(calendarType: $calendarType)
    }
`;

/**
 * __useConnectCalendarQuery__
 *
 * To run a query within a React component, call `useConnectCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectCalendarQuery({
 *   variables: {
 *      calendarType: // value for 'calendarType'
 *   },
 * });
 */
export function useConnectCalendarQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ConnectCalendarQuery,
        ConnectCalendarQueryVariables
    > &
        ({ variables: ConnectCalendarQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ConnectCalendarQuery, ConnectCalendarQueryVariables>(
        ConnectCalendarDocument,
        options,
    );
}
export function useConnectCalendarLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ConnectCalendarQuery,
        ConnectCalendarQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ConnectCalendarQuery, ConnectCalendarQueryVariables>(
        ConnectCalendarDocument,
        options,
    );
}
export function useConnectCalendarSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ConnectCalendarQuery,
        ConnectCalendarQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ConnectCalendarQuery, ConnectCalendarQueryVariables>(
        ConnectCalendarDocument,
        options,
    );
}
export type ConnectCalendarQueryHookResult = ReturnType<typeof useConnectCalendarQuery>;
export type ConnectCalendarLazyQueryHookResult = ReturnType<typeof useConnectCalendarLazyQuery>;
export type ConnectCalendarSuspenseQueryHookResult = ReturnType<
    typeof useConnectCalendarSuspenseQuery
>;
export type ConnectCalendarQueryResult = Apollo.QueryResult<
    ConnectCalendarQuery,
    ConnectCalendarQueryVariables
>;
export const GetClassChatHistoryDocument = gql`
    query getClassChatHistory($classUid: String!) {
        chatHistory(classId: $classUid) {
            content
        }
    }
`;

/**
 * __useGetClassChatHistoryQuery__
 *
 * To run a query within a React component, call `useGetClassChatHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassChatHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassChatHistoryQuery({
 *   variables: {
 *      classUid: // value for 'classUid'
 *   },
 * });
 */
export function useGetClassChatHistoryQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetClassChatHistoryQuery,
        GetClassChatHistoryQueryVariables
    > &
        ({ variables: GetClassChatHistoryQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetClassChatHistoryQuery, GetClassChatHistoryQueryVariables>(
        GetClassChatHistoryDocument,
        options,
    );
}
export function useGetClassChatHistoryLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetClassChatHistoryQuery,
        GetClassChatHistoryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetClassChatHistoryQuery,
        GetClassChatHistoryQueryVariables
    >(GetClassChatHistoryDocument, options);
}
export function useGetClassChatHistorySuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        GetClassChatHistoryQuery,
        GetClassChatHistoryQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        GetClassChatHistoryQuery,
        GetClassChatHistoryQueryVariables
    >(GetClassChatHistoryDocument, options);
}
export type GetClassChatHistoryQueryHookResult = ReturnType<typeof useGetClassChatHistoryQuery>;
export type GetClassChatHistoryLazyQueryHookResult = ReturnType<
    typeof useGetClassChatHistoryLazyQuery
>;
export type GetClassChatHistorySuspenseQueryHookResult = ReturnType<
    typeof useGetClassChatHistorySuspenseQuery
>;
export type GetClassChatHistoryQueryResult = Apollo.QueryResult<
    GetClassChatHistoryQuery,
    GetClassChatHistoryQueryVariables
>;
export const ChatHistoryMetadataDocument = gql`
    query chatHistoryMetadata($classUid: String!) {
        class(id: $classUid) {
            uid
            ...classChatParticipantPhotos
        }
        currentUser {
            uid
        }
    }
    ${ClassChatParticipantPhotosFragmentDoc}
`;

/**
 * __useChatHistoryMetadataQuery__
 *
 * To run a query within a React component, call `useChatHistoryMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatHistoryMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatHistoryMetadataQuery({
 *   variables: {
 *      classUid: // value for 'classUid'
 *   },
 * });
 */
export function useChatHistoryMetadataQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ChatHistoryMetadataQuery,
        ChatHistoryMetadataQueryVariables
    > &
        ({ variables: ChatHistoryMetadataQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ChatHistoryMetadataQuery, ChatHistoryMetadataQueryVariables>(
        ChatHistoryMetadataDocument,
        options,
    );
}
export function useChatHistoryMetadataLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ChatHistoryMetadataQuery,
        ChatHistoryMetadataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ChatHistoryMetadataQuery,
        ChatHistoryMetadataQueryVariables
    >(ChatHistoryMetadataDocument, options);
}
export function useChatHistoryMetadataSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ChatHistoryMetadataQuery,
        ChatHistoryMetadataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ChatHistoryMetadataQuery,
        ChatHistoryMetadataQueryVariables
    >(ChatHistoryMetadataDocument, options);
}
export type ChatHistoryMetadataQueryHookResult = ReturnType<typeof useChatHistoryMetadataQuery>;
export type ChatHistoryMetadataLazyQueryHookResult = ReturnType<
    typeof useChatHistoryMetadataLazyQuery
>;
export type ChatHistoryMetadataSuspenseQueryHookResult = ReturnType<
    typeof useChatHistoryMetadataSuspenseQuery
>;
export type ChatHistoryMetadataQueryResult = Apollo.QueryResult<
    ChatHistoryMetadataQuery,
    ChatHistoryMetadataQueryVariables
>;
export const ClassParticipantsDocument = gql`
    query classParticipants($classUid: String!) {
        class(id: $classUid) {
            uid
            ...classParticipantPhotos
            ...classParticipantCompletedClasses
        }
    }
    ${ClassParticipantPhotosFragmentDoc}
    ${ClassParticipantCompletedClassesFragmentDoc}
`;

/**
 * __useClassParticipantsQuery__
 *
 * To run a query within a React component, call `useClassParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassParticipantsQuery({
 *   variables: {
 *      classUid: // value for 'classUid'
 *   },
 * });
 */
export function useClassParticipantsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ClassParticipantsQuery,
        ClassParticipantsQueryVariables
    > &
        ({ variables: ClassParticipantsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ClassParticipantsQuery, ClassParticipantsQueryVariables>(
        ClassParticipantsDocument,
        options,
    );
}
export function useClassParticipantsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ClassParticipantsQuery,
        ClassParticipantsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ClassParticipantsQuery, ClassParticipantsQueryVariables>(
        ClassParticipantsDocument,
        options,
    );
}
export function useClassParticipantsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ClassParticipantsQuery,
        ClassParticipantsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ClassParticipantsQuery,
        ClassParticipantsQueryVariables
    >(ClassParticipantsDocument, options);
}
export type ClassParticipantsQueryHookResult = ReturnType<typeof useClassParticipantsQuery>;
export type ClassParticipantsLazyQueryHookResult = ReturnType<typeof useClassParticipantsLazyQuery>;
export type ClassParticipantsSuspenseQueryHookResult = ReturnType<
    typeof useClassParticipantsSuspenseQuery
>;
export type ClassParticipantsQueryResult = Apollo.QueryResult<
    ClassParticipantsQuery,
    ClassParticipantsQueryVariables
>;
export const DemoClassParticipantsDocument = gql`
    query demoClassParticipants($classUid: String!) {
        teacher {
            ...teacherParticipantPhoto
        }
    }
    ${TeacherParticipantPhotoFragmentDoc}
`;

/**
 * __useDemoClassParticipantsQuery__
 *
 * To run a query within a React component, call `useDemoClassParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoClassParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoClassParticipantsQuery({
 *   variables: {
 *      classUid: // value for 'classUid'
 *   },
 * });
 */
export function useDemoClassParticipantsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        DemoClassParticipantsQuery,
        DemoClassParticipantsQueryVariables
    > &
        ({ variables: DemoClassParticipantsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        DemoClassParticipantsQuery,
        DemoClassParticipantsQueryVariables
    >(DemoClassParticipantsDocument, options);
}
export function useDemoClassParticipantsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        DemoClassParticipantsQuery,
        DemoClassParticipantsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        DemoClassParticipantsQuery,
        DemoClassParticipantsQueryVariables
    >(DemoClassParticipantsDocument, options);
}
export function useDemoClassParticipantsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        DemoClassParticipantsQuery,
        DemoClassParticipantsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        DemoClassParticipantsQuery,
        DemoClassParticipantsQueryVariables
    >(DemoClassParticipantsDocument, options);
}
export type DemoClassParticipantsQueryHookResult = ReturnType<typeof useDemoClassParticipantsQuery>;
export type DemoClassParticipantsLazyQueryHookResult = ReturnType<
    typeof useDemoClassParticipantsLazyQuery
>;
export type DemoClassParticipantsSuspenseQueryHookResult = ReturnType<
    typeof useDemoClassParticipantsSuspenseQuery
>;
export type DemoClassParticipantsQueryResult = Apollo.QueryResult<
    DemoClassParticipantsQuery,
    DemoClassParticipantsQueryVariables
>;
export const PostClassPresentationOverlayDocument = gql`
    query postClassPresentationOverlay($classUid: String!) {
        class(id: $classUid) {
            uid
            sourcePresentation
        }
        classLessonMaterialSnapshot(classId: $classUid) {
            snapshot
        }
    }
`;

/**
 * __usePostClassPresentationOverlayQuery__
 *
 * To run a query within a React component, call `usePostClassPresentationOverlayQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostClassPresentationOverlayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostClassPresentationOverlayQuery({
 *   variables: {
 *      classUid: // value for 'classUid'
 *   },
 * });
 */
export function usePostClassPresentationOverlayQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        PostClassPresentationOverlayQuery,
        PostClassPresentationOverlayQueryVariables
    > &
        (
            | { variables: PostClassPresentationOverlayQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PostClassPresentationOverlayQuery,
        PostClassPresentationOverlayQueryVariables
    >(PostClassPresentationOverlayDocument, options);
}
export function usePostClassPresentationOverlayLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PostClassPresentationOverlayQuery,
        PostClassPresentationOverlayQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PostClassPresentationOverlayQuery,
        PostClassPresentationOverlayQueryVariables
    >(PostClassPresentationOverlayDocument, options);
}
export function usePostClassPresentationOverlaySuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        PostClassPresentationOverlayQuery,
        PostClassPresentationOverlayQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        PostClassPresentationOverlayQuery,
        PostClassPresentationOverlayQueryVariables
    >(PostClassPresentationOverlayDocument, options);
}
export type PostClassPresentationOverlayQueryHookResult = ReturnType<
    typeof usePostClassPresentationOverlayQuery
>;
export type PostClassPresentationOverlayLazyQueryHookResult = ReturnType<
    typeof usePostClassPresentationOverlayLazyQuery
>;
export type PostClassPresentationOverlaySuspenseQueryHookResult = ReturnType<
    typeof usePostClassPresentationOverlaySuspenseQuery
>;
export type PostClassPresentationOverlayQueryResult = Apollo.QueryResult<
    PostClassPresentationOverlayQuery,
    PostClassPresentationOverlayQueryVariables
>;
export const ClassFeedbackAnswersDocument = gql`
    query classFeedbackAnswers($type: String!, $sentiment: String!) {
        classFeedbackAnswers(type: $type, sentiment: $sentiment) {
            ...classFeedbackAnswer
        }
    }
    ${ClassFeedbackAnswerFragmentDoc}
`;

/**
 * __useClassFeedbackAnswersQuery__
 *
 * To run a query within a React component, call `useClassFeedbackAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassFeedbackAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassFeedbackAnswersQuery({
 *   variables: {
 *      type: // value for 'type'
 *      sentiment: // value for 'sentiment'
 *   },
 * });
 */
export function useClassFeedbackAnswersQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ClassFeedbackAnswersQuery,
        ClassFeedbackAnswersQueryVariables
    > &
        ({ variables: ClassFeedbackAnswersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ClassFeedbackAnswersQuery, ClassFeedbackAnswersQueryVariables>(
        ClassFeedbackAnswersDocument,
        options,
    );
}
export function useClassFeedbackAnswersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ClassFeedbackAnswersQuery,
        ClassFeedbackAnswersQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ClassFeedbackAnswersQuery,
        ClassFeedbackAnswersQueryVariables
    >(ClassFeedbackAnswersDocument, options);
}
export function useClassFeedbackAnswersSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ClassFeedbackAnswersQuery,
        ClassFeedbackAnswersQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ClassFeedbackAnswersQuery,
        ClassFeedbackAnswersQueryVariables
    >(ClassFeedbackAnswersDocument, options);
}
export type ClassFeedbackAnswersQueryHookResult = ReturnType<typeof useClassFeedbackAnswersQuery>;
export type ClassFeedbackAnswersLazyQueryHookResult = ReturnType<
    typeof useClassFeedbackAnswersLazyQuery
>;
export type ClassFeedbackAnswersSuspenseQueryHookResult = ReturnType<
    typeof useClassFeedbackAnswersSuspenseQuery
>;
export type ClassFeedbackAnswersQueryResult = Apollo.QueryResult<
    ClassFeedbackAnswersQuery,
    ClassFeedbackAnswersQueryVariables
>;
export const ClassStateStudentDocument = gql`
    query classStateStudent($classId: String!) {
        class(id: $classId) {
            ...classStateKlass
            appointment {
                id
                createdAt
                status
            }
        }
    }
    ${ClassStateKlassFragmentDoc}
`;

/**
 * __useClassStateStudentQuery__
 *
 * To run a query within a React component, call `useClassStateStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassStateStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassStateStudentQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useClassStateStudentQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ClassStateStudentQuery,
        ClassStateStudentQueryVariables
    > &
        ({ variables: ClassStateStudentQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ClassStateStudentQuery, ClassStateStudentQueryVariables>(
        ClassStateStudentDocument,
        options,
    );
}
export function useClassStateStudentLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ClassStateStudentQuery,
        ClassStateStudentQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ClassStateStudentQuery, ClassStateStudentQueryVariables>(
        ClassStateStudentDocument,
        options,
    );
}
export function useClassStateStudentSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ClassStateStudentQuery,
        ClassStateStudentQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ClassStateStudentQuery,
        ClassStateStudentQueryVariables
    >(ClassStateStudentDocument, options);
}
export type ClassStateStudentQueryHookResult = ReturnType<typeof useClassStateStudentQuery>;
export type ClassStateStudentLazyQueryHookResult = ReturnType<typeof useClassStateStudentLazyQuery>;
export type ClassStateStudentSuspenseQueryHookResult = ReturnType<
    typeof useClassStateStudentSuspenseQuery
>;
export type ClassStateStudentQueryResult = Apollo.QueryResult<
    ClassStateStudentQuery,
    ClassStateStudentQueryVariables
>;
export const ClassStateTeacherDocument = gql`
    query classStateTeacher($classId: String!) {
        class(id: $classId) {
            ...classStateKlass
            classLogEnabled
            confirmedAt
        }
    }
    ${ClassStateKlassFragmentDoc}
`;

/**
 * __useClassStateTeacherQuery__
 *
 * To run a query within a React component, call `useClassStateTeacherQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassStateTeacherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassStateTeacherQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useClassStateTeacherQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ClassStateTeacherQuery,
        ClassStateTeacherQueryVariables
    > &
        ({ variables: ClassStateTeacherQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ClassStateTeacherQuery, ClassStateTeacherQueryVariables>(
        ClassStateTeacherDocument,
        options,
    );
}
export function useClassStateTeacherLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ClassStateTeacherQuery,
        ClassStateTeacherQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ClassStateTeacherQuery, ClassStateTeacherQueryVariables>(
        ClassStateTeacherDocument,
        options,
    );
}
export function useClassStateTeacherSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ClassStateTeacherQuery,
        ClassStateTeacherQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ClassStateTeacherQuery,
        ClassStateTeacherQueryVariables
    >(ClassStateTeacherDocument, options);
}
export type ClassStateTeacherQueryHookResult = ReturnType<typeof useClassStateTeacherQuery>;
export type ClassStateTeacherLazyQueryHookResult = ReturnType<typeof useClassStateTeacherLazyQuery>;
export type ClassStateTeacherSuspenseQueryHookResult = ReturnType<
    typeof useClassStateTeacherSuspenseQuery
>;
export type ClassStateTeacherQueryResult = Apollo.QueryResult<
    ClassStateTeacherQuery,
    ClassStateTeacherQueryVariables
>;
export const UpcomingAppointmentsCountDocument = gql`
    query upcomingAppointmentsCount {
        upcomingAppointmentsCount
    }
`;

/**
 * __useUpcomingAppointmentsCountQuery__
 *
 * To run a query within a React component, call `useUpcomingAppointmentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingAppointmentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingAppointmentsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpcomingAppointmentsCountQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        UpcomingAppointmentsCountQuery,
        UpcomingAppointmentsCountQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        UpcomingAppointmentsCountQuery,
        UpcomingAppointmentsCountQueryVariables
    >(UpcomingAppointmentsCountDocument, options);
}
export function useUpcomingAppointmentsCountLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        UpcomingAppointmentsCountQuery,
        UpcomingAppointmentsCountQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        UpcomingAppointmentsCountQuery,
        UpcomingAppointmentsCountQueryVariables
    >(UpcomingAppointmentsCountDocument, options);
}
export function useUpcomingAppointmentsCountSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        UpcomingAppointmentsCountQuery,
        UpcomingAppointmentsCountQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        UpcomingAppointmentsCountQuery,
        UpcomingAppointmentsCountQueryVariables
    >(UpcomingAppointmentsCountDocument, options);
}
export type UpcomingAppointmentsCountQueryHookResult = ReturnType<
    typeof useUpcomingAppointmentsCountQuery
>;
export type UpcomingAppointmentsCountLazyQueryHookResult = ReturnType<
    typeof useUpcomingAppointmentsCountLazyQuery
>;
export type UpcomingAppointmentsCountSuspenseQueryHookResult = ReturnType<
    typeof useUpcomingAppointmentsCountSuspenseQuery
>;
export type UpcomingAppointmentsCountQueryResult = Apollo.QueryResult<
    UpcomingAppointmentsCountQuery,
    UpcomingAppointmentsCountQueryVariables
>;
export const SaveClassroomHistoryEventDocument = gql`
    mutation saveClassroomHistoryEvent(
        $classId: String!
        $eventName: VirtualClassroomEvent!
        $studentIds: [Int!]
    ) {
        saveClassroomEvent(classId: $classId, eventName: $eventName, studentIds: $studentIds)
    }
`;
export type SaveClassroomHistoryEventMutationFn = Apollo.MutationFunction<
    SaveClassroomHistoryEventMutation,
    SaveClassroomHistoryEventMutationVariables
>;

/**
 * __useSaveClassroomHistoryEventMutation__
 *
 * To run a mutation, you first call `useSaveClassroomHistoryEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveClassroomHistoryEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveClassroomHistoryEventMutation, { data, loading, error }] = useSaveClassroomHistoryEventMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      eventName: // value for 'eventName'
 *      studentIds: // value for 'studentIds'
 *   },
 * });
 */
export function useSaveClassroomHistoryEventMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SaveClassroomHistoryEventMutation,
        SaveClassroomHistoryEventMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SaveClassroomHistoryEventMutation,
        SaveClassroomHistoryEventMutationVariables
    >(SaveClassroomHistoryEventDocument, options);
}
export type SaveClassroomHistoryEventMutationHookResult = ReturnType<
    typeof useSaveClassroomHistoryEventMutation
>;
export type SaveClassroomHistoryEventMutationResult =
    Apollo.MutationResult<SaveClassroomHistoryEventMutation>;
export type SaveClassroomHistoryEventMutationOptions = Apollo.BaseMutationOptions<
    SaveClassroomHistoryEventMutation,
    SaveClassroomHistoryEventMutationVariables
>;
export const ClassStateClassroomDocument = gql`
    query classStateClassroom($classUid: String!) {
        class(id: $classUid) {
            ...classStateKlass
        }
    }
    ${ClassStateKlassFragmentDoc}
`;

/**
 * __useClassStateClassroomQuery__
 *
 * To run a query within a React component, call `useClassStateClassroomQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassStateClassroomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassStateClassroomQuery({
 *   variables: {
 *      classUid: // value for 'classUid'
 *   },
 * });
 */
export function useClassStateClassroomQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ClassStateClassroomQuery,
        ClassStateClassroomQueryVariables
    > &
        ({ variables: ClassStateClassroomQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ClassStateClassroomQuery, ClassStateClassroomQueryVariables>(
        ClassStateClassroomDocument,
        options,
    );
}
export function useClassStateClassroomLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ClassStateClassroomQuery,
        ClassStateClassroomQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ClassStateClassroomQuery,
        ClassStateClassroomQueryVariables
    >(ClassStateClassroomDocument, options);
}
export function useClassStateClassroomSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ClassStateClassroomQuery,
        ClassStateClassroomQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ClassStateClassroomQuery,
        ClassStateClassroomQueryVariables
    >(ClassStateClassroomDocument, options);
}
export type ClassStateClassroomQueryHookResult = ReturnType<typeof useClassStateClassroomQuery>;
export type ClassStateClassroomLazyQueryHookResult = ReturnType<
    typeof useClassStateClassroomLazyQuery
>;
export type ClassStateClassroomSuspenseQueryHookResult = ReturnType<
    typeof useClassStateClassroomSuspenseQuery
>;
export type ClassStateClassroomQueryResult = Apollo.QueryResult<
    ClassStateClassroomQuery,
    ClassStateClassroomQueryVariables
>;
export const ClassroomUserRoleDataDocument = gql`
    query classroomUserRoleData($classUid: String!) {
        currentUser {
            id
            uid
        }
        class(id: $classUid) {
            uid
            teacher {
                uid
            }
        }
    }
`;

/**
 * __useClassroomUserRoleDataQuery__
 *
 * To run a query within a React component, call `useClassroomUserRoleDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassroomUserRoleDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassroomUserRoleDataQuery({
 *   variables: {
 *      classUid: // value for 'classUid'
 *   },
 * });
 */
export function useClassroomUserRoleDataQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ClassroomUserRoleDataQuery,
        ClassroomUserRoleDataQueryVariables
    > &
        ({ variables: ClassroomUserRoleDataQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        ClassroomUserRoleDataQuery,
        ClassroomUserRoleDataQueryVariables
    >(ClassroomUserRoleDataDocument, options);
}
export function useClassroomUserRoleDataLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ClassroomUserRoleDataQuery,
        ClassroomUserRoleDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ClassroomUserRoleDataQuery,
        ClassroomUserRoleDataQueryVariables
    >(ClassroomUserRoleDataDocument, options);
}
export function useClassroomUserRoleDataSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ClassroomUserRoleDataQuery,
        ClassroomUserRoleDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ClassroomUserRoleDataQuery,
        ClassroomUserRoleDataQueryVariables
    >(ClassroomUserRoleDataDocument, options);
}
export type ClassroomUserRoleDataQueryHookResult = ReturnType<typeof useClassroomUserRoleDataQuery>;
export type ClassroomUserRoleDataLazyQueryHookResult = ReturnType<
    typeof useClassroomUserRoleDataLazyQuery
>;
export type ClassroomUserRoleDataSuspenseQueryHookResult = ReturnType<
    typeof useClassroomUserRoleDataSuspenseQuery
>;
export type ClassroomUserRoleDataQueryResult = Apollo.QueryResult<
    ClassroomUserRoleDataQuery,
    ClassroomUserRoleDataQueryVariables
>;
export const ClassroomDemoUserRoleDataDocument = gql`
    query classroomDemoUserRoleData($classUid: String!) {
        currentUser {
            id
            uid
        }
        teacher {
            uid
        }
    }
`;

/**
 * __useClassroomDemoUserRoleDataQuery__
 *
 * To run a query within a React component, call `useClassroomDemoUserRoleDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassroomDemoUserRoleDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassroomDemoUserRoleDataQuery({
 *   variables: {
 *      classUid: // value for 'classUid'
 *   },
 * });
 */
export function useClassroomDemoUserRoleDataQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ClassroomDemoUserRoleDataQuery,
        ClassroomDemoUserRoleDataQueryVariables
    > &
        (
            | { variables: ClassroomDemoUserRoleDataQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        ClassroomDemoUserRoleDataQuery,
        ClassroomDemoUserRoleDataQueryVariables
    >(ClassroomDemoUserRoleDataDocument, options);
}
export function useClassroomDemoUserRoleDataLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ClassroomDemoUserRoleDataQuery,
        ClassroomDemoUserRoleDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ClassroomDemoUserRoleDataQuery,
        ClassroomDemoUserRoleDataQueryVariables
    >(ClassroomDemoUserRoleDataDocument, options);
}
export function useClassroomDemoUserRoleDataSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ClassroomDemoUserRoleDataQuery,
        ClassroomDemoUserRoleDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ClassroomDemoUserRoleDataQuery,
        ClassroomDemoUserRoleDataQueryVariables
    >(ClassroomDemoUserRoleDataDocument, options);
}
export type ClassroomDemoUserRoleDataQueryHookResult = ReturnType<
    typeof useClassroomDemoUserRoleDataQuery
>;
export type ClassroomDemoUserRoleDataLazyQueryHookResult = ReturnType<
    typeof useClassroomDemoUserRoleDataLazyQuery
>;
export type ClassroomDemoUserRoleDataSuspenseQueryHookResult = ReturnType<
    typeof useClassroomDemoUserRoleDataSuspenseQuery
>;
export type ClassroomDemoUserRoleDataQueryResult = Apollo.QueryResult<
    ClassroomDemoUserRoleDataQuery,
    ClassroomDemoUserRoleDataQueryVariables
>;
export const ClassroomStudentDataDocument = gql`
    query classroomStudentData {
        ...classroomStudentData
    }
    ${ClassroomStudentDataFragmentDoc}
`;

/**
 * __useClassroomStudentDataQuery__
 *
 * To run a query within a React component, call `useClassroomStudentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassroomStudentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassroomStudentDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useClassroomStudentDataQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        ClassroomStudentDataQuery,
        ClassroomStudentDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ClassroomStudentDataQuery, ClassroomStudentDataQueryVariables>(
        ClassroomStudentDataDocument,
        options,
    );
}
export function useClassroomStudentDataLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ClassroomStudentDataQuery,
        ClassroomStudentDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ClassroomStudentDataQuery,
        ClassroomStudentDataQueryVariables
    >(ClassroomStudentDataDocument, options);
}
export function useClassroomStudentDataSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ClassroomStudentDataQuery,
        ClassroomStudentDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ClassroomStudentDataQuery,
        ClassroomStudentDataQueryVariables
    >(ClassroomStudentDataDocument, options);
}
export type ClassroomStudentDataQueryHookResult = ReturnType<typeof useClassroomStudentDataQuery>;
export type ClassroomStudentDataLazyQueryHookResult = ReturnType<
    typeof useClassroomStudentDataLazyQuery
>;
export type ClassroomStudentDataSuspenseQueryHookResult = ReturnType<
    typeof useClassroomStudentDataSuspenseQuery
>;
export type ClassroomStudentDataQueryResult = Apollo.QueryResult<
    ClassroomStudentDataQuery,
    ClassroomStudentDataQueryVariables
>;
export const ClassroomTeacherDataDocument = gql`
    query classroomTeacherData {
        ...classroomTeacherData
    }
    ${ClassroomTeacherDataFragmentDoc}
`;

/**
 * __useClassroomTeacherDataQuery__
 *
 * To run a query within a React component, call `useClassroomTeacherDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassroomTeacherDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassroomTeacherDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useClassroomTeacherDataQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        ClassroomTeacherDataQuery,
        ClassroomTeacherDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ClassroomTeacherDataQuery, ClassroomTeacherDataQueryVariables>(
        ClassroomTeacherDataDocument,
        options,
    );
}
export function useClassroomTeacherDataLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ClassroomTeacherDataQuery,
        ClassroomTeacherDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ClassroomTeacherDataQuery,
        ClassroomTeacherDataQueryVariables
    >(ClassroomTeacherDataDocument, options);
}
export function useClassroomTeacherDataSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ClassroomTeacherDataQuery,
        ClassroomTeacherDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ClassroomTeacherDataQuery,
        ClassroomTeacherDataQueryVariables
    >(ClassroomTeacherDataDocument, options);
}
export type ClassroomTeacherDataQueryHookResult = ReturnType<typeof useClassroomTeacherDataQuery>;
export type ClassroomTeacherDataLazyQueryHookResult = ReturnType<
    typeof useClassroomTeacherDataLazyQuery
>;
export type ClassroomTeacherDataSuspenseQueryHookResult = ReturnType<
    typeof useClassroomTeacherDataSuspenseQuery
>;
export type ClassroomTeacherDataQueryResult = Apollo.QueryResult<
    ClassroomTeacherDataQuery,
    ClassroomTeacherDataQueryVariables
>;
export const PresentationModeAuthDataDocument = gql`
    query presentationModeAuthData($classUid: String!) {
        presentationModeAuthData(classId: $classUid) {
            userId
            jwtToken
            pdfUrl
        }
    }
`;

/**
 * __usePresentationModeAuthDataQuery__
 *
 * To run a query within a React component, call `usePresentationModeAuthDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePresentationModeAuthDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePresentationModeAuthDataQuery({
 *   variables: {
 *      classUid: // value for 'classUid'
 *   },
 * });
 */
export function usePresentationModeAuthDataQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        PresentationModeAuthDataQuery,
        PresentationModeAuthDataQueryVariables
    > &
        ({ variables: PresentationModeAuthDataQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        PresentationModeAuthDataQuery,
        PresentationModeAuthDataQueryVariables
    >(PresentationModeAuthDataDocument, options);
}
export function usePresentationModeAuthDataLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PresentationModeAuthDataQuery,
        PresentationModeAuthDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        PresentationModeAuthDataQuery,
        PresentationModeAuthDataQueryVariables
    >(PresentationModeAuthDataDocument, options);
}
export function usePresentationModeAuthDataSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        PresentationModeAuthDataQuery,
        PresentationModeAuthDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        PresentationModeAuthDataQuery,
        PresentationModeAuthDataQueryVariables
    >(PresentationModeAuthDataDocument, options);
}
export type PresentationModeAuthDataQueryHookResult = ReturnType<
    typeof usePresentationModeAuthDataQuery
>;
export type PresentationModeAuthDataLazyQueryHookResult = ReturnType<
    typeof usePresentationModeAuthDataLazyQuery
>;
export type PresentationModeAuthDataSuspenseQueryHookResult = ReturnType<
    typeof usePresentationModeAuthDataSuspenseQuery
>;
export type PresentationModeAuthDataQueryResult = Apollo.QueryResult<
    PresentationModeAuthDataQuery,
    PresentationModeAuthDataQueryVariables
>;
export const ClassroomPresentationFeatureAvailabilityDocument = gql`
    query classroomPresentationFeatureAvailability($classUid: String!) {
        class(id: $classUid) {
            uid
            isPresentationMode
        }
    }
`;

/**
 * __useClassroomPresentationFeatureAvailabilityQuery__
 *
 * To run a query within a React component, call `useClassroomPresentationFeatureAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassroomPresentationFeatureAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassroomPresentationFeatureAvailabilityQuery({
 *   variables: {
 *      classUid: // value for 'classUid'
 *   },
 * });
 */
export function useClassroomPresentationFeatureAvailabilityQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ClassroomPresentationFeatureAvailabilityQuery,
        ClassroomPresentationFeatureAvailabilityQueryVariables
    > &
        (
            | { variables: ClassroomPresentationFeatureAvailabilityQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        ClassroomPresentationFeatureAvailabilityQuery,
        ClassroomPresentationFeatureAvailabilityQueryVariables
    >(ClassroomPresentationFeatureAvailabilityDocument, options);
}
export function useClassroomPresentationFeatureAvailabilityLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ClassroomPresentationFeatureAvailabilityQuery,
        ClassroomPresentationFeatureAvailabilityQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ClassroomPresentationFeatureAvailabilityQuery,
        ClassroomPresentationFeatureAvailabilityQueryVariables
    >(ClassroomPresentationFeatureAvailabilityDocument, options);
}
export function useClassroomPresentationFeatureAvailabilitySuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ClassroomPresentationFeatureAvailabilityQuery,
        ClassroomPresentationFeatureAvailabilityQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ClassroomPresentationFeatureAvailabilityQuery,
        ClassroomPresentationFeatureAvailabilityQueryVariables
    >(ClassroomPresentationFeatureAvailabilityDocument, options);
}
export type ClassroomPresentationFeatureAvailabilityQueryHookResult = ReturnType<
    typeof useClassroomPresentationFeatureAvailabilityQuery
>;
export type ClassroomPresentationFeatureAvailabilityLazyQueryHookResult = ReturnType<
    typeof useClassroomPresentationFeatureAvailabilityLazyQuery
>;
export type ClassroomPresentationFeatureAvailabilitySuspenseQueryHookResult = ReturnType<
    typeof useClassroomPresentationFeatureAvailabilitySuspenseQuery
>;
export type ClassroomPresentationFeatureAvailabilityQueryResult = Apollo.QueryResult<
    ClassroomPresentationFeatureAvailabilityQuery,
    ClassroomPresentationFeatureAvailabilityQueryVariables
>;
export const StudentPaymentMethodAddedDocument = gql`
    query studentPaymentMethodAdded {
        subscriptions: studentSubscriptionInfo {
            currentSubscription {
                id
                expiresAt
                paymentMethodAdded
            }
        }
    }
`;

/**
 * __useStudentPaymentMethodAddedQuery__
 *
 * To run a query within a React component, call `useStudentPaymentMethodAddedQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentPaymentMethodAddedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentPaymentMethodAddedQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentPaymentMethodAddedQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        StudentPaymentMethodAddedQuery,
        StudentPaymentMethodAddedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        StudentPaymentMethodAddedQuery,
        StudentPaymentMethodAddedQueryVariables
    >(StudentPaymentMethodAddedDocument, options);
}
export function useStudentPaymentMethodAddedLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        StudentPaymentMethodAddedQuery,
        StudentPaymentMethodAddedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        StudentPaymentMethodAddedQuery,
        StudentPaymentMethodAddedQueryVariables
    >(StudentPaymentMethodAddedDocument, options);
}
export function useStudentPaymentMethodAddedSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        StudentPaymentMethodAddedQuery,
        StudentPaymentMethodAddedQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        StudentPaymentMethodAddedQuery,
        StudentPaymentMethodAddedQueryVariables
    >(StudentPaymentMethodAddedDocument, options);
}
export type StudentPaymentMethodAddedQueryHookResult = ReturnType<
    typeof useStudentPaymentMethodAddedQuery
>;
export type StudentPaymentMethodAddedLazyQueryHookResult = ReturnType<
    typeof useStudentPaymentMethodAddedLazyQuery
>;
export type StudentPaymentMethodAddedSuspenseQueryHookResult = ReturnType<
    typeof useStudentPaymentMethodAddedSuspenseQuery
>;
export type StudentPaymentMethodAddedQueryResult = Apollo.QueryResult<
    StudentPaymentMethodAddedQuery,
    StudentPaymentMethodAddedQueryVariables
>;
export const CreateAppleCreditPurchaseDocument = gql`
    mutation createAppleCreditPurchase($price: PriceInput!, $transactionReceipt: String!) {
        createAppleCreditPurchase(price: $price, transactionReceipt: $transactionReceipt) {
            id
        }
    }
`;
export type CreateAppleCreditPurchaseMutationFn = Apollo.MutationFunction<
    CreateAppleCreditPurchaseMutation,
    CreateAppleCreditPurchaseMutationVariables
>;

/**
 * __useCreateAppleCreditPurchaseMutation__
 *
 * To run a mutation, you first call `useCreateAppleCreditPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppleCreditPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppleCreditPurchaseMutation, { data, loading, error }] = useCreateAppleCreditPurchaseMutation({
 *   variables: {
 *      price: // value for 'price'
 *      transactionReceipt: // value for 'transactionReceipt'
 *   },
 * });
 */
export function useCreateAppleCreditPurchaseMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateAppleCreditPurchaseMutation,
        CreateAppleCreditPurchaseMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateAppleCreditPurchaseMutation,
        CreateAppleCreditPurchaseMutationVariables
    >(CreateAppleCreditPurchaseDocument, options);
}
export type CreateAppleCreditPurchaseMutationHookResult = ReturnType<
    typeof useCreateAppleCreditPurchaseMutation
>;
export type CreateAppleCreditPurchaseMutationResult =
    Apollo.MutationResult<CreateAppleCreditPurchaseMutation>;
export type CreateAppleCreditPurchaseMutationOptions = Apollo.BaseMutationOptions<
    CreateAppleCreditPurchaseMutation,
    CreateAppleCreditPurchaseMutationVariables
>;
export const CreateExtraCreditPurchaseDocument = gql`
    mutation createExtraCreditPurchase(
        $privateCredits: Int!
        $groupCredits: Int!
        $currency: Currency!
    ) {
        purchase: createExtraCreditPurchase(
            privateCredits: $privateCredits
            groupCredits: $groupCredits
            currency: $currency
        ) {
            ...extraCreditPurchase
        }
    }
    ${ExtraCreditPurchaseFragmentDoc}
`;
export type CreateExtraCreditPurchaseMutationFn = Apollo.MutationFunction<
    CreateExtraCreditPurchaseMutation,
    CreateExtraCreditPurchaseMutationVariables
>;

/**
 * __useCreateExtraCreditPurchaseMutation__
 *
 * To run a mutation, you first call `useCreateExtraCreditPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExtraCreditPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExtraCreditPurchaseMutation, { data, loading, error }] = useCreateExtraCreditPurchaseMutation({
 *   variables: {
 *      privateCredits: // value for 'privateCredits'
 *      groupCredits: // value for 'groupCredits'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useCreateExtraCreditPurchaseMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateExtraCreditPurchaseMutation,
        CreateExtraCreditPurchaseMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateExtraCreditPurchaseMutation,
        CreateExtraCreditPurchaseMutationVariables
    >(CreateExtraCreditPurchaseDocument, options);
}
export type CreateExtraCreditPurchaseMutationHookResult = ReturnType<
    typeof useCreateExtraCreditPurchaseMutation
>;
export type CreateExtraCreditPurchaseMutationResult =
    Apollo.MutationResult<CreateExtraCreditPurchaseMutation>;
export type CreateExtraCreditPurchaseMutationOptions = Apollo.BaseMutationOptions<
    CreateExtraCreditPurchaseMutation,
    CreateExtraCreditPurchaseMutationVariables
>;
export const CreateLegacyPurchaseDocument = gql`
    mutation createLegacyPurchase($productId: Int!, $currency: Currency!, $couponCode: String) {
        purchase: createLegacyPurchase(
            couponCode: $couponCode
            productId: $productId
            currency: $currency
        ) {
            ...legacyPurchase
        }
    }
    ${LegacyPurchaseFragmentDoc}
`;
export type CreateLegacyPurchaseMutationFn = Apollo.MutationFunction<
    CreateLegacyPurchaseMutation,
    CreateLegacyPurchaseMutationVariables
>;

/**
 * __useCreateLegacyPurchaseMutation__
 *
 * To run a mutation, you first call `useCreateLegacyPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLegacyPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLegacyPurchaseMutation, { data, loading, error }] = useCreateLegacyPurchaseMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      currency: // value for 'currency'
 *      couponCode: // value for 'couponCode'
 *   },
 * });
 */
export function useCreateLegacyPurchaseMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateLegacyPurchaseMutation,
        CreateLegacyPurchaseMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateLegacyPurchaseMutation,
        CreateLegacyPurchaseMutationVariables
    >(CreateLegacyPurchaseDocument, options);
}
export type CreateLegacyPurchaseMutationHookResult = ReturnType<
    typeof useCreateLegacyPurchaseMutation
>;
export type CreateLegacyPurchaseMutationResult =
    Apollo.MutationResult<CreateLegacyPurchaseMutation>;
export type CreateLegacyPurchaseMutationOptions = Apollo.BaseMutationOptions<
    CreateLegacyPurchaseMutation,
    CreateLegacyPurchaseMutationVariables
>;
export const CreatePackagePurchaseDocument = gql`
    mutation createPackagePurchase(
        $packageId: Ulid!
        $currency: Currency!
        $withTrial: Boolean!
        $couponCode: String
        $options: PurchaseOptionsInput = null
    ) {
        purchase: createPurchase(
            packageId: $packageId
            currency: $currency
            withTrial: $withTrial
            couponCode: $couponCode
            options: $options
        ) {
            ... on SubscriptionPurchase {
                id
                agreements {
                    id
                }
                type
            }
            ... on LegacyPurchase {
                id
                agreements {
                    id
                }
                type
            }
            ... on ExtraCreditPurchase {
                id
                agreements {
                    id
                }
                type
            }
        }
    }
`;
export type CreatePackagePurchaseMutationFn = Apollo.MutationFunction<
    CreatePackagePurchaseMutation,
    CreatePackagePurchaseMutationVariables
>;

/**
 * __useCreatePackagePurchaseMutation__
 *
 * To run a mutation, you first call `useCreatePackagePurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePackagePurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPackagePurchaseMutation, { data, loading, error }] = useCreatePackagePurchaseMutation({
 *   variables: {
 *      packageId: // value for 'packageId'
 *      currency: // value for 'currency'
 *      withTrial: // value for 'withTrial'
 *      couponCode: // value for 'couponCode'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreatePackagePurchaseMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreatePackagePurchaseMutation,
        CreatePackagePurchaseMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreatePackagePurchaseMutation,
        CreatePackagePurchaseMutationVariables
    >(CreatePackagePurchaseDocument, options);
}
export type CreatePackagePurchaseMutationHookResult = ReturnType<
    typeof useCreatePackagePurchaseMutation
>;
export type CreatePackagePurchaseMutationResult =
    Apollo.MutationResult<CreatePackagePurchaseMutation>;
export type CreatePackagePurchaseMutationOptions = Apollo.BaseMutationOptions<
    CreatePackagePurchaseMutation,
    CreatePackagePurchaseMutationVariables
>;
export const DismissStudentNotificationDocument = gql`
    mutation dismissStudentNotification($studentNotificationId: Ulid!) {
        dismissStudentNotification(studentNotificationId: $studentNotificationId)
    }
`;
export type DismissStudentNotificationMutationFn = Apollo.MutationFunction<
    DismissStudentNotificationMutation,
    DismissStudentNotificationMutationVariables
>;

/**
 * __useDismissStudentNotificationMutation__
 *
 * To run a mutation, you first call `useDismissStudentNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissStudentNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissStudentNotificationMutation, { data, loading, error }] = useDismissStudentNotificationMutation({
 *   variables: {
 *      studentNotificationId: // value for 'studentNotificationId'
 *   },
 * });
 */
export function useDismissStudentNotificationMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DismissStudentNotificationMutation,
        DismissStudentNotificationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DismissStudentNotificationMutation,
        DismissStudentNotificationMutationVariables
    >(DismissStudentNotificationDocument, options);
}
export type DismissStudentNotificationMutationHookResult = ReturnType<
    typeof useDismissStudentNotificationMutation
>;
export type DismissStudentNotificationMutationResult =
    Apollo.MutationResult<DismissStudentNotificationMutation>;
export type DismissStudentNotificationMutationOptions = Apollo.BaseMutationOptions<
    DismissStudentNotificationMutation,
    DismissStudentNotificationMutationVariables
>;
export const ReviewMachineNextDocument = gql`
    mutation reviewMachineNext {
        reviewMachineNext
    }
`;
export type ReviewMachineNextMutationFn = Apollo.MutationFunction<
    ReviewMachineNextMutation,
    ReviewMachineNextMutationVariables
>;

/**
 * __useReviewMachineNextMutation__
 *
 * To run a mutation, you first call `useReviewMachineNextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewMachineNextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewMachineNextMutation, { data, loading, error }] = useReviewMachineNextMutation({
 *   variables: {
 *   },
 * });
 */
export function useReviewMachineNextMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        ReviewMachineNextMutation,
        ReviewMachineNextMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        ReviewMachineNextMutation,
        ReviewMachineNextMutationVariables
    >(ReviewMachineNextDocument, options);
}
export type ReviewMachineNextMutationHookResult = ReturnType<typeof useReviewMachineNextMutation>;
export type ReviewMachineNextMutationResult = Apollo.MutationResult<ReviewMachineNextMutation>;
export type ReviewMachineNextMutationOptions = Apollo.BaseMutationOptions<
    ReviewMachineNextMutation,
    ReviewMachineNextMutationVariables
>;
export const ReviewMachineReviewedDocument = gql`
    mutation reviewMachineReviewed($provider: ReviewMachineProvider!) {
        reviewMachineReviewed(provider: $provider)
    }
`;
export type ReviewMachineReviewedMutationFn = Apollo.MutationFunction<
    ReviewMachineReviewedMutation,
    ReviewMachineReviewedMutationVariables
>;

/**
 * __useReviewMachineReviewedMutation__
 *
 * To run a mutation, you first call `useReviewMachineReviewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewMachineReviewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewMachineReviewedMutation, { data, loading, error }] = useReviewMachineReviewedMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useReviewMachineReviewedMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        ReviewMachineReviewedMutation,
        ReviewMachineReviewedMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        ReviewMachineReviewedMutation,
        ReviewMachineReviewedMutationVariables
    >(ReviewMachineReviewedDocument, options);
}
export type ReviewMachineReviewedMutationHookResult = ReturnType<
    typeof useReviewMachineReviewedMutation
>;
export type ReviewMachineReviewedMutationResult =
    Apollo.MutationResult<ReviewMachineReviewedMutation>;
export type ReviewMachineReviewedMutationOptions = Apollo.BaseMutationOptions<
    ReviewMachineReviewedMutation,
    ReviewMachineReviewedMutationVariables
>;
export const SubmitExerciseScoreDocument = gql`
    mutation submitExerciseScore(
        $exerciseId: Int!
        $score: Float!
        $lessonId: Int!
        $type: ExerciseType!
    ) {
        submitExerciseScore(
            exerciseId: $exerciseId
            score: $score
            lessonId: $lessonId
            type: $type
        ) {
            exercises {
                id
                type
                url
                score
            }
            lesson {
                id
            }
        }
    }
`;
export type SubmitExerciseScoreMutationFn = Apollo.MutationFunction<
    SubmitExerciseScoreMutation,
    SubmitExerciseScoreMutationVariables
>;

/**
 * __useSubmitExerciseScoreMutation__
 *
 * To run a mutation, you first call `useSubmitExerciseScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitExerciseScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitExerciseScoreMutation, { data, loading, error }] = useSubmitExerciseScoreMutation({
 *   variables: {
 *      exerciseId: // value for 'exerciseId'
 *      score: // value for 'score'
 *      lessonId: // value for 'lessonId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSubmitExerciseScoreMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SubmitExerciseScoreMutation,
        SubmitExerciseScoreMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SubmitExerciseScoreMutation,
        SubmitExerciseScoreMutationVariables
    >(SubmitExerciseScoreDocument, options);
}
export type SubmitExerciseScoreMutationHookResult = ReturnType<
    typeof useSubmitExerciseScoreMutation
>;
export type SubmitExerciseScoreMutationResult = Apollo.MutationResult<SubmitExerciseScoreMutation>;
export type SubmitExerciseScoreMutationOptions = Apollo.BaseMutationOptions<
    SubmitExerciseScoreMutation,
    SubmitExerciseScoreMutationVariables
>;
export const UpdateExtraCreditPurchaseDocument = gql`
    mutation updateExtraCreditPurchase(
        $id: String!
        $privateCredits: Int
        $groupCredits: Int
        $currency: Currency
    ) {
        purchase: updateExtraCreditPurchase(
            id: $id
            privateCredits: $privateCredits
            groupCredits: $groupCredits
            currency: $currency
        ) {
            ...extraCreditPurchase
        }
    }
    ${ExtraCreditPurchaseFragmentDoc}
`;
export type UpdateExtraCreditPurchaseMutationFn = Apollo.MutationFunction<
    UpdateExtraCreditPurchaseMutation,
    UpdateExtraCreditPurchaseMutationVariables
>;

/**
 * __useUpdateExtraCreditPurchaseMutation__
 *
 * To run a mutation, you first call `useUpdateExtraCreditPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExtraCreditPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExtraCreditPurchaseMutation, { data, loading, error }] = useUpdateExtraCreditPurchaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      privateCredits: // value for 'privateCredits'
 *      groupCredits: // value for 'groupCredits'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useUpdateExtraCreditPurchaseMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateExtraCreditPurchaseMutation,
        UpdateExtraCreditPurchaseMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateExtraCreditPurchaseMutation,
        UpdateExtraCreditPurchaseMutationVariables
    >(UpdateExtraCreditPurchaseDocument, options);
}
export type UpdateExtraCreditPurchaseMutationHookResult = ReturnType<
    typeof useUpdateExtraCreditPurchaseMutation
>;
export type UpdateExtraCreditPurchaseMutationResult =
    Apollo.MutationResult<UpdateExtraCreditPurchaseMutation>;
export type UpdateExtraCreditPurchaseMutationOptions = Apollo.BaseMutationOptions<
    UpdateExtraCreditPurchaseMutation,
    UpdateExtraCreditPurchaseMutationVariables
>;
export const UpdatePackagePurchaseDocument = gql`
    mutation updatePackagePurchase($id: String!, $currency: Currency, $couponCode: String) {
        updatePurchase(id: $id, currency: $currency, couponCode: $couponCode) {
            ...extraCreditPurchase
            ...legacyPurchase
            ...subscriptionPurchase
        }
    }
    ${ExtraCreditPurchaseFragmentDoc}
    ${LegacyPurchaseFragmentDoc}
    ${SubscriptionPurchaseFragmentDoc}
`;
export type UpdatePackagePurchaseMutationFn = Apollo.MutationFunction<
    UpdatePackagePurchaseMutation,
    UpdatePackagePurchaseMutationVariables
>;

/**
 * __useUpdatePackagePurchaseMutation__
 *
 * To run a mutation, you first call `useUpdatePackagePurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePackagePurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePackagePurchaseMutation, { data, loading, error }] = useUpdatePackagePurchaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      currency: // value for 'currency'
 *      couponCode: // value for 'couponCode'
 *   },
 * });
 */
export function useUpdatePackagePurchaseMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdatePackagePurchaseMutation,
        UpdatePackagePurchaseMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdatePackagePurchaseMutation,
        UpdatePackagePurchaseMutationVariables
    >(UpdatePackagePurchaseDocument, options);
}
export type UpdatePackagePurchaseMutationHookResult = ReturnType<
    typeof useUpdatePackagePurchaseMutation
>;
export type UpdatePackagePurchaseMutationResult =
    Apollo.MutationResult<UpdatePackagePurchaseMutation>;
export type UpdatePackagePurchaseMutationOptions = Apollo.BaseMutationOptions<
    UpdatePackagePurchaseMutation,
    UpdatePackagePurchaseMutationVariables
>;
export const UpdateUserLanguageAndSubscriptionDocument = gql`
    mutation UpdateUserLanguageAndSubscription(
        $agreements: [String!]!
        $purchaseId: String!
        $newSectionName: SectionName!
        $newCurriculumId: Int!
        $couponCode: String
    ) {
        updateUserLanguageAndSubscription(
            agreements: $agreements
            purchaseId: $purchaseId
            newSectionName: $newSectionName
            newCurriculumId: $newCurriculumId
            couponCode: $couponCode
        ) {
            id
            status
            expiresAt
        }
    }
`;
export type UpdateUserLanguageAndSubscriptionMutationFn = Apollo.MutationFunction<
    UpdateUserLanguageAndSubscriptionMutation,
    UpdateUserLanguageAndSubscriptionMutationVariables
>;

/**
 * __useUpdateUserLanguageAndSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateUserLanguageAndSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLanguageAndSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLanguageAndSubscriptionMutation, { data, loading, error }] = useUpdateUserLanguageAndSubscriptionMutation({
 *   variables: {
 *      agreements: // value for 'agreements'
 *      purchaseId: // value for 'purchaseId'
 *      newSectionName: // value for 'newSectionName'
 *      newCurriculumId: // value for 'newCurriculumId'
 *      couponCode: // value for 'couponCode'
 *   },
 * });
 */
export function useUpdateUserLanguageAndSubscriptionMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateUserLanguageAndSubscriptionMutation,
        UpdateUserLanguageAndSubscriptionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateUserLanguageAndSubscriptionMutation,
        UpdateUserLanguageAndSubscriptionMutationVariables
    >(UpdateUserLanguageAndSubscriptionDocument, options);
}
export type UpdateUserLanguageAndSubscriptionMutationHookResult = ReturnType<
    typeof useUpdateUserLanguageAndSubscriptionMutation
>;
export type UpdateUserLanguageAndSubscriptionMutationResult =
    Apollo.MutationResult<UpdateUserLanguageAndSubscriptionMutation>;
export type UpdateUserLanguageAndSubscriptionMutationOptions = Apollo.BaseMutationOptions<
    UpdateUserLanguageAndSubscriptionMutation,
    UpdateUserLanguageAndSubscriptionMutationVariables
>;
export const ClassroomUrlDocument = gql`
    query classroomUrl($classId: String!) {
        classroomUrl(uniqueId: $classId)
    }
`;

/**
 * __useClassroomUrlQuery__
 *
 * To run a query within a React component, call `useClassroomUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassroomUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassroomUrlQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useClassroomUrlQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ClassroomUrlQuery, ClassroomUrlQueryVariables> &
        ({ variables: ClassroomUrlQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ClassroomUrlQuery, ClassroomUrlQueryVariables>(
        ClassroomUrlDocument,
        options,
    );
}
export function useClassroomUrlLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ClassroomUrlQuery,
        ClassroomUrlQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ClassroomUrlQuery, ClassroomUrlQueryVariables>(
        ClassroomUrlDocument,
        options,
    );
}
export function useClassroomUrlSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ClassroomUrlQuery,
        ClassroomUrlQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ClassroomUrlQuery, ClassroomUrlQueryVariables>(
        ClassroomUrlDocument,
        options,
    );
}
export type ClassroomUrlQueryHookResult = ReturnType<typeof useClassroomUrlQuery>;
export type ClassroomUrlLazyQueryHookResult = ReturnType<typeof useClassroomUrlLazyQuery>;
export type ClassroomUrlSuspenseQueryHookResult = ReturnType<typeof useClassroomUrlSuspenseQuery>;
export type ClassroomUrlQueryResult = Apollo.QueryResult<
    ClassroomUrlQuery,
    ClassroomUrlQueryVariables
>;
export const ConfigurationDocument = gql`
    query configuration {
        config {
            ...ConfigurationTimezones
            languages
            currencies
            minimumCharges
            classConfig {
                refundableAfterBookingForMinutes
                refundableDaysBeforeStart
                studentCancelLateHours
                classStartTimePoint
                classDefaultDurationMinutes
            }
        }
    }
    ${ConfigurationTimezonesFragmentDoc}
`;

/**
 * __useConfigurationQuery__
 *
 * To run a query within a React component, call `useConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigurationQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        ConfigurationQuery,
        ConfigurationQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ConfigurationQuery, ConfigurationQueryVariables>(
        ConfigurationDocument,
        options,
    );
}
export function useConfigurationLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ConfigurationQuery,
        ConfigurationQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ConfigurationQuery, ConfigurationQueryVariables>(
        ConfigurationDocument,
        options,
    );
}
export function useConfigurationSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ConfigurationQuery,
        ConfigurationQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ConfigurationQuery, ConfigurationQueryVariables>(
        ConfigurationDocument,
        options,
    );
}
export type ConfigurationQueryHookResult = ReturnType<typeof useConfigurationQuery>;
export type ConfigurationLazyQueryHookResult = ReturnType<typeof useConfigurationLazyQuery>;
export type ConfigurationSuspenseQueryHookResult = ReturnType<typeof useConfigurationSuspenseQuery>;
export type ConfigurationQueryResult = Apollo.QueryResult<
    ConfigurationQuery,
    ConfigurationQueryVariables
>;
export const CountriesDocument = gql`
    query countries {
        countries {
            code
            commonName
        }
    }
`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<CountriesQuery, CountriesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CountriesQuery, CountriesQueryVariables>(
        CountriesDocument,
        options,
    );
}
export function useCountriesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CountriesQuery, CountriesQueryVariables>(
        CountriesDocument,
        options,
    );
}
export function useCountriesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        CountriesQuery,
        CountriesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<CountriesQuery, CountriesQueryVariables>(
        CountriesDocument,
        options,
    );
}
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesSuspenseQueryHookResult = ReturnType<typeof useCountriesSuspenseQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const LessonDocument = gql`
    query lesson($id: Int!) {
        lesson(id: $id) {
            id
            type
            label
            title
            description
            learningOutcomes
            learningOutcome
        }
    }
`;

/**
 * __useLessonQuery__
 *
 * To run a query within a React component, call `useLessonQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLessonQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<LessonQuery, LessonQueryVariables> &
        ({ variables: LessonQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<LessonQuery, LessonQueryVariables>(LessonDocument, options);
}
export function useLessonLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LessonQuery, LessonQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<LessonQuery, LessonQueryVariables>(
        LessonDocument,
        options,
    );
}
export function useLessonSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<LessonQuery, LessonQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<LessonQuery, LessonQueryVariables>(
        LessonDocument,
        options,
    );
}
export type LessonQueryHookResult = ReturnType<typeof useLessonQuery>;
export type LessonLazyQueryHookResult = ReturnType<typeof useLessonLazyQuery>;
export type LessonSuspenseQueryHookResult = ReturnType<typeof useLessonSuspenseQuery>;
export type LessonQueryResult = Apollo.QueryResult<LessonQuery, LessonQueryVariables>;
export const OwnReferralDocument = gql`
    query ownReferral {
        ownReferral {
            ...OwnReferral
        }
    }
    ${OwnReferralFragmentDoc}
`;

/**
 * __useOwnReferralQuery__
 *
 * To run a query within a React component, call `useOwnReferralQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnReferralQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnReferralQuery({
 *   variables: {
 *   },
 * });
 */
export function useOwnReferralQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<OwnReferralQuery, OwnReferralQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<OwnReferralQuery, OwnReferralQueryVariables>(
        OwnReferralDocument,
        options,
    );
}
export function useOwnReferralLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        OwnReferralQuery,
        OwnReferralQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<OwnReferralQuery, OwnReferralQueryVariables>(
        OwnReferralDocument,
        options,
    );
}
export function useOwnReferralSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        OwnReferralQuery,
        OwnReferralQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<OwnReferralQuery, OwnReferralQueryVariables>(
        OwnReferralDocument,
        options,
    );
}
export type OwnReferralQueryHookResult = ReturnType<typeof useOwnReferralQuery>;
export type OwnReferralLazyQueryHookResult = ReturnType<typeof useOwnReferralLazyQuery>;
export type OwnReferralSuspenseQueryHookResult = ReturnType<typeof useOwnReferralSuspenseQuery>;
export type OwnReferralQueryResult = Apollo.QueryResult<
    OwnReferralQuery,
    OwnReferralQueryVariables
>;
export const PackagesDocument = gql`
    query packages(
        $sectionName: SectionName!
        $currency: Currency!
        $store: Store!
        $ignoreSectionValidation: Boolean = false
    ) {
        packages(
            sectionName: $sectionName
            currency: $currency
            store: $store
            ignoreSectionValidation: $ignoreSectionValidation
        ) {
            ...package
        }
    }
    ${PackageFragmentDoc}
`;

/**
 * __usePackagesQuery__
 *
 * To run a query within a React component, call `usePackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagesQuery({
 *   variables: {
 *      sectionName: // value for 'sectionName'
 *      currency: // value for 'currency'
 *      store: // value for 'store'
 *      ignoreSectionValidation: // value for 'ignoreSectionValidation'
 *   },
 * });
 */
export function usePackagesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<PackagesQuery, PackagesQueryVariables> &
        ({ variables: PackagesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PackagesQuery, PackagesQueryVariables>(
        PackagesDocument,
        options,
    );
}
export function usePackagesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PackagesQuery, PackagesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PackagesQuery, PackagesQueryVariables>(
        PackagesDocument,
        options,
    );
}
export function usePackagesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<PackagesQuery, PackagesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<PackagesQuery, PackagesQueryVariables>(
        PackagesDocument,
        options,
    );
}
export type PackagesQueryHookResult = ReturnType<typeof usePackagesQuery>;
export type PackagesLazyQueryHookResult = ReturnType<typeof usePackagesLazyQuery>;
export type PackagesSuspenseQueryHookResult = ReturnType<typeof usePackagesSuspenseQuery>;
export type PackagesQueryResult = Apollo.QueryResult<PackagesQuery, PackagesQueryVariables>;
export const PurchaseDocument = gql`
    query purchase($purchaseId: String!) {
        purchase(id: $purchaseId) {
            ...extraCreditPurchase
            ...legacyPurchase
            ...subscriptionPurchase
        }
        student {
            onboarded
        }
    }
    ${ExtraCreditPurchaseFragmentDoc}
    ${LegacyPurchaseFragmentDoc}
    ${SubscriptionPurchaseFragmentDoc}
`;

/**
 * __usePurchaseQuery__
 *
 * To run a query within a React component, call `usePurchaseQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseQuery({
 *   variables: {
 *      purchaseId: // value for 'purchaseId'
 *   },
 * });
 */
export function usePurchaseQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<PurchaseQuery, PurchaseQueryVariables> &
        ({ variables: PurchaseQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PurchaseQuery, PurchaseQueryVariables>(
        PurchaseDocument,
        options,
    );
}
export function usePurchaseLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PurchaseQuery, PurchaseQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PurchaseQuery, PurchaseQueryVariables>(
        PurchaseDocument,
        options,
    );
}
export function usePurchaseSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<PurchaseQuery, PurchaseQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<PurchaseQuery, PurchaseQueryVariables>(
        PurchaseDocument,
        options,
    );
}
export type PurchaseQueryHookResult = ReturnType<typeof usePurchaseQuery>;
export type PurchaseLazyQueryHookResult = ReturnType<typeof usePurchaseLazyQuery>;
export type PurchaseSuspenseQueryHookResult = ReturnType<typeof usePurchaseSuspenseQuery>;
export type PurchaseQueryResult = Apollo.QueryResult<PurchaseQuery, PurchaseQueryVariables>;
export const ReferralByCodeDocument = gql`
    query referralByCode($code: String!) {
        referralByCode(code: $code) {
            userDiscount {
                amount
                amountInMajor
                currency
            }
            ownerFirstName
            ownerPhoto {
                urls {
                    thumbnail
                }
            }
        }
    }
`;

/**
 * __useReferralByCodeQuery__
 *
 * To run a query within a React component, call `useReferralByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useReferralByCodeQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        ReferralByCodeQuery,
        ReferralByCodeQueryVariables
    > &
        ({ variables: ReferralByCodeQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ReferralByCodeQuery, ReferralByCodeQueryVariables>(
        ReferralByCodeDocument,
        options,
    );
}
export function useReferralByCodeLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ReferralByCodeQuery,
        ReferralByCodeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ReferralByCodeQuery, ReferralByCodeQueryVariables>(
        ReferralByCodeDocument,
        options,
    );
}
export function useReferralByCodeSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ReferralByCodeQuery,
        ReferralByCodeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ReferralByCodeQuery, ReferralByCodeQueryVariables>(
        ReferralByCodeDocument,
        options,
    );
}
export type ReferralByCodeQueryHookResult = ReturnType<typeof useReferralByCodeQuery>;
export type ReferralByCodeLazyQueryHookResult = ReturnType<typeof useReferralByCodeLazyQuery>;
export type ReferralByCodeSuspenseQueryHookResult = ReturnType<
    typeof useReferralByCodeSuspenseQuery
>;
export type ReferralByCodeQueryResult = Apollo.QueryResult<
    ReferralByCodeQuery,
    ReferralByCodeQueryVariables
>;
export const UserCreditsDocument = gql`
    query userCredits {
        studentCreditsInfo {
            canBuyExtraCredits
            groupCredits
            privateCredits
            contentBlocks {
                table
                label
                location
                icon
                text
                isStarred
            }
        }
    }
`;

/**
 * __useUserCreditsQuery__
 *
 * To run a query within a React component, call `useUserCreditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCreditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCreditsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCreditsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<UserCreditsQuery, UserCreditsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<UserCreditsQuery, UserCreditsQueryVariables>(
        UserCreditsDocument,
        options,
    );
}
export function useUserCreditsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        UserCreditsQuery,
        UserCreditsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<UserCreditsQuery, UserCreditsQueryVariables>(
        UserCreditsDocument,
        options,
    );
}
export function useUserCreditsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        UserCreditsQuery,
        UserCreditsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<UserCreditsQuery, UserCreditsQueryVariables>(
        UserCreditsDocument,
        options,
    );
}
export type UserCreditsQueryHookResult = ReturnType<typeof useUserCreditsQuery>;
export type UserCreditsLazyQueryHookResult = ReturnType<typeof useUserCreditsLazyQuery>;
export type UserCreditsSuspenseQueryHookResult = ReturnType<typeof useUserCreditsSuspenseQuery>;
export type UserCreditsQueryResult = Apollo.QueryResult<
    UserCreditsQuery,
    UserCreditsQueryVariables
>;
export const VocabularyItemsDocument = gql`
    query vocabularyItems($lessonIds: [Int!]!, $learningUnitId: Int = null) {
        vocabularyItems(lessonIds: $lessonIds, learningUnitId: $learningUnitId) {
            id
            lessons {
                id
                title
            }
            title
            plural
            wordClass
            gender
            sampleSentenceOne
            item
            isCompleted
            audioUrls {
                target
                translation
            }
        }
    }
`;

/**
 * __useVocabularyItemsQuery__
 *
 * To run a query within a React component, call `useVocabularyItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVocabularyItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVocabularyItemsQuery({
 *   variables: {
 *      lessonIds: // value for 'lessonIds'
 *      learningUnitId: // value for 'learningUnitId'
 *   },
 * });
 */
export function useVocabularyItemsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        VocabularyItemsQuery,
        VocabularyItemsQueryVariables
    > &
        ({ variables: VocabularyItemsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<VocabularyItemsQuery, VocabularyItemsQueryVariables>(
        VocabularyItemsDocument,
        options,
    );
}
export function useVocabularyItemsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        VocabularyItemsQuery,
        VocabularyItemsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<VocabularyItemsQuery, VocabularyItemsQueryVariables>(
        VocabularyItemsDocument,
        options,
    );
}
export function useVocabularyItemsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        VocabularyItemsQuery,
        VocabularyItemsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<VocabularyItemsQuery, VocabularyItemsQueryVariables>(
        VocabularyItemsDocument,
        options,
    );
}
export type VocabularyItemsQueryHookResult = ReturnType<typeof useVocabularyItemsQuery>;
export type VocabularyItemsLazyQueryHookResult = ReturnType<typeof useVocabularyItemsLazyQuery>;
export type VocabularyItemsSuspenseQueryHookResult = ReturnType<
    typeof useVocabularyItemsSuspenseQuery
>;
export type VocabularyItemsQueryResult = Apollo.QueryResult<
    VocabularyItemsQuery,
    VocabularyItemsQueryVariables
>;
export const StudentCurrentSubscriptionTypeDocument = gql`
    query studentCurrentSubscriptionType {
        subscriptions: studentSubscriptionInfo {
            currentSubscription {
                id
                type
            }
        }
    }
`;

/**
 * __useStudentCurrentSubscriptionTypeQuery__
 *
 * To run a query within a React component, call `useStudentCurrentSubscriptionTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentCurrentSubscriptionTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentCurrentSubscriptionTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentCurrentSubscriptionTypeQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        StudentCurrentSubscriptionTypeQuery,
        StudentCurrentSubscriptionTypeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        StudentCurrentSubscriptionTypeQuery,
        StudentCurrentSubscriptionTypeQueryVariables
    >(StudentCurrentSubscriptionTypeDocument, options);
}
export function useStudentCurrentSubscriptionTypeLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        StudentCurrentSubscriptionTypeQuery,
        StudentCurrentSubscriptionTypeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        StudentCurrentSubscriptionTypeQuery,
        StudentCurrentSubscriptionTypeQueryVariables
    >(StudentCurrentSubscriptionTypeDocument, options);
}
export function useStudentCurrentSubscriptionTypeSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        StudentCurrentSubscriptionTypeQuery,
        StudentCurrentSubscriptionTypeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        StudentCurrentSubscriptionTypeQuery,
        StudentCurrentSubscriptionTypeQueryVariables
    >(StudentCurrentSubscriptionTypeDocument, options);
}
export type StudentCurrentSubscriptionTypeQueryHookResult = ReturnType<
    typeof useStudentCurrentSubscriptionTypeQuery
>;
export type StudentCurrentSubscriptionTypeLazyQueryHookResult = ReturnType<
    typeof useStudentCurrentSubscriptionTypeLazyQuery
>;
export type StudentCurrentSubscriptionTypeSuspenseQueryHookResult = ReturnType<
    typeof useStudentCurrentSubscriptionTypeSuspenseQuery
>;
export type StudentCurrentSubscriptionTypeQueryResult = Apollo.QueryResult<
    StudentCurrentSubscriptionTypeQuery,
    StudentCurrentSubscriptionTypeQueryVariables
>;
export const StudentLearningTeamStatusDocument = gql`
    query studentLearningTeamStatus {
        studentLearningTeamInfo {
            courseId
            status
        }
    }
`;

/**
 * __useStudentLearningTeamStatusQuery__
 *
 * To run a query within a React component, call `useStudentLearningTeamStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentLearningTeamStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentLearningTeamStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentLearningTeamStatusQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        StudentLearningTeamStatusQuery,
        StudentLearningTeamStatusQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        StudentLearningTeamStatusQuery,
        StudentLearningTeamStatusQueryVariables
    >(StudentLearningTeamStatusDocument, options);
}
export function useStudentLearningTeamStatusLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        StudentLearningTeamStatusQuery,
        StudentLearningTeamStatusQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        StudentLearningTeamStatusQuery,
        StudentLearningTeamStatusQueryVariables
    >(StudentLearningTeamStatusDocument, options);
}
export function useStudentLearningTeamStatusSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        StudentLearningTeamStatusQuery,
        StudentLearningTeamStatusQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        StudentLearningTeamStatusQuery,
        StudentLearningTeamStatusQueryVariables
    >(StudentLearningTeamStatusDocument, options);
}
export type StudentLearningTeamStatusQueryHookResult = ReturnType<
    typeof useStudentLearningTeamStatusQuery
>;
export type StudentLearningTeamStatusLazyQueryHookResult = ReturnType<
    typeof useStudentLearningTeamStatusLazyQuery
>;
export type StudentLearningTeamStatusSuspenseQueryHookResult = ReturnType<
    typeof useStudentLearningTeamStatusSuspenseQuery
>;
export type StudentLearningTeamStatusQueryResult = Apollo.QueryResult<
    StudentLearningTeamStatusQuery,
    StudentLearningTeamStatusQueryVariables
>;
export const LearningTeamsModuleDocument = gql`
    query learningTeamsModule {
        studentLearningTeamInfo {
            courseId
            modules {
                id
            }
        }
    }
`;

/**
 * __useLearningTeamsModuleQuery__
 *
 * To run a query within a React component, call `useLearningTeamsModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningTeamsModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningTeamsModuleQuery({
 *   variables: {
 *   },
 * });
 */
export function useLearningTeamsModuleQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        LearningTeamsModuleQuery,
        LearningTeamsModuleQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<LearningTeamsModuleQuery, LearningTeamsModuleQueryVariables>(
        LearningTeamsModuleDocument,
        options,
    );
}
export function useLearningTeamsModuleLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        LearningTeamsModuleQuery,
        LearningTeamsModuleQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        LearningTeamsModuleQuery,
        LearningTeamsModuleQueryVariables
    >(LearningTeamsModuleDocument, options);
}
export function useLearningTeamsModuleSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        LearningTeamsModuleQuery,
        LearningTeamsModuleQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        LearningTeamsModuleQuery,
        LearningTeamsModuleQueryVariables
    >(LearningTeamsModuleDocument, options);
}
export type LearningTeamsModuleQueryHookResult = ReturnType<typeof useLearningTeamsModuleQuery>;
export type LearningTeamsModuleLazyQueryHookResult = ReturnType<
    typeof useLearningTeamsModuleLazyQuery
>;
export type LearningTeamsModuleSuspenseQueryHookResult = ReturnType<
    typeof useLearningTeamsModuleSuspenseQuery
>;
export type LearningTeamsModuleQueryResult = Apollo.QueryResult<
    LearningTeamsModuleQuery,
    LearningTeamsModuleQueryVariables
>;
export const LearningTeamsCourseDateDocument = gql`
    query learningTeamsCourseDate {
        studentLearningTeamInfo {
            courseId
            courseEndDate
            courseStartDate
        }
    }
`;

/**
 * __useLearningTeamsCourseDateQuery__
 *
 * To run a query within a React component, call `useLearningTeamsCourseDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningTeamsCourseDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningTeamsCourseDateQuery({
 *   variables: {
 *   },
 * });
 */
export function useLearningTeamsCourseDateQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        LearningTeamsCourseDateQuery,
        LearningTeamsCourseDateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        LearningTeamsCourseDateQuery,
        LearningTeamsCourseDateQueryVariables
    >(LearningTeamsCourseDateDocument, options);
}
export function useLearningTeamsCourseDateLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        LearningTeamsCourseDateQuery,
        LearningTeamsCourseDateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        LearningTeamsCourseDateQuery,
        LearningTeamsCourseDateQueryVariables
    >(LearningTeamsCourseDateDocument, options);
}
export function useLearningTeamsCourseDateSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        LearningTeamsCourseDateQuery,
        LearningTeamsCourseDateQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        LearningTeamsCourseDateQuery,
        LearningTeamsCourseDateQueryVariables
    >(LearningTeamsCourseDateDocument, options);
}
export type LearningTeamsCourseDateQueryHookResult = ReturnType<
    typeof useLearningTeamsCourseDateQuery
>;
export type LearningTeamsCourseDateLazyQueryHookResult = ReturnType<
    typeof useLearningTeamsCourseDateLazyQuery
>;
export type LearningTeamsCourseDateSuspenseQueryHookResult = ReturnType<
    typeof useLearningTeamsCourseDateSuspenseQuery
>;
export type LearningTeamsCourseDateQueryResult = Apollo.QueryResult<
    LearningTeamsCourseDateQuery,
    LearningTeamsCourseDateQueryVariables
>;
export const PrivacyPolicyDocument = gql`
    query privacyPolicy {
        privacyPolicy {
            body
        }
    }
`;

/**
 * __usePrivacyPolicyQuery__
 *
 * To run a query within a React component, call `usePrivacyPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivacyPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivacyPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrivacyPolicyQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        PrivacyPolicyQuery,
        PrivacyPolicyQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(
        PrivacyPolicyDocument,
        options,
    );
}
export function usePrivacyPolicyLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        PrivacyPolicyQuery,
        PrivacyPolicyQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(
        PrivacyPolicyDocument,
        options,
    );
}
export function usePrivacyPolicySuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        PrivacyPolicyQuery,
        PrivacyPolicyQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(
        PrivacyPolicyDocument,
        options,
    );
}
export type PrivacyPolicyQueryHookResult = ReturnType<typeof usePrivacyPolicyQuery>;
export type PrivacyPolicyLazyQueryHookResult = ReturnType<typeof usePrivacyPolicyLazyQuery>;
export type PrivacyPolicySuspenseQueryHookResult = ReturnType<typeof usePrivacyPolicySuspenseQuery>;
export type PrivacyPolicyQueryResult = Apollo.QueryResult<
    PrivacyPolicyQuery,
    PrivacyPolicyQueryVariables
>;
export const StudentNotificationsDocument = gql`
    query studentNotifications {
        studentNotification {
            ...StudentNotification
        }
    }
    ${StudentNotificationFragmentDoc}
`;

/**
 * __useStudentNotificationsQuery__
 *
 * To run a query within a React component, call `useStudentNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentNotificationsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        StudentNotificationsQuery,
        StudentNotificationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<StudentNotificationsQuery, StudentNotificationsQueryVariables>(
        StudentNotificationsDocument,
        options,
    );
}
export function useStudentNotificationsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        StudentNotificationsQuery,
        StudentNotificationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        StudentNotificationsQuery,
        StudentNotificationsQueryVariables
    >(StudentNotificationsDocument, options);
}
export function useStudentNotificationsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        StudentNotificationsQuery,
        StudentNotificationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        StudentNotificationsQuery,
        StudentNotificationsQueryVariables
    >(StudentNotificationsDocument, options);
}
export type StudentNotificationsQueryHookResult = ReturnType<typeof useStudentNotificationsQuery>;
export type StudentNotificationsLazyQueryHookResult = ReturnType<
    typeof useStudentNotificationsLazyQuery
>;
export type StudentNotificationsSuspenseQueryHookResult = ReturnType<
    typeof useStudentNotificationsSuspenseQuery
>;
export type StudentNotificationsQueryResult = Apollo.QueryResult<
    StudentNotificationsQuery,
    StudentNotificationsQueryVariables
>;
export const OrientationModulesDocument = gql`
    query orientationModules($sectionName: SectionName!) {
        orientationModules(sectionName: $sectionName)
    }
`;

/**
 * __useOrientationModulesQuery__
 *
 * To run a query within a React component, call `useOrientationModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrientationModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrientationModulesQuery({
 *   variables: {
 *      sectionName: // value for 'sectionName'
 *   },
 * });
 */
export function useOrientationModulesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        OrientationModulesQuery,
        OrientationModulesQueryVariables
    > &
        ({ variables: OrientationModulesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<OrientationModulesQuery, OrientationModulesQueryVariables>(
        OrientationModulesDocument,
        options,
    );
}
export function useOrientationModulesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        OrientationModulesQuery,
        OrientationModulesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<OrientationModulesQuery, OrientationModulesQueryVariables>(
        OrientationModulesDocument,
        options,
    );
}
export function useOrientationModulesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        OrientationModulesQuery,
        OrientationModulesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        OrientationModulesQuery,
        OrientationModulesQueryVariables
    >(OrientationModulesDocument, options);
}
export type OrientationModulesQueryHookResult = ReturnType<typeof useOrientationModulesQuery>;
export type OrientationModulesLazyQueryHookResult = ReturnType<
    typeof useOrientationModulesLazyQuery
>;
export type OrientationModulesSuspenseQueryHookResult = ReturnType<
    typeof useOrientationModulesSuspenseQuery
>;
export type OrientationModulesQueryResult = Apollo.QueryResult<
    OrientationModulesQuery,
    OrientationModulesQueryVariables
>;
export const OrientationLessonDocument = gql`
    query orientationLesson {
        orientationLesson {
            id
            studentStatus
        }
    }
`;

/**
 * __useOrientationLessonQuery__
 *
 * To run a query within a React component, call `useOrientationLessonQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrientationLessonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrientationLessonQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrientationLessonQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        OrientationLessonQuery,
        OrientationLessonQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<OrientationLessonQuery, OrientationLessonQueryVariables>(
        OrientationLessonDocument,
        options,
    );
}
export function useOrientationLessonLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        OrientationLessonQuery,
        OrientationLessonQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<OrientationLessonQuery, OrientationLessonQueryVariables>(
        OrientationLessonDocument,
        options,
    );
}
export function useOrientationLessonSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        OrientationLessonQuery,
        OrientationLessonQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        OrientationLessonQuery,
        OrientationLessonQueryVariables
    >(OrientationLessonDocument, options);
}
export type OrientationLessonQueryHookResult = ReturnType<typeof useOrientationLessonQuery>;
export type OrientationLessonLazyQueryHookResult = ReturnType<typeof useOrientationLessonLazyQuery>;
export type OrientationLessonSuspenseQueryHookResult = ReturnType<
    typeof useOrientationLessonSuspenseQuery
>;
export type OrientationLessonQueryResult = Apollo.QueryResult<
    OrientationLessonQuery,
    OrientationLessonQueryVariables
>;
export const SectionCurriculaDocument = gql`
    query sectionCurricula {
        sectionCurricula {
            ...SectionCurriculum
        }
    }
    ${SectionCurriculumFragmentDoc}
`;

/**
 * __useSectionCurriculaQuery__
 *
 * To run a query within a React component, call `useSectionCurriculaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionCurriculaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionCurriculaQuery({
 *   variables: {
 *   },
 * });
 */
export function useSectionCurriculaQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        SectionCurriculaQuery,
        SectionCurriculaQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<SectionCurriculaQuery, SectionCurriculaQueryVariables>(
        SectionCurriculaDocument,
        options,
    );
}
export function useSectionCurriculaLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        SectionCurriculaQuery,
        SectionCurriculaQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<SectionCurriculaQuery, SectionCurriculaQueryVariables>(
        SectionCurriculaDocument,
        options,
    );
}
export function useSectionCurriculaSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        SectionCurriculaQuery,
        SectionCurriculaQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<SectionCurriculaQuery, SectionCurriculaQueryVariables>(
        SectionCurriculaDocument,
        options,
    );
}
export type SectionCurriculaQueryHookResult = ReturnType<typeof useSectionCurriculaQuery>;
export type SectionCurriculaLazyQueryHookResult = ReturnType<typeof useSectionCurriculaLazyQuery>;
export type SectionCurriculaSuspenseQueryHookResult = ReturnType<
    typeof useSectionCurriculaSuspenseQuery
>;
export type SectionCurriculaQueryResult = Apollo.QueryResult<
    SectionCurriculaQuery,
    SectionCurriculaQueryVariables
>;
export const MarkWidgetAsDisplayedDocument = gql`
    mutation markWidgetAsDisplayed($widgetClass: UserDisplayedWidgetClassEnum!) {
        markWidgetAsDisplayed(widgetClass: $widgetClass) {
            status
        }
    }
`;
export type MarkWidgetAsDisplayedMutationFn = Apollo.MutationFunction<
    MarkWidgetAsDisplayedMutation,
    MarkWidgetAsDisplayedMutationVariables
>;

/**
 * __useMarkWidgetAsDisplayedMutation__
 *
 * To run a mutation, you first call `useMarkWidgetAsDisplayedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkWidgetAsDisplayedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markWidgetAsDisplayedMutation, { data, loading, error }] = useMarkWidgetAsDisplayedMutation({
 *   variables: {
 *      widgetClass: // value for 'widgetClass'
 *   },
 * });
 */
export function useMarkWidgetAsDisplayedMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        MarkWidgetAsDisplayedMutation,
        MarkWidgetAsDisplayedMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        MarkWidgetAsDisplayedMutation,
        MarkWidgetAsDisplayedMutationVariables
    >(MarkWidgetAsDisplayedDocument, options);
}
export type MarkWidgetAsDisplayedMutationHookResult = ReturnType<
    typeof useMarkWidgetAsDisplayedMutation
>;
export type MarkWidgetAsDisplayedMutationResult =
    Apollo.MutationResult<MarkWidgetAsDisplayedMutation>;
export type MarkWidgetAsDisplayedMutationOptions = Apollo.BaseMutationOptions<
    MarkWidgetAsDisplayedMutation,
    MarkWidgetAsDisplayedMutationVariables
>;
export const CurrentStudentSprintDocument = gql`
    query currentStudentSprint {
        currentStudentSprint {
            ...CurrentSprint
        }
    }
    ${CurrentSprintFragmentDoc}
`;

/**
 * __useCurrentStudentSprintQuery__
 *
 * To run a query within a React component, call `useCurrentStudentSprintQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentStudentSprintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentStudentSprintQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentStudentSprintQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        CurrentStudentSprintQuery,
        CurrentStudentSprintQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CurrentStudentSprintQuery, CurrentStudentSprintQueryVariables>(
        CurrentStudentSprintDocument,
        options,
    );
}
export function useCurrentStudentSprintLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        CurrentStudentSprintQuery,
        CurrentStudentSprintQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        CurrentStudentSprintQuery,
        CurrentStudentSprintQueryVariables
    >(CurrentStudentSprintDocument, options);
}
export function useCurrentStudentSprintSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        CurrentStudentSprintQuery,
        CurrentStudentSprintQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        CurrentStudentSprintQuery,
        CurrentStudentSprintQueryVariables
    >(CurrentStudentSprintDocument, options);
}
export type CurrentStudentSprintQueryHookResult = ReturnType<typeof useCurrentStudentSprintQuery>;
export type CurrentStudentSprintLazyQueryHookResult = ReturnType<
    typeof useCurrentStudentSprintLazyQuery
>;
export type CurrentStudentSprintSuspenseQueryHookResult = ReturnType<
    typeof useCurrentStudentSprintSuspenseQuery
>;
export type CurrentStudentSprintQueryResult = Apollo.QueryResult<
    CurrentStudentSprintQuery,
    CurrentStudentSprintQueryVariables
>;
export const CompleteChecklistItemsDocument = gql`
    mutation completeChecklistItems($slugs: [ChecklistItemSlug!]!) {
        completeChecklistItems(slugs: $slugs) {
            id
            isCompleted
        }
    }
`;
export type CompleteChecklistItemsMutationFn = Apollo.MutationFunction<
    CompleteChecklistItemsMutation,
    CompleteChecklistItemsMutationVariables
>;

/**
 * __useCompleteChecklistItemsMutation__
 *
 * To run a mutation, you first call `useCompleteChecklistItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteChecklistItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeChecklistItemsMutation, { data, loading, error }] = useCompleteChecklistItemsMutation({
 *   variables: {
 *      slugs: // value for 'slugs'
 *   },
 * });
 */
export function useCompleteChecklistItemsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CompleteChecklistItemsMutation,
        CompleteChecklistItemsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CompleteChecklistItemsMutation,
        CompleteChecklistItemsMutationVariables
    >(CompleteChecklistItemsDocument, options);
}
export type CompleteChecklistItemsMutationHookResult = ReturnType<
    typeof useCompleteChecklistItemsMutation
>;
export type CompleteChecklistItemsMutationResult =
    Apollo.MutationResult<CompleteChecklistItemsMutation>;
export type CompleteChecklistItemsMutationOptions = Apollo.BaseMutationOptions<
    CompleteChecklistItemsMutation,
    CompleteChecklistItemsMutationVariables
>;
export const SchedulePauseDocument = gql`
    mutation schedulePause($pauseStartTime: DateTime!, $pauseEndTime: DateTime!) {
        schedulePause(pauseStartTime: $pauseStartTime, pauseEndTime: $pauseEndTime)
    }
`;
export type SchedulePauseMutationFn = Apollo.MutationFunction<
    SchedulePauseMutation,
    SchedulePauseMutationVariables
>;

/**
 * __useSchedulePauseMutation__
 *
 * To run a mutation, you first call `useSchedulePauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchedulePauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schedulePauseMutation, { data, loading, error }] = useSchedulePauseMutation({
 *   variables: {
 *      pauseStartTime: // value for 'pauseStartTime'
 *      pauseEndTime: // value for 'pauseEndTime'
 *   },
 * });
 */
export function useSchedulePauseMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SchedulePauseMutation,
        SchedulePauseMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<SchedulePauseMutation, SchedulePauseMutationVariables>(
        SchedulePauseDocument,
        options,
    );
}
export type SchedulePauseMutationHookResult = ReturnType<typeof useSchedulePauseMutation>;
export type SchedulePauseMutationResult = Apollo.MutationResult<SchedulePauseMutation>;
export type SchedulePauseMutationOptions = Apollo.BaseMutationOptions<
    SchedulePauseMutation,
    SchedulePauseMutationVariables
>;
export const UpcomingAppointmentDatesDocument = gql`
    query upcomingAppointmentDates {
        upcomingAppointmentDates
    }
`;

/**
 * __useUpcomingAppointmentDatesQuery__
 *
 * To run a query within a React component, call `useUpcomingAppointmentDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingAppointmentDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingAppointmentDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpcomingAppointmentDatesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        UpcomingAppointmentDatesQuery,
        UpcomingAppointmentDatesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        UpcomingAppointmentDatesQuery,
        UpcomingAppointmentDatesQueryVariables
    >(UpcomingAppointmentDatesDocument, options);
}
export function useUpcomingAppointmentDatesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        UpcomingAppointmentDatesQuery,
        UpcomingAppointmentDatesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        UpcomingAppointmentDatesQuery,
        UpcomingAppointmentDatesQueryVariables
    >(UpcomingAppointmentDatesDocument, options);
}
export function useUpcomingAppointmentDatesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        UpcomingAppointmentDatesQuery,
        UpcomingAppointmentDatesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        UpcomingAppointmentDatesQuery,
        UpcomingAppointmentDatesQueryVariables
    >(UpcomingAppointmentDatesDocument, options);
}
export type UpcomingAppointmentDatesQueryHookResult = ReturnType<
    typeof useUpcomingAppointmentDatesQuery
>;
export type UpcomingAppointmentDatesLazyQueryHookResult = ReturnType<
    typeof useUpcomingAppointmentDatesLazyQuery
>;
export type UpcomingAppointmentDatesSuspenseQueryHookResult = ReturnType<
    typeof useUpcomingAppointmentDatesSuspenseQuery
>;
export type UpcomingAppointmentDatesQueryResult = Apollo.QueryResult<
    UpcomingAppointmentDatesQuery,
    UpcomingAppointmentDatesQueryVariables
>;
export const AppointmentsCountDocument = gql`
    query appointmentsCount {
        upcomingAppointmentsCount
        pastAppointmentsCount
    }
`;

/**
 * __useAppointmentsCountQuery__
 *
 * To run a query within a React component, call `useAppointmentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppointmentsCountQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        AppointmentsCountQuery,
        AppointmentsCountQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<AppointmentsCountQuery, AppointmentsCountQueryVariables>(
        AppointmentsCountDocument,
        options,
    );
}
export function useAppointmentsCountLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        AppointmentsCountQuery,
        AppointmentsCountQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<AppointmentsCountQuery, AppointmentsCountQueryVariables>(
        AppointmentsCountDocument,
        options,
    );
}
export function useAppointmentsCountSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        AppointmentsCountQuery,
        AppointmentsCountQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        AppointmentsCountQuery,
        AppointmentsCountQueryVariables
    >(AppointmentsCountDocument, options);
}
export type AppointmentsCountQueryHookResult = ReturnType<typeof useAppointmentsCountQuery>;
export type AppointmentsCountLazyQueryHookResult = ReturnType<typeof useAppointmentsCountLazyQuery>;
export type AppointmentsCountSuspenseQueryHookResult = ReturnType<
    typeof useAppointmentsCountSuspenseQuery
>;
export type AppointmentsCountQueryResult = Apollo.QueryResult<
    AppointmentsCountQuery,
    AppointmentsCountQueryVariables
>;
export const TeacherUiEventDataDocument = gql`
    query teacherUiEventData {
        teacher {
            id
            uid
            section {
                name
            }
        }
    }
`;

/**
 * __useTeacherUiEventDataQuery__
 *
 * To run a query within a React component, call `useTeacherUiEventDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherUiEventDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherUiEventDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherUiEventDataQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherUiEventDataQuery,
        TeacherUiEventDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TeacherUiEventDataQuery, TeacherUiEventDataQueryVariables>(
        TeacherUiEventDataDocument,
        options,
    );
}
export function useTeacherUiEventDataLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherUiEventDataQuery,
        TeacherUiEventDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TeacherUiEventDataQuery, TeacherUiEventDataQueryVariables>(
        TeacherUiEventDataDocument,
        options,
    );
}
export function useTeacherUiEventDataSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherUiEventDataQuery,
        TeacherUiEventDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherUiEventDataQuery,
        TeacherUiEventDataQueryVariables
    >(TeacherUiEventDataDocument, options);
}
export type TeacherUiEventDataQueryHookResult = ReturnType<typeof useTeacherUiEventDataQuery>;
export type TeacherUiEventDataLazyQueryHookResult = ReturnType<
    typeof useTeacherUiEventDataLazyQuery
>;
export type TeacherUiEventDataSuspenseQueryHookResult = ReturnType<
    typeof useTeacherUiEventDataSuspenseQuery
>;
export type TeacherUiEventDataQueryResult = Apollo.QueryResult<
    TeacherUiEventDataQuery,
    TeacherUiEventDataQueryVariables
>;
export const ShouldDisplayClassroomPromoBannerTeacherDocument = gql`
    query shouldDisplayClassroomPromoBannerTeacher {
        shouldDisplayClassroomPromoBanner: isWidgetEligibleForDisplay(
            widgetClass: TeacherFutureClassInClassroomBanner
        )
    }
`;

/**
 * __useShouldDisplayClassroomPromoBannerTeacherQuery__
 *
 * To run a query within a React component, call `useShouldDisplayClassroomPromoBannerTeacherQuery` and pass it any options that fit your needs.
 * When your component renders, `useShouldDisplayClassroomPromoBannerTeacherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShouldDisplayClassroomPromoBannerTeacherQuery({
 *   variables: {
 *   },
 * });
 */
export function useShouldDisplayClassroomPromoBannerTeacherQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        ShouldDisplayClassroomPromoBannerTeacherQuery,
        ShouldDisplayClassroomPromoBannerTeacherQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        ShouldDisplayClassroomPromoBannerTeacherQuery,
        ShouldDisplayClassroomPromoBannerTeacherQueryVariables
    >(ShouldDisplayClassroomPromoBannerTeacherDocument, options);
}
export function useShouldDisplayClassroomPromoBannerTeacherLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        ShouldDisplayClassroomPromoBannerTeacherQuery,
        ShouldDisplayClassroomPromoBannerTeacherQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        ShouldDisplayClassroomPromoBannerTeacherQuery,
        ShouldDisplayClassroomPromoBannerTeacherQueryVariables
    >(ShouldDisplayClassroomPromoBannerTeacherDocument, options);
}
export function useShouldDisplayClassroomPromoBannerTeacherSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        ShouldDisplayClassroomPromoBannerTeacherQuery,
        ShouldDisplayClassroomPromoBannerTeacherQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        ShouldDisplayClassroomPromoBannerTeacherQuery,
        ShouldDisplayClassroomPromoBannerTeacherQueryVariables
    >(ShouldDisplayClassroomPromoBannerTeacherDocument, options);
}
export type ShouldDisplayClassroomPromoBannerTeacherQueryHookResult = ReturnType<
    typeof useShouldDisplayClassroomPromoBannerTeacherQuery
>;
export type ShouldDisplayClassroomPromoBannerTeacherLazyQueryHookResult = ReturnType<
    typeof useShouldDisplayClassroomPromoBannerTeacherLazyQuery
>;
export type ShouldDisplayClassroomPromoBannerTeacherSuspenseQueryHookResult = ReturnType<
    typeof useShouldDisplayClassroomPromoBannerTeacherSuspenseQuery
>;
export type ShouldDisplayClassroomPromoBannerTeacherQueryResult = Apollo.QueryResult<
    ShouldDisplayClassroomPromoBannerTeacherQuery,
    ShouldDisplayClassroomPromoBannerTeacherQueryVariables
>;
export const TeacherSetPauseDocument = gql`
    mutation TeacherSetPause($pauseFrom: DateTime!, $pauseUntil: DateTime!) {
        pauseTeaching(pausedFrom: $pauseFrom, pausedUntil: $pauseUntil) {
            ...TeacherPauseTeacher
        }
    }
    ${TeacherPauseTeacherFragmentDoc}
`;
export type TeacherSetPauseMutationFn = Apollo.MutationFunction<
    TeacherSetPauseMutation,
    TeacherSetPauseMutationVariables
>;

/**
 * __useTeacherSetPauseMutation__
 *
 * To run a mutation, you first call `useTeacherSetPauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherSetPauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherSetPauseMutation, { data, loading, error }] = useTeacherSetPauseMutation({
 *   variables: {
 *      pauseFrom: // value for 'pauseFrom'
 *      pauseUntil: // value for 'pauseUntil'
 *   },
 * });
 */
export function useTeacherSetPauseMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        TeacherSetPauseMutation,
        TeacherSetPauseMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<TeacherSetPauseMutation, TeacherSetPauseMutationVariables>(
        TeacherSetPauseDocument,
        options,
    );
}
export type TeacherSetPauseMutationHookResult = ReturnType<typeof useTeacherSetPauseMutation>;
export type TeacherSetPauseMutationResult = Apollo.MutationResult<TeacherSetPauseMutation>;
export type TeacherSetPauseMutationOptions = Apollo.BaseMutationOptions<
    TeacherSetPauseMutation,
    TeacherSetPauseMutationVariables
>;
export const TeacherRemovePauseDocument = gql`
    mutation TeacherRemovePause {
        removePauseTeaching {
            ...TeacherPauseTeacher
        }
    }
    ${TeacherPauseTeacherFragmentDoc}
`;
export type TeacherRemovePauseMutationFn = Apollo.MutationFunction<
    TeacherRemovePauseMutation,
    TeacherRemovePauseMutationVariables
>;

/**
 * __useTeacherRemovePauseMutation__
 *
 * To run a mutation, you first call `useTeacherRemovePauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherRemovePauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherRemovePauseMutation, { data, loading, error }] = useTeacherRemovePauseMutation({
 *   variables: {
 *   },
 * });
 */
export function useTeacherRemovePauseMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        TeacherRemovePauseMutation,
        TeacherRemovePauseMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        TeacherRemovePauseMutation,
        TeacherRemovePauseMutationVariables
    >(TeacherRemovePauseDocument, options);
}
export type TeacherRemovePauseMutationHookResult = ReturnType<typeof useTeacherRemovePauseMutation>;
export type TeacherRemovePauseMutationResult = Apollo.MutationResult<TeacherRemovePauseMutation>;
export type TeacherRemovePauseMutationOptions = Apollo.BaseMutationOptions<
    TeacherRemovePauseMutation,
    TeacherRemovePauseMutationVariables
>;
export const TeacherGetPauseDocument = gql`
    query TeacherGetPause {
        teacher {
            ...TeacherPauseTeacher
        }
    }
    ${TeacherPauseTeacherFragmentDoc}
`;

/**
 * __useTeacherGetPauseQuery__
 *
 * To run a query within a React component, call `useTeacherGetPauseQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherGetPauseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherGetPauseQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherGetPauseQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherGetPauseQuery,
        TeacherGetPauseQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TeacherGetPauseQuery, TeacherGetPauseQueryVariables>(
        TeacherGetPauseDocument,
        options,
    );
}
export function useTeacherGetPauseLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherGetPauseQuery,
        TeacherGetPauseQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TeacherGetPauseQuery, TeacherGetPauseQueryVariables>(
        TeacherGetPauseDocument,
        options,
    );
}
export function useTeacherGetPauseSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherGetPauseQuery,
        TeacherGetPauseQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<TeacherGetPauseQuery, TeacherGetPauseQueryVariables>(
        TeacherGetPauseDocument,
        options,
    );
}
export type TeacherGetPauseQueryHookResult = ReturnType<typeof useTeacherGetPauseQuery>;
export type TeacherGetPauseLazyQueryHookResult = ReturnType<typeof useTeacherGetPauseLazyQuery>;
export type TeacherGetPauseSuspenseQueryHookResult = ReturnType<
    typeof useTeacherGetPauseSuspenseQuery
>;
export type TeacherGetPauseQueryResult = Apollo.QueryResult<
    TeacherGetPauseQuery,
    TeacherGetPauseQueryVariables
>;
export const TeacherGetTeacherTimeFormatDocument = gql`
    query TeacherGetTeacherTimeFormat {
        teacher {
            id
            teacherPreferences {
                timeFormat
            }
        }
    }
`;

/**
 * __useTeacherGetTeacherTimeFormatQuery__
 *
 * To run a query within a React component, call `useTeacherGetTeacherTimeFormatQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherGetTeacherTimeFormatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherGetTeacherTimeFormatQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherGetTeacherTimeFormatQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        TeacherGetTeacherTimeFormatQuery,
        TeacherGetTeacherTimeFormatQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        TeacherGetTeacherTimeFormatQuery,
        TeacherGetTeacherTimeFormatQueryVariables
    >(TeacherGetTeacherTimeFormatDocument, options);
}
export function useTeacherGetTeacherTimeFormatLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        TeacherGetTeacherTimeFormatQuery,
        TeacherGetTeacherTimeFormatQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        TeacherGetTeacherTimeFormatQuery,
        TeacherGetTeacherTimeFormatQueryVariables
    >(TeacherGetTeacherTimeFormatDocument, options);
}
export function useTeacherGetTeacherTimeFormatSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        TeacherGetTeacherTimeFormatQuery,
        TeacherGetTeacherTimeFormatQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        TeacherGetTeacherTimeFormatQuery,
        TeacherGetTeacherTimeFormatQueryVariables
    >(TeacherGetTeacherTimeFormatDocument, options);
}
export type TeacherGetTeacherTimeFormatQueryHookResult = ReturnType<
    typeof useTeacherGetTeacherTimeFormatQuery
>;
export type TeacherGetTeacherTimeFormatLazyQueryHookResult = ReturnType<
    typeof useTeacherGetTeacherTimeFormatLazyQuery
>;
export type TeacherGetTeacherTimeFormatSuspenseQueryHookResult = ReturnType<
    typeof useTeacherGetTeacherTimeFormatSuspenseQuery
>;
export type TeacherGetTeacherTimeFormatQueryResult = Apollo.QueryResult<
    TeacherGetTeacherTimeFormatQuery,
    TeacherGetTeacherTimeFormatQueryVariables
>;

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        Purchase: ['ExtraCreditPurchase', 'LegacyPurchase', 'SubscriptionPurchase'],
        SymfonyUserInterface: ['SymfonyUserImpl'],
        UnionClassFeedbackClassTeacherFeedback: ['ClassFeedback', 'ClassTeacherFeedback'],
    },
};
export default result;
