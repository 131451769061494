import { buildValidator } from '@lingoda/forms';
import { trans } from '@lingoda/i18n';

export const validate = buildValidator({
    username: {
        required: trans('form-validation-username-required', {}, 'public-common'),
        email: true,
    },
    password: {
        required: trans('form-validation-password-required', {}, 'public-common'),
        password: true,
    },
});
