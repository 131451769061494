import { lazy } from 'react';
import {
    RouteAccess,
    type RouteConfigExtended,
    addRouterListener,
    addSectionListener,
    errorLanguagePath,
} from '@lingoda/router';
import { NotFoundPage } from '@lingoda/fallbacks';

const ErrorLanguage = lazy(() => import('../components/pages/errors/Language'));

const RouteErrorLanguage = addSectionListener(
    addRouterListener('errorLanguagePath')(ErrorLanguage),
);

const commonRoutes: RouteConfigExtended[] = [
    {
        exact: true,
        component: RouteErrorLanguage,
        path: errorLanguagePath.raw,
        label: 'ErrorLanguage',
        config: {
            access: RouteAccess.Public,
            tab: undefined,
        },
    },
    {
        component: NotFoundPage,
        label: 'PageNotFound',
        config: {
            access: RouteAccess.Public,
            hideFooter: true,
            hideNotifications: true,
            tab: undefined,
        },
    },
];

export default commonRoutes;
