import { addDays, isSameOrBefore } from '@lingoda/dates';
import { useConfigurationQuery } from '@lingoda/graphql';
import { useSelector } from '@lingoda/hooks';
import { localRoundMinuteSelector } from '@lingoda/time';

interface Params {
    startDate: Date;
    teacherId: number | undefined;
}

export const useIsRefundable = ({ startDate, teacherId }: Params) => {
    const now = useSelector(localRoundMinuteSelector);
    const { data } = useConfigurationQuery();

    if (!teacherId) {
        return true;
    }

    const refundDate = addDays(now, data?.config.classConfig.refundableDaysBeforeStart ?? 7);

    return isSameOrBefore(refundDate, startDate);
};
