import {
    addHours,
    addYears,
    formatTimeRemaining,
    isBetween,
    isSameDay,
    toDate,
    toUTCString,
} from '@lingoda/dates';
import { useClosestAppointmentsQuery } from '@lingoda/graphql';
import { useIntervalDate } from '@lingoda/hooks';

export const useStudentAppointment = () => {
    const now = useIntervalDate('minute');
    const { data } = useClosestAppointmentsQuery({
        fetchPolicy: 'no-cache',
        variables: {
            historicalStartDate: toUTCString(addYears(now, -10)),
            ongoingStartDate: toUTCString(addHours(now, -1)),
        },
    });

    const previousAppointment = data?.pastAppointments[0];
    const nextAppointment = data?.futureAppointments[0];

    const isInSession =
        nextAppointment &&
        isBetween(
            toDate(nextAppointment.class.startDate),
            toDate(nextAppointment.class.endDate),
            now,
        );

    const isNextClassToday = nextAppointment
        ? isSameDay(toDate(nextAppointment.class.startDate), now)
        : false;

    const timeRemaining =
        nextAppointment && isNextClassToday
            ? formatTimeRemaining(toDate(nextAppointment.class.startDate), { now })
            : null;

    return {
        previousAppointment,
        nextAppointment,
        isInSession,
        isNextClassToday,
        timeRemaining,
    };
};
