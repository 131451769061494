import ContextProviders from 'components/ContextProviders';
import { type PropsWithChildren } from 'react';
import { ErrorBoundary } from '@lingoda/monitor';
import { Suspense } from '@lingoda/ui';
import { InternalErrorPage } from '@lingoda/fallbacks';
import { getDashLang } from '@lingoda/env';

const FallbackError = () => (
    <ContextProviders locale={getDashLang()}>
        <Suspense variant="linear">
            <InternalErrorPage />
        </Suspense>
    </ContextProviders>
);

export const PageErrorBoundary = ({ children }: PropsWithChildren<unknown>) => (
    <ErrorBoundary fallback={FallbackError}>{children}</ErrorBoundary>
);
