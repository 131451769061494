import { startTransaction as _startTransaction, getCurrentHub } from '@sentry/react';
import type { Transaction } from '@sentry/types';

export type { Span as TraceSpan, Transaction as TraceTransaction } from '@sentry/types';

type TransactionContext = Parameters<typeof _startTransaction>[0] & {
    active?: boolean;
};

export const startTraceTransaction = ({ active, ...context }: TransactionContext) => {
    const transaction = _startTransaction(context);

    if (active) {
        // All spans created during lifecycle of active transaction without explicit parent will be attached to it
        getCurrentHub().configureScope((scope) => scope.setSpan(transaction));
    }

    return transaction;
};

type SpanContext = Parameters<Transaction['startChild']>[0];

export const startTraceSpan = (context: SpanContext, tx?: Transaction) => {
    const transaction = tx || getCurrentHub().getScope()?.getTransaction();

    if (!transaction) {
        return;
    }

    return transaction.startChild(context);
};

export const traceAsSpan = async <T>(
    context: SpanContext,
    asyncFn: () => Promise<T>,
    tx?: Transaction,
): Promise<T> => {
    const span = startTraceSpan(context, tx);

    if (!span) {
        console.warn('No active transaction found, skipping span creation');

        return asyncFn();
    }

    try {
        const result = await asyncFn();
        span.setStatus('ok');

        return result;
    } catch (error) {
        span.setStatus('internal_error');

        throw error;
    } finally {
        span.finish();
    }
};
