/**
 * TODO: Add AppointmentLabel as enum to schema
 * https://lingoda.atlassian.net/browse/LW-22430
 */
export enum AppointmentLabel {
    InSession = 'in-session',
    TeacherFeedbackNotReady = 'teacher-feedback-not-ready',
    TeacherFeedbackReady = 'teacher-feedback-ready',
    ClassCancelled = 'class-cancelled',
    ClassMissed = 'class-missed',
}
