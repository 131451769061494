import { memo } from 'react';
import { type CourseOverviewLessonFragment } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';
import { LessonStatus, computeLessonStatus } from '@lingoda/learning-units';
import { Tooltip } from '@lingoda/ui';
import LessonStatusIcon from './LessonStatusIcon';

const isLessonLocked = (lesson: CourseOverviewLessonFragment) => !lesson.availableWithoutPayment;

interface Props {
    lesson: CourseOverviewLessonFragment;
}

const LessonStatusTooltipComponent = ({ lesson }: Props) => {
    const locked = isLessonLocked(lesson);
    const { studentStatus, completedCurrentVersion } = lesson;
    const lessonStatus = computeLessonStatus({
        studentStatus,
        completedCurrentVersion,
        locked,
    });
    const title = getTitle(lessonStatus);

    return (
        <Tooltip title={title} data-testid="LessonStatusTooltip">
            <LessonStatusIcon lessonStatus={lessonStatus} titleAccess={title} />
        </Tooltip>
    );
};

const getTitle = (lessonStatus: LessonStatus) => {
    switch (lessonStatus) {
        case LessonStatus.Locked:
            return trans('subscribe-to-learn-this-lesson', {}, 'student-common');
        case LessonStatus.Completed:
            return trans('you-have-taken-this-class', {}, 'student-common');
        case LessonStatus.CompletedImproved:
            return trans('we-improved-the-lesson-plan', {}, 'student-common');
        case LessonStatus.Booked:
            return trans('you-have-booked-this-class', {}, 'student-common');
        case LessonStatus.Pending:
            return trans('this-class-being-logged', {}, 'student-common');
        case LessonStatus.Skip:
            return trans('you-have-skipped-this-class', {}, 'student-common');
        default:
            return trans('you-have-not-taken', {}, 'student-common');
    }
};

export const LessonStatusTooltip = memo(LessonStatusTooltipComponent);
