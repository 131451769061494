import { useSelector } from '@lingoda/hooks';
import { studentHasCreditsSelector } from '@lingoda/students';
import { isSprintSubscriptionSelector } from '@lingoda/subscriptions';

const useCanBookOnboardingClasses = () => {
    const isSprint = useSelector(isSprintSubscriptionSelector);
    const hasCredits = useSelector(studentHasCreditsSelector);

    return canBookOnboardingClasses(isSprint, hasCredits);
};

export const canBookOnboardingClasses = (isSprint: boolean, hasCredits: boolean) =>
    !isSprint && hasCredits;

export default useCanBookOnboardingClasses;
