import { getLang } from '@lingoda/env';

export * from './buildQueryString';
export * from './buildUrl';
export * from './extractRelativePath';
export * from './getAppByDomain';
export * from './getBaseUri';
export * from './getCurrentPath';
export * from './getHost';
export * from './getNewLangLocation';
export * from './getPath';
export * from './getQueryParam';
export * from './getQueryParams';
export * from './getRawPath';
export * from './getSchema';
export * from './getSchemaHost';
export * from './getTopLevelDomain';
export * from './getUrl';
export * from './isAbsolute';
export * from './isInTeacherDomain';
export * from './isLocalHost';
export * from './queryParamsDecoder';
export { toMarketingUrl } from './replaceDomain';
export * from './resolveProtocol';

export enum SupportUrlType {
    // student urls
    Home = 'home',
    Apple = 'apple',
    Facebook = 'facebook',
    Class = 'class',
    CancelClass = 'cancelClass',
    Lingobites = 'lingobites',
    Recording = 'recording',
    TechnicalRequirements = 'technicalRequirements',
    PersonalizedClassFeedbackFromTeacher = 'personalizedClassFeedbackFromTeacher',
    ClassroomStudentFAQ = 'classroomStudentFAQ',
    // teacher urls
    TeacherLogClasses = 'teacherLogClasses',
    TeacherCancelClass = 'teacherCancelClass',
}

const elevioBase = 'https://lingoda-students.elevio.help';
const elevioTeacherBase = 'https://lingodateachers.elevio.help';

export const getSupportUrl = (type: SupportUrlType) =>
    ({
        // student urls
        home: elevioBase,
        apple: `${elevioBase}/${getLang()}/articles/256`,
        facebook: `${elevioBase}/${getLang()}/articles/247`,
        class: `${elevioBase}/${getLang()}/articles/157`,
        cancelClass: `${elevioBase}/${getLang()}/articles/230`,
        lingobites: `${elevioBase}/${getLang()}/articles/349-lingobites-unsere-neue-uebungsfunktion`,
        recording: `${elevioBase}/${getLang()}/articles/153-are-the-chat-notes-from-my-class-saved`,
        technicalRequirements: `${elevioBase}/${getLang()}/articles/175`,
        personalizedClassFeedbackFromTeacher: `${elevioBase}/${getLang()}/articles/305-personalised-class-feedback`,
        classroomStudentFAQ: `${elevioBase}/${getLang()}/articles/154-how-do-i-attend-my-classes`,
        // teacher urls
        teacherLogClasses: `${elevioTeacherBase}/${getLang()}/articles/70-how-to-log-your-classes`,
        teacherCancelClass: `${elevioTeacherBase}/${getLang()}/articles/60-cancelling-classes`,
    })[type];
