import {
    Integrations as BaseIntegrations,
    Replay,
    init,
    reactRouterV5Instrumentation,
    setTag,
} from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { withoutGraphQLFetch } from 'apollo-link-sentry';
import { getApp } from '@lingoda/env';
import { config } from '@lingoda/config';
import { beforeBreadcrumb } from './breadcrumbs';
import { isSentryTrackingEnabled } from './isSentryTrackingEnabled';

const ERROR_NAMES_TO_IGNORE = [
    'ChunkLoadError',
    'React ErrorBoundary Error',
    'top.GLOBALS',
    'Non-Error exception captured',
    `TypeError: null is not an object (evaluating 'n.title')`,
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    'This class is no longer available', // LW-24978 known issue when 2 teacher trying to book the same class
    `Can't find variable: disableWebkitEnterFullscreen`, // LW-29773 third party issue
];

const escapeRegExp = (text: string | undefined = '') =>
    text.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');

export const initErrorTracker = (history: RouterHistory) => {
    if (!isSentryTrackingEnabled()) {
        return;
    }

    init({
        dsn: config.sentryFrontendDsn,
        environment: config.deployEnvironment,
        release: config.releaseId,
        ignoreErrors: ERROR_NAMES_TO_IGNORE,
        allowUrls: [/lingoda\.(com|xyz|org|wip)/i, /linguando\.com/i],
        integrations: [
            new BaseIntegrations.GlobalHandlers({
                onerror: true,
                onunhandledrejection: false,
            }),
            new TracingIntegrations.BrowserTracing({
                tracingOrigins: [
                    /^\//,
                    new RegExp(
                        `^(?!${escapeRegExp(config.kinesisEndpoint)}).*(lingoda|linguando).*`,
                    ),
                ],
                // TODO: We should also pass static routes and matchPath, but couldn't get past type errors…
                routingInstrumentation: reactRouterV5Instrumentation(history),
            }),
            new Replay({
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],
        attachStacktrace: true,
        tracesSampler: (context) => {
            if (context.transactionContext.metadata?.sampleRate) {
                return context.transactionContext.metadata.sampleRate;
            }

            return 1 / 50;
        },
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        beforeBreadcrumb: withoutGraphQLFetch(beforeBreadcrumb),
    });

    setTag('lingoda.app', getApp());
};

type RouterHistory = Parameters<typeof reactRouterV5Instrumentation>[0];
