import { useCallback } from 'react';
import { addDays, createDate, diffInUnits } from '@lingoda/dates';
import { useAppointmentsCountQuery } from '@lingoda/graphql';
import { usePersistentState, useSelector } from '@lingoda/hooks';
import { canRolloverCurrentSubscriptionSelector, currentSubscriptionSelector } from '../expanded';

export const useShowRolloverTrialBanner = () => {
    const [closedBanner, setCloseBanner] = usePersistentState<boolean>('SKIP_TRIAL_BANNER', false);
    const subscription = useSelector(currentSubscriptionSelector);
    const canRolloverCurrentSubscription = useSelector(canRolloverCurrentSubscriptionSelector);

    const { data } = useAppointmentsCountQuery({ fetchPolicy: 'cache-and-network' });
    const { upcomingAppointmentsCount = 0, pastAppointmentsCount = 0 } = data || {};

    const closeBanner = useCallback(() => {
        setCloseBanner(true);
    }, [setCloseBanner]);

    const showBanner =
        !closedBanner &&
        canRolloverCurrentSubscription &&
        (pastAppointmentsCount > 0 ||
            (upcomingAppointmentsCount > 0 &&
                diffInUnits('days', createDate(), addDays(subscription?.lastChargedAt as Date, 5)) >
                    0));

    return [showBanner, closeBanner] as const;
};
