import type { FieldPolicy, Reference } from '@apollo/client';

export const teacherLearningMaterials: FieldPolicy<{ items: Reference[] }> = {
    merge(existing, incoming, { readField }) {
        const items = existing?.items ? existing.items : [];

        const map = new Map<number, Reference>(
            items.map((item: Reference) => [readField('id', item) as number, item]),
        );
        incoming.items.forEach((item: Reference) => {
            map.set(readField('id', item) as number, item);
        });

        return {
            ...incoming,
            items: Array.from(map.values()),
        };
    },
    read(existing) {
        return existing;
    },
};
