import { createDate } from '@lingoda/dates';
import { useClassStateStudentQuery } from '@lingoda/graphql';
import {
    getClassHasEnded,
    getClassHasStarted,
    getClassIsLive,
    getClassIsMissed,
} from '@lingoda/classes-helpers';
import { useIntervalDate } from '@lingoda/hooks';
import type { ClassId } from '../../types';

export const useClassStateStudent = (classId: ClassId | undefined) => {
    const { data, loading } = useClassStateStudentQuery({
        variables: { classId: classId as ClassId },
        skip: !classId,
    });
    const startDate = data && createDate(data.class.startDate);
    const endDate = data && createDate(data.class.endDate);
    const appointmentStatus = data?.class.appointment?.status;

    const currentTime = useIntervalDate('minute');

    return {
        loading,
        hasStarted: getClassHasStarted(startDate, currentTime),
        hasEnded: getClassHasEnded(endDate, currentTime),
        isLive: getClassIsLive(startDate, endDate, currentTime),
        isMissed: getClassIsMissed(appointmentStatus),
    };
};
