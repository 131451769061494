import { gtmAddEvent, updateGtmUserData } from '@lingoda/analytics';
import {
    cancelSubscription as cancelSubscriptionApi,
    changeSubscription as changeSubscriptionApi,
    getAllSubscriptionsByStudent,
    uncancelSubscription as uncancelSubscriptionApi,
    unpauseSubscription as unpauseSubscriptionApi,
} from '@lingoda/api';
import type { SubscriptionsResponse } from '@lingoda/api';
import { userSelector } from '@lingoda/auth';
import { addCallback, addTrackerCallback } from '@lingoda/core';
import { createDate } from '@lingoda/dates';
import { studentSelector } from '@lingoda/students';
import { getPlatform, platform } from '@lingoda/utils';
import {
    changeSubscription,
    confirmCancelSubscription,
    fetchSubscriptions,
    setSubscriptions,
    uncancelSubscription,
    unpauseSubscription,
} from './actions';
import type { Subscription, SubscriptionStatus } from './models';

export default () => {
    addTrackerCallback(fetchSubscriptions, (action, store) => {
        const student = studentSelector(store.getState());
        const studentId = action?.payload || student?.id;

        return studentId ? getAllSubscriptionsByStudent(studentId) : Promise.reject();
    });

    addTrackerCallback(uncancelSubscription, ({ payload }) => uncancelSubscriptionApi(payload));

    addCallback(uncancelSubscription.success, (_, store) => {
        store.dispatch(fetchSubscriptions());
    });

    addTrackerCallback(unpauseSubscription, ({ payload }) => unpauseSubscriptionApi(payload));

    addTrackerCallback(confirmCancelSubscription, ({ payload }) =>
        cancelSubscriptionApi(payload.subscriptionId),
    );

    addTrackerCallback(changeSubscription, ({ payload }) => changeSubscriptionApi(payload.payload));

    addCallback(changeSubscription.success, (action, store) => {
        const { data: subscriptions } = action.payload.result as SubscriptionsResponse;
        const user = userSelector(store.getState());

        const actualSubscriptionIndex = subscriptions.findIndex(
            (subscription) => !subscription.expiresAt,
        );
        const previousSubscription =
            actualSubscriptionIndex === 0 ? null : subscriptions[actualSubscriptionIndex - 1];

        const { purchase } = action.payload.payload.payload;

        gtmAddEvent('subscriptionChanged', {
            transactionRevenue: `${purchase.chargeTotal.amountInMajor}`,
            transactionCurrency: purchase.chargeTotal.currency,
            product_type: purchase.type,
            product_name: `${purchase.displayName}`,
            purchase_type:
                purchase.__typename === 'LegacyPurchase' && purchase.withTrial ? 'trial' : 'direct',
            discountValue: `${purchase.discount.amountInMajor}`,
            promoCode: purchase.coupons.map((coupon) => coupon.code).join(', '),
            userEmail: user?.email ?? '',
            firstName: user?.firstName ?? '',
            lastName: user?.lastName ?? '',
            isFirstSubscription: `${!previousSubscription?.createdAt}`,
        });
    });
    addCallback([changeSubscription.success, fetchSubscriptions.success], (action, store) => {
        const { data } = action.payload.result as SubscriptionsResponse;

        const subscriptions: Subscription[] = data.map((subscription) => ({
            ...subscription,
            createdAt: createDate(subscription.createdAt),
            status: subscription.status as SubscriptionStatus,
            expiresAt: subscription.expiresAt && createDate(subscription.expiresAt),
            lastChargedAt: subscription.lastChargedAt && createDate(subscription.lastChargedAt),
            nextChargeAt: subscription.nextChargeAt && createDate(subscription.nextChargeAt),
            pausedAt: subscription.pausedAt && createDate(subscription.pausedAt),
            resumesAt: subscription.resumesAt && createDate(subscription.resumesAt),
        })) as Subscription[];
        store.dispatch(setSubscriptions(subscriptions));
        if (getPlatform() === platform.web) {
            store.dispatch(updateGtmUserData());
        }
    });
};
