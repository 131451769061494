import { type ComponentType, useEffect } from 'react';
import { transformSectionName } from '@lingoda/sections';
import { type SectionNameTranslation } from '@lingoda/config';
import { useDispatch } from '@lingoda/hooks';
import useParams from '../hooks/useParams';
import { setCurrentSection } from '../route';
import { wrapDisplayName } from '../utils';

interface SectionParams {
    section?: SectionNameTranslation | undefined;
}

export const addSectionListener = <Props extends Record<string, unknown>>(
    Component: ComponentType<Props>,
) => {
    const SectionListener = (props: Props) => {
        const dispatch = useDispatch();
        const { section } = useParams<{
            section: SectionParams['section'];
        }>();

        useEffect(() => {
            if (section) {
                dispatch(setCurrentSection(transformSectionName(section)));
            }
        }, [dispatch, section]);

        return <Component {...props} />;
    };

    SectionListener.displayName = wrapDisplayName(Component, 'SectionListener');

    return SectionListener;
};
