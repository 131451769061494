import { memo } from 'react';
import { Grid, MeetingCard } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import { createDate } from '@lingoda/dates';
import { resolvePath, studentClassDetailsPath } from '@lingoda/router';
import { sortClassStudents } from '@lingoda/students';
import { AppointmentStatus, type ClassCardAppointmentFragment } from '@lingoda/graphql';
import { ClassTag } from '../ClassTag';
import { buildClassSubtitle } from '../../utils';

interface Props {
    appointment: ClassCardAppointmentFragment;
    studentId: number;
    showTime?: boolean;
    secondColumn?: React.ReactNode;
    onClick?: () => void;
}

const ClassCardComponent = ({ appointment, studentId, showTime, secondColumn, onClick }: Props) => {
    const { klass } = appointment;
    const { lesson, teacher } = klass;

    const participants = klass.students
        ?.map((student) => ({
            id: student.id,
            photo: student.photo?.urls.medium,
            name: student.firstLetterOfFirstName,
        }))
        .sort(sortClassStudents(studentId));

    const linkTo = resolvePath(studentClassDetailsPath({ class_id: klass.uid }));
    const titleSuffix =
        teacher?.firstName &&
        trans('with-teacher', { teacherName: teacher.firstName }, 'student-common');
    const subtitle = `${buildClassSubtitle(klass.type)} • ${lesson?.label}`;
    const isActive = appointment.status === AppointmentStatus.Active;

    return (
        <MeetingCard
            title={lesson?.title || undefined}
            titleSuffix={titleSuffix}
            subtitle={subtitle}
            avatarSrc={teacher?.photo?.urls.medium}
            avatarAlt={teacher?.firstName}
            startDate={createDate(klass.startDate)}
            endDate={createDate(klass.endDate)}
            hasHost={!!teacher}
            participants={participants}
            isActive={isActive}
            linkTo={linkTo}
            showTime={showTime}
            secondColumn={secondColumn}
            onClick={onClick}
        >
            {appointment.label && (
                <Grid item mt={1}>
                    <ClassTag label={appointment.label} />
                </Grid>
            )}
        </MeetingCard>
    );
};

export const ClassCard = memo(ClassCardComponent);
