import { createDate, diffInMinutes } from '@lingoda/dates';
import { isPresentationFeatureEnabled } from '@lingoda/feature-flags';
import { config } from '@lingoda/config';
import type { TypePolicy } from '@apollo/client';

export const Klass: TypePolicy = {
    keyFields: ['uid'],
    fields: {
        duration: (_, { readField }) => {
            const startDate = readField<string>('startDate');
            const endDate = readField<string>('endDate');

            return diffInMinutes(createDate(endDate), createDate(startDate));
        },
        isPresentationMode: (value) => {
            if (config.deployEnvironment === 'production') {
                return value;
            }

            return isPresentationFeatureEnabled() || value;
        },
    },
};
