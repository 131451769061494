import { httpPost } from '@lingoda/http';
import { getUrl } from '@lingoda/urls';
import type {
    AppointmentSchema,
    ClassSchema,
    LearningUnitSchema,
    LessonSchema,
    ModuleSchema,
    StudentSchema,
    TeacherSchema,
} from './common';

interface IncludedResponseAttributes {
    learningUnits?: LearningUnitSchema[];
    lessons?: LessonSchema[];
    modules?: ModuleSchema[];
    teachers?: TeacherSchema[];
    students?: StudentSchema[];
}

interface RootAppointmentSchema extends AppointmentSchema {
    class: ClassSchema;
}

export interface AppointmentResponse {
    data: RootAppointmentSchema;
    included: IncludedResponseAttributes;
}

interface CreateAppointmentResponse {
    data: RootAppointmentSchema;
    included: IncludedResponseAttributes;
}

export const createAppointment = (
    classId: string,
    studentId: string,
    stats: string | undefined,
): Promise<CreateAppointmentResponse> =>
    httpPost(getUrl('api_classes_appointments_create', { uniqueId: classId }), {
        classId,
        studentId,
        stats,
    });
