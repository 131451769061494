import { Button, Grid, Hidden } from '@lingoda/ui';
import { Checkbox, Form, TextField, addFormErrors, withForm } from '@lingoda/forms';
import { Translate, trans } from '@lingoda/i18n';
import { forgotPasswordPath, registerPath, resolvePath } from '@lingoda/router';
import type { FormErrors, FormProps } from '@lingoda/forms';
import { validate } from './schema';
import type * as React from 'react';

interface OwnProps {
    onSubmit: ({ username, password, rememberMe }: FormValues) => Promise<void>;
    username?: string;
    disabled?: boolean;
}

export interface FormValues {
    username: string;
    password: string;
    rememberMe: boolean;
}

type Props = OwnProps & FormProps<FormValues>;

const LoginFormComponent: React.FC<Props> = ({ disabled, isSubmitting }) => (
    <Form noValidate>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    name="username"
                    type="email"
                    variant="outlined"
                    label={trans('form-label-email', {}, 'public-common')}
                    placeholder={trans('form-label-email-placeholder', {}, 'public-common')}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="password"
                    type="password"
                    variant="outlined"
                    label={trans('form-label-password-label', {}, 'public-common')}
                    placeholder={trans('form-label-password-placeholder', {}, 'public-common')}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Checkbox
                    name="rememberMe"
                    label={trans('keep-me-logged-in', {}, 'student-registration')}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    fullWidth
                    disabled={isSubmitting || disabled}
                >
                    {trans('login-with-email-button', {}, 'student-registration')}
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Translate
                    id="forgot-password"
                    params={{
                        forgotPasswordPath: resolvePath(forgotPasswordPath()),
                    }}
                    domain="public-login"
                />
            </Grid>
            <Hidden smUp>
                <Grid item xs={12}>
                    <Translate
                        id="register-action"
                        params={{
                            registerPath: resolvePath(registerPath()),
                        }}
                        domain="public-login"
                    />
                </Grid>
            </Hidden>
        </Grid>
    </Form>
);

export const LoginForm = withForm<OwnProps, FormValues>({
    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values).catch((errors: FormErrors<FormValues>) => {
            addFormErrors(errors, formikBag);
            formikBag.setSubmitting(false);
        });
    },
    validate,
    mapPropsToValues: (props) => ({
        rememberMe: false,
        password: '',
        username: props.username || '',
    }),
})(LoginFormComponent);
