import { useEffect } from 'react';
import { useSelector, useToggleState } from '@lingoda/hooks';
import { goTo, loginPath, setRedirectPathStorage, wrapDisplayName } from '@lingoda/router';
import { Loader } from '@lingoda/ui';
import { getSessionValue, removeSessionValue } from '@lingoda/utils';
import {
    isAuthStatusUnauthenticatedSelector,
    isAuthStatusUnknownSelector,
    userSelector,
} from '@lingoda/auth';
import type { ComponentType, PropsWithChildren } from 'react';

const DefaultRedirect = () => {
    goTo(loginPath());

    return null;
};

const UnauthenticatedLoading = ({ children }: PropsWithChildren<unknown>) => {
    const [isLoaded, setLoaded] = useToggleState(false);

    useEffect(() => {
        const isSkippedRedirect = getSessionValue('isSkippedRedirect');

        if (isSkippedRedirect) {
            removeSessionValue('isSkippedRedirect');
        } else {
            const currentUrl = window.location.href;
            setRedirectPathStorage(currentUrl);
        }

        setLoaded();
    }, [setLoaded]);

    return isLoaded ? <>{children}</> : <Loader />;
};

interface Options {
    Redirect?: ComponentType;
}

export const ensureAuthenticated = <P extends object>(
    Component: ComponentType<PropsWithChildren<P>>,
    { Redirect }: Options = {
        Redirect: DefaultRedirect,
    },
): ComponentType<P> => {
    const EnsureAuthenticated = (props: PropsWithChildren<P>) => {
        const isUnauthenticated = useSelector(isAuthStatusUnauthenticatedSelector);
        const isUnknown = useSelector(isAuthStatusUnknownSelector);
        const user = useSelector(userSelector);

        if ((isUnknown && !user) || (!isUnauthenticated && !user)) {
            return <Loader />;
        } else if (isUnauthenticated) {
            return <UnauthenticatedLoading>{Redirect && <Redirect />}</UnauthenticatedLoading>;
        }

        return <Component {...props} />;
    };

    if (__DEV__) {
        EnsureAuthenticated.displayName = wrapDisplayName(Component, 'EnsureAuthenticated');
    }

    return EnsureAuthenticated;
};
