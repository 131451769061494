import { useUpcomingAppointmentsCountQuery } from '@lingoda/graphql';

export const useUpcomingAppointmentsCount = () => {
    const { data, loading } = useUpcomingAppointmentsCountQuery();

    return {
        count: data?.upcomingAppointmentsCount,
        hasUpcomingAppointments: !!data?.upcomingAppointmentsCount,
        loading,
    };
};
