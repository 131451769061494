import { createRecordFactory } from '@lingoda/utils';
import type { StudentInfoId } from './constants';

export type StudentId = number;

export interface TimeAttributes {
    end?: number;
    start?: number;
}
export interface NotificationsAttributes {
    startTourCourse?: string;
    [key: string]: string | number | boolean | undefined;
}

export enum AnnouncementId {
    lingobitesBanner = 'lingobitesBanner',
    practiceMaterialsUpdateBanner = 'practiceMaterialsUpdateBanner',
}

type DismissedAnnouncements = {
    [key in AnnouncementId]?: boolean;
};

export interface DismissedWidgets extends DismissedAnnouncements {
    calendarReminderWidget?: boolean;
    checkoutTheCourse?: boolean;
    downloadAppBanner?: boolean;
    myCourseUpgradeAnnouncement?: boolean;
    personalizedFeedbackDismissedAt?: string;
    referralBanner?: boolean;
    subscribeLingodaBanner?: boolean;
    subscribeLingodaPopup?: boolean;
    videoSection?: boolean;
    myCourseGuidanceBanner?: boolean;
    practiceSatisfactionSurvey?: string;
    multiDateAnnouncement?: boolean;
    goalSettingV2Banner?: boolean;
    vocabAudioAnnouncement?: boolean;
    yearlyRecapBanner?: boolean;
    learningTeamsNewCourseBanner?: string;
    fftWelcomeBanner?: boolean;
    bookOrientationClassBanner?: boolean;
    fftInformBanner?: boolean;
    studentFillProfileReminderTodayPageBanner?: boolean;
    studentFillProfileReminderProfilePageBanner?: boolean;
    studentFillProfileReminderProfileSettingsPageBanner?: boolean;
    studentFillProfileReminderClassDetailsPageBanner?: boolean;
}

export type DismissedWidgetId = keyof DismissedWidgets;

export interface StudentPreferences {
    nextClass?: boolean;
    notifications?: NotificationsAttributes;
    oneClickBooking?: boolean;
    npsFeedbackRequired?: boolean;
    movedToNextModule?: boolean;
    timeFormat?: string;
    weekStart?: number | undefined;
    isProductTourFinished?: boolean;
    emailCancelledClassConfirmation?: boolean;
    emailBookedClassConfirmation?: boolean;
    emailClassReminder?: boolean;
    emailWeeklyReport?: boolean;
    onboardingBooked?: boolean;
    showOrientationClassBanner?: boolean;
    practiceSequence?: PracticeSequencePreferences;
    pushNotificationsStatus?: boolean;
    pushNotificationsTriggerBeforeClassStartMinutes?: number;
    pushNotificationsFlashcardStatus?: boolean;
    flashCardPushNotificationsStatus?: boolean;
    flashCardPushNotificationSchedule?: {
        weekday: number;
        time: number;
    };
    studentFeedbackGroup?: boolean;
    dismissedWidgets?: DismissedWidgets;
    classWeeklyAttendanceGoal?: number;
    classWeeklyWordsGoal?: number;
    flashcardsSwapped?: boolean;
    quizIntroSeen?: boolean;
    isCompletedOnboardingSurvey?: boolean;
    applicationNotifications?: boolean;
}

export interface PracticeSequencePreferences {
    hideIntro?: boolean;
    hideIntroReflect?: boolean;
}

export const StudentPreferencesFactory = createRecordFactory<StudentPreferences>({});

export interface Student {
    curriculumId?: number;
    firstName: string;
    groupCredits?: number;
    id: StudentId;
    individualCredits?: number;
    lastName?: string;
    preferences?: StudentPreferences;
    photo?: {
        medium?: string;
    };
    sectionId?: number;
    timezone?: string;
    moduleId?: number;
    teacherIds?: number[];
    creditIds?: number[];
    onboarded?: boolean;
    isNewStudent?: boolean;
}

export const StudentFactory = createRecordFactory<Student>({
    curriculumId: undefined,
    firstName: undefined,
    groupCredits: undefined,
    id: undefined,
    individualCredits: undefined,
    lastName: undefined,
    preferences: StudentPreferencesFactory({}),
    sectionId: undefined,
    timezone: undefined,
    moduleId: undefined,
    teacherIds: undefined,
    creditIds: undefined,
    onboarded: undefined,
});

export interface StudentInfo {
    readonly id: StudentInfoId;
    readonly birthCity: string;
    readonly birthCountry: string;
    readonly invoiceAddress: string;
}

export const StudentInfoFactory = createRecordFactory<StudentInfo>({
    id: undefined,
    birthCity: undefined,
    birthCountry: undefined,
    invoiceAddress: undefined,
});
