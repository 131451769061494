import { useCallback, useEffect, useState } from 'react';
import {
    type RegisterUserParams,
    type ResourceOwnersSchema,
    getResourceOwners,
} from '@lingoda/api';
import { useToggleState, useWindowEventListener } from '@lingoda/hooks';
import { trans } from '@lingoda/i18n';
import { Grid, SocialButton } from '@lingoda/ui';
import Loader from '@lingoda/ui/feedback/Loader';
import { getNonEmptyRegisterState } from '../../utils';

interface Props {
    signup?: boolean;
    registerParams?: Partial<RegisterUserParams>;
    onClick?: () => void;
}

export const SocialOptions = ({ signup, onClick, registerParams = {} }: Props) => {
    const [resourceOwners, setResourceOwners] = useState<ResourceOwnersSchema[]>();
    const [disabled, setDisabled, setEnabled] = useToggleState(false);
    const { section, curriculumId, module, timezone, timeFormat, weekStart } = registerParams;

    useWindowEventListener('pagehide', setEnabled);

    useEffect(() => {
        const registerState = getNonEmptyRegisterState({
            section,
            curriculumId,
            module,
            timezone,
            timeFormat,
            weekStart,
        });
        void getResourceOwners(registerState).then(({ data }) => setResourceOwners(data));
    }, [section, curriculumId, module, timezone, timeFormat, weekStart]);

    const handleClick = useCallback(() => {
        if (onClick) {
            onClick();
        }
        setDisabled();
    }, [onClick, setDisabled]);

    return resourceOwners ? (
        <Grid container spacing={1}>
            {resourceOwners.map(({ type, name, loginUrl }) => (
                <Grid item xs={12} key={type}>
                    <SocialButton
                        onClick={handleClick}
                        disabled={disabled}
                        href={loginUrl}
                        vendor={type}
                        fullWidth
                    >
                        {signup
                            ? trans('sign-up-with', { name }, 'social-auth')
                            : trans('login-with', { name }, 'social-auth')}
                    </SocialButton>
                </Grid>
            ))}
        </Grid>
    ) : (
        <Loader minHeight={50} />
    );
};
