import { fetchMe } from '@lingoda/auth';
import { format, formatDateTime } from '@lingoda/dates';
import { UserCreditsDocument, useSchedulePauseMutation } from '@lingoda/graphql';
import { useDispatch } from '@lingoda/hooks';
import { trans } from '@lingoda/i18n';
import { showToast } from '@lingoda/toasts';

interface Args {
    startDate: Date;
    endDate: Date;
}

export const useSchedulePause = () => {
    const dispatch = useDispatch();

    const [schedulePauseMutation, { loading }] = useSchedulePauseMutation({
        refetchQueries: [UserCreditsDocument],
    });

    const schedulePause = async ({ startDate, endDate }: Args) => {
        try {
            await schedulePauseMutation({
                variables: {
                    pauseStartTime: formatDateTime(startDate),
                    pauseEndTime: formatDateTime(endDate),
                },
            });

            showToast(
                'success',
                trans(
                    'subscription-pause-feedback',
                    {
                        dateFrom: format('PPP', startDate),
                        dateTo: format('PPP', endDate),
                    },
                    'student-common',
                ),
            );

            dispatch(fetchMe());
        } catch (error) {
            if (error instanceof Error) {
                showToast('error', error.message);
            }

            throw error;
        }
    };

    return [schedulePause, { loading }] as const;
};
